import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="footer">
      <nav className="footer-links">
        <Link to="/privacy-policy">Privacy Policy</Link>
      </nav>
    </footer>
  );
}
