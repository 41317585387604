import Help from "../../../../../components/Help/Help";
import { getTrustPathogens } from "../../../../../lib/services/TrustSwitcher";
import SettingsContainer from "../SettingsContainer";
import QuestionList from "./List";
export default ({
  baseUrl,
  title,
  helpCopyKey,
  successMessage,
  deletedMessage,
}: {
  baseUrl: string;
  title: string;
  helpCopyKey?: string;
  successMessage?: string;
  deletedMessage?: string;
}) => (
  <>
    <SettingsContainer>
      <Help helpCopyKey={helpCopyKey} />
      {getTrustPathogens().map(({ id, name }) => (
        <>
          <QuestionList
            baseUrl={baseUrl}
            title={title}
            titleFull={`${name} ${title} Statements`}
            pathogens={[id]}
            successMessage={successMessage}
            deletedMessage={deletedMessage}
          />
        </>
      ))}
    </SettingsContainer>
  </>
);
