import { Button, CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import {
  getRoleCurrentTrust,
  getTrustId,
  getTrustPathogens,
} from "../../../../lib/services/TrustSwitcher";
import useDelayedRedirect from "../../Patients/BookAppointment/useDelayedRedirect";
import styles from "../Form.module.scss";
import useCreateTrustAdmin from "./useCreateTrustAdmin";
import useTrustList from "../Trust/useTrustList";
import useUpdateTrustAdmin from "./useUpdateTrustAdmin";
import {
  formatForServer,
  SelectPathogenList,
  pathogensFromArray,
} from "../../../../lib/pathogens";
import { Roles } from "../../../../lib/types/TrustRoles";
import ConfirmDialog from "../../ConfirmDialog";
import { humanDate, removeZeroFields } from "../../../../lib/common_utils";
import { Trust } from "../Trust/Trust";

type TrustAdminFormProps = {
  setFeedback: (feedback: {
    type: "success" | "error";
    message: string;
  }) => void;
  setWarningDialog?: (messages: boolean | string[]) => void;
  id: number;
  isUpdate: boolean;
  isChoosingTrust?: boolean;
  formData: any;
  returnPath?: string;
  proceedIfWarnings?: boolean;
};

const formId = "trustAdminCreateOrUpdate";
const RETURN_PATH = "/users/trust-admins";

export default function TrustAdminForm({
  setFeedback,
  id,
  isUpdate,
  isChoosingTrust = true,
  formData,
  returnPath = RETURN_PATH,
}: TrustAdminFormProps): JSX.Element {
  const history = useHistory();
  const {
    reset,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const [trusts, trustsLoading, trustsError] = useTrustList(isChoosingTrust);

  React.useEffect(() => {
    if (formData) reset(formData);
  }, [formData, reset]);

  const [warningDialog, setWarningDialog] = React.useState<boolean | string[]>(
    false,
  );
  // eslint-disable-next-line
  let [proceedIfWarnings, setProceedIfWarnings] = React.useState(false);

  const delayedRedirect = useDelayedRedirect(returnPath, 3);

  const [createAdmin, createSuccess, createError, createWarning] =
    useCreateTrustAdmin();
  const [updateAdmin, updateSuccess, updateError] = useUpdateTrustAdmin();

  const trustId = getTrustId();
  const isSuperAdmin = getRoleCurrentTrust() === Roles.SuperAdmin;
  const trustPathogens = getTrustPathogens();
  const isMultiplePathogenTrust = isSuperAdmin || trustPathogens?.length > 1;
  const [modal, setModal] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (data: any) => {
    setIsSubmitting(true);
    // Remove "Please Select" (value 0) option for given valueAsNumber select
    data = removeZeroFields({
      data,
      fieldsThatDontWantZero: ["trustId"],
    });

    if (isUpdate) {
      updateAdmin(id, {
        ...data,
        emailAddress: formData.emailAddress,
      });
    } else {
      createAdmin({
        firstName: data.firstName,
        lastName: data.lastName,
        dateOfBirth: new Date(data.dateOfBirth).toISOString(),
        emailAddress: data.emailAddress,
        jobTitle: data.jobTitle,
        mobileNumber: data.mobileNumber,
        trust: {
          id: data.trustId ?? trustId,
        },
        proceedIfWarnings,
        pathogens: isMultiplePathogenTrust
          ? formatForServer(data.pathogens)
          : pathogensFromArray(trustPathogens ?? []),
      });
    }
  };

  React.useEffect(() => {
    if (createSuccess) {
      setIsSubmitting(false);
      setFeedback({
        type: "success",
        message: "Trust Admin created",
      });
      delayedRedirect();
    }
  }, [createSuccess, setFeedback, delayedRedirect]);

  React.useEffect(() => {
    if (updateSuccess) {
      setIsSubmitting(false);
      setFeedback({
        type: "success",
        message: "Trust Admin updated",
      });
      delayedRedirect();
    }
  }, [updateSuccess, setFeedback, delayedRedirect]);

  React.useEffect(() => {
    if (createError) {
      setIsSubmitting(false);
      if (Array.isArray(createError)) {
        setFeedback({
          type: "error",
          message: createError.join(" "),
        });
      } else if (typeof createError == "string") {
        setFeedback({
          type: "error",
          message: createError,
        });
      } else {
        setFeedback({
          type: "error",
          message: "Trust Admin could not be created",
        });
      }
    }
  }, [createError, setFeedback]);

  React.useEffect(() => {
    if (updateError) {
      setIsSubmitting(false);
      if (Array.isArray(updateError)) {
        setFeedback({
          type: "error",
          message: updateError.join(" "),
        });
      } else {
        setFeedback({
          type: "error",
          message: "Trust Admin could not be updated",
        });
      }
    }
  }, [updateError, setFeedback]);

  React.useEffect(() => {
    if (trustsError) {
      setIsSubmitting(false);
      setFeedback({
        type: "error",
        message: "Trusts could not be loaded. Please refresh the page.",
      });
    }
  }, [trustsError, setFeedback]);

  React.useEffect(() => {
    if (createWarning) {
      setIsSubmitting(false);
      setWarningDialog(createWarning);
    }
  }, [setWarningDialog, createWarning]);

  const isDisplayingTrustSelect =
    isChoosingTrust && !isUpdate && !trustsLoading && !trustsError;

  return (
    <div className={styles.template}>
      <h1>{isUpdate ? "Edit Trust Admin" : "Create Trust Admin"}</h1>
      <form
        id={formId}
        className={styles.form}
        onSubmit={(e) => {
          handleSubmit(onSubmit)(e).catch((err) => {
            console.log(err);
            setFeedback({ type: "error", message: err.message });
          });
        }}
      >
        <div className={styles.email}>
          {/*First Name*/}
          <label className={styles.textInput}>
            <span>
              First Name{" "}
              <strong>(Must be identical to their ESR record)</strong>
            </span>
            <input
              {...register("firstName", {
                required: "You must enter a first name",
              })}
            />
            {errors.firstName && (
              <span role="alert">{errors.firstName.message}</span>
            )}
          </label>

          {/*Last Name*/}
          <label className={styles.textInput}>
            <span>
              Last Name <strong>(Must be identical to their ESR record)</strong>
            </span>
            <input
              {...register("lastName", {
                required: "You must enter a last name",
              })}
            />
            {errors.lastName && (
              <span role="alert">{errors.lastName.message}</span>
            )}
          </label>

          {/*Date of birth*/}
          {/* IF THEY ASK again for date of birth to be viewable/editable for trust admins.. it isn't stored against trust admin:
          https://2-app.donedone.com/6110/project/17407/task/682513
          UPDATE: They did ask, for it to be viewable at least */}
          <label className={styles.textInput}>
            <span>
              Date of Birth{" "}
              <strong>(Must be identical to their ESR record)</strong>
            </span>
            {isUpdate ? (
              <span className={styles.nonEditableField}>
                {formData?.dateOfBirth === "0001-01-01T00:00:00"
                  ? "Unavailable"
                  : humanDate(formData?.dateOfBirth)}
              </span>
            ) : (
              <>
                <input
                  type="date"
                  {...register("dateOfBirth", {
                    required: "You must enter a date of birth",
                  })}
                />
                {errors.dateOfBirth && (
                  <span role="alert">{errors.dateOfBirth.message}</span>
                )}
              </>
            )}
          </label>

          {/*Email*/}
          {!isUpdate && (
            <label className={styles.textInput}>
              <span>
                Email <strong>(Must be identical to their ESR record)</strong>
              </span>
              <input
                {...register("emailAddress", {
                  required: "You must enter an email address",
                })}
              />
              {errors.emailAddress && (
                <span role="alert">{errors.emailAddress.message}</span>
              )}
            </label>
          )}
          {isUpdate && formData?.emailAddress && (
            <label className={styles.textInput}>
              <span>
                Email <strong>(Must be identical to their ESR record)</strong>
              </span>
              <span className={styles.nonEditableField}>
                {formData.emailAddress}
              </span>
            </label>
          )}

          {/*Available Vaccines*/}
          {!isUpdate && isMultiplePathogenTrust && (
            <label className={styles.textInput}>
              Vaccines this trust admin can book for themselves
              <select
                className={styles.trustSelect}
                {...register("pathogens", {
                  required: "You must select a vaccine type",
                  validate: (v: string) =>
                    v?.length > 0 || "You must select clinic type",
                })}
              >
                <option value=""></option>
                {SelectPathogenList.map((pathogen, idx) => (
                  <option key={`${idx}`} value={pathogen.value.join(",")}>
                    {/*
                    Bullshit requests get bullshit solutions, and this was right at the bottom of the steamy pile:
                    https://techdepartment.mydonedone.com/issuetracker/projects/84148/issues/377 */}
                    {pathogen.label.replace(" Together", "")}
                  </option>
                ))}
              </select>
              {errors.pathogens && (
                <span role="alert">{errors.pathogens.message}</span>
              )}
            </label>
          )}

          {/*Job Title*/}
          <label className={styles.textInput}>
            Job Title
            <input
              {...register("jobTitle", {
                required: "You must enter a job title",
              })}
            />
            {errors.jobTitle && (
              <span role="alert">{errors.jobTitle.message}</span>
            )}
          </label>

          {/*Organisation Name*/}
          {isDisplayingTrustSelect && (
            <>
              <label className={styles.textInput}>
                <span>Organisation Name</span>
                <select
                  className={styles.trustSelect}
                  {...register("trustId", {
                    valueAsNumber: true,
                    required: "You must enter an organisation name",
                  })}
                >
                  <option value="">Please Select</option>
                  {trusts.map((t: Trust) => (
                    <option key={t.id} value={t.id}>
                      {t.trustName}
                    </option>
                  ))}
                </select>
                {errors?.trustId && (
                  <span role="alert">{errors.trustId?.message}</span>
                )}
                {/* This might be vestigial? : */}
                {errors.trust && (
                  <span className={styles.error}>{errors.trust.message}</span>
                )}
              </label>
            </>
          )}

          {/*Contact Number*/}
          <label className={styles.textInput}>
            Contact Number (Optional)
            <input {...register("mobileNumber")} />
            {errors.mobileNumber && (
              <span role="alert">{errors.mobileNumber.message}</span>
            )}
          </label>
        </div>
        {isSubmitting ? (
          <CircularProgress />
        ) : (
          <div className={styles.actions}>
            <Button
              form={formId}
              color="secondary"
              variant="contained"
              disabled={isSubmitting}
              onClick={(e) => {
                e.preventDefault();
                setModal("confirm");
              }}
            >
              {isUpdate ? "Update" : "Create"}
            </Button>
            {modal === "confirm" && (
              <ConfirmDialog
                open={true}
                title="Warning"
                confirmLabel="Yes"
                onClose={() => {
                  setModal(null);
                }}
                onConfirm={() => {
                  setModal(null);
                  handleSubmit(onSubmit)().catch((err) => {
                    console.log(err);
                    setFeedback({ type: "error", message: err.message });
                  });
                }}
              >
                <>
                  <p>
                    Are you sure that their <strong>First Name</strong>,{" "}
                    <strong>Last Name</strong>, <strong>Date of Birth</strong>{" "}
                    and <strong>Email Address</strong> exactly match the trust
                    admin's ESR record?"
                  </p>
                </>
              </ConfirmDialog>
            )}
            <Button
              onClick={() => {
                history.push(returnPath);
              }}
            >
              Cancel
            </Button>
          </div>
        )}
      </form>

      <ConfirmDialog
        open={Array.isArray(warningDialog)}
        title="Please confirm"
        confirmLabel="Confirm"
        onConfirm={() => {
          setWarningDialog(false);
          setProceedIfWarnings(true);
          proceedIfWarnings = true;
          handleSubmit(onSubmit)().catch((err) => {
            console.log(err);
            setFeedback({ type: "error", message: err.message });
          });
        }}
        onClose={() => {
          setWarningDialog(false);
        }}
      >
        <span>
          {Array.isArray(warningDialog)
            ? warningDialog.join(" ")
            : "A similar Trust Worker already exists. Please confirm and then resubmit to update them as a Trust Admin."}
        </span>
      </ConfirmDialog>
    </div>
  );
}
