import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid/Grid";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import styles from "../Form.module.scss";
import useCreateSuperAdmin from "./useCreateSuperAdmin";
import useSuperAdmin from "./useSuperAdmin";
import useUpdateSuperAdmin from "./useUpdateSuperAdmin";

const SUPER_ADMIN_URL = "/users/super-admins";

type CreateUpdateProps = {
  id: number | null;
  setFeedback: (feedback: {
    type: "success" | "error";
    message: string;
  }) => void;
};

export default function SuperAdminCreateUpdate({
  id,
  setFeedback,
}: CreateUpdateProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const isUpdate = id !== null;
  const {
    reset,
    handleSubmit,
    register,
    formState: { errors: formErrors },
  } = useForm();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [admin, _, adminError] = useSuperAdmin(id);
  React.useEffect(() => {
    if (isUpdate && admin) {
      reset(admin);
    }
  }, [isUpdate, admin, reset]);
  React.useEffect(() => {
    if (adminError) {
      setFeedback({
        type: "error",
        message: "Could not load the Super Admin. Please retry.",
      });
    }
  }, [adminError, setFeedback]);
  const [createAdmin, createdSuccess, createdError] = useCreateSuperAdmin(); //TODO: useCreateSuperAdmin
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updateAdmin, updateSuccess, updateError] = useUpdateSuperAdmin();

  React.useEffect(() => {
    if (createdSuccess) {
      setFeedback({
        type: "success",
        message: "Super Admin created successfully.",
      });
      console.log(createdSuccess);
      history.push(SUPER_ADMIN_URL, {
        admin: createdSuccess,
      });
    }
  }, [createdSuccess, setFeedback, history]);

  React.useEffect(() => {
    if (createdError) {
      setFeedback({ type: "error", message: "Failed to create Super Admin." });
    }
  }, [createdError, setFeedback]);

  React.useEffect(() => {
    if (updateSuccess) {
      setFeedback({
        type: "success",
        message: "Super Admin updated successfully.",
      });
      history.push(SUPER_ADMIN_URL, {
        update: updateSuccess,
      });
    }
  }, [updateSuccess, setFeedback, history]);

  React.useEffect(() => {
    if (updateError) {
      setFeedback({ type: "error", message: "Failed to update Super Admin." });
    }
  }, [updateError, setFeedback]);

  const onSubmit = (data: any) => {
    if (isUpdate) {
      updateAdmin(id, {
        email: data.email,
      });
    } else {
      createAdmin({
        email: data.email,
      });
    }
  };
  return (
    <Dialog
      fullScreen={fullScreen}
      open={true}
      onClose={() => {
        history.push(SUPER_ADMIN_URL);
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {isUpdate ? <h2>Edit Super Admin</h2> : <h2>Create Super Admin</h2>}
      </DialogTitle>
      <DialogContent>
        <form
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          style={{ marginBottom: "10px" }}
        >
          <div className={styles.email}>
            <label className={styles.textInput}>
              Email
              <input
                {...register("email", {
                  required: "You must enter a email address.",
                })}
              />
              {formErrors.email && (
                <span role="alert">{formErrors.email.message}</span>
              )}
            </label>
          </div>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Button
                type="button"
                color="default"
                onClick={() => {
                  history.push(SUPER_ADMIN_URL);
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                autoFocus
              >
                {isUpdate ? "Update" : "Create"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}
