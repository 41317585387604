import React from "react";
import DataClient from "../../../../lib/services/api/DataClient";
import useDataClient from "../useDataClient";

export type SuperAdmin = {
  id?: number;
  email: string;
};

type SuperAdminResponse = {
  success: boolean;
  results: SuperAdmin[];
};

const isSuperAdminResponse = (res: unknown): res is SuperAdminResponse => {
  return res["success"] && res["results"] && Array.isArray(res["results"]);
};

export default function useSuperAdminList() {
  const postDataArgs = React.useMemo(() => ["/SuperAdmin/Search"], []);
  const hookOptions = React.useMemo(
    () => ({
      formatter: (data: SuperAdminResponse) => {
        if (!data.success) {
          throw new Error("API call was a failure");
        }
        return data.results;
      },
      guard: isSuperAdminResponse,
    }),
    []
  );
  const adminList = useDataClient<SuperAdmin[], SuperAdminResponse>(
    DataClient.postData,
    postDataArgs,
    hookOptions
  );
  return adminList;
}
