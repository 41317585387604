//
// Simple custom validation rule system to layer on top of react-hook-form
// that also mixes in nicely with server-side validation output
// For usage, have a glance at validateAllTheThings in Register.tsx
// NOTE: To rules generally allow empty values, so you might put a simple `required: true` on the input itself
//
import moment from "moment";
import { ValidateResult } from "react-hook-form";
// Extend hooks validation to allow passing the whole data as well as just value
type Validate<TFieldValue> = (
  value: TFieldValue,
  data?: any
) => ValidateResult | Promise<ValidateResult>;
export const validateAllTheThings = (
  data: any,
  validations: {
    field: string;
    // tests: [key: string, value: Validate<any>];
    tests: Record<string, Validate<any>>[];
  }[]
) => {
  const errors = {};
  validations.forEach(({ field, tests }) => {
    const value = data[field];
    tests.forEach((test) => {
      Object.values(test).forEach((testRule) => {
        const result = testRule(value, data);
        if (result !== true) {
          errors[field] = result;
        }
      });
    });
  });
  return errors;
};
export const required = (options?: {
  message?: string;
}): Record<string, Validate<any>> => {
  return {
    required: (v: string) =>
      !v ? options.message || "This field is required" : true,
  };
};
export const validateNhsNumber: Record<string, Validate<any>> = {
  format: (v: string) =>
    !v || // allow empty
    v === "" ||
    /^[0-9\s]*$/.test(v) ||
    "Please only enter numbers and spaces.",
  equalsTen: (v: string) =>
    !v ||
    v === "" ||
    v.replaceAll(" ", "").length === 10 ||
    "Your NHS Number should be 10 digits.",
  validCheckDigit: (v: string) => {
    //https://www.datadictionary.nhs.uk/attributes/nhs_number.html
    if (!v || v === "") return true;
    const nhsNumber = v.replaceAll(" ", "");
    const checkDigit = parseInt(nhsNumber[9]);
    const sum = nhsNumber
      .slice(0, 9)
      .split("")
      .reduce((acc, cur, i) => {
        return acc + parseInt(cur) * (10 - i);
      }, 0);
    const remainder = sum % 11;
    const checkValue = 11 - remainder === 11 ? 0 : 11 - remainder;
    return checkValue === checkDigit || "Your NHS Number is not valid.";
  },
};
export const validatePhoneNumber: Record<string, Validate<any>> = {
  length: (v: string) => {
    if (!v || v.length === 0) return true;
    const strip = v.replace(/^44|^0/, "");
    if (strip.length >= 9 && strip.length <= 11) {
      return true;
    } else {
      return "Please only enter a phone number with 9 to 11 digits.";
    }
  },
};
export const validateHasNhsNumberOrPostCode: Record<string, Validate<any>> = {
  hasNhsNumberOrPostcode: (v: string, data: any) => {
    if (data.addedManually || data.selfRegistered) {
      return !data?.nhsNumber && !data?.postCode
        ? "Either NHS Number or Postcode are required"
        : true;
    } else {
      return true;
    }
  },
};
export const dateOfBirthOver16: Record<string, Validate<any>> = {
  over16: (v: string) => {
    const dob = moment(v);
    if (
      !v || // Also a required field in general
      v.length === 0 ||
      moment(dob).isAfter(moment().subtract(16, "years"))
    ) {
      return "Age needs to be above 16.";
    }
    return true;
  },
};
export const validatePathogenChosen: Record<string, Validate<any>> = {
  pathogenChosen: (v: string) => {
    return (v ?? []).length === 0
      ? "You must select which vaccine(s) the staff member can receive"
      : true;
  },
};
