import { CircularProgress, LinearProgress } from "@material-ui/core";
import { useEffect, useMemo, useState } from "react";
import { Link, useLocation, RouteComponentProps } from "react-router-dom";
import { Pathogens } from "../../../../lib/pathogens";
import {
  getPatientCampaignId,
  getLastCovidDoseDateFromMe,
  getPatientCampaignIdFromMe,
  getTrustWorkerId,
  getWorkerPathogens,
  getMe,
  findPatientCampaignIdInMe,
} from "../../../../lib/services/TrustSwitcher";
import BookingForm from "./BookingForm";
import useAppointments from "./useAppointments";
import useDelayedRedirect from "./useDelayedRedirect";
import { DateTime } from "luxon";
import {
  alertNoActiveCampaign,
  alertTrustAdminNotTrustWorker,
} from "../../../../components/AlertMessaging";
type LocationState = {
  reschedule?: number;
  pathogens?: Pathogens[];
  secondCovidDate?: string; // ISO format (can't pass DateTime objects through history)
};
export default (
  props: RouteComponentProps<{
    pathogens?: string;
  }>
): JSX.Element => {
  let bookingPathogensFromUrl;
  try {
    bookingPathogensFromUrl = props?.match?.params?.pathogens
      ? JSON.parse(decodeURIComponent(props.match.params.pathogens))
      : null;
  } catch (e) {
    console.log(
      "Error deciphering pathogens from URL",
      props?.match?.params?.pathogens
    );
  }
  const {
    bookings,
    loading: appointmentsLoading,
    errors: appointmentErrors,
  } = useAppointments(getPatientCampaignId());
  const redirect = useDelayedRedirect("/my-appointments", 3);
  const { state } = useLocation<LocationState>();
  const { secondCovidDate } = state || {};
  const rescheduleAppointment = state?.reschedule || null;
  const trustWorkerPathogens = getWorkerPathogens();
  const isBooked = useMemo(
    () =>
      bookings !== null &&
      trustWorkerPathogens?.every((p) => bookings[JSON.stringify([p])]),
    [bookings, trustWorkerPathogens]
  );
  const bookingPathogens = bookingPathogensFromUrl
    ? bookingPathogensFromUrl
    : trustWorkerPathogens?.filter((p) => !bookings[JSON.stringify([p])]);
  useEffect(() => {
    if (isBooked && rescheduleAppointment === null) {
      redirect();
    }
  }, [isBooked, redirect, rescheduleAppointment]);
  const [lastCovidDoseDate, setLastCovidDoseDate] = useState<DateTime | null>(
    null
  );
  const [alert, setAlert] = useState(null);

  // Updated to match the My Appointments page, minus the Context setting,
  useEffect(() => {
    (async () => {
      const me = await getMe();
      const patientCampaignId = findPatientCampaignIdInMe(me);
      // If for some reason you are a TA but not a TW/V/CA, you should receive a specific message. Daft logic duplicated in My Appointments component.
      if (
        me.currentTrust.roles.includes("TrustAdmin") &&
        !(
          me.currentTrust.roles.includes("TrustWorker") ||
          me.currentTrust.roles.includes("Vaccinator") ||
          me.currentTrust.roles.includes("ClinicAdmin")
        )
      ) {
        setAlert(alertTrustAdminNotTrustWorker);
      } else if (!patientCampaignId) {
        setAlert(alertNoActiveCampaign({ involvesBookings: true }));
      }
    })();
  }, [getPatientCampaignIdFromMe]);

  useEffect(() => {
    // But then also get it from getMe if possible, in case users resets the history state
    // (don't solely rely on this, as the db doesn't update quick enough during redirect to this page so it comes back null)
    // Update: we're dismantling this concept now so it's not so important
    (async () => {
      const date = await getLastCovidDoseDateFromMe();
      setLastCovidDoseDate(date);
    })();
  }, [setLastCovidDoseDate, state, secondCovidDate]);
  return (
    <main className="wrapper-main">
      <h1>
        {rescheduleAppointment ? "Reschedule" : "Book"} Vaccination Appointment
      </h1>
      {alert ? (
        alert
      ) : appointmentsLoading ? (
        <LinearProgress color="primary" />
      ) : appointmentErrors.length > 0 ? (
        <p>We could not load your information. Please refresh the page.</p>
      ) : isBooked && !rescheduleAppointment ? (
        <>
          <h2>You already have an appointment booked</h2>
          <p>
            To manage your existing booking, go to the{" "}
            <Link to="/my-appointments">My Appointments</Link> page.
            (Redirecting in 3 seconds ...)
          </p>
          <CircularProgress />
        </>
      ) : (
        <BookingForm
          trustWorkerId={getTrustWorkerId()}
          bookingForMyself={true}
          reschedule={rescheduleAppointment}
          pathogens={bookingPathogens}
          dateOverride={lastCovidDoseDate}
        />
      )}
    </main>
  );
};
