import { LinearProgress } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router-dom";
import ToastMessage from "../../../../../components/Universal/toast_message";
import useTrustAdmin from "../../../SuperAdmin/TrustAdmin/useTrustAdmin";
import TrustAdminForm from "../../../SuperAdmin/TrustAdmin/TrustAdminForm";
import SettingsContainer from "../SettingsContainer";

export default function TrustAdminUpdate() {
  const { id } = useParams<{ id: string }>();
  const [feedback, setFeedback] =
    React.useState<{ type: "success" | "error"; message: string }>(null);
  const [data, loading, error] = useTrustAdmin(Number(id));
  React.useEffect(() => {
    if (error) {
      setFeedback({
        type: "error",
        message: "There was a problem loading the Trust Admin.",
      });
    }
  }, [error]);
  return (
    <>
      {loading && <LinearProgress color="primary" />}
      <SettingsContainer>
        <TrustAdminForm
          setFeedback={setFeedback}
          isUpdate={true}
          id={Number(id)}
          formData={data}
          isChoosingTrust={false}
          returnPath="/settings/trust-admins"
        />
        {feedback && (
          <ToastMessage
            variant={feedback.type}
            message={feedback.message}
            clear={() => {
              setFeedback(null);
            }}
          />
        )}
      </SettingsContainer>
    </>
  );
}
