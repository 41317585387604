import { enumVaccinationStatus } from "../../../../../lib/enums";
import {
  humanPathogen,
  otherPathogenIds,
  Pathogens,
} from "../../../../../lib/pathogens";
import { getCurrentTrust } from "../../../../../lib/services/TrustSwitcher";
import { MyAppointment } from "../MyAppointments";
import { AppointmentFields } from "./AppointmentFields";
import styles from "./appointments.module.scss";
import DeclineActions from "./DeclineActions";
const DeclineMessage = ({ pathogens }: { pathogens: Pathogens[] }) => (
  <>
    <p>Let us know if you’ve:</p>
    <ul>
      <li>Already had it elsewhere</li>
      <li>Can’t have it for medical reasons</li>
      <li>Do not want the vaccine</li>
    </ul>
    {pathogens.length > 1 && (
      <p>
        If you’ve already had a vaccine elsewhere or you wish to decline one,
        click on the relevant link in the flu and/or Covid boxes on this page.
        Each vaccine type must be declined separately.
      </p>
    )}
  </>
);
const noClinicsMessageMap = {
  [JSON.stringify([Pathogens.Flu])]:
    "The trust is not currently offering clinics to book just your flu vaccination on its own.",
  [JSON.stringify([Pathogens.Covid])]:
    "The trust is not currently offering clinics to book just your Covid booster vaccination on its own.",
  [JSON.stringify([Pathogens.Flu, Pathogens.Covid])]:
    "The trust is not currently offering clinics to book your flu and Covid booster vaccinations together.",
};
export default function Invalid({
  appointment,
}: {
  appointment: MyAppointment;
}) {
  const {
    isClinicAvailable,
    pathogens,
    isCombined,
    isCombinedFeasible,
    hasASeparateCombinedBooking,
  } = appointment;
  const { vaccinationStatusId } = appointment?.status || {};
  // Previous vaccinations have no details, including location, so we jam in the current trust and a note:
  // https://techdepartment.mydonedone.com/issuetracker/projects/84148/issues/383
  const appointmentOverrides: any = {
    details: {
      locationName:
        vaccinationStatusId === enumVaccinationStatus["Vaccinated"]
          ? getCurrentTrust().trustName
          : null,
      clinicLocationName:
        vaccinationStatusId === enumVaccinationStatus["Vaccinated"]
          ? "Recorded by Trust Admin"
          : null,
      description: appointment?.status?.declineDescription,
    },
  };
  // However, had elsewhere vaccinations can sometimes have details,
  // which all piped through in separate status.hadElsewhereFoo values (ಠ╭╮ಠ)
  // https://2-app.donedone.com/6110/project/17407/task/682515
  // NOTE!
  // If client starts wondering where missing fields are, such as injection site,
  // those fields are disabled when editing vaccinations and unavailable
  // Vaccine Type (e.g. Pfizer) is enabled, however, but hadElsewhereVaccinationTypeName doesn't come back
  // on the API with campaignPathogenStatuses but is ready to work if it did.
  if (vaccinationStatusId === enumVaccinationStatus["Had Elsewhere"]) {
    appointmentOverrides.status = {
      patientVaccination: {
        vaccinationBatchNumber:
          appointment?.status?.hadElsewhereVaccinationBatchNumber,
        vaccinationDate: appointment?.status?.hadElsewhereDate,
        vaccinationTypeName:
          appointment?.status?.hadElsewhereVaccinationTypeName,
      },
    };
    appointmentOverrides.details.clinicLocationName =
      appointment?.status?.hadElsewhereLocation;
    appointmentOverrides.details.locationName =
      appointment?.status?.hadElsewhereSite;
  }
  return (
    <div>
      <div className={styles.status} data-theme-id={pathogens}>
        <h3>
          {/* If 'had elsewhere' but, at another trust, display as vaccinated elsewhere:
          https://techdepartment.mydonedone.com/issuetracker/projects/84148/issues/391 */}
          {appointment?.lockedByOtherTrust &&
          vaccinationStatusId === enumVaccinationStatus["Had Elsewhere"]
            ? "Vaccinated"
            : enumVaccinationStatus[vaccinationStatusId] || "Not available"}
        </h3>
        {appointment?.lockedByOtherTrust && (
          <p>
            (Click the 'Switch Trust' link in the top navigation to view
            details)
          </p>
        )}
      </div>
      {!isClinicAvailable &&
        // #dd-391 Only show 'not currently offering clinics' message if user has no booking
        (vaccinationStatusId === enumVaccinationStatus["No Booking"] ||
          // Status is booked (but no actual appointment) as there's a combined booking, show the 'trust not currently offering' errors which isn't right but yeah.. requested.
          (vaccinationStatusId === enumVaccinationStatus["Booked"] &&
            appointment.hasASeparateCombinedBooking)) && (
          <p>{noClinicsMessageMap[JSON.stringify(pathogens)]}</p>
        )}
      {appointment.canDecline && <DeclineMessage pathogens={pathogens} />}
      {
        // ┌(ಠ_ಠ)┘ P2 2.4
        // "Different text in single pathogen cards in this scenario:"
        !appointment.isCombined &&
        // "if you have a combined appointment" ...
        appointment.hasASeparateCombinedBooking &&
        // ... "and status is Vaccinated for the other pathogen (within combined appointment)"
        appointment.isVaccinatedForAnotherPathogenInSeparateCombinedBooking &&
        // ... "and status is Booked, ABNV or DNA for that pathogen"
        appointment.isBookedABNVDNAForThisPathogenInSeparateCombinedBooking ? (
          <>
            <p>
              To book a separate {Pathogens[appointment.pathogens?.[0]]}{" "}
              appointment you need to cancel your previous combined appointment
              by clicking the 'Cancel & Rebook{" "}
              {Pathogens[appointment.pathogens?.[0]]} Appointment' button.
            </p>
            <p>
              Your{" "}
              {/* Just pre-empting this issue (despite design by TK)
                https://techdepartment.mydonedone.com/issuetracker/projects/85036/issues/5#history-27816077 */}
              {humanPathogen(
                Pathogens[otherPathogenIds(appointment.pathogens)?.[0]]
              )}{" "}
              vaccination records will not be affected.
            </p>
          </>
        ) : (!(appointment.status && vaccinationStatusId > 1) &&
            isClinicAvailable &&
            !appointment.canDecline &&
            !appointment.hasDeclined) ||
          (!isCombined && hasASeparateCombinedBooking) ||
          // #dd-248 Combined clinics shouldn't be shown if user is already vaccinated in either Flu / Covid individually, in any trust
          // combinedAppointment
          (isCombined && !isCombinedFeasible) ? (
          <>
            <p>This option is not available, for one of these reasons:</p>
            <ul>
              <li>You have booked your vaccine(s) separately</li>
              <li>
                The trust is not offering this type of clinic at the moment
              </li>
              <li>You have had your vaccine(s) elsewhere</li>
              <li>
                You have booked your vaccine(s) at another Trust (use Switch
                Trust in the top navigation to check)
              </li>
              <li>You have been marked as exempt for a vaccination</li>
            </ul>
          </>
        ) : (
          <AppointmentFields
            appointment={{ ...appointment, ...appointmentOverrides }}
          />
        )
      }
      <DeclineActions appointment={appointment} />
    </div>
  );
}
