import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import NoSsr from "@material-ui/core/NoSsr";
import Paper from "@material-ui/core/Paper";
import { emphasize, makeStyles, useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CancelIcon from "@material-ui/icons/Cancel";
import clsx from "clsx";
import Select from "react-select";
// import components from "react-select";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(4),
  },
  input: {
    display: "flex",
    padding: 0,
    height: "auto",
    minHeight: "42px",
    alignItems: "center",
    background: "#fff",
  },
  multiValueNoChips: {
    top: "12px",
    left: "2px",
    bottom: "6px",
    position: "absolute",
    fontSize: "14px",
    paddingLeft: "15px",
    // fontWeight: "bold",
  },
  valueContainer: {
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
    padding: "5px",
    display: "-webkit-inline-box",
    // lineHeight: '2'
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 14,
    paddingLeft: 15,
  },
  placeholder: {
    position: "absolute",
    left: 2,
    bottom: 6,
    fontSize: 14,
    paddingLeft: 15,
    top: 12,
    fontWeight: "bold",
  },
  paper: {
    position: "absolute",
    zIndex: 5,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    fonSize: 12,
  },
  divider: {
    height: theme.spacing(2),
  },
}));

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;
  return (
    <TextField
      disabled={props.isDisabled}
      fullWidth
      variant="outlined"
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="li"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
        color: props.isSelected ? "#009fe3" : "",
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

const OptionWithCheckbox = (props) => {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocussed}
      component="li"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
        color: props.isSelected ? "#283583" : "",
      }}
      {...props.innerProps}
    >
      {["selectAll", "selectNone"].includes(props.data.value) ? (
        <></>
      ) : (
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
          style={{ marginRight: "1em", accentColor: "#283583" }}
        />
      )}{" "}
      {props.children}
    </MenuItem>
  );
};

function Placeholder(props) {
  const { selectProps, innerProps = {}, children } = props;
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.placeholder}
      {...innerProps}
    >
      {children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
      noWrap
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function MultiValueCheckbox(props) {
  return (
    <p className={props.selectProps.classes.multiValueNoChips}>
      {props.index == 0 ? "Include / Exclude Level 1s" : ""}
    </p>
  );
}

function Menu(props) {
  return (
    <Paper
      style={{ zIndex: "1750" }}
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

const customComponents = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};
/**
 * Dropdown input based on react-select
 *
 * @link https://react-select.com/home
 * @export
 * @param {*} props
 * @return {*}
 */
const multiCheckboxComponents = {
  Control,
  Menu,
  MultiValue: MultiValueCheckbox,
  NoOptionsMessage,
  Option: OptionWithCheckbox,
  Placeholder,
  SingleValue,
  ValueContainer,
};

export default function DropdownInput(props) {
  const classes = useStyles();
  const styles = props.styles || {};
  const className = props.className || ""; // extras
  const htmlAttributes = props.htmlAttributes || {}; // extras
  const theme = useTheme();
  const suggestions = props.options;
  const inputFieldProps = props.inputFieldProps;
  const dropdownValue = inputFieldProps.value;
  const [single, setSingle] = [dropdownValue, props.setDropDownValue];
  const [multi, setMulti] = [dropdownValue, props.setDropDownValue];
  const components = props.components
    ? { ...customComponents, ...props.components }
    : customComponents;

  function handleChangeSingle(value) {
    setSingle(value);
  }

  function handleChangeMulti(selectedOptions) {
    if (props.multipleCheckbox) {
      if (selectedOptions.find((opt) => opt.value === "selectAll")) {
        const noSelectAll = props.options.filter(
          (opt) => opt.value !== "selectAll" && opt.value !== "selectNone"
        );
        setMulti(noSelectAll);
      } else if (selectedOptions.find((opt) => opt.value === "selectNone")) {
        setMulti([]);
      } else {
        setMulti(selectedOptions);
      }
    } else {
      setMulti(selectedOptions);
    }
  }

  function handleChangeMultiCheckbox(selectedOptions) {
    if (selectedOptions.find((opt) => opt.value === "selectAll")) {
      const noSelectAll = props.options.filter(
        (opt) => opt.value !== "selectAll" && opt.value !== "selectNone"
      );
      setMulti(noSelectAll);
    } else if (selectedOptions.find((opt) => opt.value === "selectNone")) {
      setMulti([]);
    } else {
      setMulti(selectedOptions);
    }
  }

  const selectStyles = (styleOverrides) => ({
    input: (base) => ({
      ...base,
      color: theme.palette.text.primary,
      "& input": {
        font: "inherit",
      },
      ...styleOverrides.input,
    }),
    menuList: (base) => ({
      ...base,
      ...styleOverrides.menuList,
    }),
  });

  const addSelectAllNone = (options) => {
    if (
      options.find(
        (opt) => opt.value === "selectAll" || opt.value === "selectNone"
      )
    ) {
      return options;
    } else {
      props.options.unshift({ value: "selectNone", label: "Unselect All" });
      props.options.unshift({ value: "selectAll", label: "Select All" });
      return options;
    }
  };

  return (
    <div
      className={`${classes.root} ${className}`.trim()}
      style={styles}
      {...htmlAttributes}
    >
      <NoSsr>
        {props.multiple ? (
          <Select
            classes={classes}
            styles={selectStyles(styles)}
            inputId={inputFieldProps.id ? inputFieldProps.id : null}
            TextFieldProps={{
              label: inputFieldProps.label ? inputFieldProps.label : null,
              InputLabelProps: {
                htmlFor: inputFieldProps.id ? inputFieldProps.id : null,
                shrink: true,
              },
            }}
            error={props.error}
            placeholder={
              inputFieldProps.placeholder ? inputFieldProps.placeholder : null
            }
            options={suggestions}
            components={components}
            value={multi}
            isDisabled={props.disabled}
            onChange={handleChangeMulti}
            isMulti
            required={props.required}
          />
        ) : props.multipleCheckbox ? (
          <Select
            classes={classes}
            styles={selectStyles(styles)}
            inputId={inputFieldProps.id ? inputFieldProps.id : null}
            TextFieldProps={{
              label: inputFieldProps.label ? inputFieldProps.label : null,
              InputLabelProps: {
                htmlFor: inputFieldProps.id ? inputFieldProps.id : null,
                shrink: true,
              },
            }}
            placeholder={
              inputFieldProps.placeholder
                ? inputFieldProps.placeholder
                : "Select"
            }
            error={props.error}
            options={addSelectAllNone(suggestions)}
            components={multiCheckboxComponents}
            value={multi}
            isDisabled={props.disabled}
            onChange={handleChangeMultiCheckbox}
            isMulti
            isMultiCheckbox
            required={props.required}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            isClearable={false}
            isSearchable={false}
            maxMenuHeight={"60vh"}
          />
        ) : (
          <Select
            classes={classes}
            styles={selectStyles(styles)}
            inputId={inputFieldProps.id ? inputFieldProps.id : null}
            TextFieldProps={{
              label: inputFieldProps.label ? inputFieldProps.label : null,
              InputLabelProps: {
                htmlFor: inputFieldProps.id ? inputFieldProps.id : null,
                shrink: true,
              },
            }}
            error={props.error}
            placeholder={
              inputFieldProps.placeholder ? inputFieldProps.placeholder : null
            }
            options={suggestions}
            components={components}
            value={single}
            isDisabled={props.disabled}
            onChange={handleChangeSingle}
          />
        )}
      </NoSsr>
    </div>
  );
}
