import { Pathogens } from "./pathogens";

// VaccinationHistoryIdentifierEnum.cs must be kept up to date with
// VaccinationHistoryIdentifierEnum.ts !
export const VaccinationHistoryIdentifiers = [
  {
    id: 201,
    key: "CovidBase1",
    name: "Covid 1",
    pathogen: Pathogens.Covid,
  },
  {
    id: 202,
    key: "CovidBase2",
    name: "Covid 2",
    pathogen: Pathogens.Covid,
  },
  {
    id: 103,
    key: "FluBooster1",
    name: "Flu 21/22",
    pathogen: Pathogens.Flu,
  },
  {
    id: 203,
    key: "CovidBooster1",
    name: "Covid Booster 21/22",
    pathogen: Pathogens.Covid,
  },
  {
    id: 204,
    key: "CovidBooster1Point5",
    name: "Spring Booster 2022",
    pathogen: Pathogens.Covid,
  },
  {
    id: 110,
    key: "FluBooster2",
    name: "Flu 22/23",
    pathogen: Pathogens.Flu,
  },
  {
    id: 210,
    key: "CovidBooster2",
    name: "Covid Booster 22/23",
    pathogen: Pathogens.Covid,
  },
  {
    id: 205,
    key: "CovidBoosterSpring23",
    name: "Spring Booster 2023",
    pathogen: Pathogens.Covid,
  },
  {
    id: 120,
    key: "FluBooster3",
    name: "Flu 23/24",
    pathogen: Pathogens.Flu,
  },
  {
    id: 220,
    key: "CovidBooster3",
    name: "Covid Booster 23/24",
    pathogen: Pathogens.Covid,
  },
  {
    id: 130,
    key: "FluBooster4",
    name: "Flu 24/25",
    pathogen: Pathogens.Flu, // if changing, check VaccinationHistory.tsx
  },
  {
    id: 230,
    key: "CovidBooster4", // if changing, check VaccinationHistory.tsx
    name: "Covid Booster 24/25",
    pathogen: Pathogens.Covid,
  },
];
