import { createContext, useContext } from "react";
import { DateTime } from "luxon";
import { humanDate } from "../../../../lib/common_utils";
const INPUT_FORMAT = "yyyy-LL-dd";
const HUMAN_FORMAT = "dd/LL/yyyy";
type BoosterDateCheckerProps = {
  dateOverride?: DateTime;
  isForStaff?: boolean; // via staff list
};
type DateContextState = {
  date?: string;
  setDate?: React.Dispatch<React.SetStateAction<DateTime>>;
};
export const DateContext = createContext<DateContextState | undefined>(
  undefined
);
export const boostDateDays = 91;
// Ideally we'd figure out boostDateDays using getCurrentTrustFromMe but sticking with localstorage for now because there's no time to make it async all the way up the tree, as this value is thrown up like a beachball at a Nickleback concert. (Also we only need the ID which won't randomly change)
// useEffect(() => {
//   (async () => {
//     const currentTrust = await getCurrentTrustFromMe();
//   })();
// }, []);
export const boostDate = (selectedDate: DateTime | string): DateTime | null => {
  // 90 days after the given date, unless that's in the past, in which case today
  selectedDate =
    typeof selectedDate === "string"
      ? DateTime.fromFormat(selectedDate, INPUT_FORMAT)
      : selectedDate;
  if (!selectedDate || !selectedDate.isValid) return null;
  let date = selectedDate.plus({
    days: boostDateDays,
  });
  date = date < DateTime.now() ? DateTime.now() : date;
  // console.log("::boostDate", selectedDate.toString(), date.toString());
  return date;
};
export const isPastBoostDate = (selectedDate: string) => {
  const date = DateTime.fromFormat(selectedDate, INPUT_FORMAT);
  return (
    date?.isValid &&
    boostDate(selectedDate) &&
    DateTime.now().toMillis() >= boostDate(selectedDate).toMillis()
  );
};
const BoosterDateChecker = ({
  dateOverride,
  isForStaff = false,
}: BoosterDateCheckerProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dateContext = useContext(DateContext);
  const setDate = dateContext?.setDate;
  const dateGiven: DateTime | null = dateOverride || null;
  const defaultDate = DateTime?.isDateTime(dateGiven)
    ? dateGiven.toFormat(INPUT_FORMAT)
    : "";
  // console.log("::date", {
  //   dateGiven: dateGiven?.toISO(),
  //   dateOverride,
  //   defaultDate,
  // });
  return (
    <>
      {dateOverride && (
        <p>
          For information, we have previously recorded{" "}
          {isForStaff ? "their" : "your"} most recent Covid dose date as{" "}
          <strong>{humanDate(dateOverride)}</strong>
        </p>
      )}
    </>
  );
};
export default BoosterDateChecker;
