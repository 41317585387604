import React from "react";
import ReactDOM from "react-dom";
import DataClient from "../../../../lib/services/api/DataClient";

export default function useResendInvitesForTrust() {
  const [success, setSuccess] = React.useState<false | number>(-1);
  const [error, setError] = React.useState(false);

  const callback = (id: number) => {
    setError(false);
    setSuccess(-1);
    DataClient.postData(`/SuperAdmin/ResendInviteEmails?trustId=${id}`)
      .then((res: { sent: number; failed: number }) => {
        ReactDOM.unstable_batchedUpdates(() => {
          setSuccess(res.sent);
          if (res.failed) {
            console.warn(`${res.failed} failed to send`);
          }
        });
      })
      .catch((error) => {
        ReactDOM.unstable_batchedUpdates(() => {
          console.error(error);
          setError(true);
        });
      });
  };

  return [callback, success, error] as const;
}
