import moment from "moment";
import DataClient from "../../../../lib/services/api/DataClient";
import { catchFormErrors, request } from "../../../../lib/shared/api_client";
import { TrustWorkerDetailed } from "./TrustWorkerDetailedDTO";

export interface SetReportingRolesDTO {
  IsClinicAdmin: boolean;
  IsVaccinator: boolean;
  IsReportingViewer: boolean;
  IsReportingStaffViewer: boolean;
  AllowedLevels: {
    L1: string[];
    L2: string[];
    L3: string[];
    L4: string[];
  };
}

/**
 * Please update the Typescript typings if you work on an endpoint and know what the types should be.
 * @type {*}
 * */
const service = {
  staff: {
    list: (filter: any) =>
      DataClient.getData("/trustworker", filter).then((res) => res.results),
    add: (item: any) =>
      DataClient.postData("/trustworker", item).catch(catchFormErrors),
    edit: (item: any) =>
      DataClient.update(`/trustworker/${item.id}`, item).catch(catchFormErrors),
    deleteFromActiveCampaign: (ids: any) =>
      DataClient.remove({
        url: "/trustworker/DeleteFromActiveCampaign",
        data: ids,
      }),
    restoreUser: (userData: any, userId: any) =>
      DataClient.update(`/TrustWorker/${userId}/Restore`, userData),
    archive: (ids: any) =>
      DataClient.remove({
        url: "/trustworker/DeletePatients",
        data: ids,
      }),
    hardDeleteMany: (ids: any) =>
      DataClient.remove({
        url: "/trustworker/HardDeleteMany",
        data: ids,
      }),
    hardDeleteUser: (id: any) =>
      DataClient.remove({
        url: `/trustworker/${id}/HardDelete`,
      }),
    rejectSelfReg: (id: any) =>
      DataClient.update(`/trustworker/${id}/Reject`, {}),
    rejectSelfRegMany: (ids: any) =>
      DataClient.update(`/trustworker/RejectMany`, ids),
    approveSelfReg: (id: any) =>
      DataClient.update(`/trustworker/${id}/Approve`, {}),
    approveSelfRegMany: (ids: any) =>
      DataClient.update(`/trustworker/ApproveMany`, ids),
    restoreArchivedUser: (id: any) =>
      DataClient.update(`/trustworker/${id}/Restore`, {}),
    restoreMany: (ids: any) =>
      DataClient.update(`/trustworker/RestorePatients`, ids),
    setFrontLine: (id: any, value: any) =>
      DataClient.update(
        `/trustworker/${id}/ChangeFrontLineStatus?isFrontLine=${value}`,
        {}
      ),
    setImmform: (id: any, value: any) =>
      DataClient.update(
        `/trustworker/${id}/ChangeImmform?isImmform=${value}`,
        {}
      ),
    // e.g. values = ['isClinicAdmin']
    // NB: SetAdditionalRoles and setReportinRoles use the same endpoint
    setAdditionalRoles: (
      trustworker: TrustWorkerDetailed,
      values: any
    ): Promise<TrustWorkerDetailed> => {
      // This endpoint will overwrite any values we don't send. Its a true PUT
      return DataClient.update(
        `/trustworker/${trustworker.id}/setAdditionalRole`,
        {
          isClinicAdmin: values.includes("isClinicAdmin") || false,
          isVaccinator: values.includes("isVaccinator") || false,
          // We need all this because the backend will just overwrite anything we don't send.
          isReportingViewer: trustworker.isReportingViewer,
          isReportingStaffViewer: trustworker.isReportingStaffViewer,
          allowedLevels: {
            L1: trustworker.reportingStaffAllowedLevels.L1,
            L2: trustworker.reportingStaffAllowedLevels.L2,
            L3: trustworker.reportingStaffAllowedLevels.L3,
            L4: trustworker.reportingStaffAllowedLevels.L4,
          },
        }
      );
    },
    setReportingRoles: (
      trustworker: TrustWorkerDetailed,
      postData: SetReportingRolesDTO
    ): Promise<TrustWorkerDetailed> => {
      // This endpoint will overwrite any values we don't send. Its a true PUT
      return DataClient.update(
        `/trustworker/${trustworker.id}/setAdditionalRole`,
        postData
      );
    },
    getReportAccessLevelValues: (trustWorkerId = 51526) => {
      return Promise.all([
        DataClient.getData("/trustworker/LevelValues/l1"),
        DataClient.getData("/trustworker/LevelValues/l2"),
        DataClient.getData("/trustworker/LevelValues/l3"),
        DataClient.getData("/trustworker/LevelValues/l4"),
      ]);
    },
    updateAtRisk: (id: any, { isAtRisk, atRiskDetails }: any) =>
      DataClient.update(`/trustworker/${id}/UpdateAtRisk`, {
        isAtRisk,
        atRiskDetails,
      }),
    getDeclineReasons: () =>
      DataClient.postData("/declinereason/search", { pageSize: -1 })
        .then((res) => res.results)
        .then((declineReasons) =>
          declineReasons.filter((x: any) => !x.obsolete)
        ),
    getVaccinationTypes: (pathogenId: any) =>
      DataClient.postData(`/VaccinationType/Search`, {
        pageSize: -1,
        pathogens: [pathogenId],
      }).then((res) => res.results),
    decline: (
      id: any,
      vaccinationStatusId: any,
      description: any,
      pathogen: any,
      declineReasonId: any,
      hadElsewhereSite: any,
      hadElsewhereLocation: any,
      hadElsewhereDate: any,
      hadElsewhereVaccinationTypeId: any,
      hadElsewhereVaccinationBatchNumber: any
    ): any =>
      DataClient.update("/Reservation/RecordDecline", {
        trustWorkerId: id,
        vaccinationStatusId,
        declineReasonId,
        declineDescription: description,
        hadElsewhereSite,
        hadElsewhereLocation,
        hadElsewhereDate,
        hadElsewhereVaccinationTypeId,
        hadElsewhereVaccinationBatchNumber,
        pathogenId: pathogen,
      }),
    undoDecline: (id: any, pathogen: any) =>
      request({
        url: "/Reservation/UndoDecline",
        method: "PUT",
        params: { trustWorkerId: id, pathogenId: pathogen },
      }).client,
    exempt: (id: any, description: any, pathogen: any) =>
      DataClient.update("/Reservation/RecordExempt", {
        trustWorkerId: id,
        description: description,
        pathogenId: pathogen,
      }),
    undoExempt: (id: any, pathogen: any) =>
      request({
        url: "/Reservation/UndoExempt",
        method: "PUT",
        params: { trustWorkerId: id, pathogenId: pathogen },
      }).client,
    download: (filter = {}) =>
      DataClient.getFile({
        url: "/trustworker/export",
        filename: `VaccinationTrack Data ${moment(Date()).format(
          "MMMM Do YYYY, H:mm:ss"
        )}.csv`,
        method: "POST",
        params: { ...filter },
      }),
    downloadReport: (filter = {}) =>
      DataClient.getFile({
        url: "/trustworker/export",
        filename: `VaccinationTrack Report ${moment(Date()).format(
          "MMMM Do YYYY, H:mm:ss"
        )}.csv`,
        method: "POST",
        params: { ...filter },
      }),
    downloadBookingDetails: (filter: any = {}) =>
      DataClient.getFile({
        url: "/Reservation/ExportAllBookings",
        filename: `VaccinationTrack Booking Details (${
          filter.clinicId
        }) ${moment(Date()).format("MMMM Do YYYY, H:mm:ss")}.csv`,
        method: "GET",
        params: { ...filter },
      }),
    sendInviteEmail: (id: any) =>
      DataClient.postData(`/TrustWorker/${id}/ResendInviteEmail`),
    getLookups: () =>
      Promise.all(
        [
          "assignmentStatus",
          "jobRole",
          "nonEsrStaffType",
          "staffGroup",
          "gender",
          "ethnicity",
        ].map((l) =>
          DataClient.postData(`/${l}/search`, { pageSize: -1 }).then((r) => ({
            name: l,
            values: r.results,
          }))
        )
      ).then((r) =>
        r.reduce((o, i) => {
          o[i.name] = i.values;
          return o;
        }, {})
      ),
    removeReservation: (id: any, reschedule: any) =>
      DataClient.remove({
        url: `/Reservation/${id}`,
        data: { rescheduleFlag: reschedule ? 1 : 0 },
      }),
    clearEmailInDoubt: (id: any) =>
      DataClient.update(`/TrustWorker/${id}/ClearEmailInDoubt`, {}),
    clearPossibleDuplicate: (id: any) =>
      DataClient.update(`/TrustWorker/${id}/ClearPossibleDuplicates`, {}),
    getDuplicateDetails: (id: any) =>
      DataClient.getData("/TrustWorker/" + id + "/GetDuplicateDetails"),
  },
};

export default service;
