import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
} from "@material-ui/core";
import {
  KeyboardBackspace as BackIcon,
  Cancel as CancelIcon,
} from "@material-ui/icons";
import { Alert, AlertTitle } from "@material-ui/lab";
import cloneDeep from "lodash/cloneDeep";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import ApiAlert from "../../../components/ApiAlert";
import { parseFieldsIsoDates } from "../../../components/Fields";
import { Flag } from "../../../components/FlagIcons";
import {
  currentDate,
  currentTime,
  filterObjectKeys,
  humanName,
  removeEmptyValues,
  removeZeroFields,
  toZeroOffsetMidnightIsoString,
} from "../../../lib/common_utils";
import {
  enumPrescribingMethods,
  enumToInputOptions,
  enumVaccinationStatus,
} from "../../../lib/enums";
import { Pathogens, shouldShowClinicianNames } from "../../../lib/pathogens";
import DataClient from "../../../lib/services/api/DataClient";
import { validateNhsNumber } from "../../../lib/validationRules";
import Checkbox from "../Patients/BookAppointment/Checkbox";
import {
  VaccineBatch,
  vaccineBatchInformalName,
} from "./Clinics/VaccineBatches";
import "./VaccinationDetails.scss";
import { fetchVaccinators, selectOptions } from "./Vaccinators";
import KeyboardDatePickerDateOnly from "../../../components/KeyboardDatePickerDateOnly";
export function VaccinationDetails(props: {
  clinicId?: string | number;
  pathogenId: string;
  pathogenName?: string;
  unplannedVaccination?: string;
  trustWorkerId?: string | number;
  trustWorkerName?: string | number;
  trustWorkerDob?: string;
  trustWorkerFlags?: string[];
  closeCallback?: (...args: any[]) => void;
  showSendVaccinationConfirmation?: boolean;
}): JSX.Element {
  let clinicId = props.clinicId ? Number(props.clinicId) : null;
  const [hasReservation, setHasReservation] = useState(false);
  const pathogenId = parseInt(props.pathogenId);
  const pathogenName = props.pathogenName || null;
  const unplannedVaccination = props.unplannedVaccination || false;
  const trustWorkerId = props.trustWorkerId
    ? Number(props.trustWorkerId)
    : null;
  const trustWorkerName = props.trustWorkerName || null;
  const trustWorkerDob = props.trustWorkerDob || null;
  const trustWorkerFlags = props.trustWorkerFlags || [];
  console.log(`trustWorkerFlags: `, trustWorkerFlags);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState(null);
  const [initialRes, setInitialRes] = useState(null);
  const [vaccinationTypes, setVaccinationTypes] = useState(null);
  const [vaccineBatches, setVaccineBatches] = useState([]);
  const [vaccinatorNames, setVaccinatorNames] = useState(null);
  const [injectionSites, setInjectionSites] = useState(null);
  const [reservationDetails, setReservationDetails] = useState(null);
  const [consentQuestionAnswers, setConsentQuestionAnswers] = useState(null);
  const [exclusionQuestionAnswers, setExclusionQuestionAnswers] =
    useState(null);
  const [popupConfirmation, setPopupConfirmation] = useState(null);
  const [vaccinationStatusId, setVaccinationStatusId] = useState(null);
  const [vaccinationStatusIdPrevious, setVaccinationStatusIdPrevious] =
    useState(null);
  const [showCustomVaccinatorInput, setShowCustomVaccinatorInput] =
    useState(false);
  const {
    getValues,
    handleSubmit,
    register,
    reset,
    formState,

    setValue,
    control,
  } = useForm({
    // shouldUseNativeValidation: false,
    reValidateMode: "onChange",
  });
  const showClinicianNames = shouldShowClinicianNames(pathogenId);
  const hadElsewhereSites = [
    {
      label: "GP Practice",
      locationLabel: "GP Practice Name",
    },
    {
      label: "Pharmacy",
      locationLabel: "Pharmacy Name",
    },
    {
      label: "Other NHS Trust",
      locationLabel: "Name of other NHS Trust",
    },
    {
      label: "Vaccination Centre",
      locationLabel: "Vaccination Centre Name",
    },
    {
      label: "Recorded on NIVS",
      locationLabel: undefined,
    },
    {
      label: "Unknown",
      locationLabel: undefined,
    },
  ]
    .map((s) => ({ ...s, value: s.label }))
    .reduce(
      (p, c) => ({ ...p, [c.value]: c }),
      {} as {
        [key: string]: { label: string; locationLabel?: string };
      },
    );
  useEffect(
    function getInitialRes() {
      // Promises, promises..
      Promise.all([
        // NB: this 'get' is actully a POST with params(!)
        DataClient.postData(
          `/Reservation/GetPatientDetails`,
          {},
          {
            trustWorkerId,
            pathogen: pathogenId,
          },
        ),
        DataClient.postData(`/VaccinationType/Search`, {
          pageSize: -1,
          pathogens: [pathogenId],
        }),
        fetchVaccinators(),
        DataClient.postData(`/InjectionSite/Search`, {
          searchText: "",
          pageSize: -1,
        }),
        DataClient.getData(`/me`), // trying to make vaccinator default to current user. currentTrust.roles: ["Vaccinator"] is what we're looking for. currentTrust.trustWorkerName yet to come
        DataClient.postData("/declinereason/search", { pageSize: -1 }),
      ])
        .then(async (res) => {
          // Handle API failures
          res.forEach(({ success }, i) => {
            if (!success && i !== 4) {
              // because /me doesn't have success: true because.. consistency is not king apparently
              throw new Error(`Failed to fetch correct data (Request #${i})`);
            }
          });
          const patientDetails = (res[0]?.results || {}) as PatientDetails;
          const vaccinationTypes = res[1]?.results || [];
          const vaccinatorNames =
            res[2]?.results?.pagedResults?.map(
              ({
                firstName,
                lastName,
              }: {
                firstName: string;
                lastName: string;
              }) => humanName(firstName, lastName),
            ) || [];
          const injectionSites = res[3]?.results || [];
          const patientCampaignId = patientDetails?.patientCampaignId;
          let reservationDetails: any = {};
          if (patientCampaignId) {
            const response = await DataClient.getData(
              `/Reservation/MyAppointments/${patientCampaignId}`,
            );
            // Get the reservation details specifically of one that involves the current pathogen
            reservationDetails = response?.success
              ? response?.results?.reservations?.find((r: any) =>
                  r.pathogens?.some((p: any) => p?.id === pathogenId),
                )
              : {};
          }
          const me = res[4];
          const declineReasons = res[5].results.filter((x: any) => !x.obsolete);
          let clinic = {};
          let vaccineBatches = [];
          let defaultBatch: VaccineBatch = null;
          // Clinic Id comes from props if viewing within clinic details booking, or reservation if staff list for a booked user
          clinicId = reservationDetails?.clinicId || clinicId;
          if (clinicId) {
            // IF possible, get clinic details (to prefill vaccine batch number, expiry etc)
            const resClinic = await DataClient.getData(`/Clinic/${clinicId}`);
            clinic =
              resClinic?.success && resClinic?.results
                ? resClinic?.results
                : {};
            const resBatch = await DataClient.postData(
              `/Clinic/${clinicId}/GetAllClinicBatchDetails`,
            );
            vaccineBatches = resBatch?.results?.filter(
              (x: VaccineBatch) => x.pathogenId === pathogenId,
            );
            // If no batch was set as default, assume the first
            defaultBatch = vaccineBatches?.find(
              (b: VaccineBatch) => b.isDefault,
            );
            if (vaccineBatches?.length && !defaultBatch) {
              defaultBatch = vaccineBatches[0];
              defaultBatch.isDefault = true;
            }
          }
          // Awaiting BB todo for the flag
          setInitialRes({
            patientDetails,
            vaccinationTypes,
            vaccinatorNames,
            injectionSites,
            me,
            clinic,
            clinicId,
            reservationDetails,
            declineReasons,
            vaccineBatches,
            defaultBatch,
          });
        })
        .catch((e) => {
          // API not returning what we expect
          setAlert(
            ApiAlert({ res: e.response || e.message, alwaysShow: true }),
          );
          setLoading(false);
        });
    },
    [trustWorkerId, pathogenId, clinicId],
  );
  // Set states and the form when all data has come in, with various client requests
  useEffect(() => {
    if (!initialRes) return;
    // console.log("::initialRes", initialRes);
    const {
      patientDetails,
      vaccinationTypes,
      injectionSites,
      reservationDetails,
      vaccinatorNames,
      vaccineBatches,
      defaultBatch,
    } = initialRes;
    // Use values from DB - worth a go! should be largely 1:1
    let resetData = cloneDeep(patientDetails);
    // Remove null values (or valueAsNumber inputs get set to 0, messing with 'please select' options)
    resetData = removeEmptyValues(resetData);
    // Parse any detected API dates for date inputs
    resetData = parseFieldsIsoDates(resetData);
    // Consent questions set as a state for rendering
    if (resetData.consentQuestionAnswers) {
      setConsentQuestionAnswers(resetData.consentQuestionAnswers);
    }
    if (resetData.exclusionQuestionAnswers) {
      setExclusionQuestionAnswers(resetData.exclusionQuestionAnswers);
    }
    // Always true: "It should always be ticked - as a staff member isn't able to book without accepting these!!"
    resetData.consentAlreadyGiven = true;
    // "All other vaccinators in alphabetical order"
    const _hasReservation = !!reservationDetails;
    // const hasReservation = !!reservationDetails?.reservations?.some(
    //   (r: any) => r?.pathogens?.some((p: any) => p?.id === pathogenId)
    // );
    setHasReservation(_hasReservation);
    vaccinatorNames.sort();
    setVaccinatorNames(vaccinatorNames);
    // Attendance Status "Booked (defaults to this)" for vaccinators within clinic details
    // Update: I'm removing as it doesn't make sense. When editing vaccination details you don't control whether they have a booking or not.
    // resetData.vaccinationStatusId =
    //   resetData.vaccinationStatusId || !!clinicId
    //     ? enumVaccinationStatus["Booked"]
    //     : enumVaccinationStatus["No Booking"];
    // Attendance Status: If API reports being "booked", but doesn't actually have a reservation
    // (which happens somehow, when adding staff) set it to be "Not Booked" (unless I'm misunderstanding this status vs reservations)
    if (
      !_hasReservation &&
      resetData.vaccinationStatusId === enumVaccinationStatus["Booked"]
    ) {
      resetData.vaccinationStatusId = enumVaccinationStatus["Not Booked"];
    }
    setVaccinationStatusId(resetData.vaccinationStatusId); // set state for onChange popupConfirmation reasons
    // Checkbox style <select> elements:
    // Cast isFrontLine from boolean to bit
    resetData.isFrontLine = ~~resetData.isFrontLine;
    // Cast consentToVaccination from boolean to bit (or empty string)
    resetData.consentToVaccination = ~~resetData.consentToVaccination || "";
    // dd#175 "The check box should default to being ticked but if it's unticked"
    if (props.showSendVaccinationConfirmation) {
      resetData.sendVaccinationConfirmation = true;
    }
    // Set default vaccineBatches selection to whichever isDefault
    // Or if a vaccine details are present: try to match batch details, defaulting to "please select" if no batch could be found
    const currentVaccinationBatchNumber =
      initialRes.patientDetails.vaccinationBatchNumber;
    const currentVaccineBatch = vaccineBatches.find(
      (vaccineBatch: VaccineBatch) => {
        return (
          vaccineBatch.vaccinationType?.name ===
            initialRes.patientDetails.vaccinationTypeName &&
          vaccineBatch.vaccinationBatchNumber ===
            initialRes.patientDetails.vaccinationBatchNumber &&
          vaccineBatch.expiryDate === initialRes.patientDetails.expiryDate
        );
      },
    );
    if (currentVaccinationBatchNumber && currentVaccineBatch) {
      resetData.vaccineBatches = currentVaccineBatch.id;
    } else if (currentVaccinationBatchNumber) {
      resetData.vaccineBatches = null;
    } else {
      resetData.vaccineBatches = defaultBatch?.id;
    }
    console.log("::resetData init", resetData);
    reset(resetData);
    console.log(`vaccinationTypes: `, vaccinationTypes);
    setVaccinationTypes(vaccinationTypes);
    setInjectionSites(injectionSites);
    setReservationDetails(reservationDetails);
    setVaccineBatches(vaccineBatches);
    setLoading(false);
  }, [initialRes, reset]);
  const preFill = ({
    changedField,
    changedValue,
  }: {
    changedField?: string;
    changedValue?: any;
  }) => {
    // On changing attendance status to *Vaccinated*, we want to:
    // - Prefill vaccine batch number, expiry, etc
    const { patientDetails, vaccinatorNames, me, clinic } = initialRes || {};
    const vaccinationBatchInfo = clinic.vaccinationBatchInfos?.find(
      (b: any) => b.pathogenId === pathogenId,
    );
    const prevVaccinationStatusId = getValues("vaccinationStatusId");
    // console.log("::preFill", {
    //   changedField,a
    //   changedValue,
    //   patientDetails,
    //   vaccinationBatchInfo,
    // });
    const resetData: any = {};
    if (changedField) {
      resetData[changedField] = changedValue;
    }
    // #230 The way this works is that when you change the attendance status it essentially resets the whole form, then merges in patient details (for NHS number etc), clinic information (vaccine batch number etc), vaccinator choices, injection sites, etc etc, and finally the consent responses from the previous form state. 👍
    let resetMerge = getValues();
    if (changedField == "vaccinationStatusId") {
      // If you change the status from anything to vaccinated to will fill the vaccinator d/down with the current user and fill in other vaccination details with todays date and such.
      if (changedValue == enumVaccinationStatus["Vaccinated"]) {
        if (
          // Vaccinator name defaults to current user (if they're a vaccinator, and one isn't already stored):
          // Or, also, if they're a clinic admin apparently (see Slack "RE: 4645") [part 1 of 2]
          (me?.currentTrust?.roles?.includes("Vaccinator") ||
            me?.currentTrust?.roles?.includes("ClinicAdmin")) &&
          (me.currentTrust.firstName || me.currentTrust.lastName) &&
          !patientDetails.vaccinatorName
        ) {
          // Set vaccinatorName value
          resetData.vaccinatorName = `${me?.currentTrust?.firstName || ""} ${
            me?.currentTrust?.lastName || ""
          }`.trim();
        }
        // Vaccination date / time default to current
        resetData.vaccinationDate =
          patientDetails.vaccinationDate || currentDate();
        resetData.vaccinationTime =
          patientDetails.vaccinationTime || currentTime();
        setVaccinatorNames(vaccinatorNames);
      }
      // If you change from any status, to any (not vaccinated) status it will fill the vaccinator d/down with the current user and remove the other vaccination details
      else {
        if (
          // Vaccinator name defaults to current user (if they're a vaccinator, and one isn't already stored):
          // Or, also, if they're a clinic admin apparently (see Slack "RE: 4645") [part 2 of 2]
          (me?.currentTrust?.roles?.includes("Vaccinator") ||
            me?.currentTrust?.roles?.includes("ClinicAdmin")) &&
          (me.currentTrust.firstName || me.currentTrust.lastName) &&
          !patientDetails.vaccinatorName
        ) {
          // Set vaccinatorName value
          resetData.vaccinatorName = `${me?.currentTrust?.firstName || ""} ${
            me?.currentTrust?.lastName || ""
          }`.trim();
        }
        // If anything other than 'Vaccinated', empty all the fields above again
        // Note: Removed vaccine field resets as they generally persist regardless via vaccineBatchMergeFields
        resetData.vaccinationDate = null;
        resetData.vaccinationTime = null;
      }
      // If you change from vaccinated to any other status it will keep the vaccinator name the same, but reset the other vaccination details.
      if (prevVaccinationStatusId == enumVaccinationStatus["Vaccinated"]) {
        // dd#4645 Specifically keep the vaccinator name so its not overriden by the stored data on reset.
        // Note: Removed vaccine field resets as they generally persist regardless via vaccineBatchMergeFields
        resetData.vaccinatorName = getValues("vaccinatorName");
        resetData.vaccinationDate = null;
        resetData.vaccinationTime = null;
      }
      // Is Frontline? defaults to that of the patient details (which by default is yes), cast from boolean to bit
      resetData.isFrontLine = ~~patientDetails.isFrontLine;
      // #230 Given consent answers to persist between status change (overriding db)
      resetData.consentQuestionAnswers = getValues("consentQuestionAnswers");
      resetData.exclusionQuestionAnswers = getValues(
        "exclusionQuestionAnswers",
      );
      // #291 Notes to persist between status change
      resetData.otherInformation = getValues("otherInformation");
      // Merge current form values + db info + prefills above
      resetMerge = { ...resetMerge, ...patientDetails, ...resetData };
    }
    if (changedField == "vaccineBatches") {
      resetMerge[changedField] = changedValue.toString();
    }
    // Vaccine fields generally persist through resets
    const vaccineBatchMergeFields = [
      "vaccinationBatchNumber",
      "expiryDate",
      "defrostDate",
      "vaccinationTypeId",
      "clinicianName",
    ];
    const vaccineBatch: VaccineBatch =
      changedField == "vaccineBatches"
        ? vaccineBatches.find((b) => b.id == changedValue)
        : vaccineBatches.find((b) => b.id == getValues("vaccineBatches"));
    const vaccineBatchFields = filterObjectKeys(
      vaccineBatch,
      vaccineBatchMergeFields,
    );
    resetMerge = { ...resetMerge, ...vaccineBatchFields };
    // .. Except retain consent as it's required client-side but not actually in the data
    resetMerge.consentAlreadyGiven = getValues().consentAlreadyGiven;
    // console.log("::resetData change!", {
    //   getValues: getValues(),
    //   patientDetails,
    //   resetData,
    //   vaccineBatchFields,
    //   resetMerge,
    // });
    reset(resetMerge);
  };

  const ErrorMessage = (err: any) =>
    err?.message ? <p className="error">{err.message}</p> : null;
  const onSubmit = (data: any) => {
    // console.log("::onSubmit", data);
    // Remove null values and bung it into a call, they should match 1:1
    data = removeEmptyValues(data);
    // Parse dates into API format
    data.vaccinationDate = toZeroOffsetMidnightIsoString(data.vaccinationDate);
    // Remove "Please Select" (value 0) option for given valueAsNumber select
    data = removeZeroFields({
      data,
      fieldsThatDontWantZero: [
        // "vaccinationStatusId", // no 'please select' option
        "consentToVaccination",
        "injectionSiteId",
        "vaccinationTypeId",
        "declineReasonId",
        "clinicianName",
        // "isFrontLine", // handled later
      ],
    });
    // Checkbox style <select> elements:
    // This select box is redundant, mandatory, and inappropriate. anyways, cast it from bit to boolean
    if (Number.isInteger(data.consentToVaccination)) {
      data.consentToVaccination = data.consentToVaccination === 1;
    }
    // Cast isFrontLine from bit to boolean (but only if option was selected)
    if (Number.isInteger(data.isFrontLine)) {
      data.isFrontLine = data.isFrontLine === 1;
    }
    if (!props.showSendVaccinationConfirmation) {
      data.sendVaccinationConfirmation = true;
    }
    // console.log("::onSubmit postData", data);
    setLoading(true);
    DataClient.postData(`/Reservation/AddPatientDetails`, {
      trustWorkerId,
      pathogenId,
      unplannedVaccination,
      ...data,
    })
      .then((res) => {
        setLoading(false);
        if (res.success) {
          return setAlert(
            ApiAlert({
              title: "Vaccination record updated",
              message: (
                <>
                  <Button
                    to={clinicId ? `/clinics/${clinicId}/view` : `/staff`}
                    onClick={(e: React.MouseEvent) => {
                      props.closeCallback && props.closeCallback(true);
                    }}
                    component={Link}
                    variant="contained"
                    startIcon={<BackIcon />}
                  >
                    Return
                  </Button>
                </>
              ),
              severity: "success",
              alwaysShow: true,
            }),
          );
        }
        // Validation catch
        return setAlert(ApiAlert({ res }));
      })
      .catch((e) => {
        // Internal server error catch
        setAlert(ApiAlert({ res: e.response }));
        setLoading(false);
      });
  };
  const vaccinationStatusABNV =
    getValues("vaccinationStatusId") ===
    enumVaccinationStatus["Attended But Not Vaccinated"];
  const vaccinationStatusHadElsewhere =
    getValues("vaccinationStatusId") === enumVaccinationStatus["Had Elsewhere"];
  const vaccinationStatusDeclined =
    getValues("vaccinationStatusId") === enumVaccinationStatus["Declined"];
  // Attendance status: Had Elsewhere, or various Declined, "Please provide details text box appear"
  const vaccinationStatusElsewhereOrDeclined =
    vaccinationStatusHadElsewhere || vaccinationStatusDeclined;
  // "Attendance status: Vaccinated (if selected all other drop downs and text fields below appear)"
  const vaccinationStatusVaccinated =
    getValues("vaccinationStatusId") === enumVaccinationStatus["Vaccinated"];
  // "Vaccinated (if selected all other drop downs and text fields below appear)" ..
  // .. "Attended But Not Vaccinated or DNA (if selected, just ‘Other Info’ and ‘Time Stamp’ appear below)"
  // .. DNA: "Good question. I don't think we need to record it"
  const vaccinationStatusVaccinatedOrAttended = [
    enumVaccinationStatus["Vaccinated"],
    enumVaccinationStatus["Attended But Not Vaccinated"],
  ].includes(getValues("vaccinationStatusId"));
  const vaccinationStatusBooked = [enumVaccinationStatus["Booked"]].includes(
    getValues("vaccinationStatusId"),
  );
  // "95% of the time the status will be changed from ‘Booked' to ‘Vaccinated’. When any other attendance status is recorded have a pop up asking if they are sure"
  const vaccinationStatusBookedOrVaccinated = [
    enumVaccinationStatus["Booked"],
    enumVaccinationStatus["Vaccinated"],
  ].includes(getValues("vaccinationStatusId"));
  const disallowedVaccinationStatuses = [
    enumVaccinationStatus["Declined (allergy)"],
    enumVaccinationStatus["Declined (doesn't want a vaccine)"],
    enumVaccinationStatus["Declined (other contraindication)"],
    enumVaccinationStatus["Exempt"],
    enumVaccinationStatus["Had In Another Trust"],
  ];
  const hadElsewhereSite = getValues("hadElsewhereSite");
  useEffect(() => {
    if (
      getValues("vaccinationStatusId") &&
      getValues("vaccinationStatusId") !== vaccinationStatusId
    ) {
      setVaccinationStatusIdPrevious(vaccinationStatusId);
      setVaccinationStatusId(getValues("vaccinationStatusId"));
      if (!vaccinationStatusBookedOrVaccinated && !popupConfirmation) {
        setPopupConfirmation(
          <p>
            Are you sure you want to record the staff member as{" "}
            <strong>
              {enumVaccinationStatus[getValues("vaccinationStatusId")]}
            </strong>
            ?
          </p>,
        );
      }
    }
  }, [
    setVaccinationStatusId,
    setPopupConfirmation,
    getValues,
    popupConfirmation,
    vaccinationStatusId,
    vaccinationStatusBookedOrVaccinated,
  ]);
  const undoVaccinationStatusId = () => {
    reset({ ...getValues(), vaccinationStatusId: vaccinationStatusIdPrevious });
  };
  return (
    <div className="wrapper">
      <Dialog
        open={true}
        fullWidth={true}
        maxWidth={false}
        className="dialog vaccination-details"
        data-theme-id={pathogenId}
      >
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <div className="dialog__inner ">
            <aside className="form">
              <header>
                <h2>
                  <em>{pathogenName}</em>
                </h2>
              </header>
              <div className="content">
                <section className="section-border">
                  <h2>{pathogenName} Pre-Screening Statements</h2>
                  {/* <Checkbox
                    key={`consentAlreadyGiven`}
                    id={`consentAlreadyGiven`}
                    label={"Pre-booking exclusions agreed"}
                    register={register}
                    required={true}
                  /> */}
                  {exclusionQuestionAnswers?.map(
                    ({ question }: ExclusionQuestionAnswer, i: number) => (
                      <section key={`exclusionQuestionAnswers.${i}.answer`}>
                        <Checkbox
                          id={`exclusionQuestionAnswers.${i}.answer`}
                          label={question}
                          register={register}
                          registerOptions={{
                            required: {
                              // #4046 "It should now only care about all pre-screening Qs being ticked if you are saving the status as Vaccinated"
                              value: vaccinationStatusVaccinated,
                              message:
                                "All pre-screening statements must be agreed to",
                            },
                          }}
                        />
                        {ErrorMessage(
                          formState.errors.exclusionQuestionAnswers &&
                            formState.errors.exclusionQuestionAnswers[i]
                              ?.answer,
                        )}
                      </section>
                    ),
                  )}
                </section>
                <section>
                  <h2>{pathogenName} Consent Statements</h2>
                  {consentQuestionAnswers?.map(
                    ({ question }: ConsentQuestionAnswer, i: number) => (
                      <section key={`consentQuestionAnswers.${i}.answer`}>
                        <Checkbox
                          id={`consentQuestionAnswers.${i}.answer`}
                          label={question}
                          register={register}
                        />
                      </section>
                    ),
                  )}
                </section>
              </div>
            </aside>
            <div className="form">
              <header>
                <div>
                  <h2>
                    {trustWorkerName || ""}{" "}
                    {trustWorkerDob ? `(${trustWorkerDob})` : ""}
                  </h2>
                  {trustWorkerFlags.map((flagType) => (
                    <Flag key={flagType} type={flagType} size="large" />
                  ))}
                </div>

                <Button
                  to={clinicId ? `/clinics/${clinicId}/view` : `/staff`}
                  onClick={(e: React.MouseEvent) => {
                    props.closeCallback && props.closeCallback(true);
                  }}
                  component={Link}
                  color="primary"
                  endIcon={<CancelIcon />}
                >
                  Close
                </Button>
              </header>
              <div className="content">
                {unplannedVaccination && (
                  <section>
                    <Alert severity="info">
                      <AlertTitle>Warning</AlertTitle>
                      <p>
                        Please be aware that this clinic does not typically
                        provide {pathogenName} vaccinations.
                      </p>
                    </Alert>
                  </section>
                )}
                <fieldset className="fieldset-half">
                  <section>
                    <label>{pathogenName} Attendance Status *</label>
                    <select
                      {...register("vaccinationStatusId", {
                        valueAsNumber: true,
                        required: "Please choose an option",
                      })}
                      {...{
                        onChange: (e) => {
                          preFill({
                            changedField: "vaccinationStatusId",
                            changedValue: parseInt(e.target.value),
                          });
                        },
                      }}
                    >
                      {enumToInputOptions(enumVaccinationStatus)
                        .filter(
                          ({ value }) =>
                            !disallowedVaccinationStatuses.includes(value),
                        )
                        .map(({ label, value }) => {
                          if (
                            // No Booking: Hide if has reservation
                            (value === enumVaccinationStatus["No Booking"] &&
                              hasReservation) ||
                            // Booked: Hide if no reservation (otherwise pre-selected within clinic detail for vaccinators)
                            (value === enumVaccinationStatus["Booked"] &&
                              !hasReservation) ||
                            //
                            // Update:MAYBE always show as client is complaining it's gone. People are often marked as 'booked' but don't actually have reservations, so I dunno
                            //
                            // DNA: Hide if no reservation
                            // BUT retain if the value is somehow set
                            (value === enumVaccinationStatus["DNA"] &&
                              !hasReservation &&
                              getValues("vaccinationStatusId") !==
                                enumVaccinationStatus["DNA"]) ||
                            // Attended But Not Vaccinated: Hide if no reservation
                            // BUT retain if the value is somehow set
                            (value ===
                              enumVaccinationStatus[
                                "Attended But Not Vaccinated"
                              ] &&
                              !hasReservation &&
                              getValues("vaccinationStatusId") !==
                                enumVaccinationStatus[
                                  "Attended But Not Vaccinated"
                                ])
                            // All the rest: Always visible
                            // TODO: **  NB: If reservation somehow has an invalid status, allow that to be displayed as a disabled option
                          )
                            return null;
                          return (
                            <option
                              key={`vaccinationStatusId-${value}`}
                              value={value}
                            >
                              {label}
                            </option>
                          );
                        })}
                    </select>
                    {ErrorMessage(formState.errors.vaccinationStatusId)}
                  </section>
                  <section
                    className="hide-on-data-disabled"
                    data-disabled={!vaccinationStatusDeclined}
                  >
                    <label>Decline Reason *</label>
                    <select
                      {...register("declineReasonId", {
                        valueAsNumber: true,
                        required:
                          vaccinationStatusDeclined &&
                          "Please provide a reason for decline",
                      })}
                      {...{
                        disabled:
                          !vaccinationStatusDeclined && !popupConfirmation,
                      }}
                    >
                      <option value="">Please Select</option>
                      {initialRes?.declineReasons.map(
                        (i: { id: string; name: string }) => (
                          <option key={`declineReasonId-${i.id}`} value={i.id}>
                            {i.name}
                          </option>
                        ),
                      )}
                    </select>
                    {ErrorMessage(formState.errors.declineReasonId)}
                  </section>
                  <section
                    className="hide-on-data-disabled"
                    data-disabled={!vaccinationStatusHadElsewhere}
                  >
                    <label>Vaccination Site *</label>
                    <select
                      {...register("hadElsewhereSite", {
                        required:
                          vaccinationStatusHadElsewhere &&
                          "Please provide a vaccination site",
                      })}
                      {...{
                        disabled:
                          !vaccinationStatusHadElsewhere && !popupConfirmation,
                      }}
                    >
                      <option value={null}>Please Select</option>
                      {Object.entries(hadElsewhereSites).map(([key, value]) => (
                        <option key={`hadElsewhereSite-${key}`} value={key}>
                          {value.label}
                        </option>
                      ))}
                    </select>
                    {ErrorMessage(formState.errors.hadElsewhereSite)}
                  </section>
                  <section
                    className="hide-on-data-disabled"
                    data-disabled={
                      !(
                        vaccinationStatusHadElsewhere &&
                        hadElsewhereSites[hadElsewhereSite]?.locationLabel
                      )
                    }
                  >
                    <label>
                      {hadElsewhereSites[hadElsewhereSite]?.locationLabel}
                    </label>
                    <input
                      {...register("hadElsewhereLocation")}
                      {...{
                        disabled:
                          !(
                            vaccinationStatusHadElsewhere &&
                            hadElsewhereSites[hadElsewhereSite]?.locationLabel
                          ) && !popupConfirmation,
                      }}
                    />
                    {ErrorMessage(formState.errors.hadElsewhereLocation)}
                  </section>
                  <section
                    //  I'm reticent to actually remove this from the DOM..
                    className="hide-on-data-disabled"
                    data-disabled={!vaccinationStatusElsewhereOrDeclined}
                  >
                    <label>
                      Please Provide Details
                      {vaccinationStatusElsewhereOrDeclined && " *"}
                    </label>
                    <textarea
                      {...register("details", {
                        required:
                          vaccinationStatusElsewhereOrDeclined &&
                          "Please provide details",
                      })}
                      {...{
                        disabled:
                          !vaccinationStatusElsewhereOrDeclined &&
                          !popupConfirmation,
                      }}
                    />
                    {ErrorMessage(formState.errors.details)}
                  </section>
                  <section data-disabled={!vaccinationStatusVaccinated}>
                    <label>{pathogenName} Consent Given *</label>
                    <select
                      {...register("consentToVaccination", {
                        valueAsNumber: true,
                        required:
                          vaccinationStatusVaccinated &&
                          "Consent must be given",
                      })}
                      {...{
                        disabled:
                          !vaccinationStatusVaccinated && !popupConfirmation,
                      }}
                    >
                      <option value="">Please Select</option>
                      <option value={1}>Yes</option>
                    </select>
                    {ErrorMessage(formState.errors.consentToVaccination)}
                  </section>
                  <section data-disabled={!vaccinationStatusVaccinated}>
                    <label>Injection Site</label>
                    <select
                      name="injectionSiteId"
                      {...register("injectionSiteId", {
                        valueAsNumber: true,
                      })}
                      {...{
                        disabled:
                          !vaccinationStatusVaccinated && !popupConfirmation,
                      }}
                    >
                      <option value="">Please Select</option>
                      {injectionSites?.map(
                        ({ id, name }: { id: string; name: string }) => (
                          <option
                            key={`injectionSiteId-${id}`}
                            value={parseInt(id)}
                          >
                            {name}
                          </option>
                        ),
                      )}
                    </select>
                    {ErrorMessage(formState.errors.injectionSiteId)}
                  </section>
                  <section data-disabled={!vaccinationStatusVaccinated}>
                    <label>Known Allergies</label>
                    <input
                      {...register("allergyStatus")}
                      {...{
                        disabled:
                          !vaccinationStatusVaccinated && !popupConfirmation,
                      }}
                    />
                    {ErrorMessage(formState.errors.allergyStatus)}
                  </section>
                  <section
                    className="section-double"
                    data-disabled={!vaccinationStatusVaccinated}
                  >
                    <label></label>
                    <div>
                      <label style={{ fontWeight: 600 }}>NHS Number</label>
                      <input
                        {...register("nhsNumber", {
                          // If attendance status Vaccinated,
                          // and actually providing a number (empty is valid)
                          validate:
                            vaccinationStatusVaccinated &&
                            getValues("nhsNumber")?.length &&
                            validateNhsNumber,
                        })}
                        {...{
                          disabled:
                            !vaccinationStatusVaccinated && !popupConfirmation,
                        }}
                      />
                      {ErrorMessage(formState.errors.nhsNumber)}
                    </div>
                    <div>
                      <label style={{ fontWeight: 600 }}>Is Frontline?</label>
                      <select
                        {...register("isFrontLine", {
                          valueAsNumber: true,
                        })}
                        {...{
                          disabled:
                            !vaccinationStatusVaccinated && !popupConfirmation,
                        }}
                      >
                        <option value={1}>Yes</option>
                        <option value={0}>No</option>
                      </select>
                      {ErrorMessage(formState.errors.isFrontLine)}
                    </div>
                  </section>
                  <section data-disabled={!vaccinationStatusVaccinated}>
                    <label>Prescribing Method</label>
                    <select
                      {...register("prescribingMethodId", {
                        valueAsNumber: true,
                      })}
                    >
                      <option value="">Please Select</option>
                      {Object.entries(enumPrescribingMethods)
                        .filter(([key, value]) => {
                          return isNaN(Number(key));
                        })
                        .map(([key, value]) => (
                          <option value={value}>{key}</option>
                        ))}
                    </select>
                    {ErrorMessage(formState.errors.isFrontLine)}
                  </section>
                </fieldset>
                <fieldset className="fieldset-half">
                  {hasReservation && ( // Can't get prefill options without being tied to a clinic booking
                    <section
                      data-disabled={
                        !vaccinationStatusVaccinated &&
                        !vaccinationStatusHadElsewhere
                      }
                    >
                      <label>
                        Select Vaccine Batch (or manually edit details below)
                      </label>
                      <select
                        name="vaccineBatches"
                        {...register("vaccineBatches")}
                        {...{
                          disabled:
                            !vaccinationStatusVaccinated &&
                            !vaccinationStatusHadElsewhere &&
                            !popupConfirmation,
                          onChange: (e) => {
                            preFill({
                              changedField: "vaccineBatches",
                              changedValue: e.target.value,
                            });
                          },
                        }}
                      >
                        <option value="">Please Select</option>
                        {vaccineBatches?.map((b) => (
                          <option
                            key={`vaccineBatches-${b.id}`}
                            value={b.id.toString()}
                          >
                            {vaccineBatchInformalName(b)}
                          </option>
                        ))}
                      </select>
                    </section>
                  )}
                  <section
                    data-disabled={
                      !vaccinationStatusVaccinated &&
                      !vaccinationStatusHadElsewhere
                    }
                  >
                    <label>Vaccine Type</label>
                    <select
                      name="vaccinationTypeId"
                      {...register("vaccinationTypeId", {
                        valueAsNumber: true,
                      })}
                      {...{
                        disabled:
                          !vaccinationStatusVaccinated &&
                          !vaccinationStatusHadElsewhere &&
                          !popupConfirmation,
                      }}
                    >
                      <option value="">Please Select</option>
                      {vaccinationTypes?.map(
                        ({ id, name }: { id: string; name: string }) => (
                          <option
                            key={`vaccineTypeId-${id}`}
                            value={parseInt(id)}
                          >
                            {name}
                          </option>
                        ),
                      )}
                    </select>
                    {ErrorMessage(formState.errors.vaccinationTypeId)}
                  </section>
                  <section
                    data-disabled={
                      !vaccinationStatusVaccinated &&
                      !vaccinationStatusHadElsewhere
                    }
                  >
                    <label>Vaccine Batch Number</label>
                    <input
                      style={{ textTransform: "uppercase" }}
                      {...register("vaccinationBatchNumber")}
                      {...{
                        disabled:
                          !vaccinationStatusVaccinated &&
                          !vaccinationStatusHadElsewhere &&
                          !popupConfirmation,
                      }}
                    />
                    {ErrorMessage(formState.errors.vaccinationBatchNumber)}
                  </section>
                  <section className="section-double-left-align">
                    <section data-disabled={!vaccinationStatusVaccinated}>
                      <label>Vaccine Expiry</label>
                      <Controller
                        name="expiryDate"
                        control={control}
                        defaultValue={null}
                        render={({ field: { ref, ...rest } }) => {
                          return (
                            <KeyboardDatePickerDateOnly
                              inputVariant="outlined"
                              InputLabelProps={{ shrink: true }}
                              {...rest}
                              {...{
                                autoOk: true,
                                format: "DD/MM/YYYY",
                                error: !!formState.errors?.expiryDate,
                                helperText:
                                  formState.errors?.expiryDate?.message,
                              }}
                            />
                          );
                        }}
                      />
                      {ErrorMessage(formState.errors.expiryDate)}
                    </section>
                    <section data-disabled={!vaccinationStatusVaccinated}>
                      {pathogenId === Pathogens["Covid"] && (
                        <>
                          <label>Covid Defrost Expiry Date</label>
                          <Controller
                            name="defrostDate"
                            control={control}
                            defaultValue={null}
                            render={({ field: { ref, ...rest } }) => {
                              return (
                                <KeyboardDatePickerDateOnly
                                  inputVariant="outlined"
                                  InputLabelProps={{ shrink: true }}
                                  {...rest}
                                  {...{
                                    autoOk: true,
                                    format: "DD/MM/YYYY",
                                    error: !!formState.errors?.defrostDate,
                                    helperText:
                                      formState.errors?.defrostDate?.message,
                                  }}
                                />
                              );
                            }}
                          />
                          {ErrorMessage(formState.errors.defrostDate)}
                        </>
                      )}
                    </section>
                  </section>
                  {showClinicianNames && (
                    <section
                      data-disabled={
                        !vaccinationStatusVaccinated &&
                        !vaccinationStatusHadElsewhere
                      }
                    >
                      <label>Clinician Drawing Up Vaccine</label>
                      <select
                        name="clinicianName"
                        {...register("clinicianName")}
                        {...{
                          disabled:
                            !vaccinationStatusVaccinated &&
                            !vaccinationStatusHadElsewhere &&
                            !popupConfirmation,
                        }}
                        onChange={(e) => {
                          if (e.target.value === "Other") {
                            setShowCustomVaccinatorInput(true);
                          } else {
                            // Force update as new item not selected after switching from custom Other back to d/down.
                            setValue("clinicianName", e.target.value);
                            setShowCustomVaccinatorInput(false);
                          }
                        }}
                      >
                        <option value="">Please Select</option>
                        {selectOptions(
                          vaccinatorNames,
                          getValues("clinicianName"),
                        )}
                        <option disabled value="">
                          --------------
                        </option>
                        <option value="Other">Other...</option>
                      </select>
                      {ErrorMessage(formState.errors.clinicianName)}
                    </section>
                  )}
                  {showClinicianNames && showCustomVaccinatorInput && (
                    <section>
                      <label>Other Clinician Name</label>
                      <input {...register("clinicianName")} />
                    </section>
                  )}
                  <section data-disabled={vaccinationStatusBooked}>
                    <label>Vaccinator</label>
                    <select
                      {...register("vaccinatorName")}
                      {...{
                        disabled: vaccinationStatusBooked && !popupConfirmation,
                      }}
                    >
                      <option value="">Please Select</option>
                      {selectOptions(
                        vaccinatorNames,
                        getValues("vaccinatorName"),
                      )}
                    </select>
                    {ErrorMessage(formState.errors.vaccinatorName)}
                  </section>
                  <section
                    data-disabled={
                      (!vaccinationStatusVaccinatedOrAttended &&
                        !vaccinationStatusHadElsewhere) ||
                      vaccinationStatusABNV
                    }
                    className="section-double"
                  >
                    <label>
                      Vaccination Date and Time
                      {vaccinationStatusHadElsewhere && "*"}
                    </label>
                    <div>
                      <input
                        type="date"
                        {...register("vaccinationDate", {
                          required:
                            vaccinationStatusHadElsewhere &&
                            "Please provide date of vaccination",
                        })}
                        {...{
                          disabled:
                            !vaccinationStatusVaccinatedOrAttended &&
                            !vaccinationStatusHadElsewhere &&
                            !popupConfirmation,
                        }}
                      />
                      {ErrorMessage(formState.errors.vaccinationDate)}
                    </div>
                    <div>
                      <input
                        {...register("vaccinationTime")}
                        {...{
                          disabled:
                            !vaccinationStatusVaccinatedOrAttended &&
                            !popupConfirmation,
                          type: "time",
                        }}
                      />
                      {ErrorMessage(formState.errors.vaccinationTime)}
                    </div>
                  </section>
                </fieldset>
                <fieldset>
                  <section className="section-textarea">
                    <label>Notes</label>
                    <textarea {...register("otherInformation")} />
                    {ErrorMessage(formState.errors.otherInformation)}
                  </section>
                </fieldset>
              </div>
              <DialogActions>
                <Box>
                  {props.showSendVaccinationConfirmation && (
                    <>
                      <Checkbox
                        key={`sendVaccinationConfirmation`}
                        id={`sendVaccinationConfirmation`}
                        label={"Send post vaccination confirmation email"}
                        register={register}
                        alt={true}
                      />
                      <br />
                    </>
                  )}
                  <Button variant="outlined" type="submit">
                    Save {pathogenName?.toUpperCase()} Record
                  </Button>
                </Box>
                <Box flexGrow={1}>{alert}</Box>
              </DialogActions>
            </div>
          </div>
        </form>
      </Dialog>
      {/* Modals upon modals upon modals! */}
      {popupConfirmation && !loading && (
        <Dialog
          open={true}
          // fullWidth={true}
          maxWidth={false}
          className="dialog"
        >
          <div className="dialog__inner -vertical">
            <div>
              <header>
                <h2>Please Confirm</h2>
                <Button
                  onClick={() => {
                    undoVaccinationStatusId();
                    setPopupConfirmation(false);
                  }}
                  endIcon={<CancelIcon />}
                >
                  Close
                </Button>
              </header>
              {popupConfirmation}
            </div>
            <DialogActions>
              <Button
                onClick={() => {
                  setPopupConfirmation(false);
                }}
                type="submit"
                variant="contained"
                color="secondary"
                autoFocus
              >
                Yes
              </Button>
              <Button
                onClick={() => {
                  undoVaccinationStatusId();
                  setPopupConfirmation(false);
                }}
                variant="outlined"
                color="secondary"
                style={{
                  color: "#fff",
                }}
              >
                No
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      )}
      {loading && (
        <div className="interstitial">
          <CircularProgress />
        </div>
      )}
    </div>
  );
}
