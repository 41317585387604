import { PerfTableSortDirection } from "./PerformanceTable";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

interface SortingArrowsProps {
  isCurrentlySorted: boolean;
  orderDirection: PerfTableSortDirection;
}
export function SortingArrows({
  isCurrentlySorted,
  orderDirection,
}: SortingArrowsProps) {
  if (isCurrentlySorted && orderDirection === "asc") {
    return (
      <div
        style={{ display: "flex", justifyContent: "center", minWidth: "40px" }}
      >
        <span>
          <ChevronDown isLarge={true} />
        </span>
      </div>
    );
  }
  if (isCurrentlySorted && orderDirection === "desc") {
    return (
      <div
        style={{ display: "flex", justifyContent: "center", minWidth: "40px" }}
      >
        <span>
          <ChevronUp isLarge={true} />
        </span>
      </div>
    );
  } else {
    return (
      <div
        style={{ display: "flex", justifyContent: "center", minWidth: "40px" }}
      >
        <span style={{ opacity: 0.4 }}>
          <ChevronUp />
        </span>
        <span style={{ opacity: 0.4 }}>
          <ChevronDown />
        </span>
      </div>
    );
  }
}

function ChevronDown({ isLarge = false }) {
  return (
    <div style={{ transform: "rotate(90deg)" }}>
      <ChevronLeft
        style={isLarge ? { width: "24px", fill: "#283583" } : { width: "20px" }}
      />
    </div>
  );
}

function ChevronUp({ isLarge = false }) {
  return (
    <div style={{ transform: "rotate(-90deg)" }}>
      <ChevronLeft
        style={isLarge ? { width: "24px", fill: "#283583" } : { width: "20px" }}
      />
    </div>
  );
}
