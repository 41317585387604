import styles from "./appointments.module.scss";

import React from "react";
import {
  DeclineDialogOpenContext,
  DeclineDialogPathogensContext,
  DeclineDialogReasonContext,
  MyAppointment,
} from "../MyAppointments";
import { enumVaccinationStatus } from "../../../../../lib/enums";

export default function DeclineActions({
  appointment,
}: {
  appointment: MyAppointment;
}) {
  const openDialog = React.useContext(DeclineDialogOpenContext);
  const setDialogPathogens = React.useContext(DeclineDialogPathogensContext);
  const setDialogReason = React.useContext(DeclineDialogReasonContext);
  const { pathogens } = appointment;

  if (pathogens.length !== 1) {
    return null;
  }

  if (appointment.status.vaccinationStatusId === enumVaccinationStatus.Exempt) {
    return null;
  }

  return (
    <>
      {appointment.hasDeclined ? (
        <>
          <div className={styles.decline}>
            <p>
              <button
                className={styles.link}
                onClick={() => {
                  openDialog("undoDecline");
                  setDialogPathogens(pathogens[0]);
                }}
              >
                Undo Decline / Had Elsewhere
              </button>
            </p>
            <p>This will enable you to book this vaccine</p>
          </div>
        </>
      ) : (
        appointment.canDecline && (
          <div className={styles.decline}>
            <button
              className={styles.link}
              onClick={() => {
                openDialog("decline");
                setDialogPathogens(pathogens[0]);
                setDialogReason(1);
              }}
            >
              Had Elsewhere
            </button>
            <button
              className={styles.link}
              onClick={() => {
                openDialog("decline");
                setDialogPathogens(pathogens[0]);
                setDialogReason(2);
              }}
            >
              No Thanks
            </button>
          </div>
        )
      )}
    </>
  );
}
