import { createTheme } from "@material-ui/core/styles";
import { makeStyles, ThemeProvider } from "@material-ui/styles";
import { merge } from "lodash";
import { PathogenSelectOptions } from "../../lib/pathogens";
import {
  overridesGenericCells,
  overridesTableGenericColors,
} from "../../lib/themes/overrides";
import { palette } from "../../lib/themes/vars";
import { TrustsLeagueChart } from "./Charts/TrustsLeagueChart";
const useStyles = makeStyles((theme) => ({
  root: {
    "& h2": {
      margin: "1em 0",
    },
  },
}));
const theme = createTheme({
  palette,
  overrides: merge(
    {},
    overridesGenericCells,
    overridesTableGenericColors,
  ) as any,
});

interface PropTypes {
  excludeL1Ids: string;
  pathogenIds: PathogenSelectOptions;
}
export function TrustsLeagueTableTab({ excludeL1Ids, pathogenIds }: PropTypes) {
  const classes = useStyles();

  return (
    <main className={classes.root}>
      <ThemeProvider theme={theme}>
        <h2>Trusts' League Table</h2>
        <h4>
          Compare your uptake percentage with other Trusts using
          VaccinationTrack.
        </h4>
        <p>
          Please note, all Trusts other than yours have been anonymised and
          given generic names such as ‘Trust 21’.{" "}
        </p>
        <TrustsLeagueChart
          excludeL1Ids={excludeL1Ids}
          pathogenSelectOptions={pathogenIds}
        />
      </ThemeProvider>
    </main>
  );
}
