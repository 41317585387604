import {
  AppBar,
  Button,
  Grid,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MoreIcon from "@material-ui/icons/MoreVert";
import LogoutIcon from "@material-ui/icons/PowerSettingsNewOutlined";
import { HelpOutlineSharp as HelpIcon } from "@material-ui/icons";
import { startCase } from "lodash";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { humanName, joinIsh, isLocalhostOrQa } from "../../lib/common_utils";
import DataClient from "../../lib/services/api/DataClient";
import {
  getCurrentTrust,
  getRoleCurrentTrust,
  getRolesCurrentTrust,
  getTrusts,
  isTrustGroupUser,
  isTrustAdmin,
  setCurrentTrust,
  setSelectedTrust,
  setTrusts,
  sortRoles,
} from "../../lib/services/TrustSwitcher";
import { Roles } from "../../lib/types/TrustRoles";
import ConfirmDialog from "../../views/Users/ConfirmDialog";
import authService from "../api-authorization/AuthorizeService";
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    webkitBoxFlex: 1,
    msFlexPositive: 1,
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      minWidth: "40%",
      flexGrow: 1,
      "& .MuiGrid-root": {
        flexWrap: "nowrap",
      },
    },
    "& button": {
      lineHeight: "1.1",
      fontSize: "inherit",
      color: "rgba(0, 0, 0, 0.54)",
      "&:hover": {
        color: theme.palette.secondary.main,
      },
    },
  },
  primaryColor: {
    color: theme.palette.primary.main,
  },
  mobileMenuButton: {
    display: "flex",
    marginLeft: "auto",
    textDecorationLine: "none",
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
    "& button": {
      borderRadius: "4px",
    },
  },
  mobileMenu: {
    "& div": {
      width: "100%",
      maxWidth: "15em",
    },
    // lineHeight: "1",
    // "& li": {
    //   color: theme.palette.primary.main,
    //   fontSize: "1em",
    //   fontFamily: "inherit",
    //   font: "inherit",
    // },
    // "& p": {
    //   margin: 0,
    //   fontSize: "inherit",
    //   fontFamily: "inherit",
    //   color: "inherit",
    // },
    "& button": {
      width: "100%",
      fontSize: "inherit",
      fontFamily: "inherit",
      textAlign: "left",
      color: "inherit",
      justifyContent: "flex-start",
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  logout: {
    fontSize: "inherit",
    borderRadius: "4px",
    color: "inherit",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      justifyContent: "flex-start",
    },
  },
  noUnderline: {
    textDecorationLine: "none",
  },
  help: {
    fontSize: "inherit",
    borderRadius: "4px",
    color: "inherit",
    textDecorationLine: "none",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      justifyContent: "flex-start",
      color: "unset",
      textDecorationLine: "none",
    },
  },
}));
const SYSTEMS_URL = "/trust-groups";
const TRUSTS_URL = "/trusts";
const TRUST_ADMINS_URL = "/users/trust-admins";
function Header(props) {
  const showNav = !props.location.pathname.startsWith("/register");
  const showInfo =
    (showNav && isLocalhostOrQa) ||
    (!isLocalhostOrQa && getTrusts()?.length > 1);
  const classes = useStyles();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [showTrustModal, setShowTrustModal] = React.useState(false);
  const currentTrust = getCurrentTrust();
  let role = getRoleCurrentTrust();
  const updateTrust = async (id) => {
    const success = setSelectedTrust(id);
    if (success) {
      const data = await DataClient.getData("/me");
      setCurrentTrust(data?.currentTrust);
      setTrusts(data?.trusts);
      // window.location.reload();
      // dd-357: Redirect to home for security
      window.location.href = "/";
    }
  };
  const popupState = usePopupState({
    variant: "popover",
    popupId: "trustSwitcher",
  });
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  useEffect(() => {
    if (
      window.location.hash === "#logging-in" &&
      getTrusts() &&
      getTrusts()?.length > 1
    ) {
      setShowTrustModal(true);
      window.location.hash = "";
    }
  }, [setShowTrustModal]);
  function handleLogout() {
    authService.signOut();
  }
  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }
  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }
  const helpLinkForRoles = {
    SuperAdmin: null,
    TrustAdmin:
      "https://support.vaccinationtrack.com/jh2g/trust-admin/?password=Jhg7@ed0h5!RdswjD1",
    ClinicAdmin:
      "https://support.vaccinationtrack.com/k7sy/clinic-admin/?password=Jhg7@ed0h5!RdswjD1",
    Vaccinator:
      "https://support.vaccinationtrack.com/h9ql/vaccinator/?password=Jhg7@ed0h5!RdswjD1",
    TrustWorker:
      "https://www.support.vaccinationtrack.com/l9dt/staff/?password=Jhg7@ed0h5!RdswjD1",
    TrustGroupUser: null,
    // (No role)
    other: null,
  };
  const helpLinkForRole = helpLinkForRoles[role];
  const menuItems = {
    manageSystems: (
      <Button
        fullWidth={true}
        className={classes.button}
        style={
          props.location.pathname.indexOf(SYSTEMS_URL) >= 0
            ? { color: "#009FE3" }
            : {}
        }
        onClick={() => {
          props.history.push(SYSTEMS_URL);
          setMobileMoreAnchorEl(null);
        }}
      >
        Manage Systems
      </Button>
    ),
    manageTrusts: (
      <Button
        fullWidth={true}
        className={classes.button}
        style={
          props.location.pathname.indexOf(TRUSTS_URL) >= 0
            ? { color: "#009FE3" }
            : {}
        }
        onClick={() => {
          props.history.push(TRUSTS_URL);
          setMobileMoreAnchorEl(null);
        }}
      >
        Manage Trusts
      </Button>
    ),
    manageAdministrators: (
      <Button
        fullWidth={true}
        className={classes.button}
        style={
          props.location.pathname.indexOf(TRUST_ADMINS_URL) >= 0
            ? { color: "#009FE3" }
            : {}
        }
        onClick={() => {
          props.history.push(TRUST_ADMINS_URL);
          setMobileMoreAnchorEl(null);
        }}
      >
        Manage Administrators
      </Button>
    ),
    staff: (
      <Button
        fullWidth={true}
        className={classes.button}
        style={
          props.location.pathname.startsWith("/staff")
            ? { color: "#009FE3" }
            : {}
        }
        onClick={() => {
          props.history.push("/staff");
          setMobileMoreAnchorEl(null);
        }}
      >
        Staff
      </Button>
    ),
    clinicPlanner: (
      <Button
        fullWidth={true}
        className={classes.button}
        style={
          props.location.pathname.startsWith("/clinics")
            ? { color: "#009FE3" }
            : {}
        }
        onClick={() => {
          props.history.push("/clinics");
          setMobileMoreAnchorEl(null);
        }}
      >
        Clinic Planner
      </Button>
    ),
    bookAppointment: (
      <Button
        fullWidth={true}
        className={classes.button}
        style={
          props.location.pathname.startsWith("/book-appointment")
            ? { color: "#009FE3" }
            : {}
        }
        onClick={() => {
          props.history.push("/book-appointment");
          setMobileMoreAnchorEl(null);
        }}
      >
        Book Appointment
      </Button>
    ),
    myAppointment: (
      <Button
        fullWidth={true}
        className={classes.button}
        style={
          props.location.pathname.startsWith("/my-appointments")
            ? { color: "#009FE3" }
            : {}
        }
        onClick={() => {
          props.history.push("/my-appointments");
          setMobileMoreAnchorEl(null);
        }}
      >
        My Appointments
      </Button>
    ),
    reporting: (
      <Button
        fullWidth={true}
        className={classes.button}
        style={
          props.location.pathname === "/reporting" ? { color: "#009FE3" } : {}
        }
        onClick={() => {
          props.history.push("/reporting");
          setMobileMoreAnchorEl(null);
        }}
      >
        Reporting
      </Button>
    ),
    settings: (
      <Button
        fullWidth={true}
        className={classes.button}
        style={
          props.location.pathname.startsWith("/settings")
            ? { color: "#009FE3" }
            : {}
        }
        onClick={() => {
          props.history.push("/settings");
          setMobileMoreAnchorEl(null);
        }}
      >
        Settings
      </Button>
    ),
    switchTrust: getTrusts()?.length > 1 && (
      <span>
        <Button
          fullWidth={true}
          className={`${classes.button} nav-button--switch-trust`}
          {...bindTrigger(popupState)}
        >
          Switch Trust
        </Button>
        <Menu {...bindMenu(popupState)}>
          {getTrusts().map((trust) => {
            return (
              <MenuItem
                key={trust.trustId}
                className={`nav-item--switch-trust ${
                  trust.trustId === currentTrust?.trustId ? "-active" : ""
                }`}
                onClick={() => {
                  updateTrust(trust.trustId);
                  popupState.close();
                }}
              >
                {trust.trustName}
              </MenuItem>
            );
          })}
        </Menu>
      </span>
    ),
    help: (
      <a href={helpLinkForRole} target="_blank" className={classes.help}>
        <IconButton className={classes.help}>
          <HelpIcon />
          <Hidden lgUp>
            <span className={classes.noUnderline}>&nbsp;Help</span>
          </Hidden>
        </IconButton>
      </a>
    ),
    logout: (
      <IconButton className={classes.logout} onClick={handleLogout}>
        <LogoutIcon />
        {/* Hide text on larger screens as top nav can be real wide (trustworker) */}
        <Hidden lgUp>&nbsp;Logout</Hidden>
      </IconButton>
    ),
  };
  const menuItemsForRoles = {
    [Roles.SuperAdmin]: [
      menuItems.manageSystems,
      menuItems.manageTrusts,
      menuItems.manageAdministrators,
      menuItems.logout,
    ],
    [Roles.TrustAdmin]: [
      menuItems.staff,
      menuItems.clinicPlanner,
      menuItems.bookAppointment,
      menuItems.myAppointment,
      menuItems.reporting,
      menuItems.settings,
      menuItems.switchTrust,
      menuItems.help,
      menuItems.logout,
    ],
    [Roles.ClinicAdmin]: [
      menuItems.clinicPlanner,
      menuItems.bookAppointment,
      menuItems.myAppointment,
      menuItems.reporting,
      menuItems.switchTrust,
      menuItems.help,
      menuItems.logout,
    ],
    [Roles.Vaccinator]: [
      menuItems.clinicPlanner,
      menuItems.bookAppointment,
      menuItems.myAppointment,
      menuItems.switchTrust,
      menuItems.help,
      menuItems.logout,
    ],
    [Roles.TrustWorker]: [
      menuItems.bookAppointment,
      menuItems.myAppointment,
      menuItems.switchTrust,
      menuItems.help,
      menuItems.logout,
    ],
    [Roles.TrustGroupUser]: [
      // If your role is singularly TrustGroupUser, show only show dashboard and logout, even after adding the ReportingViewer role.
      // menuItems.dashboard,
      menuItems.logout,
    ],
    // (No role)
    other: [
      menuItems.bookAppointment,
      menuItems.myAppointment,
      menuItems.switchTrust,
      menuItems.logout,
    ],
  };

  const menuItemsForRole = menuItemsForRoles[role] || menuItemsForRoles.other;

  // Dues to the legacy roles system, ReportingViewer is added explicitly as its outside of the bonkers heirarchy that is the rest of the roles system.
  if (
    isTrustGroupUser() ||
    (getRolesCurrentTrust().length &&
      getRolesCurrentTrust().includes("ReportingViewer") &&
      !menuItemsForRole.includes(menuItems.reporting))
  ) {
    // Prepend dashboard
    let position = 0; // to account for help and logout
    if (menuItemsForRole.includes(menuItems.help)) {
      position = position - 1;
    }
    if (menuItemsForRole.includes(menuItems.logout)) {
      position = position - 1;
    }
    if (menuItemsForRole.includes(menuItems.switchTrust)) {
      position = position - 1;
    }
    if (menuItemsForRole.includes(menuItems.settings)) {
      position = position - 1;
    }
    menuItemsForRole.splice(position, 0, menuItems.reporting);
  }

  // If user is TrustGroupUser and TrustAdmin, don't show the reporting (dashboard) link twice: dd#5063
  if (isTrustGroupUser() && isTrustAdmin()) {
    const reportingMenuItemIndex = menuItemsForRole.indexOf(
      menuItems.reporting
    );
    menuItemsForRole.splice(reportingMenuItemIndex, 1);
  }

  const renderDesktopMenu = (
    <Grid
      container
      justifyContent="flex-end"
      alignItems="center"
      className="desktop-nav"
    >
      {menuItemsForRole.map((m, i) => (
        <Grid item key={i}>
          {m}
        </Grid>
      ))}
    </Grid>
  );
  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      onClick={handleMobileMenuClose}
      className={classes.mobileMenu}
    >
      {menuItemsForRole}
    </Menu>
  );
  const noNavBarRoutes = [
    "signin",
    "forgot-password",
    "reset-password",
    "auth",
  ];
  for (let index in noNavBarRoutes) {
    const route = noNavBarRoutes[index];
    if (props.location.pathname.includes(route)) {
      return null;
    }
  }
  return (
    <>
      <div
        className="header-wrapper"
        data-modals-open={showTrustModal ? "trust-modal" : ""}
      >
        <AppBar position="static" color="inherit" elevation={0}>
          <Toolbar className="wrapper-header">
            <img
              src={"/img/logo-vaccinationtrack.svg"}
              alt="VaccinationTrack logo"
              className={`header-logo ${showNav ? "-link" : ""}`}
              onClick={() => showNav && props.history.push("/")}
            />
            {role && role.length > 1 && (
              <div className="header-role">
                {role === Roles.TrustWorker
                  ? "Staff Booking"
                  : role === Roles.TrustGroupUser
                    ? "ICS/B Admin"
                    : startCase(role)}
              </div>
            )}
            {showNav ? (
              <>
                {/* <div className={classes.grow} /> */}
                <div className={classes.sectionDesktop}>
                  {renderDesktopMenu}
                </div>
                <div className={classes.mobileMenuButton}>
                  <IconButton
                    className={`${classes.button} nav-button--show-more`}
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={handleMobileMenuOpen}
                    color="primary"
                  >
                    <MoreIcon />
                  </IconButton>
                </div>
                {renderMobileMenu}
              </>
            ) : null}
          </Toolbar>
        </AppBar>

        {/*
          localhost or QA : Show full debug info
          live: Show current trust (only if multiple trusts)
         */}
        {showInfo && (
          <div className="info-bar">
            <div className="inner">
              <span>
                {isLocalhostOrQa() && (
                  <>
                    {getCurrentTrust()?.firstName || getCurrentTrust()?.lastName
                      ? humanName(
                          getCurrentTrust()?.firstName,
                          getCurrentTrust()?.lastName
                        )
                      : getCurrentTrust()?.adminName
                        ? `<${getCurrentTrust()?.adminName}>`
                        : ""}{" "}
                    {getRolesCurrentTrust().length ? (
                      <>[{sortRoles(getRolesCurrentTrust()).join(", ")}]</>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </span>
              <span>
                {getCurrentTrust() && (
                  <>
                    You are signed into: {getCurrentTrust()?.trustName}{" "}
                    {isLocalhostOrQa() && (
                      <>
                        [
                        {getCurrentTrust()
                          ?.trustPathogens?.map((p) => p.name)
                          .join("/")}
                        ]
                      </>
                    )}
                  </>
                )}
              </span>
            </div>
          </div>
        )}
      </div>
      {showTrustModal && (
        <ConfirmDialog
          title="You are on the staff list of more than one trust"
          open={showTrustModal}
          showClose={false}
          confirmLabel="OK"
          dialogParams={{ disableBackdropClick: true }}
          onConfirm={() => {
            setShowTrustModal(false);
          }}
          onClose={() => {
            setShowTrustModal(false);
          }}
        >
          {currentTrust && (
            <>
              <p>
                You are currently signed into{" "}
                <strong>
                  {currentTrust.trustName
                    ? ` ${currentTrust.trustName}`
                    : currentTrust.trustId
                      ? ` id ${currentTrust.trustId}`
                      : "an unnamed trust"}
                </strong>
                .
              </p>
              <p>
                To change to{" "}
                <strong>
                  {joinIsh({
                    arr: getTrusts()
                      .filter(({ trustId }) => trustId != currentTrust.trustId)
                      .map(({ trustName }) => trustName),
                    separator: ", ",
                    separatorLast: " or ",
                  })}
                </strong>{" "}
                use the 'Switch Trust' button in the top right navigation.
              </p>
            </>
          )}
        </ConfirmDialog>
      )}
    </>
  );
}
export default withRouter(Header);
