import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Tooltip,
} from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";
import { FunctionComponent } from "react";
import ReactMarkdown from "react-markdown";
import { ReactMarkdownOptions } from "react-markdown/lib/react-markdown";
import { useHistory } from "react-router-dom";
import styles from "./EmailTemplates.module.scss";
import { Template } from "./template";
import { NotificationTemplateEnum } from "./useNotificationTemplate";

const mandatoryTemplates = [
  NotificationTemplateEnum.CampaignInvite,
  NotificationTemplateEnum.CampaignContinuation,
  NotificationTemplateEnum.ReminderToBook,
  NotificationTemplateEnum.ReminderToBookForVerified,
  NotificationTemplateEnum.PostVaccinationEmail,
];

// FIX? (@nmtmason): Perhaps better to determine optional templates based on their omission from the mandatory list
const optionalTemplates = [
  NotificationTemplateEnum.ClinicCancelledByTrustAdmin,
  NotificationTemplateEnum.ClinicMovedByTrustAdmin,
  NotificationTemplateEnum.RegistrationReceived,
  NotificationTemplateEnum.RegistrationRejected,
];

const NotificationTemplateDisplayNameMarkdown: React.FC<
  Omit<ReactMarkdownOptions, "components">
> = (props) => {
  return (
    <ReactMarkdown
      {...props}
      components={{ p: (props) => <p {...props} style={{ margin: 0 }} /> }}
    />
  );
};

type EmailTemplatesProps = {
  templates: Template[];
};

export const EmailTemplates: FunctionComponent<EmailTemplatesProps> = ({
  templates,
}) => {
  const history = useHistory();

  const mandatoryItems = templates
    ? templates.filter((t) => mandatoryTemplates.includes(t.id))
    : [];
  const optionalItems = templates
    ? templates.filter((t) => optionalTemplates.includes(t.id))
    : [];

  return (
    <>
      {mandatoryItems.length > 0 && (
        <Paper
          style={{
            maxWidth: "45em",
            margin: "1.5em 0 0",
          }}
        >
          <div className={styles["template-group-title"]}>
            <h2 className="secondary">Mandatory templates</h2>
            <p className="danger">
              ALL 5 TEMPLATES MUST BE CUSTOMISED AND UP-TO-DATE
            </p>
          </div>
          <List>
            {mandatoryItems.map((item) => (
              <ListItem key={item.id}>
                <ListItemText
                  primary={
                    item.displayName ? (
                      <NotificationTemplateDisplayNameMarkdown>
                        {item.displayName}
                      </NotificationTemplateDisplayNameMarkdown>
                    ) : (
                      item.name
                    )
                  }
                />
                <ListItemSecondaryAction>
                  <Tooltip title="Edit">
                    <IconButton
                      color="primary"
                      onClick={() => {
                        history.push(`/settings/templates/${item.id}`);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
      {optionalItems.length > 0 && (
        <Paper
          style={{
            maxWidth: "45em",
            margin: "1.5em 0 0",
          }}
        >
          <div className={styles["template-group-title"]}>
            <h2 className="secondary">Optional templates</h2>
            <p>Only customise if necessary</p>
          </div>
          <List>
            {optionalItems.map((item) => (
              <ListItem key={item.id}>
                <ListItemText
                  primary={
                    item.displayName ? (
                      <NotificationTemplateDisplayNameMarkdown>
                        {item.displayName}
                      </NotificationTemplateDisplayNameMarkdown>
                    ) : (
                      item.name
                    )
                  }
                />
                <ListItemSecondaryAction>
                  <Tooltip title="Edit">
                    <IconButton
                      color="primary"
                      onClick={() => {
                        history.push(`/settings/templates/${item.id}`);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Paper>
      )}
    </>
  );
};
