import { Button, Grid, LinearProgress } from "@material-ui/core";
import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ToastMessage from "../../../../components/Universal/toast_message";
import ConfirmDialog from "../../ConfirmDialog";
import useDeleteTrust from "./useDeleteTrust";
import useResendInvitesForTrust from "./useResendInvitesForTrust";
import useTrusts from "./useTrustList";
import useUpdateTrust from "./useUpdateTrust";
import NavContainer from "../NavContainer";
import TrustCreateUpdate from "./TrustCreateUpdate";
import TrustsTable from "./TrustsTable";

export default function ManageTrusts() {
  const history = useHistory();
  const [feedback, setFeedback] = React.useState<{
    type: "success" | "error";
    message: string;
  }>(null);
  const [trusts, loading, error] = useTrusts();
  const [optimisticTrusts, setTrusts] = React.useState(trusts);
  React.useEffect(() => {
    setTrusts(trusts);
  }, [trusts]);
  const [updateTrust, updateErrors, clearErrors] = useUpdateTrust();
  React.useEffect(() => {
    if (error) {
      setFeedback({
        type: "error",
        message: "There was a problem loading the trusts.",
      });
    }
  }, [error]);
  React.useEffect(() => {
    if (updateErrors.length) {
      setFeedback({
        type: "error",
        message: "There was a problem updating the trust.",
      });
      // Reset state due to error
      // Some fields are updated optimistically, and so original data needs to be restored.
      setTrusts((trusts) => {
        const reset = [...trusts];
        updateErrors.forEach((err) => {
          const index = reset.findIndex((t) => t.id === err.id);
          reset[index].showInLeagueTable = err.showInLeagueTable;
        });
        return reset;
      });
      clearErrors();
    }
  }, [updateErrors, clearErrors]);
  const { id } = useParams<{ id: string }>();
  const { state } = useLocation<{ trust: any; update: any }>();
  const [showConfirmDialog, setShowDialog] = React.useState(false);
  const [confirmResendDialog, setConfirmResendDialog] = React.useState(false);

  const [targetId, setTargetId] = React.useState<number | null>(null);
  React.useEffect(() => {
    if (state?.trust && trusts) {
      setTrusts((v) => {
        if (v.some((t) => t.id === state.trust.id)) return v;
        const updated = [...v, state.trust];
        updated.sort((a, b) => a.trustName.localeCompare(b.trustName));
        return updated;
      });
    }
    if (state?.update && trusts) {
      setTrusts((v) => {
        const updated = [...v];
        const index = updated.findIndex((t) => t.id === state.update.id);
        console.log(state.update);
        updated[index] = state.update;
        return updated;
      });
    }
  }, [state, trusts]);
  const [deleteTrust, deleteSuccess, deleteError] = useDeleteTrust();
  const [resendInvites, resendInvitesSuccess, resendInvitesError] =
    useResendInvitesForTrust();

  React.useEffect(() => {
    if (deleteSuccess) {
      setFeedback({
        type: "success",
        message: "Trust deleted.",
      });
      setTrusts((v) => {
        const update = [...v];
        const index = update.findIndex((t) => t.id === deleteSuccess);
        update.splice(index, 1);
        return update;
      });
    }
  }, [deleteSuccess, setFeedback, setTrusts]);
  React.useEffect(() => {
    if (deleteError) {
      setFeedback({
        type: "error",
        message: "Could not delete Trust. Please retry.",
      });
    }
  }, [deleteError, setFeedback]);

  React.useEffect(() => {
    if (resendInvitesSuccess !== -1) {
      setFeedback({
        type: "success",
        message: `Successfully resent invites for ${resendInvitesSuccess} workers.`,
      });
    }
  }, [resendInvitesSuccess, setFeedback]);

  React.useEffect(() => {
    if (resendInvitesError) {
      setFeedback({
        type: "error",
        message: "Failed resending invites for trust.",
      });
    }
  }, [resendInvitesError, setFeedback]);

  return (
    <>
      {loading && <LinearProgress color="primary" />}
      <NavContainer>
        <Grid container direction="row" justifyContent="space-between">
          <h1>Manage Trusts</h1>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              history.push("/trusts/new");
            }}
          >
            Add Trust
          </Button>
        </Grid>
        <TrustsTable
          data={optimisticTrusts ?? []}
          updateTrustShowInLeagueTable={(row: any[], v: boolean) => {
            const trust = {
              id: row[0],
              trustName: row[1],
              senderEmailAddress: row[2],
              logoUrl: row[3],
              pathogens: row[4],
              // 5 = bookings data
              showInLeageTable: row[6],
              // 6 = actions
            };
            //Optimistically set state
            setTrusts((trusts) => {
              const updated = [...trusts];
              const index = updated.findIndex((t) => t.id === trust.id);
              updated[index].showInLeagueTable = v;
              return updated;
            });
            //Make call to update server
            updateTrust(trust.id, {
              ...trust,
              showInLeagueTable: v,
            });
          }}
          deleteTrust={(id: number) => {
            setShowDialog(true);
            setTargetId(id);
          }}
          resendInviteEmails={(id: number) => {
            setConfirmResendDialog(true);
            setTargetId(id);
          }}
        />
        {id && (
          <TrustCreateUpdate
            id={id !== "new" ? Number(id) : null}
            setFeedback={setFeedback}
          />
        )}
        <ConfirmDialog
          open={showConfirmDialog}
          title="Delete Trust?"
          confirmLabel="Delete"
          onConfirm={() => {
            setShowDialog(false);
            deleteTrust(targetId);
          }}
          onClose={() => {
            setShowDialog(false);
            setTargetId(null);
          }}
        >
          <span>
            Are you sure you want to delete{" "}
            {optimisticTrusts?.find((t) => t.id === targetId)?.trustName} ?
          </span>
        </ConfirmDialog>

        <ConfirmDialog
          open={confirmResendDialog}
          title="Resend Invites?"
          confirmLabel="Resend"
          onConfirm={() => {
            setConfirmResendDialog(false);
            resendInvites(targetId);
          }}
          onClose={() => {
            setConfirmResendDialog(false);
            setTargetId(null);
          }}
        >
          <span>
            Are you sure you want to resend invites for{" "}
            {optimisticTrusts?.find((t) => t.id === targetId)?.trustName} ?
          </span>
        </ConfirmDialog>
        {feedback && (
          <ToastMessage
            variant={feedback.type}
            message={feedback.message}
            clear={() => {
              setFeedback(null);
              clearErrors();
            }}
          />
        )}
      </NavContainer>
    </>
  );
}
