import {
  Box,
  CircularProgress,
  makeStyles,
  MenuItem,
  Theme,
  Typography,
} from "@material-ui/core";
import { useMachine, useSelector } from "@xstate/react";
import { DateTime } from "luxon";
import React from "react";
import { useController } from "react-hook-form";
import DropdownInput from "../../../../../components/FormComponent/dropdown";
import { enumClinicTypeId } from "../../../../../lib/enums";
import usePrefersReducedMotion from "../../../../../lib/hooks/usePrefersReducedMotion";
import { Pathogens } from "../../../../../lib/pathogens";
import { boostDate } from "../../BoosterDateChecker/BoosterDateChecker";
import styles from "./AppointmentDetails.module.scss";
import SelectClinic from "./SelectClinic";
import createAppointmentMachine from "./stateMachine";

type DetailsProps = {
  control: any;
  disabled: boolean;
  pathogens: Pathogens[];
  secondVaccinationDate: DateTime;
};

type Slot = {
  availableSpaces: number;
  timeDuration: string;
};

type Time = {
  timeDuration: string;
  timeFrom: string;
  timeTo: string;
  availableSpaces: number;
};

const DROP_IN = "drop-in";

const selectClinicId = (state: any) => state.context.clinicId;
const selectSlotFrom = (state: any) => state.context.slotFrom;
const selectTimeFrom = (state: any) => state.context.timeFrom;

const useStyles = makeStyles((theme: Theme) => ({
  availableAppointments: {
    marginLeft: theme.spacing(1),
    color: theme.palette.success.main,
    fontWeight: 500,
  },
  noAppointments: {
    marginLeft: theme.spacing(1),
    color: theme.palette.error.main,
    fontWeight: 500,
  },
}));

function HospitalSiteOption(props: any) {
  const styles = useStyles();

  const availableSlot = props.data.meta?.availableSlot || 0;

  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="li"
      style={{
        display: "block",
        fontWeight: props.isSelected ? 500 : 400,
        color: props.isSelected ? "#009fe3" : "",
      }}
      {...props.innerProps}
    >
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography noWrap>{props.children}</Typography>
        {availableSlot > 0 ? (
          <Typography className={styles.availableAppointments}>
            {availableSlot} appointment{availableSlot === 1 ? "" : "s"}
          </Typography>
        ) : (
          <Typography className={styles.noAppointments}>
            No appointments
          </Typography>
        )}
      </Box>
    </MenuItem>
  );
}

export default function AppointmentDetails({
  control,
  disabled,
  pathogens,
  secondVaccinationDate,
}: DetailsProps): JSX.Element {
  const [currentSite, setCurrentSite] = React.useState(null);
  const involvesBoostData = pathogens?.includes(Pathogens.Covid);
  // Begin at either the future boostDate, if appropriate, or today
  const earliestDate =
    secondVaccinationDate && involvesBoostData
      ? boostDate(secondVaccinationDate)
      : DateTime.now();
  const machineInfo = {
    pathogens,
    earliestDate,
    startDate: earliestDate,
  };
  // console.log(
  //   "::Machine" + "\n",
  //   secondVaccinationDate + "\n",
  //   machineInfo.startDate.toString() + "\n"
  // );
  const [state, send, service] = useMachine(
    createAppointmentMachine(machineInfo)
  );
  const { context } = state;
  React.useEffect(() => {
    if (currentSite && pathogens) {
      const earliestDate = boostDate(secondVaccinationDate);
      send("SELECT_SITE", {
        id: currentSite.id,
        pathogens,
        earliestDate,
        startDate: earliestDate,
      });
    }
  }, [currentSite, pathogens, secondVaccinationDate, send]);
  const clinicHasDropIns =
    context.clinicTypeId === enumClinicTypeId.bookingsWithDropIns &&
    context.dropInsAvailable > 0;
  const {
    field: { onChange },
  } = useController({
    control,
    name: "appointmentDetails",
    rules: {
      required: "You must select an appointment time.",
      validate: (value) =>
        (value.timeFrom && value.clinicId) ||
        "You must select an appointment time.",
    },
  });

  const isFineWithScrolling = !usePrefersReducedMotion();
  const clinicId = useSelector(service, selectClinicId);
  const slotFrom = useSelector(service, selectSlotFrom);
  const timeFrom = useSelector(service, selectTimeFrom);

  const [isYetToScrollClinic, setYetToScrollClinic] = React.useState(true);
  const [isYetToScrollSlot, setYetToScrollSlot] = React.useState(true);
  const [isWaitingToScroll, setWaitingToScroll] = React.useState(false);

  const clinicRef = React.useRef<HTMLDivElement>();
  const slotRef = React.useRef<HTMLDivElement>();
  React.useEffect(() => {
    onChange({ clinicId, timeFrom });
    // onChange would cause re-renders if included in the deps array
  }, [clinicId, timeFrom]); // eslint-disable-line

  React.useEffect(() => {
    if (clinicId && isYetToScrollClinic && isFineWithScrolling) {
      setTimeout(() => {
        setWaitingToScroll(true);
      }, 1000);
      setYetToScrollClinic(false);
    }
  }, [clinicId, isYetToScrollClinic, isFineWithScrolling]);

  React.useEffect(() => {
    if (isWaitingToScroll) {
      window.scrollTo(0, window.innerHeight);
    }
  }, [isWaitingToScroll]);

  React.useEffect(() => {
    if (slotFrom && isYetToScrollSlot && isFineWithScrolling) {
      setTimeout(() => {
        window.scrollTo(0, window.innerHeight);
      }, 400);
      setYetToScrollSlot(false);
    }
  }, [slotFrom, isYetToScrollSlot, isFineWithScrolling]);

  return (
    <div>
      <h2>Appointment Details</h2>
      {/** state
          .toStrings()
          .join(", ")  - use this to debug current state if required **/}
      <h3 style={{ marginBottom: "-24px" }}>Select Site</h3>
      <div style={{ maxWidth: "30rem" }}>
        <DropdownInput
          inputFieldProps={{
            id: "id",
            name: "hospitalSiteName",
            value: currentSite,
            placeholder: disabled
              ? "Please confirm you can book first"
              : "Select from the list",
          }}
          disabled={disabled}
          options={context.sites}
          setDropDownValue={setCurrentSite}
          components={{ Option: HospitalSiteOption }}
        />
      </div>
      {currentSite && !context.clinics ? (
        <CircularProgress />
      ) : context.clinics ? (
        <div ref={clinicRef}>
          <h3>Select Clinic</h3>
          <SelectClinic context={context} send={send} />
        </div>
      ) : null}
      <div ref={slotRef}>
        {context.clinicId &&
        state.matches("selectingSite.selectingClinic.selectingSlot.loading") ? (
          <CircularProgress />
        ) : context.slots ? (
          <>
            <h3>Select Clinic Slot</h3>
            <div className={styles.slots}>
              <>
                {context.slots.map((slot: Slot) => (
                  <button
                    key={`slot-${slot.timeDuration}`}
                    disabled={slot.availableSpaces === 0}
                    type="button"
                    className={`${styles.button}${
                      slot.timeDuration === context.slotFrom
                        ? ` ${styles.selected}`
                        : ""
                    }`}
                    onClick={() => {
                      send("SELECT_SLOT", { time: slot.timeDuration });
                    }}
                  >
                    <span>{slot.timeDuration}</span>
                    <span>{slot.availableSpaces} available</span>
                  </button>
                ))}
                {clinicHasDropIns ? (
                  <button
                    type="button"
                    className={`${styles.dropIn} ${styles.button}${
                      DROP_IN === context.timeFrom ? ` ${styles.selected}` : ""
                    }`}
                    onClick={() => {
                      send("SELECT_DROP_IN", { timeFrom: DROP_IN });
                    }}
                  >
                    <span>Drop-in</span>
                    <span>{context.dropInsAvailable} available</span>
                  </button>
                ) : null}
              </>
            </div>
            {context.timeFrom === DROP_IN && (
              <p className={styles.appointmentInfo}>
                Choosing a time slot helps us better manage the clinic and
                minimises any waiting for you.
                <br />
                Therefore, only select ‘Drop-in’ if you’re unable to commit to a
                time slot or there are no time slots available.
                <br />
                Please attend when it is most convenient during the clinic
                hours.
              </p>
            )}
          </>
        ) : null}
      </div>
      {context.slotFrom &&
      state.matches(
        "selectingSite.selectingClinic.selectingSlot.selectingTime.loading"
      ) ? (
        <CircularProgress />
      ) : context.times && context.timeFrom !== DROP_IN ? (
        <>
          <h3>Select Appointment Time</h3>
          <div className={styles.slots}>
            {!context.times.find((t: Time) => t.availableSpaces > 0) && (
              <p style={{ alignSelf: "flex-start" }}>
                No appointment times available within this slot.
              </p>
            )}
            {context.times
              .filter((t: Time) => t.availableSpaces > 0)
              .map((time: Time) => (
                <button
                  key={`time-${time.timeDuration}`}
                  type="button"
                  className={`${styles.button}${
                    time.timeFrom === context.timeFrom
                      ? ` ${styles.selected}`
                      : ""
                  }`}
                  onClick={() => {
                    send("SELECT_TIME", { timeFrom: time.timeFrom });
                  }}
                >
                  {time.timeDuration}
                </button>
              ))}
          </div>
        </>
      ) : null}
    </div>
  );
}
