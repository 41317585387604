import { FileCopy } from "@material-ui/icons/";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "./Copy.scss";
export default ({
  children,
  copyContent,
  copyable = true,
  iconAlign = "left",
}: {
  children: string | JSX.Element;
  copyContent?: string;
  copyable?: boolean;
  iconAlign?: "left" | "right";
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const inner = (
    <span className={`copy ${!copyable ? "-decoy" : ""} -${iconAlign}`}>
      <FileCopy /> {children}
      {isCopied && <b> - copied!</b>}
    </span>
  );
  if (!copyable) return inner;
  return (
    <CopyToClipboard
      text={copyContent}
      onCopy={() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      }}
    >
      {inner}
    </CopyToClipboard>
  );
};
