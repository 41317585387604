import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
//
// Usage:
//   DataClient.postData('/foo')
//     .then((res) => {
//       setAlert(ApiAlert({ res, title: "Uh oh" }));
//   ...
//   <>
//     {alert}
//   </>
//
export default ({
  res,
  title,
  message,
  severity = "error",
  alwaysShow = false,
}: {
  res?: any;
  title?: string;
  message?: string | JSX.Element | JSX.Element[];
  severity?: "error" | "warning" | "info" | "success";
  alwaysShow?: boolean;
}) => {
  // Show error if response:
  const showAlert =
    alwaysShow ||
    // - Has an errors  array
    res?.errors?.length ||
    // - Status text is set but not "OK" (e.g. clinic create date out of campaign)
    (res?.statusText && res?.statusText !== "OK") ||
    // - Status code not 2xx (e.g. create clinic QA no status text)
    // nb: create clinic is .status (previously .statusCode but not sure why)
    (res?.status?.toString()?.[0] && res?.status?.toString()?.[0] !== "2") ||
    // - Has a success: "false"
    res?.success === "false";
  // Handle inconsistencies of API messages - errors are sometimes:
  let errors = res?.errors?.length
    ? // errors: ["foo"]
      res.errors
    : res?.data?.errors?.length
    ? // data: { errors: ["foo"]} (axios e.g. edit clinic reservationConflcits)
      res.data.errors
    : // data: { success: false, results: ["foo"] }
    // e.g. POST /TrustGroup (ideal output)
    res?.data?.success === false &&
      res?.data?.results?.length &&
      Array.isArray(res.data.results)
    ? res.data.results
    : res?.data?.errors && Object.keys(res?.data?.errors).length
    ? // data: { errors: {foo: ['bar']} (UpdateClinicBatchDetails validation)
      res.data.errors
    : res?.data && typeof res?.data === "string"
    ? // data: "foo" (Internal Server Error e.g. add staff already added)
      [res.data.substr(0, 300)]
    : res?.data?.length
    ? // data: ["foo"]
      res.data
    : Array.isArray(res)
    ? // ["foo"]
      res
    : typeof res === "string"
    ? // "foo" (e.g. clinic delete non-existing ID)
      [res.substr(0, 300)]
    : [];
  // Internal server error 500 when it spaffs out a bunch of HTML (replace with 'Server error')
  // e.g. ListVaccinators on QA at time of writing
  errors =
    // Being extra super duper careful
    errors?.length && Array.isArray(errors)
      ? errors.map((e) =>
          typeof e === "string" &&
          e.substr(0, `<!DOCTYPE html>`.length) === `<!DOCTYPE html>`
            ? "Server error"
            : e
        )
      : errors;
  const warnings = res?.warnings?.length ? res.warnings : null;
  console.log("::ApiAlert", { showAlert, errors, warnings, res });
  if (!showAlert && !warnings) {
    return null;
  }
  return (
    <>
      {showAlert && (
        <Alert severity={severity} variant="filled">
          <AlertTitle>{title || "Error"}</AlertTitle>
          {errors ? (
            <ul>
              {Array.isArray(errors) // e.g. clinic create validation error
                ? errors?.map((e: any) => (
                    <li>
                      {e?.errorMessage
                        ? e.errorMessage // e.g. clinic create date empty
                        : typeof e === "string" // e.g. clinic create date out of campaign
                        ? e
                        : JSON.stringify(e)}
                    </li>
                  ))
                : JSON.stringify(errors)}
            </ul>
          ) : null}
          {message}
        </Alert>
      )}
      {warnings && (
        <Alert severity="warning" variant="filled">
          <AlertTitle>{title || "Warning"}</AlertTitle>
          <p>{warnings.join(<br />)}</p>
        </Alert>
      )}
    </>
  );
};
