import { humanDate, humanName } from "../../../../lib/common_utils";
import styles from "./AppointmentDetails.module.scss";
import { getLabel, pathogensFromArray } from "../../../../lib/pathogens";

declare interface AppointmentProps {
  result: any;
  actions?: JSX.Element;
  label?: string;
}

export default function AppointmentDetails({
  result,
  actions,
  label,
}: AppointmentProps) {
  const { hospitalSiteName, mobileNumber, firstName, lastName } = result;
  const date = result.bookingDate || result.clinicDate;
  const timeSlot = result.bookingTime || result.timeSlot || result.timeDuration;
  const clinicLocationName = result.clinicLocationName || result.locationName;
  const vaccinationStatus =
    result.attendanceStatusName || result.vaccinationStatus;
  //Expects pathogens to either be an Array of Pathogens enums
  //or an array of objects that have ids that are Pathogens enums
  const pathogens = pathogensFromArray(result.pathogens);
  const pathogenLabel = getLabel(pathogens);
  return (
    <div className={styles.details}>
      <h2>
        {pathogenLabel} Appointment Details{label ? ` ${label}` : null}
      </h2>
      <ul className={styles.list}>
        {(firstName || lastName) && (
          <li>
            <h3>Name</h3>
            <span>{humanName(firstName, lastName)}</span>
          </li>
        )}
        <li>
          <h3>Vaccine(s) Booked</h3>
          <span>{pathogenLabel}</span>
        </li>
        <li>
          <h3>Status</h3>
          <span>{vaccinationStatus ?? "N/A"}</span>
        </li>
        <li>
          <h3>Site</h3>
          <span>{hospitalSiteName ?? "N/A"}</span>
        </li>
        <li>
          <h3>Clinic Location</h3>
          <span>{clinicLocationName ?? "N/A"}</span>
        </li>
        <li>
          <h3>Date</h3>
          <span>{date ? humanDate(date) : "N/A"}</span>
        </li>
        <li>
          <h3>Time Slot</h3>
          <span>{timeSlot ?? "Drop In"}</span>
        </li>
        <li>
          <h3>Contact Number</h3>
          <span>{mobileNumber?.length ? mobileNumber : "N/A"}</span>
        </li>
      </ul>
      <div>{actions}</div>
    </div>
  );
}
