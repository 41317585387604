import { Grid, List, ListItem } from "@material-ui/core";
import { Component, PropsWithChildren } from "react";
import { NavLink } from "react-router-dom";

export interface Feedback {
  type: string;
  message: string;
}
/**
 * Trust admin settings Navigation sidebar wrapper
 *
 * @export
 * @class
 * @extends {Component<PropsWithChildren<any>>}
 */
export default class extends Component<PropsWithChildren<any>> {
  render() {
    const links = [
      {
        name: "Email & SMS",
        url: "/settings/templates",
        exact: false,
      },
      {
        name: "Trust Admins",
        url: "/settings/trust-admins",
        exact: false,
      },
      {
        name: "Sites & Clinic Locations",
        url: "/settings/hospitals",
        exact: true,
      },
      { name: "Pre-screening", url: "/settings/pre-screening", exact: true },
      { name: "Consent", url: "/settings/consent", exact: true },
      {
        name: "Self Registration",
        url: "/settings/organisations",
        exact: true,
      },
      {
        name: "Frontline & ImmForm Assignments",
        url: "/settings/immform",
        exact: false,
      },
      {
        name: "Change Log",
        url: "/settings/change-log",
        exact: false,
      },
      {
        name: "Master Vaccine Batches",
        url: "/settings/master-vaccine-batches",
        exact: false,
      },
    ];

    return (
      <>
        <Grid container spacing={5}>
          <Grid item sm={3} className="nav-settings">
            <List component="nav">
              <ul>
                {links.map((l, i) => (
                  <li>
                    <ListItem
                      key={i}
                      component={(p) => <NavLink {...p} exact={l.exact} />}
                      to={l.url}
                    >
                      {l.name}
                    </ListItem>
                  </li>
                ))}
              </ul>
            </List>
          </Grid>
          <Grid item sm={9}>
            {this.props.children}
          </Grid>
        </Grid>
      </>
    );
  }
}
