import {
  Button,
  Dialog,
  // ConfirmDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { useState } from "react";
import api from "./data";
import StaffForm from "./Form";
import Help from "../../../../components/Help/Help";
import { staffCreateEditSubmit } from "./StaffValidation";
import { UserExistsDialog } from "./UserExistsDialog";
import ToastMessage from "../../../../components/Universal/toast_message";

function CreateDialog({ open, onClose, onSuccess, onError }) {
  const [errors, setErrors] = useState({});
  const [formLoaded, setFormLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showUserExistsDialog, setShowUserExistsDialog] = useState(false);
  const [userExistsData, setUserExistsData] = useState(null);
  const [feedback, setFeedback] = useState(null);

  const handleSubmit = (data) => {
    data.addedManually = true;
    staffCreateEditSubmit(api.staff.add)({
      data,
      onSuccess,
      onError: errorHandler,
      setErrors,
      setLoading,
    });
  };

  const restoreUser = () => {
    const userToRestore = { ...userExistsData.newUserData };
    api.staff
      .restoreUser(userToRestore, userExistsData.newUserData.id)
      .then((_) => {
        setFeedback({
          type: "success",
          message: "User restored",
        });
        setUserExistsData(null);
        onSuccess(userToRestore);
      })
      .catch((err) => {
        setFeedback({
          type: "error",
          message: "Failed to restore user",
        });
        setUserExistsData(null);
        onError();
      });
  };

  const errorHandler = (response) => {
    if (response?.status == 409) {
      // If the details match an archived user, show the user exists dialog
      if (response.data.results.archived) {
        setUserExistsData({
          errorMsg: response.data.summary,
          newUserData: response.data.results,
        });
        setShowUserExistsDialog(true);
      } else {
        onError(response);
      }
    } else {
      onError(response);
    }
  };
  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle disableTypography>
          <h2>Add Staff Member</h2>
        </DialogTitle>
        <DialogContent>
          <Help helpCopyKey="1.2" />
          <StaffForm
            formId="createForm"
            defaultItem={{}}
            onSubmit={handleSubmit}
            errors={errors}
            setFormLoaded={setFormLoaded}
          />
        </DialogContent>
        {formLoaded ? (
          <DialogActions>
            <Button
              form="createForm"
              type="submit"
              variant="contained"
              color="secondary"
              autoFocus
              disabled={loading}
            >
              Add
            </Button>
            <Button
              onClick={onClose}
              variant="outlined"
              color="secondary"
              style={{
                color: "#fff",
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        ) : null}
      </Dialog>

      <UserExistsDialog
        userExistsData={userExistsData}
        open={showUserExistsDialog && userExistsData}
        onConfirm={() => {
          userExistsData?.newUserData.archived
            ? restoreUser(userExistsData.newUserData.id)
            : setShowUserExistsDialog(false);
        }}
        onClose={() => {
          setShowUserExistsDialog(false);
        }}
      ></UserExistsDialog>
      {feedback && (
        <ToastMessage
          variant={feedback.type}
          message={feedback.message}
          clear={() => setFeedback(null)}
        />
      )}
    </>
  );
}

export { CreateDialog };
