import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
} from "@material-ui/core";
import {
  Cancel as CancelIcon,
  Delete as DeleteIcon,
  KeyboardBackspace as BackIcon,
} from "@material-ui/icons/";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import set from "lodash/set";
import startCase from "lodash/startCase";
import { Component } from "react";
import { Link } from "react-router-dom";
import ApiAlert from "../../../../components/ApiAlert";
import {
  createPostDataForClinic,
  populateClinicLocation,
  populateFieldsCreateClinic,
  populateFieldsEditClinic,
  showHideFields,
} from "../../../../components/Fields";
import Help from "../../../../components/Help/Help";
import Input, { InputProps } from "../../../../components/Input";
import {
  isBeforeToday,
  isEarlierInTheDay,
  isoDate,
  parseServerError,
} from "../../../../lib/common_utils";
import {
  dayStrings,
  enumClinicSchedule,
  enumClinicTypeId,
  enumClinicTypeIdLabels,
  enumRequireBookingAllPathogens,
  enumToInputOptions,
  slotDurations,
} from "../../../../lib/enums";
import DataClient from "../../../../lib/services/api/DataClient";
import AlertConflicts from "./AlertConflicts";
import "./CRUD.scss";
import { splitClinic } from "./Split";
import ConfirmDialog from "../../ConfirmDialog";
// interface FormComponent {
//   fields: InputProps[];
//   loading: boolean;
//   error?: string;
// }
// There could be loads apparently, so let's not show info until asked by client
// const reservationsList = (reservations: Booking[]) => (
//   <ul>
//     {reservations.map(
//       ({ firstName, lastName, jobRoleName, dateOfBirth }: Booking) => (
//         <li>
//           {firstName} {lastName} {jobRoleName ? `(${jobRoleName})` : ""}
//           {dateOfBirth ? ` born ${humanDate(dateOfBirth)}` : ""}
//         </li>
//       )
//     )}
//   </ul>
// );
const isRecurringFromFields = (fields: any[]) => {
  const clinicSchedule = find(fields, {
    id: "clinicSchedule",
  })?.value;
  return clinicSchedule !== enumClinicSchedule["One-off"];
};
export default class Crud extends Component<
  InputProps,
  Record<string, unknown>
> {
  state: {
    campaignActive?: Campaign;
    fields: InputProps[];
    clinic?: Clinic;
    loading: boolean;
    // Could maybe have all three at once somehow
    alert?: JSX.Element;
    success?: JSX.Element;
    splitIfEditing?: boolean; // previously having clicked 'Edit Single' on the split page for a recurring clinic
    showSplitPreflight?: boolean;
    showGenericPreflight: boolean;
    showClinicIsBeforeToday: boolean;
  } = {
    fields: [],
    loading: true,
    alert: null,
    success: null,
    splitIfEditing: false,
    showSplitPreflight: false,
    showGenericPreflight: false,
    showClinicIsBeforeToday: false,
  };
  clinicId: string = null;
  mode: "create" | "edit";
  constructor(props: any) {
    super(props);
    this.clinicId = props.match?.params?.clinicId;
    this.mode = typeof this.clinicId !== "undefined" ? "edit" : "create";
    this.state.splitIfEditing = !!props.splitIfEditing;
    this.state.fields = [
      // Set the initial empty fields
      {
        id: "pathogens",
        label: "Clinic Type",
        type: "radio",
        options: [], // Overwritten though by populatePathogens
        required: true,
        disabled: this.mode === "edit",
      },
      {
        // legend: "Configuration",
        id: "clinicTypeId",
        label: (
          <div className="help-tooltip-wrapper">
            <span key={`legend-span-clinicTypeId`}>Booking Model</span>
            <Help helpCopyKey="3.2" key={`legend-help-clinicTypeId`} />
          </div>
        ),
        type: "radio",
        options: enumToInputOptions(
          enumClinicTypeId,
          false,
          enumClinicTypeIdLabels
        ),
        required: true,
      },
      {
        id: "maximumNumberOfDropInAllowed",
        label: "Maximum Number of Bookable Drop Ins",
        type: "number",
        min: 1,
        required: true,
        requires: [
          {
            // Only Drop-in, (not walkaround)
            id: "clinicTypeId",
            value: enumClinicTypeId.bookingsWithDropIns,
          },
        ],
      },
      {
        id: "requireBookingAllPathogens",
        label: (
          <div className="help-tooltip-wrapper">
            <span key={`legend-span-requireBookingAllPathogens`}>
              Appointments staff can book
            </span>
            <Help
              helpCopyKey="3.1"
              key={`legend-help-requireBookingAllPathogens`}
            />
          </div>
        ),
        type: "radio",
        isBoolean: true,
        options: enumToInputOptions(enumRequireBookingAllPathogens),
        required: true,
        disabled: this.mode === "edit",
        requires: [
          {
            // Flu & Covid
            id: "pathogens",
            func: (value: any) => value?.toString()?.split(",")?.length >= 2,
          },
          {
            // Not walkaround
            id: "clinicTypeId",
            notValue: enumClinicTypeId.walkAround,
          },
        ],
      },
      {
        id: "slotDuration",
        label: "Appointment Slot Duration",
        type: "radio",
        options: slotDurations.map((mins, i) => ({
          label: `${mins} minutes`,
          value: mins,
        })),
        required: true,
        requires: [
          {
            // Not walkaround
            id: "clinicTypeId",
            notValue: enumClinicTypeId.walkAround,
          },
        ],
      },
      {
        id: "numberOfSpaces",
        label: "Number of Vaccinators",
        type: "number",
        min: 1,
        required: true,
        requires: [
          {
            // Not walkaround
            id: "clinicTypeId",
            notValue: enumClinicTypeId.walkAround,
          },
        ],
      },
      {
        legend: (
          <span className="help-tooltip-wrapper">
            <span key={`legend-span-hospitalSite`}>Location</span>
            <Help helpCopyKey="3.3" key={`legend-help-hospitalSite`} />
          </span>
        ),
        id: "hospitalSite",
        label: "Site",
        type: "select",
        className: "form-double",
        value: "",
        options: [{ label: "Select from the list", value: "" }],
        onChangeCallback: async () => {
          // When user selects a hospital, populate the Clinic Location options
          this.setState({ loading: true });
          try {
            const fields = await populateClinicLocation(
              {
                fields: cloneDeep(this.state.fields),
              },
              this.state.clinic,
              this.mode
            );
            this.setState({
              fields,
              loading: false,
            });
          } catch (e) {
            this.setState({
              alert: (
                <Alert>
                  <header>Data error</header>
                  <p>{parseServerError(e) || "Clinic Location"}</p>
                </Alert>
              ),
            });
          }
        },
      },
      {
        id: "clinicLocation",
        label: "Clinic Location",
        className: "form-double",
        type: "select",
        value: "",
        options: [{ label: "Select Site first", value: "" }],
        required: true,
      },
      {
        legend: "Date",
        id: "clinicSchedule",
        label: "Clinic Schedule",
        type: "radio",
        options: enumToInputOptions(enumClinicSchedule),
        required: true,
        disabled: this.mode === "edit",
      },
      {
        id: "date",
        label: "Date",
        type: "date",
        required: true,
        max: ((): string => {
          // yyyy-mm-dd 3 few years from now
          // https://2-app.donedone.com/6110/project/17405/task/682243#e2571769
          const date = new Date();
          date.setFullYear(date.getFullYear() + 3);
          return isoDate(date);
        })(),
        onChangeCallback: (dateInput) => {
          this.warnIfClinicDateIsBeforeToday(dateInput.value);
        },
        requires: [
          {
            // Not recurring
            id: "clinicSchedule",
            notValue: enumClinicSchedule["Recurring"],
          },
        ],
      },
      {
        id: "dateFrom",
        label: "Start Date",
        type: "date",
        className: "form-double",
        required: true,
        requires: [
          {
            // Only recurring
            id: "clinicSchedule",
            value: enumClinicSchedule["Recurring"],
          },
        ],
        onChangeCallback: (dateInput) => {
          this.warnIfClinicDateIsBeforeToday(dateInput.value);
        },
      },
      {
        id: "dateTo",
        label: "End Date",
        type: "date",
        className: "form-double",
        required: true,
        requires: [
          {
            // Only recurring
            id: "clinicSchedule",
            value: enumClinicSchedule["Recurring"],
          },
        ],
        onChangeCallback: (dateInput) => {
          this.warnIfClinicDateIsBeforeToday(dateInput.value);
        },
      },
      {
        id: "is24HourClinic",
        label: "24-hour Clinic",
        type: "checkbox",
        requires: [
          {
            // Only walkaround
            id: "clinicTypeId",
            value: enumClinicTypeId.walkAround,
          },
        ],
      },
      {
        legend: "Time",
        id: "timeFrom",
        label: "Start Time",
        type: "time",
        step: 300, // doesn't currently work, but who knows! (5m steps)
        className: "form-double",
        required: true,
        requires: (fields: any[]) => {
          const clinicTypeIdField = fields.find(
            (field) => field.id === "clinicTypeId"
          );
          const is24HourClinicField = fields.find(
            (field) => field.id === "is24HourClinic"
          );

          // For walk around clinics, only display when it's not a 24 hour clinic.
          if (clinicTypeIdField.value === enumClinicTypeId.walkAround) {
            return !is24HourClinicField.value;
          }

          // Always display for non-walkaround clinics.
          return true;
        },

        // Removing validation until figuring out how to disable it when fields are hidden (3 places)
        // validation: (value: any, fields: any[]) => {
        //   const timeFrom = value;
        //   const timeTo = fields.find((f) => f.id === "timeTo")?.value;
        //   if (
        //     (timeTo && timeFrom && isEarlierInTheDay(timeTo, timeFrom)) ||
        //     timeTo == timeFrom
        //   ) {
        //     return ["Start Time must be earlier than End Time"];
        //   }
        // },
      },
      {
        id: "timeTo",
        label: "End Time",
        type: "time",
        step: 300, // doesn't currently work, but who knows! (5m steps)
        className: "form-double",
        required: true,
        requires: (fields: any[]) => {
          const clinicTypeIdField = fields.find(
            (field) => field.id === "clinicTypeId"
          );
          const is24HourClinicField = fields.find(
            (field) => field.id === "is24HourClinic"
          );

          // For walk around clinics, only display when it's not a 24 hour clinic.
          if (clinicTypeIdField.value === enumClinicTypeId.walkAround) {
            return !is24HourClinicField.value;
          }

          // Always display for non-walkaround clinics.
          return true;
        },
        // OLD: Removing validation until figuring out how to disable it when fields are hidden (3 places)
        // DD#4847 I'm showing this when the clinic type is Single Clinic only.
        // NB special styling for this error.
        validation: (value: any, fields: any[]) => {
          const clinicScheduleType = fields.find(
            (f) => f.id === "clinicSchedule"
          )?.value;
          const clinicIsSingle =
            clinicScheduleType === enumClinicSchedule["One-off"];
          const timeFrom = fields.find((f) => f.id === "timeFrom")?.value;
          const timeTo = value;

          if (!clinicIsSingle || !timeTo) return null;
          if (
            (timeTo && timeFrom && isEarlierInTheDay(timeTo, timeFrom)) ||
            timeTo == timeFrom
          ) {
            return [
              "'End Time' must be later than 'Start Time' and on the same day. If you are trying to create an overnight clinic that spans two days, build two clinics. One up until 23:59 and then create a 2nd clinic starting at 0:00am on the next day.",
            ];
          }
        },
      },
      {
        legend: "Break Times (optional)",
        id: "breakTimes",
        label: "Break Times",
        type: "timespan",
        repeater: true,
        requires: [
          {
            // Not walkaround
            id: "clinicTypeId",
            notValue: enumClinicTypeId.walkAround,
          },
        ],
      },
      {
        id: "repeatOnDays",
        type: "hidden",
        onChangeCallback: async (daysValue: string) => {
          // Populate the various repeatOnDaysMon repeatOnDaysTue inputs below based on this value
          const { fields } = this.state;
          dayStrings.forEach((day) => {
            set(
              find(fields, { id: `repeatOnDays${day}` }),
              "value",
              daysValue.split(",").includes(day)
            );
          });
          this.setState({
            fields,
          });
        },
      },
      ...dayStrings.map((day) => ({
        legend: day === "Mon" ? "Repeating on the Following Days" : null,
        id: `repeatOnDays${day}`,
        type: "checkbox" as const,
        className: "repeatOnDays",
        label: day,
        requires: [
          {
            // Only recurring
            id: "clinicSchedule",
            value: enumClinicSchedule["Recurring"],
          },
        ],
        onChangeCallback: async (field: any) => {
          // Checking these boxes sets the hidden repeatOnDays field above, in a "Tue,Fri" style string
          const { fields } = this.state;
          const { label, value } = field;
          // Get current string of days and convert to array
          const repeatOnDaysField = find(fields, { id: "repeatOnDays" });
          let repeatOnDays = repeatOnDaysField.value?.split(",") || [];
          // If unchecking, filter out that day otherwise push it
          if (value) {
            !repeatOnDays.includes(label) && repeatOnDays.push(label);
          } else {
            repeatOnDays = repeatOnDays.filter((d: string) => d !== label);
          }
          // Remove sneaky empty strings
          repeatOnDays = repeatOnDays.filter(Boolean);
          const repeatOnDaysString = repeatOnDays.join(",");
          set(repeatOnDaysField, "value", repeatOnDaysString);
          repeatOnDaysField.onChangeCallback(repeatOnDaysString);
        },
      })),
      {
        legend: "Manually Add Staff",
        id: "manuallyAddStaff",
        label: (
          <>
            <p
              style={{
                marginBottom: 0,
              }}
            >
              Include the 'Manually Add Staff' button in the clinic to allow
              vaccinators to add new staff into the system. <br></br>{" "}
              <span style={{ fontWeight: 400 }}>
                (Vaccinators will still be able to drop in staff already on the
                system via the 'Add Drop In' button)
              </span>
            </p>
          </>
        ),
        type: "checkbox",
        value: this.mode === "create" ? true : undefined,
      },
    ];
  }
  async componentDidMount() {
    const { fields } = this.state;
    // Query API for initial data, depending on create/edit mode
    const infoGatherers = [
      DataClient.getData(`/Campaign`),
      DataClient.getData(
        `/HospitalSite/hospital_sites${
          this.mode === "edit" ? `?includeArchived=true` : ""
        }`
      ),
      DataClient.getData(`/me`), // no success parameter returned
    ];
    if (this.mode === "edit") {
      infoGatherers.push(DataClient.getData(`/Clinic/${this.clinicId}`));
    }
    Promise.all(infoGatherers)
      .then(async (res) => {
        // console.log({ res });
        // Handle API failures
        res.forEach(({ success }, i) => {
          if (!success && i !== 2) {
            throw new Error(`Failed to fetch correct data (${i})`);
          }
        });
        // Gather results
        const campaigns = res[0].results;
        const hospitalSites = res[1].results;
        const me = res[2];
        const campaignActive = campaigns.find((c: any) => c.active);
        // console.log({ campaignActive });
        const clinic = (res[3]?.results as Clinic) || null;
        // A campaign must be active or we give up
        if (!campaignActive) {
          return this.setState({
            alert: (
              <Alert>
                No active campaign, please select one first in{" "}
                <Link to="/campaigns">Campaigns</Link>.
              </Alert>
            ),
            loading: false,
          });
        }
        // Populate fields with gathered data
        let fieldsPopulated = [];
        if (this.mode === "edit") {
          fieldsPopulated = await populateFieldsEditClinic({
            pathogens: me?.currentTrust?.trustPathogens || [],
            fields,
            campaigns,
            hospitalSites,
            campaignActive,
            clinic,
            mode: this.mode,
          });
          // force clinicSchedule field value for clinic state to One-off if editing via edit-single
          if (
            this.state.splitIfEditing &&
            this.mode === "edit" &&
            clinic.recurrenceId === enumClinicSchedule.Recurring
          ) {
            fieldsPopulated.find((f) => f.id === "clinicSchedule").value =
              enumClinicSchedule["One-off"];
          }
          // Slam the clinic data into a state as well, useful to get the id
          this.setState({ clinic });
        } else {
          fieldsPopulated = await populateFieldsCreateClinic({
            pathogens: me?.currentTrust?.trustPathogens || [],
            fields,
            campaigns,
            hospitalSites,
            campaignActive,
            clinic,
          });
        }
        this.setState({
          fields: fieldsPopulated,
          campaignActive,
          loading: false,
        });
      })
      .catch((e) => {
        // API fail without a success false is probably an auth issue
        this.setState({
          alert: (
            <Alert>
              <header>Data error</header>
              <p>
                {parseServerError(e) ||
                  "Unauthorised, please log out and back in."}
              </p>
            </Alert>
          ),
          loading: false,
        });
      });
  }
  updateFields = (fields: any[]) =>
    new Promise((resolve) =>
      this.setState({ fields }, async () => {
        resolve(this.state);
      })
    );

  warnIfClinicDateIsBeforeToday(date: string) {
    const beforeToday = isBeforeToday(date);
    if (beforeToday) {
      this.setState({ showClinicIsBeforeToday: true });
    }
  }

  isRecurringClinic(): boolean {
    return (
      this.state.fields.find((f) => f.id === "clinicSchedule")?.value ===
      enumClinicSchedule["Recurring"]
    );
  }
  genericPreflight = (e: any) => {
    e.preventDefault();
    // Removing validation until figuring out how to disable it when fields are hidden (3 places)
    // const allValid = !this.state.fields.find((f) => f.valid === false);
    // if (!allValid) {
    //   this.setState({
    //     alert: (
    //       <Alert severity="error">
    //         <header>Invalid fields</header>
    //         <p>Please check again before submitting</p>
    //       </Alert>
    //     ),
    //   });
    //   return;
    // }
    // /edit-single warning for a recurring clinic
    const clinicIsRecurring =
      this.state.clinic?.recurrenceId === enumClinicSchedule.Recurring;
    // On reflection, I should have written this differently, in that the showSplitPreflight should appear if:
    // - edit mode and clinic is recurring and *clinicSchedule is set to One-off*
    // so, if they want to sack off the 'split' page and have that field not disabled, do that.
    const showSplitPreflight =
      this.mode === "edit" && clinicIsRecurring && this.state.splitIfEditing;
    // Show one or the other (#4102)
    const showGenericPreflight = !showSplitPreflight;
    this.setState({
      showGenericPreflight,
      showSplitPreflight,
    });
  };
  submit = async (e: any) => {
    this.setState({
      loading: true,
    });
    console.log(
      "::Submit form",
      // JSON.stringify(data.fields),
      this.state.fields.map((f) => ({ id: f.id, value: f.value }))
    );
    const data = createPostDataForClinic({
      mode: this.mode,
      campaignId: this.state.campaignActive?.id,
      fields: this.state.fields,
    });
    if (!data) {
      console.error(
        "No data to post, check createPostDataForClinic for this mode"
      );
      return;
    }
    const url =
      this.mode === "create" && !isRecurringFromFields(this.state.fields)
        ? `/Clinic/SingleClinic`
        : this.mode === "create" && isRecurringFromFields(this.state.fields)
        ? `/Clinic/WeeklyClinic`
        : this.mode === "edit" && !isRecurringFromFields(this.state.fields)
        ? `/Clinic/SingleClinic/${this.state.clinic?.id}`
        : this.mode === "edit" && isRecurringFromFields(this.state.fields)
        ? `/Clinic/WeeklyClinic/${this.state.clinic?.clinicRepeatingGroupId}`
        : "";
    if (!url) {
      // Shouldn't occur
      console.error("Unknown clinic schedule type");
      return;
    }
    DataClient.call({
      url,
      method: this.mode === "edit" ? "PUT" : "POST",
      data,
    })
      .client.then((res: any) => {
        // console.log("::Submit result", { res });
        this.setState({
          loading: false,
          alert: ApiAlert({
            res,
            // #234 "Please note" (where 'Warning' is currently
            title: res?.warnings?.length ? "Please note" : null,
          }),
          // success: res.conflicts?.totalConflicts === 0 && !errors.length
          // Successfully created a single clinic
          success: res.clinic?.id ? (
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
              {res.clinic?.id && (
                <p>
                  <Button
                    component={Link}
                    to={`/clinics/${res.clinic.id}/view`}
                    color="primary"
                    variant="contained"
                  >
                    View Clinic
                  </Button>
                </p>
              )}
              <p>
                <Button
                  to="/clinics"
                  component={Link}
                  color="primary"
                  variant="contained"
                >
                  Return to Clinic Planner
                </Button>
              </p>
            </Alert>
          ) : res.clinics?.length ? (
            // Successfully created recurring clinics
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
              <p>{res.clinics.length} clinics created.</p>
              <p>
                <Button
                  to="/clinics"
                  component={Link}
                  color="primary"
                  variant="contained"
                >
                  Return to Clinic Planner
                </Button>
              </p>
            </Alert>
          ) : res.totalErrors === 0 ? (
            // Successfully edited something
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
              <p>
                <Button
                  color="primary"
                  variant="contained"
                  component={Link}
                  to="/clinics"
                  className="button"
                >
                  Return to Clinic Planner
                </Button>
              </p>
            </Alert>
          ) : null,
        });
        window.scrollTo(0, 0);
      })
      // .then(({ success, results }) => {
      //   console.log({ success, results });
      // })
      .catch((e) => {
        console.error("Error at ", url, e);
        // console.log("::Error data", e, e.response?.data);
        let alert: JSX.Element;
        const clinicErrors: string[] = [];
        // Reservation conflicts output to error message
        const {
          clinicTimeSlots,
          clinics,
          reservations,
          totalConflicts,
          reservationsRequiringCancellation,
          reservationsRequiringMoving,
          errors,
        } = e.response?.data || {};
        if (
          !!totalConflicts &&
          (clinicTimeSlots?.length || clinics?.length || reservations?.length)
        ) {
          if (clinicTimeSlots?.length) {
            clinicErrors.push(
              `${clinicTimeSlots.length} Clinic Time Slots have conflicts with this Clinic`
            );
          }
          if (clinics?.length) {
            clinicErrors.push(
              `A clinic at this location already exists during this time`
            );
          }
          if (reservations?.length) {
            clinicErrors.push(
              `${reservations.length} Clinic Reservations have conflicts with this Clinic`
            );
          }
        } else if (
          reservationsRequiringCancellation?.length ||
          reservationsRequiringMoving?.length
        ) {
          alert = (
            <AlertConflicts
              {...{
                reservationsRequiringCancellation,
                reservationsRequiringMoving,
              }}
            >
              <DialogActions>
                <Button
                  onClick={(e) => {
                    this.setState(
                      // @ts-ignore
                      ({ fields }: { fields: InputProps[] }) => {
                        fields.push({
                          id: "cancelReservationsIfInConflict",
                          type: "hidden",
                          value: true,
                        });
                        fields.push({
                          id: "moveReservationsIfRequired",
                          type: "hidden",
                          value: true,
                        });
                        return {
                          fields,
                        };
                      },
                      () => {
                        this.submit(e);
                      }
                    );
                  }}
                  type="submit"
                  variant="contained"
                  color="secondary"
                  autoFocus
                >
                  Yes
                </Button>
                <Button
                  component={Link}
                  to="/clinics"
                  variant="outlined"
                  color="secondary"
                  style={{
                    color: "#fff",
                  }}
                  startIcon={<CancelIcon />}
                >
                  Cancel
                </Button>
              </DialogActions>
            </AlertConflicts>
          );
        } else if (Array.isArray(errors)) {
          // "There is 1 clinics that clash." when conflicting location
          clinicErrors.push(...errors);
        } else {
          // Error returned in unpredicted format (but ApiAlert is crazy flexible)
          alert = ApiAlert({
            res: { errors: e.response },
          });
        }
        console.log(":: ~ clinicErrors:", clinicErrors);
        if (clinicErrors.length) {
          // #2803 - if there's only one conflict, show error as the title (part A)
          // https://2-app.donedone.com/6110/project/17408/task/682480
          if (clinicErrors.length === 1) {
            alert = ApiAlert({
              title: clinicErrors[0],
              alwaysShow: true,
            });
          } else {
            alert = ApiAlert({
              title: `Found ${totalConflicts} conflict${
                totalConflicts === 1 ? "" : "s"
              } with this Clinic`,
              res: { errors: clinicErrors },
            });
          }
        }
        this.setState({
          loading: false,
          alert,
        });
        window.scrollTo(0, 0);
      });
  };
  render() {
    const {
      fields,
      loading,
      alert,
      success,
      showSplitPreflight,
      showGenericPreflight,
      campaignActive,
      showClinicIsBeforeToday,
    } = this.state;
    const fieldsFiltered = showHideFields(fields);
    return (
      <>
        <div className="wrapper-main">
          <h1>{startCase(this.mode)} Clinic</h1>
          {alert}
          {success}
        </div>
        {!success && (
          <form onSubmit={this.genericPreflight} className="form">
            <Box className="form-wrapper">
              {fieldsFiltered.map((f: any, i: number) => (
                <Input
                  {...{ ...f, fields, updateFields: this.updateFields, key: i }}
                />
              ))}
            </Box>
            <Box className="form-wrapper">{alert}</Box>
            <DialogActions>
              <Button type="submit" variant="contained" color="secondary">
                {this.mode === "edit" ? "Save Clinic" : "Create Clinic"}
              </Button>
              <Button
                component={Link}
                to={`/clinics`}
                variant="outlined"
                color="secondary"
                style={{
                  color: "#fff",
                }}
                startIcon={<BackIcon />}
              >
                Back
              </Button>
              {this.mode === "edit" && (
                <Button
                  component={Link}
                  to={`/clinics/${this.clinicId}/delete`}
                  variant="contained"
                  style={{ marginLeft: "auto" }}
                  startIcon={<DeleteIcon />}
                >
                  Cancel Clinic
                </Button>
              )}
            </DialogActions>
          </form>
        )}
        {/* Show this separately to the form, as the loading state mysteriously interferes with fields */}
        {loading && (
          <div className="interstitial">
            <CircularProgress />
          </div>
        )}
        {showSplitPreflight ? (
          <Dialog open={true} maxWidth={false} className="dialog">
            <div className="dialog__inner">
              <div>
                <header>
                  <h2>Are you sure you want to decouple this clinic?</h2>
                  <Button
                    onClick={(e: React.MouseEvent) => {
                      this.setState({
                        showSplitPreflight: false,
                      });
                    }}
                    color="primary"
                    endIcon={<CancelIcon />}
                  >
                    Close
                  </Button>
                </header>
                <Box>
                  <p>
                    Making changed to this clinic will decouple it from the
                    recurring series.
                  </p>
                  <p>
                    Any future changes to the series will exclude this clinic.
                    This action cannot be undone.
                  </p>
                </Box>
              </div>
            </div>
            <DialogActions>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ loading: true });
                  splitClinic(parseInt(this.clinicId))
                    .then((res: any) => {
                      const { success, results } = res;
                      if (!success) {
                        throw new Error(`Failed to decouple clinic`);
                      }
                      this.setState({
                        splitIfEditing: false, // no need to split again on next save
                        showSplitPreflight: false,
                        showGenericPreflight: false,
                        loading: false,
                      });
                      this.submit(e); // Save clinic as well (no genericPreflight #4102)
                    })
                    .catch((e) => {
                      this.setState({
                        alert: ApiAlert({
                          alwaysShow: true,
                          severity: "error",
                          title: "Split clinic error",
                          message: e.response || e.message,
                        }),
                        showSplitPreflight: false,
                        loading: false,
                      });
                    });
                }}
                type="submit"
                variant="contained"
                color="secondary"
                autoFocus
              >
                Yes
              </Button>
              <Box display="inline" ml={2}>
                <Button
                  onClick={(e) => {
                    this.setState({
                      showSplitPreflight: false,
                    });
                  }}
                  variant="outlined"
                  color="secondary"
                  style={{
                    color: "#fff",
                  }}
                  startIcon={<BackIcon />}
                >
                  Back
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        ) : showGenericPreflight ? (
          <Dialog
            open={true}
            // keepMounted
            // onClose={() => props.handleClose(false)}
            // fullWidth={true}
            maxWidth={false}
            // fullScreen={true}
            className="dialog"
          >
            <div className="dialog__inner">
              {/* This looks a little div-itis, but it supports multicolumn layout (see record vaccination details popoup) */}
              <div>
                <header>
                  <h2>Are you sure?</h2>
                  <Button
                    onClick={(e: React.MouseEvent) => {
                      this.setState({ showGenericPreflight: false });
                    }}
                    color="primary"
                    endIcon={<CancelIcon />}
                  >
                    Close
                  </Button>
                </header>
                <Box>
                  {this.mode === "edit" &&
                    (isRecurringFromFields(fields) ? (
                      <p>
                        Changes will be applied to all clinics in the series.
                        Are you sure you want to proceed?
                      </p>
                    ) : (
                      <p>
                        Changes will be made to this one-off clinic only. It is
                        not part of a series. Are you sure you want to proceed?
                      </p>
                    ))}
                  {this.mode === "create" &&
                    (isRecurringFromFields(fields) ? (
                      <p>
                        This will create a series of clinics. Are you sure you
                        want to proceed?
                      </p>
                    ) : (
                      <p>
                        This will create a one-off clinic, not a series. Are you
                        sure you want to proceed?
                      </p>
                    ))}
                </Box>
              </div>
            </div>
            <DialogActions>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    showGenericPreflight: false,
                  });
                  this.submit(e);
                }}
                type="submit"
                variant="contained"
                color="secondary"
                autoFocus
              >
                Yes
              </Button>
              <Box display="inline" ml={2}>
                <Button
                  onClick={(e) => {
                    this.setState({
                      showGenericPreflight: false,
                    });
                  }}
                  variant="outlined"
                  color="secondary"
                  style={{
                    color: "#fff",
                  }}
                  startIcon={<BackIcon />}
                >
                  Back
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        ) : (
          ""
        )}
        <ConfirmDialog
          title={
            this.isRecurringClinic()
              ? "Clinic dates are in the past"
              : "Clinic date is in the past"
          }
          open={showClinicIsBeforeToday}
          confirmLabel="Close"
          showClose={false}
          onClose={() => {
            this.setState({
              showClinicIsBeforeToday: false,
            });
          }}
          onConfirm={() => {
            this.setState({
              showClinicIsBeforeToday: false,
            });
          }}
        >
          {this.isRecurringClinic() ? (
            <p>Are you sure you want to create clinics in the past?</p>
          ) : (
            <p>Are you sure you want to create a clinic in the past?</p>
          )}
        </ConfirmDialog>
      </>
    );
  }
}
