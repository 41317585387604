import { Pathogens } from "../../lib/pathogens";
import { isClinicAdminOrHigher } from "../../lib/services/TrustSwitcher";
import StaffList from "../Users/TrustAdmin/Staff/List";
import { TableToolbarButtons } from "../Users/TrustAdmin/Staff/TableToolbar";
import ComplianceStats from "./ComplianceStats";
export default ({ trustId }: { trustId: number }) => {
  return (
    <>
      <h1 className="dashboard-heading">Compliance</h1>
      <ComplianceStats
        {...{ pathogenIds: [Pathogens.Covid], label: "Covid", trustId }}
      />
      {isClinicAdminOrHigher() && (
        <StaffList
          {...{
            // trustId,
            tableProps: {
              header: null,
              showAlertBoxes: false,
              buttonsToShow: [TableToolbarButtons.downloadReport],
              complianceFiltersEnabled: true,
              isComplianceTable: true,
              complianceFiltersPathogenIds: [Pathogens.Covid],
              downloadReportEnabled: true,
              optionsOverrides: {
                search: false,
                searchAlwaysOpen: false,
                filter: false,
                selectableRows: false,
              },
            },
          }}
        />
      )}
    </>
  );
};
