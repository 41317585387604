import { ReactElement, useCallback, useMemo, useState } from "react";

// I'm sorry - this is pretty gnarly. Trying to shoehorn "hidden" steps into a stepper is not a great idea.
// It's constrained by the original design, and a bit of a silly requirement to have a "confirm your confirmation" button in a confirmation dialog...
function generateActiveSteps(steps: SteppedConfirmDialogStep[]): number[] {
  const activeSteps: number[] = [];

  for (let i = 0; i < steps.length; i++) {
    const previousActiveStep = activeSteps[i - 1];

    if (!steps[i].isHiddenFromStepper) {
      activeSteps.push(
        previousActiveStep !== undefined ? previousActiveStep + 1 : 0
      );
    } else {
      activeSteps.push(
        previousActiveStep !== undefined ? previousActiveStep : 0
      );
    }
  }

  return activeSteps;
}

export type SteppedConfirmDialogStep = {
  label: string;
  stepperLabel?: ReactElement | string;
  content: ReactElement;
  isHiddenFromStepper?: boolean;
  requiresConfirmation?: boolean;
  previousStepLabel?: string;
  nextStepLabel?: string;
  submitLabel?: string;
  confirmationLabel?: string;
};

interface UseSteppedConfirmDialogOptions {
  steps: SteppedConfirmDialogStep[];
  defaultStep?: number;
}

type UseSteppedConfirmDialogReturn = {
  activeStep: number;
  currentStep: SteppedConfirmDialogStep;
  isFirstStep: boolean;
  isLastStep: boolean;
  isCurrentStepConfirmed: boolean;
  setNextStep: () => void;
  setPreviousStep: () => void;
  resetCurrentStep: () => void;
  setIsCurrentStepConfirmed: (checked: boolean) => void;
};

export function useSteppedConfirmDialog({
  steps,
  defaultStep = 0,
}: UseSteppedConfirmDialogOptions): UseSteppedConfirmDialogReturn {
  const [currentIndex, setCurrentIndex] = useState(defaultStep);
  const [isCurrentStepConfirmed, setIsCurrentStepConfirmed] = useState(false);

  const currentStep = steps[currentIndex];

  const activeSteps = useMemo(() => generateActiveSteps(steps), [steps]);

  const isFirstStep = currentIndex === 0;
  const isLastStep = currentIndex === steps.length - 1;

  const setNextStep = useCallback(() => {
    setCurrentIndex((currentIndex) =>
      Math.min(steps.length - 1, currentIndex + 1)
    );
    setIsCurrentStepConfirmed(false);
  }, []);

  const setPreviousStep = useCallback(() => {
    setCurrentIndex((currentIndex) => Math.max(0, currentIndex - 1));
    setIsCurrentStepConfirmed(false);
  }, []);

  const resetCurrentStep = useCallback(() => {
    setCurrentIndex(defaultStep);
    setIsCurrentStepConfirmed(false);
  }, []);

  return {
    activeStep: activeSteps[currentIndex],
    currentStep,
    isFirstStep,
    isLastStep,
    isCurrentStepConfirmed: currentStep.requiresConfirmation
      ? isCurrentStepConfirmed
      : true,
    setNextStep,
    setPreviousStep,
    resetCurrentStep,
    setIsCurrentStepConfirmed,
  };
}
