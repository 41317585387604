import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography/Typography";

const useStyles = makeStyles((theme) => ({
  placeholderBackground: {
    width: "100%",
  },
  img: {
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    marginTop: "10%",
    width: "20%",
    [theme.breakpoints.down("sm")]: {
      width: "40%",
      marginTop: "40%",
    },
  },
  title: {
    fontWeight: "bold",
    paddingBottom: "2%",
  },
  subheader: {
    paddingTop: "2%",
    fontSize: "15px",
    textAlign: "center",
    color: theme.palette.primary.light,
  },
}));

export function NoResultsFound(props) {
  const classes = useStyles();
  return (
    <div className={classes.placeholderBackground}>
      {/*<img alt={"no results found"} className={classes.img} src={noResults}/>*/}
      <Typography className={classes.subheader}>
        {props.message || "No Results found"}
      </Typography>
    </div>
  );
}

export default NoResultsFound;
