import { MUIDataTableColumnDef } from "mui-datatables";
import {
  humanDate,
  humanDateTime,
  pascalCaseToSentence,
} from "../../lib/common_utils";
import { ChangesetTable } from "./ChangesetTable";
import { changeTypeToNameMapper } from "./ClinicEventDetailsMapper";
import {
  ChangesetBase,
  ChangesetDetailsLookup,
  ReservationChangesetDetailedDTO,
  ReservationChangesetDTO,
  ReservationChangesetSearchFilterDTO,
} from "./EventLogData";

const reservationTableColumns: MUIDataTableColumnDef[] = [
  {
    name: "type",
    label: "Change Type",
    options: {
      customBodyRender: (value: number) =>
        pascalCaseToSentence(changeTypeToNameMapper(value)),
    },
  },
  {
    name: "createdDate",
    label: "Change Date",
    options: {
      customBodyRender: (value: number) => {
        return humanDateTime(value);
      },
    },
  },
  {
    name: "reservationId",
    label: "Appointment ID",
    options: {
      customBodyRender: (value) => (value === 0 ? "N/A" : value),
    },
  },
  // { name: "changesetLocationId", label: "Location Id" },
  {
    name: "clinicLocationName",
    label: "Location Name",
  },
  {
    name: "clinicDate",
    label: "Date",
    options: {
      customBodyRender: (value: string) =>
        humanDate(value) === "01/01/0001" ? "N/A" : humanDate(value),
    },
  },
  // {
  //   name: "dateFrom",
  //   label: "Date From",
  //   options: {
  //     customBodyRender: (value: number) => humanDate(value),
  //   },
  // },
  // {
  //   name: "dateTo",
  //   label: "Date To",
  //   options: {
  //     customBodyRender: (value: number) => humanDate(value),
  //   },
  // },
  // { name: "hospitalSiteId", label: "Hospital Site Id" },
  { name: "hospitalSiteName", label: "Hospital Site Name" },
  // { name: "id", label: "Id" },
  // 'parentChangeset',
  // {
  //   name: "timeFrom",
  //   label: "Time From",
  //   options: {
  //     customBodyRender: (value: number) => humanTime(value),
  //   },
  // },
  // {
  //   name: "timeTo",
  //   label: "Time To",
  //   options: {
  //     customBodyRender: (value: number) => humanTime(value),
  //   },
  // },

  {
    name: "createdByEmail",
    label: "Changed By",
  },
];

export function ReservationTable({
  reservationResults,
  totalCount,
  setReservationFilter,
  reservationFilter,
  getReservationDetails,
  reservationDetailsLookup,
}: {
  reservationResults: ReservationChangesetDTO[];
  totalCount: number;
  setReservationFilter: (filter: ReservationChangesetSearchFilterDTO) => void;
  reservationFilter: ReservationChangesetSearchFilterDTO;
  getReservationDetails: (changesets: ChangesetBase[]) => void;
  reservationDetailsLookup: ChangesetDetailsLookup<ReservationChangesetDetailedDTO>;
}): JSX.Element {
  return (
    <ChangesetTable
      {...{
        changesetResults: reservationResults,
        totalCount: totalCount,
        setChangesetFilter: setReservationFilter,
        changesetFilter: reservationFilter,
        getChangesetDetails: getReservationDetails,
        changesetDetails: reservationDetailsLookup,
        changesetTableColumns: reservationTableColumns,
      }}
    />
  );
}
