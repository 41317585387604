import { LinearProgress } from "@material-ui/core";
import { startCase } from "lodash";
import { useEffect, useState } from "react";
import ConfirmDialog from "../../ConfirmDialog";
import { useForm } from "react-hook-form";
import DataClient from "../../../../lib/services/api/DataClient";
import ApiAlert from "../../../../components/ApiAlert";
// import TrustGroupForm from "./TrustGroupForm";
export default function ({
  mode,
  id,
  onClose,
}: {
  mode: string;
  id?: number;
  onClose: (...args: any[]) => void;
}) {
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const {
    reset,
    handleSubmit,
    register,
    formState: { errors: formErrors },
  } = useForm();
  const create = (data: any) => {
    const { name } = data;
    if (!name) throw Error("Missing data");
    return DataClient.postData(`/TrustGroup`, {
      name,
    });
  };
  const edit = (data: any) => {
    const { id, name } = data;
    if (!id || !name) throw Error("Missing data");
    return DataClient.update(`/TrustGroup/${id}`, {
      name,
    });
  };
  const onSubmit = (data: any) => {
    setLoading(true);
    data.id = id || null;
    (mode === "create" ? create : edit)(data)
      .then((res) => {
        const { success, results } = res || {};
        if (!success) {
          setAlert(
            ApiAlert({
              alwaysShow: true,
              message: `Could not ${mode} system`,
            })
          );
          return;
        }
        // Successful
        setAlert(
          ApiAlert({
            alwaysShow: true,
            title: "Success",
            message: `System ${mode} complete`,
            severity: "success",
          })
        );
        setSuccessful(true);
      })
      .catch((e) => {
        console.error(e.response);
        setAlert(
          ApiAlert({
            alwaysShow: true,
            res: e.response,
          })
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (mode === "edit" && id) {
      setLoading(true);
      DataClient.getData(`/TrustGroup/${id}`)
        .then((res) => {
          const { success, results } = res || {};
          if (!success) {
            setAlert(
              ApiAlert({
                alwaysShow: true,
                message: `Could not get system "${id}"`,
              })
            );
            return;
          }
          const { name } = results;
          reset({ name });
        })
        .catch((e) => {
          console.error(e.response);
          setAlert(
            ApiAlert({
              alwaysShow: true,
              res: e.response,
            })
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [mode, id]);
  return (
    <>
      <ConfirmDialog
        open={true}
        title={`${startCase(mode)} System`}
        onConfirm={handleSubmit(onSubmit)}
        confirmLabel="Submit"
        onClose={() => onClose()}
        closeLabel={successful ? "Back" : "Cancel"}
        dialogParams={{
          fullWidth: true,
          maxWidth: "sm",
        }}
        loading={loading}
        showConfirm={!successful}
        alert={alert}
      >
        {loading ? (
          <LinearProgress />
        ) : (
          <form
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
            className="form"
          >
            <section>
              <label>Name</label>
              <input
                {...register("name", {
                  required: "You must enter a name",
                })}
                style={{ width: "100%" }}
              />
              {formErrors.name && (
                <span role="alert">{formErrors.name.message}</span>
              )}
            </section>
          </form>
        )}
      </ConfirmDialog>
      {/* <Dialog open={open} onClose={onClose}>
        <DialogTitle disableTypography>
          <h2>{startCase(mode)} System</h2>
        </DialogTitle>
        <DialogContent>Hello</DialogContent>
        <DialogActions>
          <Button
            onClick={onConfirm}
            type="submit"
            variant="contained"
            color="secondary"
            autoFocus
          >
            {confirmLabel || "Yes"}
          </Button>
          <Button
            onClick={onClose}
            variant="outlined"
            color="secondary"
            style={{
              color: "#fff",
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
  // const { id } = useParams<{ id: string }>();
  // const [feedback, setFeedback] =
  //   React.useState<{ type: "success" | "error"; message: string }>(null);
  // const [data, loading, error] = useTrustAdmin(
  //   id === "new" ? null : Number(id)
  // );
  // React.useEffect(() => {
  //   if (error) {
  //     setFeedback({
  //       type: "error",
  //       message: "There was a problem loading the Trust Admin.",
  //     });
  //   }
  // }, [error]);
  // const isUpdate = id !== "new";
  // const [warningDialog, setWarningDialog] = React.useState<boolean | string[]>(
  //   false
  // );
  // const [proceedIfWarnings, setProceedIfWarnings] = React.useState(false);
  // return (
  //   <>
  //     {loading && <LinearProgress color="primary" />}
  //     <NavContainer>
  //       <TrustGroupForm
  //         setFeedback={setFeedback}
  //         setWarningDialog={setWarningDialog}
  //         isUpdate={isUpdate}
  //         id={Number(id)}
  //         formData={data ?? null}
  //         proceedIfWarnings={proceedIfWarnings}
  //       />
  //       {feedback && (
  //         <ToastMessage
  //           variant={feedback.type}
  //           message={feedback.message}
  //           clear={() => {
  //             setFeedback(null);
  //           }}
  //         />
  //       )}
  //       <ConfirmDialog
  //         open={Array.isArray(warningDialog)}
  //         title="Please confirm"
  //         confirmLabel="Confirm"
  //         onConfirm={() => {
  //           setWarningDialog(false);
  //           setProceedIfWarnings(true);
  //         }}
  //         onClose={() => {
  //           setWarningDialog(false);
  //         }}
  //       >
  //         <span>
  //           {Array.isArray(warningDialog)
  //             ? warningDialog.join(" ")
  //             : "A similar Trust Worker already exists. Please confirm and then resubmit to update them as a Trust Admin."}
  //         </span>
  //       </ConfirmDialog>
  //     </NavContainer>
  //   </>
  // );
}
