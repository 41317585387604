import React from "react";
import ReactDOM from "react-dom";

type HookOptions<T, D> = {
  formatter: (data: D) => T;
  guard?: (res: unknown) => res is D;
};

export default function useDataClient<T, D>(
  client: (...args: any[]) => Promise<any>,
  args: any[],
  options: HookOptions<T, D>
) {
  const isValid = args && args.length >= 1;
  const [data, setData] = React.useState<T>(null);
  const [loading, setLoading] = React.useState(isValid);
  const [error, setError] = React.useState(false);
  const { formatter, guard } = options;
  React.useEffect(() => {
    if (!isValid) return;
    client(...args)
      .then((res: unknown) => {
        ReactDOM.unstable_batchedUpdates(() => {
          if (guard && !guard(res)) {
            throw new Error("DataClient response was an unexpected format.");
          }
          const data = res as D;
          setLoading(false);
          setData(formatter(data));
        });
      })
      .catch((error) => {
        ReactDOM.unstable_batchedUpdates(() => {
          console.error(error);
          setLoading(false);
          setError(true);
        });
      });
  }, [client, args, formatter, guard, isValid]);
  return [data, loading, error] as const;
}
