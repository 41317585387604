import { CircularProgress, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import DropdownInput from "../../components/FormComponent/dropdown";
import { getTrustId } from "../../lib/services/TrustSwitcher";
import { ChangesetFilter, changesetTypeOptions } from "./ChangesetFilter";
import { ClinicTable } from "./ClinicTable";
import styles from "./EventLog.module.css";
import {
  ChangesetBase,
  ChangesetDetailsLookup,
  ClinicChangesetDTO,
  ClinicChangesetSearchFilterDTO,
  ClinicDetailsLookup,
  EventLogDataService,
  EventType,
  ReservationChangesetDTO,
  ReservationChangesetDetailedDTO,
  ReservationChangesetSearchFilterDTO,
  SearchFilterBase,
  TrustDataService,
  TrustDetailedDTO,
} from "./EventLogData";
import { ReservationTable } from "./ReservationTable";
import Help from "../../components/Help/Help";

const defaultSearchFilter: SearchFilterBase = {
  orderBy: "CreatedDate DESC",
  pageNumber: 1,
  pageSize: 25,
  searchText: "",
  dateFrom: null,
  dateTo: null,
  // type: null, // removed for typings
  // HospitalSiteName: "",
  // ClinicLocationName: "",
};

/**
 *TODO: 
  - Proptypes and default props
 *
 * @export
 * @return {*}  {JSX.Element}
 */
export function EventLog(): JSX.Element {
  // Local component state
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedEventType, setSelectedEventType] = useState<EventType>();
  // If the user is SuperAdmin, the getTrustId() function will return 0 and the user can select a trust in the UI.
  const [currentTrustId, setCurrentTrustId] = useState<number>(getTrustId());
  const [isSuperAdmin] = useState<boolean>(!getTrustId());
  const [allTrusts, setAllTrusts] = useState<
    { label: string; value: number }[]
  >([]);

  useEffect(function getTrusts() {
    if (currentTrustId == undefined) {
      TrustDataService.getAllTrusts().then((trusts: any) => {
        const dropdownValues = trusts.map((trust: TrustDetailedDTO) => ({
          label: trust.trustName,
          value: trust.id,
        }));
        dropdownValues.unshift({ label: "All", value: null });
        setAllTrusts(dropdownValues);
      });
    }
  }, []);

  // ClinicEventTable State
  const [clinicResults, setClinicResults] = useState<ClinicChangesetDTO[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [clinicFilter, setClinicFilter] =
    useState<ClinicChangesetSearchFilterDTO>(defaultSearchFilter);
  const [clinicDetailsLookup, setClinicDetailsLookup] =
    useState<ClinicDetailsLookup>({});

  useEffect(
    function getClinicResults() {
      if (selectedEventType !== "clinic") return;
      if (currentTrustId == undefined) return;
      setIsLoading(true);
      EventLogDataService.getClinicResults(clinicFilter).then((results) => {
        setClinicResults(results.pagedResults);
        setTotalCount(results.totalCount);
        setIsLoading(false);
      });
    },
    [clinicFilter, selectedEventType]
  );
  /**
   * An api request is made and the clinicDetails array
   * is updated - triggering a rerender with the new data.
   * We hold the clinicDetails in state so that we don't
   * have to keep re-fetching it
   * @param {number[]} ids
   */
  function getClinicDetails(changesets: ChangesetBase[]): void {
    const ids = changesets.map((set) => set.id);
    const idsToFetch = ids.filter((id) => !clinicDetailsLookup[id]);
    const promises = idsToFetch.map((id) =>
      EventLogDataService.getClinicDetails(id)
    );
    Promise.all(promises).then((allDetails) => {
      const detailsObj = {};
      allDetails.forEach((element) => {
        detailsObj[element.id] = element;
      });
      setClinicDetailsLookup({
        ...clinicDetailsLookup,
        ...detailsObj,
      });
    });
  }

  // ReservationEventTable State
  const [reservationResults, setReservationResults] = useState<
    ReservationChangesetDTO[]
  >([]);
  const [reservationFilter, setReservationFilter] =
    useState<ReservationChangesetSearchFilterDTO>(defaultSearchFilter);
  const [reservationDetailsLookup, setReservationDetailsLookup] = useState<
    ChangesetDetailsLookup<ReservationChangesetDetailedDTO>
  >({});

  useEffect(
    function getReservationResults() {
      if (selectedEventType !== "reservation") return;
      if (currentTrustId == undefined) return;
      setIsLoading(true);
      EventLogDataService.getReservationResults(reservationFilter).then(
        (results) => {
          setReservationResults(results.pagedResults);
          setTotalCount(results.totalCount);
          setIsLoading(false);
        }
      );
    },
    [reservationFilter, selectedEventType]
  );
  /**
   * An api request is made and the reservationDetails array
   * is updated - triggering a rerender with the new data.
   * We hold the reservationDetails in state so that we don't
   * have to keep re-fetching it
   * @param {number[]} ids
   */
  function getReservationDetails(changesets: ChangesetBase[]): void {
    const ids = changesets.map((set) => set.id);
    const idsToFetch = ids.filter((id) => !reservationDetailsLookup[id]);
    const promises = idsToFetch.map((id) =>
      EventLogDataService.getReservationDetails(id)
    );
    Promise.all(promises).then((allDetails) => {
      const detailsObj = {};
      allDetails.forEach((element) => {
        detailsObj[element.id] = element;
      });
      setReservationDetailsLookup({
        ...reservationDetailsLookup,
        ...detailsObj,
      });
    });
  }

  const eventTypeOptions = [
    { label: "Clinics", value: "clinic" },
    { label: "Staff Bookings", value: "reservation" },
  ];
  const dataLoaded = clinicResults.length > 0 || reservationResults.length > 0;

  return (
    <>
      <Grid container direction="row" justifyContent="space-between">
        <h1>Change Log</h1>
      </Grid>
      <Help helpCopyKey="5.6" />
      <div className={styles.eventLogActions}>
        {isSuperAdmin && (
          <DropdownInput
            inputFieldProps={{
              id: "TrustId",
              name: "trustId",
              value: allTrusts.find((trust) => trust.value == currentTrustId)
                ?.value,
              placeholder: "Select Trust",
              label: "Select Trust",
            }}
            options={[
              ...allTrusts.map((trust) => ({
                label: trust.label,
                value: trust,
              })),
            ]}
            setDropDownValue={(option: any) => {
              setCurrentTrustId(option.value);
              setClinicFilter({ ...clinicFilter, trustId: option.value.value });
            }}
          />
        )}
        <DropdownInput
          style={{ width: "8em" }}
          inputFieldProps={{
            id: "EventType",
            name: "eventType",
            value:
              eventTypeOptions.find((et) => et.value == selectedEventType) ||
              null,
            placeholder: "Select Event Type",
            label: "Select Event Type",
          }}
          options={eventTypeOptions}
          setDropDownValue={(option: any) => {
            setSelectedEventType(option.value);
          }}
        />
        <div className={styles.filterDropdown}>
          <DropdownInput
            inputFieldProps={{
              placeholder: "All",
              label: "Select Change Type",
            }}
            options={changesetTypeOptions.filter(
              (opt) =>
                opt.eventType === selectedEventType || opt.eventType === null
            )}
            setDropDownValue={(option: any) => {
              if (selectedEventType === "reservation") {
                setReservationFilter({
                  ...reservationFilter,
                  ...{ type: option.value },
                });
              } else if (selectedEventType === "clinic") {
                setClinicFilter({
                  ...clinicFilter,
                  ...{ type: option.value },
                });
              }
            }}
            inputVariant="outlined"
          />
        </div>
      </div>
      <div className={"styles.eventLogTable"}>
        {!isLoading && selectedEventType === "clinic" && (
          <>
            <ChangesetFilter
              {...{
                setChangesetFilter: setClinicFilter,
                changesetFilter: clinicFilter,
                eventType: selectedEventType,
              }}
            ></ChangesetFilter>
            <ClinicTable
              {...{
                clinicResults,
                totalCount,
                setClinicFilter,
                clinicFilter,
                getClinicDetails,
                clinicDetailsLookup,
              }}
            />
          </>
        )}
        {!isLoading && selectedEventType === "reservation" && (
          <>
            <ChangesetFilter
              {...{
                setChangesetFilter: setReservationFilter,
                changesetFilter: reservationFilter,
                eventType: selectedEventType,
              }}
            ></ChangesetFilter>

            <ReservationTable
              {...{
                reservationResults,
                totalCount,
                setReservationFilter,
                reservationFilter,
                getReservationDetails,
                reservationDetailsLookup,
              }}
            />
          </>
        )}
        {isLoading && (
          <div className="interstitial">
            <CircularProgress />
          </div>
        )}
      </div>
    </>
  );
}
