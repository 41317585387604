import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
} from "@material-ui/core";
import Settings from "@material-ui/icons/Settings";
import { FunctionComponent } from "react";
import { useHistory } from "react-router-dom";

const ListItem: FunctionComponent<{
  item: Campaign;
}> = ({ item }) => {
  const history = useHistory();
  return (
    <Grid item xs={12} md={4}>
      <Card>
        <CardContent>
          <p>
            Click the 'Assignments' button below to assign 'Frontline' and
            'ImmForm' status by ESR staff group, ESR job role or Non ESR staff
            group.
          </p>
          <p>
            You can also assign 'Frontline' and 'ImmForm' status for individual
            staff records using the toggle buttons on the staff page.
          </p>
          <p>
            Please note, if you upload any 'Frontline' or 'ImmForm' statuses
            from the Staff List file by putting either a 'Yes' or 'No' in the
            'Frontline' and 'ImmForm' columns, these will overwrite the settings
            on this page or any changes you've made using the individual toggles
            on the staff page.
          </p>
        </CardContent>

        <Divider light />

        <CardActions>
          <Button
            color={"primary"}
            variant={"contained"}
            startIcon={<Settings />}
            onClick={() => {
              history.push(`/settings/immform/${item.id}`);
            }}
          >
            Frontline & ImmForm Assignments
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default ListItem;
