import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import ApiAlert from "../../components/ApiAlert";
import { parseIsoDatetime } from "../../components/Fields";
import { isEmptyOfValues, lowerCaseFirstLetter } from "../../lib/common_utils";
import DataClient from "../../lib/services/api/DataClient";
import {
  getOrganisationsFromTrust,
  getTrustFromRegistrationSegment,
} from "../../lib/services/TrustSwitcher";
import styles from "./Register.module.scss";
import {
  dateOfBirthOver16,
  required,
  validateAllTheThings,
  validateHasNhsNumberOrPostCode,
  validateNhsNumber,
  validatePhoneNumber,
} from "../../lib/validationRules";
import Copy from "../../components/Copy";
const RECAPTCHA_SITE_KEY = "6LcoR2QhAAAAAJq6rPqnPgY2C3UM8rprjBhSIrJI";
export default (): JSX.Element => {
  const { registrationSegment } = useParams<{ registrationSegment: string }>();
  const {
    control,
    // formState: { errors },
    formState,
    handleSubmit,
  } = useForm({
    defaultValues: {
      firstName: null,
      lastName: null,
      dateOfBirth: null,
      emailAddress: null,
      level1: null,
      level2: null,
      lineManager: null,
      employeeNumber: null,
      nhsNumber: null,
      mobileNumber: null,
      postCode: null,
    },
  });
  const [trust, setTrust] = useState(null);
  const [organisations, setOrganisations] = useState([]);
  const [errors, setErrors] = useState(null);
  const [success, setSuccess] = useState<successDTO>(null);
  const [processing, setProcessing] = useState(true);
  const [alert, setAlert] = useState(null);
  useEffect(recaptchaLoadingScript, []);
  useEffect(() => {
    (async () => {
      const trust = await getTrustFromRegistrationSegment(
        registrationSegment
      ).catch((e) => {
        setAlert(
          ApiAlert({
            alwaysShow: true,
            severity: "error",
            title: "Trust Error",
            message: e?.response?.data
              ? e.response.data
              : "Unknown error with trust",
          })
        );
      });
      setTrust(trust);
      console.log(":: ~ trust:", trust);
    })();
  }, []);
  useEffect(() => {
    (async () => {
      const organisations = await getOrganisationsFromTrust(trust.id).catch(
        (e) => {
          console.log(":: ~ organisation error:", { trust, e });
          setAlert(
            ApiAlert({
              alwaysShow: true,
              severity: "error",
              title: "Trust Organisations Error",
              message: e?.response?.data
                ? e.response.data
                : "Unknown error fetching organisations for thi strust",
            })
          );
        }
      );
      setOrganisations(organisations);
      setProcessing(false);
    })();
  }, [trust]);
  // /TrustWorker/Register with trust in header(!) (figured out from url param)
  // Returning like
  // [ {propertyName: "PostCode", errorMessage: "'Post Code' is not in the correct format."} ]
  // Use <ApiAlert /> thereafter
  // TODO: Validation for NHS number, etc as API doesn't care, and postcode/nhs check
  const onSubmit = async (formData: SelfRegistrationData) => {
    //@ts-ignore
    window.grecaptcha.ready(function () {
      //@ts-ignore
      window.grecaptcha
        .execute(`${RECAPTCHA_SITE_KEY}`, {
          action: "submit",
        })
        .then(async function (recaptchaToken: string) {
          // Add your logic to submit to your backend server here.
          setProcessing(true);
          formData.trustId = trust.id;
          formData.dateOfBirth = parseIsoDatetime(formData.dateOfBirth);
          console.log("::onSubmit", formData);
          const errorsClientSide = validateAllTheThings(formData, [
            {
              field: "firstName",
              tests: [required({ message: "Please fill in First Name" })],
            },
            {
              field: "lastName",
              tests: [required({ message: "Please fill in Last Name" })],
            },
            {
              field: "dateOfBirth",
              tests: [dateOfBirthOver16],
            },
            {
              field: "emailAddress",
              tests: [required({ message: "Please fill in Email Address" })],
            },
            {
              field: "mobileNumber",
              tests: [validatePhoneNumber],
            },
            {
              field: "postCode",
              tests: [validateHasNhsNumberOrPostCode],
            },
            {
              field: "nhsNumber",
              tests: [validateHasNhsNumberOrPostCode, validateNhsNumber],
            },
            {
              field: "level1",
              tests: [required({ message: "Please fill in Organisation" })],
            },
            {
              field: "level2",
              tests: [required({ message: "Please fill in Job Role" })],
            },
            {
              field: "lineManager",
              tests: [required({ message: "Please fill in Line Manager" })],
            },
          ]);
          setErrors(errorsClientSide);
          setAlert(null);
          if (!isEmptyOfValues(errorsClientSide)) {
            setProcessing(false);
            return false;
          }
          const dataToSend: SelfRegistrationDTO = {
            recaptchaToken,
            model: formData,
          };
          // TODO: Trim some stuff?
          const request = await DataClient.call({
            url: `/TrustWorker/Register`,
            method: "POST",
            data: dataToSend,
            headers: { trustId: dataToSend.model.trustId },
          }).client.catch((e: any) => {
            console.error(e);
            console.log("::catch", e?.response?.data);
            let err = [];
            // GREAT. So errors come back in 5 completely different formats, and with different keys, in diff situations, i.e:
            // 1. DoB in wrong format - { errors: { $.dateOfBirth: ['error'] } }
            if (e?.response?.data?.errors) {
              err = e?.response?.data?.errors;
              for (const key in err) {
                err[key.replace("$.", "")] = err[key].join("\n");
                delete err[key];
              }
            }
            // 2. Duplicate account details, { success: false, results: ['error'] }
            else if (e?.response?.data?.success === false) {
              // If matching an Archived user dd#4873
              if (e?.response?.data?.results.targetIsArchived) {
                setAlert(
                  ApiAlert({
                    alwaysShow: true,
                    title: "Unable to Register",
                    message: (
                      <>
                        <p>
                          Unfortunately, we have not been able to register you
                          on VaccinationTrack.
                        </p>
                        <p>
                          This is because you already have an account matching
                          some of the details you provided. Please:{" "}
                        </p>
                        <ul>
                          <li>
                            Try again using the email you used to register
                            previously
                          </li>
                          <li>
                            Make sure your date of birth you entered is correct
                          </li>
                        </ul>
                        <p>
                          If you are still having issues, please contact your
                          line manager for assistance.
                        </p>
                      </>
                    ),
                  })
                );
              } else {
                setAlert(
                  ApiAlert({
                    alwaysShow: true,
                    title: "Unable to Register",
                    message: (
                      <>
                        <p>
                          Unfortunately, we have not been able to register you
                          on VaccinationTrack.
                        </p>
                        <p>
                          This is because you already have an account matching
                          the details you provided.{" "}
                          <a
                            style={{ color: "inherit" }}
                            href="/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Click here and try logging in
                          </a>{" "}
                          or{" "}
                          <a
                            style={{ color: "inherit" }}
                            href="/Identity/Account/ForgotPassword"
                            target="_blank"
                            rel="noreferrer"
                          >
                            reset your password
                          </a>{" "}
                          to gain access
                        </p>
                        <p>
                          If your name and date of birth match but the email
                          address you provided is different to the one on your
                          existing account, the administrator will be notified
                          and may update your record and resend you an email
                          inviting you to book.
                        </p>
                        <p>
                          If you are sure you do not have an existing account
                          please contact your line manager for assistance.
                        </p>
                      </>
                    ),
                  })
                );
              }
            }
            // 3. Postcode invalid - [ { propertyName: "PostCode", errorMessage: "'Post Code' is not in the correct format." } ]
            else if (
              Array.isArray(e?.response?.data) &&
              e.response.data.length > 0
            ) {
              err = e?.response?.data.reduce(
                (obj: any, item: any) =>
                  Object.assign(obj, {
                    [lowerCaseFirstLetter(item.propertyName)]:
                      item.errorMessage,
                  }),
                {}
              );
              console.error(err);
            }
            // 4. Recaptcha - [ { propertyName: "PostCode", errorMessage: "'Post Code' is not in the correct format." } ]
            else if (
              e?.response?.data?.propertyName &&
              e?.response?.data?.propertyName["Recaptcha"]
            ) {
              err = e?.response?.data.reduce(
                (obj: any, item: any) =>
                  Object.assign(obj, {
                    [lowerCaseFirstLetter(item.propertyName)]:
                      item.errorMessage,
                  }),
                {}
              );
              console.log(`err: `, err);
            }
            // 5. 404 error trust does not exist
            else if (e?.response?.status == 404) {
              err = [{ notFound: e?.response.data }];
            }
            setErrors(err);
            setSuccess(null);
          });
          if (request?.success) {
            setSuccess(request);
          }
          setProcessing(false);
        });
    });
  };
  return (
    <main className={`wrapper-main`}>
      <div className={styles.paper}>
        {alert}
        {!trust && !alert ? (
          <CircularProgress />
        ) : success ? (
          <div style={{ maxWidth: "39em" }}>
            <h1>Thank You {success?.results?.firstName}</h1>
            <p>
              You will very shortly receive an email confirming we’ve received
              your request to register with VaccinationTrack.
            </p>
            <p>
              Once your account has been approved by the administrator, you will
              be sent another email. You should receive this within 24 hours but
              it may take longer over weekends. Be sure to check your junk
              folder or add{" "}
              <Copy
                {...{
                  copyContent: "bookings@vaccinationtrack.com",
                  iconAlign: "right",
                }}
              >
                <strong>bookings@vaccinationtrack.com</strong>
              </Copy>{" "}
              to your list of safe senders.
            </p>
          </div>
        ) : trust ? (
          <>
            <h1>Register with {trust?.trustName}</h1>
            <p>
              Please fill in your details to register with Vaccination Track.
              Once approved, you will be able to:
            </p>
            <ul>
              <li>Book a vaccination appointment</li>
              <li>Let us know you've already been vaccinated elsewhere</li>
              <li>
                Decline the vaccination(s) if you're unable to be vaccinated for
                medical reasons or if you do not wish to be vaccinated
              </li>
            </ul>
            <form
              className={styles.form}
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
            >
              <fieldset disabled={processing}>
                <div>
                  <section>
                    <Controller
                      name="firstName"
                      control={control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          {...rest}
                          {...{
                            label: "First Name *",
                            variant: "outlined",
                            borderFillColor: "white",
                            fullWidth: true,
                            error:
                              !!errors?.firstName ||
                              !!formState?.errors?.firstName,
                            helperText:
                              errors?.firstName ||
                              formState?.errors?.firstName?.message,
                          }}
                        />
                      )}
                    />
                  </section>
                  <section>
                    <Controller
                      name="lastName"
                      control={control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          {...rest}
                          {...{
                            label: "Last Name *",
                            variant: "outlined",
                            fullWidth: true,
                            error:
                              !!errors?.lastName ||
                              !!formState?.errors?.lastName,
                            helperText:
                              errors?.lastName ||
                              formState?.errors?.lastName?.message,
                          }}
                        />
                      )}
                    />
                  </section>
                  <section>
                    <Controller
                      name="dateOfBirth"
                      control={control}
                      render={({ field: { ref, ...rest } }) => (
                        <KeyboardDatePicker
                          {...rest}
                          {...{
                            label: "Date of Birth *",
                            placeholder: "dd/mm/yyyy",
                            inputVariant: "outlined",
                            fullWidth: true,
                            autoOk: true,
                            format: "DD/MM/YYYY",
                            disableFuture: true,
                            minDate: new Date("1900-01-01"),
                            maxDate: moment().subtract(16, "years"),
                            error:
                              !!errors?.dateOfBirth ||
                              !!formState?.errors?.dateOfBirth,
                            helperText:
                              errors?.dateOfBirth ||
                              formState?.errors?.dateOfBirth?.message,
                            disabled: processing,
                          }}
                        />
                      )}
                    />
                  </section>
                  <section>
                    <Controller
                      name="emailAddress"
                      control={control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          {...rest}
                          {...{
                            label: "Email Address *",
                            type: "email",
                            variant: "outlined",
                            fullWidth: true,
                            error:
                              !!errors?.emailAddress ||
                              !!formState?.errors?.emailAddress,
                            helperText:
                              errors?.emailAddress ||
                              formState?.errors?.emailAddress?.message,
                          }}
                        />
                      )}
                    />
                  </section>
                  <section>
                    <Controller
                      name="mobileNumber"
                      control={control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          {...rest}
                          {...{
                            label: "Mobile Number",
                            variant: "outlined",
                            fullWidth: true,
                            error:
                              !!errors?.mobileNumber ||
                              !!formState?.errors?.mobileNumber,
                            helperText:
                              errors?.mobileNumber ||
                              formState?.errors?.mobileNumber?.message,
                          }}
                        />
                      )}
                    />
                  </section>
                  <section>
                    <Controller
                      name="postCode"
                      control={control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          {...rest}
                          {...{
                            label: "Post Code",
                            variant: "outlined",
                            fullWidth: true,
                            error:
                              !!errors?.postCode ||
                              !!formState?.errors?.postCode,
                            helperText:
                              errors?.postCode ||
                              formState?.errors?.postCode?.message,
                          }}
                        />
                      )}
                    />
                  </section>
                </div>
                <div>
                  <section>
                    <Controller
                      name="level1"
                      control={control}
                      render={({ field: { ref, ...rest } }) => (
                        // <TextField
                        //   {...rest}
                        //   {...{
                        //     label: "Organisation *",
                        //     variant: "outlined",
                        //     fullWidth: true,
                        //     error:
                        //       !!errors?.level1 || !!formState?.errors?.level1,
                        //     helperText:
                        //       errors?.level1 ||
                        //       formState?.errors?.level1?.message,
                        //   }}
                        // />
                        // react-hook-form mui select
                        <FormControl
                          variant="outlined"
                          fullWidth
                          error={
                            !!errors?.level1 || !!formState?.errors?.level1
                          }
                        >
                          {organisations.length === 0 ? (
                            // Free text field if no Custom Organisations set

                            <TextField
                              {...rest}
                              {...{
                                label: "Organisation *",
                                variant: "outlined",
                                fullWidth: true,
                                error:
                                  !!errors?.level1 ||
                                  !!formState?.errors?.level1,
                                helperText:
                                  errors?.level1 ||
                                  formState?.errors?.level1?.message,
                              }}
                            />
                          ) : (
                            // Select field if Custom Organisations set
                            <>
                              <InputLabel id="level1-label">
                                Organisation *
                              </InputLabel>
                              <Controller
                                name="level1"
                                control={control}
                                render={({ field: { ref, ...rest } }) => (
                                  <Select
                                    {...rest}
                                    {...{
                                      labelId: "level1-label",
                                      label: "Organisation *",
                                      variant: "outlined",
                                      fullWidth: true,
                                      error:
                                        !!errors?.level1 ||
                                        !!formState?.errors?.level1,
                                      helperText:
                                        errors?.level1 ||
                                        formState?.errors?.level1?.message,
                                    }}
                                  >
                                    {organisations.map((o) => (
                                      <MenuItem key={o.name} value={o.name}>
                                        {o.name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                )}
                              />
                            </>
                          )}
                        </FormControl>
                      )}
                    />
                  </section>
                  <section>
                    <Controller
                      name="level2"
                      control={control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          {...rest}
                          {...{
                            label: "Job Role *",
                            variant: "outlined",
                            fullWidth: true,
                            error:
                              !!errors?.level2 || !!formState?.errors?.level2,
                            helperText:
                              errors?.level2 ||
                              formState?.errors?.level2?.message,
                          }}
                        />
                      )}
                    />
                  </section>
                  <section>
                    <Controller
                      name="lineManager"
                      control={control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          {...rest}
                          {...{
                            label: "Line Manager *",
                            variant: "outlined",
                            fullWidth: true,
                            error:
                              !!errors?.lineManager ||
                              !!formState?.errors?.lineManager,
                            helperText:
                              errors?.lineManager ||
                              formState?.errors?.lineManager?.message,
                          }}
                        />
                      )}
                    />
                  </section>
                  <section>
                    <Controller
                      name="employeeNumber"
                      control={control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          {...rest}
                          {...{
                            label: "Employee Number",
                            variant: "outlined",
                            fullWidth: true,
                            error:
                              !!errors?.employeeNumber ||
                              !!formState?.errors?.employeeNumber,
                            helperText:
                              errors?.employeeNumber ||
                              formState?.errors?.employeeNumber?.message,
                          }}
                        />
                      )}
                    />
                  </section>
                  <section>
                    <Controller
                      name="nhsNumber"
                      control={control}
                      render={({ field: { ref, ...rest } }) => (
                        <>
                          <TextField
                            {...rest}
                            {...{
                              label: "NHS Number",
                              variant: "outlined",
                              fullWidth: true,
                              error:
                                !!errors?.nhsNumber ||
                                !!formState?.errors?.nhsNumber,
                              helperText:
                                errors?.nhsNumber ||
                                formState?.errors?.nhsNumber?.message,
                            }}
                          />
                        </>
                      )}
                    />
                  </section>
                </div>
              </fieldset>
              {processing ? (
                <CircularProgress />
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  size="small"
                  // disabled={disabled}
                >
                  Submit
                </Button>
              )}
              {errors?.recaptcha && (
                <p className="formError">ReCaptcha failed - please retry</p>
              )}
              {errors && errors[0]?.notFound && (
                <p className="formError">Trust not found</p>
              )}
            </form>
          </>
        ) : (
          ""
        )}
      </div>
    </main>
  );
};
/** @link: https://www.cluemediator.com/how-to-implement-recaptcha-v3-in-react*/
const recaptchaLoadingScript = () => {
  const loadScriptByURL = (id: string, url: string, callback: () => void) => {
    const isScriptExist = document.getElementById(id);
    if (!isScriptExist) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      script.id = id;
      script.onload = function () {
        if (callback) callback();
      };
      document.body.appendChild(script);
    }
    if (isScriptExist && callback) callback();
  };
  // load the script by passing the URL
  loadScriptByURL(
    "recaptcha-element-id",
    `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`,
    function () {
      console.log("ReCaptcha script loaded!");
    }
  );
};
interface SelfRegistrationDTO {
  recaptchaToken: string;
  model: SelfRegistrationData;
}
interface SelfRegistrationData {
  trustId?: number;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  emailAddress: string;
  /** Organisation */
  level1: string;
  /** Job role */
  level2: string;
  lineManager: string;
  employeeNumber?: string;
  nhsNumber?: string;
  postCode?: string;
  mobileNumber?: string;
}
interface successDTO {
  success: boolean;
  results: {
    id: string;
    firstName: string;
    lastName: string;
    pathogens: number[];
  };
}
