import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from "@material-ui/pickers";
import moment from "moment";
import { toZeroOffsetMidnightIsoString } from "../lib/common_utils";
/**
 * Date picker that force the date to be at 00:00+00.00 regardless of
 * the users browser clock and timezone
 *
 * @export
 * @param {KeyboardDatePickerProps} props
 * @return {*}  {JSX.Element}
 */
export default function KeyboardDatePickerDateOnly(
  props: KeyboardDatePickerProps
): JSX.Element {
  const { onChange: parentOnChange, ...other } = props;

  function manipulateDate(date: moment.Moment): void {
    parentOnChange(toZeroOffsetMidnightIsoString(date));
  }

  return (
    <KeyboardDatePicker
      {...other}
      format="DD/MM/YYYY"
      onChange={manipulateDate}
    />
  );
}
