import { KeyboardDatePicker } from "@material-ui/pickers";
import {
  ChangeSetTypeOption,
  ChangesetType,
  EventType,
  SearchFilterBase,
} from "./EventLogData";
import styles from "./EventLog.module.css";
import { TextField } from "@material-ui/core";
import { useState } from "react";

export const changesetTypeOptions: ChangeSetTypeOption[] = [
  { label: "All", value: null, eventType: null },
  {
    label: "Create single clinic",
    value: ChangesetType.CreateSingleClinic,
    eventType: "clinic",
  },
  {
    label: "Update single clinic",
    value: ChangesetType.UpdateSingleClinic,
    eventType: "clinic",
  },
  {
    label: "Cancel single clinic",
    value: ChangesetType.CancelSingleClinic,
    eventType: "clinic",
  },
  {
    label: "Create recurring clinc",
    value: ChangesetType.CreateRecurringClinc,
    eventType: "clinic",
  },
  {
    label: "Update recurring clinic",
    value: ChangesetType.UpdateRecurringClinic,
    eventType: "clinic",
  },
  {
    label: "Cancel recurring clinic",
    value: ChangesetType.CancelRecurringClinic,
    eventType: "clinic",
  },
  {
    label: "Suspend clinic",
    value: ChangesetType.SuspendClinic,
    eventType: "clinic",
  },
  {
    label: "Enable clinic",
    value: ChangesetType.EnableClinic,
    eventType: "clinic",
  },
  {
    label: "Add clinic vaccination batch",
    value: ChangesetType.AddClinicVaccinationBatch,
    eventType: "clinic",
  },
  {
    label: "Delete clinic vaccination batch",
    value: ChangesetType.DeleteClinicVaccinationBatch,
    eventType: "clinic",
  },
  {
    label: "Update clinic vaccination batch",
    value: ChangesetType.UpdateClinicVaccinationBatch,
    eventType: "clinic",
  },
  {
    label: "Update primary clinic vaccination batch",
    value: ChangesetType.UpdatePrimaryClinicVaccinationBatch,
    eventType: "clinic",
  },
  {
    label: "Split clinic",
    value: ChangesetType.SplitClinic,
    eventType: "clinic",
  },
  {
    label: "Close Clinic",
    value: ChangesetType.CloseClinic,
    eventType: "clinic",
  },
  // Reservations
  {
    label: "Book Appointment",
    value: ChangesetType.CreateReservation,
    eventType: "reservation",
  },
  {
    label: "Reschedule Appointment",
    value: ChangesetType.RescheduleReservation,
    eventType: "reservation",
  },
  {
    label: "Cancel Appointment",
    value: ChangesetType.CancelReservation,
    eventType: "reservation",
  },
];

export function ChangesetFilter<
  ChangesetTypeSearchFilterDTO extends SearchFilterBase
>({
  changesetFilter,
  setChangesetFilter,
  eventType,
}: {
  changesetFilter: ChangesetTypeSearchFilterDTO;
  setChangesetFilter: (newValue: ChangesetTypeSearchFilterDTO) => void;
  eventType: EventType;
}): JSX.Element {
  // We need to control the search inputs state here, so the input is controlled by react. However we do not want to call the setChangesetFilter function on every keystroke, so we need to debounce it.
  const [currentSearchText, setCurrentSearchText] = useState<string>(
    changesetFilter.searchText || ""
  );

  const [timeoutId, setTimeoutId] = useState<number>(0);

  const updateChangesetFilter = (newValue: any) => {
    setChangesetFilter({ ...changesetFilter, ...newValue });
  };

  const updateSearchText = (newSearchText: string) => {
    setCurrentSearchText(newSearchText);
    clearTimeout(timeoutId);
    setTimeoutId(
      window.setTimeout(() => {
        updateChangesetFilter({ searchText: newSearchText });
      }, 500)
    );
  };

  return (
    <>
      <div className={styles.filterContainer}>
        {/* // Moved to the top line of filters on EventLof.tsx */}
        {/* <div className={styles.filterDropdown}>
          <DropdownInput
            styles={{ width: "28em" }}
            inputFieldProps={{
              placeholder: "Select Change Type",
              label: "Select Change Type",
            }}
            options={changesetTypeOptions.filter(
              (opt) => opt.eventType === eventType || opt.eventType === null
            )}
            setDropDownValue={(option: any) => {
              updateChangesetFilter({ type: option.value });
            }}
            inputVariant="outlined"
          />
        </div> */}
        <div className={styles.filterDateInput}>
          <KeyboardDatePicker
            inputVariant="outlined"
            InputLabelProps={{ shrink: true }}
            label="Start Date"
            autoOk={true}
            format="DD/MM/YYYY"
            disableFuture={true}
            value={changesetFilter.dateFrom}
            onChange={(date: moment.Moment, value) =>
              updateChangesetFilter({ dateFrom: date.toISOString() })
            }
            style={{ background: "white" }}
          />
        </div>
        <div className={styles.filterDateInput}>
          <KeyboardDatePicker
            label="End Date"
            autoOk={true}
            format="DD/MM/YYYY"
            disableFuture={true}
            value={changesetFilter.dateTo}
            onChange={(date: moment.Moment, value) =>
              updateChangesetFilter({ dateTo: date.toISOString() })
            }
            inputVariant="outlined"
            InputLabelProps={{ shrink: true }}
            style={{ background: "white" }}
          />
        </div>
        <div className={styles.filterSearchfield}>
          <TextField
            variant="outlined"
            label="Search"
            autoFocus={true}
            value={currentSearchText}
            onChange={(event) => updateSearchText(event.target.value)}
            fullWidth={true}
            InputLabelProps={{ shrink: true }}
            style={{ background: "white" }}
            type='search'
          />
        </div>
      </div>
    </>
  );
}
