import { Grid, LinearProgress } from "@material-ui/core";
import { FunctionComponent, useEffect, useState } from "react";
import NoResultsFound from "../../../../../components/Loader/no_results_found";
import ToastMessage from "../../../../../components/Universal/toast_message";
import SettingsContainer, { Feedback } from "../SettingsContainer";
import api from "./api";
import ListItem from "./ListItem";

const CampaignsList: FunctionComponent = () => {
  const [items, setItems] = useState<Campaign[]>(null);
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState<Feedback>(null);

  const fetchData = () => {
    setLoading(true);
    api
      .list()
      .then((items) => setItems(items))
      .catch((e) => {
        if (!e.handled) {
          setFeedback({
            type: "error",
            message: "Error fetching campaign list",
          });
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {loading && <LinearProgress color="primary" />}

      <SettingsContainer>
        <Grid
          container
          alignItems="center"
          spacing={2}
          justifyContent="space-between"
        >
          <Grid item>
            <h1>Frontline & ImmForm Assignments</h1>
          </Grid>
          <Grid item>
            {/* P3#2.2.2 Hide create campaign button (see commit for previous code) */}
          </Grid>
        </Grid>

        {!loading && items && items.length > 0 ? (
          <Grid container direction="row" alignItems="stretch" spacing={4}>
            {items.map((item, i) => {
              return <ListItem key={i} item={item} />;
            })}
          </Grid>
        ) : items !== null ? (
          <NoResultsFound message={"No Campaigns Available"} />
        ) : null}

        {feedback && (
          <ToastMessage
            variant={feedback.type}
            message={feedback.message}
            clear={() => setFeedback(null)}
          />
        )}
      </SettingsContainer>
    </>
  );
};

export default CampaignsList;
