import { Grid, List, ListItem } from "@material-ui/core";
import { NavLink } from "react-router-dom";

const links = [
  { name: "Manage Systems", url: "/trust-groups", exact: true },
  {
    name: "Manage System Users",
    url: "/users/trust-group-users",
    exact: false,
  }, // TODO
  { name: "Manage Trusts", url: "/trusts", exact: true },
  { name: "Manage Trust Admins", url: "/users/trust-admins", exact: false },
  {
    name: "Manage Super Admins",
    url: "/users/super-admins",
    exact: false,
  },
  {
    name: "Default Email & SMS",
    url: "/default-notifications",
    exact: false,
  },
  {
    name: "Change Log",
    url: "/change-log",
    exact: false,
  },
];

type NavContainerProps = {
  children: JSX.Element[] | JSX.Element;
};
/**
 * Super admin settings Navigation sidebar wrapper
 *
 * @export
 * @param {NavContainerProps} {
 *   children,
 * }
 * @return {*}  {JSX.Element}
 */
export default function NavContainer({
  children,
}: NavContainerProps): JSX.Element {
  return (
    <Grid container spacing={5}>
      <Grid item sm={2} className="nav-settings">
        <List component="nav">
          <ul>
            {links.map((l, i) => (
              <li>
                <ListItem
                  key={i}
                  component={(p) => <NavLink {...p} exact={l.exact} />}
                  to={l.url}
                >
                  {l.name}
                </ListItem>
              </li>
            ))}
          </ul>
        </List>
      </Grid>
      <Grid item sm={10}>
        {children}
      </Grid>
    </Grid>
  );
}
