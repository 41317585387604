import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import {
  ImmFormReportData,
  ImmFormRow,
} from "../DashboardApi";
import DashboardApi from "../DashboardApi";
import { Link } from "react-router-dom";

const color = {
  text: "#333",
  orange: "#fae8de",
  green: "#defae4",
  pink: "#fadef5",
  blue: "#def0fa",
};
const useStyles = makeStyles({
  perfTable: {
    borderCollapse: "collapse",
    marginBottom: 20,
    minWidth: 600,
    "& $tbody": {
      margin: 10,
    },
    "& $thead $tr $th": {
      color: "#fff",
      backgroundColor: "#009fe3",
      textAlign: "center",
      borderBottom: "none",
      lineHeight: 1,
      paddingLeft: 7,
    },
    "& $tbody $tr $td": {
      color: "#009fe3",
      textAlign: "center",
      borderBottom: "1px solid #ccc",
      paddingLeft: 7,
    },
    "& $tbody $tr $td:first-child": {
      textAlign: "left",
      maxWidth: 150,
    },
    "& $thead $tr $th:first-child": {
      textAlign: "left",
    },
  },
  perfTableFlu: {
    "& $thead $tr $th": {
      color: "#fff",
      backgroundColor: "#169bd8",
    },
    "& $tbody $tr $td": {
      color: "#169bd8",
    },
  },
  perfTableCovid: {
    "& $thead $tr $th": {
      color: "#fff",
      backgroundColor: "#c80089",
    },
    "& $tbody $tr $td": {
      color: "#c80089",
    },
  },
  blankRow: {
    backgroundColor: "transparent",
    height: 25,
  },
  greyCell: {
    backgroundColor: "#eee",
  },
  whiteCell: {
    backgroundColor: "#fff",
  },
  rowTitleCell: {
    minWidth: 225,
    textAlign: "left",
  },
  compareTitle: {
    minWidth: 225,
  },
  headerGridRow: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  hidden: {
    display: "none",
  },
  dropDownContainer: {
    paddingLeft: 0,
  },
});

const groupingLabels = {
  allDoctors: (<p>All Doctors</p>),
  qualifiedNurses: (<p>Qualified Nurses, midwives and health visitors</p>),
  allOtherPros: (<>
    <p>All other professionally qualified clinical staff, which comprises of:</p>
    <ul>
      <li>Qualified scientific, therapeutic & technical staff (ST&T)</li>
      <li>Qualified allied health professionals (AHPs)</li>
      <li>Other qualified ST&T</li>
      <li>Qualified ambulance staff</li>
    </ul>
  </>),
  supportClinicalStaff: (<>
    <p>Support to Clinical Staff, which comprises of:</p>
    <ul>
      <li>Support to doctors & nurses</li>
      <li>Support to ST&T staff</li>
      <li>Support to ambulance staff</li>
    </ul>
  </>),
  supportGpStaff: (<p>Support to GP staff</p>),
};

export const ImmFormReport = (props: any) => {
  const api = DashboardApi;
  const [immFormReport, setReport] = useState(null as ImmFormReportData);

  const classes = useStyles();
  const pathogenClass = props.pathogenIds.indexOf('1') > -1
    ? "perfTableFlu"
    : "perfTableCovid";
  const [loading, setLoading] = useState(false);

  const getImmFormData = () => {
    setLoading(true);
    api
      .immFormReport(
        props.pathogenIds,
        props.onlyFLHCW,
        props.trustId,
        props.excludeL1Ids
      )
      .then((res: ImmFormReportData) => {
        setReport(res);
      })
      // .catch((e) => {
      //   if (!e.handled) {
      //   }
      // })
      .finally(() => setLoading(false));
  };

  const getPercentageVaccinated = (immRow: ImmFormRow) => {
    if (immRow.vaccinated == null || immRow.vaccinated < 1) {
      return 0;
    }

    return (immRow.vaccinated / immRow.total) * 100;
  };

  const getTotalImmFormWorkers = () => {
    return immFormReport.totalImmFormWorkers;
  };
  const getTotalImmFormVaccinated = () => {
    return (
      immFormReport.immFormEsrCategoryTotals.vaccinated +
      immFormReport.immFormNonEsrGroupTotals.vaccinated
    );
  };
  const getTotalImmFormPercentageVaccinated = () => {
    const vaccinated = getTotalImmFormVaccinated();
    const total = getTotalImmFormWorkers();
    if (vaccinated < 1) return 0;

    return (vaccinated / total) * 100;
  };

  const getTotalNonImmFormWorkers = () => {
    return immFormReport.totalNonImmFormWorkers;
  };
  const getTotalNonImmFormVaccinated = () => {
    return (
      immFormReport.nonImmFormEsrCategoryTotals.vaccinated +
      immFormReport.nonImmFormNonEsrGroupTotals.vaccinated
    );
  };
  const getTotalNonImmFormPercentageVaccinated = () => {
    const vaccinated = getTotalNonImmFormVaccinated();
    const total = getTotalNonImmFormWorkers();
    if (vaccinated < 1) return 0;

    return (vaccinated / total) * 100;
  };

  useEffect(() => {
    getImmFormData();
  }, [api, props]);
  if (immFormReport == null) return <> </>;
  return (
    <>
      <p>
        This page is a replica of sections 3-5 of the online ImmForm survey form.
      </p>
      <br />
      <p>
        <strong>3. Number of Healthcare Workers by Occupation Involved with DIRECT Patient Care ONLY</strong>
      </p>
      <p>
        Any staff who have their ImmForm assignment turned on will appear in this section.
      </p>
      <p>
        If you have staff in Non ESR staff groups, these are separated out
        in the light grey rows below.
        You can either add these into one of the most appropriate prescribed
        ImmForm staff group rows or you can subtract them from the total.
      </p>
      <p>
        If you wish to remove them completely from this section,
        you can turn their ImmForm assignment off either via the
        'ImmForm' column on the staff list upload file, on
        the <Link to="/settings/immform" target="_blank">
        Frontline & ImmForm Assignments</Link> page in 'Settings'
        or by changing the ImmForm toggle next to individual
        staff records on the staff page.
      </p>
      <p>
        For more information on the different ways to set staff's ImmForm assignments
        see the 'Frontline & ImmForm Assignments' section in the user guide
        via the help icon in the top right.
      </p>
      <Table className={`${classes[pathogenClass]} ${classes.perfTable}`} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.rowTitleCell}>
              Occupation (HCWs that have left the Trust should be excluded from
              this section)
            </TableCell>
            <TableCell className={classes.rowTitleCell} width={"10%"}>
              No. Of HCWs involved <br /> with direct patient care
            </TableCell>
            <TableCell className={classes.rowTitleCell} width={"10%"}>
              No. Of HCWs vaccinated
              <br /> in this campaign
            </TableCell>
            <TableCell className={classes.rowTitleCell} width={"10%"}>
              Vaccine Uptake (%)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {immFormReport.immFormEsrCategories.map((row: ImmFormRow) => (
            <TableRow>
              <TableCell className={classes.whiteCell}>
                {row.name
                    ? groupingLabels[row.name]
                  : (<>Staff with no Staff Group/Job Role information on their record
                      <br />
                      <i>(An ESR Staff Group/Job Role is required
                      to assign staff into one of the above rows)</i>
                    </>)}
              </TableCell>
              <TableCell className={classes.whiteCell}>
                {row.total}
              </TableCell>
              <TableCell className={classes.whiteCell}>
                {row.vaccinated}
              </TableCell>
              <TableCell className={classes.whiteCell}>
                {getPercentageVaccinated(row).toFixed(0)}%
              </TableCell>
            </TableRow>
          ))}
          {immFormReport.immFormNonEsrGroups.map((row: ImmFormRow) => (
            <TableRow className={classes.greyCell}>
              <TableCell className={classes.greyCell}>
                {row.name
                  ? row.name + " (Non ESR Staff Group)"
                  : (<>No Non ESR Staff Group Assigned
                      <br />
                      <i>(A Non ESR Staff Group is required
                      to assign staff into one of the above rows)</i>
                    </>)}
              </TableCell>
              <TableCell className={classes.greyCell}>
                {row.total}
              </TableCell>
              <TableCell className={classes.greyCell}>
                {row.vaccinated}
              </TableCell>
              <TableCell className={classes.greyCell}>
                {getPercentageVaccinated(row).toFixed(0)}%
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableHead>
          <TableRow className={classes.whiteCell}>
            <TableCell className={classes.whiteCell}>
            Total Number of HCWs involved with Direct Patient Care (Including Non ESR Staff)
            </TableCell>
            <TableCell className={classes.whiteCell}>
              {getTotalImmFormWorkers()}
            </TableCell>
            <TableCell className={classes.whiteCell}>
              {getTotalImmFormVaccinated()}
            </TableCell>
            <TableCell className={classes.whiteCell}>
              {getTotalImmFormPercentageVaccinated().toFixed(0)}%
            </TableCell>
          </TableRow>
          <TableRow className={classes.blankRow}></TableRow>
        </TableHead>
      </Table>

      <p>
        <strong>4. Number of Healthcare Workers NOT INVOLVED with DIRECT Patient Care</strong>
      </p>
      <p>
        Any staff who have their ImmForm assignment turned off will appear in this section.
      </p>
      <p>
        Only the figures in the totals row at the bottom should be entered into the boxes
        in section 4 on the ImmForm website. The other rows are just for your information.
      </p>
      <p>
        Staff in Non ESR groups are separated out in light grey rows below.
        These can be subtracted from the total if necessary.
      </p>
      <Table className={`${classes[pathogenClass]} ${classes.perfTable}`} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.rowTitleCell}>
              Staff Type
            </TableCell>
            <TableCell className={classes.rowTitleCell} width={"10%"}>
              No. Of HCWs NOT involved
              <br /> with direct patient care
            </TableCell>
            <TableCell className={classes.rowTitleCell} width={"10%"}>
              No. Of HCWs NOT involved
              <br /> with direct patient care
              <br />vaccinated in this campaign
            </TableCell>
            <TableCell className={classes.rowTitleCell} width={"10%"}>
              Vaccine Uptake (%)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {immFormReport.nonImmFormEsrCategories.map((row: ImmFormRow) => (
            <TableRow className={classes.whiteCell}>
              <TableCell className={classes.whiteCell}>
                {row.name
                    ? groupingLabels[row.name]
                  : (<>Staff with no Staff Group/Job Role information on their record
                      <br />
                      <i>(An ESR Staff Group/Job Role is required
                      to assign staff into one of the above rows)</i>
                    </>)}
              </TableCell>
              <TableCell className={classes.whiteCell}>
                {row.total}
              </TableCell>
              <TableCell className={classes.whiteCell}>
                {row.vaccinated}
              </TableCell>
              <TableCell className={classes.whiteCell}>
                {getPercentageVaccinated(row).toFixed(0)}%
              </TableCell>
            </TableRow>
          ))}
          {immFormReport.nonImmFormNonEsrGroups.map((row: ImmFormRow) => (
            <TableRow className={classes.greyCell}>
              <TableCell className={classes.greyCell}>
                {row.name
                  ? row.name + " (Non ESR Staff Group)"
                  : (<>No Non ESR Staff Group Assigned
                      <br />
                      <i>(A Non ESR Staff Group is required
                      to assign staff into one of the above rows)</i>
                    </>)}
              </TableCell>
              <TableCell className={classes.greyCell}>
                {row.total}
              </TableCell>
              <TableCell className={classes.greyCell}>
                {row.vaccinated}
              </TableCell>
              <TableCell className={classes.greyCell}>
                {getPercentageVaccinated(row).toFixed(0)}%
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableHead>
          <TableRow className={classes.whiteCell}>
            <TableCell className={classes.whiteCell}>
            Total Number of HCWs NOT involved with Direct Patient Care (Including Non ESR Staff)
            </TableCell>
            <TableCell className={classes.whiteCell}>
              {getTotalNonImmFormWorkers()}
            </TableCell>
            <TableCell className={classes.whiteCell}>
              {getTotalNonImmFormVaccinated()}
            </TableCell>
            <TableCell className={classes.whiteCell}>
              {getTotalNonImmFormPercentageVaccinated().toFixed(0)}%
            </TableCell>
          </TableRow>
          <TableRow className={classes.blankRow}></TableRow>
        </TableHead>
      </Table>
    </>
  );
};
