import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentProps,
  DialogProps,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
} from "@material-ui/core";
import { FunctionComponent, ReactElement, useCallback } from "react";
import Checkbox from "./Checkbox";
import "./SteppedConfirmDialog.scss";
import {
  SteppedConfirmDialogStep,
  useSteppedConfirmDialog,
} from "./useSteppedConfirmDialog";

interface SteppedConfirmDialogProps {
  title: ReactElement | string;
  steps: SteppedConfirmDialogStep[];
  open: boolean;
  loading?: boolean;
  alert?: ReactElement | null;
  showConfirm?: boolean;
  showClose?: boolean;
  nextStepLabel?: string;
  previousStepLabel?: string;
  confirmLabel?: string;
  closeLabel?: string;
  disableConfirm?: boolean;
  disableClose?: boolean;
  fixedHeight?: string;
  dialogProps?: DialogProps;
  dialogContentProps?: DialogContentProps;
  onConfirm: () => void;
  onClose: () => void;
}

export const SteppedConfirmDialog: FunctionComponent<
  SteppedConfirmDialogProps
> = ({
  title,
  steps,
  open,
  loading = false,
  alert = null,
  showConfirm = true,
  showClose = true,
  nextStepLabel = "Next",
  previousStepLabel = "Back",
  confirmLabel = "Yes",
  closeLabel = "Cancel",
  disableConfirm = false,
  disableClose = false,
  fixedHeight,
  dialogProps,
  dialogContentProps,
  onConfirm,
  onClose,
}) => {
  const {
    activeStep,
    currentStep,
    isFirstStep,
    isLastStep,
    isCurrentStepConfirmed,
    setNextStep,
    setPreviousStep,
    resetCurrentStep,
    setIsCurrentStepConfirmed,
  } = useSteppedConfirmDialog({ steps });

  const curriedOnClose = useCallback(() => {
    onClose();
    resetCurrentStep();
  }, [onClose, resetCurrentStep]);

  return (
    <Dialog
      {...dialogProps}
      open={open}
      onClose={curriedOnClose}
      closeAfterTransition={true}
      PaperProps={{
        style: fixedHeight
          ? {
              height: "100%",
              maxHeight: fixedHeight,
            }
          : undefined,
      }}
    >
      <DialogTitle disableTypography>
        {/* Better that this style comes from the title itself, but it's deeply integrated into the enums */}
        <h1 style={{ marginBottom: 0 }}>{title}</h1>
      </DialogTitle>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps
          .filter((step) => !step.isHiddenFromStepper)
          .map((step) => (
            <Step key={step.label}>
              <StepLabel>
                {step.stepperLabel ? step.stepperLabel : step.label}
              </StepLabel>
            </Step>
          ))}
      </Stepper>
      <DialogContent {...dialogContentProps}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "1em",
            height: "100%",
          }}
        >
          <div style={{ flex: 1 }}>
            {alert && <Box flexGrow={1}>{alert}</Box>}
            {currentStep.content}
          </div>
          {currentStep.requiresConfirmation ? (
            <Checkbox
              label={currentStep.confirmationLabel}
              checked={isCurrentStepConfirmed}
              onChange={setIsCurrentStepConfirmed}
            />
          ) : null}
        </div>
      </DialogContent>
      {(showConfirm || showClose) && (
        <DialogActions>
          <Box width="100%" display="flex" justifyContent="space-between">
            {showClose ? (
              <Button
                onClick={curriedOnClose}
                variant="outlined"
                color="secondary"
                style={{
                  color: "#fff",
                }}
                disabled={loading || disableClose}
              >
                {closeLabel}
              </Button>
            ) : null}
            <Box
              display="flex"
              justifyItems="space-between"
              gridColumnGap="0.5rem"
            >
              {!isFirstStep ? (
                <Button
                  onClick={setPreviousStep}
                  type="button"
                  variant="outlined"
                  color="secondary"
                  style={{
                    color: "#fff",
                  }}
                >
                  {currentStep.previousStepLabel ?? previousStepLabel}
                </Button>
              ) : null}
              {showConfirm && !isLastStep ? (
                <Button
                  onClick={setNextStep}
                  type="button"
                  variant="contained"
                  color="secondary"
                  autoFocus
                  disabled={loading || !isCurrentStepConfirmed}
                >
                  {currentStep.nextStepLabel ?? nextStepLabel}
                </Button>
              ) : null}
              {showConfirm && isLastStep ? (
                <Button
                  onClick={onConfirm}
                  type="submit"
                  variant="contained"
                  color="secondary"
                  autoFocus
                  disabled={
                    loading || disableConfirm || !isCurrentStepConfirmed
                  }
                >
                  {loading && (
                    <CircularProgress
                      size={"1rem"}
                      style={{
                        margin: "0 .5em 0 0",
                      }}
                    />
                  )}
                  {currentStep.submitLabel ?? confirmLabel}
                </Button>
              ) : null}
            </Box>
          </Box>
        </DialogActions>
      )}
    </Dialog>
  );
};
