import ApiAlert from "./ApiAlert";
import {
  isTrustAdmin,
  isTrustWorker,
  isTrustAdminOrHigher,
} from "../lib/services/TrustSwitcher";
import { humanName } from "../lib/common_utils";
export const alertNoActiveCampaign = ({ involvesBookings = false } = {}) =>
  ApiAlert({
    alwaysShow: true,
    title: "No Active Campaign",
    message: (
      <p>
        {involvesBookings ? (
          // Remember! Clinic Admins don't have access to settings..
          isTrustAdmin() && !isTrustWorker() ? (
            <>
              You cannot book an appointment because you do not have an active
              staff account on VaccinationTrack.
              <br />
              Being a Trust Admin does not automatically give you a staff member
              account. Please add yourself as a staff member either via the
              staff list upload or by manually adding yourself from the Staff
              page.
            </>
          ) : isTrustAdminOrHigher() ? (
            <>
              Bookings cannot be made until an active campaign is set up. If you
              do have an active campaign and still see this message please log
              out and back in again to refresh your account.
            </>
          ) : (
            <>
              You must be added to the current campaign. Please contact your
              trust administrator.
            </>
          )
        ) : (
          <>An active campaign must be set up before you can use this page.</>
        )}
        <br />
        {isTrustAdmin() && (
          <>
            Please go to{" "}
            <a href="/settings" rel="noreferrer" style={{ color: "inherit" }}>
              Settings
            </a>{" "}
            to set up and activate your campaign.
          </>
        )}
      </p>
    ),
    severity: "error",
  });
export const alertTrustAdminNotTrustWorker = () =>
  ApiAlert({
    alwaysShow: true,
    title: "No Active Staff Account",
    message: (
      <p>
        You cannot book an appointment because you do not have an active staff
        record on VaccinationTrack. Being a Trust Admin does not automatically
        give you a staff member access.
        <br /> <br />
        It is possible your staff record has been accidentally archived or
        permanently deleted from the 'Staff' page.
        <br /> <br />
        If you have been archived, get another Trust Admin to restore your staff
        record by selecting the 'View Archived Staff' option in the 'Active /
        Inactive' drop down of 'Filters' on the 'Staff' page and then clicking
        the green restore icon next to your record.
        <br /> <br />
        If you have been permanently deleted, you need to be re-added as a staff
        member either via the staff list CSV upload or by using the 'Add Staff
        Manually' option from the 'Staff' page.
      </p>
    ),
    severity: "error",
  });
export const alertCancelAppointment = (activeReservation: any) =>
  ApiAlert({
    alwaysShow: true,
    title: "Successfully cancelled appointment",
    severity: "success",
    message: (
      <>
        {humanName(activeReservation.firstName, activeReservation.lastName)}{" "}
        &lt;
        {activeReservation.emailAddress}&gt;:{" "}
      </>
    ),
  });
export const alertCancelAppointmentError = (activeReservation: any) =>
  ApiAlert({
    alwaysShow: true,
    title: "Error",
    message: (
      <>
        {humanName(activeReservation.firstName, activeReservation.lastName)}{" "}
        &lt;
        {activeReservation.emailAddress}&gt;:{" "}
        <strong>Error cancelling appointment</strong>{" "}
      </>
    ),
  });
export const alertAddedPatient = (selectedPatientDetails: any) =>
  ApiAlert({
    alwaysShow: true,
    title: "Submitted",
    severity: "success",
    message: (
      <>
        Added:{" "}
        {humanName(
          selectedPatientDetails.firstName,
          selectedPatientDetails.lastName
        )}
      </>
    ),
  });
export const alertAddedPatientError = (
  selectedPatientDetails: any,
  errorMessage: string
) =>
  ApiAlert({
    alwaysShow: true,
    title: "Error",
    message: (
      <>
        {humanName(
          selectedPatientDetails.firstName,
          selectedPatientDetails.lastName
        )}{" "}
        &lt;{selectedPatientDetails.emailAddress}
        &gt;: <strong>{errorMessage}</strong>{" "}
      </>
    ),
  });
