import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid/Grid";
import { makeStyles } from "@material-ui/core/styles";
import LeftArrow from "@material-ui/icons/ArrowLeft";
import RightArrow from "@material-ui/icons/ArrowRight";
import moment from "moment";
import React from "react";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import { datePlusTime } from "../../../../../lib/common_utils";
import styles from "./AppointmentDetails.module.scss";

const MIN_WEEK_PIVOT = 0;
const MAX_WEEK_PIVOT = 52;

const useStyles = makeStyles((theme) => ({
  dateResponsiveness: {
    width: "100%",
  },
  dateScrollController: {
    overflowX: "hidden",
    overflowY: "hidden",
    [theme.breakpoints.down("sm")]: {
      overflowX: "scroll",
    },
  },
  gridDateTile: {
    width: `${100 / 7}%`,
    "& > button": {
      fontSize: "12px",
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& > p": {
      marginTop: "0.75rem",
    },
    [theme.breakpoints.down("sm")]: {
      width: "45%",
      flexShrink: 0,
    },
  },
  arrowLeft: {
    textAlign: "left",
  },
  arrowRight: {
    textAlign: "right",
  },
  dateTab: {
    fontWeight: "bold",
    fontSize: "14px",
    display: "block",
    width: "100%",
  },
  weekend: {
    backgroundColor: "#296ed8",
  },
  timeSlotButton: {
    minWidth: "90%",
    border: "1px solid rgba(63, 165, 53, 0.75)",
    boxShadow: "unset",
    color: "rgba(63, 165, 53, 1)",
    marginBottom: "10px",
    fontWeight: "unset !important",
    "&:hover": {
      fontWeight: "bold !important",
      backgroundColor: "rgba(63, 165, 53, 1)",
      color: "#fff",
    },
  },
  clinicContainer: {
    paddingBottom: "1rem",
    borderBottom: "1px solid gray",
  },
}));

const filterFutureClinics = (clinics) =>
  clinics.filter(({ date, timeTo }) => moment() <= datePlusTime(date, timeTo));

const groupByLocation = (arr) =>
  arr.reduce((acc, clinic, i) => {
    acc[clinic.clinicLocationName] = (
      acc[clinic.clinicLocationName] || []
    ).concat(arr[i]);
    return acc;
  }, {});

export default function SelectClinic({ context, send }) {
  const { clinics, weekPivot, clinicId, pathogens, startDate } = context;
  // console.log("::SelectClinic", startDate?.toString());
  const classes = useStyles();
  const buildTab = (prop) => {
    const availableClinics = prop.clinicData?.clinics.length
      ? prop.clinicData.clinics.filter(
          (c) => c.availableSlot + c.availableDropIns > 0
        )
      : [];
    const clinicsOnlyInFuture = filterFutureClinics(availableClinics);
    const locations = groupByLocation(clinicsOnlyInFuture);
    return (
      <Grid item key={prop.key} className={classes.gridDateTile}>
        <Button
          style={{ pointerEvents: "none" }}
          disableRipple
          variant="contained"
          fullWidth
          color="primary"
          className={prop.isWeekend ? classes.weekend : ""}
        >
          {prop.label}
        </Button>
        {/* ************** DEBUG */}
        {/* {prop?.date?.toFormat("dd/LL/y")} */}
        {Object.entries(locations).length ? (
          Object.entries(locations).map(([location, clinics]) => (
            <div key={`location-${location}`} className={styles.tab}>
              <span className={classes.dateTab}>{location}</span>
              {clinics.map((clinic) => (
                <>
                  {/* {clinic.date} */}
                  <button
                    key={`clinic-${clinic.id}`}
                    className={`${styles.button}${
                      clinic.id === clinicId ? ` ${styles.selected}` : ""
                    }`}
                    type="button"
                    onClick={() => {
                      send("SELECT_CLINIC", { id: clinic.id });
                    }}
                  >
                    <div style={{ marginTop: "-5px" }}>{clinic.duration}</div>
                  </button>
                </>
              ))}
            </div>
          ))
        ) : (
          <p>No available slots</p>
        )}
      </Grid>
    );
  };
  const createTabs = (clinicsData) => {
    let newTabs = [];
    // console.log("::createTabs", startDate.toString());
    for (let i = 0; i < 7; i++) {
      const date = startDate.plus({ days: i });
      const day = date.toFormat("ccc");
      newTabs.push(
        buildTab({
          date,
          label: (
            <div>
              <span className={classes.dateTab}>
                {/* {date.toLocaleString(DateTime.DATE_SHORT)} */}
                {date.toFormat("dd/LL/y")}
              </span>
              <div style={{ marginTop: "-5px" }}>{day}</div>
            </div>
          ),
          isWeekend: [6, 7].includes(parseInt(date.toFormat("c"))),
          key: i,
          clinicData: clinicsData[i],
        })
      );
    }
    return newTabs;
  };
  return (
    <ScrollSync>
      <div className={classes.clinicContainer}>
        <Grid container direction="row" style={{ display: "flex" }}>
          <Grid item xs={1}>
            <div className={classes.arrowLeft}>
              <Button
                title="Previous Week"
                // Removing disability for now, as it's prevented in stateMachine anyway
                // disabled={weekPivot === MIN_WEEK_PIVOT}
                style={{ minWidth: "50px" }}
                onClick={() => {
                  send("CALENDAR_BACKWARD");
                }}
              >
                <LeftArrow fontSize={"large"} color={"primary"} />
              </Button>
            </div>
          </Grid>
          <Grid item xs={10} className={classes.dateResponsiveness}>
            <ScrollSyncPane group="horizontal">
              <div className={classes.dateScrollController}>
                <Grid
                  className={"dateButtons"}
                  container
                  direction="row"
                  spacing={1}
                  style={{
                    display: "flex",
                    flexWrap: "nowrap",
                    textAlign: "center",
                  }}
                >
                  {createTabs(clinics ? clinics : [])}
                </Grid>
              </div>
            </ScrollSyncPane>
          </Grid>
          <Grid item xs={1}>
            <div className={classes.arrowRight}>
              <Button
                // Removing disability for now, as it's prevented in stateMachine anyway
                // disabled={weekPivot === MAX_WEEK_PIVOT}
                title="Next Week"
                style={{ minWidth: "30px" }}
                onClick={() => {
                  send("CALENDAR_FORWARD");
                }}
              >
                <RightArrow fontSize={"large"} color={"primary"} />
              </Button>
            </div>
          </Grid>
        </Grid>
        <Box
          display={{ xs: "block", md: "none" }}
          style={{
            textAlign: "center",
            padding: "10px 30px",
            fontSize: "12px",
          }}
        >
          (Scroll with your finger to change day or use the arrow to move by
          week)
        </Box>
      </div>
    </ScrollSync>
  );
}
