import React from "react";
import styles from "./Checkbox.module.scss";

type CheckboxProps = {
  label: string;
  alt?: boolean;
  checked: boolean;
  onChange: (checked: boolean) => void;
};

const Checkbox = function ({
  label,
  alt = false,
  checked,
  onChange,
}: CheckboxProps): JSX.Element {
  return (
    <label className={`${styles.checkbox} ${alt ? styles.alt : ""}`}>
      <span>{label}</span>
      <span className={styles.input}>
        <input
          type="checkbox"
          name="checked"
          checked={checked}
          onChange={(event) => onChange(event.target.checked)}
        />
        <span className={styles.control}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="100%"
            height="100%"
            aria-hidden="true"
            focusable="false"
          >
            <path
              fill="none"
              stroke="currentColor"
              strokeWidth="4"
              d="M1.73 12.91l6.37 6.37L22.79 4.59"
            />
          </svg>
        </span>
      </span>
    </label>
  );
};

export default Checkbox;
