export const helpCopyJsx = [
  {
    location: "1.1",
    type: "tooltip",
    placement: "left",
    title: "",
    content: (
      <>
        Use this only if the staff member has not yet been added to the staff
        list. This may be because they are a new starter (not yet been added to
        ESR) or they may never be on ESR e.g agency staff, volunteers etc.
      </>
    ),
  },
  {
    location: "1.2",
    type: "infobox",
    placement: "",
    title: "",
    content: (
      <>
        Use this only if the staff member has not yet been added to the staff
        list. This may be because they are a new starter (so not yet been added
        to ESR) or they may never be on ESR e.g if they are agency staff, from
        an external organisation or a volunteer.
      </>
    ),
  },
  {
    location: "1.3",
    type: "tooltip",
    placement: "top",
    title: "",
    content: (
      <>
        Immform status can be assigned from the staff list upload, the
        ‘Settings’ menu or by using the individual toggle buttons below. Please
        note, any ImmForm statuses included in the ESR upload, will overwrite
        assignments on the ‘Settings’ page or made using the toggle buttons.
      </>
    ),
  },
  {
    location: "1.4",
    type: "tooltip",
    placement: "top",
    title: "",
    content: (
      <>
        Frontline status can be assigned from the staff list upload, the
        ‘Settings’ menu or by using the individual toggle buttons below. Please
        note, any frontline statuses included in the ESR upload, will overwrite
        assignments on the ‘Settings’ page or made using the toggle buttons.
      </>
    ),
  },
  {
    location: "2.1",
    type: "tooltip",
    placement: "left",
    title: "",
    content: (
      <>
        Use this only if the staff member has not yet been added to the staff
        list. This may be because they are a new starter (not yet been added to
        ESR) or they may never be on ESR e.g agency staff, volunteers etc.
      </>
    ),
  },
  {
    location: "2.2",
    type: "tooltip",
    placement: "top",
    title: "",
    content: (
      <>
        Staff who are already on the system, can be added into any clinic even
        if they have a future booking or have previously declined. If staff are
        not on the system, use ‘Manually Add Staff’ first.
      </>
    ),
  },
  {
    location: "2.3",
    type: "tooltip",
    placement: "left",
    title: "",
    content: (
      <>
        Pre-add your flu vaccine batch details here, so you don’t need to
        manually enter them every time you record a vaccination.
      </>
    ),
  },
  {
    location: "2.4",
    type: "tooltip",
    placement: "left",
    title: "",
    content: (
      <>
        Pre-add your Covid vaccine batch details here, so you don’t need to
        manually enter them every time you record a vaccination.
      </>
    ),
  },
  {
    location: "2.5",
    type: "tooltip",
    placement: "top",
    title: "",
    content: (
      <>
        The ‘Close Clinic’ function will check to see if you have missed any
        staff who still have a status of ‘Booked’ and are therefore in need of
        attention.
        <br />
        <strong>USE THIS FUNCTION ONLY AT THE END OF A CLINIC.</strong>
      </>
    ),
  },
  {
    location: "2.6",
    type: "tooltip",
    placement: "top",
    title: "",
    content: <>Clinic is now closed. All staff admin is up-to-date.</>,
  },
  {
    location: "3.1",
    type: "tooltip",
    placement: "top",
    title: "",
    content: (
      <>
        Flu & Covid Together / Flu Only / Covid Only: A combined flexible
        clinic. Staff can book either just flu, just Covid or a combined flu and
        Covid appointment.
        <br />
        <br />
        Flu & Covid Together Only: Staff can only book a combined flu and Covid
        appointment.
      </>
    ),
  },
  {
    location: "3.2",
    type: "tooltip",
    placement: "top",
    title: "",
    content: (
      <>
        Bookable Drop Ins: A clinic where staff can book a specific time slot.
        You can select the time slot duration below.
        <br />
        <br />
        Bookable Time Slots plus Bookable Drop Ins: A clinic where staff can
        book a specific time slot or a ‘drop-in’ appointment. The ‘drop-in’ can
        be at any time during clinic times. The number of drop-ins can be capped
        using the ‘Maximum Number of Drop Ins’ field below.
        <br />
        <br />
        Walkaround: Use this model for ‘ad hoc’ walkaround vaccinations.
        Vaccinators can use the ‘Add Drop In’ function in the clinic to add
        staff as they go. These clinics are not visible to staff when booking.
      </>
    ),
  },
  {
    location: "3.3",
    type: "tooltip",
    placement: "top",
    title: "",
    content: (
      <>
        A ‘site’ is the location of your clinic, usually a hospital or health
        centre. ‘Clinic location’ is the specific location of the clinic within
        the site e.g, a ward, room, office etc. To add, edit or remove sites and
        clinic locations go to: Settings &gt; Sites & Clinic locations.
      </>
    ),
  },
  {
    location: "4.1",
    type: "tooltip",
    placement: "top",
    title: "",
    content: (
      <>
        🤷🏼‍♀️ 4.1 Add rollovers to some of the figures to explain exactly what they
        represent
      </>
    ),
  },
  {
    location: "5.1",
    type: "infobox",
    placement: "",
    title: "",
    content: (
      <>
        <p>
          Here you can add, edit and remove your site and clinic locations.
          These appear in the location drop downs on the ‘Create Clinic’ and
          ‘Edit Clinic’ pages, allowing you to select the location of your
          clinic.
        </p>
        <p>
          A ‘Site’ is the location of your clinic, usually a hospital or health
          centre. ‘Clinic location’ is the specific location of the clinic
          within the site e.g, a ward, room, office etc. Please note, if you add
          a site you must add at least one clinic location within the site.
        </p>
      </>
    ),
  },
  {
    location: "5.2",
    type: "infobox",
    placement: "",
    title: "",
    content: (
      <>
        The boxes next to all these statements must be checked before a staff
        member can proceed to book an appointment. These are used to screen out
        staff who can’t be vaccinated at this time. It is recommended not to add
        any more than necessary (ideally a maximum of 4) to make the staff
        booking process as simple as possible. You can use the arrows to change
        the order that the statements will appear. Please note, these should be
        worded as statements that staff agree to by checking a box and not as a
        question that requires a ‘Yes’ or ‘No’ response.
      </>
    ),
  },
  {
    location: "5.3",
    type: "infobox",
    placement: "",
    title: "",
    content: (
      <>
        These consent statements appear during the booking process after
        selecting a time slot and before the appointment is confirmed. The
        results will then be visible to the vaccinator in the clinic. Unlike the
        pre-booking exclusions, it is not mandatory for staff to check all
        consent statements in order to complete the booking of an appointment.
        You can use the arrows to change the order that the consent statements
        will appear. Please note, these should be worded as statements that
        staff agree to by checking a box and not as a question that requires a
        ‘Yes’ or ‘No’ response.
      </>
    ),
  },
  {
    location: "5.4",
    type: "infobox",
    placement: "",
    title: "",
    content: <>Assignments</>,
  },
  {
    location: "5.5",
    type: "infobox",
    placement: "",
    title: "",
    content: (
      <>
        <p>
          When staff fill in the form on the self registration page they are
          asked to enter their organisation. Once the form is submitted this
          auto populates into Level 1 on their staff record. By default
          ‘Organisation’ is a free text field allowing staff to enter anything
          they like.
        </p>
        <p>
          If you’d like to control exactly which organisation(s) they can
          select, please add them below. Adding custom organisations is
          recommended as it makes the reporting of self registered staff much
          easier. With just a free text box you will inevitably end up with a
          wide variety of different versions and spellings of the same
          organisation which you will then need to manually conform. Once you
          add one or more custom organisations, staff will no longer be able to
          enter into a free text field.
        </p>
        <p>
          Please note, the ‘Job Role’ field which is also mandatory will remain
          a free text field.
        </p>
      </>
    ),
  },
  {
    location: "5.6",
    type: "infobox",
    placement: "",
    title: "",
    content: (
      <>
        Here you can search the logs for any time a clinic has been created,
        edited, cancelled or rescheduled as well any staff appointment bookings,
        reschedules or cancellations.
      </>
    ),
  },
  {
    location: "6.1",
    placement: "bottom",
    type: "tooltip",
    content: (
      <>
        Use this to remove cohorts of staff you do not want included in your
        dashboard data. Make sure these cohorts are defined in level 1 when you
        upload them via the staff list or manually add them to system.
      </>
    ),
  },
];
