import useCampaigns from "../../lib/hooks/useCampaigns";
import { getRoleCurrentTrust } from "../../lib/services/TrustSwitcher";
import { Roles } from "../../lib/types/TrustRoles";
import { PerformanceTable } from "./PerformanceTable/PerformanceTable";
import { Grid, FormControlLabel } from "@material-ui/core";
import { useState } from "react";
import Switch from "@material-ui/core/Switch";
import { makeStyles } from "@material-ui/core/styles";

const StaffGroupsLevelsTab = (props: any) => {
  const role = getRoleCurrentTrust();
  const isTrustWorker = role === Roles.TrustWorker;
  const isVaccinator = role === Roles.Vaccinator;
  const isTrustAdmin = role === Roles.TrustAdmin;
  const isSuperAdmin = role === Roles.SuperAdmin;
  const { loading, hasActiveCampaigns } = useCampaigns(isTrustWorker);
  const [onlyFLHCW, setonlyFLHCW] = useState(false);
  const useStyles = makeStyles((theme) => ({
    wrapper: {
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      marginBottom: "20px",
      alignItems: "stretch",
    },
    subtitle1: {
      fontWeight: 800,
      fontSize: theme.spacing(2),
    },
    p: {
      textAlign: "center",
    },
    onlyFLHCWButton: {
      float: "right",
      marginTop: 20,
    },
    topGridRow: {
      height: 30,
    },
  }));
  const classes = useStyles();
  if (loading) return null;
  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid
          container
          className={classes.topGridRow}
          justifyContent="flex-end"
        >
          <FormControlLabel
            className={classes.onlyFLHCWButton}
            control={
              <Switch
                checked={onlyFLHCW}
                color="primary"
                onChange={() => setonlyFLHCW(!onlyFLHCW)}
              />
            }
            label="View Frontline Only"
          />
        </Grid>
        <PerformanceTable
          pathogenIds={props.pathogenIds}
          onlyFLHCW={onlyFLHCW}
          trustId={props.trustId}
          excludeL1Ids={props.excludeL1Ids}
        ></PerformanceTable>
      </Grid>
    </>
  );
};

export default StaffGroupsLevelsTab;
