import { Button, Grid, LinearProgress } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import ToastMessage from "../../../../components/Universal/toast_message";
import ConfirmDialog from "../../ConfirmDialog";
import useDeleteTrustGroupUser from "./useDeleteTrustGroupUser";
import useTrustGroupUsers, { TrustGroupUser } from "./useTrustGroupUserList";
import NavContainer from "../NavContainer";
import TrustGroupUsersTable from "./TrustGroupUsersTable";

export default function TrustGroupUsers() {
  const history = useHistory();
  const [feedback, setFeedback] = React.useState<{
    type: "success" | "error";
    message: string;
  }>(null);
  const [users, setUsers] = React.useState<TrustGroupUser[]>([]);
  const [totalCount, setTotalCount] = React.useState<number>(0);
  const [userData, loading, error, setSearchText, setPageNumber, setPageSize] =
    useTrustGroupUsers();
  React.useEffect(() => {
    if (userData) {
      setUsers(userData.results);
      setTotalCount(userData.totalCount);
    }
  }, [userData]);
  React.useEffect(() => {
    if (error) {
      setFeedback({
        type: "error",
        message: "There was a problem loading the System users.",
      });
    }
  }, [error]);
  const [showConfirmDialog, setShowDialog] = React.useState(false);
  const [targetId, setTargetId] = React.useState<number | null>(null);
  const [deleteTrust, deleteSuccess, deleteError] = useDeleteTrustGroupUser();
  React.useEffect(() => {
    if (deleteSuccess) {
      setFeedback({
        type: "success",
        message: "System User deleted.",
      });
      setUsers((v) => {
        const update = [...v];
        const index = update.findIndex((t) => t.id === deleteSuccess);
        update.splice(index, 1);
        return update;
      });
    }
  }, [deleteSuccess, setFeedback]);
  React.useEffect(() => {
    if (deleteError) {
      setFeedback({
        type: "error",
        message: "Could not delete System User. Please retry.",
      });
    }
  }, [deleteError, setFeedback]);
  return (
    <>
      {loading && <LinearProgress color="primary" />}
      <NavContainer>
        <Grid container direction="row" justifyContent="space-between">
          <h1>Manage System Users</h1>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              history.push("/users/trust-group-users/new");
            }}
          >
            Add System User
          </Button>
        </Grid>
        <TrustGroupUsersTable
          totalCount={totalCount}
          data={users}
          deleteUser={(id: number) => {
            setShowDialog(true);
            setTargetId(id);
          }}
          setSearchText={setSearchText}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
        />
        <ConfirmDialog
          open={showConfirmDialog}
          title="Delete System User?"
          confirmLabel="Delete"
          onConfirm={() => {
            setShowDialog(false);
            deleteTrust(targetId);
          }}
          onClose={() => {
            setShowDialog(false);
            setTargetId(null);
          }}
        >
          <span>
            Are you sure you want to delete{" "}
            {() => {
              const user = users?.find((a) => a.id === targetId);
              return user ? `${user.firstName} ${user.lastName}` : "";
            }}{" "}
            ?
          </span>
        </ConfirmDialog>
        {feedback && (
          <ToastMessage
            variant={feedback.type}
            message={feedback.message}
            clear={() => {
              setFeedback(null);
            }}
          />
        )}
      </NavContainer>
    </>
  );
}
