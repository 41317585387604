import { LinearProgress } from "@material-ui/core";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ToastMessage from "../../../../../components/Universal/toast_message";
import {
  getCurrentTrustIdFromMe,
  getTrustById,
} from "../../../../../lib/services/TrustSwitcher";
import SettingsContainer, { Feedback } from "../SettingsContainer";
import api from "./api";
import EditTemplateForm from "./EditTemplateForm";
import EmailSettingsForm from "./EmailSettingsForm";
import { Template } from "./template";
import { EmailTemplates } from "./EmailTemplates";
const TemplateList: FunctionComponent = () => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<Template[]>(null);
  const [feedback, setFeedback] = useState<Feedback>(null);
  const [trust, setTrust] = useState<any>(null);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const fetchData = useCallback(() => {
    if (id) return;
    setLoading(true);
    api
      .list()
      .then((items) =>
        setItems(items.filter((i) => i.name !== "BrandTemplate"))
      )
      .catch((e) => {
        if (!e.handled) {
          setFeedback({
            type: "error",
            message: "Error fetching template list",
          });
        }
      })
      .finally(() => setLoading(false));
  }, [setFeedback, id]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const isEditing = id !== undefined;
  useEffect(() => {
    (async () => {
      const trustId = await getCurrentTrustIdFromMe();
      setTrust(await getTrustById(trustId));
    })();
  }, []);
  return (
    <>
      {loading && <LinearProgress color="primary" />}
      <SettingsContainer>
        {!isEditing && (
          <>
            <h1>Email & SMS Templates</h1>
            {items && items.length > 0 ? (
              <EmailTemplates templates={items} />
            ) : items && items.length === 0 ? (
              <p>No records</p>
            ) : null}
            <br />
            <br />
            <h1>Email & SMS Activation</h1>
            {trust && <EmailSettingsForm {...{ trust }} />}
          </>
        )}
        {isEditing && (
          <EditTemplateForm
            id={Number(id)}
            editingSuperAdminTemplate={false}
            fetchUrl={`/TrustNotificationTemplate?notificationTemplateId=${id}`}
            loading={loading}
            onCancel={() => {
              history.push(`/settings/templates`);
            }}
            onUpdate={() => {
              setFeedback({
                type: "success",
                message: "Notification template updated",
              });
            }}
            onError={() =>
              setFeedback({ type: "error", message: "Failed saving record" })
            }
            setFeedback={setFeedback}
          />
        )}
        {feedback && (
          <ToastMessage
            variant={feedback.type}
            message={feedback.message}
            clear={() => setFeedback(null)}
          />
        )}
      </SettingsContainer>
    </>
  );
};
export default TemplateList;
