import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Tooltip,
} from "@material-ui/core";
import { Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons";
import { useEffect, useState } from "react";
import NavContainer from "../NavContainer";
import DataClient from "../../../../lib/services/api/DataClient";
import ApiAlert from "../../../../components/ApiAlert";
import TrustGroupCreateUpdate from "./TrustGroupCreateUpdate";
import ConfirmDialog from "../../ConfirmDialog";
import { Trust } from "../Trust/useTrustList";
export const getTrustGroups = () =>
  DataClient.postData(`/TrustGroup/Search`, {
    pageSize: -1,
  });
export const deleteTrustGroup = (id: number) =>
  DataClient.remove({
    url: `/TrustGroup/${id}`,
  });

export interface TrustGroup {
  id: number;
  name: string;
  trusts: Trust[];
}

export default function () {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [alert, setAlert] = useState(null);
  const [alertDelete, setAlertDelete] = useState(null);
  const [modal, setModal] = useState(null);
  const fetchResults = async () => {
    setLoading(true);
    setAlert(null);
    const res = await getTrustGroups()
      .catch((e) => {
        console.error(e);
        setAlert(ApiAlert({ res: e.response }));
      })
      .finally(() => {
        setLoading(false);
      });
    setList(res?.results?.pagedResults || null);
  };
  useEffect(() => {
    fetchResults();
  }, []);
  const deleteById = async (id: number) => {
    if (!id) return;
    setLoading(true);
    const res = await deleteTrustGroup(id)
      .catch((e) => {
        console.error(e);
        setAlertDelete(ApiAlert({ res: e.response }));
      })
      .finally(() => {
        setLoading(false);
      });
    if (res?.success) {
      setAlertDelete(
        ApiAlert({
          alwaysShow: true,
          title: "Success",
          message: `System deleted`,
          severity: "success",
        })
      );
    }
  };
  return (
    <>
      {loading && (
        <div className="interstitial">
          <CircularProgress />
        </div>
      )}
      <NavContainer>
        <Grid container direction="row" justifyContent="space-between">
          <h1>Manage Systems</h1>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setModal({ mode: "create" });
            }}
          >
            Add System
          </Button>
        </Grid>
        {alert}
        {list?.length > 0 ? (
          <Paper>
            <List>
              {list.map((item) => (
                <ListItem key={item.id}>
                  <ListItemText primary={item.name} />
                  <ListItemSecondaryAction>
                    <Tooltip title="Edit">
                      <IconButton
                        color="primary"
                        onClick={() => {
                          setModal({ mode: "edit", id: item.id });
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton
                        color="primary"
                        onClick={() => {
                          setAlertDelete(null);
                          setModal({ mode: "delete", ...item });
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Paper>
        ) : (
          <p>No items</p>
        )}
        {["create", "edit"].includes(modal?.mode) && (
          <TrustGroupCreateUpdate
            {...modal}
            onClose={() => {
              setModal(null);
              fetchResults();
            }}
          />
        )}
        {["delete"].includes(modal?.mode) && (
          <ConfirmDialog
            open={true}
            title="Delete System?"
            confirmLabel="Delete"
            onConfirm={() => {
              deleteById(modal.id);
            }}
            onClose={() => {
              setModal(null);
              fetchResults();
            }}
            closeLabel={alertDelete ? "Back" : "Cancel"}
            alert={alertDelete}
            showConfirm={!alertDelete}
          >
            <span>Are you sure you want to delete {modal?.name}?</span>
          </ConfirmDialog>
        )}
      </NavContainer>
    </>
  );
}
