import { DateTime } from "luxon";
import { Pathogen, Pathogens } from "../pathogens";
import TrustData from "../types/TrustData";
import { Roles, TrustRole } from "../types/TrustRoles";
import DataClient from "./api/DataClient";

export enum TrustRoles {
  "TrustWorker" = 1,
  "Vaccinator" = 2,
  "ClinicAdmin" = 3,
  "TrustAdmin" = 4,
  "SuperAdmin" = 5,
}

const CURRENT_TRUST_KEY = "current_trust";
const TRUSTS_KEY = "trusts";
const ROLES_KEY = "roles";
const SELECTED_TRUST_KEY = "selected_trust";
const WORKER_PATHOGENS = "worker_pathogens";

// Slowly deprecating localStorage functions like this, in favour of *FromMe further below
// However it can be useful, for example how header.js uses it on every render to display username
export const getCurrentTrust = (): TrustData => {
  try {
    return JSON.parse(localStorage.getItem(CURRENT_TRUST_KEY));
  } catch (error) {
    return null;
  }
};
export const getCurrentTrustFromMe = async () => {
  return (await getMe())?.currentTrust || null;
};

export const setCurrentTrust = (trust: TrustData) => {
  if (trust) {
    localStorage.setItem(CURRENT_TRUST_KEY, JSON.stringify(trust));
  } else {
    localStorage.removeItem(CURRENT_TRUST_KEY);
  }
};

export const getTrustWorkerId = (): number | null =>
  getCurrentTrust()?.trustWorkerId || null;

// Slowly deprecating localStorage functions like this, in favour of *FromMe further below
export const getPatientCampaignId = (): number | null =>
  getCurrentTrust()?.patientCampaignId || null;
export const findPatientCampaignIdInMe = (me: any) =>
  me.currentTrust?.patientCampaignId || null;
export const getPatientCampaignIdFromMe = async () => {
  return findPatientCampaignIdInMe(await getMe());
};
// Trust admins only (or endpoint biffs)
export const getTrustCampaigns = async () => {
  if (!isVaccinatorOrHigher()) {
    console.warn(
      "Warning: User lower than vaccinator attempted to use getTrustActiveCampaign!"
    );
    return null;
  }
  return (await DataClient.getData(`/campaign`))?.results || [];
};
// Your current trust active campaign, even if you're not ON it
// (/campaign showing it but not necessarily getPatientCampaignIdFromMe)
export const getTrustActiveCampaign = async () => {
  return (await getTrustCampaigns())?.find((c: any) => c.active) || null;
};
export const getCurrentTrustActiveCampaignId = () =>
  getCurrentTrust()?.patientCampaignId || null;

export const getTrustId = (): number => {
  const currentTrust: TrustData = JSON.parse(
    localStorage.getItem(CURRENT_TRUST_KEY)
  );
  return currentTrust?.trustId;
};
export const getCurrentTrustIdFromMe = async () => {
  return (await getMe())?.currentTrust?.trustId || null;
};
// Get any trust, by id (not necessarily current)
export const getTrustById = async (trustId: number | string) => {
  return (await DataClient.getData(`/trust/${trustId}`))?.results || null;
};

export const getMe = async () => {
  return DataClient.getData(`/me`);
};

export const getTrustFromRegistrationSegment = async (segment: string) => {
  return (
    (await DataClient.getData(`/trust/GetByRegistrationSegment`, {
      segment,
    })) || null
  );
};

export const getOrganisationsFromTrust = async (trustId: number) => {
  return (
    // await DataClient.postData(`/TrustOrganisationOption/Search`, {
    //   trustId,
    //   pageSize: -1,
    // })
    // Use call instead of postData to force (whatever) trustId into header for non-logged in registration
    (
      await DataClient.call({
        method: "POST",
        url: `/TrustOrganisationOption/Search`,
        data: {
          trustId,
          pageSize: -1,
        },
        headers: {
          trustId,
        },
      }).client
    )?.results?.pagedResults || []
  );
};

// [dd#2404] Removing
export const findLastCovidDoseDateInMe = (me: any) =>
  me?.currentTrust?.lastCovidDoseDate
    ? DateTime.fromISO(me.currentTrust?.lastCovidDoseDate)
    : null;
export const getLastCovidDoseDateFromMe =
  async (): Promise<DateTime | null> => {
    const me = await getMe();
    return findLastCovidDoseDateInMe(me);
  };

/** Get the pathogens the trust supports */
export const getTrustPathogens = (): Pathogen[] | null => {
  try {
    const currentTrust: TrustData = JSON.parse(
      localStorage.getItem(CURRENT_TRUST_KEY)
    );
    return currentTrust?.trustPathogens;
  } catch (error) {
    console.error(error);
    return null;
  }
};

/** Get the pathogens the trust worker is allowed to book for */
export const getWorkerPathogens = (): Pathogens[] | null => {
  try {
    const currentTrust: TrustData = JSON.parse(
      localStorage.getItem(CURRENT_TRUST_KEY)
    );
    return currentTrust?.trustWorkerPathogens;
  } catch (error) {
    console.error(error);
    return null;
  }
};

// Slowly deprecating localStorage functions like this, in favour of *FromMe further below
export const getTrusts = (): TrustData[] => {
  return JSON.parse(localStorage.getItem(TRUSTS_KEY)) || [];
};
export const getTrustsFromMe = async () => {
  const me = await getMe();
  return me.trusts || [];
};

export const setTrusts = (trusts: TrustData[]) => {
  if (trusts) {
    localStorage.setItem(TRUSTS_KEY, JSON.stringify(trusts));
  } else {
    localStorage.setItem(TRUSTS_KEY, JSON.stringify([]));
  }
};

/**
 * Roles for current trust
 */
export const getRolesCurrentTrust = (): TrustRole[] => {
  const storedData = localStorage.getItem(CURRENT_TRUST_KEY);
  if (storedData) {
    const currentTrust: TrustData = JSON.parse(storedData);
    return currentTrust?.roles || [];
  } else {
    return getRolesGeneric();
  }
};
/* Used for places where the app expects one role to represent a user
 * @returns The "highest" authority role (SuperAdmin > TrustAdmin > Vaccinator > TrustWorker)
 */
export const getRoleCurrentTrust = (): TrustRole => {
  return sortRoles(getRolesCurrentTrust())?.[0] || null;
};

// DD#4617 I needed to handle the Reporting roles as they are not part of the hierarchical role structure.
export const sortRoles = (roles: TrustRole[]): TrustRole[] => {
  const sortedRoles = roles.sort((a, b) => {
    if (!TrustRoles[b]) return -1; // If not in enum place last.
    return TrustRoles[b] - TrustRoles[a];
  });
  return sortedRoles;
};

/**
 * Generic roles in local storage, not specific to current trust
 * Avoid using this directly as getRolesCurrentTrust falls back
 */
export const setRolesGeneric = (roles: TrustRole[]) => {
  localStorage.setItem(ROLES_KEY, JSON.stringify(roles));
};
export const getRolesGeneric = (): TrustRole[] => {
  return JSON.parse(localStorage.getItem(ROLES_KEY)) || [];
};
// export const getRoleGeneric = (): TrustRole => {
//   return sortRoles(getRolesGeneric())?.[0] || null;
// };
export const isSuperAdmin = (): boolean =>
  getRolesCurrentTrust()?.includes(Roles.SuperAdmin);
export const isTrustAdmin = (): boolean =>
  getRolesCurrentTrust()?.includes(Roles.TrustAdmin);
export const isClinicAdmin = (): boolean =>
  getRolesCurrentTrust()?.includes(Roles.ClinicAdmin);
export const isTrustAdminOrHigher = (): boolean => {
  return TrustRoles[getRoleCurrentTrust()] >= TrustRoles.TrustAdmin;
};
export const isClinicAdminOrHigher = (): boolean => {
  return TrustRoles[getRoleCurrentTrust()] >= TrustRoles.ClinicAdmin;
};
export const isVaccinatorOrHigher = (): boolean => {
  return TrustRoles[getRoleCurrentTrust()] >= TrustRoles.Vaccinator;
};
export const isTrustWorker = (): boolean =>
  getRolesCurrentTrust()?.includes(Roles.TrustWorker);
export const isVaccinator = (): boolean =>
  getRolesCurrentTrust()?.includes(Roles.Vaccinator);
export const isTrustGroupUser = (): boolean => {
  // Being a trust group user is not specific to a trust, just mainly adds access to dashboard
  return getRolesGeneric()?.includes(Roles.TrustGroupUser);
};

/**
 * selectTrust only sets the localStorage for
 * @param id trustId
 * @returns true if trust was found and set
 */
export const setSelectedTrust = (id: number): boolean => {
  let trusts: TrustData[] = [];
  try {
    trusts = JSON.parse(localStorage.getItem(TRUSTS_KEY)) || [];
  } catch (error) {
    console.error(error);
  }
  if (trusts.findIndex((trust) => (trust.trustId = id)) > -1) {
    localStorage.setItem(SELECTED_TRUST_KEY, JSON.stringify(id));
    return true;
  } else {
    console.error("There is no trust with that id");
    return false;
  }
};

export const getSelectedTrust = (): number => {
  return JSON.parse(localStorage.getItem(SELECTED_TRUST_KEY));
};

export const clearTrustState = () => {
  localStorage.removeItem(CURRENT_TRUST_KEY);
  localStorage.removeItem(TRUSTS_KEY);
  localStorage.removeItem(ROLES_KEY);
  localStorage.removeItem(SELECTED_TRUST_KEY);
  localStorage.removeItem(WORKER_PATHOGENS);
};

export const onUserChange = async (user: any) => {
  if (user === null) {
    // Logout
    clearTrustState();
  } else {
    // Login
    const data = await DataClient.getData("/me");
    const currentTrust = data?.currentTrust ?? data?.trusts[0];
    setCurrentTrust(currentTrust);
    setTrusts(data?.trusts);
    setSelectedTrust(Number(data?.trusts[0]?.trustId));
    setRolesGeneric(data?.roles);
  }
};
