import { Component } from "react";
import {
  ApplicationPaths,
  messagesLogoutActions,
  QueryParameterNames,
} from "./ApiAuthorizationConstants";
import authService from "./AuthorizeService";
import LoginLogoutRender from "./LoginLogoutRender";
// The main responsibility of this component is to handle the user's logout process.
// This is the starting point for the logout process, which is usually initiated when a
// user clicks on the logout button on the LoginMenu component.
export class Logout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: undefined,
      isReady: false,
      authenticated: false,
    };
  }
  componentDidMount() {
    const action = this.props.action;
    switch (action) {
      case "logout":
        if (window.history.state.state.local) {
          this.logout(this.getReturnUrl());
        } else {
          // This prevents regular links to <app>/authentication/logout from triggering a logout
          this.setState({
            isReady: true,
            message: "The logout was not initiated from within the page.",
          });
        }
        break;
      case "logoutCallback":
        this.processLogoutCallback();
        break;
      case "loggedOut":
        this.setState({
          isReady: true,
        });
        break;
      default:
        throw new Error(`Invalid action '${action}'`);
    }
    this.populateAuthenticationState();
  }
  render() {
    const { action } = this.props;
    let { isReady, message, erroring } = this.state;
    // if (!isReady) return null;
    message = message || messagesLogoutActions[action];
    // console.log({ action, message }, messagesLogoutActions);
    if (!message && isReady) {
      throw new Error(`Invalid action '${action}'`);
    }
    return (
      <LoginLogoutRender
        {...{
          message,
          showHomeButton: erroring || action === "loggedOut",
          showLoadingSpinner: ["logoutCallback", "logoutCallback"].includes(
            action
          ),
        }}
      />
    );
  }
  async logout(returnUrl) {
    const state = { returnUrl };
    const isauthenticated = await authService.isAuthenticated();
    if (isauthenticated) {
      const result = await authService.signOut(state);
      switch (result.status) {
        case "redirect":
          break;
        case "success":
          await this.navigateToReturnUrl(returnUrl);
          break;
        case "fail":
          this.setState({ message: result.message, erroring: true });
          break;
        default:
          throw new Error("Invalid authentication result status.");
      }
    } else {
      this.setState({ message: "You successfully logged out!" });
    }
  }
  async processLogoutCallback() {
    const url = window.location.href;
    const result = await authService.completeSignOut(url);
    switch (result.status) {
      case "redirect":
        // There should not be any redirects as the only time completeAuthentication finishes
        // is when we are doing a redirect sign in flow.
        throw new Error("Should not redirect.");
      case "success":
        await this.navigateToReturnUrl(this.getReturnUrl(result.state));
        break;
      case "fail":
        this.setState({ message: result.message, erroring: true });
        break;
      default:
        throw new Error("Invalid authentication result status.");
    }
  }
  async populateAuthenticationState() {
    const authenticated = await authService.isAuthenticated();
    this.setState({ isReady: true, authenticated });
  }
  getReturnUrl(state) {
    const params = new URLSearchParams(window.location.search);
    const fromQuery = params.get(QueryParameterNames.ReturnUrl);
    if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
      // This is an extra check to prevent open redirects.
      throw new Error(
        "Invalid return url. The return url needs to have the same origin as the current page."
      );
    }
    return (
      (state && state.returnUrl) ||
      fromQuery ||
      `${window.location.origin}${ApplicationPaths.LoggedOut}`
    );
  }
  navigateToReturnUrl(returnUrl) {
    return window.location.replace(returnUrl);
  }
}
