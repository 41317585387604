import {
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import {
  Archive,
  CheckCircleOutline,
  DeleteOutline,
  Edit as EditIcon,
  Email,
  HighlightOff,
  InsertInvitation,
  SettingsBackupRestore,
  Settings as SettingsIcon,
  Timeline,
  Visibility,
} from "@material-ui/icons";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { useEffect, useState } from "react";
import { alertNoActiveCampaign } from "../../../../components/AlertMessaging";
import { Flag } from "../../../../components/FlagIcons";
import { HewOrDecline } from "../../../../components/HewOrDecline";
import ToastMessage from "../../../../components/Universal/toast_message";
import { VaccinationHistory } from "../../../../components/VaccinationHistory";
import {
  humanDate,
  humanName,
  humanNameAndEmailFromObject,
  humanNameFromObject,
} from "../../../../lib/common_utils";
import { enumToArray } from "../../../../lib/enumManipulations";
import { enumVaccinationStatus } from "../../../../lib/enums";
import {
  Pathogens,
  getLabel,
  humanPathogen,
  userHasAnyReservation,
} from "../../../../lib/pathogens";
import { getCurrentTrustFromMe } from "../../../../lib/services/TrustSwitcher";
import { overridesTableFiltersStaff } from "../../../../lib/themes/overrides";
import ConfirmDialog from "../../ConfirmDialog";
import { VaccinationDetails } from "../VaccinationDetails";
import { BookAppointmentDialog, ViewAppointmentDialog } from "./Appointment";
import { ArchiveUserDialog } from "./ArchiveUserDialog";
import { CreateDialog } from "./Create";
import { EditDialog } from "./Edit";
import { ExemptDialog } from "./Exempt";
import { SetAdditionalRoles } from "./SetAdditionalRoles";
import { SetReportingAccess } from "./SetReportingAccess";
import Table from "./Table";
import UpdateAtRisk from "./UpdateAtRisk";
import api from "./data";
import useAppointments from "./useAppointments";
import useListData from "./useListData";

function List(props) {
  const [alert, setAlert] = useState(null);
  const [fail, setFail] = useState(false);
  const [pathogens, setPathogens] = useState();
  useEffect(() => {
    (async () => {
      // Happy if current trust has an active campaign, even if you're not personally ON it
      const currentTrust = await getCurrentTrustFromMe();
      if (
        !currentTrust.patientCampaignId &&
        !currentTrust.roles.includes("TrustAdmin")
      ) {
        setFail(true);
        setAlert(alertNoActiveCampaign());
      }
      //
      // Pathogens
      //
      // if (trustId === 0) {
      if (props.tableProps?.complianceFiltersEnabled) {
        // Compliance mode - allow all pathogens
        setPathogens(enumToArray(Pathogens).map((p) => p.id));
        // } else if (trustId) {
        //   // Chosen trust (in Compliance) - permitted pathogens
        //   const trust = (await getTrustsFromMe())?.find(
        //     (t) => t.trustId === trustId
        //   );
        //   if (!trust) {
        //     return console.log(`Trust ${trustId} is not available to you!`);
        //   }
        //   setPathogens(trust.trustPathogens.map((p) => p.id));
      } else {
        // /Staff - users' current trust permitted pathogens
        const currentTrust = await getCurrentTrustFromMe();
        setPathogens(currentTrust.trustPathogens.map((p) => p.id));
      }
    })();
  }, [setFail, setAlert, setPathogens]);

  if (typeof props.trustId !== "undefined") {
    console.log(
      "Passing trustId to useListData to get staff for given trust is not currently available"
    );
  }

  let {
    fetchStaff,
    activeModal,
    setActiveModal,
    setSelectedIds,
    extendedItems,
    totalCount,
    filter,
    setFilter,
    feedback,
    setFeedback,
    activeItem,
    setActiveItem,
    confirmDialog,
    setConfirmDialog,
    activePathogen,
    setActivePathogen,
    handleArchive,
    handleArchiveMany,
    handleFrontLineChange,
    handleImmformChange,
    isFetchingStaff,
    handleSelfRegAction,
    handleArchivedUserAction,
    alertBoxCounts,
    removeEmailInDoubt,
    removePossibleDuplicate,
  } = useListData(props.activeModal, !props.tableProps?.isComplianceTable);
  activeModal = props.match?.params.activeModal || activeModal;
  const handleCloseModal = setActiveModal;

  const {
    handleRescheduleAppointment,
    handleCancelAppointment,
    handleBookedAppointment,
    appointmentAction,
    setAppointmentAction,
  } = useAppointments(setConfirmDialog, setFeedback, fetchStaff);

  const items = extendedItems;

  const getAppointmentActions = (item) => {
    if (!pathogens || !item) return {};
    return {
      ...(item?.pathogens?.some(
        (p) => item?.pathogenPermissions?.[p].canBook
      ) && {
        bookAppointment: {
          title: (
            <>
              <InsertInvitation /> Book Appointment
            </>
          ),
          modal: "bookAppointment",
          pathogens: item?.pathogens?.filter(
            (p) => item?.pathogenPermissions?.[p]?.canBook
          ),
        },
      }),
      ...item?.reservations
        .filter((r) =>
          r.pathogens?.some(
            (p) => !item?.pathogenPermissions?.[p]?.lockedByOtherTrust
          )
        )
        .reduce(
          (p, c) => ({
            ...p,
            [`viewAppointment${getLabel(c.pathogens)}`]: {
              title: (
                <>
                  <Visibility /> View Appointment ({getLabel(c.pathogens)})
                </>
              ),
              modal: "viewAppointment",
              ...c,
            },
          }),
          {}
        ),
    };
  };

  const getPathogenActions = (pathogen) => {
    const suffix = Pathogens[pathogen];
    return {
      [`recordVaccination${suffix}`]: {
        title: (
          <>
            <Flag type={`flag${suffix}Vaccinated`} />
            Record Vaccination for {suffix}
          </>
        ),
        modal: "vaccinationDetails",
        pathogen,
        visible: (item) =>
          !item?.pathogenPermissions?.[pathogen].hasFutureBooking &&
          item?.pathogenPermissions?.[pathogen].canRecordVaccination,
      },
      [`futureVaccination${suffix}`]: {
        title: (
          <>
            <Flag type={`flag${suffix}Vaccinated`} />
            Record Vaccination for {suffix}
          </>
        ),
        Dialog: ConfirmDialog,
        dialogProps: {
          title: `This staff member has an appointment in the future. You must cancel their future appointment before you can record a vaccination from this page.`,
          showConfirm: false,
          closeLabel: "Ok",
        },
        visible: (item) =>
          item?.pathogenPermissions?.[pathogen].hasFutureBooking &&
          item?.pathogenPermissions?.[pathogen].canRecordVaccination,
      },
      [`editVaccination${suffix}`]: {
        title: (
          <>
            <Flag type={`flag${suffix}Vaccinated`} />
            Edit Vaccination for {suffix}
          </>
        ),
        modal: "vaccinationDetails",
        pathogen,
        visible: (item) =>
          item?.pathogenPermissions?.[pathogen].canEditVaccination,
      },
      [`recordDecline${suffix}`]: {
        title: (item) => (
          <>
            <Flag type={`flag${suffix}Decline`} />
            {item?.pathogenPermissions?.[pathogen].canRecordDecline
              ? "Record"
              : "View"}{" "}
            Decline / Had Elsewhere for {suffix}
          </>
        ),
        visible: (item) =>
          item?.pathogenPermissions?.[pathogen].canRecordDecline ||
          (item?.pathogenPermissions?.[pathogen].canUndoDecline &&
            item[`vaccinationStatusId${suffix}`] !==
              enumVaccinationStatus.Exempt),
        Dialog: HewOrDecline,
        pathogen,
        dialogProps: {
          value: { id: activeItem?.id, trustWorkerDetails: activeItem },
          pathogen,
          onSuccess: () => {
            setFeedback({
              type: "success",
              message: `Had Elsewhere or Decline recorded successfully for ${humanPathogen(
                suffix
              )}`,
            });
            fetchStaff();
            setActiveModal(null);
          },
          onRemove: () => {
            setConfirmDialog("removeDecline");
          },
          title: `${
            activeItem?.pathogenPermissions?.[pathogen].canRecordDecline
              ? "Record"
              : "View"
          } Decline / Had Elsewhere for ${humanPathogen(suffix)}`,
          showStaffVaccinationSites: true,
        },
      },
      [`recordExempt${suffix}`]: {
        title: (item) => (
          <>
            <Flag type={`flagExempt${suffix}`} />
            {item?.pathogenPermissions?.[pathogen].canRecordExempt
              ? `Mark as Exempt for ${suffix}`
              : `View ${suffix} Exemption`}
          </>
        ),
        visible: (item) =>
          item?.pathogenPermissions?.[pathogen].canRecordExempt ||
          (item?.pathogenPermissions?.[pathogen].canUndoExempt &&
            item[`vaccinationStatusId${suffix}`] ===
              enumVaccinationStatus.Exempt),

        Dialog: ExemptDialog,
        pathogen,
        dialogProps: {
          value: activeItem,
          pathogen,
          onSuccess: () => {
            setFeedback({
              type: "success",
              message: `Successfully marked as exempt for ${humanPathogen(
                suffix
              )}`,
            });
            fetchStaff();
            setActiveModal(null);
          },
          onRemove: () => {
            setConfirmDialog("removeExempt");
          },
        },
      },
    };
  };

  const actionMenu = {
    vaccinationHistory: {
      title: (
        <>
          <Timeline /> Vaccination History
        </>
      ),
      visible: () => true,
      Dialog: ConfirmDialog,
      dialogProps: {
        title: `Vaccination History`,
        showConfirm: false,
        closeLabel: "Close",
        onClose: () => {
          // Reload staff as covid 1 and 2 are stored on trustworker, so even though it queries /CovidVaccinationRecord it doesn't update when re-opening this modal
          // https://techdepartment.mydonedone.com/issuetracker/projects/85036/issues/44
          fetchStaff();
          setActiveModal(null);
        },
        dialogParams: {
          fullWidth: true,
          maxWidth: "lg",
          className: "dialog--vaccination-history",
        },
        children: (() => (
          <VaccinationHistory
            id={activeItem?.id}
            allowViewEmptyRecords={true}
            injectMissingRecordsUsingVaccinationIdentifiiers={true}
            allowEdit={true}
          />
        ))(),
      },
    },
    separatorA: {
      visible: () => true,
      divider: true,
    },
    ...Object.values(Pathogens)
      .filter((k) => !isNaN(Number(k)))
      .map((p, i) => ({
        ...getPathogenActions(p),
        ...{
          [`separatorB${i}`]: {
            visible: () => true,
            divider: true,
          },
        },
      }))
      .reduce((p, c) => ({ ...p, ...c })),
    separatorC: {
      visible: () => true,
      divider: true,
    },
    resendInvite: {
      title: (
        <>
          <Email /> Resend Invite
        </>
      ),
      visible: (item) => {
        return (
          // #232 Show resend invite if (they have an email address and)
          // They've got both no vaccination date and no declination for any particular pathogen
          item?.emailAddress &&
          item?.pathogens.some(
            (p) =>
              item[`vaccinationDate${Pathogens[p]}`] === null &&
              item[`declineReasonId${Pathogens[p]}`] === null
          )
        );
      },
      Dialog: ConfirmDialog,
      dialogProps: {
        title: `Are you sure you want to resend an invite to ${humanNameAndEmailFromObject(
          activeItem
        )}`,
        onConfirm: () => {
          return api.staff
            .sendInviteEmail(activeItem.id)
            .then(() => {
              setFeedback({
                type: "success",
                message: `Sent invite to ${activeItem?.emailAddress}`,
              });
            })
            .catch(() =>
              setFeedback({
                type: "error",
                message: "Failed to Resend Invite",
              })
            )
            .finally(() => {
              setActiveModal(null);
            });
        },
      },
    },
    updateAtRisk: {
      title: (item) => (
        <>
          <Flag type="flagAtRisk" />
          {item?.atRisk ? `View 'At Risk' Status` : `Mark as 'At Risk'`}
        </>
      ),
      visible: () => true,
      Dialog: UpdateAtRisk,
      dialogProps: {
        value: activeItem,
        name: humanNameFromObject(activeItem, true),
        // Isolate the dialog actions within UpdateAtRisk instead
        dialogContentParams: { style: { padding: 0 } },
        onClose: () => {
          setActiveModal(null);
        },
        onSuccess: () => {
          setFeedback({
            type: "success",
            message: `Successfully marked as 'At Risk'`,
          });
          fetchStaff();
          setActiveModal(null);
        },
        onError: ({ message }) => {
          setFeedback({
            type: "error",
            message,
          });
        },
      },
    },
    removeEmailInDoubt: {
      title: () => (
        <>
          <Flag type="flagEmailInDoubt" />
          {`Remove 'Email In Doubt' Flag`}
        </>
      ),
      visible: (item) => item?.flagEmailInDoubt,
      Dialog: ConfirmDialog,
      dialogProps: {
        title: `Are you sure you want remove the Email in Doubt flag?`,
        onConfirm: () => {
          removeEmailInDoubt();
        },
      },
    },
    removePossibleDuplicate: {
      title: () => (
        <>
          <Flag type="flagPossibleDuplicate" />
          {`Remove 'Possible Duplicate'`}
        </>
      ),
      visible: (item) => item?.flagPossibleDuplicate,
      Dialog: ConfirmDialog,
      dialogProps: {
        title: `Are you sure you want remove the Possible Duplicate flag?`,
        onConfirm: () => {
          removePossibleDuplicate();
          setActiveModal(null);
        },
      },
    },
    setAdditionalRoles: {
      title: (item) => (
        <>
          <Flag type="flagVaccinator" /> Assign as Vaccinator / Clinic Admin
        </>
      ),
      visible: () => true,
      Dialog: SetAdditionalRoles,
      dialogProps: {
        value: activeItem,
        onClose: () => {
          setActiveModal(null);
        },
        onSuccess: () => {
          setFeedback({
            type: "success",
            message: `Successfully updated user's role`,
          });
          fetchStaff();
          setActiveModal(null);
        },
        onError: ({ message }) => {
          setFeedback({
            type: "error",
            message,
          });
        },
      },
    },
    setReportingAccess: {
      title: (item) => (
        <>
          <Flag type="flagReportingViewer" />
          {item?.isReportingViewer ? (
            <span>Edit / Remove Reporting Access</span>
          ) : (
            <span>Enable Reporting Access</span>
          )}
        </>
      ),
      // Only show for non Trust Admins
      visible: (item) => item?.flagTrustAdmin === false,
      Dialog: SetReportingAccess,
      dialogProps: {
        value: activeItem,
        onClose: () => {
          setActiveModal(null);
        },
        onSuccess: () => {
          setFeedback({
            type: "success",
            message: `Successfully updated user's reporting access`,
          });
          fetchStaff();
          setActiveModal(null);
        },
        onError: ({ message }) => {
          setFeedback({
            type: "error",
            message,
          });
        },
      },
    },
    deleteRow: {
      title: (
        <>
          <Archive /> Archive Staff Record
        </>
      ),
      visible: () => true,
      Dialog: ArchiveUserDialog,
      dialogProps: {
        onClose: () => {
          setActiveModal(null);
        },
        onConfirm: () => {
          handleArchive();
        },
        activeItem: activeItem,
        userHasAnyReservation: userHasAnyReservation,
      },
    },
  };
  const ActionsMenu = ({ id, item }) => {
    const popupState = usePopupState({ variant: "popover", popupId: id });
    return (
      <div style={{ display: "inline" }}>
        <Tooltip title="Actions Menu">
          <IconButton {...bindTrigger(popupState)}>
            <SettingsIcon fontSize="small" color="secondary" />
          </IconButton>
        </Tooltip>
        <Menu {...bindMenu(popupState)} className="cog-menu">
          {Object.entries(getAppointmentActions(item)).map(([key, value]) => (
            <MenuItem
              key={`${key}-${id}`}
              onClick={() => {
                setAppointmentAction({ ...value, item });
                popupState.close();
              }}
            >
              {typeof value.title === "function"
                ? value.title(item)
                : value.title}
            </MenuItem>
          ))}

          {Object.entries(actionMenu)
            .filter(([_, { visible }]) => visible(item))
            .map(([key, { title, pathogen, modal, divider }]) =>
              divider ? (
                <Divider key={`divider-${key}-${id}`} />
              ) : (
                <MenuItem
                  key={`${key}-${id}`}
                  onClick={() => {
                    setActiveItem(item);
                    setActivePathogen(pathogen);
                    setActiveModal(modal ?? key);
                    popupState.close();
                  }}
                >
                  {typeof title === "function" ? title(item) : title}
                </MenuItem>
              )
            )}
        </Menu>
      </div>
    );
  };

  const rowActions = {
    editStaff: {
      title: "Edit Staff Member",
      color: "primary",
      visible: (item) => item?.flagArchived === false,
      Icon: EditIcon,
      Dialog: EditDialog,
      dialogProps: {
        value: activeItem,
        onSuccess: () => {
          setFeedback({
            type: "success",
            message: "Successfully updated staff member",
          });
          fetchStaff();
          setActiveModal(null);
        },
        onError: (error) => {
          const messages = error.data?.results;
          const summary = error.data?.summary;
          setFeedback({
            type: "error",
            message: messages?.message
              ? messages.message
              : messages
                ? Array.isArray(messages)
                  ? messages?.join(" ")
                  : messages
                : "Failed to add staff member",
          });
          if (summary) {
            setFeedback({
              type: "error",
              message: summary,
            });
          }
        },
        onRemoveEmailInDoubt: () => {
          setConfirmDialog("confirmRemoveEmailInDoubt");
        },
        onRemovePossibleDuplicate: () => {
          setConfirmDialog("confirmRemovePossibleDuplicate");
        },
      },
    },
  };

  const renderRowActions = ({ item }) => {
    return (
      <>
        {/* If user archived show these actions */}
        {item?.flagArchived ? (
          <>
            <Tooltip title="Restore Archived Record">
              <IconButton
                onClick={() => {
                  setActiveItem(item);
                  setConfirmDialog("archivedUserRestore");
                }}
              >
                <SettingsBackupRestore style={{ color: "#57a24f" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Permanently Delete">
              <IconButton
                onClick={() => {
                  setActiveItem(item);
                  setConfirmDialog("archivedUserDelete");
                }}
              >
                <DeleteOutline style={{ color: "#333" }} />
              </IconButton>
            </Tooltip>
          </>
        ) : //  If self registered, hide the cog and show these bad boys instead
        item?.selfRegistered && !item.approved ? (
          <>
            <Tooltip title="Approve">
              <IconButton
                onClick={() => {
                  setActiveItem(item);
                  setConfirmDialog("selfRegApprove");
                }}
              >
                <CheckCircleOutline style={{ color: "#57a24f" }} />
                {/* Flat alternative: CheckCircle */}
              </IconButton>
            </Tooltip>
            <Tooltip title="Reject">
              <IconButton
                onClick={() => {
                  setActiveItem(item);
                  setConfirmDialog("selfRegReject");
                }}
              >
                <HighlightOff style={{ color: "#e30613" }} />
                {/* Flat alternative: Cancel */}
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                onClick={() => {
                  setActiveItem(item);
                  setConfirmDialog("selfRegDelete");
                }}
              >
                <DeleteOutline style={{ color: "#333" }} />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <ActionsMenu item={item} id={`popup-${item?.id}`} />
        )}
        {Object.entries(rowActions).map(
          ([key, { title, Icon, color, visible }]) => {
            return (
              visible(item) && (
                <Tooltip key={key} title={title}>
                  <IconButton
                    onClick={() => {
                      setActiveItem(item);
                      setActiveModal(key);
                    }}
                  >
                    <Icon fontSize="small" color={color} />
                  </IconButton>
                </Tooltip>
              )
            );
          }
        )}
      </>
    );
  };

  return (
    <>
      {alert}
      {!fail && (
        <div className="wrapper-bleed">
          <Table
            {...props.tableProps}
            readOnly={props.tableProps?.readOnly}
            styleOverrides={overridesTableFiltersStaff}
            pathogens={pathogens}
            items={items}
            isLoading={isFetchingStaff}
            filter={filter}
            setFilter={setFilter}
            totalCount={totalCount}
            renderRowActions={renderRowActions}
            setExternalActiveModal={setActiveModal}
            onRowsArchive={(usersToArchive) => {
              if (usersToArchive.length > 1) {
                setSelectedIds(usersToArchive.map((x) => x.id));
                setActiveModal("archiveMany");
              } else {
                setActiveItem(usersToArchive[0]);
                setConfirmDialog("archiveUser");
              }
            }}
            onRowsRestore={(usersToRestore) => {
              if (usersToRestore.length > 1) {
                setSelectedIds(usersToRestore.map((x) => x.id));
                setConfirmDialog("archivedUserRestoreMany");
              } else {
                setActiveItem(usersToRestore[0]);
                setConfirmDialog("archivedUserRestore");
              }
            }}
            onRowsHardDelete={(usersToDelete) => {
              if (usersToDelete.length > 1) {
                setSelectedIds(usersToDelete.map((x) => x.id));
                setConfirmDialog("archivedUserDeleteMany");
              } else {
                setActiveItem(usersToDelete[0]);
                setConfirmDialog("archivedUserDelete");
              }
            }}
            onRowsHardDeleteSR={(usersToDelete) => {
              if (usersToDelete.length > 1) {
                setSelectedIds(usersToDelete.map((x) => x.id));
                setConfirmDialog("selfRegDeleteMany");
              } else {
                setActiveItem(usersToDelete[0]);
                setConfirmDialog("selfRegDelete");
              }
            }}
            onRowsApprove={(usersToApprove) => {
              if (usersToApprove.length > 1) {
                setSelectedIds(usersToApprove.map((x) => x.id));
                setConfirmDialog("selfRegApproveMany");
              } else {
                setActiveItem(usersToApprove[0]);
                setConfirmDialog("selfRegApprove");
              }
            }}
            onRowsReject={(usersToReject) => {
              if (usersToReject.length > 1) {
                setSelectedIds(usersToReject.map((x) => x.id));
                setConfirmDialog("selfRegRejectMany");
              } else {
                setActiveItem(usersToReject[0]);
                setConfirmDialog("selfRegReject");
              }
            }}
            onFrontLineChange={handleFrontLineChange}
            onImmformChange={handleImmformChange}
            alertBoxCounts={alertBoxCounts}
          />
          <CreateDialog
            open={activeModal === "add"}
            onClose={handleCloseModal}
            onSuccess={() => {
              setFeedback({
                type: "success",
                message: "Successfully added staff member",
              });
              setConfirmDialog(null);
              setActiveModal(null);
              fetchStaff();
            }}
            onError={(response) => {
              if (response?.status == 409) {
                if (response.data.results.archived) {
                  setConfirmDialog("userExists");
                } else {
                  setFeedback({
                    type: "error",
                    message: response.errors,
                  });
                }
              } else {
                const messages = response?.data?.results;
                setFeedback({
                  type: "error",
                  message: messages?.message
                    ? messages.message
                    : messages
                      ? Array.isArray(messages)
                        ? messages?.join(" ")
                        : messages
                      : "Failed to add staff member",
                });
              }
            }}
            defaultItem={{}}
          />

          {[...Object.entries(actionMenu), ...Object.entries(rowActions)]
            .filter(([_, action]) => action.Dialog)
            .map(([key, { title, Dialog, dialogProps }]) => (
              <Dialog
                key={key}
                open={activeModal === key}
                onClose={handleCloseModal}
                onError={(messages) => {
                  setFeedback({
                    type: "error",
                    message: messages?.message
                      ? messages.message
                      : messages
                        ? Array.isArray(messages)
                          ? messages?.join(" ")
                          : messages
                        : `Failed to ${title}`,
                  });
                }}
                {...dialogProps}
              />
            ))}

          <BookAppointmentDialog
            open={appointmentAction?.modal === "bookAppointment"}
            onClose={setAppointmentAction}
            onSuccess={handleBookedAppointment}
            pathogens={appointmentAction?.pathogens}
            item={appointmentAction?.item}
            reschedule={
              appointmentAction?.reschedule
                ? appointmentAction.reservationId
                : undefined
            }
          />

          <ViewAppointmentDialog
            open={appointmentAction?.modal === "viewAppointment"}
            onClose={setAppointmentAction}
            onCancel={() => setConfirmDialog("cancelAppointment")}
            onReschedule={() => setConfirmDialog("rescheduleAppointment")}
            item={appointmentAction}
          />

          <ConfirmDialog
            open={confirmDialog === "cancelAppointment"}
            title="Cancel Appointment"
            confirmLabel="Yes, Cancel"
            onConfirm={() =>
              handleCancelAppointment(appointmentAction.reservationId)
            }
            onClose={() => setConfirmDialog(null)}
          >
            <p>Are you sure you want to cancel this appointment?</p>
          </ConfirmDialog>

          <ConfirmDialog
            open={confirmDialog === "rescheduleAppointment"}
            title="Reschedule Appointment"
            confirmLabel="Yes, Reschedule"
            onConfirm={() =>
              handleRescheduleAppointment(appointmentAction.reservationId)
            }
            onClose={() => setConfirmDialog(null)}
          >
            <p>Are you sure you want to reschedule this appointment?</p>
            <p>
              Please note: your current booking will not be cancelled until you
              book the new one.
            </p>
          </ConfirmDialog>

          <ConfirmDialog
            open={confirmDialog === "removeDecline"}
            title={`Are you sure you want to undo this refusal for ${Pathogens[activePathogen]}?`}
            onConfirm={() =>
              api.staff
                .undoDecline(activeItem.id, activePathogen)
                .then(() => {
                  setFeedback({
                    type: "success",
                    message: `Successfully Undone Decline for ${humanPathogen(
                      activePathogen
                    )}`,
                  });
                  fetchStaff();
                  setActiveModal(null);
                  setConfirmDialog(null);
                })
                .catch((e) => {
                  setFeedback({
                    type: "error",
                    message: `Failed to Undo Decline for ${humanPathogen(
                      activePathogen
                    )}`,
                  });
                  setConfirmDialog(null);
                })
            }
            onClose={() => setConfirmDialog(null)}
          ></ConfirmDialog>

          <ConfirmDialog
            open={confirmDialog === "removeExempt"}
            title={`Are you sure you want to undo ${activeItem?.firstName} ${
              activeItem?.lastName
            }'s exemption for ${humanPathogen(activePathogen)} vaccinations?`}
            onConfirm={() =>
              api.staff
                .undoDecline(activeItem.id, activePathogen)
                .then(() => {
                  setFeedback({
                    type: "success",
                    message: `Exemption for ${humanPathogen(
                      activePathogen
                    )} successfully removed`,
                  });
                  fetchStaff();
                  setActiveModal(null);
                  setConfirmDialog(null);
                })
                .catch((e) => {
                  setFeedback({
                    type: "error",
                    message: `Failed to Undo Exemption for ${humanPathogen(
                      activePathogen
                    )}`,
                  });
                  setConfirmDialog(null);
                })
            }
            onClose={() => setConfirmDialog(null)}
          >
            <p>This will enable them to book a vaccination.</p>
          </ConfirmDialog>

          {feedback && (
            <ToastMessage
              variant={feedback.type}
              message={feedback.message}
              clear={() => setFeedback(null)}
            />
          )}
          {activeModal === "vaccinationDetails" && (
            <VaccinationDetails
              {...{
                trustWorkerName: humanName(
                  activeItem.firstName,
                  activeItem.lastName
                ),
                trustWorkerDob: humanDate(activeItem.dateOfBirth),
                trustWorkerId:
                  activeItem.id || props.match?.params?.trustWorkerId,
                trustWorkerFlags: [
                  "flagOver65",
                  "flagUnder18",
                  "flagFrontline",
                  "flagCovidVaccinated",
                  "flagFluVaccinated",
                ].filter((flag) => activeItem && activeItem[flag]),
                pathogenId: activePathogen,
                pathogenName: Pathogens[activePathogen],
                closeCallback: (updated) => {
                  if (updated) {
                    fetchStaff();
                  }
                  setActiveModal(null);
                },
                showSendVaccinationConfirmation: true,
              }}
            />
          )}
        </div>
      )}

      {/* // Archived user dialogs. */}

      <ArchiveUserDialog
        open={confirmDialog === "archiveUser"}
        onClose={() => {
          setActiveModal(null);
          setConfirmDialog(null);
        }}
        onConfirm={() => {
          handleArchive();
        }}
        activeItem={activeItem}
        userHasAnyReservation={userHasAnyReservation}
      ></ArchiveUserDialog>

      <ConfirmDialog
        open={activeModal === "archiveMany"}
        title={`Archive Staff Records`}
        children={
          <>
            <p>
              Archiving will hide any selected users within the system. They
              will be excluded from all functionality in the system and any
              booked appointments will be automatically cancelled. However, any
              vaccination history associated with the record will be retained.
            </p>
            <p>
              To view archived staff, select ‘View Archived Staff’ in the
              ‘Active / Inactive’ section in ‘Filters’. From there you can
              permanently delete a record by clicking the ‘Delete’ icon.
            </p>
            <p>
              <strong>
                Are you sure you want to archive these staff records?
              </strong>
            </p>
          </>
        }
        onClose={handleCloseModal}
        onConfirm={handleArchiveMany}
      />
      <ConfirmDialog
        open={confirmDialog === "archivedUserDelete"}
        {...{
          title: `Are you sure you want to permanently delete ${activeItem?.firstName} ${activeItem?.lastName}?`,
          children: (
            <p>
              Their record including any vaccination history will be permanently
              deleted. Permanently deleted records cannot be restored.
            </p>
          ),
          onCancel: () => setConfirmDialog(null),
          onClose: () => setConfirmDialog(null),
          confirmLabel: "Permanently Delete",
          onConfirm: () => {
            handleArchivedUserAction("delete", activeItem);
            setConfirmDialog(null);
          },
        }}
      ></ConfirmDialog>
      <ConfirmDialog
        open={confirmDialog === "archivedUserDeleteMany"}
        {...{
          title: `Are you sure you want to permanently these records?`,
          children: (
            <p>
              The records including any vaccination history will be permanently
              deleted. Permanently deleted records cannot be restored.
            </p>
          ),
          onCancel: () => setConfirmDialog(null),
          onClose: () => setConfirmDialog(null),
          confirmLabel: "Permanently Delete",
          onConfirm: () => {
            console.log("confirmed");
            handleArchivedUserAction("deleteMany", null);
            setConfirmDialog(null);
          },
        }}
      ></ConfirmDialog>

      <ConfirmDialog
        open={confirmDialog === "archivedUserRestore"}
        {...{
          title: `Are you sure you want to restore ${activeItem?.firstName} ${activeItem?.lastName}?`,
          children: (
            <>
              <p>
                Their record including any vaccination history will be restored
                to the staff page.
              </p>
            </>
          ),
          onCancel: () => setConfirmDialog(null),
          onClose: () => setConfirmDialog(null),
          confirmLabel: "Restore",
          onConfirm: () => {
            handleArchivedUserAction("restore", activeItem);
            setConfirmDialog(null);
          },
        }}
      ></ConfirmDialog>

      <ConfirmDialog
        open={confirmDialog === "archivedUserRestoreMany"}
        {...{
          title: `Are you sure you want to restore these records?`,
          children: (
            <>
              <p>
                The records including any vaccination history will be restored
                to the staff page.
              </p>
            </>
          ),
          onCancel: () => setConfirmDialog(null),
          onClose: () => setConfirmDialog(null),
          confirmLabel: "Restore",
          onConfirm: () => {
            handleArchivedUserAction("restoreMany", null);
            setConfirmDialog(null);
          },
        }}
      ></ConfirmDialog>

      {/* SelfReg users Dialogs */}
      <ConfirmDialog
        open={confirmDialog === "selfRegDelete"}
        {...{
          title: `Are you sure you want to delete ${activeItem?.firstName} ${activeItem?.lastName}?`,
          children: (
            <>
              <p>
                They will be deleted from the staff list but will NOT be sent an
                email confirmation.This option is most suitable for any obvious
                spam registrations.
              </p>
              <p>
                If you do wish to send a rejection email, select the ‘Reject’
                option instead.
              </p>
            </>
          ),
          onCancel: () => setConfirmDialog(null),
          onClose: () => setConfirmDialog(null),
          confirmLabel: "Delete",
          onConfirm: () => {
            handleSelfRegAction("delete", activeItem);
            setConfirmDialog(null);
          },
        }}
      ></ConfirmDialog>
      <ConfirmDialog
        open={confirmDialog === "selfRegDeleteMany"}
        {...{
          title: `Are you sure you want to delete these users?`,
          children: (
            <>
              <p>
                They will be deleted from the staff list but will NOT be sent an
                email confirmation.This option is most suitable for any obvious
                spam registrations.
              </p>
              <p>
                If you do wish to send a rejection email, select the ‘Reject’
                option instead.
              </p>
            </>
          ),
          closeLabel: "Cancel",
          onCancel: () => setConfirmDialog(null),
          onClose: () => setConfirmDialog(null),
          confirmLabel: "Delete",
          onConfirm: () => {
            handleSelfRegAction("deleteMany", null);
            setConfirmDialog(null);
          },
        }}
      ></ConfirmDialog>
      <ConfirmDialog
        open={confirmDialog === "selfRegApprove"}
        {...{
          title: `Are you sure you want to approve ${activeItem?.firstName} ${activeItem?.lastName}?`,
          children: (
            <p>
              They will be sent an email inviting them to verify their account
              by setting a password. Once verified they will be able to book a
              vaccination, record a ‘had elsewhere’ or decline.
            </p>
          ),
          closeLabel: "Cancel",
          onCancel: () => setConfirmDialog(null),
          onClose: () => setConfirmDialog(null),
          confirmLabel: "Approve",
          onConfirm: () => {
            handleSelfRegAction("approve", activeItem);
            setConfirmDialog(null);
          },
        }}
      ></ConfirmDialog>
      <ConfirmDialog
        open={confirmDialog === "selfRegApproveMany"}
        {...{
          title: `Are you sure you want to approve these users?`,
          children: (
            <p>
              They will be sent an email inviting them to verify their account
              by setting a password. Once verified they will be able to book a
              vaccination, record a ‘had elsewhere’ or decline.
            </p>
          ),
          closeLabel: "Cancel",
          onCancel: () => setConfirmDialog(null),
          onClose: () => setConfirmDialog(null),
          confirmLabel: "Approve",
          onConfirm: () => {
            handleSelfRegAction("approveMany", null);
            setConfirmDialog(null);
          },
        }}
      ></ConfirmDialog>
      <ConfirmDialog
        open={confirmDialog === "selfRegReject"}
        {...{
          title: `Are you sure you want to reject ${activeItem?.firstName} ${activeItem?.lastName}?`,
          children: (
            <p>
              They will be sent a rejection email and be deleted from the staff
              list. To delete the user without sending a rejection email click
              ‘Cancel’ and choose the ‘Delete’ option.
            </p>
          ),
          onCancel: () => setConfirmDialog(null),
          onClose: () => setConfirmDialog(null),
          confirmLabel: "Reject",
          onConfirm: () => {
            handleSelfRegAction("reject", activeItem);
            setConfirmDialog(null);
          },
        }}
      ></ConfirmDialog>
      <ConfirmDialog
        open={confirmDialog === "selfRegRejectMany"}
        {...{
          title: `Are you sure you want to reject these users?`,
          children: (
            <p>
              They will be sent a rejection email and be deleted from the staff
              list. To delete the user without sending a rejection email click
              ‘Cancel’ and choose the ‘Delete’ option.
            </p>
          ),
          onCancel: () => setConfirmDialog(null),
          onClose: () => setConfirmDialog(null),
          confirmLabel: "Reject",
          onConfirm: () => {
            handleSelfRegAction("rejectMany", null);
            setConfirmDialog(null);
          },
        }}
      ></ConfirmDialog>
      <ConfirmDialog
        open={confirmDialog === "confirmRemoveEmailInDoubt"}
        {...{
          title: `Are you sure you want to remove the Email in Doubt flag?`,
          onCancel: () => setConfirmDialog(null),
          onClose: () => setConfirmDialog(null),
          confirmLabel: "Remove flag",
          onConfirm: () => {
            removeEmailInDoubt();
            setConfirmDialog(null);
          },
        }}
      ></ConfirmDialog>
      <ConfirmDialog
        // This is confirmation for the edit screen
        open={confirmDialog === "confirmRemovePossibleDuplicate"}
        {...{
          title: `Are you sure you want to remove the Possible Duplicate flag?`,
          onCancel: () => setConfirmDialog(null),
          onClose: () => setConfirmDialog(null),
          confirmLabel: "Remove flag",
          onConfirm: () => {
            removePossibleDuplicate();
            setConfirmDialog(null);
          },
        }}
      ></ConfirmDialog>
    </>
  );
}

export default List;
