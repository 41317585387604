import React from "react";
import ReactDOM from "react-dom";
import DataClient from "../../../../lib/services/api/DataClient";
import { SuperAdmin } from "./useSuperAdminList";

export default function useUpdateSuperAdmin() {
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState<false | SuperAdmin>(false);

  const callback = (id: number, update: SuperAdmin) => {
    setSuccess(false);
    DataClient.update(`/SuperAdmin/${id}`, update)
      .then((res: unknown) => {
        ReactDOM.unstable_batchedUpdates(() => {
          if (!res["success"]) {
            throw new Error("API Update failed");
          }
          setSuccess({ ...update, id });
        });
      })
      .catch((error) => {
        ReactDOM.unstable_batchedUpdates(() => {
          console.error(error);
          setError(true);
        });
      });
  };

  return [callback, success, error] as const;
}
