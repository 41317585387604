import { IconButton } from "@material-ui/core";
import { Close as CancelIcon } from "@material-ui/icons";
import { Component } from "react";

export default class extends Component {
  state = { value: "" };
  debounce = (func, wait) => {
    clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(() => {
      func();
    }, wait);
  };
  onChange = (e) => {
    const { value } = e.target;
    this.setState({ value });
    this.debounce(() => {
      this.props.handleSearch(value);
    }, 500);
  };
  render() {
    // const { classes, options, onHide, searchText } = this.props;
    const { value } = this.state;
    return (
      <form
        className="form search"
        ref={(el) => (this.rootRef = el)}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <input
          placeholder={"Search.."}
          type="search"
          value={value !== " " && value}
          onChange={this.onChange}
        />
        {value.length > 0 && (
          <IconButton
            aria-label="Clear Search"
            style={{ padding: "8px" }}
            onClick={() => {
              this.setState({ value: "" });
              this.props.handleSearch("");
            }}
          >
            <CancelIcon />
          </IconButton>
        )}
      </form>
    );
  }
}
