import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { FunctionComponent, useEffect, useRef, useState } from "react";

/**
 * This component displays a confirm dialog.
 * If the onConfirm function returns a promise, the submit button will be show a loading state and
 * the submit button will be disabled until the promise finalizes
 *
 * Example usage:
 * <ConfirmDialog
 *   title="Lorem"
 *   open={true}
 *   onConfirm={() => {}}
 *   onClose={() => {}}
 * >
 *   <p>Ipsum</p>
 * </ConfirmDialog>
 *
 * @param showConfirm
 * @param disableConfirm
 * @param showClose
 * @param children
 * @param confirmLabel
 * @param closeLabel
 * @param dialogParams
 * @param dialogContentParams
 * @param loading
 * @param onClose
 * @param onConfirm
 * @param open
 * @param title
 * @param alert
 * @constructor
 */
const ConfirmDialog: FunctionComponent<{
  alert?: JSX.Element;
  children?: JSX.Element;
  showConfirm?: boolean;
  disableConfirm?: boolean;
  showClose?: boolean;
  confirmLabel?: string;
  closeLabel?: string;
  dialogParams?: any;
  dialogContentParams?: Record<string, any>;
  loading?: boolean;
  onClose: (...args: any[]) => void;
  // If a promise is returned, the submit button will be disabled until the promise finalizes.
  onConfirm: ((...args: any[]) => void) | ((...args: any[]) => Promise<any>);
  open?: boolean;
  title: string | JSX.Element;
}> = ({
  showConfirm = true,
  disableConfirm = false,
  showClose = true,
  children,
  confirmLabel,
  closeLabel,
  dialogParams = {},
  dialogContentParams = {},
  loading = false,
  onClose,
  onConfirm,
  open,
  title,
  alert,
}) => {
  // dialogParams.className = `confirm-dialog ${
  //   dialogParams?.className ? dialogParams.className : ""
  // }`;

  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [isLoading, setIsLoading] = useState(loading);
  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  async function handleConfirm() {
    if (onConfirm) {
      // This calls the onConfirm callback
      const onConfirmCB = onConfirm();
      // Check if the CB function returns a Promise and use that promise to re-enable the button
      if (onConfirmCB && typeof onConfirmCB.then === "function") {
        console.log("with CB");
        submitButtonRef.current.disabled = true;
        setIsLoading(true);
        try {
          await onConfirmCB;
        } catch (error) {
          console.error("Error:", error);
        } finally {
          setTimeout(() => {
            if (submitButtonRef.current) {
              submitButtonRef.current.disabled = false;
            }
            setIsLoading(false);
          }, 300);
        }
      }
    }
  }
  return (
    <Dialog {...{ open, onClose }} {...dialogParams}>
      <DialogTitle disableTypography>
        <h2>{title}</h2>
      </DialogTitle>
      <DialogContent {...dialogContentParams}>{children}</DialogContent>
      {(showConfirm || showClose) && (
        <DialogActions>
          {showConfirm && (
            <Button
              ref={submitButtonRef}
              onClick={handleConfirm}
              type="submit"
              variant="contained"
              color="secondary"
              autoFocus
              disabled={loading || disableConfirm}
            >
              {isLoading && (
                <CircularProgress
                  size={"1rem"}
                  style={{
                    margin: "0 .5em 0 0",
                  }}
                />
              )}
              {confirmLabel || "Yes"}
            </Button>
          )}
          {showClose && (
            <Button
              onClick={onClose}
              variant="outlined"
              color="secondary"
              style={{
                color: "#fff",
              }}
              disabled={isLoading}
            >
              {closeLabel || "Cancel"}
            </Button>
          )}
          {alert && <Box flexGrow={1}>{alert}</Box>}
        </DialogActions>
      )}
    </Dialog>
  );
};
export { ConfirmDialog };
export default ConfirmDialog;
