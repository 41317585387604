import ConfirmDialog from "../../ConfirmDialog";

export interface UserExistsDialogProps {
  userExistsData: any;
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
}
/**
 * This component is used in when manually adding staff. If the staff record already exists (matched on FirstName, LastName and DOB) then this dialog is shown.
 * The parent element handles the logic of whether to show the dialog or not.
 * The parent elements handles the logic of what to do when the user clicks the confirm button or the close button.
 * @param props
 * @returns
 */
export function UserExistsDialog(props: UserExistsDialogProps): JSX.Element {
  const { userExistsData, onClose, onConfirm, open } = props;
  return (
    <ConfirmDialog
      open={open}
      title="Archived record already exists"
      children={
        <>
          {userExistsData?.newUserData.archived ? (
            <>
              <p>
                A previously archived record with the same first name, last name
                and DOB already exists in the database:{" "}
                {userExistsData.newUserData.firstName}{" "}
                {userExistsData.newUserData.lastName},{" "}
                {new Date(
                  userExistsData.newUserData.dateOfBirth
                ).toLocaleDateString()}
                .
              </p>
              <p>
                Since the record has been archived, it does not currently appear
                on the staff page and is not available to drop into a clinic.
                You can either:
              </p>
              <p>
                <strong>1. Restore the staff member’s record.</strong> This will
                fully restore the record to the state it was prior to being
                archived, including their job role details, any vaccination
                history and access level (i.e. if they were assigned as a clinic
                admin or vaccinator). This will also mean they are again
                searchable on the staff page and via the ‘Add Drop In’ function
                in a clinic.
              </p>
              <p>
                <strong>
                  2. Amend the staff member’s details to create a new unique
                  record.
                </strong>{" "}
                Please note, changing the first name, last name or D.O.B will
                create a new unique record. Therefore, it is recommended to
                restore the archived record and then make changes via the pen
                edit icon on the staff page.
              </p>
            </>
          ) : (
            <>
              <p>{userExistsData?.errorMsg}</p>
              <p>
                Please edit the existing record if you would like to change any
                of their details.
              </p>
            </>
          )}
        </>
      }
      confirmLabel={
        userExistsData?.newUserData.archived ? "Restore Full Record" : "Ok"
      }
      closeLabel={
        userExistsData?.newUserData.archived ? "Amend Details" : undefined
      }
      showClose={userExistsData?.newUserData.archived}
      onClose={onClose}
      onConfirm={onConfirm}
    ></ConfirmDialog>
  );
}
