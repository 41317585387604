import styles from "./AppointmentFields.module.scss";
import { humanDate, humanDateMMMMYYYY } from "../../../../../lib/common_utils";
import { MyAppointment } from "../MyAppointments";
export const AppointmentFields = ({
  appointment,
}: {
  appointment: MyAppointment | any;
}) => {
  // console.log("::MyAppointment", appointment);
  const fields = [
    {
      label: "Date",
      value: humanDate(
        // Either date of upcoming appointment, or vaccinated vaccination
        appointment?.details?.clinicDate ||
          appointment?.status?.patientVaccination?.vaccinationDate
      ),
    },
    { label: "Time Slot", value: appointment?.details?.timeDuration },
    // Unavailable (Vaccinated but no booking) has this instead of timeDuration:
    {
      label: "Time",
      value: appointment?.status?.patientVaccination?.vaccinationTime,
    },
    // Booking:
    { label: "Hospital", value: appointment?.details?.hospitalSiteName },
    // "On front end rename Location to be Vaccination Site" https://2-app.donedone.com/6110/project/17407/task/682515
    { label: "Vaccination Site", value: appointment?.details?.locationName },
    { label: "Clinic Name", value: appointment?.details?.clinicLocationName },
    {
      label: "Vaccine Type",
      value: appointment?.status?.patientVaccination?.vaccinationTypeName,
    },
    {
      label: "Vaccine Batch Number",
      value: appointment?.status?.patientVaccination?.vaccinationBatchNumber,
    },
    {
      label: "Vaccine Expiry Date",
      value:
        appointment?.status?.patientVaccination?.expiryDate
          ? humanDateMMMMYYYY(
              appointment.status.patientVaccination.expiryDate
            )
          : null,
    },
    {
      label: "Vaccinator",
      value: appointment?.status?.patientVaccination?.vaccinatorName,
    },
    {
      label: "Injection site",
      value: appointment?.status?.patientVaccination?.injectionSiteName,
    },
    {
      label: "Known Allergies",
      value: appointment?.status?.patientVaccination?.allergyStatus,
    },
    {
      label: "Adverse reaction",
      value: appointment?.status?.patientVaccination?.adverseReaction,
    },
    {
      label: "Other information",
      value: appointment?.status?.patientVaccination?.otherInformation,
    },
    {
      label: "Description",
      value: appointment?.details?.description,
    },
  ];
  return (
    <dl className={styles.dl}>
      {fields
        .filter((f) => f.value)
        .map(({ label, value }) => (
          <div>
            <dt>{label}</dt>
            <dd>{value}</dd>
          </div>
        ))}
    </dl>
  );
};
