import React from "react";
import ReactDOM from "react-dom";
import DataClient from "../services/api/DataClient";
export interface Campaign {
  id: number;
  name: string;
  dateFrom: Date;
  dateTo: Date;
  totalNumberOfHospitals: number;
  active: boolean;
  notify: boolean;
}

export default function useCampaigns(isTrustWorker: boolean) {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [hasActiveCampaigns, setActiveCampaigns] = React.useState(false);
  const [campaigns, setCampaigns] = React.useState<Campaign[]>(null);
  React.useEffect(() => {
    if (!isTrustWorker) {
      DataClient.getData("/campaign")
        .then((res) => {
          ReactDOM.unstable_batchedUpdates(() => {
            const results = res.results as Campaign[];
            setLoading(false);
            setCampaigns(results);
            setActiveCampaigns(results.some((campaign) => campaign.active));
          });
        })
        .catch(() => {
          ReactDOM.unstable_batchedUpdates(() => {
            setLoading(false);
            setError(true);
          });
        });
    }
  }, [isTrustWorker]);
  if (isTrustWorker) {
    return {
      loading: false,
      error: false,
      hasActiveCampaigns: false,
      campaigns: null,
    };
  }
  return { loading, error, hasActiveCampaigns, campaigns };
}
