import api from "./data";
import { useState } from "react";

export default function useListData(
  setConfirmDialog: (name: string) => void,
  setFeedback: (value: any) => void,
  fetchStaff: (...args: any[]) => void
) {
  const [appointmentAction, setAppointmentAction] = useState(null);

  const handleRescheduleAppointment = () => {
    setAppointmentAction({
      ...appointmentAction,
      modal: "bookAppointment",
      reschedule: true,
    });
    setConfirmDialog(null);
  };

  const handleBookedAppointment = () => {
    fetchStaff();
    setFeedback({
      type: "success",
      message: "Appointment Booked",
    });
    setAppointmentAction(null);
  };

  const handleCancelAppointment = (id: number) => {
    api.staff
      .removeReservation(id, false)
      .then((res) => {
        if (res.success) {
          setFeedback({
            type: "success",
            message: "Appointment cancelled.",
          });
          setAppointmentAction(null);
          setConfirmDialog(null);
          fetchStaff();
        } else {
          setFeedback({
            type: "error",
            message: "Error cancelling appointment",
          });
        }
      })
      .catch(() => {
        setFeedback({
          type: "error",
          message: "Error cancelling appointment",
        });
      });
  };

  return {
    appointmentAction,
    setAppointmentAction,
    handleRescheduleAppointment,
    handleCancelAppointment,
    handleBookedAppointment,
  };
}
