import { Pathogens } from "../../../../../lib/pathogens";
import { Link, useHistory } from "react-router-dom";
import styles from "./appointments.module.scss";
import BoosterDateChecker, { DateContext } from "../../BoosterDateChecker";
import React, { useContext, useEffect } from "react";
import { findLastCovidDoseDateInMe } from "../../../../../lib/services/TrustSwitcher";
import DeclineActions from "./DeclineActions";
import { MeContext, MyAppointment } from "../MyAppointments";
import { DateTime } from "luxon";
import { Button, CircularProgress } from "@material-ui/core";
type InvitationProps = {
  appointment: MyAppointment;
};
type PathogenOption = {
  label: string | JSX.Element;
  value: Pathogens[];
};
export default function Invitation({ appointment }: InvitationProps) {
  const history = useHistory();
  const { pathogens, isSinglePathogenTrust } = appointment;
  const label = getLabel(pathogens, BookingList);
  const buttonColour = getLabel(pathogens, ColourList) as string;
  const [lastCovidDoseDate, setLastCovidDoseDate] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  // const [lastCovidDoseDate, setLastCovidDoseDate] =
  // React.useState<DateTime | null>(null);
  const { me } = useContext(MeContext);
  useEffect(() => {
    if (
      appointment?.pathogens?.includes(Pathogens["Covid"]) &&
      !lastCovidDoseDate
    ) {
      setLoading(true);
      if (me) {
        const date = findLastCovidDoseDateInMe(me);
        setLastCovidDoseDate(date);
        setLoading(false);
      }
    }
  }, [setLastCovidDoseDate, me]);
  return (
    <div>
      {loading && (
        <div className="interstitial">
          <CircularProgress />
        </div>
      )}
      <div className={styles.body}>
        <DateContext.Provider value={{ setDate: setLastCovidDoseDate }}>
          {getBody(pathogens, isSinglePathogenTrust, lastCovidDoseDate)}
        </DateContext.Provider>
      </div>
      <div className={styles.actions}>
        <div className={styles.book}>
          <Button
            component={Link}
            to={`/book-appointment/${encodeURIComponent(
              JSON.stringify(pathogens)
            )}`}
            color="secondary"
            variant="contained"
            size="small"
            data-theme-id={pathogens}
          >
            {label}
          </Button>
        </div>
        <DeclineActions appointment={appointment} />
      </div>
    </div>
  );
}
const ColourList: PathogenOption[] = [
  { label: "flu", value: [Pathogens.Flu] },
  { label: "covid", value: [Pathogens.Covid] },
  {
    label: "combined",
    value: [Pathogens.Flu, Pathogens.Covid],
  },
];
const BookingList: PathogenOption[] = [
  { label: "Book Flu Vaccination", value: [Pathogens.Flu] },
  { label: "Book Covid Booster Vaccination", value: [Pathogens.Covid] },
  {
    label: "Book Flu & Covid Booster Vaccinations Together",
    value: [Pathogens.Flu, Pathogens.Covid],
  },
];
const BodyList: {
  [x: string]: (
    single: boolean,
    lastCovidDoseDate?: DateTime | null
  ) => JSX.Element;
} = {
  [JSON.stringify([Pathogens.Flu])]: (single: boolean) => (
    <>
      <p>
        Click the button below to{" "}
        <strong>book {!single && "just "}your flu vaccination.</strong>
      </p>
      {!single && (
        <p>
          If you choose this option, you will need to book your Covid booster
          vaccination separately.
        </p>
      )}
    </>
  ),
  [JSON.stringify([Pathogens.Covid])]: (
    single: boolean,
    lastCovidDoseDate?: DateTime | null
  ) => (
    <>
      <p>
        Click the button below to{" "}
        <strong>
          book {!single && "just "}your Covid booster vaccination.
        </strong>
      </p>
      {!single && (
        <p>
          If you choose this option, you will need to book your flu vaccination
          separately.
        </p>
      )}
      <p>
        The Covid booster must be received a minimum of 91 days after your most
        recent dose.
      </p>
      <BoosterDateChecker {...{ dateOverride: lastCovidDoseDate }} />
    </>
  ),
  [JSON.stringify([Pathogens.Flu, Pathogens.Covid])]: (single: boolean) => (
    <>
      <p>
        Click the button below to{" "}
        <strong>
          book your flu &amp; Covid booster vaccinations together in the same
          appointment.
        </strong>
      </p>
      <p>
        If you choose this option, you will not need to book your flu and covid
        booster appointments separately.
      </p>
      <p>
        If you’ve already had a vaccine elsewhere or you wish to decline one,
        click on the relevant link in the flu and/or Covid boxes on this page.
        Each vaccine type must be declined separately.
      </p>
    </>
  ),
};
function getLabel(
  pathogens: Pathogens[],
  list: PathogenOption[]
): string | undefined | JSX.Element {
  const option = list.find(
    (pathogenOption) =>
      JSON.stringify(pathogenOption.value) === JSON.stringify(pathogens)
  );
  return option?.label;
}
function getBody(
  pathogens: Pathogens[],
  isSinglePathogenTrust: boolean,
  lastCovidDoseDate?: DateTime | null
): JSX.Element {
  return BodyList[JSON.stringify(pathogens)](
    isSinglePathogenTrust,
    lastCovidDoseDate
  );
}
const disabledMap = {
  [JSON.stringify([Pathogens.Flu])]: () => false,
  [JSON.stringify([Pathogens.Covid])]: (date: string) => !date,
  [JSON.stringify([Pathogens.Flu, Pathogens.Covid])]: () => false,
};
function isDisabled(pathogens: Pathogens[], date: string) {
  return disabledMap[JSON.stringify(pathogens)](date);
}
