import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  TextField,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { isWidthUp } from "@material-ui/core/withWidth";
import {
  Add,
  Cancel as CancelIcon,
  Search as SearchIcon,
} from "@material-ui/icons/";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import React, { useState } from "react";
import NoResultsFound from "../../../../components/Loader/no_results_found";
import { humanNameFromObject } from "../../../../lib/common_utils";
import DataClient from "../../../../lib/services/api/DataClient";
import ReactDOM from "react-dom";
import {
  getLabel,
  humanPathogens,
  Pathogens,
  sortCombinedPathogensToStart,
} from "../../../../lib/pathogens";
import { ViewAppointmentDialog } from "../Staff/Appointment";
import api from "../Staff/data";
import {
  getPathogenPermissionMatrix,
  getReservations,
} from "../trustWorkerExtensions";
import ConfirmDialog from "../../ConfirmDialog";
import {
  alertAddedPatient,
  alertAddedPatientError,
  alertCancelAppointment,
  alertCancelAppointmentError,
} from "../../../../components/AlertMessaging";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "fixed",
    borderBottom: "1px solid #f5f5f5",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    "& button": {
      marginRight: theme.spacing(3),
    },
  },
  DialogTitle: {
    paddingLeft: theme.spacing(4),
    color: "#fff",
    flexGrow: 1,
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.h3,
    fontWeight: "bold",
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.h4,
    },
  },
  // dense: {
  //   marginTop: theme.spacing(4),
  // },
  search: {
    [theme.breakpoints.up("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "80%",
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "40%",
    },
  },
  workerTable: {
    width: "100%",
  },
  padContent: {
    paddingLeft: "10%",
    paddingRight: "10%",
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  minWidthWrap: {
    minWidth: "350px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "unset",
    },
  },
  submit: {
    marginBottom: "10px",
    marginTop: "10px",
  },
  detailWrapper: {
    backgroundColor: "#f0f0f0",
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
  cardLabel: {
    fontSize: "12px",
  },
  subTitle: {
    fontWeight: "bold",
    fontSize: 16,
    padding: theme.spacing(0, 4),
  },
  subTitle2: {
    fontWeight: "bold",
    fontSize: 16,
  },
  padTopAndBottom: {
    padding: "30px 5px",
  },
}));

const tweakPatientDetails = (data) =>
  data.map((d) => ({
    ...d,
    name: humanNameFromObject(d),
    pathogenPermissions: getPathogenPermissionMatrix(d),
    reservations: getReservations(d),
  }));

export default function AddPatient(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [fullScreen, setFullScreen] = useState(
    useMediaQuery(theme.breakpoints.down("sm"))
  );
  const { clinicId, clinicPathogenIds, handleNewBookingAdd, handleClose } =
    props;
  let [patientDetails, setPatientDetails] = useState([]);
  const [alert, setAlert] = useState(null);
  const [hasSearched, setHasSearched] = useState(false);
  const [activeReservation, setActiveReservation] = useState(null);

  const handleSubmit = (e) => {
    setAlert(null);
    setFullScreen(true);
    e.preventDefault();
    setLoading(true);
    const formData = new FormData(e.target);
    DataClient.postData(`/TrustWorker/Search`, {
      pageSize: 250, // seems reasonable?
      searchText: formData.get("email"),
      // excludeVaccinationStatuses: [enumVaccinationStatus["Booked"],enumVaccinationStatus["Vaccinated"]]
    })
      .then(({ success, results }) => {
        setPatientDetails(
          success && results?.pagedResults.length > 0
            ? tweakPatientDetails(results.pagedResults)
            : []
        );
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setPatientDetails([]);
      });
    setHasSearched(true);
  };

  const handleCancelAppointment = () => {
    const error = () => {
      setAlert(alertCancelAppointmentError(activeReservation));
      setActiveReservation(null);
    };
    api.staff
      .removeReservation(activeReservation.reservationId, false)
      .then((res) => {
        if (res.success) {
          const patient = patientDetails.find(
            (p) => p.id === activeReservation.trustWorker.id
          );
          const i = patientDetails.indexOf(patient);

          const reservationIds = activeReservation.pathogens.reduce(
            (p, c) => ({
              ...p,
              [`reservationId${Pathogens[c]}`]: null,
            }),
            {}
          );

          const updatedPatient = {
            ...patient,
            ...reservationIds,
          };

          setPatientDetails([
            ...patientDetails.slice(0, i),
            {
              ...updatedPatient,
              pathogenPermissions: getPathogenPermissionMatrix(updatedPatient),
              reservations: getReservations(updatedPatient),
            },
            ...patientDetails.slice(i + 1),
          ]);
          setActiveReservation(null);

          setAlert(alertCancelAppointment(activeReservation));
        } else {
          error();
        }
      })
      .catch(() => {
        error();
      });
  };
  const [addPatientPreflight, setAddPatientPreflight] = useState({
    show: false,
    patient: null,
    pathogens: null,
    message: null,
  });
  const handleAddPatientPreflight = (patient, pathogens, clinicPathogenIds) => {
    // console.log("::handleAddPatientPreflight", { patient, pathogens, clinicPathogenIds });
    let warnings = [];
    // If patient is not yet active
    if (!patient?.isActive) {
      warnings.push(
        "This staff member currently has an inactive assignment status."
      );
    }
    // If some of the chosen pathogens are not bookable for this person
    pathogens.forEach((p) => {
      console.log(patient.pathogenPermissions[p]);
      if (patient.pathogenPermissions[p].cantBookReasons) {
        let cantBookReasons =
          patient.pathogenPermissions[p].cantBookReasons || [];
        // Replace "the vaccination" with, say "the Flu vaccination"
        cantBookReasons = cantBookReasons.map((r) =>
          r.replace("the vaccination", `the ${Pathogens[p]} vaccination`)
        );
        warnings = [...warnings, ...cantBookReasons];
      }
    });
    // #2038 If some of the chosen pathogens are not included in those for the clinic type
    if (pathogens.some((p) => !clinicPathogenIds.includes(p))) {
      // Empty warning as the following 'Are you sure' is apparently enough
      // warnings.push(" ");
    }
    // if (warnings.length) {
    // #2038 "However, the idea was to have the generic last message even if..."
    setAddPatientPreflight({
      show: true,
      patient,
      pathogens,
      message: (
        <>
          {warnings.length ? (
            <Alert severity="warning" variant="filled">
              {/* <ul className="list-unstyled"> */}
              <ul className="list-simple">
                {warnings.map((w) => (
                  <li>{w}</li>
                ))}
              </ul>
            </Alert>
          ) : (
            ""
          )}
          <p>
            Are you sure you want to drop {humanNameFromObject(patient)} into
            this clinic for a {humanPathogens(pathogens)} vaccination?
          </p>
        </>
      ),
    });
    // } else {
    //   handleAddPatient(patient, pathogens);
    // }
  };
  const handleAddPatient = async (patient, pathogens) => {
    setLoading(true);
    // ^ This can only ever be one item now, as we've removed checkboxes
    const selectedPatientDetails = patient;
    const trustWorkerId = selectedPatientDetails.id;
    DataClient.call({
      url: `/Reservation/AddPatient`,
      method: "POST",
      data: { trustWorkerId, clinicId, pathogens },
    })
      .client.then(async (res) => {
        ReactDOM.unstable_batchedUpdates(() => {
          setLoading(false);
          handleNewBookingAdd({
            ...res,
            firstName: selectedPatientDetails.firstName,
            lastName: selectedPatientDetails.lastName,
            dateOfBirth: selectedPatientDetails.dateOfBirth,
            emailAdress: selectedPatientDetails.emailAddress,
            staffGroupName: selectedPatientDetails.staffGroupName,
            employeeNumber: selectedPatientDetails.employeeNumber,
            pathogens,
            nhsNumber: selectedPatientDetails.nhsNumber,
            vaccinationStatusName: "Booked",
          });
          setAlert(alertAddedPatient(selectedPatientDetails));
        });
      })
      .catch((e) => {
        console.log(e);
        console.log(e.response);
        // API errors are still coming in different formats... sigh
        const errorMessage =
          // "The patient is already booked for the time slot in that clinic"
          e.response?.data && typeof e.response.data === "string"
            ? e.response.data
            : // "Already booked on this campaign."
            e.response?.data?.results && Array.isArray(e.response.data.results)
            ? e.response.data.results.join(", ")
            : "Unknown reason";
        setLoading(false);
        setAlert(alertAddedPatientError(selectedPatientDetails, errorMessage));
      });
  };

  const columns = [
    // #55 "You can combine First name and last name columns into one column"
    {
      //   name: "firstName",
      //   name: "lastName",
      name: "name",
      label: "Name",
      options: {
        filter: false,
        sort: true,
        searchable: true,
        display: true,
      },
    },
    // #55 "You don't need the 'Pathogens' column here"
    // {
    //   name: "pathogens",
    //   label: "Pathogens",
    //   options: {
    //     display: pathogens?.length > 1,
    //     customBodyRender: (v) => getLabel(v) ?? "Not Set",
    //   },
    // },
    {
      name: "dateOfBirth",
      label: "Date of Birth",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(value, "YYYY-MM-DD").format("DD/MM/YYYY");
        },
      },
    },
    {
      name: "emailAddress",
      label: "Email Address",
      options: {
        filter: false,
        sort: false,
        searchable: true,
        display: true,
      },
    },
    {
      name: "",
      options: {
        display: true,
        viewColumns: false,
        print: false,

        customBodyRenderLite: (i) => {
          const patient = patientDetails[i];

          const getPathogenCombinations = (pathogens) =>
            pathogens
              .map((p) => [p])
              .concat(pathogens.length > 1 ? [pathogens] : []);

          const canDropIns = getPathogenCombinations(
            Object.entries(patient.pathogenPermissions)
              .filter(([_pathogen, permission]) => permission.canDropIn)
              .map(([pathogen]) => Number(pathogen))
          )
            .map((pathogens) => ({ pathogens, canBook: true }))
            .sort(sortCombinedPathogensToStart);

          const completeStatuses = Object.entries(patient.pathogenPermissions)
            .filter(([_pathogen, permission]) => permission.isComplete)
            .map(([pathogen]) => ({
              pathogen: pathogen,
              name: patient[`vaccinationStatus${Pathogens[pathogen]}`],
            }));

          const lockedByOtherTrust = Object.entries(patient.pathogenPermissions)
            .filter(([_pathogen, permission]) => permission.lockedByOtherTrust)
            .map(([pathogen]) => ({
              pathogen: pathogen,
              name: patient[`vaccinationStatus${Pathogens[pathogen]}`],
            }));
          return (
            // Show add button for each pathogen in the trust
            // (they may want to tweak these depending on the clinic pathogen type but wait for feedback)
            <>
              {canDropIns.map(({ pathogens }, i) => (
                <Box key={`add-${i}`} ml={1} display="inline">
                  <Button
                    onClick={() =>
                      handleAddPatientPreflight(
                        patient,
                        pathogens,
                        clinicPathogenIds
                      )
                    }
                    color="secondary"
                    variant="contained"
                    size="small"
                    data-theme-id={pathogens}
                    startIcon={<Add />}
                  >
                    <span style={{ width: "max-content" }}>
                      {getLabel(pathogens).toUpperCase()} Drop In
                    </span>
                  </Button>
                </Box>
              ))}
              {patient.reservations
                .filter((reservation) => reservation.canCancel)
                .filter((r) =>
                  r.pathogens.some(
                    (p) => !patient.pathogenPermissions[p]?.lockedByOtherTrust
                  )
                )
                .map((reservation, i) => (
                  <Box key={`res-${i}`} ml={1} display="inline">
                    <Button
                      onClick={() =>
                        setActiveReservation({
                          ...reservation,
                          trustWorker: patient,
                        })
                      }
                      color="secondary"
                      size="small"
                      data-theme-id={reservation.pathogens}
                    >
                      <span style={{ width: "max-content" }}>
                        {getLabel(reservation.pathogens).toUpperCase()} Already
                        Booked{" "}
                        <strong style={{ textDecoration: "underline" }}>
                          View/Cancel
                        </strong>
                      </span>
                    </Button>
                  </Box>
                ))}

              {completeStatuses.map((status, i) => (
                <Box ml={1} display="inline">
                  <span
                    key={`completed-status-${i}`}
                    className="table-status"
                    data-theme-id={[status.pathogen]}
                    style={{ width: "max-content" }}
                  >
                    {status.name} for {Pathogens[status.pathogen]}
                  </span>
                </Box>
              ))}

              {lockedByOtherTrust.map((status, i) => (
                <span
                  key={`locked-status-${i}`}
                  className="table-status"
                  data-theme-id={[status.pathogen]}
                  style={{ width: "max-content" }}
                >
                  {status.name} for {Pathogens[status.pathogen]} by another
                  trust
                </span>
              ))}
            </>
          );
        },
      },
    },
  ];

  const options = {
    elevation: 0,
    searchOpen: false,
    filterType: "dropdown",
    filter: false,
    download: false,
    print: false,
    search: false,
    selectableRows: "none",
    viewColumns: false,
    searchPlaceholder: "Search",
    responsive: isWidthUp("sm", props.width) ? "false" : "stacked",
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    textLabels: {
      body: {
        noMatch: "No Staff added yet",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
    },
  };
  return (
    <div>
      <ViewAppointmentDialog
        open={activeReservation}
        onClose={() => setActiveReservation(null)}
        onCancel={handleCancelAppointment}
        item={activeReservation}
        label="- To add this user as a drop in, you must first cancel this booking."
      />
      <Dialog
        // Originally medium width, then I made it fill the screen, then Liam made it smaller (all at client requests).. but here we are again with:
        // #55 "There seems no need to have two separate window sizes during the process."
        fullScreen={false}
        open={true}
        onClose={handleClose}
        // fullWidth={true}
        maxWidth="xl"
        aria-labelledby="responsive-dialog-title"
      >
        {loading && <LinearProgress color="primary" />}
        <Button
          onClick={handleClose}
          endIcon={<CancelIcon />}
          className="dialog-close"
        >
          Close
        </Button>
        <DialogTitle disableTypography>
          <h2>Add Drop In</h2>
        </DialogTitle>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Box m={2}>
            <TextField
              id="email"
              label="Search by Name, Email or DOB (Eg: 25/01/1999)"
              type="text"
              name="email"
              autoFocus
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              fullWidth={false}
              style={{ minWidth: "20em" }}
              autoComplete="off"
              required
            />
            <DialogContent>{alert}</DialogContent>
          </Box>
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              autoFocus
              startIcon={<SearchIcon />}
            >
              Search Staff
            </Button>
            <Button
              onClick={handleClose}
              variant="outlined"
              color="secondary"
              style={{
                color: "#fff",
              }}
            >
              Cancel
            </Button>
          </DialogActions>
          {loading ? (
            <div className="interstitial">
              <CircularProgress />
            </div>
          ) : patientDetails.length > 0 ? (
            <Box mt={2} className={classes.workerTable}>
              <MUIDataTable
                data={patientDetails}
                columns={columns}
                options={options}
                ref={React.createRef()}
              />
            </Box>
          ) : (
            hasSearched && (
              <div className={classes.padTopAndBottom}>
                <NoResultsFound
                  message={
                    <React.Fragment>
                      <span>No staff member found</span>
                    </React.Fragment>
                  }
                />
              </div>
            )
          )}
        </form>
      </Dialog>
      <ConfirmDialog
        title={
          <>Add Drop In: {humanNameFromObject(addPatientPreflight.patient)}</>
        }
        open={addPatientPreflight?.show}
        onConfirm={() => {
          handleAddPatient(
            addPatientPreflight?.patient,
            addPatientPreflight?.pathogens
          );
          setAddPatientPreflight({
            show: false,
            patient: null,
            pathogens: null,
            message: null,
          });
        }}
        onClose={() => {
          setAddPatientPreflight({
            show: false,
            patient: null,
            pathogens: null,
            message: null,
          });
        }}
      >
        {addPatientPreflight?.message}
      </ConfirmDialog>
    </div>
  );
}
