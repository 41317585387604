import { Button, Grid, LinearProgress } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import ToastMessage from "../../../../components/Universal/toast_message";
import ConfirmDialog from "../../ConfirmDialog";
import useDeleteTrustAdmin from "./useDeleteTrustAdmin";
import useTrustAdmins, { TrustAdmin } from "./useTrustAdminList";
import NavContainer from "../NavContainer";
import TrustAdminsTable from "./TrustAdminsTable";

export default function TrustAdmins() {
  const history = useHistory();
  const [feedback, setFeedback] = React.useState<{
    type: "success" | "error";
    message: string;
  }>(null);
  const [admins, setAdmins] = React.useState<TrustAdmin[]>([]);
  const [totalCount, setTotalCount] = React.useState<number>(0);
  const [adminData, loading, error, setSearchText, setPageNumber, setPageSize] =
    useTrustAdmins();
  React.useEffect(() => {
    if (adminData) {
      setAdmins(adminData.results);
      setTotalCount(adminData.totalCount);
    }
  }, [adminData]);
  React.useEffect(() => {
    if (error) {
      setFeedback({
        type: "error",
        message: "There was a problem loading the Trust Admins.",
      });
    }
  }, [error]);
  const [showConfirmDialog, setShowDialog] = React.useState(false);
  const [targetId, setTargetId] = React.useState<number | null>(null);
  const [deleteTrust, deleteSuccess, deleteError] = useDeleteTrustAdmin();
  React.useEffect(() => {
    if (deleteSuccess) {
      setFeedback({
        type: "success",
        message: "Trust Admin deleted.",
      });
      setAdmins((v) => {
        const update = [...v];
        const index = update.findIndex((t) => t.id === deleteSuccess);
        update.splice(index, 1);
        return update;
      });
    }
  }, [deleteSuccess, setFeedback]);
  React.useEffect(() => {
    if (deleteError) {
      setFeedback({
        type: "error",
        message: "Could not delete Trust Admin. Please retry.",
      });
    }
  }, [deleteError, setFeedback]);
  return (
    <>
      {loading && <LinearProgress color="primary" />}
      <NavContainer>
        <Grid container direction="row" justifyContent="space-between">
          <h1>Manage Trust Admins</h1>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              history.push("/users/trust-admins/new");
            }}
          >
            Add Trust Admin
          </Button>
        </Grid>
        <TrustAdminsTable
          totalCount={totalCount}
          data={admins}
          deleteAdmin={(id: number) => {
            setShowDialog(true);
            setTargetId(id);
          }}
          setSearchText={setSearchText}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
        />
        <ConfirmDialog
          open={showConfirmDialog}
          title="Delete Trust Admin?"
          confirmLabel="Delete"
          onConfirm={() => {
            setShowDialog(false);
            deleteTrust(targetId);
          }}
          onClose={() => {
            setShowDialog(false);
            setTargetId(null);
          }}
        >
          <span>
            Are you sure you want to delete{" "}
            {() => {
              const admin = admins?.find((a) => a.id === targetId);
              return admin ? `${admin.firstName} ${admin.lastName}` : "";
            }}{" "}
            ?
          </span>
        </ConfirmDialog>
        {feedback && (
          <ToastMessage
            variant={feedback.type}
            message={feedback.message}
            clear={() => {
              setFeedback(null);
            }}
          />
        )}
      </NavContainer>
    </>
  );
}
