import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import DataClient from "../../../../../../lib/services/api/DataClient";

export interface Group {
  id: number;
  name: string;
  isChecked: boolean;
  children: Role[];
}

export interface Role {
  id: number;
  jobRoleId: number;
  jobRoleName: string;
  staffGroupId: number;
  staffGroupName: string;
  trustId: number;
  campaignId: number;
  default: boolean;
  override?: boolean;
  current: boolean;
  originalOverride?: boolean;
}

const frontlineToRole = (model: any) => ({
  ...model,
  current: model.isFrontLineHealthcareWorker,
  default: model.isFlhwByDefault,
  override: model.isFrontLineOverride,
});

const frontlineFromRole = (role: Role) => ({
  ...role,
  isFrontLineOverride: role.override,
  isFrontLineHealthcareWorker: role.current,
  isFlhwByDefault: role.default,
});

const immFormToRole = (model: any) => ({
  ...model,
  current: model.isImmForm,
  default: model.isImmFormByDefault,
  override: model.isImmFormOverride,
});

const immFormFromRole = (role: Role) => ({
  ...role,
  isImmFormOverride: role.override,
  isImmForm: role.current,
  isImmFormByDefault: role.default,
});

/**
 *
 * @param baseUrl One of the endpoints for StaffGroups
 * @param setFeedback Standard set state function for the Toast messages
 * @returns { groups, loading, hasChanges, handleGroupChange, handleRoleChange, handleSaveChanges }
 */

export default function useStaffGroups(
  baseUrl: "/CampaignImmFormStaffGroup" | "/CampaignFrontLineStaffGroup",
  setFeedback: (value: { type: string; message: string }) => void
) {
  const { campaignId } = useParams<{ campaignId: string }>();
  const toRole =
    baseUrl === "/CampaignImmFormStaffGroup" ? immFormToRole : frontlineToRole;
  const api = useMemo(
    () => ({
      list: (campaignId: number) =>
        Promise.all([
          DataClient.postData("/StaffGroup/Search", { pageSize: -1 }),
          DataClient.postData(`${baseUrl}/Inherited`, {
            pageSize: -1,
            campaignId,
          }),
        ]).then(([resGroup, resRoles]: [any, { results: any[] }]) =>
          resGroup.results
            .map((g: any) => {
              const children = resRoles.results
                .filter((r) => r.staffGroupId === g.id)
                .map(toRole)
                .map((r) => ({
                  ...r,
                  originalOverride: r.override,
                }));
              return {
                id: g.id,
                name: g.name,
                children,
                isChecked: children.some((x) => x.current),
              };
            })
            .filter((g: any) => g.children.length)
            .reduce((cg: { [id: number]: Group }, g: Group) => {
              cg[g.id] = g;
              return cg;
            }, {} as { [id: number]: Group })
        ),
    }),
    [baseUrl, toRole]
  );

  const [groups, setGroups] = useState<{ [id: number]: Group }>(null);

  const fetchData = React.useCallback(() => {
    api
      .list(parseInt(campaignId))
      .then((groups) => {
        setGroups(groups);
      })
      .catch((e) => {
        if (!e.handled) {
          setFeedback({
            type: "error",
            message: "Error fetching staff list",
          });
        }
      });
  }, [api, campaignId, setFeedback]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const changes: Role[] = useMemo(
    () =>
      groups
        ? Object.values(groups)
            .reduce((gc, g) => gc.concat(g.children), [] as Role[])
            .filter((r) => r.originalOverride !== r.override)
            .map((r) => r as Role)
        : [],
    [groups]
  );

  const hasChanges = !!changes.length;

  /**
   * Sets the value on a single item in a group
   *
   * @param {Role} role
   * @param {boolean} isChecked
   */
  const handleRoleChange = (role: Role, isChecked: boolean) => {
    const newGroup = {
      ...groups[role.staffGroupId],
      children: groups[role.staffGroupId].children.map((r) =>
        r.jobRoleId === role.jobRoleId
          ? {
              ...r,
              override: isChecked,
              current: isChecked,
            }
          : r
      ),
    };

    setGroups({
      ...groups,
      [newGroup.id]: {
        ...newGroup,
        isChecked: newGroup.children.some((x) => x.current),
      },
    });
  };

  /**
   * Sets all the items in a group to the same value.
   * nb: not used for Non ESR roles as they are not in groups
   *
   * @param {Group} group
   * @param {boolean} isChecked
   */
  const handleGroupChange = (group: Group, isChecked: boolean) => {
    setGroups({
      ...groups,
      [group.id]: {
        ...group,
        isChecked,
        children: group.children.map((r) => ({
          ...r,
          override: isChecked,
          current: isChecked,
        })),
      },
    });
  };

  return {
    groups,
    hasChanges,
    handleGroupChange,
    handleRoleChange,
    fetchData,
  };
}
