import { Button } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { merge } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import startCase from "lodash/startCase";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { Link } from "react-router-dom";
import { humanDate, humanTime } from "../../../../lib/common_utils";
import {
  clinicTypeElement,
  enumClinicTypeId,
  enumClinicTypeIdLabels,
} from "../../../../lib/enums";
import {
  overridesGenericCells,
  overridesTableGenericColors,
} from "../../../../lib/themes/overrides";
import { palette } from "../../../../lib/themes/vars";
import { clinicAllowsBookings } from "./View";
import { Flag } from "../../../../components/FlagIcons";
// Take a data parameter with collection
// data = [
//   {
//     clinicLocationName: "Foo",
//     hospitalSiteName: "Bar"
//   }, ...
// ]
const theme = createTheme({
  palette,
  overrides: merge({}, overridesGenericCells, overridesTableGenericColors),
});
export default ({ data, page }) => {
  if (!data?.length) return <p>No Clinics</p>;
  // Using all given keys is too naive unfortunately!
  // const columns = Object.keys(data[0]);
  // const data = data.map((e) => Object.values(e));
  // Choose keys to be displayed in columns
  const columnKeys = [
    "actions",
    "date",
    "type", // "Clinic Type" (search helper)
    "times",
    "_breakTimes",
    "site",
    "clinicLocation",
    "bookingModel",
    "slot", // "To get more room we can shorten Slot Duration to just 'Slot'"
    "vaccinators", // (numberOfSpaces)
    "slots",
    "availableSlots",
    "bookedSlots",
    "vaccinated",
    "notVaccinated",
    "clinicCompleted",
  ];
  // dd-2057 madness https://2-app.donedone.com/6110/project/17407/task/682454
  const columnKeysSlang = {
    slots: "Total Slots",
    vaccinators: "Vacc's",
    vaccinated: "Vacc'd",
    notVaccinated: "Not Vacc'd",
    clinicCompleted: "Admin Up-to-date",
    actions: " ",
  };
  // Special cases (snagging list, with quotes)!
  let tableData = cloneDeep(data).map((datum) => {
    const { pathogens, requireBookingAllPathogens } = datum;
    // Render Booking Model as string based on enum
    if (datum.clinicTypeId) {
      datum.bookingModel =
        enumClinicTypeIdLabels[enumClinicTypeId[datum.clinicTypeId]];
    }
    datum.type = clinicTypeElement({
      pathogens,
      requireBookingAllPathogens,
    });
    // "Hospital Site Name > Site"
    datum.site = datum.hospitalSiteName;
    // "Clinic Location Name > Location name"
    // "Location Name > Clinic Location" (kill me)
    datum.clinicLocation = datum.clinicLocationName;
    // "Date (rather than start and end date)"
    datum.date = humanDate(datum.start);
    // Add ' mins' to Slot Duration
    datum.slot = datum.slotDuration ? `${datum.slotDuration} mins` : "";
    // "Number of vaccinators column"
    // Might be an idea to shorten 'Number of Vaccinators' to just 'Vaccinators' as space will be at a premium
    datum.vaccinators = datum.numberOfSpaces;
    // "Break times column"
    datum._breakTimes = Array.isArray(datum.breakTimes)
      ? datum.breakTimes.map(({ start, end }) => (
          <div>
            {humanTime(start)} - {humanTime(end)}
          </div>
        ))
      : "";
    // "Concatenate start and end time and rename ‘times’"
    datum.times = `${
      datum.dateTimeFrom
        ? moment(datum.dateTimeFrom, "YYYY-MM-DD HH:mm").format("HH:mm")
        : ""
    } - ${
      datum.dateTimeTo
        ? moment(datum.dateTimeTo, "YYYY-MM-DD HH:mm").format("HH:mm")
        : ""
    }`;
    //
    // Slots: 300 (1 booked, 299 available)
    // datum.slots = (
    //   <>
    //     {datum.totalSlot || 0}
    //     {datum.bookedSlot && datum.availableSlot ? (
    //       <>
    //         <br /> {datum.bookedSlot || 0} booked
    //         <br /> {datum.availableSlot || 0} available
    //       </>
    //     ) : null}
    //   </>
    // );
    // " Slots split out into separate columns Unfortunately as clients find it very useful to quickly scan down a column
    // UPDATE: They totally did want this ^ I'm a regular mystic meg over here (dd-2033)
    // UPDATE: #3661 Similar N/A blocking in both View an PlannerList
    datum.slots = clinicAllowsBookings(datum.clinicTypeId)
      ? `${datum.totalSlot ?? ""} (${datum.totalDropInSlot})`
      : "N/A";
    datum.availableSlots = clinicAllowsBookings(datum.clinicTypeId)
      ? `${datum.availableSlot ?? ""} (${datum.availableDropInSlot})`
      : "N/A";
    datum.bookedSlots = clinicAllowsBookings(datum.clinicTypeId)
      ? `${datum.bookedSlot ?? ""} (${datum.bookedDropInSlot})`
      : "N/A";
    datum.vaccinated = datum.pathogens?.map((p, i) => (
      <>
        {i > 0 ? "/" : null}
        <span className="theme-color" data-theme-id={p.id}>
          {datum[`${p.name.toLowerCase()}Vaccinated`] ?? ""}
        </span>
      </>
    ));
    datum.notVaccinated = datum.pathogens?.map((p, i) => (
      <>
        {i > 0 ? "/" : null}
        <span className="theme-color" data-theme-id={p.id}>
          {datum[`${p.name.toLowerCase()}NotVaccinated`] ?? ""}
        </span>
      </>
    ));
    datum.clinicCompleted = datum.requiresAdmin ? (
      <Flag type="flagClinicRequiresAdmin"></Flag>
    ) : (
      <Flag type="flagClinicCompleted"></Flag>
    );
    // Actions
    datum.actions = (
      <Button
        component={Link}
        to={`/clinics/${datum.id}/view`}
        color="secondary"
        variant="contained"
        size="small"
        target="_blank"
        rel="noopener noreferrer"
        style={{ minWidth: "0", padding: ".4em" }}
      >
        View
      </Button>
    );
    console.log(`datum: `, datum);
    return datum;
  });
  // Column header text formatted from their keys
  /* console.log(columnKeys);
  const columns = columnKeys.map((key) => ({
    name: key,
    label: startCase(key),
  })); */
  const columns = columnKeys.map((k) => columnKeysSlang[k] || startCase(k));
  // Use keys to manipulate the data for MUIDataTable
  const tableDataToRender = tableData.map((d) => [
    ...columnKeys.map((c) => {
      const value = d[c];
      // console.log({ c, value });
      // More special cases! (generic input type things)
      // Date format
      if (moment.isMoment(value)) {
        return value.format("DD/MM/YYYY");
      }
      // Boolean to 'Yes'/'No'
      if (typeof value === "boolean") {
        return value ? "Yes" : "No";
      }
      // Default return value for matching key in datum
      return value ?? "";
    }),
  ]);
  // console.log("::", { data, columns, tableData });
  return (
    <ThemeProvider theme={theme}>
      <MUIDataTable
        className="table-planner table-horizontal-squeeze table-fonts"
        data={tableDataToRender}
        columns={columns}
        options={{
          page,
          download: false,
          filter: false,
          print: false,
          search: false,
          selectableRowsHideCheckboxes: true,
          sort: true,
          sortOrder: {
            name: "date",
            direction: "asc",
          },
          viewColumns: false,
          rowsPerPage: 100,
          rowsPerPageOptions: [25, 50, 100, 250],
          setRowProps: (row, i) => {
            // [dd#2057] Colouring rows based on date or pausedness
            const rowData = tableData[i];
            return rowData.date &&
              // Paused
              rowData.isBookingSuspended
              ? {
                  // style: {
                  //   background: "red",
                  //   // background: palette.present.main,
                  //   // color: palette.present.contrastText,
                  // },
                  className: "planner-list-suspended",
                }
              : // Present day:
              moment(rowData.date, "DD/MM/YYYY")
                  .startOf("day")
                  .isSame(moment().startOf("day"))
              ? {
                  style: {
                    background: palette.present.main,
                    color: palette.present.contrastText,
                  },
                }
              : // Past:
              moment(rowData.date, "DD/MM/YYYY").isBefore(moment())
              ? {
                  style: {
                    background: palette.inactive.main,
                    color: palette.inactive.contrastText,
                  },
                }
              : {};
          },
        }}
      />
    </ThemeProvider>
  );
};
