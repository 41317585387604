import { TextField } from "@material-ui/core";
import { FunctionComponent } from "react";
import { useForm, UseFormRegisterReturn } from "react-hook-form";
import { FormProps } from "../FormDialog";
import { Hospital } from "./hospital";

export const Form: FunctionComponent<FormProps<Hospital>> = ({
  id,
  defaultValue,
  onSubmit,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ defaultValues: defaultValue });

  const renameRef = ({ ref, ...reg }: UseFormRegisterReturn) => ({
    inputRef: ref,
    ...reg,
  });

  const inputProps = {
    autoComplete: "new-password",
    InputLabelProps: {
      shrink: true,
    },
    fullWidth: true,
  };

  return (
    <form id={id} autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <TextField
        label="Name"
        {...renameRef(register("name", { required: "Name required" }))}
        error={!!errors.name}
        helperText={errors.name?.message}
        {...inputProps}
      />
    </form>
  );
};

export default Form;
