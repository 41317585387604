import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import {
  Archive,
  Delete,
  CheckCircleOutline,
  Restore,
  HighlightOff,
} from "@material-ui/icons";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  largeButton: {
    height: "48px",
    width: "48px",
  },
  wrapper: {
    marginRight: "1em",
  },
  errorView: {
    marginRight: "auto",
    marginLeft: "3em",
  },
}));

export function TableToolbarSelect({
  selectedRows,
  items,
  onRowsArchive,
  onRowsRestore,
  onRowsHardDelete,
  onRowsApprove,
  onRowsReject,
  onRowsHardDeleteSR,
}: any) {
  const classes = useStyles();
  const allUsers = selectedRows.data.map((x: any) => items?.[x.index]);

  const archivedUsers = allUsers.filter((row: any) => row.flagArchived);

  const unarchivedUsers = allUsers.filter(
    (row: any) => row.flagArchived === false
  );

  const unapprovedUsers = allUsers.filter((row: any) => row.approved === false);

  type viewType =
    | "errorView"
    | "archivedUsersView"
    | "unarchivedUsersView"
    | "unapprovedUsersView";

  function getView(): viewType {
    // Show approve button if all are unapproved (regardless of archived state)
    if (unapprovedUsers.length == allUsers.length) {
      return "unapprovedUsersView";
    }
    // Show delete button if all are archived (regardless of unapproved state)
    if (archivedUsers.length == allUsers.length) {
      return "archivedUsersView";
    }
    // Show archive button if all are unarchived and none are unapproved
    if (unarchivedUsers.length == allUsers.length && !unapprovedUsers.length) {
      return "unarchivedUsersView";
    }
    // Show error if none of these conditions are met
    return "errorView";
  }

  const view: viewType = getView();

  return (
    <div
      className={clsx(classes.wrapper, {
        [classes.errorView]: view == "errorView",
      })}
    >
      {/* <span>Archive Staff Record</span> */}
      {view == "errorView" && (
        <>
          <h4>
            Your selection includes unapproved self registered staff. These
            should he archived separately to standard staff members.
          </h4>
          <h4>
            You may find it easier to use the ‘Self Registered (Unapproved)’
            filter in the ‘Flags’ drop-down in ‘Filters’
          </h4>
        </>
      )}
      {view == "unarchivedUsersView" && (
        <Tooltip title={`Archive ${unarchivedUsers.length} Staff Records`}>
          <IconButton
            className={classes.largeButton}
            onClick={() => {
              onRowsArchive(unarchivedUsers);
            }}
          >
            <Archive />
          </IconButton>
        </Tooltip>
      )}
      {view === "archivedUsersView" && (
        <>
          <Tooltip title={`Restore ${archivedUsers.length} Staff Records`}>
            <IconButton
              className={classes.largeButton}
              onClick={() => {
                onRowsRestore(archivedUsers);
              }}
            >
              <Restore style={{ color: "#57a24f" }} />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={`Permanently Delete ${archivedUsers.length} Staff Records`}
          >
            <IconButton
              className={classes.largeButton}
              onClick={() => {
                onRowsHardDelete(archivedUsers);
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </>
      )}
      {view === "unapprovedUsersView" && (
        <>
          <Tooltip title={`Approve ${unapprovedUsers.length} Staff Records`}>
            <IconButton
              className={classes.largeButton}
              onClick={() => {
                onRowsApprove(unapprovedUsers);
              }}
            >
              <CheckCircleOutline style={{ color: "#57a24f" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title={`Reject ${unapprovedUsers.length} Staff Records`}>
            <IconButton
              className={classes.largeButton}
              onClick={() => {
                onRowsReject(unapprovedUsers);
              }}
            >
              <HighlightOff style={{ color: "#e30613" }} />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={`Permanently Delete ${unapprovedUsers.length} Staff Records`}
          >
            <IconButton
              className={classes.largeButton}
              onClick={() => {
                onRowsHardDeleteSR(unapprovedUsers);
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </>
      )}
    </div>
  );
}
