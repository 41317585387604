import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {
  ArrowBack,
  CloudDownload,
  CloudDownload as CloudDownloadIcon,
  CloudUpload as CloudUploadIcon,
  Error as ErrorIcon,
  CheckCircle as CheckCircleIcon,
} from "@material-ui/icons";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { alertNoActiveCampaign } from "../../../../../components/AlertMessaging";
import DataClient from "../../../../../lib/services/api/DataClient";
import { getTrustActiveCampaign } from "../../../../../lib/services/TrustSwitcher";

function UploadForm({ onComplete, onPoll }) {
  const [loading, setLoading] = useState(null);
  const hiddenFileInput = useRef(null);
  let [resultsSummary, setResultsSummary] = useState({
    results: [],
    errors: [],
  });

  const handleFileInput = (event) => {
    const { target } = event;
    // cancelled
    if (target.value.length === 0) {
      target.value = "";
      target.reset();
      return;
    }

    let importing = true;
    setLoading(true);

    const formData = new FormData();
    formData.append("file", target.files[0]);

    DataClient.postData("/trustworker/import", formData)
      .then((results) => {
        setResultsSummary({
          results: mapResultsSummary(results),
          errors: results.errors,
        });
        onComplete();
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        target.value = "";
        setLoading(false);
        importing = false;
      });

    setTimeout(function poll() {
      onPoll();
      // TODO: should await reload
      if (importing) {
        setTimeout(poll, 3000);
      }
    }, 200);
  };

  const handleTemplateDownload = () => {
    DataClient.getFile({
      url: "/EsrUpload/DownloadTemplate",
      filename: "VaccinationTrack Staff Upload Template.csv",
    }).catch((e) => console.error(e));
  };

  const emptyResult = (result) =>
    Object.values(result).reduce((v, c) => (c += v)) > 0;

  return (
    <>
      <input
        type="file"
        accept=".csv"
        required
        ref={hiddenFileInput}
        style={{ display: "none" }}
        onInput={handleFileInput}
      />
      <Button
        type="submit"
        color="secondary"
        variant="contained"
        disabled={loading}
        onClick={() => hiddenFileInput.current.click()}
        startIcon={
          loading ? (
            <CircularProgress size={16} color="primary" />
          ) : (
            <CloudUploadIcon />
          )
        }
      >
        Upload
      </Button>
      <Button
        onClick={() => handleTemplateDownload()}
        disabled={loading}
        color="primary"
        variant="contained"
        startIcon={<CloudDownload />}
        // Lazy styling because I know Oliver will change his mind again
        style={{ float: "right" }}
      >
        Download Staff List Template
      </Button>
    </>
  );
}

function UploadList({ items }) {
  const history = useHistory();

  const handleDownload = (id, date) => {
    DataClient.getFile({
      url: `/EsrUpload/${id}/GetFile`,
      filename: `Staff List ${moment(date).format(
        "MMMM Do YYYY, H:mm:ss"
      )}.csv`,
    }).catch((e) => console.error(e));
  };
  const hasError = (row) => row.status === "Errors";
  const isPending = (row) =>
    row.status.includes("Saving") || row.status.includes("Processing");

  return (
    <Table className="table esr-uploads-table">
      <TableHead>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell>Time</TableCell>
          <TableCell>Uploaded By</TableCell>
          <TableCell>Import Status</TableCell>
          <TableCell>Details</TableCell>
          <TableCell>File</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items
          .map((item) => ({
            ...item,
            date: moment(item.dateTimeUploaded),
          }))
          .map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.date.format("DD/MM/yyyy")}</TableCell>
              <TableCell>{row.date.format("HH:mm")}</TableCell>
              <TableCell>{row.createdBy}</TableCell>
              <TableCell
                style={
                  isPending(row) ? { color: "#009fe3", fontWeight: 700 } : {}
                }
              >
                {row.status}
              </TableCell>
              <TableCell>
                {!isPending(row) && (
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={
                      hasError(row) ? <ErrorIcon /> : <CheckCircleIcon />
                    }
                    onClick={() => history.push(`/ESR/${row.id}`)}
                    style={
                      hasError(row)
                        ? { background: "#e30613" } // $brand-danger
                        : { background: "#57a24f" } // $brand-walkaround
                    }
                  >
                    View
                  </Button>
                )}
              </TableCell>
              <TableCell>
                {!isPending(row) && (
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<CloudDownloadIcon />}
                    onClick={() => handleDownload(row.id, row.dateTimeUploaded)}
                  >
                    Download
                  </Button>
                )}
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}

function List() {
  const history = useHistory();
  const [data, setData] = useState(null);
  const [alert, setAlert] = useState(null);
  const [fail, setFail] = useState(false);

  // const [page, setPage] = React.useState(0);
  // const [rows, setRows] = React.useState([]);
  // const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    (async () => {
      if (!(await getTrustActiveCampaign())) {
        setFail(true);
        setAlert(alertNoActiveCampaign());
      } else {
        fetchData();
      }
    })();
  }, []);

  const fetchData = useCallback(() => {
    DataClient.postData("/EsrUpload/Search", { pageSize: -1 })
      .then((response) => {
        setData(
          response.results.map((r) => ({
            ...r,
            status:
              r.uploadStatusName === "Pending"
                ? !!r.totalRecords && r.totalAddedRecords === r.totalRecords
                  ? "Saving..."
                  : ("Processing" +
                      (r.totalRecords
                        ? " (" + r.totalAddedRecords + "/" + r.totalRecords +")..."
                        : "")
                    )
                : r.uploadStatusName === "PartialSuccess"
                  ? "Errors"
                  : r.uploadStatusName,
          }))
        );
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <main className="wrapper-main">
      <h1>Upload Staff List</h1>
      {alert}
      {!fail && (
        <>
          <p>
            <Button
              onClick={() => history.push("/staff")}
              color="primary"
              startIcon={<ArrowBack />}
            >
              Back to Staff
            </Button>
          </p>
          <p>Please upload your staff list in CSV format</p>
          <p>
            <UploadForm onComplete={fetchData} onPoll={fetchData} />
          </p>
          <h2>Recent Uploads</h2>
          {data ? <UploadList items={data} /> : <CircularProgress />}
        </>
      )}
    </main>
  );
}

// I duplicated this in Detail.jsx - whats the best way to reuse it?
export const mapResultsSummary = (results) => {
  return [
    {
      label: "staff records were not uploaded due to errors",
      prefixValue: results.totalErroredRecords || 0,
    },
    {
      label: "new staff records were added",
      prefixValue: results.totalAddedRecords || 0,
    },
    {
      label:
        "staff records became inactive as they are no longer on the staff upload",
      prefixValue: results.totalRecordsBecameInactiveAsNotInImport || 0,
    },
    {
      label:
        "staff records became active as they have been added back onto the staff upload having previously been removed",
      prefixValue: results.totalRecordsBecameActiveAsBackInImport || 0,
    },
    {
      label:
        "inactive staff records have been added back onto the staff upload having previously been removed",
      prefixValue: results.totalRecordsBecameInactiveAsBackInImport || 0,
    },
    {
      label:
        "staff records became active as their assignment status changed to an active one",
      prefixValue: results.totalRecordsInactiveToActive || 0,
    },
    {
      label:
        "staff records became inactive as their assignment status changed to an inactive one",
      prefixValue: results.totalRecordsActiveToInactive || 0,
    },
    {
      label: "existing staff records were amended",
      prefixValue: results.totalUpdatedRecords || 0,
    },
    {
      label:
        "staff records with an inactive assignment status are no longer on the staff upload",
      prefixValue:
        results.totalRecordsBecameInactiveAsNotInImportPreviouslyInactive || 0,
    },
    {
      label:
        "previously archived staff records were restored",
      prefixValue: results.totalArchivedUsersRestored || 0,
    },
    {
      label: "self-registered staff records were approved",
      prefixValue: results.totalSelfRegUsersActivated || 0,
    },
  ];
};

export default List;
