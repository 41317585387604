import styles from "./appointments.module.scss";
import { Pathogens } from "../../../../../lib/pathogens";
import Invitation from "./Invitation";
import Unavailable from "./Unavailable";
import Booking from "./Booking";
import { MyAppointment } from "../MyAppointments";
import { CampaignPathogenStatus } from "../../BookAppointment/useAppointments";
type AppointmentProps = {
  appointment: MyAppointment;
  campaignPathogenStatuses: CampaignPathogenStatus[]; // same for all instances
  reload: (...args: any[]) => void;
  isCombinedBookingPossible?: boolean;
};
const titleMap = {
  [JSON.stringify([1])]: "Flu",
  [JSON.stringify([2])]: "Covid Booster",
  [JSON.stringify([1, 2])]: "Flu & Covid Booster Combined",
};
const classMap = {
  [JSON.stringify([1])]: "fluAppointment",
  [JSON.stringify([2])]: "covidAppointment",
  [JSON.stringify([1, 2])]: "combinedAppointment",
};
const getMappedData = (pathogens: Pathogens[], map: any) => {
  return map[JSON.stringify(pathogens)];
};
export default function Appointment({
  appointment,
  campaignPathogenStatuses,
  reload,
}: AppointmentProps) {
  return (
    <div
      className={`${styles.appointment} ${
        !appointment.canBook &&
        !appointment.hasBooking &&
        appointment.pathogens.length === 2
          ? styles.invalidAppointment
          : styles[getMappedData(appointment.pathogens, classMap)]
      }`}
    >
      <div className={styles.title}>
        <h2>{getMappedData(appointment.pathogens, titleMap)}</h2>
      </div>
      <div className={styles.body}>
        {appointment.canBook && <Invitation appointment={appointment} />}
        {!appointment.canBook && appointment.hasBooking && (
          <Booking
            appointment={appointment}
            campaignPathogenStatuses={campaignPathogenStatuses}
            reload={reload}
          />
        )}
        {!appointment.canBook && !appointment.hasBooking && (
          <Unavailable appointment={appointment} />
        )}
      </div>
    </div>
  );
}
