import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ImmFormReport } from "./ImmForm/ImmFormReport";

const ImmFormTab = (props: any) => {
  const useStyles = makeStyles((theme) => ({
    wrapper: {
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      marginBottom: "20px",
      alignItems: "stretch",
    },

    subtitle1: {
      fontWeight: 800,
      fontSize: theme.spacing(2),
    },
    p: {
      textAlign: "center",
    },
    onlyFLHCWButton: {
      float: "right",
      marginTop: 20,
    },
    topGridRow: {
      height: 30,
    },
  }));
  const classes = useStyles();

  return (
    <>
      <Grid
        xs={12}
        className={classes.topGridRow}
        justifyContent="flex-end"
      ></Grid>
      <h2>ImmForm Survey Report (England Only)</h2>
      <ImmFormReport
        pathogenIds={props.pathogenIds}
        trustId={props.trustId}
        excludeL1Ids={props.excludeL1Ids}
      ></ImmFormReport>
    </>
  );
};

export default ImmFormTab;
