import {
  Box,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Tooltip,
} from "@material-ui/core";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  ToggleOff,
  ToggleOn,
} from "@material-ui/icons";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import Help from "../../../../../components/Help/Help";
import ToastMessage from "../../../../../components/Universal/toast_message";
import ConfirmDialog from "../../../ConfirmDialog";
import { ClinicListDialog } from "../Clinics/List";
import FormDialog from "../FormDialog";
import SettingsContainer, { Feedback } from "../SettingsContainer";
import api from "./api";
import Form from "./Form";
import { Hospital } from "./hospital";

const HospitalList: FunctionComponent = () => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<Hospital[]>(null);
  const [action, setAction] = useState<
    "delete" | "create" | "edit" | "clinics" | "archive" | "unarchive"
  >(null);
  const [activeItem, setActiveItem] = useState<Hospital>(null);
  const [feedback, setFeedback] = useState<Feedback>(null);

  const fetchData = useCallback(() => {
    setLoading(true);
    api
      .list()
      .then((data) => setItems(data))
      .catch((e) => {
        if (!e.handled) {
          setFeedback({
            type: "error",
            message: "Error fetching hospital list",
          });
        }
      })
      .finally(() => setLoading(false));
  }, [setFeedback]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleCancel = () => {
    setAction(null);
  };

  const handleDelete = () => {
    api
      .deleteHard(activeItem.id)
      .then(() => {
        setFeedback({ type: "success", message: "Site permanently deleted" });
      })
      .catch(() =>
        setFeedback({ type: "error", message: "Failed removing site" })
      )
      .finally(() => {
        fetchData();
        setAction(null);
      });
  };

  const handleCreated = () => {
    setAction(null);
    setActiveItem(null);
    fetchData();
    setFeedback({
      type: "success",
      message: "Site created successfully!",
    });
  };

  const handleUpdated = () => {
    setAction(null);
    setActiveItem(null);
    fetchData();
    setFeedback({
      type: "success",
      message: "Site updated successfully!",
    });
  };

  const handleArchive = () => {
    api
      .archive(activeItem.id)
      .then(() => {
        setFeedback({ type: "success", message: "Site archived" });
      })
      .catch(() =>
        setFeedback({ type: "error", message: "Failed archiving site" })
      )
      .finally(() => {
        fetchData();
        setAction(null);
      });
  };

  const handleUnarchive = () => {
    api
      .unarchive(activeItem.id)
      .then(() => {
        setFeedback({ type: "success", message: "Site unarchived" });
      })
      .catch(() =>
        setFeedback({ type: "error", message: "Failed unarchiving site" })
      )
      .finally(() => {
        fetchData();
        setAction(null);
      });
  };

  const hasOneArchivedItem = (): boolean => {
    return items.some((item) => item.isArchived);
  };

  return (
    <>
      {loading && <LinearProgress color="primary" />}

      <SettingsContainer>
        <Grid container spacing={2} justifyContent="space-between">
          <h1>Sites & Clinic Locations</h1>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                setActiveItem(null);
                setAction("create");
              }}
            >
              Add Site
            </Button>
          </Grid>
        </Grid>
        <Help helpCopyKey="5.1" />
        <Paper>
          {items && items.length > 0 && (
            <List>
              {items.map((item) => (
                <ListItem key={item.id}>
                  <ListItemText primary={item.name} />
                  <ListItemSecondaryAction>
                    <Tooltip title="Edit">
                      <IconButton
                        color="primary"
                        onClick={() => {
                          setActiveItem(item);
                          setAction("edit");
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        setActiveItem(item);
                        setAction("clinics");
                      }}
                    >
                      Manage Clinics
                    </Button>
                    {item.isArchived ? (
                      <Tooltip title="Unarchive">
                        <IconButton
                          // color="secondary"
                          style={{ color: "red" }}
                          onClick={() => {
                            setActiveItem(item);
                            setAction("unarchive");
                          }}
                        >
                          <ToggleOn />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Archive">
                        <IconButton
                          color="secondary"
                          onClick={() => {
                            setActiveItem(item);
                            setAction("archive");
                          }}
                        >
                          <ToggleOff />
                        </IconButton>
                      </Tooltip>
                    )}
                    {item.isArchived && (
                      <Tooltip title="Delete">
                        <IconButton
                          color="secondary"
                          onClick={() => {
                            setActiveItem(item);
                            setAction("delete");
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    {!item.isArchived && hasOneArchivedItem() && (
                      <button
                        style={{ width: "50px", visibility: "hidden" }}
                      ></button>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          )}
          {items && items.length === 0 && (
            <Box p={1}>
              <p>No hospitals</p>
            </Box>
          )}
        </Paper>

        <FormDialog
          open={action === "create"}
          onClose={handleCancel}
          onError={() =>
            setFeedback({ type: "error", message: "Failed saving site" })
          }
          title="Add Site"
          id="formEdit"
          value={activeItem}
          render={(props) => <Form {...props} />}
          onSuccess={handleCreated}
          saveButtonText="Create"
          save={api.add}
        />

        <FormDialog
          open={action === "edit"}
          onClose={handleCancel}
          onError={() =>
            setFeedback({ type: "error", message: "Failed saving site" })
          }
          title="Edit Hospital"
          id="formCreate"
          value={activeItem}
          render={(props) => <Form {...props} />}
          onSuccess={handleUpdated}
          saveButtonText="Update"
          save={api.update}
        />

        <ConfirmDialog
          title="Are you sure you want to permanently delete this site?"
          open={action === "delete"}
          onClose={handleCancel}
          onConfirm={handleDelete}
        />
        <ConfirmDialog
          title="Are you sure you want to archive this site?"
          children={
            <p>
              The site will no longer appear in the ‘Site’ drop-down menu when
              creating or editing a clinic. However, any reference to the site
              will not be removed from any bookings or previously recorded
              vaccinations.
            </p>
          }
          open={action === "archive"}
          onClose={handleCancel}
          onConfirm={handleArchive}
        />
        <ConfirmDialog
          title="Are you sure you want to unarchive this site?"
          children={
            <p>
              The site will now appear in the ‘Site’ drop-down menu when
              creating or editing a clinic.
            </p>
          }
          open={action === "unarchive"}
          onClose={handleCancel}
          onConfirm={handleUnarchive}
        />

        <ClinicListDialog
          hospital={activeItem}
          open={action === "clinics"}
          onClose={handleCancel}
          onConfirm={handleCancel}
        />

        {feedback && (
          <ToastMessage
            variant={feedback.type}
            message={feedback.message}
            clear={() => setFeedback(null)}
          />
        )}
      </SettingsContainer>
    </>
  );
};

export default HospitalList;
