import React from "react";
import DataClient from "../../../../lib/services/api/DataClient";
import useDataClient from "../useDataClient";
import { Pathogens } from "../../../../lib/pathogens";

type BaseTrust = {
  id: number;
  trustName: string;
  senderEmailAddress?: string;
  logoUrl: string;
  showInLeagueTable: boolean;
};

export type Trust = BaseTrust & {
  pathogens?: Pathogens[];
  bookingData?: {
    pathogens: Pathogens[];
    fluOnly: number;
    covidOnly: number;
    combined: number;
  };
};

export type TrustData = BaseTrust & {
  pathogens: { id: number; name: string }[];
  bookingsFluOnly?: number;
  bookingsCovidOnly?: number;
  bookingsCombined?: number;
};

type TrustResponse = {
  success: boolean;
  results: TrustData[];
};

const isTrustResponse = (res: unknown): res is TrustResponse => {
  return res["success"] && res["results"] && Array.isArray(res["results"]);
};

export default function useTrustList(shouldCall = true) {
  const getDataArgs = React.useMemo(
    () => (shouldCall ? ["/Trust/GetWithBookingCounts"] : []),
    [shouldCall]
  );
  const hookOptions = React.useMemo(
    () => ({
      formatter: (data: TrustResponse) => {
        if (!data.success) {
          throw new Error("API call was a failure");
        }
        return data.results.map(
          ({ pathogens, ...rest }: TrustData): Trust => ({
            ...rest,
            pathogens: pathogens.map(
              (p: { id: number; name: string }) => Pathogens[p.name]
            ),
            bookingData: {
              pathogens: pathogens.map(
                (p: { id: number; name: string }) => Pathogens[p.name]
              ),
              fluOnly: rest.bookingsFluOnly,
              covidOnly: rest.bookingsCovidOnly,
              combined: rest.bookingsCombined,
            },
          })
        );
      },
      guard: isTrustResponse,
    }),
    []
  );
  const trustList = useDataClient<Trust[], TrustResponse>(
    DataClient.getData,
    getDataArgs,
    hookOptions
  );
  return trustList;
}
