import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
export default ({
  subheading,
  reservationsRequiringCancellation,
  reservationsRequiringMoving,
  children,
}: {
  subheading?: string;
  reservationsRequiringCancellation?: any[];
  reservationsRequiringMoving?: any[];
  children?: JSX.Element;
}) => {
  if (!subheading) {
    subheading =
      reservationsRequiringMoving?.length &&
      !reservationsRequiringCancellation?.length
        ? "Are you sure you want to reschedule this clinic?"
        : "Are you sure you want to make these changes?";
  }
  return (
    <Alert severity="warning">
      <AlertTitle>Booking conflicts</AlertTitle>
      <h3>{subheading}</h3>
      {reservationsRequiringCancellation?.length ? (
        <>
          <p>
            {reservationsRequiringCancellation?.length} appointment
            {reservationsRequiringCancellation.length > 1 && "s"} will be
            cancelled. Staff will be notified and asked to rebook.
            <br />
            The notification can be customised under{" "}
            <a
              href="/settings/templates"
              target="_blank"
              rel="noreferrer"
              className="external-link"
              style={{ color: "inherit" }}
            >
              Settings &gt; Email & SMS
            </a>{" "}
            &gt; Cancelled Appointment Email & SMS
          </p>
        </>
      ) : (
        ""
      )}
      {reservationsRequiringMoving?.length ? (
        <>
          <p>
            {reservationsRequiringMoving?.length} appointment
            {reservationsRequiringMoving.length > 1 && "s"} will be rescheduled.
            Staff will be notified of the new clinic date and/or location and
            given the option to reschedule if necessary.
            <br />
            The notification can be customised under{" "}
            <a
              href="/settings/templates"
              target="_blank"
              rel="noreferrer"
              className="external-link"
              style={{ color: "inherit" }}
            >
              Settings &gt; Email & SMS
            </a>{" "}
            &gt; Rescheduled Clinic Email & SMS
          </p>
        </>
      ) : (
        ""
      )}
      {children}
    </Alert>
  );
};
