import { DateTime } from "luxon";
import { assign, createMachine } from "xstate";
import {
  currentTime,
  isEarlierInTheDay,
  isTodaysDate,
} from "../../../../../lib/common_utils";
import DataClient from "../../../../../lib/services/api/DataClient";

const findClinicInContext = (context) => {
  const { clinicId } = context;
  const clinicsByDate = context.clinics;
  // Find the clinic to which we're referring
  const clinics = [].concat.apply(
    [],
    clinicsByDate.map((c) => c.clinics)
  );
  return clinics.find((c) => c.id === clinicId);
};
// const getTimeFrom = (context) => {
//   return (
//     DateTime.now()
//       .plus({ weeks: context.weekPivot })
//       .toISODate()
//   );
// };
// const getTimeTo = (context) =>
//   DateTime.now()
//     .plus({ weeks: context.weekPivot, days: 7 })
//     .toISODate();

export const getSites = (params) =>
  DataClient.getData("/HospitalSite/GetForActiveCampaign", params);
const getClinics = (params) =>
  DataClient.getData("Reservation/AvailableClinics", params);
const getSlots = (params) =>
  DataClient.getData("Reservation/AvailableClinicTimesByHour", params);
const getTimes = (context) => {
  const { clinicId, slotFrom } = context;
  const times = slotFrom.split(" - ");
  let timeFrom = times[0];
  // API bug discussed: https://techdept-team.slack.com/archives/C01UKV2AK5M/p1631483312492700
  // TL:DR; ShowAllSlots?timeTo=24:00 borks API so hack to 23:59, which is fine as clinic slots can't run over midnight (in any scenario I can think of)
  let timeTo = times[1] === "24:00" ? "23:59" : times[1];
  // If selected clinic is today, adjust timeFrom so that it can't look earlier than this moment
  const clinic = findClinicInContext(context);
  if (isTodaysDate(clinic.date) && isEarlierInTheDay(timeFrom, currentTime())) {
    timeFrom = currentTime();
  }
  const params = { clinicId, timeFrom, timeTo };
  return DataClient.getData("/Reservation/ShowAllSlots", params);
};
const filterFutureOnlyClinicSlots = (context, clinicTimesByHour) => {
  // If selected clinic is today, filter out slots that have finished
  const clinic = findClinicInContext(context);
  const { slotFrom } = context;
  clinicTimesByHour = clinicTimesByHour.filter(({ timeDuration }) => {
    const times = timeDuration.split(" - ");
    let timeTo = times[1];
    return !(
      isTodaysDate(clinic.date) && isEarlierInTheDay(timeTo, currentTime())
    );
  });
  return clinicTimesByHour;
};

/**
 *
 * @param {DateTime} date
 * @returns positive number of weeks from data and DateTime.now()
 */
const weeksFromToday = (date) => {
  const diffInWeeks =
    date?.diff(DateTime.now(), "weeks").toObject()?.weeks || 0;
  return Math.max(Math.floor(diffInWeeks), 0);
};

export default function createAppointmentMachine({
  pathogens = [],
  earliestDate,
  startDate,
  includeArchivedSites = false,
}) {
  const startingPivot = startDate ? weeksFromToday(startDate) : 0;
  const machineSetup = {
    id: "appointment-details",
    initial: "selectingSite",
    context: {
      sites: [],
      hospitalSiteId: null,
      clinics: null,
      clinicId: null,
      clinicTypeId: null,
      dropInsAvailable: null,
      slots: null,
      slotFrom: null,
      times: null,
      timeFrom: null,
      startWeek: startingPivot,
      weekPivot: startingPivot,
      earliestDate, // fixed on load / when entering date
      startDate, // changes with calendar
      pathogens,
      includeArchivedSites,
    },
    states: {
      selectingSite: {
        initial: "loading",
        on: {
          SELECT_SITE: {
            target: ".selectingClinic",
            actions: assign({
              hospitalSiteId: (_, event) => event.id,
              pathogens: (_, event) => {
                return event.pathogens;
              },
              clinics: null,
              clinicId: null,
              clinicTypeId: null,
              dropInsAvailable: null,
              slots: null,
              slotFrom: null,
              times: null,
              timeFrom: null,
              startWeek: (_, event) => weeksFromToday(event.startDate),
              weekPivot: (_, event) => weeksFromToday(event.startDate),
            }),
          },
        },
        states: {
          loading: {
            invoke: {
              id: "getSites",
              src: (context) => {
                const params = new URLSearchParams();
                context.pathogens.forEach((p) => {
                  params.append("pathogens", p);
                });
                if (context.includeArchivedSites === true) {
                  params.append("includeArchived", "true");
                }
                return getSites(params);
              },
              onDone: {
                target: "ready",
                actions: assign({
                  sites: (_, event) =>
                    event.data.results.map(
                      ({ id, hospitalSiteName, availableSlot }) => {
                        return {
                          id,
                          label: hospitalSiteName,
                          value: hospitalSiteName,
                          meta: {
                            availableSlot,
                          },
                        };
                      }
                    ),
                }),
              },
              onError: {
                target: "error",
              },
            },
          },
          error: {
            on: {
              RETRY: "loading",
            },
          },
          ready: {
            on: {
              SELECT_SITE: {
                target: "selectingClinic",
                actions: assign({
                  hospitalSiteId: (_, event) => event.id,
                }),
              },
            },
          },
          selectingClinic: {
            initial: "loading",
            on: {
              SELECT_CLINIC: {
                target: ".selectingSlot",
                actions: assign({
                  slots: null,
                  slotFrom: null,
                  times: null,
                  timeFrom: null,
                  clinicId: (_, event) => event.id,
                }),
              },
            },
            states: {
              ready: {
                on: {
                  SELECT_CLINIC: {
                    target: "selectingSlot",
                    actions: assign({
                      slots: null,
                      slotFrom: null,
                      times: null,
                      timeFrom: null,
                      clinicId: (_, event) => event.id,
                    }),
                  },
                  CALENDAR_FORWARD: {
                    target: "loading",
                    actions: assign({
                      slots: null,
                      slotFrom: null,
                      times: null,
                      timeFrom: null,
                      clinics: null,
                      clinicId: null,
                      clinicTypeId: null,
                      dropInsAvailable: null,
                      // weekPivot: (context) => context.weekPivot + 1,
                      startDate: (context) =>
                        context.startDate.plus({ weeks: 1 }),
                    }),
                  },
                  CALENDAR_BACKWARD: {
                    target: "loading",
                    actions: assign({
                      slots: null,
                      slotFrom: null,
                      times: null,
                      timeFrom: null,
                      clinics: null,
                      clinicId: null,
                      clinicTypeId: null,
                      dropInsAvailable: null,
                      // weekPivot: (context) =>
                      //   Math.max(context.weekPivot - 1, context.startWeek),
                      startDate: (context) =>
                        context.startDate.minus({
                          weeks:
                            context.startDate.startOf("day") >
                            context.earliestDate.startOf("day")
                              ? 1
                              : 0,
                        }),
                    }),
                  },
                },
              },
              loading: {
                invoke: {
                  id: "getClinics",
                  src: (context) => {
                    // console.log("::getClinics", context);
                    const params = {
                      hospitalSiteId: context.hospitalSiteId,
                      // dateFrom: getTimeFrom(context),
                      // dateTo: getTimeTo(context),
                      dateFrom: context.startDate?.toISODate(),
                      dateTo: context.startDate?.plus({ days: 7 })?.toISODate(),
                    };
                    let urlParams = new URLSearchParams(params);
                    context.pathogens.forEach((p) => {
                      urlParams.append("pathogens", p);
                    });
                    // If Flu & Covid Together, requireBookingAllPathogens is not necessary as it is supposedly handled on the API end
                    return getClinics(urlParams);
                  },
                  onDone: {
                    target: "ready",
                    actions: assign({
                      clinics: (_, event) => {
                        return event.data;
                      },
                    }),
                  },
                  onError: {
                    target: "error",
                  },
                },
              },
              error: {
                on: {
                  RETRY: "loading",
                },
              },
              selectingSlot: {
                initial: "loading",
                on: {
                  SELECT_SLOT: {
                    target: ".selectingTime",
                    actions: assign({
                      times: null,
                      timeFrom: null,
                      slotFrom: (_, event) => event.time,
                    }),
                  },
                  SELECT_DROP_IN: {
                    target: ".selectingTime.selected",
                    actions: assign({
                      slotFrom: null,
                      timeFrom: (_, event) => event.timeFrom,
                    }),
                  },
                  CALENDAR_FORWARD: {
                    target: "loading",
                    actions: assign({
                      slots: null,
                      slotFrom: null,
                      times: null,
                      timeFrom: null,
                      clinics: null,
                      clinicId: null,
                      clinicTypeId: null,
                      dropInsAvailable: null,
                      // weekPivot: (context) => context.weekPivot + 1,
                      // startDate: DateTime.now().plus({ weeks: 1 }),
                      // startDate: (context) => {
                      //   console.log("wtf", context);
                      //   // return context.startDate.plus({ weeks: 1 });
                      // },
                    }),
                  },
                  CALENDAR_BACKWARD: {
                    target: "loading",
                    actions: assign({
                      slots: null,
                      slotFrom: null,
                      times: null,
                      timeFrom: null,
                      clinics: null,
                      clinicId: null,
                      clinicTypeId: null,
                      dropInsAvailable: null,
                      //   weekPivot: (context) =>
                      //     Math.max(context.weekPivot - 1, context.startWeek),
                    }),
                  },
                },
                states: {
                  ready: {
                    on: {
                      SELECT_SLOT: {
                        target: "selectingTime",
                        actions: assign({
                          slotFrom: (_, event) => event.time,
                        }),
                      },
                      SELECT_DROP_IN: {
                        target: "selectingTime.selected",
                        actions: assign({
                          slotFrom: null,
                          timeFrom: (_, event) => event.timeFrom,
                        }),
                      },
                    },
                  },
                  loading: {
                    invoke: {
                      id: "getSlots",
                      src: (context) =>
                        getSlots({ clinicId: context.clinicId }),
                      onDone: {
                        target: "ready",
                        actions: assign({
                          slots: (_, event) =>
                            filterFutureOnlyClinicSlots(
                              _,
                              event.data.clinicTimesByHour
                            ),
                          clinicTypeId: (_, event) =>
                            Number(event.data.clinicTypeId),
                          dropInsAvailable: (_, event) =>
                            Number(event.data.dropInsAvailable),
                        }),
                      },
                      onError: {
                        target: "error",
                      },
                    },
                  },
                  error: {
                    on: {
                      RETRY: "loading",
                    },
                  },
                  selectingTime: {
                    initial: "loading",
                    on: {
                      SELECT_TIME: {
                        target: ".selected",
                        actions: assign({
                          timeFrom: (_, event) => event.timeFrom,
                        }),
                      },
                    },
                    states: {
                      loading: {
                        invoke: {
                          id: "getTimes",
                          src: (context) => getTimes(context),
                          onDone: {
                            target: "ready",
                            actions: assign({
                              times: (_, event) => event.data.results,
                            }),
                          },
                          onError: {
                            target: "error",
                          },
                        },
                      },
                      ready: {
                        on: {
                          SELECT_TIME: {
                            target: "selected",
                            actions: assign({
                              timeFrom: (_, event) => event.timeFrom,
                            }),
                          },
                        },
                      },
                      error: {
                        on: {
                          RETRY: "loading",
                        },
                      },
                      selected: {},
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  };

  return createMachine(machineSetup);
}
