import React from "react";
import DataClient from "../../../../lib/services/api/DataClient";
import useDataClient from "../useDataClient";
import { TrustGroupUserData } from "./useTrustGroupUserList";

type TrustResponse = {
  success: boolean;
  results: TrustGroupUserData;
};

const isTrustResponse = (res: unknown): res is TrustResponse => {
  return res["success"] && res["results"];
};

export default function useTrustGroupUser(id: number | null) {
  const getDataArgs = React.useMemo(
    () => (id ? [`/TrustGroupUser/${id}`] : []),
    [id]
  );
  const hookOptions = React.useMemo(
    () => ({
      formatter: (data: TrustResponse) => {
        if (!data.success) {
          throw new Error("API call was a failure");
        }
        return data.results;
      },
      guard: isTrustResponse,
    }),
    []
  );
  return useDataClient<TrustGroupUserData, TrustResponse>(
    DataClient.getData,
    getDataArgs,
    hookOptions
  );
}
