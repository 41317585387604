import { Grid, Card, FormControlLabel } from "@material-ui/core";
import { useState } from "react";
import useCampaigns from "../../lib/hooks/useCampaigns";
import { getRoleCurrentTrust } from "../../lib/services/TrustSwitcher";
import { Roles } from "../../lib/types/TrustRoles";
import { Activestaff, currentVaccineType } from "../Home/ActiveStaff";
import { UnVaccinatedStaffChart } from "./Charts/UnvaccinatedStaffChart";
import { ClinicCapacity } from "./ClinicCapacityBlock";
import { makeStyles } from "@material-ui/core/styles";
import { VaccinatedStaffChart } from "./Charts/VaccinatedStaffChart";
import { DeclineReasonsChart } from "./Charts/DeclineReasonsChart";
import clsx from "clsx";
import Switch from "@material-ui/core/Switch";
import { upperFirst } from "lodash";

const DashboardTab = (props: any) => {
  const role = getRoleCurrentTrust();
  const isTrustWorker = role === Roles.TrustWorker;
  const isVaccinator = role === Roles.Vaccinator;
  const isTrustAdmin = role === Roles.TrustAdmin;
  const isSuperAdmin = role === Roles.SuperAdmin;
  const { loading, hasActiveCampaigns } = useCampaigns(isTrustWorker);
  const [onlyFLHCW, setonlyFLHCW] = useState(false);
  const useStyles = makeStyles((theme) => ({
    wrapper: {
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      marginBottom: "20px",
      alignItems: "stretch",
    },
    flex1: {
      // flex: 1
    },
    flex0: {
      // flex: 0
    },
    desktop: {
      width: 120,
    },
    subtitle1: {
      fontWeight: 800,
      fontSize: theme.spacing(2),
    },
    p: {
      textAlign: "center",
    },
    topGridRow: {
      height: 30,
    },
    onlyFLHCWButton: {
      float: "right",
      marginTop: 20,
    },
  }));

  const classes = useStyles();

  if (loading) return null;
  return (
    <Grid container justifyContent="space-between" spacing={3}>
  <Grid item xs={12} className={classes.topGridRow} container justifyContent="flex-end">
    <FormControlLabel
      control={
        <Switch
          checked={onlyFLHCW}
          color="primary"
          onChange={() => setonlyFLHCW(!onlyFLHCW)}
        />
      }
      label="View Frontline Only"
    />
  </Grid>
  <Grid item xs={6}>
    <h2>{upperFirst(currentVaccineType(props.pathogenIds))} Dashboard</h2>
    <Card
      elevation={0}
      className={classes.wrapper}
      style={{ overflow: "visible" }}
    >
 
          <Activestaff
            pathogenIds={props.pathogenIds}
            onlyFLHCW={onlyFLHCW}
            trustId={props.trustId}
            excludeL1Ids={props.excludeL1Ids}
          ></Activestaff>
        </Card>
        {props.pathogenIds.length == 1 && (
          <>
            <h2>
              {upperFirst(currentVaccineType(props.pathogenIds))} Clinic
              Capacity
            </h2>
            <Card elevation={0} className={classes.wrapper}>
              <ClinicCapacity
                pathogenIds={props.pathogenIds}
                trustId={props.trustId}
              ></ClinicCapacity>
            </Card>
          </>
        )}
      </Grid>
      <Grid xs={6} item>
        <h2>
          Breakdown of Staff Vaccinated for{" "}
          {upperFirst(currentVaccineType(props.pathogenIds))}
        </h2>
        <Card elevation={0} className={classes.wrapper}>
          <VaccinatedStaffChart
            pathogenIds={props.pathogenIds}
            onlyFLHCW={onlyFLHCW}
            trustId={props.trustId}
            excludeL1Ids={props.excludeL1Ids}
          ></VaccinatedStaffChart>
        </Card>
      </Grid>
      <Grid xs={8} item>
        {props.pathogenIds.length == 1 && (
          <>
            <h2>
              Breakdown of Staff Unvaccinated for{" "}
              {upperFirst(currentVaccineType(props.pathogenIds))}
            </h2>
            <Card elevation={0} className={classes.wrapper}>
              <UnVaccinatedStaffChart
                pathogenIds={props.pathogenIds}
                onlyFLHCW={onlyFLHCW}
                trustId={props.trustId}
                excludeL1Ids={props.excludeL1Ids}
              ></UnVaccinatedStaffChart>
            </Card>
          </>
        )}
      </Grid>
      <Grid xs={4} item>
        {props.pathogenIds.length == 1 && (
          <>
            <h2>
              Reasons for Declining a{" "}
              {upperFirst(currentVaccineType(props.pathogenIds))} Vaccination
            </h2>
            <Card elevation={0} className={clsx(classes.wrapper, classes.p)}>
              <DeclineReasonsChart
                pathogenIds={props.pathogenIds}
                onlyFLHCW={onlyFLHCW}
                trustId={props.trustId}
                excludeL1Ids={props.excludeL1Ids}
              ></DeclineReasonsChart>
            </Card>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default DashboardTab;
