import { Button, CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import {
  getRoleCurrentTrust,
  getTrustId,
  getTrustPathogens,
} from "../../../../lib/services/TrustSwitcher";
import useDelayedRedirect from "../../Patients/BookAppointment/useDelayedRedirect";
import styles from "../Form.module.scss";
import useCreateTrustGroupUser from "./useCreateTrustGroupUser";
import useUpdateTrustGroupUser from "./useUpdateTrustGroupUser";

import { Roles } from "../../../../lib/types/TrustRoles";
import { TrustGroup } from "../TrustGroup/TrustGroups";
import { TrustGroupUserData } from "./useTrustGroupUserList";

type TrustGroupUserFormProps = {
  setFeedback: (feedback: {
    type: "success" | "error";
    message: string;
  }) => void;
  setWarningDialog?: (messages: boolean | string[]) => void;
  id: number;
  isUpdate: boolean;
  isChoosingTrust?: boolean;
  formData: any;
  trustGroups: any;
  returnPath?: string;
  proceedIfWarnings?: boolean;
};

const formId = "trustGroupUserCreateOrUpdate";
const RETURN_PATH = "/users/trust-group-users";

export default function TrustGroupUserForm({
  setFeedback,
  setWarningDialog = () => {
    return;
  },
  id,
  isUpdate,
  isChoosingTrust = true,
  formData,
  trustGroups,
  returnPath = RETURN_PATH,
  proceedIfWarnings = false,
}: TrustGroupUserFormProps): JSX.Element {
  const history = useHistory();
  const {
    reset,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  // const [trusts, trustsLoading, trustsError] = useTrustList(isChoosingTrust);

  React.useEffect(() => {
    if (formData) reset(formData);
  }, [formData, reset]);

  const delayedRedirect = useDelayedRedirect(returnPath, 3);

  const [createUser, createSuccess, createError, createWarning] =
    useCreateTrustGroupUser();
  const [updateUser, updateSuccess, updateError] = useUpdateTrustGroupUser();

  const trustId = getTrustId();
  const isSuperAdmin = getRoleCurrentTrust() === Roles.SuperAdmin;
  const trustPathogens = getTrustPathogens();
  const isMultiplePathogenTrust = isSuperAdmin || trustPathogens?.length > 1;
  const [modal, setModal] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (data: any) => {
    setIsSubmitting(true);
    if (isUpdate) {
      updateUser(id, {
        ...data,
      });
    } else {
      createUser({
        firstName: data.firstName,
        lastName: data.lastName,
        emailAddress: data.emailAddress,
        trustGroup: findTrustGroup(data.trustId) as any, // TODO: wtf typing nand shoud be trustGroupId not trustId
        proceedIfWarnings,
      });
    }
  };

  function findTrustGroup(trustGroupId: number): TrustGroupUserData {
    return trustGroups.find((tg: TrustGroup) => +tg.id === +trustGroupId);
  }

  React.useEffect(() => {
    if (createSuccess) {
      setIsSubmitting(false);
      setFeedback({
        type: "success",
        message: "System User created.",
      });
      delayedRedirect();
    }
  }, [createSuccess, setFeedback, delayedRedirect]);

  React.useEffect(() => {
    if (updateSuccess) {
      setIsSubmitting(false);
      setFeedback({
        type: "success",
        message: "System User updated.",
      });
      delayedRedirect();
    }
  }, [updateSuccess, setFeedback, delayedRedirect]);

  React.useEffect(() => {
    if (createError) {
      setIsSubmitting(false);
      if (Array.isArray(createError)) {
        setFeedback({
          type: "error",
          message: createError.join(" "),
        });
      } else {
        setFeedback({
          type: "error",
          message: "System User could not be created",
        });
      }
    }
  }, [createError, setFeedback]);

  React.useEffect(() => {
    if (updateError) {
      setIsSubmitting(false);
      if (Array.isArray(updateError)) {
        setFeedback({
          type: "error",
          message: updateError.join(" "),
        });
      } else {
        setFeedback({
          type: "error",
          message: "System User could not be updated",
        });
      }
    }
  }, [updateError, setFeedback]);

  // React.useEffect(() => {
  //   if (trustsError) {
  //     setIsSubmitting(false);
  //     setFeedback({
  //       type: "error",
  //       message: "Systems could not be loaded. Please refresh the page.",
  //     });
  //   }
  // }, [trustsError, setFeedback]);

  React.useEffect(() => {
    if (createWarning) {
      setIsSubmitting(false);
      setWarningDialog(createWarning);
    }
  }, [setWarningDialog, createWarning]);

  // const isDisplayingTrustSelect =
  //   isChoosingTrust && !isUpdate && !trustsLoading && !trustsError;

  return (
    <div className={styles.template}>
      <h1>{isUpdate ? "Edit System User" : "Create System User"}</h1>
      <form
        id={formId}
        className={styles.form}
        onSubmit={(e) => {
          handleSubmit(onSubmit)(e).catch((err) => {
            console.log(err);
            setFeedback({ type: "error", message: err.message });
          });
        }}
      >
        <div className={styles.email}>
          <h2>Description</h2>
          {/* First Name */}
          <label className={styles.textInput}>
            First Name
            <input
              {...register("firstName", {
                required: "You must enter a first name",
              })}
            />
            {errors.firstName && (
              <span role="alert">{errors.firstName.message}</span>
            )}
          </label>
          {/* Last Name */}
          <label className={styles.textInput}>
            Last Name
            <input
              {...register("lastName", {
                required: "You must enter a last name",
              })}
            />
            {errors.lastName && (
              <span role="alert">{errors.lastName.message}</span>
            )}
          </label>
          {/* Trust Group (System) Select */}
          {!isUpdate && (
            <>
              <label className={styles.textInput}>
                <span>System</span>
                <select
                  className={styles.trustSelect}
                  {...register("trustId", {
                    required: "You must enter an system name",
                  })}
                >
                  <option selected hidden disabled value="">
                    Please select a trust
                  </option>
                  {trustGroups.map((tg: TrustGroup) => (
                    <option key={tg.id} value={tg.id}>
                      {tg.name}
                    </option>
                  ))}
                </select>
              </label>
              {errors.trustGroup && (
                <span className={styles.error}>
                  {errors.trustGroup.message}
                </span>
              )}
            </>
          )}
          {/* Email address */}
          {!isUpdate && (
            <label className={styles.textInput}>
              Email
              <input
                {...register("emailAddress", {
                  required: "You must enter an email address",
                })}
              />
              {errors.emailAddress && (
                <span role="alert">{errors.emailAddress.message}</span>
              )}
            </label>
          )}
          {isUpdate && formData?.emailAddress && (
            <label className={styles.textInput}>
              Email{" "}
              <span className={styles.emailInfo}>{formData.emailAddress}</span>
            </label>
          )}
        </div>
        {isSubmitting ? (
          <CircularProgress />
        ) : (
          <div className={styles.actions}>
            <Button
              form={formId}
              color="secondary"
              variant="contained"
              disabled={isSubmitting}
              onClick={(e) => {
                e.preventDefault();
                handleSubmit(onSubmit)().catch((err) => {
                  console.log(err);
                  setFeedback({ type: "error", message: err.message });
                });
              }}
            >
              {isUpdate ? "Update" : "Create"}
            </Button>
            <Button
              onClick={() => {
                history.push(returnPath);
              }}
            >
              Cancel
            </Button>
          </div>
        )}
      </form>
    </div>
  );
}
