import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
} from "@material-ui/core";
import {
  Edit as EditIcon,
  KeyboardBackspace as BackIcon,
} from "@material-ui/icons";
import { useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
// import ApiAlert from "../../../../components/ApiAlert";
import DataClient from "../../../../lib/services/api/DataClient";
export const splitClinic = (clinicId: number) =>
  // This POST is actually not data, but query params. GREAT (╯°□°)╯︵ ┻━┻
  DataClient.postData(
    `/Clinic/SplitOutClinic`,
    {},
    {
      clinicId,
    }
  );
export default (
  props: RouteComponentProps<{
    clinicId: string;
  }>
): JSX.Element => {
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);
  const clinicId = props.match.params.clinicId;
  return (
    <main className="wrapper-main">
      <h1>Edit Clinic</h1>
      <p>
        This clinic is part of a <strong>recurring series</strong> of clinics.
        <br />
      </p>
      <Box mt={3}>
        <h2>Would you like to edit all recurring clinics in the series?</h2>
        <DialogActions>
          <Button
            component={Link}
            to={`/clinics/${clinicId}/edit`}
            type="submit"
            variant="contained"
            color="secondary"
            startIcon={<EditIcon />}
          >
            Edit All
          </Button>
          <Button
            component={Link}
            to={`/clinics/${clinicId}/view`}
            variant="outlined"
            color="secondary"
            style={{
              color: "#fff",
            }}
            startIcon={<BackIcon />}
          >
            Back
          </Button>
        </DialogActions>
      </Box>
      <h3>- OR -</h3>
      <Box mt={3}>
        <h2>Would you like to decouple and edit this single occurrence?</h2>
        <p>
          If you edit this single occurrence, it will split the recurring series
          and this one will become decoupled.
          <br />
          Any future changes to the series will exclude this clinic. This action
          cannot be undone.
        </p>
      </Box>
      <DialogActions>
        <Button
          component={Link}
          to={`/clinics/${clinicId}/edit-single`}
          variant="contained"
          color="secondary"
          startIcon={<EditIcon />}
        >
          Edit Single
        </Button>
        <Button
          component={Link}
          to={`/clinics/${clinicId}/view`}
          variant="outlined"
          color="secondary"
          style={{
            color: "#fff",
          }}
          startIcon={<BackIcon />}
        >
          Back
        </Button>
      </DialogActions>
      <Box mt={3}>{alert}</Box>
      {loading && (
        <div className="interstitial">
          <CircularProgress />
        </div>
      )}
    </main>
  );
};
