import React from "react";
import ReactDOM from "react-dom";
import DataClient from "../../../../lib/services/api/DataClient";
import { TrustAdmin } from "./useTrustAdminList";

export default function useUpdateTrustAdmin() {
  const [success, setSuccess] = React.useState<false | TrustAdmin>(false);
  const [error, setError] = React.useState(false);
  const [warning, setWarning] = React.useState<boolean | string[]>(false);

  const callback = (id: number, update: TrustAdmin) => {
    setSuccess(false);
    DataClient.update(`/TrustAdmin/${id}`, {
      firstName: update.firstName,
      lastName: update.lastName,
      jobTitle: update.jobTitle,
      dateOfBirth: update.dateOfBirth,
      mobileNumber: update.mobileNumber,
      emailAddress: update.emailAddress,
      pathogens: update.pathogens,
    })
      .then((res: unknown) => {
        ReactDOM.unstable_batchedUpdates(() => {
          if (res["success"]) {
            setSuccess({ ...update, id });
          } else {
            console.log(res);
            throw new Error("API Update failed");
          }
        });
      })
      .catch((error) => {
        ReactDOM.unstable_batchedUpdates(() => {
          if (error?.response?.status === 422) {
            setWarning(error.response?.data?.results);
          } else if (error?.response?.status === 409) {
            setError(
              error.response?.data?.results || error.response?.data?.errors
            );
          } else {
            console.error(error);
            setError(true);
          }
        });
      });
  };

  return [callback, success, error, warning] as const;
}
