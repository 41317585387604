import { ReactElement } from "react";
import { MyAppointment } from "../views/Users/Patients/MyAppointments/MyAppointments";
import { SteppedConfirmDialogStep } from "../views/Users/useSteppedConfirmDialog";
import { enumInArray, filterHeterogenous } from "./enumManipulations";
import { otherPathogenIds, Pathogen } from "./pathogens";
import { getTrustPathogens } from "./services/TrustSwitcher";
//
// NOTE! If changing any of these strings, they may need changing around other places too - which seems dumb but it's more useful to pair values with the API strings than with copy.
//
// Usage
// enumClinicSchedule.Recurring = 2
// enumClinicSchedule["Recurring"] = 2
// enumClinicSchedule[2] = "Recurring"
export enum enumClinicSchedule {
  "One-off" = 1,
  "Recurring" = 2,
}
export enum enumClinicTypeId {
  // Don't change these ..
  bookingsOnly = 1,
  bookingsWithDropIns = 2,
  walkAround = 3,
}
export enum enumClinicTypeIdLabels {
  // .. Change these
  bookingsOnly = "Bookable Time Slots",
  bookingsWithDropIns = "Bookable Time Slots plus bookable drop-ins",
  walkAround = "Walk Around (not visible for staff booking)",
}
export enum enumRequireBookingAllPathogens {
  "Flu & Covid Together / Flu Only / Covid Only" = 0,
  "Flu & Covid Together Only" = 1,
}
export enum SecurityRoleEnum {
  "SuperAdmin" = 1,
  "TrustAdmin" = 2,
  "TrustSuperAdmins" = 3,
  "Staff" = 4,
  "Vaccinator" = 5,
  "ClinicAdmin" = 7,
  "ReportingViewer" = 8,
}
// export const enumRequireBookingAllPathogens = {
//   false: "Flexible",`
//   true: "Fixed",
// };
export enum enumRecurrenceId {
  "None" = 0,
  "Daily" = 1,
  "Weekly" = 2,
  "Monthly" = 3,
}
// Note: both the strings and integers are sacrosanct here,
// as they're used to translate in both directions
export enum enumVaccinationStatus {
  // Order here reflects the Vaccination Details attendance status,
  // but Staff filter dropdown has custom ordering.
  // Re-ordered https://techdept-team.slack.com/archives/C0275F7ST4Z/p1660734866150879?thread_ts=1660734132.819719&cid=C0275F7ST4Z
  "No Booking" = 0, // canbook
  "Booked" = 1,
  "Vaccinated" = 2,
  "DNA" = 3, // canbook
  "Attended But Not Vaccinated" = 4, // canbook
  "Had Elsewhere" = 5,
  "Declined (allergy)" = 6, // obsolete
  "Declined (other contraindication)" = 7, // obsolete
  "Declined (doesn't want a vaccine)" = 8, // obsolete
  "Declined" = 9,
  "Exempt" = 10,
  "Had In Another Trust" = 11,
}
export enum enumVaccinationStatusFilterable {
  "No Booking" = enumVaccinationStatus["No Booking"],
  "Declined" = enumVaccinationStatus["Declined"],
  "DNA" = enumVaccinationStatus["DNA"],
  "Attended But Not Vaccinated" = enumVaccinationStatus[
    "Attended But Not Vaccinated"
  ],
  "Booked" = enumVaccinationStatus["Booked"],
  "Vaccinated" = enumVaccinationStatus["Vaccinated"],
  "Had Elsewhere" = enumVaccinationStatus["Had Elsewhere"],
  "Had In Another Trust" = enumVaccinationStatus["Had In Another Trust"],
  "Exempt" = enumVaccinationStatus["Exempt"],
}

// (I) can't guarantee the data was migrated to fully remove enums such as "Declined (allergy)" in all systems, so filter them out where necessary - e.g. staff table filter dropdown
export enum enumsVaccinationStatusObsolete {
  "Declined (allergy)" = 6,
  "Declined (other contraindication)" = 7,
  "Declined (doesn't want a vaccine)" = 8,
}
export enum enumVaccinationRecordNumbers {
  "Covid 1" = 1,
  "Covid 2" = 2,
  "Covid Booster 2" = 3,
}
export enum enumAssignmentStatusesFilterable {
  "Active Assignments" = "IsActiveAssignment",
  "Inactive Assignments" = "IsInactiveAssignment",
  "Not on Latest Staff Upload" = "IsNotOnLatestEsr",
  "View Archived Staff" = "ShowArchived",
}
export enum enumRolesFilterableExtra {
  "Trust Admin" = "IsTrustAdmin",
  // ^ Only currently handles this single item for expediency
}
export enum enumFlagsFilterable {
  // "Trust Admin" = "isTrustAdmin", // moved to Role filter dropdown (enumSecurityRolesFilterableExtra above) [dd#2103]
  "Manually Added" = "isAddedManually",
  "Non ESR Staff" = "isNotEsr",
  "Verified" = "isVerified",
  "Not Verified" = "isNotVerified",
  "65 Or Over" = "isOver65",
  "Under 18" = "isUnder18",
  "At Risk" = "isAtRisk",
  "Self Registered (approved)" = "selfRegisteredAndApproved",
  "Self Registered (unapproved)" = "selfRegisteredAndUnapproved",
  "Email In Doubt" = "isEmailInDoubt",
  "Possible Duplicate" = "IsPossibleDuplicate",
  "Requires Admin" = "requiringAdmin",
}
export enum enumFrontLineFilterable {
  "Frontline" = "isFrontLine",
  "Non Frontline" = "isNotFrontLine",
}

export enum enumPrescribingMethods {
  "National Protocol (NP)" = 1,
  "Patient Group Directions (PGD)" = 2,
  "Patient Specific Directions (PSD)" = 3,
  "Written Instruction (WI)" = 4,
}
export const filterObsoleteEnumsFromArray = (arr: [], enumsObsolete: any) =>
  arr.filter((a: enumInArray) => !enumsObsolete[a.name]);
// { One-off: 1, Recurring: 2 }
export const enumToInputOptions = (
  e: any,
  arrayValues = false,
  arrayLabels = {}, // e.g. enumClinicTypeIdLabels
) => {
  return Object.keys(filterHeterogenous(e)).map((key) => ({
    label: arrayLabels?.[key] || key.toString(),
    value: arrayValues && !Array.isArray(e[key]) ? [e[key]] : e[key],
  }));
};
export const slotDurations = [4, 5, 6, 8, 10, 12, 15];
export const dayStrings = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
export const monthStrings = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
// [2021, 2022, ..., 2026]
export const expiryYearOptions: number[] = Array.from(
  { length: 6 },
  (v, k) => k + new Date().getFullYear(),
);
export const clinicTypeElement = ({
  pathogens,
  requireBookingAllPathogens,
}: {
  pathogens?: Pathogen[];
  requireBookingAllPathogens?: boolean;
}): JSX.Element => (
  <span className="clinic-type">
    {pathogens?.map((p: { id: number; name: string }, i: number) => (
      <span key={`pathogen-${p.id}`}>
        <span data-theme-id={p.id}>{p.name.toUpperCase()}</span>
        {i < pathogens.length - 1 && <span>&#8203;/&#8203;</span>}
      </span>
    )) || `-`}{" "}
    {pathogens?.length > 1 && (
      <span className="_flexibility">
        {requireBookingAllPathogens === true
          ? "(Together)"
          : requireBookingAllPathogens === false
            ? "(Flexible)"
            : ""}
      </span>
    )}
  </span>
);
// Active booking in this case means Booked / ABNV / DNA
export const appointmentisBookedABNVDNAForThisPathogen = ({
  appointment,
  pathogenId,
}: {
  appointment: MyAppointment;
  pathogenId: number;
}): boolean => {
  return [
    enumVaccinationStatus["Booked"],
    enumVaccinationStatus["Attended But Not Vaccinated"],
    enumVaccinationStatus["DNA"],
  ].includes(
    appointment?.details?.[
      `vaccinationStatusId${
        getTrustPathogens().find((p) => p.id === pathogenId).name
      }`
    ],
  );
};
// Does this appointment have vaccinated status for other pathogens?
export const appointmentIsVaccinatedForAnotherPathogen = ({
  appointment,
  pathogenIds,
}: {
  appointment: MyAppointment;
  pathogenIds: number[];
}): boolean => {
  return otherPathogenIds(pathogenIds)?.some(
    (pathogenId) =>
      appointment?.details?.[
        `vaccinationStatusId${
          getTrustPathogens().find((p) => p.id === pathogenId).name
        }`
      ] === enumVaccinationStatus["Vaccinated"],
  );
};

type NotificationSettingSwitch = {
  name: string;
  label: ReactElement | string;
  description: ReactElement | string;
  activateDialogTitle?: string;
  deactivateDialogTitle?: string;
  dialogFixedHeight?: string;
  deactivateButtonLabel?: string;
  generateConfirmationSteps: (
    isActive: boolean,
  ) => ReactElement | SteppedConfirmDialogStep[];
};

export const updateNotificationSettingsSwitches: NotificationSettingSwitch[] = [
  {
    name: "notifyPostVaccination",
    label: "Post Vaccination Confirmation Emails",
    description:
      "Sends an email 24 hours after the staff member has been vaccinated to confirm the details of the vaccination. The email is delayed by 24 hours to allow any input errors made during the clinic to be corrected. Make sure you edit the default template above as you may want to remove some of the fields if you don't record them e.g batch number etc. You may also want to add a thank you message or a link to a survey.",
    generateConfirmationSteps: (isActive: boolean): ReactElement => {
      return (
        <p>
          <strong>
            {!isActive
              ? "Are you sure you want to send post vaccination emails?"
              : "Are you sure you want to deactivate post vaccination emails?"}
          </strong>
        </p>
      );
    },
  },
  {
    name: "notifyAssignmentClinicAdmin",
    label: "Assign 'Clinic Admin' Role Emails",
    description:
      "Sends an email to staff members when you assign them as a 'Clinic Admin'. This is a generic email that trusts don't have control over. It asks them to verify their account by setting a password (if they have not already done so). Staff are usually assigned as 'Clinic Admins' before the campaign is activated (i.e. staff are sent their invite emails). Bear in mind that if you send these emails before you have built at least some clinic capacity, 'Clinic Admins' won't be able to book their own appointments yet.",
    generateConfirmationSteps: (isActive: boolean): ReactElement => {
      return (
        <>
          <p>
            Staff will be sent an email when you assign them as a 'Clinic Admin'
            asking them to verify their account (if they have not already done
            so).
          </p>
          <p>
            <strong>
              {!isActive
                ? "Are you sure you want to send assign 'Clinic Admin' role emails?"
                : "Are you sure you want to deactivate assign 'Clinic Admin' role emails?"}
            </strong>
          </p>
        </>
      );
    },
  },
  {
    name: "notifyAssignmentVaccinator",
    label: "Assign 'Vaccinator' Role Emails",
    description:
      "Sends an email to staff members when you assign them as a 'Vaccinator'. This is a generic email that trusts don't have control over. It asks them to verify their account by setting a password (if they have not already done so). Staff are usually assigned as 'Vaccinators' before the campaign is activated (i.e. staff are sent their invite emails). It isn't recommended that you send these emails before you have built at least some clinic capacity, otherwise vaccinators won't be able to book their own appointments.",
    generateConfirmationSteps: (isActive: boolean): ReactElement => {
      return (
        <>
          <p>
            Staff will be sent an email when you assign them as a 'Vaccinator',
            asking them to verify their account (if they have not already done
            so).
          </p>
          <p>
            <strong>
              {!isActive
                ? "Are you sure you want to send assign 'Vaccinator' role emails?"
                : "Are you sure you want to deactivate assign 'Vaccinator' role emails?"}
            </strong>
          </p>
        </>
      );
    },
  },
  {
    name: "notifyAssignmentReportingAdmin",
    label: "Enable 'Reporting Access' Role Emails",
    description:
      "Sends an email to staff members when you give them access to the Reporting section, both with or without the defined staff list visibility option. This is a generic email that trusts don't have control over. It explains ‘Reporting Access’ and asks them to verify their account by setting a password (if they have not already done so). Bear in mind that if you send these emails before you have built at least some clinic capacity, staff won't be able to book their own appointments yet.",
    generateConfirmationSteps: (isActive: boolean): ReactElement => {
      return (
        <>
          <p>
            Staff will be sent an email when you enable 'Reporting Access' for
            them, explaining the access and asking them to verify their account
            (if they have not already done so).
          </p>
          <p>
            <strong>
              {!isActive
                ? "Are you sure you want to send enable 'Reporting Access' role emails?"
                : "Are you sure you want to deactivate enable 'Reporting Access' role emails?"}
            </strong>
          </p>
        </>
      );
    },
  },
  // #2413 word doc copy amends show this changing from 'Booking cancelled notifications' even though, to my mind, it's not quite accurate but hey ho
  {
    name: "notifyBookingCancelled",
    label: "Cancelled Appointment Email & SMS",
    description:
      "This sends an email and SMS (if the staff member has given their mobile number during booking) when a Trust Admin or Clinic Admin cancels a clinic or makes changes to a clinic that results in appointments being cancelled e.g changing the clinic duration or reducing the number of vaccinators.",
    generateConfirmationSteps: (isActive: boolean): ReactElement => {
      return (
        <>
          <p>
            When you cancel a clinic that staff have an appointment booked in,
            they will be sent an email and SMS asking them to re-book.
          </p>
          <p>
            <strong>
              {!isActive
                ? "Are you sure you want to send cancelled clinic emails & SMS?"
                : "Are you sure you want to deactivate cancelled clinic emails & SMS?"}
            </strong>
          </p>
        </>
      );
    },
  },
  {
    name: "notifyBookingRescheduled",
    label: "Rescheduled Clinic Emails & SMS",
    description:
      "Sends an email and SMS (if the staff member has given their mobile number during booking) when a Trust Admin or Clinic Admin reschedules a clinic to a different day.",
    generateConfirmationSteps: (isActive: boolean): ReactElement => {
      return (
        <>
          <p>
            When you reschedule a clinic that staff have an appointment booked
            in, they will be sent an email and SMS informing them of the new
            date.
          </p>
          <p>
            <strong>
              {!isActive
                ? "Are you sure you want to send rescheduled clinic emails & SMS?"
                : "Are you sure you want to deactivate rescheduled clinic emails & SMS?"}
            </strong>
          </p>
        </>
      );
    },
  },
  {
    name: "notifyReminderToBookFlu",
    label: "Reminders to Book Flu Appointment Emails",
    description:
      "Reminders are sent every week to staff who have not yet been vaccinated, booked an appointment, let you know they've had it elsewhere or declined. Make sure these are turned on when you activate your campaign. Turn them off once your flu campaign is over.",
    generateConfirmationSteps: (isActive: boolean): ReactElement => {
      return (
        <>
          <p>
            These will send a reminder every week to staff who have not yet been
            vaccinated, booked an appointment, let you know they've had it
            elsewhere or declined.
          </p>
          <p>
            <strong>
              {!isActive
                ? "Are you sure you want to activate weekly reminders to book a flu appointment?"
                : "Are you sure you want to deactivate weekly reminders to book a flu appointment?"}
            </strong>
          </p>
        </>
      );
    },
  },
  {
    name: "notifyReminderToBookCovid",
    label: "Reminders to Book Covid Appointment Emails",
    description:
      "Reminders are sent every week to staff who have not yet been vaccinated, booked an appointment, let you know they've had it elsewhere or declined. Make sure these are turned on when you activate your campaign. Turn them off once your Covid campaign is over.",
    generateConfirmationSteps: (isActive: boolean): ReactElement => {
      return (
        <>
          <p>
            These will send a reminder every week to staff who have not yet been
            vaccinated, booked an appointment, let you know they've had it
            elsewhere or declined.
          </p>
          <p>
            <strong>
              {!isActive
                ? "Are you sure you want to activate weekly reminders to book a Covid appointment?"
                : "Are you sure you want to deactivate weekly reminders to book a Covid appointment?"}
            </strong>
          </p>
        </>
      );
    },
  },
  {
    name: "notifyStaffWelcome",
    label: (
      <>
        Campaign Invite Emails{" "}
        <strong>
          <span className="danger">(Campaign Activation)</span>
        </strong>
      </>
    ),
    description: (
      <>
        <p>
          <strong>Please read</strong>: Sends an email to all active staff on
          your staff list (and anyone added subsequently) inviting them to book
          their flu and/or Covid appointment.
        </p>
        <p>
          <strong>
            Sending these invite emails marks the official activation of your
            campaign. This cannot be undone.
          </strong>{" "}
          Before activation, you will be guided through the 'Campaign Launch
          Checklist' to make sure you have everything in place for a successful
          launch. You can find a more detailed version of this checklist in the
          user guide in the 'Campaign Launch' chapter.
        </p>
        <p>
          Once you have completed all the checks you are strongly advised to
          arrange a screenshare call with VaccationTrack so they can double
          check everything prior to you clicking the 'Activate Campaign' button.
        </p>
      </>
    ),
    activateDialogTitle: "Campaign Launch Checklist",
    deactivateDialogTitle: "Deactivate Campaign Invite Emails",
    dialogFixedHeight: "768px",
    deactivateButtonLabel: "Yes, Deactivate",
    generateConfirmationSteps: (
      isActive: boolean,
    ): ReactElement | SteppedConfirmDialogStep[] => {
      if (isActive) {
        return (
          <>
            <p>
              This will stop Campaign Invite Emails being sent to new staff who
              are added to the system and inactive staff who subsequently become
              active, inviting them to book a vaccination appointment.
            </p>
            <p>
              If you are turning these emails off because your campaign is
              ending, make sure you also turn off the reminder emails for both
              flu and Covid, otherwise these will continue to be sent every
              week.
            </p>
            <p>
              <strong>Please note</strong>: If you turn these emails off and
              then back on again, any staff added while the emails were turned
              off will be sent an invite. Therefore it is recommended you only
              turn these emails off at the end of your campaign or if you are
              taking a break in your campaign for operational reasons.
            </p>
            <p>
              <strong>
                Are you sure you want to stop sending Campaign Invite Emails?
              </strong>
            </p>
          </>
        );
      }
      // These are accessed by enabling the Campaign Invite Emails in settings/templates
      return [
        {
          label: "Customise Emails",
          requiresConfirmation: true,
          confirmationLabel: "Yes, I have completed these steps",
          content: (
            <>
              <h2 className="secondary">
                1. Customise Mandatory Email Templates
              </h2>
              <br />
              <p>
                <strong>
                  BEFORE ACTIVATING THE CAMPAIGN, YOU MUST ENSURE YOU HAVE:
                </strong>
              </p>
              <p>
                Customised the 5 mandatory email templates on the 'Email & SMS'
                page in 'Settings'. These are
              </p>
              <ol>
                <li>
                  Campaign Invite Email (for staff{" "}
                  <strong>
                    <em>without</em>
                  </strong>{" "}
                  a verified account)
                </li>
                <li>
                  Campaign Invite Email (for staff{" "}
                  <strong>
                    <em>with</em>
                  </strong>{" "}
                  a verified account)
                </li>
                <li>
                  Reminder to Book Email (for staff{" "}
                  <strong>
                    <em>without</em>
                  </strong>{" "}
                  a verified account)
                </li>
                <li>
                  Reminder to Book Email (for staff{" "}
                  <strong>
                    <em>with</em>
                  </strong>{" "}
                  a verified account)
                </li>
                <li>Post Vaccination Confirmation Email</li>
              </ol>
              <p>
                A very common mistake is to only customise one or two of these.
                <br />
                <strong>All 5 must be customised.</strong>
              </p>
              <p>
                Please note, you no longer need to include text explaining that
                staff should not reply directly to the email. Any replies to the
                emails are now automatically forwarded direct to your own
                support email address.
              </p>
            </>
          ),
        },
        {
          label: "Activate Notifications",
          requiresConfirmation: true,
          confirmationLabel: "Yes, I have completed these steps",
          content: (
            <>
              <h2 className="secondary">
                2. Activate Email & SMS Notifications
              </h2>
              <br />
              <p>
                <strong>
                  BEFORE ACTIVATING THE CAMPAIGN, YOU MUST ENSURE YOU HAVE:
                </strong>
              </p>
              <p>
                Activated all email and SMS notifications in the 'Email & SMS
                Activation' section of the 'Email & SMS page' in 'Settings'. You
                will know they are activated as the toggle button is green and
                moved to the right. These are:
              </p>
              <ul>
                <li>
                  Post Vaccination Confirmation Emails
                  <br />
                  <em>(you can turn this off if you'd rather not send them)</em>
                </li>
                <li>Assign 'Clinic Admin' Role Emails</li>
                <li>Assign 'Vaccinator' Role Emails</li>
                <li>Enable 'Reporting Access' Role Emails</li>
                <li>Cancelled Clinic Emails & SMS</li>
                <li>Rescheduled Clinic Emails & SMS</li>
                <li>Reminders to Book Flu Appointment Emails</li>
                <li>Reminders to Book Covid Appointment Emails</li>
              </ul>
            </>
          ),
        },
        {
          label: "Pre-screening & Consent",
          requiresConfirmation: true,
          confirmationLabel: "Yes, I have completed these steps",
          content: (
            <>
              <h2 className="secondary">
                3. Pre-screening & Consent Statements
              </h2>
              <br />
              <p>
                <strong>
                  BEFORE ACTIVATING THE CAMPAIGN, YOU MUST ENSURE YOU HAVE:
                </strong>
              </p>
              <p>
                Set up your pre-screening and consent statements for flu and
                Covid.
              </p>
              <p>
                Make sure they are all <strong>statements</strong> that can be
                agreed to with a tick and <strong>NOT</strong> questions that
                require a YES or NO answer. For example:
              </p>
              <p>
                <strong>Correct format</strong>: I am NOT currently acutely
                unwell or have a temperature
              </p>
              <p>
                <strong>Incorrect format</strong>: Are you currently acutely
                unwell or have a temperature?
              </p>
              <p>
                Unless you think it is essential for clinical safety reasons,
                try to avoid including identical statements in the pre-screening
                and consent sections as this will mean staff are having to agree
                to the same statement twice during the booking process.
              </p>
            </>
          ),
        },
        {
          label: "Clinic Capacity",
          stepperLabel: (
            <>
              Clinic
              <br />
              Capacity
            </>
          ),
          requiresConfirmation: true,
          confirmationLabel: "Yes, I have completed these steps",
          content: (
            <>
              <h2 className="secondary">4. Clinic Capacity</h2>
              <br />
              <p>
                <strong>
                  BEFORE ACTIVATING THE CAMPAIGN, YOU MUST ENSURE YOU HAVE:
                </strong>
              </p>
              <p>
                Built sufficient clinic capacity for staff to book into. It is
                recommended you build at least 40% of the capacity required to
                vaccinate every staff member from the start of the campaign.
              </p>
              <p>
                If there is not enough clinic capacity or limited choice, staff
                will not be able to book. This is frustrating for them and will
                result in a lot of queries coming your way!
              </p>
              <p>
                If you are unable to build more capacity at the moment, consider
                inviting only a section of your staff at this stage.
              </p>
            </>
          ),
        },
        {
          label: "Final Checks",
          stepperLabel: (
            <>
              Final
              <br />
              Checks
            </>
          ),
          requiresConfirmation: true,
          confirmationLabel: "Yes, I have completed these steps",
          content: (
            <>
              <h2 className="secondary">5. Final Checks</h2>
              <br />
              <p>
                <strong>
                  BEFORE ACTIVATING THE CAMPAIGN, YOU MUST ENSURE YOU HAVE:
                </strong>
              </p>
              <ol>
                <li>
                  Set up custom organisations in the 'Self Registration' section
                  of 'Settings'. This is only if you are using the 'Self
                  Registration' form and you want staff to choose from a drop
                  down when entering the organisation.
                </li>
                <li>
                  Run pre-campaign comms within the Trust to make sure staff are
                  aware of the campaign and to expect an email with
                  VaccinationTrack and Trust branding, inviting them to register
                  / book.
                </li>
                <li>
                  Ensure the vaccinationtrack.com domain is whitelisted and that
                  all emails sent from a vaccinationtrack.com email addresses
                  are permitted by IT in your Trust.
                </li>
                <li>
                  Pre-added the vaccine batches available to the Trust during
                  the campaign in ‘Master Vaccine Batches’ in ‘Settings’.
                  Pre-adding means that vaccinators don’t need to manually add
                  batch details at the start of each clinic. If you don’t have
                  the vaccines yet, remember to add them prior to the clinics
                  starting.
                </li>
                <li>
                  Checked in with VaccinationTrack support for them to check
                  over everything.{" "}
                  <strong>
                    Even if you are an experienced user, this is still
                    essential.
                  </strong>
                </li>
              </ol>
            </>
          ),
        },
        {
          label: "Are you sure?",
          isHiddenFromStepper: true,
          submitLabel: "Yes, I'm 100% sure. Activate Campaign",
          content: (
            <h2 style={{ textAlign: "center" }}>
              Are you 100% sure you are ready to activate the campaign? This
              cannot be undone.
            </h2>
          ),
        },
      ];
    },
  },
  // Hidden visually, and enabled by default throughout
  // {
  //   name: "notifyBookingUpcoming",
  //   label: "Upcoming Appointment Reminders",
  //   description:
  //     "These emails are sent to remind staff of their upcoming appointments.",
  // },
];
