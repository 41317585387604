import React from "react";
import ReactDOM from "react-dom";
import { Pathogens } from "../../../../lib/pathogens";
import DataClient from "../../../../lib/services/api/DataClient";

export type AppointmentDetails = {
  id?: string;
  firstName?: string;
  lastName?: string;
  hospitalSiteName?: string;
  vaccinationClinic?: string;
  date?: Date;
  timeSlot?: string;
  mobileNumber?: string;
  vaccinationStatus?: string;
  attendanceStatusId?: number;
  vaccinationStatusFlu: string;
  vaccinationStatusCovid: string;
  bookingDate?: string;
  clinicDate?: string;
  bookingTime?: string;
  timeDuration?: string;
  clinicLocationName?: string;
  locationName?: string;
  attendanceStatusName?: string;
  pathogens?: {
    id: Pathogens;
    name: string;
    description: string;
  }[];
};

export type StatusDetails = {
  declineDescription?: string;
  declineReasonId?: number;
  declineReasonName?: string;
  hadElsewhereDate?: Date;
  hadElsewhereLocation?: string;
  hadElsewhereSite?: string;
  hadElsewhereVaccinationBatchNumber?: string;
  hadElsewhereVaccinationTypeId?: string;
  hadElsewhereVaccinationTypeName?: string;
  pathogenId: Pathogens;
  patientVaccination?: any;
  sourceStatusId: number;
  vaccinationStatusId: number;
  vaccinationStatusName: string;
};

export type Appointment = {
  pathogens: Pathogens[];
  details: AppointmentDetails;
  status: StatusDetails;
};

export interface CampaignPathogenStatus {
  consentStatus?: boolean;
  declineDescription?: string;
  declineReasonId?: string;
  declineReasonName?: string;
  hadElsewhereDate?: string;
  hadElsewhereLocation?: string;
  hadElsewhereSite?: string;
  hadElsewhereVaccinationBatchNumber?: string;
  hadElsewhereVaccinationTypeId?: number;
  hadElsewhereVaccinationTypeName?: string;
  pathogen: { id: number; name: string };
  pathogenId: number;
  patientCampaignId?: number;
  patientVaccination?: {
    adverseReaction?: string;
    allergyStatus?: string;
    consentAlreadyGiven: boolean;
    consentQuestionAnswers?: ConsentQuestionAnswer[];
    consentToVaccination: boolean;
    declineReasonId?: number;
    details?: any;
    exclusionQuestionAnswers?: ExclusionQuestionAnswer[];
    expiryMonth?: any;
    expiryYear?: any;
    hadElsewhereLocation?: any;
    hadElsewhereSite?: any;
    id: number;
    injectionSiteId?: number;
    injectionSiteName?: any;
    isFrontLine: boolean;
    nhsNumber?: any;
    otherInformation?: any;
    patientCampaignId: number;
    patientCampaignPathogenStatusId: number;
    vaccinationBatchNumber?: string;
    vaccinationDate?: string;
    vaccinationStatusId: number;
    vaccinationStatusName?: string;
    vaccinationTime?: string;
    vaccinationTypeId: number;
    vaccinationTypeName?: string;
    vaccinatorName?: any;
  };
  sourceStatusId?: any;
  vaccinationStatusId?: number;
  vaccinationStatusName?: string;
}

const FluBooking = JSON.stringify([Pathogens.Flu]);
const CovidBooking = JSON.stringify([Pathogens.Covid]);
const CombinedBooking = JSON.stringify([Pathogens.Flu, Pathogens.Covid]);

export type BookingStatus = { [x: string]: number | null };

export default function useAppointments(patientCampaignId: number) {
  const [loading, setLoading] = React.useState(true);
  const [errors, setErrors] = React.useState<string[]>([]);
  const [bookings, setBookings] = React.useState<BookingStatus>({
    [CombinedBooking]: null,
    [FluBooking]: null,
    [CovidBooking]: null,
  });
  const [results, setResults] = React.useState<Appointment[]>([]);
  const [campaignPathogenStatuses, setCampaignPathogenStatuses] =
    React.useState<CampaignPathogenStatus[]>([]);
  const [retry, setRetry] = React.useState(0);

  React.useEffect(() => {
    if (patientCampaignId) {
      // DataClient.getData(
      //   `/Reservation/MyAppointments/${patientCampaignId}`
      // ).then(({ success, results }) => {
      //   console.log(results);
      // });

      DataClient.getData(`/Reservation/MyAppointments/${patientCampaignId}`)
        .then(({ success, results }) => {
          // console.log({ results });
          const reservations: AppointmentDetails[] = results.reservations;
          const pathogenStatuses: StatusDetails[] =
            results.campaignPathogenStatuses;
          const trustWorkerPathogens: Pathogens[] =
            results.trustWorkerPathogens;
          const combined =
            trustWorkerPathogens.length === 2
              ? [[Pathogens.Flu, Pathogens.Covid]]
              : [];
          const appointmentPathogens = [
            ...combined,
            ...trustWorkerPathogens.map((p) => [p]),
          ];
          // console.log({ combined, appointmentPathogens });
          const hasBookingData =
            reservations?.length + pathogenStatuses?.length >= 1;
          if (success && hasBookingData) {
            ReactDOM.unstable_batchedUpdates(() => {
              setLoading(false);
              setBookings({
                [CombinedBooking]:
                  reservations.find(
                    (r) =>
                      JSON.stringify(r.pathogens.map((p) => p.id)) ===
                      CombinedBooking
                  )?.attendanceStatusId ?? null,
                [FluBooking]: pathogenStatuses.find(
                  (s) => s.pathogenId === Pathogens.Flu
                )?.vaccinationStatusId,
                [CovidBooking]: pathogenStatuses.find(
                  (s) => s.pathogenId === Pathogens.Covid
                )?.vaccinationStatusId,
              });
              setResults(
                appointmentPathogens.map((p) => ({
                  pathogens: p,
                  details: reservations.find(
                    (r) =>
                      JSON.stringify(r.pathogens.map((p) => p.id)) ===
                      JSON.stringify(p)
                  ),
                  status: pathogenStatuses.find((s) =>
                    p.every((pathogen) => pathogen === s.pathogenId)
                  ),
                }))
              );
              setCampaignPathogenStatuses(
                results.campaignPathogenStatuses || null
              );
            });
          } else {
            ReactDOM.unstable_batchedUpdates(() => {
              setLoading(false);
              setResults([]);
              setBookings({
                [CombinedBooking]: null,
                [FluBooking]: null,
                [CovidBooking]: null,
              });
            });
          }
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
          setErrors((errs) => [
            ...errs,
            "Could not load appointments data. Please refresh.",
          ]);
        });
    } else {
      console.error("No patientCampaignId");
      setLoading(false);
    }
  }, [patientCampaignId, retry, setLoading]);
  return {
    bookings,
    results,
    campaignPathogenStatuses,
    loading,
    errors,
    reload: () => {
      setRetry((v) => v + 1);
    },
    clear: () => {
      setBookings({
        [CombinedBooking]: null,
        [FluBooking]: null,
        [CovidBooking]: null,
      });
      setResults(null);
    },
  };
}
