import React from "react";
import DataClient from "../../../../lib/services/api/DataClient";
import { TrustAdminData } from "./useTrustAdminList";

export default function useCreateTrustAdmin() {
  const [success, setSuccess] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean | string[]>(false);
  const [warning, setWarning] = React.useState<boolean | string[]>(false);

  const callback = (admin: TrustAdminData) => {
    setError(false);
    setSuccess(false);
    setWarning(false);
    DataClient.postData(`/TrustAdmin`, {
      firstName: admin.firstName,
      lastName: admin.lastName,
      emailAddress: admin.emailAddress,
      jobTitle: admin.jobTitle,
      dateOfBirth: admin.dateOfBirth,
      mobileNumber: admin.mobileNumber,
      trustId: admin.trust.id,
      proceedIfWarnings: admin.proceedIfWarnings,
      pathogens: admin.pathogens,
    })
      .then((res: unknown) => {
        if (res["success"]) {
          setSuccess(true);
        } else {
          console.log(res);
          throw new Error("API Creation failed");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 422) {
          setWarning(error.response?.data?.results);
        } else if (error?.response?.status === 409) {
          setError(
            error.response?.data?.summary ||
              error.response?.data?.results[0] ||
              error.response?.data?.errors
          );
        } else {
          console.error(error);
          setError(
            error.response?.data?.summary ||
              error.response?.data?.results[0] ||
              error.response?.data?.errors ||
              true
          );
        }
      });
  };

  return [callback, success, error, warning] as const;
}
