import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import DataClient from "../../../../../../lib/services/api/DataClient";

interface Base {
  id: number;
  campaignId: number;
  staffTypeId: number;
  staffTypeName: string;
}

interface FrontlineRole extends Base {
  isFrontLineByDefault?: boolean;
  isFrontLineOverride: boolean;
  isFrontLine?: boolean;
}

interface ImmFormRole extends Base {
  isImmFormByDefault?: boolean;
  isImmFormOverride: boolean;
  isImmForm?: boolean;
}

export interface Role extends Base {
  current: boolean;
  originalOverride: boolean;
}

const frontlineToRole = (model: FrontlineRole): Role => ({
  id: model.id,
  campaignId: model.campaignId,
  staffTypeId: model.staffTypeId,
  staffTypeName: model.staffTypeName,
  current: model.isFrontLineOverride ?? model.isFrontLine,
  originalOverride: model.isFrontLineOverride ?? model.isFrontLine,
});

const frontlineFromRole = (role: Role): FrontlineRole => ({
  id: role.id,
  campaignId: role.campaignId,
  staffTypeId: role.staffTypeId,
  staffTypeName: role.staffTypeName,
  isFrontLineOverride: role.current,
});

const immFormToRole = (model: ImmFormRole): Role => ({
  id: model.id,
  campaignId: model.campaignId,
  staffTypeId: model.staffTypeId,
  staffTypeName: model.staffTypeName,
  current: model.isImmFormOverride ?? model.isImmForm,
  originalOverride: model.isImmFormOverride ?? model.isImmForm,
});

const immFormFromRole = (role: Role): ImmFormRole => ({
  id: role.id,
  campaignId: role.campaignId,
  staffTypeId: role.staffTypeId,
  staffTypeName: role.staffTypeName,
  isImmFormOverride: role.current,
});

/**
 *
 * @param baseUrl One of the endpoints for NonEsrStafftypes
 * @param setFeedback Standard set state function for the Toast messages
 * @returns { groups, loading, hasChanges, handleGroupChange, handleRoleChange, handleSaveChanges }
 */

export default function useStaffGroups(
  baseUrl:
    | "/CampaignImmFormNonEsrStaffType"
    | "/CampaignFrontLineNonEsrStaffType",
  setFeedback: (value: { type: string; message: string }) => void
) {
  const { campaignId } = useParams<{ campaignId: string }>();
  const toRole =
    baseUrl === "/CampaignImmFormNonEsrStaffType"
      ? immFormToRole
      : frontlineToRole;
  const api = useMemo(
    () => ({
      list: (campaignId: number) =>
        DataClient.postData(`${baseUrl}/Inherited`, {
          pageSize: -1,
          campaignId,
        }).then((res: any) => {
          return res.results.map(toRole);
        }),
    }),
    [baseUrl, toRole]
  );

  const [roles, setRolesGeneric] = useState<Role[]>(null);

  const fetchData = React.useCallback(() => {
    api
      .list(parseInt(campaignId))
      .then((roles) => {
        setRolesGeneric(roles);
      })
      .catch((e) => {
        if (!e.handled) {
          setFeedback({
            type: "error",
            message: "Error fetching Non ESR roles.",
          });
        }
      });
  }, [api, campaignId, setFeedback]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const changes: Role[] = useMemo(() => {
    const update = roles
      ? roles.filter((r) => r.originalOverride !== r.current)
      : [];
    return update;
  }, [roles]);

  const hasChanges = changes.length !== 0;

  const handleRoleChange = (role: Role, isChecked: boolean) => {
    setRolesGeneric((list) => {
      const index = list.findIndex((r) => r.staffTypeId === role.staffTypeId);
      const update = [...list];
      update[index] = {
        ...update[index],
        current: isChecked,
      };
      return update;
    });
  };

  return {
    roles,
    hasChanges,
    handleRoleChange,
    fetchData,
  };
}
