import React from "react";
import DataClient from "../../../../lib/services/api/DataClient";
import useDataClient from "../useDataClient";

export type TrustGroupUser = {
  id: number;
  firstName: string;
  lastName: string;
  emailAddress: string;
  trustGroupName: string;
};

export type TrustGroupUserData = {
  id?: number;
  firstName: string;
  lastName: string;
  emailAddress: string;
  trustGroup: {
    id: number;
    name?: string;
    trusts?: TrustGroupUserTrust[]
  };
  proceedIfWarnings: boolean;
};

export interface TrustGroupUserTrust {
  id: number;
}

type TrustGroupUserResponse = {
  success: boolean;
  results: {
    totalCount: number;

    pagedResults: TrustGroupUserData[];
  };
};

const isTrustGroupUserResponse = (res: unknown): res is TrustGroupUserResponse => {
  return (
    res["success"] &&
    res["results"] &&
    //res["results"]["totalCount"] &&
    res["results"]["pagedResults"] &&
    Array.isArray(res["results"]["pagedResults"])
  );
};

export type FilterProps = {
  pageNumber: number;
  pageSize: number;
};

export default function useTrustGroupUserList(
  defaultPage = 1,
  defaultSize = 10
) {
  const [searchText, setSearchText] = React.useState("");
  const [pageNumber, setPageNumber] = React.useState(defaultPage);
  const [pageSize, setPageSize] = React.useState(defaultSize);
  const postDataArgs = React.useMemo(() => {
    return [
      "/TrustGroupUser/Search",
      {
        searchText: searchText || "",
        pageNumber,
        pageSize,
      },
    ];
  }, [searchText, pageNumber, pageSize]);
  const hookOptions = React.useMemo(
    () => ({
      formatter: (data: TrustGroupUserResponse) => {
        if (!data.success) {
          throw new Error("API call was a failure");
        }
        return {
          totalCount: data.results.totalCount,
          results: data.results.pagedResults.map(
            (d: TrustGroupUserData): TrustGroupUser => ({
              id: d.id,
              firstName: d.firstName,
              lastName: d.lastName,
              emailAddress: d.emailAddress,
              trustGroupName: d.trustGroup?.name,
            })
          ),
        };
      },
      guard: isTrustGroupUserResponse,
    }),
    []
  );
  const userList = useDataClient<
    { totalCount: number; results: TrustGroupUser[] },
    TrustGroupUserResponse
  >(DataClient.postData, postDataArgs, hookOptions);
  return [...userList, setSearchText, setPageNumber, setPageSize] as const;
}
