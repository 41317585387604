import React from "react";
import DataClient from "../../../../lib/services/api/DataClient";
import useDataClient from "../useDataClient";
import { Trust, TrustData } from "./useTrustList";
import { Pathogens } from "../../../../lib/pathogens";

type TrustResponse = {
  success: boolean;
  results: TrustData;
};

const isTrustResponse = (res: unknown): res is TrustResponse => {
  return res["success"] && res["results"];
};

export default function useTrust(id: number | null) {
  const getDataArgs = React.useMemo(() => (id ? [`/Trust/${id}`] : []), [id]);
  const hookOptions = React.useMemo(
    () => ({
      formatter: ({
        success,
        results: { pathogens, ...rest },
      }: TrustResponse) => {
        if (!success) {
          throw new Error("API call was a failure");
        }
        const trust: Trust = {
          ...rest,
          pathogens: pathogens.map(
            (p: { id: number; name: string }) => Pathogens[p.name]
          ),
        };
        return trust;
      },
      guard: isTrustResponse,
    }),
    []
  );
  return useDataClient<Trust, TrustResponse>(
    DataClient.getData,
    getDataArgs,
    hookOptions
  );
}
