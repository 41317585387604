import DataClient from "../../../../../lib/services/api/DataClient";
import { catchFormErrors } from "../../../../../lib/shared/api_client";

const fromCampaign = (campaign: Campaign) => ({
  ...campaign,
  notifyReschedule: campaign.notify,
});

const api = {
  list: () =>
    DataClient.getData("/campaign").then((res) => res.results as Campaign[]),
  add: (item: Campaign) =>
    DataClient.postData("/campaign", fromCampaign(item)).catch(catchFormErrors),
  update: (item: Campaign) =>
    DataClient.update(`/campaign/${item.id}`, fromCampaign(item)).catch(
      catchFormErrors
    ),
};

export default api;
