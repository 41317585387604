import { Location } from "history";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import authService from "../../../../components/api-authorization/AuthorizeService";
import ToastMessage from "../../../../components/Universal/toast_message";
import {
  getTrusts,
  getWorkerPathogens,
  setCurrentTrust,
  setSelectedTrust,
  getTrustWorkerId,
} from "../../../../lib/services/TrustSwitcher";
import styles from "./Welcome.module.scss";
import StyledButton from "../MyAppointments/Appointment/StyledButton";
import { LinearProgress } from "@material-ui/core";
// import DeclineDialog from "../MyAppointments/DeclineDialog";
import { HewOrDecline } from "../../../../components/HewOrDecline";
import { Pathogens } from "../../../../lib/pathogens";
import DataClient from "../../../../lib/services/api/DataClient";
import { enumVaccinationStatus } from "../../../../lib/enums";
import TrustData from "../../../../lib/types/TrustData";
const pathogenTitles = {
  [Pathogens.Flu]: "Flu",
  [Pathogens.Covid]: "Covid Booster",
};
const pathogenVaccines = {
  [Pathogens.Flu]: "flu",
  [Pathogens.Covid]: "Covid booster",
};
const pathogenButtons = {
  [Pathogens.Flu]: "flu vaccination",
  [Pathogens.Covid]: "Covid booster",
};
export default function Welcome() {
  const [isAuthenticated, setAuthenticated] = React.useState(false);
  const [feedback, setFeedback] = React.useState<FeedbackMessage>(null);
  const { search } = useLocation<Location>();
  const trustId = Number(getURLParam(search, "trustId"));
  const verifyAccount = getURLParam(search, "verifyAccount");
  const [loading, setLoading] = useState(false);
  const [myAppointments, setMyAppointments] = useState(null);
  const [selectedPathogen, setSelectedPathogen] = useState<Pathogens>(null);
  const [pathogens, setPathogens] = useState<Pathogens[]>([]);
  const [trust, setTrust] = useState<TrustData>(null);
  const [pathogensVisibleReIssue225, setPathogensVisibleReIssue225] =
    useState(null);
  const [declined, setDeclined] = React.useState<Pathogens[]>([]);

  const [trustWorkerId, setTrustWorkerId] = useState(null);

  React.useMemo(() => {
    async function login() {
      setLoading(true);

      const result = await authService.signIn({});

      // switch trust
      const t = getTrusts().find((t) => t.trustId === trustId);
      if (t) {
        setTrust(t);
        setSelectedTrust(t.trustId);
        setCurrentTrust(t);
        setPathogens(getWorkerPathogens() ?? []);
        setTrustWorkerId(getTrustWorkerId());
      }
      if (result.status === "success") {
        setAuthenticated(true);
        const patientCampaignId = t?.patientCampaignId || "";
        DataClient.getData(`/Reservation/MyAppointments/${patientCampaignId}`)
          .then(({ success, results }) => {
            if (success && results) {
              setMyAppointments(results);
            }
            setLoading(false);
          })
          .catch((e) => {
            console.error(e);
            setFeedback({
              type: "error",
              message: e?.response?.data,
            });
            setLoading(false);
          });
      } else {
        setFeedback({
          type: "error",
          message: "You must be logged in.",
        });
        setLoading(false);
      }
    }
    login();
  }, []);

  React.useEffect(() => {
    setPathogensVisibleReIssue225(
      pathogens?.filter(
        (p) =>
          ![
            enumVaccinationStatus["Booked"],
            enumVaccinationStatus["Vaccinated"],
            enumVaccinationStatus["Had Elsewhere"],
            enumVaccinationStatus["Declined"],
            enumVaccinationStatus["Exempt"],
            enumVaccinationStatus["Had In Another Trust"],
            enumVaccinationStatus["Declined (allergy)"],
            enumVaccinationStatus["Declined (other contraindication)"],
            enumVaccinationStatus["Declined (doesn't want a vaccine)"],
          ].includes(myAppointments?.[`vaccinationStatusId${Pathogens[p]}`])
      ) || []
    );
  }, [pathogens, myAppointments]);
  React.useEffect(() => {
    if (!trustId) {
      setFeedback({
        type: "error",
        message:
          "This link is invalid. You will not be able to submit this form.",
      });
    }
  }, [trustId]);
  const isMultiplePathogens = pathogens.length > 1;
  return (
    <>
      {loading ? <LinearProgress color="primary" /> : null}
      <main className={`wrapper-main`}>
        <h1>Record a 'Had Elsewhere' or Decline a Vaccination</h1>
        {isAuthenticated && (
          <>
            <div className={styles.pathogens}>
              {pathogensVisibleReIssue225.length ? (
                pathogensVisibleReIssue225.map((p: number) => (
                  <div
                    key={`pathogen-${p}`}
                    className={`${styles.pathogen} ${
                      styles[`${Pathogens[p].toLowerCase()}Pathogen`]
                    }`}
                  >
                    <div className={styles.title}>
                      <h2>{pathogenTitles[p]}</h2>
                    </div>
                    <div className={styles.card}>
                      <div className={styles.body}>
                        <p>Let us know if you've:</p>
                        <ul>
                          <li>Already had it elsewhere</li>
                          <li>Can’t have it for medical reasons</li>
                          <li>Do not want the vaccine</li>
                        </ul>
                        {isMultiplePathogens ? (
                          <p style={{ margin: 0 }}>
                            Please note you must decline each vaccine
                            separately. Or you can decline one and book the
                            other. To book you must verify your account.{" "}
                          </p>
                        ) : (
                          <p style={{ margin: 0 }}>
                            To book a {pathogenVaccines[p]} vaccination you must
                            verify your account.
                          </p>
                        )}
                      </div>
                      <div className={styles.actions}>
                        {declined.some((d) => d === p) ? (
                          <p>
                            <strong>Decline successfully recorded</strong>
                          </p>
                        ) : (
                          <StyledButton
                            key={p}
                            onClick={() => setSelectedPathogen(p)}
                            color={Pathogens[p].toLowerCase()}
                          >
                            Decline / already had {pathogenButtons[p]}
                          </StyledButton>
                        )}

                        <HewOrDecline
                          {...{
                            value: {
                              id: trustWorkerId,
                            },
                            pathogen: selectedPathogen,
                            open: selectedPathogen === p,
                            onSuccess: () => {
                              setSelectedPathogen(null);
                              setDeclined((declines) => [...declines, p]);
                              setFeedback({
                                type: "success",
                                message: `Successfully declined ${pathogenButtons[p]}`,
                              });
                            },
                            onClose: () => {
                              setSelectedPathogen(null);
                            },
                            onError: () => {
                              setFeedback({
                                type: "error",
                                message: `Error declining ${pathogenButtons[p]}`,
                              });
                            },
                            title: `Let us know why you don't need to book a  ${Pathogens[selectedPathogen]} appointment`,
                            showStaffVaccinationSites: false,
                          }}
                        />
                      </div>
                    </div>
                    {verifyAccount && (
                      <div
                        style={{ textAlign: "center", paddingBottom: "16px" }}
                      >
                        <a style={{ color: "black" }} href={verifyAccount}>
                          Verify Account &amp; Book {pathogenButtons[p]}
                        </a>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div>
                  <p>
                    You do not currently have any vaccinations to record as 'had
                    elsewhere' or decline.
                  </p>
                  <p>This is because:</p>
                  <ul>
                    <li>
                      You have already recorded your 'had elsewhere' or decline
                    </li>
                    <li>You have booked your appointment(s)</li>
                    <li>You have already been vaccinated</li>
                  </ul>
                  <p>
                    To undo a 'had elsewhere' or decline,{" "}
                    <Link to="/my-appointments">click here</Link> to login to
                    the 'My Appointments' page.
                  </p>
                </div>
              )}
            </div>
            {feedback && (
              <ToastMessage
                variant={feedback.type}
                message={feedback.message}
                clear={() => {
                  setFeedback(null);
                }}
              />
            )}
          </>
        )}
      </main>
    </>
  );
}
type FeedbackMessage = {
  type: "success" | "error";
  message: string;
};
function getURLParam(search: string, key: string) {
  const urlSearchParams = new URLSearchParams(search);
  const params = Object.fromEntries(urlSearchParams.entries());
  return params[key];
}
