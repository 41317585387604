//
// This represents the view during the login/out process, NOT the login page - that's Layout.cshtml which shares the same .css files
//
import { Button, CircularProgress } from "@material-ui/core";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
export default ({
  message,
  showHomeButton,
  showLoadingSpinner,
}: {
  message: string | JSX.Element;
  showHomeButton?: boolean;
  showLoadingSpinner?: boolean;
}) => (
  <>
    <Helmet>
      {/* Loading wwwroot styles and logo, a la Layout.cshtml for the login page */}
      <link rel="stylesheet" href="/css/auth-reset.css" />
      <link rel="stylesheet" href="/css/auth-form.css" />
      <link rel="stylesheet" href="/css/auth-app.css" />
    </Helmet>
    {/* Hide until Helmet loads CSS */}
    <main style={{ display: "none" }} className="auth-page">
     {/* Wrapping <header> element broke in IE11 so I removed it */}
        <a href="/" className="logo-header">
          <img src="/img/logo-vaccinationtrack.svg" alt="VaccinationTrack" />
        </a>

      <div className="container-center">
        {message}
        {showHomeButton && (
          <p>
            <Button to="/" color="primary" variant="contained" component={Link}>
              Back to Log In
            </Button>
          </p>
        )}
        {showLoadingSpinner && <CircularProgress />}
      </div>
    </main>
  </>
);
