import { LinearProgress } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router-dom";
import ToastMessage from "../../../../components/Universal/toast_message";
import useTrustAdmin from "./useTrustAdmin";
import NavContainer from "../NavContainer";
import TrustAdminForm from "./TrustAdminForm";

export default function TrustAdminCreateUpdate() {
  const { id } = useParams<{ id: string }>();
  const [feedback, setFeedback] =
    React.useState<{ type: "success" | "error"; message: string }>(null);
  const [data, loading, error] = useTrustAdmin(
    id === "new" ? null : Number(id)
  );
  React.useEffect(() => {
    if (error) {
      setFeedback({
        type: "error",
        message: "There was a problem loading the Trust Admin.",
      });
    }
  }, [error]);
  const isUpdate = id !== "new";

  return (
    <>
      {loading && <LinearProgress color="primary" />}
      <NavContainer>
        <TrustAdminForm
          setFeedback={setFeedback}
          isUpdate={isUpdate}
          id={Number(id)}
          formData={data ?? null}
        />
        {feedback && (
          <ToastMessage
            variant={feedback.type}
            message={feedback.message}
            clear={() => {
              setFeedback(null);
            }}
          />
        )}
      </NavContainer>
    </>
  );
}
