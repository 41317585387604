export function validateSmsBody(
  messageBody: string,
  allowedTags: string[]
): string | boolean {
  // Regular expression to find all occurrences of text within double curly braces
  const tagRegex = /{{(.*?)}}/g;

  // Check for any invalid tags
  let match: RegExpExecArray | null;
  while ((match = tagRegex.exec(messageBody)) !== null) {
    if (!allowedTags.includes(match[0])) {
      return `The tag: ${match[0]} is not an allowed tag`;
    }
  }

  // Replace all valid tags with a placeholder
  const processedMessage = messageBody.replace(tagRegex, (match) => {
    if (allowedTags.includes(match)) {
      return "__VALID_TAG__";
    }
    return match;
  });

  // Check for any remaining curly braces in the processed message
  if (/{|}/.test(processedMessage)) {
    return "The symbols { and } are only allowed as part of a tag. Tags must start with {{ and end with }}";
  }

  // If everything is fine, return true
  return true;
}

/*
please help me write a validator function in typescript.
The function accepts 2 arguements: 
1. messageBody: A string of text from a text-area input.
2. allowedTags: An array of allowed tags that are allowed to appear in the messageBody.

example allowedTags: [
{{Date}},
{{Time}}
]

The validator function should check the messageBody for curly braces.
The curly braces are only allowed if they are part of one of the allowed tags.

return values:
 - If the messageBody contains a curly brace that is not part of an allowedTag, then please return a string that says " The symbols { and } are only allowed as part of a tag"
 - If the message contains a sub-string that looks like a tag (is wrapped in double curly braces) but is not part of the allowedTags list,  return a string that says "the tag : <sub-string> is not an allowed tag"
 - If the messageBody is valid or an empty string: return true.
*/
