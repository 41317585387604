import { Button, Dialog, DialogTitle } from "@material-ui/core";
import api from "../../TrustAdmin/Staff/data";
import { Pathogens } from "../../../../lib/pathogens";
import StyledButton from "./Appointment/StyledButton";

type DeclineDialogProps = {
  open: boolean;
  onClose: (...args: any[]) => void;
  onSuccess: (data: any) => void;
  onError: (error: Error) => void;
  value: { id: number };
  pathogen: Pathogens;
};

function UndoDeclineDialog({
  open,
  onClose,
  onSuccess,
  onError,
  value,
  pathogen,
}: DeclineDialogProps) {
  const { id } = value;

  const handleSubmit = (event: any) => {
    event.preventDefault();

    api.staff.undoDecline(id, pathogen).then(onSuccess).catch(onError);
  };

  const pathogenMap = {
    1: "flu vaccination",
    2: "Covid booster",
  };

  const titleBackground = {
    [Pathogens.Flu]: "#169bd8",
    [Pathogens.Covid]: "#c80089",
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        style={{ backgroundColor: titleBackground[pathogen] }}
        disableTypography
      >
        <h2 style={{ color: "white" }}>
          Undo decline / had elsewhere for {Pathogens[pathogen]}
        </h2>
      </DialogTitle>
      <div
        style={{
          minHeight: "16rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: "16px 24px",
        }}
      >
        This will allow you to book your {pathogenMap[pathogen]} vaccination in
        the Trust.
        <div style={{ marginTop: "16px" }}>
          <StyledButton
            form="decline"
            onClick={handleSubmit}
            color={`${Pathogens[pathogen]}`.toLowerCase()}
            autoFocus
            style={{ marginRight: "8px" }}
          >
            Submit
          </StyledButton>
          <Button onClick={onClose} variant="outlined">
            Cancel
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default UndoDeclineDialog;
