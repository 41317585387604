import { createContext, useEffect, useState } from "react";
import { Pathogens } from "../../../../lib/pathogens";
import {
  findPatientCampaignIdInMe,
  getCurrentTrust,
  getMe,
  getPatientCampaignId,
  getPatientCampaignIdFromMe,
  getTrusts,
} from "../../../../lib/services/TrustSwitcher";
import useAppointments, {
  AppointmentDetails,
  CampaignPathogenStatus,
  StatusDetails,
} from "../BookAppointment/useAppointments";
import styles from "./MyAppointments.module.scss";
import Appointment from "./Appointment/Appointment";
import { HewOrDecline } from "../../../../components/HewOrDecline";
import UndoDeclineDialog from "./UndoDeclineDialog";
import ToastMessage from "../../../../components/Universal/toast_message";
import { getSites } from "../BookAppointment/AppointmentDetails/stateMachine";
import { CircularProgress } from "@material-ui/core";
import {
  appointmentisBookedABNVDNAForThisPathogen,
  appointmentIsVaccinatedForAnotherPathogen,
  enumVaccinationStatus,
} from "../../../../lib/enums";
import ConfirmDialog from "../../ConfirmDialog";
import {
  alertNoActiveCampaign,
  alertTrustAdminNotTrustWorker,
} from "../../../../components/AlertMessaging";
import { VaccinationHistory } from "../../../../components/VaccinationHistory";
import { humanNameFromObject } from "../../../../lib/common_utils";
import { sortAppointments } from "./sortMyAppointments";
type OpenDialog = "decline" | "undoDecline" | "successfullyRecorded" | null;
export const DeclineDialogOpenContext = createContext(
  (openDialog: OpenDialog) => {
    return;
  }
);
export const DeclineDialogPathogensContext = createContext(
  (pathogen: Pathogens) => {
    return;
  }
);
export const DeclineDialogReasonContext = createContext((reason: number) => {
  return;
});
export type MyAppointment = {
  canBook: boolean;
  cantBookReasons: string[];
  canCancel: boolean;
  canDecline: boolean;
  canReschedule: boolean;
  combinedParts?: any; // P2.4
  details?: AppointmentDetails;
  hasAnyDecline: boolean;
  hasASeparateCombinedBooking?: boolean;
  hasBooking: boolean;
  hasDeclined: boolean;
  isBookedABNVDNA?: boolean; // P2.4
  isBookedABNVDNAForThisPathogenInSeparateCombinedBooking?: boolean; // P2.4
  isClinicAvailable: boolean;
  isCombined?: boolean;
  isCombinedFeasible?: boolean;
  isSinglePathogen: boolean;
  isSinglePathogenTrust: boolean;
  isVaccinated: boolean;
  isVaccinatedForAnotherPathogen?: boolean; // P2.4
  isVaccinatedForAnotherPathogenInSeparateCombinedBooking?: boolean; // P2.4
  lockedByOtherTrust?: boolean;
  pathogens: Pathogens[];
  separateCombinedBooking?: any;
  status: StatusDetails | CampaignPathogenStatus;
};
export const MeContext = createContext({ me: null });
export default function MyAppointments(): JSX.Element {
  const hasMultipleTrusts = getTrusts()?.length > 1;
  const currentTrust = getCurrentTrust();
  // We can't rewrite this to use getPatientCampaignIdFromMe as useAppointments was written to be wholly reliant on it being a fixed value available immediately.
  // I mean this is what you get for using nested effects and unstable_batchedUpdates!
  const patientCampaignId = getPatientCampaignId();
  const hasActiveCampaign =
    patientCampaignId !== undefined && patientCampaignId !== null;
  const {
    results,
    campaignPathogenStatuses,
    loading: appointmentsLoading,
    errors: appointmentErrors,
    reload,
  } = useAppointments(patientCampaignId);
  const [feedback, setFeedback] = useState<{
    type: "success" | "error";
    message: string;
  }>(null);
  const [appointments, setAppointments] = useState([] as MyAppointment[]);
  const [openDialog, setOpenDialog] = useState<OpenDialog>(null);
  const [loading, setLoading] = useState(true);
  const [dialogPathogens, setDialogPathogens] = useState<Pathogens>(null);
  const [dialogReason, setDialogReason] = useState<number>(null);
  const isSinglePathogenTrust = results?.length === 1;
  const [alert, setAlert] = useState(null);
  const [me, setMe] = useState(null);
  useEffect(() => {
    (async () => {
      // Happy if you're personally ON an active campaign.
      const me = await getMe();
      setMe(me);
      const patientCampaignId = findPatientCampaignIdInMe(me);
      // If for some reason you are a TA but not a TW/V/CA, you should receive a specific message. Daft logic duplicated in Book appointments component.
      if (
        me.currentTrust.roles.includes("TrustAdmin") &&
        !(
          me.currentTrust.roles.includes("TrustWorker") ||
          me.currentTrust.roles.includes("Vaccinator") ||
          me.currentTrust.roles.includes("ClinicAdmin")
        )
      ) {
        setAlert(alertTrustAdminNotTrustWorker);
      } else if (!patientCampaignId) {
        setAlert(alertNoActiveCampaign({ involvesBookings: true }));
      }
    })();
  }, [getPatientCampaignIdFromMe, setMe]);
  useEffect(() => {
    // Results might be [[1], [2], [1,2]] (flu, covid, combined)
    // Check to see if there are actually sites with clinics for those
    Promise.all(results.map((r) => getSites({ pathogens: r.pathogens })))
      .then((response: any[]) => {
        let myAppointments: MyAppointment[] = response
          .map((res: any) => Number(res?.results.length ?? 0))
          .map((clinics, index) => {
            const appointment = results[index];
            const declinedStatuses = [
              enumVaccinationStatus["Had Elsewhere"],
              enumVaccinationStatus.Declined,
              enumVaccinationStatus.Exempt,
            ];
            const hasDeclined = declinedStatuses.includes(
              appointment.status?.vaccinationStatusId
            );
            const isVaccinated = appointment.pathogens.every(
              (p) =>
                results.find(
                  (r) => r.pathogens.length === 1 && r.pathogens[0] === p
                ).status?.vaccinationStatusId === 2
            );
            // const hasAnyActiveAppointment = appointment.pathogens.some((p) =>
            //   results
            //     .filter((r) => r.pathogens.length === 1)
            //     .some((b) =>
            //       b.pathogens.some(
            //         (p2) =>
            //           p2 === p && appointment.status?.vaccinationStatusId === 1
            //       )
            //     )
            // );
            const hasAnyActiveAppointment = appointment.pathogens.some((p) =>
              results.some((b) =>
                b.pathogens.some((p2) => p2 === p && b.details)
              )
            );
            const hasAnyDecline = appointment.pathogens.some((p) =>
              declinedStatuses.includes(
                results.find(
                  (r) => r.pathogens.length === 1 && r.pathogens[0] === p
                ).status?.vaccinationStatusId
              )
            );
            const isClinicAvailable = !!clinics;
            const hasBooking = !!appointment.details;
            const lockedByOtherTrust = appointment.pathogens.some((p) => {
              const status = results.find(
                (r) => r.pathogens.length === 1 && r.pathogens[0] === p
              ).status;
              if (!status) {
                return false;
              }
              const managedByOtherTrust = !!status.sourceStatusId;
              const bookedInOtherTrust =
                status.vaccinationStatusId ===
                  enumVaccinationStatus["Booked"] && managedByOtherTrust;
              const vaccinatedByOtherTrust =
                status.vaccinationStatusId ===
                enumVaccinationStatus["Had In Another Trust"];
              const lockedByOtherTrust =
                bookedInOtherTrust || vaccinatedByOtherTrust;
              return lockedByOtherTrust;
            });
            const isSinglePathogen = appointment.pathogens?.length === 1;
            // const canBook =
            //   !hasAnyDecline &&
            //   !isVaccinated &&
            //   !lockedByOtherTrust &&
            //   isClinicAvailable &&
            //   !hasAnyActiveAppointment;
            // Reasons are currently unused but will no doubt come in handy
            const canBookChecks = [
              {
                name: "hasAnyDecline",
                value: !hasAnyDecline,
                reason: "This staff member has declined that vaccine",
              },
              {
                name: "isVaccinated",
                value: !isVaccinated,
                reason: "This staff member is already vaccinated",
              },
              {
                name: "lockedByOtherTrust",
                value: !lockedByOtherTrust,
                reason:
                  "This staff member is booked or vaccinated in another trust",
              },
              {
                name: "isClinicAvailable",
                value: isClinicAvailable,
                reason: "This clinic is not available",
              },
              {
                name: "hasAnyActiveAppointment",
                value: !hasAnyActiveAppointment,
                reason: "This staff member already has an appointment",
              },
            ];
            const cantBookReasons = canBookChecks
              .filter((c) => !c.value)
              .map((c) => c.reason);
            const canBook = !cantBookReasons.length;
            const canDecline =
              !hasAnyDecline &&
              !isVaccinated &&
              !hasAnyActiveAppointment &&
              !lockedByOtherTrust;
            const canCancel =
              hasBooking && !isVaccinated && !lockedByOtherTrust;
            const canReschedule =
              hasBooking && !isVaccinated && !lockedByOtherTrust;
            return {
              ...appointment,
              canBook,
              cantBookReasons,
              canCancel,
              canDecline,
              canReschedule,
              hasAnyDecline,
              hasBooking,
              hasDeclined,
              isClinicAvailable,
              isSinglePathogen,
              isSinglePathogenTrust,
              isVaccinated,
              lockedByOtherTrust,
            };
          });
        // #dd-248 Combined clinics can not be booked if user is already vaccinated with other individual pathogens, across trusts.
        // That is to say, it's either a single pathogen appointment,
        // or multi but combined booking is feasible (all individual pathogens are canBook)
        // NB: Won't work if we add more pathogens / combination types (but neither will most of the site)
        // NB: This is a separate map as it considers the canBook status of appointments as a whole
        const appointmentsSingle: MyAppointment[] = myAppointments?.filter(
          (a) => a.pathogens?.length === 1
        );
        const appointmentCombined: MyAppointment = myAppointments?.find(
          (a) => a.pathogens?.length === 2
        );
        // Feasible if:
        // - combined appointment exists
        // - no single pathogen is declined, vaccinated or locked
        // Do allow single active appointments though, as they're not real
        const isCombinedFeasible = !!(
          appointmentCombined &&
          appointmentsSingle?.every(
            (a: any) =>
              !a.hasAnyDecline && !a.isVaccinated && !a.lockedByOtherTrust
          )
        );
        myAppointments.map((a: MyAppointment) => {
          const isCombined = !a.isSinglePathogen;
          a.isCombined = isCombined;
          if (isCombined) {
            // Modify canBook/canDecline/etc to prevent things when combined is not feasible
            a.isCombinedFeasible = isCombinedFeasible;
            a.canBook = a.canBook && isCombinedFeasible;
            a.canDecline = a.canDecline && isCombinedFeasible;
            a.canReschedule = a.canReschedule && isCombinedFeasible;
          } else if (appointmentCombined?.details) {
            // ┌(ಠ_ಠ)┘ P2 2.4
            a.hasASeparateCombinedBooking = true;
            a.separateCombinedBooking = appointmentCombined;
            a.isVaccinatedForAnotherPathogenInSeparateCombinedBooking =
              appointmentIsVaccinatedForAnotherPathogen({
                appointment: appointmentCombined,
                pathogenIds: a.pathogens,
              });
            a.isBookedABNVDNAForThisPathogenInSeparateCombinedBooking =
              appointmentisBookedABNVDNAForThisPathogen({
                appointment: appointmentCombined,
                pathogenId: a.pathogens?.[0],
              });
          }
          // console.log(
          //   "\nAppointment:",
          //   a.pathogens.map((p) => Pathogens[p]).join("/"),
          //   a
          // );
          return a;
        });
        myAppointments = sortAppointments(myAppointments);
        setAppointments(myAppointments);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [results, isSinglePathogenTrust]);

  return (
    <MeContext.Provider value={{ me }}>
      {(loading || appointmentsLoading) && (
        <div className="interstitial">
          <CircularProgress />
        </div>
      )}
      <main className={`wrapper-main`}>
        <h1>My Appointments</h1>
        <h3>{me != null ? humanNameFromObject(me?.currentTrust) : ""}</h3>
        {alert}
        {appointmentsLoading && appointmentErrors.length > 0 && (
          <ul>
            {appointmentErrors.map((error) => (
              <li>{error}</li>
            ))}
          </ul>
        )}
        <DeclineDialogOpenContext.Provider
          value={(dialog) => {
            setOpenDialog(dialog);
          }}
        >
          <DeclineDialogPathogensContext.Provider value={setDialogPathogens}>
            <DeclineDialogReasonContext.Provider value={setDialogReason}>
              {hasActiveCampaign && !appointmentsLoading && (
                <div className={styles.appointments}>
                  {appointments.length ? (
                    appointments.map((appointment) => {
                      return (
                        <Appointment
                          key={JSON.stringify(appointment.pathogens)}
                          appointment={appointment}
                          campaignPathogenStatuses={campaignPathogenStatuses}
                          reload={reload}
                        />
                      );
                    })
                  ) : !loading ? (
                    <p>
                      There are no clinics available right now. Please check
                      back later.
                    </p>
                  ) : null}
                </div>
              )}
            </DeclineDialogReasonContext.Provider>
          </DeclineDialogPathogensContext.Provider>
        </DeclineDialogOpenContext.Provider>
        <h2>Vaccination History</h2>
        <VaccinationHistory />
        {/* {openDialog === "decline" && (
          <DeclineDialog
            open={true}
            preselectReasonId={dialogReason}
            onClose={() => {
              setOpenDialog(null);
              setDialogReason(null);
            }}
            onSuccess={() => {
              // dd-391: If multiple trusts, show a modal about how status takes 30 seconds to update otherwise the original toast message
              if (hasMultipleTrusts) {
                setOpenDialog("successfullyRecorded");
              } else {
                setOpenDialog(null);
                setFeedback({
                  type: "success",
                  message: "Had Elsewhere or Decline recorded successfully.",
                });
              }
              reload();
            }}
            onError={(error: Error) => {
              setOpenDialog(null);
              setFeedback({ type: "error", message: error.message });
            }}
            value={{ id: currentTrust?.trustWorkerId }}
            pathogen={dialogPathogens}
          />
        )} */}
        {openDialog === "decline" && (
          <HewOrDecline
            open={true}
            title={`Let us know why you don't need to book a 
            ${Pathogens[dialogPathogens]} appointment`}
            preselectReasonId={dialogReason}
            onClose={() => {
              setOpenDialog(null);
              setDialogReason(null);
            }}
            onSuccess={() => {
              // dd-391: If multiple trusts, show a modal about how status takes 30 seconds to update otherwise the original toast message
              if (hasMultipleTrusts) {
                setOpenDialog("successfullyRecorded");
              } else {
                setOpenDialog(null);
                setFeedback({
                  type: "success",
                  message: "Had Elsewhere or Decline recorded successfully.",
                });
              }
              reload();
            }}
            onError={(error: Error) => {
              setOpenDialog(null);
              setFeedback({ type: "error", message: error.message });
            }}
            value={{ id: currentTrust?.trustWorkerId }}
            pathogen={dialogPathogens}
          />
        )}
        {openDialog === "successfullyRecorded" && (
          <ConfirmDialog
            title="Successfully recorded"
            open={true}
            showConfirm={false}
            onConfirm={() => {
              return;
            }}
            closeLabel="OK"
            onClose={() => {
              setOpenDialog(null);
            }}
          >
            <p>
              If you are on the staff list in another Trust on VaccinationTrack,
              your 'Had Elsewhere' or 'Declined' status will be automatically
              updated in that trust within the next 30 seconds.
              <br />
              <br />
            </p>
          </ConfirmDialog>
        )}
        <UndoDeclineDialog
          open={openDialog === "undoDecline"}
          onClose={() => {
            setOpenDialog(null);
          }}
          onSuccess={() => {
            setOpenDialog(null);
            setFeedback({
              type: "success",
              message: "Undone decline successfully.",
            });
            reload();
          }}
          onError={(error: Error) => {
            setOpenDialog(null);
            setFeedback({ type: "error", message: error.message });
          }}
          value={{ id: currentTrust?.trustWorkerId }}
          pathogen={dialogPathogens}
        />
        {feedback && (
          <ToastMessage
            variant={feedback.type}
            message={feedback.message}
            clear={() => {
              setFeedback(null);
            }}
          />
        )}
      </main>
    </MeContext.Provider>
  );
}
