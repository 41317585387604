import {
  Route as PublicRoute,
  Redirect,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import ContentForDemoPage from "../../components/ContentForDemoPage";
import ContentForPrivacyPolicy from "../../components/ContentForPrivacyPolicy";
import DefaultPage from "../../components/DefaultPage";
import PageNotFound from "../../components/ErrorPages/page_not_found";
import Footer from "../../components/Universal/footer";
import Header from "../../components/Universal/header";
import { ApplicationPaths } from "../../components/api-authorization/ApiAuthorizationConstants";
import ApiAuthorizationRoutes from "../../components/api-authorization/ApiAuthorizationRoutes";
import Route from "../../components/api-authorization/AuthorizeRoute";
import requireAuth from "../../lib/hoc/requireAuth";
import { Roles } from "../../lib/types/TrustRoles";
import { EventLog } from "../EventLog/EventLog";
import Dashboard from "../Home/Dashboard";
import Home from "../Home/Home";
import Register from "../Home/Register";
import BookAppointment from "../Users/Patients/BookAppointment";
import MyAppointments from "../Users/Patients/MyAppointments";
import Welcome from "../Users/Patients/Welcome";
import NavContainer from "../Users/SuperAdmin/NavContainer";
import SuperAdmins from "../Users/SuperAdmin/SuperAdmin/SuperAdmins";
import DefaultNotificationEdit from "../Users/SuperAdmin/Template/DefaultNotificationEdit";
import DefaultNotifications from "../Users/SuperAdmin/Template/DefaultNotifications";
import ManageTrusts from "../Users/SuperAdmin/Trust/ManageTrusts";
import TrustAdminCreateUpdate from "../Users/SuperAdmin/TrustAdmin/TrustAdminCreateUpdate";
import TrustAdmins from "../Users/SuperAdmin/TrustAdmin/TrustAdmins";
import TrustGroups from "../Users/SuperAdmin/TrustGroup/TrustGroups";
import TrustGroupUserCreateUpdate from "../Users/SuperAdmin/TrustGroupUser/TrustGroupUserCreateUpdate";
import TrustGroupUsers from "../Users/SuperAdmin/TrustGroupUser/TrustGroupUsers";
import ClinicCRUD from "../Users/TrustAdmin/Clinics/CRUD";
import ClinicDelete from "../Users/TrustAdmin/Clinics/Delete";
import VaccinationPlanner from "../Users/TrustAdmin/Clinics/Planner";
import ClinicSplit from "../Users/TrustAdmin/Clinics/Split";
import ClinicVaccine from "../Users/TrustAdmin/Clinics/Vaccine";
import ClinicVaccineBatches from "../Users/TrustAdmin/Clinics/VaccineBatches";
import ClinicView from "../Users/TrustAdmin/Clinics/View";
import CampaignsSettings from "../Users/TrustAdmin/Settings/Campaigns/List";
import Assignments from "../Users/TrustAdmin/Settings/Campaigns/StaffRoleSettings/Assignments";
import HospitalSettings from "../Users/TrustAdmin/Settings/Hospitals/List";
import { MasterVaccineBatchesAdd } from "../Users/TrustAdmin/Settings/MasterVaccineBatches/Add";
import { MasterVaccineBatchesEdit } from "../Users/TrustAdmin/Settings/MasterVaccineBatches/Edit";
import { MasterVaccineBatchesList } from "../Users/TrustAdmin/Settings/MasterVaccineBatches/List";
import OrganisationsList from "../Users/TrustAdmin/Settings/Organisations/List";
import QuestionLists from "../Users/TrustAdmin/Settings/Questions/QuestionLists";
import SettingsContainer from "../Users/TrustAdmin/Settings/SettingsContainer";
import TemplateSettings from "../Users/TrustAdmin/Settings/Templates/List";
import ManageTrustAdmins from "../Users/TrustAdmin/Settings/TrustAdmins";
import TrustAdminCreate from "../Users/TrustAdmin/Settings/TrustAdmins/TrustAdminCreate";
import TrustAdminUpdate from "../Users/TrustAdmin/Settings/TrustAdmins/TrustAdminUpdate";
import ESRDetail from "../Users/TrustAdmin/Staff/ESR/Detail";
import ESR from "../Users/TrustAdmin/Staff/ESR/List";
import Staff from "../Users/TrustAdmin/Staff/Staff";

export default function BrowserAppRoutes() {
  const SUPER_ADMIN = requireAuth([Roles.SuperAdmin]);
  const TRUST_ADMIN = requireAuth([Roles.SuperAdmin, Roles.TrustAdmin]);
  // ClinicAdmin is just a slightly inferior Trust Admin (no settings/staff)
  const CLINIC_ADMIN = requireAuth([
    Roles.SuperAdmin,
    Roles.TrustAdmin,
    Roles.ClinicAdmin,
  ]);
  const VACCINATOR = requireAuth([
    Roles.SuperAdmin,
    Roles.TrustAdmin,
    Roles.ClinicAdmin,
    Roles.Vaccinator,
  ]);
  const TRUSTWORKER = requireAuth([
    Roles.TrustWorker,
    Roles.TrustAdmin,
    Roles.ClinicAdmin,
    Roles.Vaccinator,
  ]);

  const REPORTINGVIEWER = requireAuth([
    Roles.ReportingViewer,
    Roles.TrustAdmin,
    Roles.ClinicAdmin,
    Roles.TrustGroupUser,
  ]);
  const preScreeeningSuccessMessage = `### Pre-screening Statement Updated

If the statement has already been used for a booking or a recorded vaccination, it will be updated in staff vaccination records.`;

  const consentSuccessMessage = `### Consent Statement Updated

If the statement has already been used for a booking or a recorded vaccination, it will be updated in staff vaccination records.`;

  const preScreeeningDeletedMessage = `### Pre-screening Statement Deleted

If this pre-statement statement has previously been used when recording a vaccination during the current campaign, it will be deleted from those vaccination records.`;

  const consentDeletedMessage = `### Consent Statement Deleted

If this consent statement has previously been used when recording a vaccination during the current campaign, it will be deleted from those vaccination records.`;

  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <PublicRoute
          exact
          path={"/register/:registrationSegment"}
          component={Register}
        />
        <PublicRoute
          path={ApplicationPaths.ApiAuthorizationPrefix}
          component={ApiAuthorizationRoutes}
        />
        <Route
          exact
          path={"/trust-groups"}
          component={SUPER_ADMIN(TrustGroups)}
        />
        <Route
          exact
          path={"/users/trust-group-users"}
          component={SUPER_ADMIN(TrustGroupUsers)}
        />
        {/* <Route
          exact
          path={"/trust-groups/new"}
          component={SUPER_ADMIN(TrustGroupCreateUpdate)}
        /> */}
        <Route
          exact
          path={"/users/trust-group-users/:id?"}
          component={SUPER_ADMIN(TrustGroupUserCreateUpdate)}
        />
        <Route
          exact
          path={"/trusts/:id?"}
          component={SUPER_ADMIN(ManageTrusts)}
        />
        <Route
          exact
          path={"/users/trust-admins"}
          component={SUPER_ADMIN(TrustAdmins)}
        />
        <Route
          exact
          path={"/users/trust-admins/:id"}
          component={SUPER_ADMIN(TrustAdminCreateUpdate)}
        />
        <Route
          exact
          path={"/users/super-admins/:id?"}
          component={SUPER_ADMIN(SuperAdmins)}
        />
        <Route
          exact
          path={"/default-notifications"}
          component={SUPER_ADMIN(DefaultNotifications)}
        />
        <Route
          exact
          path={"/default-notifications/:id"}
          component={SUPER_ADMIN(DefaultNotificationEdit)}
        />
        <Route
          exact
          path={"/change-log"}
          component={SUPER_ADMIN(() => (
            <NavContainer>
              <EventLog></EventLog>
            </NavContainer>
          ))}
        />
        <Route
          exact
          path={"/clinics"}
          component={VACCINATOR(VaccinationPlanner)}
        />
        <Route
          exact
          path={"/clinics/create"}
          component={CLINIC_ADMIN(ClinicCRUD)}
        />
        <Route
          exact
          path={"/clinics/:clinicId/view"}
          component={VACCINATOR(ClinicView)}
        />
        {/* Clinic view page then clicking edit button on a booking: */}
        <Route
          exact
          path={
            "/clinics/:clinicId/vaccination-details/:trustWorkerId/:pathogenId/:pathogenName/:unplannedVaccination?"
          }
          component={VACCINATOR((props) => (
            <ClinicView {...props} activeModal="vaccinationDetails" />
          ))}
        />
        {/* Similar to above but with modal appearing on the staff list page (not clicking 'record vaccination' button, but if need linking from elsewhere): */}
        <Route
          exact
          path={
            "/staff/vaccination-details/:trustWorkerId/:pathogenId/:pathogenName"
          }
          component={(props) => (
            <Staff {...props} activeModal="vaccinationDetails" />
          )}
        />
        <Route
          exact
          path={"/clinics/:clinicId/edit"}
          component={CLINIC_ADMIN(ClinicCRUD)}
        />
        <Route
          exact
          path={"/clinics/:clinicId/edit-single"}
          component={VACCINATOR((props) => (
            <ClinicCRUD {...props} splitIfEditing={true} />
          ))}
        />
        <Route
          exact
          path={"/clinics/:clinicId/split"}
          component={CLINIC_ADMIN(ClinicSplit)}
        />
        <Route
          exact
          path={"/clinics/:clinicId/delete"}
          component={CLINIC_ADMIN(ClinicDelete)}
        />
        <Route
          exact
          path={"/clinics/:clinicId/vaccine-batches/:pathogenId/add"}
          component={VACCINATOR((props) => (
            <ClinicVaccine {...props} mode="add" />
          ))}
        />
        <Route
          exact
          path={"/clinics/:clinicId/vaccine-batches/:pathogenId/:id/edit"}
          component={VACCINATOR((props) => (
            <ClinicVaccine {...props} mode="edit" />
          ))}
        />
        <Route
          exact
          path={"/clinics/:clinicId/vaccine-batches/:pathogenId"}
          component={VACCINATOR(ClinicVaccineBatches)}
        />

        <Route
          exact
          path={"/reporting"}
          component={REPORTINGVIEWER(Dashboard)}
        />

        <Route exact path={"/staff"} component={TRUST_ADMIN(Staff)} />
        <Route
          exact
          path={"/staff/create"}
          component={(props) => <Staff {...props} activeModal="add" />}
        />
        <Route exact path={"/esr"} component={ESR} />
        <Route exact path={"/esr/:id"} component={ESRDetail} />
        <Route
          exact
          path={"/book-appointment/:pathogens?"}
          component={TRUSTWORKER(BookAppointment)}
        />
        <Route
          exact
          path={"/my-appointments"}
          component={TRUSTWORKER(MyAppointments)}
        />
        <Route exact path={"/campaigns"}>
          <Redirect to="/settings/campaigns" />
        </Route>
        <Route exact path={"/settings"}>
          <Redirect to="/settings/templates" />
        </Route>
        <Route
          exact
          path={"/settings/immform"}
          component={TRUST_ADMIN(CampaignsSettings)}
        />
        <Route
          exact
          path={"/settings/change-log"}
          component={TRUST_ADMIN(() => (
            <SettingsContainer>
              <EventLog></EventLog>
            </SettingsContainer>
          ))}
        />
        <Route
          exact
          path={"/settings/trust-admins"}
          component={TRUST_ADMIN(ManageTrustAdmins)}
        />
        <Route
          exact
          path={"/settings/trust-admins/new"}
          component={TRUST_ADMIN(TrustAdminCreate)}
        />
        <Route
          exact
          path={"/settings/trust-admins/:id"}
          component={TRUST_ADMIN(TrustAdminUpdate)}
        />
        <Route
          exact
          path={"/settings/hospitals"}
          component={TRUST_ADMIN(HospitalSettings)}
        />
        <Route
          exact
          path={"/settings/master-vaccine-batches"}
          component={TRUST_ADMIN(MasterVaccineBatchesList)}
        />
        <Route
          exact
          path={"/settings/master-vaccine-batches/:pathogenId/add"}
          component={TRUST_ADMIN(MasterVaccineBatchesAdd)}
        />
        <Route
          exact
          path={"/settings/master-vaccine-batches/:pathogenId/:id/edit"}
          component={TRUST_ADMIN(MasterVaccineBatchesEdit)}
        />
        <Route
          exact
          path={"/settings/organisations"}
          component={TRUST_ADMIN(OrganisationsList)}
        />
        <Route
          exact
          path={"/settings/templates"}
          component={TRUST_ADMIN(TemplateSettings)}
        />
        <Route
          exact
          path={"/settings/templates/:id"}
          component={TRUST_ADMIN(TemplateSettings)}
        />
        <Route
          exact
          path={"/settings/assignments/:campaignId"}
          component={TRUST_ADMIN((props) => (
            <Assignments {...props} helpCopyKey="5.4" />
          ))}
        />
        <Route
          exact
          path={"/settings/front-line/:campaignId"}
          component={TRUST_ADMIN(Assignments)}
        />
        <Route
          exact
          path={"/settings/immform/:campaignId"}
          component={TRUST_ADMIN(Assignments)}
        />
        <Route
          exact
          path={"/settings/pre-screening"}
          component={(props) => (
            <QuestionLists
              {...props}
              baseUrl="/TrustExclusionQuestion"
              title="Pre-screening"
              helpCopyKey="5.2"
              successMessage={preScreeeningSuccessMessage}
              deletedMessage={preScreeeningDeletedMessage}
            />
          )}
        />
        <Route
          exact
          path={"/settings/consent"}
          component={TRUST_ADMIN((props) => (
            <QuestionLists
              {...props}
              baseUrl="/TrustConsentQuestion"
              title="Consent"
              helpCopyKey="5.3"
              successMessage={consentSuccessMessage}
              deletedMessage={consentDeletedMessage}
            />
          ))}
        />
        <Route
          exact
          path={"/testing-capacity-planner"}
          component={TRUST_ADMIN(VaccinationPlanner)}
        />
        <PublicRoute exact path={"/auth/decline-request"} component={Welcome} />
        <PublicRoute
          exact
          path={"/privacy-policy"}
          component={DefaultPage(ContentForPrivacyPolicy)}
        />
        <PublicRoute
          exact
          path={"/demo"}
          component={DefaultPage(ContentForDemoPage)}
        />
        {/*
            https://www.vaccinationtrack.com/%7B%7BBookAppointmentUrl%7D%7D
            https://www.vaccinationtrack.com/%7B%7BOptoutLink%7D%7D
            https://www.vaccinationtrack.com/%7B%7BHadElsewhere%7D%7D
            ->
            https://www.vaccinationtrack.com/my-appointments
        */}
        <Route path={/.*BookAppointmentUrl.*/}>
          <Redirect to="/my-appointments" />
        </Route>
        <Route path={/.*OptoutLink.*/}>
          <Redirect to="/my-appointments" />
        </Route>
        <Route path={/.*HadElsewhere.*/}>
          <Redirect to="/my-appointments" />
        </Route>
        <PublicRoute component={PageNotFound} />
      </Switch>
      <Footer />
    </Router>
  );
}
