import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { humanNameFromObject } from "../../../../lib/common_utils";
import ConfirmDialog from "../../ConfirmDialog";
import api from "./data";
import { TrustWorkerDetailed } from "./TrustWorkerDetailedDTO";
const additionalRoles = ["isClinicAdmin", "isVaccinator"];
const additionalRoleOptions = [
  { label: "Staff Member", value: [] },
  { label: "Vaccinator", value: ["isVaccinator"] },
  { label: "Clinic Admin", value: ["isClinicAdmin"] },
];
export const SetAdditionalRoles = ({
  open,
  onClose,
  onSuccess,
  onError,
  value: trustWorker,
}: {
  open: any;
  onClose: () => Record<string, any>;
  onSuccess: any;
  onError: any;
  value: TrustWorkerDetailed; //{ atRisk?: boolean; atRiskDetails?: string };
}): JSX.Element => {
  const [role, setRole] = useState(additionalRoleOptions[0]);
  const [confirmDialog, setConfirmDialog] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  useEffect(() => {
    if (!trustWorker) return;
    const currentRoles = additionalRoles.filter((r) => trustWorker[r]) || [];
    setRole(additionalRoleOptions.find((o) => isEqual(o.value, currentRoles)));
  }, [trustWorker]);
  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Assign Staff Member's Role</DialogTitle>
        <DialogContent>
          <form
            id="update-user-roles"
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault();
              setConfirmDialog(true);
            }}
          >
            <section>
              <InputLabel>Role</InputLabel>
              <Select
                style={{ width: "100%" }}
                onChange={(e) => {
                  setRole(additionalRoleOptions[e.target.value as number]);
                }}
                value={additionalRoleOptions.findIndex(
                  (o) => JSON.stringify(o) === JSON.stringify(role)
                )}
              >
                {additionalRoleOptions.map((o, i) => (
                  <MenuItem value={i} key={`option-${i}`}>
                    {o.label}
                  </MenuItem>
                ))}
              </Select>
            </section>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            form="update-user-roles"
            type="submit"
            variant="contained"
            color="secondary"
            autoFocus
          >
            Update
          </Button>
          <Button
            onClick={onClose}
            variant="outlined"
            color="secondary"
            style={{
              color: "#fff",
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        open={!!confirmDialog}
        disableConfirm={submitting}
        title={(() => {
          return `Are you sure you want to assign ${humanNameFromObject(
            trustWorker
          )} as a ${role.label}?`;
        })()}
        onConfirm={() => {
          setSubmitting(true);
          api.staff
            .setAdditionalRoles(trustWorker, role.value)
            .then((response: TrustWorkerDetailed) => {
              if (response) {
                onSuccess();
              } else {
                throw new Error();
              }
            })
            .catch((e) =>
              onError({
                message:
                  e?.response?.data?.results?.join(",") ||
                  "Could not update roles",
              })
            )
            .finally(() => {
              setConfirmDialog(null);
              setSubmitting(false);
            });
        }}
        onClose={() => setConfirmDialog(null)}
      >
        <></>
      </ConfirmDialog>
    </>
  );
};
