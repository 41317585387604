import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  TextField,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import api from "./data";
import { humanNameFromObject } from "../../../../lib/common_utils";
import ConfirmDialog from "../../ConfirmDialog";
export default ({
  open,
  onClose,
  onSuccess,
  onError,
  value,
}: {
  open: any;
  onClose: () => Record<string, any>;
  onSuccess: any;
  onError: any;
  value: any; //{ atRisk?: boolean; atRiskDetails?: string };
}): JSX.Element => {
  const {
    control,
    formState: { errors },
    getValues,
    reset,
    watch,
  } = useForm();
  const watchAtRisk = watch("atRisk");
  const [confirmDialog, setConfirmDialog] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!value) return;
    const { atRisk, atRiskDetails } = value || {};
    const defaultValues = { atRisk, atRiskDetails };
    reset(defaultValues);
  }, [value, reset]);
  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Mark as 'At Risk'</DialogTitle>
        <DialogContent>
          <form
            id="at-risk"
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault();
              setConfirmDialog(true);
            }}
          >
            <section>
              <Controller
                name="atRisk"
                control={control}
                render={({ field }) => (
                  <FormControl error={!!errors?.atRisk}>
                    <FormControlLabel
                      label={`Mark ${humanNameFromObject(value)} as 'At Risk'`}
                      control={
                        <Checkbox
                          {...{
                            onChange: (e) => field.onChange(e.target.checked),
                            checked: field.value,
                          }}
                        />
                      }
                    />
                    {errors?.atRisk && (
                      <FormHelperText>{errors?.atRisk?.message}</FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </section>
            <section>
              {watchAtRisk && (
                <Controller
                  name="atRiskDetails"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <TextField
                      {...rest}
                      fullWidth
                      multiline
                      rows={4}
                      {...{
                        label: "Details",
                        error: !!errors?.atRiskDetails,
                        helperText: errors?.atRiskDetails?.message,
                      }}
                    />
                  )}
                />
              )}
            </section>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            form="at-risk"
            type="submit"
            variant="contained"
            color="secondary"
            autoFocus
          >
            Update
          </Button>
          <Button
            onClick={onClose}
            variant="outlined"
            color="secondary"
            style={{
              color: "#fff",
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        open={!!confirmDialog}
        disableConfirm={loading}
        title={(() => {
          const { atRisk } = getValues();
          return `Are you sure you want to ${
            atRisk ? "" : "un"
          }mark ${humanNameFromObject(value)} as 'At Risk'?`;
        })()}
        onConfirm={() => {
          setLoading(true);
          const data = getValues();
          const { id } = value;
          const send = {
            // Naming inconsistency in the API. clone JIC
            isAtRisk: data.atRisk,
            atRiskDetails: data.atRisk ? data.atRiskDetails : null,
          };
          api.staff
            .updateAtRisk(id, send)
            .then(({ success }: { success: boolean }) => {
              if (success) {
                onSuccess();
              } else throw new Error("Could not update 'At Risk'");
            })
            .catch(onError)
            .finally(() => {
              setConfirmDialog(null);
              setLoading(false);
            });
        }}
        onClose={() => setConfirmDialog(null)}
      >
        <></>
      </ConfirmDialog>
    </>
  );
};
