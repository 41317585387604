import {
  Box,
  Button,
  CircularProgress,
  LinearProgress,
} from "@material-ui/core";
import {
  ArrowBack,
  Cancel as CancelIcon,
  Save as SaveIcon,
} from "@material-ui/icons";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Help from "../../../../../../components/Help/Help";
import ToastMessage from "../../../../../../components/Universal/toast_message";
import ConfirmDialog from "../../../../ConfirmDialog";
import SettingsContainer from "../../SettingsContainer";
import styles from "./Assignments.module.scss";
import NonStaffRoleSettings from "./NonStaffRoleSettings";
import { StaffRoleSettings } from "./StaffRoleSettings";
import { useImmFormSettings } from "./useImmFormSettings";
import useNonStaffRoles from "./useNonStaffRoles";
import useStaffGroups from "./useStaffGroups";

export default function Assignments({ helpCopyKey }: { helpCopyKey?: string }) {
  const [feedback, setFeedback] = React.useState<{
    type: string;
    message: string;
  }>(null);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [
    updateImmFormSettingsByRole,
    updateImmFormSettingsByGroup,
    saveImmFormSettingsData,
    hasChanges,
  ] = useImmFormSettings(setConfirmDialogOpen, setLoading, setFeedback);

  const {
    groups: immFormGroups,
    hasChanges: immFormHasChanges,
    handleGroupChange: handleImmFormGroupChnage,
    handleRoleChange: handleImmFormRoleChange,
    fetchData: fetchImmFormData,
  } = useStaffGroups("/CampaignImmFormStaffGroup", setFeedback);
  const {
    groups: frontlineGroups,
    hasChanges: frontlineHasChanges,
    handleGroupChange: handleFrontlineGroupChnage,
    handleRoleChange: handleFrontlineRoleChange,
    fetchData: fetchFrontlineData,
  } = useStaffGroups("/CampaignFrontLineStaffGroup", setFeedback);

  const {
    roles: nonStaffFrontlineRoles,
    hasChanges: nonStaffFrontlineHasChanges,
    handleRoleChange: handleNonStaffFrontlineRoleChange,
    fetchData: fetchNonStaffFrontlineData,
  } = useNonStaffRoles("/CampaignFrontLineNonEsrStaffType", setFeedback);
  const {
    roles: nonStaffImmFormRoles,
    hasChanges: nonStaffImmFormHasChanges,
    handleRoleChange: handleNonStaffImmFormRoleChange,
    fetchData: fetchNonStaffImmFormData,
  } = useNonStaffRoles("/CampaignImmFormNonEsrStaffType", setFeedback);

  const isInitialising =
    !immFormGroups ||
    !frontlineGroups ||
    !nonStaffImmFormRoles ||
    !nonStaffFrontlineRoles;

  const cancelChanges = () => {
    if (immFormHasChanges) fetchImmFormData();
    if (frontlineHasChanges) fetchFrontlineData();
    if (nonStaffImmFormHasChanges) fetchNonStaffImmFormData();
    if (nonStaffFrontlineHasChanges) fetchNonStaffFrontlineData();
  };
  return (
    <>
      {isInitialising && <LinearProgress color="primary" />}
      <SettingsContainer>
        <div className={styles.toolbar}>
          {hasChanges && (
            <div>
              <Box mr={2} component="span">
                <Button
                  color={"secondary"}
                  variant={"contained"}
                  startIcon={<CancelIcon />}
                  onClick={cancelChanges}
                  disabled={loading}
                >
                  Cancel changes
                </Button>
              </Box>
              <Box mr={2} component="span">
                <Button
                  color={"primary"}
                  variant={"contained"}
                  startIcon={<SaveIcon />}
                  onClick={() => {
                    setConfirmDialogOpen(true);
                  }}
                  disabled={loading}
                >
                  Save changes
                </Button>
              </Box>
            </div>
          )}
          <Button
            color="primary"
            variant="contained"
            startIcon={<ArrowBack />}
            component={NavLink}
            to="/settings/immform"
          >
            Back to Frontline & ImmForm Assignments
          </Button>
        </div>
        {helpCopyKey && <Help helpCopyKey={helpCopyKey} />}
        <div className={styles.stack}>
          <StaffRoleSettings
            title="ImmForm Assignments by ESR Staff Groups / Job Roles"
            groups={immFormGroups}
            loading={loading}
            handleGroupChange={(group, isChecked) => {
              updateImmFormSettingsByGroup(
                "immFormStaffGroups",
                group,
                isChecked
              );
              handleImmFormGroupChnage(group, isChecked); //old
            }}
            handleRoleChange={(role, isChecked) => {
              updateImmFormSettingsByRole(
                "immFormStaffGroups",
                role,
                isChecked
              );
              handleImmFormRoleChange(role, isChecked); //old
            }}
          />
          <StaffRoleSettings
            title="Frontline Assignments by ESR Staff Groups / Job Roles"
            groups={frontlineGroups}
            loading={loading}
            handleGroupChange={(group, isChecked) => {
              updateImmFormSettingsByGroup(
                "frontLineStaffGroups",
                group,
                isChecked
              );
              handleFrontlineGroupChnage(group, isChecked); //old
            }}
            handleRoleChange={(role, isChecked) => {
              updateImmFormSettingsByRole(
                "frontLineStaffGroups",
                role,
                isChecked
              );
              handleFrontlineRoleChange(role, isChecked); //old
            }}
          />
        </div>
        <div className={styles.stack}>
          <NonStaffRoleSettings
            title="ImmForm Assignments by Non ESR Staff Groups"
            roles={nonStaffImmFormRoles}
            loading={false}
            handleRoleChange={(role, isChecked) => {
              updateImmFormSettingsByRole(
                "immFormNonEsrStaffTypes",
                role,
                isChecked
              );
              handleNonStaffImmFormRoleChange(role, isChecked); //old
            }}
          />
          <NonStaffRoleSettings
            title="Frontline Assignments by Non ESR Staff Groups"
            roles={nonStaffFrontlineRoles}
            loading={false}
            handleRoleChange={(role, isChecked) => {
              updateImmFormSettingsByRole(
                "frontLineNonEsrStaffTypes",
                role,
                isChecked
              );
              handleNonStaffFrontlineRoleChange(role, isChecked); //old
            }}
          />
        </div>
        {feedback && (
          <ToastMessage
            variant={feedback.type}
            message={feedback.message}
            clear={() => setFeedback(null)}
          />
        )}
      </SettingsContainer>
      <ConfirmDialog
        open={confirmDialogOpen}
        title="Save assignment settings?"
        showClose={false}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={() => {
          saveImmFormSettingsData();
        }}
        confirmLabel="OK"
      >
        <>
          <div>
            <p>ESR Staff Groups</p>
            <ul>
              <li>
                Please note, changes are NOT automatically applied to existing
                users in ESR Staff Groups
              </li>
              <li>
                Please upload your latest staff list to update assignments for
                these users
              </li>
            </ul>
          </div>
          <div>
            <p>Non ESR Staff Groups</p>
            <ul>
              <li>
                Changes have been applied automatically to existing users in
                Non ESR Staff Groups
              </li>
              <li>No further action is required</li>
            </ul>
          </div>
          <p>
            <strong>
              Note: This action may take some time. Please be patient.
            </strong>
          </p>
        </>
      </ConfirmDialog>
      {isInitialising && (
        <div className="interstitial">
          <CircularProgress />
        </div>
      )}
      {loading && (
        <div className="interstitial">
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
            <p>This may take some time. Please do not refresh the page.</p>
          </div>
        </div>
      )}
    </>
  );
}
