import { isEmptyOfValues } from "../../../../lib/common_utils";
import { createPathogensFromPathogenCheckboxes } from "../../../../lib/pathogens";
import {
  dateOfBirthOver16,
  validateAllTheThings,
  validateHasNhsNumberOrPostCode,
  validateNhsNumber,
  validatePathogenChosen,
  validatePhoneNumber,
} from "../../../../lib/validationRules";
export const staffCreateEditValidation = (data: any) => {
  data = createPathogensFromPathogenCheckboxes(data);
  const errorsClientSide = validateAllTheThings(data, [
    {
      field: "dateOfBirth",
      tests: [dateOfBirthOver16],
    },
    {
      field: "pathogens",
      tests: [validatePathogenChosen],
    },
    {
      field: "nhsNumber",
      tests: [validateHasNhsNumberOrPostCode, validateNhsNumber],
    },
    {
      field: "postCode",
      tests: [validateHasNhsNumberOrPostCode],
    },
    {
      field: "mobileNumber",
      tests: [validatePhoneNumber],
    },
  ]);
  return errorsClientSide;
};
export const staffCreateEditSubmit =
  (apiClient: (item: any) => Promise<any>) =>
  (
    { data, onSuccess, onError, setErrors, setLoading }: any,
    setShowEsrUserManuallyEditedDialog?: (data: any) => void
  ) => {
    const errorsClientSide = staffCreateEditValidation(data);
    setErrors(errorsClientSide);
    if (isEmptyOfValues(errorsClientSide)) {
      const saveData = () => {
        data = createPathogensFromPathogenCheckboxes(data);
        setLoading(true);
        apiClient(data)
          .then((response: any) => {
            setLoading(false);
            if (response.errors) {
              setErrors(Object.assign(response.errors, errorsClientSide));
              return;
            }
            setShowEsrUserManuallyEditedDialog &&
              setShowEsrUserManuallyEditedDialog(false);
            return onSuccess(response);
          })
          .catch((error) => {
            setLoading(false);
            // Catches 409 error from api_client.js: catchFormErrors
            // NB 409 can be returned for existing users, including archived.
            if (typeof error.errors == "string") {
              onError(error);
            } else if (error?.response?.data?.results) {
              const messages = error.response?.data?.results;
              onError(messages);
            } else {
              onError();
            }
          });
      };
      // If the user being edited was not created manually or self registered (Assumed is therefore ESR), warn user that the data will be overwritten by future ESR uploads.
      // Modal will call the saveData callback if user confirms.
      if (
        !data.addedManually &&
        !data.selfRegistered &&
        setShowEsrUserManuallyEditedDialog
      ) {
        setShowEsrUserManuallyEditedDialog({ data, saveDataCb: saveData });
      } else {
        saveData();
      }
    } else {
      // Scroll modal down to first errored field, matching error key with element id
      const firstError = Object.keys(errorsClientSide)[0];
      const element = document.getElementById(firstError);
      if (element) {
        element.scrollIntoView();
      }
    }
  };
