import { CircularProgress, IconButton, Tooltip } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { Delete as DeleteIcon, Edit as EditIcon } from "@material-ui/icons";
import { ThemeProvider } from "@material-ui/styles";
import MUIDataTable, { MUIDataTableOptions } from "mui-datatables";
import React from "react";
import { useHistory } from "react-router-dom";
import { TrustGroupUser } from "./useTrustGroupUserList";
const palette = {
  primary: {
    main: "#283583",
    contrastText: "#fff",
  },
  secondary: {
    main: "#009fe3",
    contrastText: "#fff",
  },
  error: {
    main: "#be584e",
    contrastText: "#fff",
  },
  action: {
    selected: "#d4dcf1",
  },
};
const theme = createTheme({
  palette,
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: "none",
      },
    },
    MuiTable: {
      root: {
        borderSpacing: "0 10px",
        borderCollapse: "separate",
      },
    },
    MuiTableCell: {
      root: {
        border: "none",
        borderBottom: "none",
        padding: "0.1em .3em",
      },
      head: {
        fontWeight: "bold",
        fontSize: "0.9rem",
      },
    },
    MuiPopover: {
      paper: {
        backgroundColor: "#fff",
      },
    },
  },
});
type TableProps = {
  data: TrustGroupUser[];
  deleteUser: (id: number) => void;
  editPath?: string;
  totalCount: number;
  setSearchText?: (searchText: any) => void;
  setPageNumber?: (pageNumber: number) => void;
  setPageSize?: (pageSize: number) => void;
};
export default function TrustGroupUsersTable({
  data,
  deleteUser,
  editPath = "/users/trust-group-users/",
  totalCount,
  setSearchText,
  setPageNumber,
  setPageSize,
}: TableProps): JSX.Element {
  const history = useHistory();
  const columns = [
    {
      name: "id",
      options: {
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "firstName",
      label: "First Name",
    },
    {
      name: "lastName",
      label: "Last Name",
    },
    {
      name: "emailAddress",
      label: "Email",
    },
    {
      name: "trustGroupName",
      label: "System",
    },
    {
      name: "",
      options: {
        display: true,
        viewColumns: false,
        setCellProps: () => ({
          style: { display: "flex", justifyContent: "flex-end" },
        }),
        customBodyRender: (v: any, d: any) => (
          <>
            <Tooltip title="Edit">
              <IconButton
                color="primary"
                onClick={() => {
                  history.push(`${editPath}${d.rowData[0]}`);
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                color="primary"
                onClick={() => {
                  deleteUser(d.rowData[0]);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        ),
      },
    },
  ];
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loading, setLoading] = React.useState(false);
  let searchDebounce: ReturnType<typeof setTimeout>;
  const options: MUIDataTableOptions = {
    elevation: 0,
    serverSide: true,
    rowsPerPageOptions: [10, 25, 50, 100],
    rowsPerPage,
    sort: false,
    filter: false,
    print: false,
    download: false,
    viewColumns: false,
    count: totalCount,
    search: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Search",
    selectableRows: "none",
    // customSearchRender: CustomDebouncedSearch.. does not work for serverSide, so instead we use onSearchChange and re-query
    onSearchChange: (searchText: string | null) => {
      // Debounce, reset to page 1, search again and timeout a loading spinner.
      // (Hacky but we can't resolve the search completion without refactoring so many files)
      clearTimeout(searchDebounce);
      searchDebounce = setTimeout(() => {
        setLoading(true);
        setPageNumber(1);
        setSearchText(searchText?.trim() || "");
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      }, 500);
    },
    onChangePage: (numberOfRows: number) => {
      setPageNumber(numberOfRows + 1);
    },
    onChangeRowsPerPage: (pageSize: number) => {
      setRowsPerPage(pageSize);
      setPageSize(pageSize);
    },
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress />
        ) : (
          "Sorry, no matching records found"
        ),
      },
    },
  };
  return (
    <ThemeProvider theme={theme}>
      <MUIDataTable
        title=""
        data={loading ? [] : data}
        columns={columns}
        options={options}
      />
    </ThemeProvider>
  );
}
