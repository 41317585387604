import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControlLabel,
  List,
  ListItem,
  Switch,
  Typography,
} from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import React from "react";
import { Group, Role } from "./useStaffGroups";

const StaffRoleSettings = ({
  title,
  groups,
  loading,
  handleGroupChange,
  handleRoleChange,
}: {
  title: string;
  groups: { [id: number]: Group };
  loading: boolean;
  handleGroupChange: (group: Group, value: boolean) => void;
  handleRoleChange: (role: Role, value: boolean) => void;
}) => {
  return (
    <div>
      <Box pb={2}>
        <Typography variant="h5" color="primary">
          {title}
        </Typography>
      </Box>

      {groups && (
        <>
          {Object.values(groups).map((group) => (
            <Accordion key={`Group${group.id}`}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <FormControlLabel
                  onClick={(e) => e.stopPropagation()}
                  control={
                    <Switch
                      disabled={loading}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) =>
                        handleGroupChange(group, e.target.checked)
                      }
                      checked={group.isChecked}
                    />
                  }
                  label={group.name}
                />
              </AccordionSummary>
              <AccordionDetails>
                <List component="nav">
                  {group.children.map((role) => (
                    <ListItem key={`Role${role.jobRoleId}`}>
                      <FormControlLabel
                        control={
                          <Switch
                            disabled={loading}
                            checked={role.current}
                            onChange={(e) =>
                              handleRoleChange(role, e.target.checked)
                            }
                          />
                        }
                        label={role.jobRoleName}
                      />
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </>
      )}

      {groups && Object.values(groups).length === 0 && <h1>No data</h1>}
    </div>
  );
};

export { StaffRoleSettings };
