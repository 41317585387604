import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

import clsx from 'clsx';
import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles1 = makeStyles((theme) => ({
  success: {
    backgroundColor: `#009fe3 !important`,
    color: '#fff !important',
    width: '650px',
  },
  error: {
    backgroundColor: `${theme.palette.error.dark} !important`,
    color: '#fff !important',
    width: '650px',
  },
  info: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: '#fff !important',
    width: '650px',
  },
  warning: {
    backgroundColor: '#ffbf00 !important',
    color: '#fff !important',
    width: '650px',
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    paddingTop: '4px',
    display: 'flex',
  },

  messageContent: {
    '& :first-child': { marginTop: 0 },
    '& :last-child': { marginBottom: 0 },
  },

  content: {
    flexWrap: 'nowrap',
    alignItems: 'start',
  },
}));

function ToastMessageWrapper(props) {
  const classes = useStyles1();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], classes.content, className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />

          <div className={classes.messageContent}>
            <ReactMarkdown children={message} />
          </div>
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

export default function ToastMessage(props) {
  const [open, setOpen] = React.useState(true);

  useEffect(() => {
    return (handleClose = () => null);
  });
  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    props.clear && props.clear();
    setOpen(false);
  }

  const { variant, message } = props;

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={10000}
      onClose={handleClose}
    >
      <ToastMessageWrapper onClose={handleClose} variant={variant} message={message} />
    </Snackbar>
  );
}
