import React from "react";
import DataClient from "../../../../lib/services/api/DataClient";
import { SuperAdmin } from "./useSuperAdminList";

export default function useCreateSuperAdmin() {
  const [success, setSuccess] = React.useState<false | SuperAdmin>(false);
  const [error, setError] = React.useState(false);

  const callback = (admin: SuperAdmin) => {
    setError(false);
    setSuccess(false);
    DataClient.postData(`/SuperAdmin`, admin)
      .then((res: unknown) => {
        setSuccess({
          id: res["id"],
          email: res["email"],
        });
      })
      .catch((error) => {
        console.error(error);
        setError(true);
      });
  };

  return [callback, success, error] as const;
}
