import {
  ContentState,
  convertFromRaw,
  EditorState,
  RawDraftContentState,
} from "draft-js";
import { draftjsToMd, mdToDraftjs } from "draftjs-md-converter";
import { useEffect } from "react";
import { useState } from "react";
import { Editor, SyntheticKeyboardEvent } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Control, Controller } from "react-hook-form";
import WysiwygLink from "./WysiwygLink";
import "./Wysiwyg.css";
import { TagSets } from "../views/Users/TrustAdmin/Settings/Templates/EditTemplateForm";
const doubleAllNewlines = (str: string) => str.replace(/\n/g, "\n\n");
const halfAllNewlines = (str: string) => str.replace(/\n\n/g, "\n");
//
// Wysiwig component for react-hook-form
// Currently inputs/outputs markdown, but could easily be adapted for html.
// <Wysiwyg {...{ control, name: "emailBody" }} />
//
export default ({
  control,
  name,
  tagSets = null,
}: {
  control: Control;
  name: string;
  tagSets?: TagSets;
}): JSX.Element => {
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );
  const defaultContent = control?._defaultValues?.[name];
  useEffect(() => {
    if (defaultContent) {
      setEditorState(
        EditorState.createWithContent(
          convertFromRaw(mdToDraftjs(halfAllNewlines(defaultContent)))
        )
      );
    }
  }, [defaultContent]);
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => {
        // Hide during load or it gets caught blank
        if (typeof value === "undefined") return null;
        return (
          <>
            <Editor
              editorState={editorState}
              onEditorStateChange={setEditorState}
              onChange={(currentContentState: RawDraftContentState) => {
                onChange(doubleAllNewlines(draftjsToMd(currentContentState)));
              }}
              // https://jpuri.github.io/react-draft-wysiwyg/#/docs?_k=jjqinp
              toolbar={{
                options: ["blockType", "inline", "list", "link", "history"],
                inline: {
                  inDropdown: false,
                  options: [
                    "bold",
                    "italic",
                    // "underline",
                  ],
                },
                blockType: {
                  inDropdown: true,
                  options: ["Normal", "H1", "H2", "H3"],
                },
                list: {
                  inDrodown: false,
                  options: ["unordered", "ordered"],
                },
                link: {
                  options: [], // Hide
                  // We could hijack this instead of a custom button
                  // component: WysiwygLink,
                },
              }}
              toolbarCustomButtons={[
                <WysiwygLink
                  {...{
                    contentState: ContentState,
                    editorState: EditorState,
                    onChange,
                    tagSets,
                  }}
                />,
              ]}
              toolbarStyle={{
                margin: 0,
                border: "1px solid gray",
                borderRadius: "4px 4px 0 0",
              }}
              editorStyle={{
                border: "1px solid gray",
                borderTop: "0",
                borderRadius: "0 0 4px 4px",
                height: "30rem",
              }}
              // @ts-fixed-ignore
              handleReturn={(
                e: SyntheticKeyboardEvent,
                editorState: EditorState
              ) => {
                if (e.key == "Enter") {
                  // // <br/> style
                  // const editorStateNew = RichUtils.insertSoftNewline(editorState);
                  // // <p> style
                  // const editorStateNew = insertNewUnstyledBlock(editorState);
                  // if (editorStateNew !== editorState) {
                  //   setEditorState(editorStateNew);
                  //   return true; // prevent default
                  // }
                  // <p><p> style
                  // const editorStateNew = insertNewUnstyledBlock(
                  //   insertNewUnstyledBlock(editorState)
                  // );
                  // if (editorStateNew !== editorState) {
                  //   setEditorState(editorStateNew);
                  //   return true; // prevent default
                  // }
                  // Sack off shift-enter <br/> style
                  return false;
                }
                return false;
              }}
            />
          </>
        );
      }}
    />
  );
};
// function removeSelectedBlocksStyle(editorState: EditorState) {
//   const newContentState = RichUtils.tryToRemoveBlockStyle(editorState);
//   if (newContentState) {
//     return EditorState.push(editorState, newContentState, "change-block-type");
//   }
//   return editorState;
// }
// function insertNewUnstyledBlock(editorState: EditorState) {
//   const newContentState = Modifier.splitBlock(
//     editorState.getCurrentContent(),
//     editorState.getSelection()
//   );
//   const newEditorState = EditorState.push(
//     editorState,
//     newContentState,
//     "split-block"
//   );
//   return removeSelectedBlocksStyle(newEditorState);
// }
