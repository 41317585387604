import React, { useState, useEffect, useRef } from "react";
import { Grid, LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Chart from "react-apexcharts";
import DropdownInput from "../../../components/FormComponent/dropdown";
import { ApexOptions } from "apexcharts";
import DashboardApi from "../DashboardApi";
import { ChartColors } from "./ChartConstants";
import { pathogenColor } from "../../../lib/pathogens";

const useStyles = makeStyles((theme) => ({
  loadingProgress: {
    padding: 0,
    width: "100%",
    marginTop: 7,
    height: 0,
  },
}));

export function VaccinatedStaffChart(props: any) {
  const api = DashboardApi;
  const classes = useStyles();
  const [barChartState, setBarChartState] = useState({
    series: [],
    options: {
      chart: {
        width: "100%",
        type: "bar",
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      legend: {
        position: "right",
      },
      states: {
        hover: {
          filter: "darken",
          value: 0.35,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "90%",
          stacked: false,
          horizontal: false,
          dataLabels: {
            position: "bottom",
          },
        },
      },
      xaxis: {
        labels: {
          trim: true,
          hideOverlappingLabels: false,
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        labels: {
          formatter: function (val: number, opt: any) {
            return val + "%";
          },
        },
        max: 100,
      },
      colors: (() => {
        // Replace main chart color with that of the selected pathogen
        ChartColors[0] = pathogenColor(props.pathogenIds);
        return ChartColors;
      })(),
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              show: true,
              position: "left",
            },
          },
        },
      ],
      dataLabels: {
        enabled: false,
        textAnchor: "start",
        style: {
          colors: ["#fff"],
        },
        formatter: function (val: any, opt: any) {
          return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
        },
        offsetX: 0,
        dropShadow: {
          enabled: true,
        },
      },
      tooltip: {
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function () {
              return "";
            },
          },
        },
      },
    } as ApexOptions,
  });

  const GetDropdownOptions = () => {
    const options = [
      { label: "Staff Groups", value: "staffGroups" },
      { label: "Level 1", value: "level1" },
    ];
    if (props.trustId === 0) {
      options.unshift({ label: "Trusts", value: "trust" });
    }
    return options;
  };
  const chartRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [dropdownOptions, setDropDownOptions] = useState(GetDropdownOptions());
  const [selectedDimension, setSelectedDimension] = useState(
    dropdownOptions[0],
  );

  const refreshOptions = () => {
    const options = GetDropdownOptions();
    setDropDownOptions(options);
    setSelectedDimension(options[0]);
  };

  const fetchResults = () => {
    setBarChartState({
      series: [],
      options: {
        xaxis: {
          categories: [],
        },
      },
    });
    setLoading(true);
    api
      .vaccinatedStaff(
        selectedDimension.value,
        props.pathogenIds,
        props.onlyFLHCW,
        props.trustId,
        props.excludeL1Ids,
      )
      .then((res) => {
        if (res.categories && res.categories.length > 0) {
          setBarChartState({
            series: res.series,
            options: {
              xaxis: {
                categories: res.categories,
              },
            },
          });
        }
      })
      // .catch((e) => {
      //     if (!e.handled) {
      //     }
      // })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchResults();
  }, [props, selectedDimension]);

  useEffect(() => {
    refreshOptions();
  }, [props]);

  return (
    <Grid>
      <DropdownInput
        inputFieldProps={{
          id: "dropdown_vac_chart",
          name: "dropdown_vac_chart",
          value: selectedDimension,
          placeholder: "Staff Groups",
        }}
        styles={{ marginTop: "0" }}
        options={dropdownOptions}
        setDropDownValue={(option: any) => {
          setSelectedDimension(option);
        }}
      />
      <Chart
        ref={chartRef}
        options={barChartState.options}
        series={barChartState.series}
        type="bar"
        height={460}
      />
      {loading && (
        <Grid className={classes.loadingProgress}>
          <LinearProgress color="secondary" aria-busy={loading} />
        </Grid>
      )}
    </Grid>
  );
}
