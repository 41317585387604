import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
} from "@material-ui/core";
import {
  Delete as DeleteIcon,
  KeyboardBackspace as BackIcon,
} from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { Component } from "react";
import { Link } from "react-router-dom";
import ApiAlert from "../../../../components/ApiAlert";
import { humanDate } from "../../../../lib/common_utils";
import { enumRecurrenceId } from "../../../../lib/enums";
import DataClient from "../../../../lib/services/api/DataClient";
import AlertConflicts from "./AlertConflicts";
import { ClinicDetails } from "./View";
export default class Delete extends Component {
  clinicId: string = null;
  state: {
    clinic?: Clinic;
    loading: boolean;
    alert?: JSX.Element;
  } = {
    clinic: null,
    loading: true,
  };
  constructor(props: any) {
    super(props);
    this.clinicId = props.match?.params?.clinicId;
  }
  componentDidMount() {
    DataClient.getData(`/Clinic/${this.clinicId}`)
      .then((res) => {
        const { success, results } = res;
        this.setState({
          clinic: success && results ? results : null,
          alert: ApiAlert({ res }),
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          alert: ApiAlert({ res: e.response }),
          loading: false,
        });
      });
  }
  deleteClinic = ({
    force = false,
    recurring = false,
  }: {
    force?: boolean;
    recurring?: boolean;
  }) => {
    const { id, clinicRepeatingGroupId } = this.state.clinic;
    this.setState({ loading: true });
    DataClient.remove({
      url: recurring
        ? `/Clinic/Group/${clinicRepeatingGroupId}`
        : `/Clinic/${id}`,
      params: {
        cancelReservationsIfInConflict: force,
      },
    })
      .then((res) => {
        const { success, results } = res;
        const reservationsRequiringCancellation =
          results?.conflictingReservations || {};
        console.log({ success, results, reservationsRequiringCancellation });
        this.setState({
          alert:
            !success && reservationsRequiringCancellation.length ? (
              <AlertConflicts
                {...{
                  subheading: "Are you sure you want to cancel this clinic?",
                  reservationsRequiringCancellation,
                }}
              >
                <DialogActions>
                  <Button
                    onClick={() => {
                      this.deleteClinic({ force: true, recurring });
                    }}
                    variant="contained"
                    color="secondary"
                    autoFocus
                    startIcon={<DeleteIcon />}
                  >
                    Yes
                  </Button>
                  <Button
                    component={Link}
                    to="/clinics"
                    variant="outlined"
                    color="secondary"
                    style={{
                      color: "#fff",
                    }}
                    startIcon={<BackIcon />}
                  >
                    Back
                  </Button>
                </DialogActions>
              </AlertConflicts>
            ) : !success ? (
              <Alert severity="error">
                <AlertTitle>Failed</AlertTitle>
                <p>Failed to delete clinic.</p>
              </Alert>
            ) : (
              <Alert severity="success">
                <AlertTitle>Success</AlertTitle>
                <p>Clinic deleted.</p>
                <p>
                  <Button
                    component={Link}
                    to="/clinics"
                    variant="contained"
                    color="primary"
                    startIcon={<BackIcon />}
                  >
                    Return to Clinic Planner
                  </Button>
                </p>
              </Alert>
            ),
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          alert: ApiAlert({
            res: e.response,
            title: "Could not delete clinic",
          }),
          loading: false,
        });
      });
  };
  render() {
    const { clinic, alert, loading } = this.state;
    // console.log(clinic);
    const isRecurring = enumRecurrenceId[clinic?.recurrenceId] !== "None";
    return (
      <main className="wrapper-main">
        <h1>Cancel Clinic</h1>
        {alert}
        {clinic && !alert && (
          <>
            <ClinicDetails {...clinic} />
            <Box mt={3}>
              {isRecurring ? (
                <>
                  <h2>
                    Would you like to cancel this clinic date only (
                    {humanDate(clinic.date)})?
                  </h2>
                  <p>
                    If you cancel this single occurrence, it will split the
                    recurring series.
                  </p>
                </>
              ) : (
                <h2>Are you sure you want to cancel this clinic?</h2>
              )}
              <DialogActions>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    this.deleteClinic({ force: false });
                  }}
                  type="submit"
                  variant="contained"
                  color="secondary"
                  autoFocus
                  startIcon={<DeleteIcon />}
                >
                  Yes
                </Button>
                <Button
                  component={Link}
                  to={`/clinics/${this.clinicId}/view`}
                  variant="outlined"
                  color="secondary"
                  style={{
                    color: "#fff",
                  }}
                  startIcon={<BackIcon />}
                >
                  Back
                </Button>
              </DialogActions>
            </Box>
            {isRecurring && (
              <Box mt={3}>
                <h3>- OR -</h3>
                <h2>Would you like to cancel all clinics in the series?</h2>
                <DialogActions>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      this.deleteClinic({ force: false, recurring: true });
                    }}
                    type="submit"
                    variant="contained"
                    color="secondary"
                    autoFocus
                    startIcon={<DeleteIcon />}
                  >
                    Yes
                  </Button>
                  <Button
                    component={Link}
                    to={`/clinics/${this.clinicId}/view`}
                    variant="outlined"
                    color="secondary"
                    style={{
                      color: "#fff",
                    }}
                    startIcon={<BackIcon />}
                  >
                    Back
                  </Button>
                </DialogActions>
              </Box>
            )}
          </>
        )}
        {loading && (
          <div className="interstitial">
            <CircularProgress />
          </div>
        )}
      </main>
    );
  }
}
