import { HelpCopy } from "./Help";
import styles from "./HelpInfoBox.module.scss";
export default ({ helpCopy }: { helpCopy: HelpCopy }): JSX.Element => {
  if (!helpCopy) return null;
  return (
    // <Alert severity="info" variant="filled">
    <div className={styles.HelpInfoBox}>
      {helpCopy.title && <h3>{helpCopy.title}</h3>}
      {helpCopy.content}
    </div>
  );
};
