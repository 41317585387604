import React from "react";
import ReactDOM from "react-dom";
import DataClient from "../../../../lib/services/api/DataClient";

const POST_URL = "/Trust/SaveLogo";

type Logo = {
  file: File;
};

export default function useCreateTrust() {
  const [logoUrl, setLogoUrl] = React.useState("");
  const [uploading, setUploading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const callback = (upload: Logo) => {
    setError(false);
    setLogoUrl("");
    setUploading(true);
    const data = new FormData();
    data.append("file", upload.file);
    const params = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    DataClient.postData(`${POST_URL}`, data, params)
      .then((res: string) => {
        ReactDOM.unstable_batchedUpdates(() => {
          setLogoUrl(res);
          setUploading(false);
        });
      })
      .catch((error) => {
        ReactDOM.unstable_batchedUpdates(() => {
          console.error(error);
          setError(true);
          setUploading(false);
        });
      });
  };

  return [callback, uploading, logoUrl, error] as const;
}
