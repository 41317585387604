import React from "react";
import DataClient from "../../../../lib/services/api/DataClient";

const DELETE = "/TrustAdmin/";

export default function useDeleteTrustAdmin() {
  const [success, setSuccess] = React.useState<false | number>(false);
  const [error, setError] = React.useState(false);

  const callback = (id: number) => {
    setError(false);
    setSuccess(false);
    DataClient.remove({ url: `${DELETE}${id}` })
      .then((res: unknown) => {
        if (!res["success"]) {
          throw new Error("API Update failed");
        }
        setSuccess(id);
      })
      .catch((error) => {
        console.error(error);
        setError(true);
      });
  };

  return [callback, success, error] as const;
}
