export default () => (
  <div className="wrapper-text">
    <h1>DEMONSTRATION PAGE</h1>
    <p>
      You have reached this page having clicked a link from a test email.
    </p>
    <p>
      To test live links in an invite email, use the 'Resend Invite' function (via the blue cog action's menu next to your own record on the staff page) to send an invite email to yourself.
    </p>
    <p>
      Bear in mind this will only send the 'Campaign Invite Email (for staff already with a verified account)' email. In order to test live links within the invite template for staff who have yet to verify their account, send (with the 'Resend Invite' function) to a staff member who is yet to verify their account.
      </p>
  </div>
);
