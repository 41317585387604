import moment from "moment";
import { Button } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { Edit as EditIcon, Add as AddIcon } from "@material-ui/icons";
import MUIDataTable from "mui-datatables";
import CustomDebouncedSearch from "../../../../components/CustomDebouncedSearch";
import {
  humanTime,
  humanDate,
  humanName,
  clinicIsEditable,
} from "../../../../lib/common_utils";
import { getDescriptiveLabel, Pathogens } from "../../../../lib/pathogens";
import { getCurrentTrust } from "../../../../lib/services/TrustSwitcher";
import { enumVaccinationStatus } from "../../../../lib/enums";
import ConfirmDialog from "../../ConfirmDialog";
import { useState, useEffect } from "react";
import {
  overridesTableGenericColors,
  overridesGenericCells,
  overridesTableRemoveSelect,
  overridesTableSearchBox,
  overridesMain,
  overridesToolbarRemoveLeftPadding,
} from "../../../../lib/themes/overrides";
import { palette } from "../../../../lib/themes/vars";
import { FlagIcons } from "../../../../components/FlagIcons";
import { merge } from "lodash";
import TableToolbar, { TableToolbarButtons } from "../Staff/TableToolbar";
import Switch from "@material-ui/core/Switch";
const theme = createTheme({
  palette,
  overrides: merge(
    {},
    // Not sure why it's necessary to re-include overridesMain but for some reason MuiDialogActions gets lost otherwise (see clinic booking details 'you cannot record' modal actions
    overridesMain,
    overridesGenericCells,
    overridesTableGenericColors,
    overridesTableRemoveSelect,
    overridesTableSearchBox,
    overridesToolbarRemoveLeftPadding,
  ),
});
export function BookingDetails(props) {
  let {
    bookings: bookingsProps,
    history,
    clinicId,
    clinic,
    clinicPathogens,
    editable,
    setIsPresent,
  } = props;

  const [state, setState] = useState({ showFutureBookingAlert: false });

  /* 
 When the bookingsProps changes:
  - if the bookingsProps is [] then columns = [] and bookings = []
  - 
 */

  const mapBookings = (bookingsToMap) =>
    bookingsToMap.map((b) => ({
      ...b,
      startTime: b.timeFrom ? humanTime(b.timeFrom) : "Drop In",
      dob: humanDate(b.dateOfBirth),
      vaccineBooked:
        getDescriptiveLabel(b.pathogens?.flatMap((p) => p.id)) ?? "N/A",
    }));

  let { showFutureBookingAlert } = state;

  // if (!bookings.length) return null;

  // ****
  // Update: Don't appear to need to control the update with a bookings state in the end, but leaving here for now as.. I've got a feeling it'll crop up
  // static getDerivedStateFromProps(
  //   { history, clinicId, clinic, bookings, clinicPathogens },
  //   state
  // ) {
  //   if (!isArrayEqual(bookings, state.bookings)) {
  //     console.log({ bookings });
  //     state.bookings = bookings;
  //   }
  //   return state;
  // }
  // shouldComponentUpdate() {
  //   if (!isArrayEqual(props.bookings, state.bookings)) {
  //     console.log(
  //       "should update!",
  //       props.bookings.length,
  //       state.bookings.length,
  //       whatsDifferent(props.bookings, state.bookings)
  //     );
  //     return true;
  //   }
  //   return false;
  // }

  const hasOutstandingBookingsInThisClinic = (booking) => {
    const hasOutstandingForFlu =
      booking[`vaccinationStatusNameFlu`] === "Booked" &&
      booking.pathogens.some((p) => p.name === "Flu");
    const hasOutstandingForCovid =
      booking[`vaccinationStatusNameCovid`] === "Booked" &&
      booking.pathogens.some((p) => p.name === "Covid");

    return hasOutstandingForFlu || hasOutstandingForCovid;
  };

  const currentTrust = getCurrentTrust();
  const { trustPathogens } = currentTrust || {};
  const pathogenColumns = trustPathogens
    .map((pathogen) => [
      {
        name: `vaccinationFullStatusName${pathogen.name}`,
        label: `${pathogen.name} Status`,
        options: {
          filter: false,
          sort: true,
          searchable: false,
          setCellProps: () => ({
            className: "td-status",
            "data-theme-id": Pathogens[pathogen.name],
          }),
          customBodyRender: (text) => {
            if (!bookings.length) return;
            if (
              text === "Booked in Another Clinic" ||
              text === "Booked in Another Trust"
            ) {
              return <span style={{ color: "gray" }}>{text}</span>;
            } else {
              return <span>{text}</span>;
            }
          },
          //
          // "Booked elsewhere"
          // If they want this kinda information (#228), refer to the commented code below, i.e. reservationIsWithinThisClinic etc
          //
          //
          // This was previously combined into one 'Status' column, but couldn't be sorted so changed with issue #134
          // customBodyRenderLite: (n) => {
          //   // For status field:
          //   // Combine { vaccinationStatusNameFlu: "Booked" } and similar into [{ name: "Flu", value: "Booked" }]
          //   const booking = bookings[n];
          //   const keys = Object.keys(booking).filter(
          //     (key) => key.startsWith("vaccinationStatusName") && booking[key]
          //   );
          //   const vaccinationStatusName = keys.map((key) => ({
          //     key,
          //     name: key.replace("vaccinationStatusName", ""),
          //     value: booking[key],
          //   }));
          //   return (
          //     <>
          //       {vaccinationStatusName?.map(({ name, value }) => (
          //         <div className="table-status" data-theme-id={Pathogens[name]}>
          //           {name.toUpperCase()}: {value}
          //         </div>
          //       ))}
          //     </>
          //   );
          // },
        },
      },
      {
        name: `vaccinator${pathogen.name}`,
        label: `${pathogen.name} Vaccinator`,
        options: {
          filter: false,
          sort: false,
          searchable: false,
          display: false,
          setCellProps: () => ({
            className: "td-status",
            "data-theme-id": Pathogens[pathogen.name],
          }),
        },
      },
    ])
    .reduce((a, b) => a.concat(b));

  const [bookings, setBookings] = useState(mapBookings(bookingsProps));

  const calculateColumns = (bookingsForCalcs) => {
    if (!bookingsForCalcs?.length) return;
    return [
      {
        name: "present",
        label: "Check In",
        options: {
          filter: false,
          sort: true,
          searchable: false,
          customBodyRenderLite: (dataIndex, rowIndex) => {
            const booking = { ...bookingsForCalcs[dataIndex] };
            const isPresent = booking.present;
            const reservationId = booking.reservationId;
            if (hasOutstandingBookingsInThisClinic(booking)) {
              return (
                <Switch
                  checked={isPresent}
                  onChange={() => {
                    setIsPresent(reservationId, !isPresent);
                  }}
                  color="secondary"
                  styles={{ color: "green", backgroundColor: "red" }}
                  className="greenToggle"
                />
              );
            } else {
              return <></>;
            }
          },
        },
      },
      {
        name: "startTime",
        label: "Start Time",
        options: {
          filter: false,
          sort: true,
          searchable: false,
        },
      },
      {
        name: "name",
        label: "Name",
        options: {
          filter: false,
          sort: true,
          searchable: true,
        },
      },
      {
        name: "dob",
        label: "Date of Birth",
        options: {
          filter: false,
          sort: true,
          searchable: false,
        },
      },
      {
        name: "emailAddress",
        label: "Email",
        options: {
          filter: false,
          sort: true,
          searchable: true,
        },
      },
      {
        name: "vaccineBooked",
        label: "Vaccine Booked",
      },
      {
        name: "staffGroupName",
        label: "Staff Group",
        options: {
          filter: false,
          sort: true,
          resizable: true,
          searchable: true,
          customBodyRenderLite: (i) => {
            const item = bookingsForCalcs?.[i];
            if (!item) return;
            return item?.staffGroupName
              ? item?.staffGroupName
              : item?.nonEsrStaffTypeName;
          },
        },
      },
      {
        name: "employeeNumber",
        // "Change 'Employee Number' to 'Employee No.'"
        label: "Employee No.",
        options: {
          filter: true,
          sort: true,
          resizable: true,
          searchable: true,
          display: false,
        },
      },
      {
        name: "nhsNumber",
        label: "NHS Number",
        options: {
          filter: false,
          sort: true,
          searchable: true,
        },
      },
      // #166, then removed again with #32
      // ...(trustInvolvesCovid()
      //   ? [
      //       {
      //         name: "firstCovidVaccinationDate",
      //         label: "First Covid Vaccination Date",
      //         options: {
      //           customBodyRenderLite: (i) => {
      //             return humanDate(bookings[i].firstCovidVaccinationDate);
      //           },
      //         },
      //       },
      //       {
      //         name: "lastCovidDoseDate",
      //         label: "Last Covid Vaccination Date",
      //         options: {
      //           customBodyRenderLite: (i) => {
      //             return humanDate(bookings[i].lastCovidDoseDate);
      //           },
      //         },
      //       },
      //     ]
      //   : []),
      {
        name: "mobileNumber",
        label: "Mobile",
        options: {
          filter: false,
          sort: false,
          searchable: true,
          display: false,
        },
      },
      {
        name: "level1",
        label: "Level 1",
        options: {
          filter: false,
          sort: false,
          searchable: true,
          display: false,
        },
      },
      {
        name: "level2",
        label: "Level 2",
        options: {
          filter: false,
          sort: false,
          searchable: true,
          display: false,
        },
      },
      {
        name: "level3",
        label: "Level 3",
        options: {
          filter: false,
          sort: false,
          searchable: true,
          display: false,
        },
      },
      {
        name: "level4",
        label: "Level 4",
        options: {
          filter: false,
          sort: false,
          searchable: true,
          display: false,
        },
      },
      {
        name: "jobRoleName",
        label: "Job Role",
        options: {
          filter: false,
          sort: false,
          searchable: true,
          display: false,
        },
      },
      // P3#2.2 "We will also hide the Flu/Covid status columns on ‘old’ clinics before the agreed cut-off date, as these will display the current system status on old clinics…"
      ...(clinicIsEditable(clinic.date) ? pathogenColumns : []),
      {
        name: "vaccinator",
        label: "Vaccinator",
        options: {
          filter: false,
          sort: false,
          searchable: false,
          display: true,
          download: false,
          customBodyRenderLite: (i) => {
            // Combine { vaccinatorFlu: "Foo" } and similar into [{ name: "Flu", pathogen: "flu" }]
            const booking = bookingsForCalcs[i];
            if (!booking) return;
            const keys = Object.keys(booking).filter(
              (key) => key.startsWith("vaccinator") && booking[key],
            );
            const vaccinators = keys.map((key) => ({
              name: booking[key],
              pathogen: key.replace("vaccinator", ""),
            }));
            return (
              <>
                {vaccinators?.map(({ name, pathogen }) => (
                  <div
                    className="table-status"
                    data-theme-id={Pathogens[pathogen]}
                  >
                    {pathogen.toUpperCase()}: {name}
                  </div>
                ))}
              </>
            );
          },
        },
      },
      {
        name: "flags",
        label: "Flags",
        options: {
          display: "true", // by default
          // customBodyRenderLite is more performant when sorting isn't necessary
          customBodyRenderLite: (i) => {
            const item = bookingsForCalcs?.[i];
            if (!item) return;
            const flags = FlagIcons(item);
            return flags;
          },
        },
      },
      {
        name: "manage",
        label: " ",
        options: {
          filter: false,
          sort: false,
          searchable: false,
          viewColumns: false,
          customBodyRenderLite: (i) => {
            if (!clinicIsEditable(clinic.date)) return null;
            // Which pathogens appear is defined by the scope of the trust,
            // completely ignoring the scope of the clinic
            // https://techdepartment.mydonedone.com/issuetracker/projects/84148/issues/29
            const booking = bookingsForCalcs[i];
            if (!booking) return;
            const trustWorkerId = booking.trustWorkerId;
            const reservationPathogens = booking?.pathogens || []; // Which pathogens this reservation currently has booked
            return trustPathogens?.map((p) => {
              const status = booking[`vaccinationStatusName${p.name}`];
              const hasReservationForThisPathogen = !!(
                // if booking has something like vaccinationStatusNameCovid: "Booked", rather than "No Booking" or empty
                (status && status !== enumVaccinationStatus["No Booking"])
              );
              const reservationIsWithinThisClinic = reservationPathogens.some(
                (pathogen) => pathogen.id === p.id,
              );
              const clinicAllowsPathogen = clinicPathogens.some(
                (pathogen) => pathogen.id === p.id,
              );
              if (
                hasReservationForThisPathogen &&
                !reservationIsWithinThisClinic
              ) {
                return null;
                // Hiding any status info, it's redundant here as it'll be in the status column (albeit without knowing if it were elsewhere)
                // return (
                //   <div className="table-status" data-theme-id={p.id}>
                //     {/* FLU: No Booking */}
                //     {/* FLU: Vaccinated */}
                //     {/* FLU: Booked elsewhere */}
                //     {p.name.toUpperCase()}: {status}{" "}
                //     {[
                //       enumVaccinationStatus["Booked"],
                //       enumVaccinationStatus["Vaccinated"],
                //     ].includes(status)
                //       ? "elsewhere"
                //       : ""}
                //   </div>
                // );
              } else {
                // Whether they say 'Manage' or 'Add' is defined by the booking itself - comparing the reservation's pathogens values (intended for user) and vaccinationStatusNameX (overridden reality) (Not that last part though..)
                const title = `${
                  hasReservationForThisPathogen ? "Manage" : "Add"
                } ${p.name}`;
                return (
                  <div>
                    <Button
                      color="primary"
                      aria-label={title}
                      size="small"
                      data-theme-id={p.id}
                      onClick={() => {
                        if (hasFutureBooking) {
                          setState({ showFutureBookingAlert: true });
                        } else {
                          // Control vaccination details modal with states, so they can close the modal and remain on the same page in the booking table, but ...
                          props.setActiveModal("vaccinationDetails");
                          props.setTrustWorkerId_VD(trustWorkerId);
                          props.setTrustWorkerName_VD(
                            humanName(booking.firstName, booking.lastName),
                          );
                          props.setTrustWorkerDob_VD(booking.dob);
                          props.setPathogenId_VD(p.id);
                          props.setPathogenName_VD(p.name);
                          props.setUnplannedVaccation_VD(!clinicAllowsPathogen);
                          const trustWorkerFlags_VD = []
                          booking.flagOver65 && trustWorkerFlags_VD.push('flagOver65')
                          booking.flagUnder18 && trustWorkerFlags_VD.push('flagUnder18')
                          booking.flagFrontline && trustWorkerFlags_VD.push('flagFrontline')
                          booking.flagCovidVaccinated && trustWorkerFlags_VD.push('flagCovidVaccinated')
                          booking.flagFluVaccinated && trustWorkerFlags_VD.push('flagFluVaccinated')
                          props.setTrustWorkerFlags(trustWorkerFlags_VD);
                            
                          // ... also update the URL (in a non-routing way) so they could refresh/share the URL to this open-modal page
                          window.history.replaceState(
                            null,
                            "VaccinationTrack",
                            `/clinics/${clinicId}/vaccination-details/${trustWorkerId}/${
                              p.id
                            }/${p.name}${
                              !clinicAllowsPathogen
                                ? "/unplannedVaccination"
                                : ""
                            }`,
                          );
                        }
                      }}
                      startIcon={
                        title.startsWith("Manage") ? <EditIcon /> : <AddIcon />
                      }
                    >
                      {title}
                    </Button>
                  </div>
                );
              }
            });
          },
        },
      },
    ];
  };

  useEffect(() => {
    // If there are no bookings we don't need to care about the columns
    if (bookingsProps.length === 0) {
      setPersistentColumns([]);
      setBookings([]);
      return;
    }
    // If there are bookingProps, we need to map them update the bookings state.
    const newMappedBookings = mapBookings(bookingsProps);
    setBookings(newMappedBookings);
    // if the number of bookings has changed, we need to recalculate the columns
    // if (bookingsProps.length !== bookings.length) {
    const updatedColumnsWithResetVisibility =
      calculateColumns(newMappedBookings);
    const updatedColumnsWithCorrectVisibility = updateColumnVisibility(
      persistentColumns,
      updatedColumnsWithResetVisibility,
    );

    if (updatedColumnsWithCorrectVisibility.length) {
      setPersistentColumns(updatedColumnsWithCorrectVisibility);
    } else {
      setPersistentColumns(updatedColumnsWithResetVisibility);
    }
    // }

    //   updateColumnVisibility(columns(mappedBookings), persistentColumns);
    // const updatedCols = updateColumnVisibility(persistentColumns);
    // setPersistentColumns(columns(mappedBookings));
    // if (mappedBookings.length) {
    // }
  }, [bookingsProps]);

  const [persistentColumns, _setPersistentColumns] = useState();
  const setPersistentColumns = (columns) => {
    if (columns?.length) {
      _setPersistentColumns(columns);
    } else {
      _setPersistentColumns([]);
    }
  };

  // When a columns visibility is toggled, go through the currently persisted columns array and update the visibility to the tableState.
  function updateColumnVisibility(
    columnsWithCorrectVisibility,
    columnsWithCorrectData,
  ) {
    if (!columnsWithCorrectVisibility?.length) {
      return columnsWithCorrectData;
    }
    const OgColumns = columnsWithCorrectData;
    // const OgColumns = columns(bookings);
    const updatedCols = OgColumns.map((col, i) => {
      const ogCol = { ...col };
      if (columnsWithCorrectVisibility[i]) {
        if (!Object.hasOwn(ogCol, "options")) {
          ogCol["options"] = {};
        }
        if (!Object.hasOwn(ogCol.options, "display")) {
          ogCol.options["display"] = {};
        }
      }
      // The columnsWithCorrectVisibility can come from the tableState, or from the persistentColumns state. These have different structures, so we need to check for both.
      const visibility =
        columnsWithCorrectVisibility[i]?.options?.display ||
        columnsWithCorrectVisibility[i]?.display;

      ogCol["options"]["display"] = visibility;
      return ogCol;
    });
    return updatedCols;
  }

  const options = {
    onTableChange: (action, tableState) => {
      if (action === "viewColumnsChange") {
        const updatedColumns = updateColumnVisibility(
          tableState.columns,
          persistentColumns,
        );
        setPersistentColumns(updatedColumns);
      }
    },
    selectableRows: "none",
    sortOrder: {
      name: "startTime",
      direction: "asc",
    },
    elevation: 0,
    filterType: "dropdown",
    searchPlaceholder: "Search",
    responsive: "vertical",
    rowsPerPage: 100,
    // rowsPerPage: 2, // ********** DEBUG ONLY *******
    // page: 2, // 0,1,2..
    // Disabling rows dropdown as it interferes with #130
    // i.e. if you change rows per page, then paginate, then open VD modal.. the table pagination resets
    rowsPerPageOptions: [],
    // rowsPerPageOptions: [2, 4, 25, 50, 100, 250],
    // Removing various icons
    // https://teams.microsoft.com/l/entity/com.microsoft.teamspace.tab.planner/_djb2_msteams_prefix_203787529?webUrl=https%3a%2f%2ftasks.office.com%2f587a665b-7b94-438b-a7cc-c9bdd5cd0066%2fHome%2fPlanViews%2fOp0ITplCdE-nw8mWn5un8JYAA2Q1%3fType%3dPlanLink%26Channel%3dTeamsTab&label=Changes+to+booking+table+in+Breakdown+(WIP)+&context=%7b%0d%0a++%22subEntityId%22%3a+%22TBeum80qBECGV97RAb-ZPZYAFmdD%22%2c%0d%0a++%22channelId%22%3a+%2219%3a71ec9d43c17f40dd8e626aa777c0ff8d%40thread.tacv2%22%0d%0a%7d&tenantId=587a665b-7b94-438b-a7cc-c9bdd5cd0066
    filter: false,
    print: false,
    textLabels: {
      body: {
        noMatch: "No Bookings found",
        toolTip: "Sort",
        columnHeaderTooltip: (column) => `Sort for ${column.label}`,
      },
      pagination: {
        next: "Next Page",
        previous: "Previous Page",
        rowsPerPage: "Rows per page:",
        displayRows: "of",
      },
      toolbar: {
        search: "Search",
        downloadCsv: "Download Clinic List as CSV",
        print: "Print",
        viewColumns: "View Columns",
        filterTable: "Filter Table",
      },
      filter: {
        all: "All",
        title: "FILTERS",
        reset: "RESET",
      },
      viewColumns: {
        title: "Show Columns",
        titleAria: "Show/Hide Table Columns",
      },
      selectedRows: {
        text: "Booking(s) selected",
        delete: "Delete",
        deleteAria: "Delete Selected Bookings",
      },
    },
    downloadOptions: {
      filename:
        "Clinic List - " +
        clinic.clinicLocationName +
        " " +
        moment(clinic.date).format("DD-MM-YYYY") +
        ".csv",
      separator: ",",
    },
    searchAlwaysOpen: true,
    customSearchRender: (searchText, handleSearch, hideSearch, options) => (
      <CustomDebouncedSearch
        {...{
          searchText,
          handleSearch,
          hideSearch,
          options,
        }}
      />
    ),
    download: false, // see TableToolbar
    customToolbar: () => (
      <TableToolbar
        {...{
          buttonsToShow: [TableToolbarButtons.downloadBookingDetails],
          filter: { clinicId, pageSize: -1 },
        }}
      />
    ),
  };
  const hasFutureBooking =
    new Date(clinic.date) > new Date(new Date().setHours(0, 0, 0, 0));

  return (
    <ThemeProvider theme={theme}>
      <MUIDataTable
        data={bookings}
        columns={persistentColumns}
        options={options}
        elevation={0}
        className="booking-details-table table-horizontal-squeeze table-fonts table-hack-actions"
      />
      <ConfirmDialog
        open={showFutureBookingAlert}
        onClose={() => {
          setState({ showFutureBookingAlert: false });
        }}
        {...{
          title: `You cannot record a vaccination for a future appointment. Instead use the ‘Add Drop In’ function in a clinic for TODAY, then cancel their future appointment and drop them in for today's clinic`,
          showConfirm: false,
          closeLabel: "Ok",
        }}
      />
    </ThemeProvider>
  );
}
