import { Pathogen } from "../../lib/pathogens";
import DataClient from "../../lib/services/api/DataClient";
import { TrustGroup } from "../Users/SuperAdmin/TrustGroup/TrustGroups";

export const EventLogDataService = {
  baseUrl: "Event",
  getClinicResults: (
    filter: ClinicChangesetSearchFilterDTO
  ): Promise<{ pagedResults: ClinicChangesetDTO[]; totalCount: number }> =>
    DataClient.postData(
      `${EventLogDataService.baseUrl}/clinics/search`,
      filter
    ).then(
      (res: { pagedResults: ClinicChangesetDTO[]; totalCount: number }) => res
    ),
  getClinicDetails: (id: number): Promise<ClinicChangesetDetailedDTO> =>
    DataClient.getData(`${EventLogDataService.baseUrl}/clinics/${id}`).then(
      (res: { results: ClinicChangesetDetailedDTO }) => res.results
    ),
  getReservationResults: (
    filter: ReservationChangesetSearchFilterDTO
  ): Promise<{ pagedResults: ReservationChangesetDTO[]; totalCount: number }> =>
    DataClient.postData(
      `${EventLogDataService.baseUrl}/reservations/search`,
      filter
    ).then(
      (res: { pagedResults: ReservationChangesetDTO[]; totalCount: number }) =>
        res
    ),
  getReservationDetails: (
    id: number
  ): Promise<ReservationChangesetDetailedDTO> =>
    DataClient.getData(
      `${EventLogDataService.baseUrl}/reservations/${id}`
    ).then((res: { results: ReservationChangesetDetailedDTO }) => res.results),
};

export const TrustDataService = {
  baseUrl: "Trust",
  getAllTrusts: (): Promise<TrustDetailedDTO[]> =>
    DataClient.getData(`${TrustDataService.baseUrl}`).then(
      (res: { results: TrustDetailedDTO[] }) => res.results
    ),
};

// Hardcoded Data
export type EventType = "clinic" | "reservation";
export interface ChangeSetTypeOption {
  label: string;
  value: number;
  eventType: EventType;
}

// Typings

export interface ChangesetBase {
  id: number;
  type: ChangesetType;
  parentChangeset: RelatedChangeset;
  childChangesetTypes: Record<ChangesetType, number>;
}

// Clinic Types
export interface ClinicChangesetDTO extends ChangesetBase {
  id: number;
  type: ChangesetType;
  dateFrom: string; // DateTime
  dateTo: string; // DateTime
  timeFrom: any; //TimeSpan
  timeTo: any; //TimeSpan
  clinicId: number;
  clinicDate: string; // DateTime
  clinicLocationId: number;
  clinicLocationName: string;
  hospitalSiteId: number;
  hospitalSiteName: string;
  parentChangeset: RelatedChangeset;
  childChangesetTypes: Record<ChangesetType, number>; // Not 100% sure this is correct Typing
  createdDate: string; //DateTimeOffset
}

export interface ClinicChangesetDetailedDTO extends ClinicChangesetDTO {
  data: any; //object containing data not included in ClinicChangeSetDTO
}

export type ClinicDetailsLookup = Record<string, ClinicChangesetDetailedDTO>;

export type ChangesetDetailsLookup<ChangesetType> = Record<
  string,
  ChangesetType
>;

export interface ClinicChangesetSearchFilterDTO extends SearchFilterBase {
  orderBy: string;
  parentChangesetId?: number;
  type?: ChangesetType;
  dateFrom?: string; //DateTime
  dateTo?: string; //DateTime
  trustId?: number;
  clinicId?: number;
  clinicDateFrom?: string; //DateTime
  clinicDateTo?: string; //DateTime
  hospitalSiteId?: number;
  hospitalSiteName?: string; // temp optional
  clinicLocationId?: number;
  clinicLocationName?: string; // temp optional
}

export interface RelatedChangeset {
  id: number;
  type: ChangesetType;
  createdDate: string;
}

export interface SearchFilterBase {
  searchText: string;
  pageNumber: number;
  pageSize: number; // The page size of returned results. '-1' will return all results.
  orderBy: string;
  createdFrom?: string; //DateTimeOffset
  createdTo?: string; //DateTimeOffset
  editedFrom?: string; //DateTimeOffset
  editedTo?: string; //DateTimeOffset
  // below added for generic filter
  type?: ChangesetType;
  dateFrom?: string;
  dateTo?: string;
}

export enum ChangesetType {
  CreateSingleClinic = 1,
  UpdateSingleClinic = 2,
  CancelSingleClinic = 3,
  CreateRecurringClinc = 4,
  UpdateRecurringClinic = 5,
  CancelRecurringClinic = 6,
  SuspendClinic = 7,
  EnableClinic = 8,
  AddClinicVaccinationBatch = 9,
  DeleteClinicVaccinationBatch = 10,
  UpdateClinicVaccinationBatch = 11,
  UpdatePrimaryClinicVaccinationBatch = 12,
  SplitClinic = 13,
  CreateReservation = 14,
  RescheduleReservation = 15,
  CancelReservation = 16,
  CloseClinic = 17,
}

// Trusts

export interface TrustDetailedDTO {
  id: number;
  trustName: string;
  superAdminId: number;
  logoUrl: string;
  registrationSegment: string;
  trustGroupId?: number;
  notifyStaffWelcome: boolean;
  notifyAssignmentVaccinator: boolean;
  notifyAssignmentClinicAdmin: boolean;
  notifyAssignmentReportingAdmin: boolean;
  notifyBookingUpcoming: boolean;
  notifyBookingCancelled: boolean;
  notifyBookingRescheduled: boolean;
  notifyReminderToBookFlu: boolean;
  notifyReminderToBookCovid: boolean;
  bookingsFluOnly?: number;
  bookingsCovidOnly?: number;
  bookingsCombined?: number;
  pathogens: Pathogen[];
  trustGroup: TrustGroup;
  createdDate: string;
}

// Reservations
// Filter
export interface ReservationChangesetSearchFilterDTO extends SearchFilterBase {
  type?: ChangesetType;
  dateFrom?: string;
  dateTo?: string;
  parentChangesetId?: number;
  trustId?: number;
  reservationId?: number;
  clinicId?: number;
  clinicDateFrom?: string;
  clinicDateTo?: string;
  hospitalSiteId?: number;
  hospitalSiteName?: string;
  clinicLocationId?: number;
  clinicLocationName?: string;
  trustWorkerId?: number;
  trustWorker?: string;
}

// export interface SearchFilter {
//   pageNumber: number;
//   pageSize: number;
//   orderBy: string;
//   createdFrom?: string;
//   createdTo?: string;
//   editedFrom?: string;
//   editedTo?: string;
// }

// Changesets

export interface ReservationChangesetDTO extends ChangesetBase {
  id: number;
  type: ChangesetType;
  reservationId: number;
  arrivalDateTime?: string;
  clinicId: number;
  clinicDate: string;
  clinicLocationId: number;
  clinicLocationName: string;
  hospitalSiteId: number;
  hospitalSiteName: string;
  trustWorkerId: number;
  trustWorkerFirstName: string;
  trustWorkerLastName: string;
  trustWorkerEmailAddress: string;
  parentChangeset: RelatedChangeset;
  childChangesetTypes: Record<ChangesetType, number>;
  createdDate: string;
}

export interface ReservationChangesetDetailedDTO {
  id: number;
  type: ChangesetType;
  reservationId: number;
  arrivalDateTime?: string;
  clinicId: number;
  clinicDate: string;
  clinicLocationId: number;
  clinicLocationName: string;
  hospitalSiteId: number;
  hospitalSiteName: string;
  trustWorkerId: number;
  trustWorkerFirstName: string;
  trustWorkerLastName: string;
  trustWorkerEmailAddress: string;
  data: any;
  parentChangeset: RelatedChangeset;
  childChangesets: RelatedChangeset[];
  createdDate: string;
}
export interface ReservationChangesetDetailedDTO
  extends ReservationChangesetDTO {
  data: any; //object containing data not included in ReservationChangeSetDTO
}
