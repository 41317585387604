import { LinearProgress } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { Pathogens } from "../lib/pathogens";
import DataClient from "../lib/services/api/DataClient";
import { VaccinationHistoryIdentifiers } from "../lib/VaccinationHistoryIdentifierEnum";
import { MeContext } from "../views/Users/Patients/MyAppointments/MyAppointments";
import ApiAlert from "./ApiAlert";
import { VaccinationHistoryItem } from "./VaccinationHistoryItem";

interface PrescreenConsentAnswer {
  answer: boolean;
  isConsentQuestion: boolean;
  questionText: string;
}
interface VaccinationRecord {
  // If this changes, change recordsMeddled.push further down
  adverseReaction?: string;
  allergyStatus?: string;
  clinicName?: string;
  date?: string;
  injectionSite?: string;
  prescribingMethod?: string;
  location?: string;
  otherInformation?: string;
  time?: string;
  vaccinationIdentifier?: number;
  vaccinator?: string;
  vaccineBatchNumber?: string;
  vaccineExpiryDate?: string;
  vaccineDefrostDate?: string;
  vaccineType?: string;
  prescreeningAnswers: PrescreenConsentAnswer[];
  consentAnswers: PrescreenConsentAnswer[];
}
export interface VaccinationRecordAfterMeddling extends VaccinationRecord {
  delete?: boolean;
  pathogen?: Pathogens;
}
const meddleWithRecords = (
  records: VaccinationRecord[],
  allowViewEmptyRecords: boolean,
  injectMissingRecordsUsingVaccinationIdentifiiers: boolean
): VaccinationRecordAfterMeddling[] => {
  const recordsMeddled = (records as VaccinationRecordAfterMeddling[])
    .map((r, i) => {
      // Mark entirely null records for deletion
      // - but ignore vaccinationIdentifier as that can't be nullified
      if (!allowViewEmptyRecords) {
        if (
          Object.keys(r)
            .filter((k) => !["vaccinationIdentifier", "date"].includes(k))
            .every((k) => r[k] === null)
        ) {
          if (
            !r.date ||
            // Also delete edge cases where date is borked (reported but can't replicate)
            r.date.includes("0001-01-01") ||
            r.date.toLowerCase() === "invalid date"
          ) {
            r.delete = true;
          }
          return r;
        }
      }
      // Add pathogen, deduced from vaccinationIdentifier
      return r;
    })
    // Cull records marked for deletion (unless allowViewEmptyRecords)
    .filter((r) => !r.delete);
  // Add missing records as they're often not returned from the API
  if (injectMissingRecordsUsingVaccinationIdentifiiers) {
    VaccinationHistoryIdentifiers.forEach((v) => {
      if (!recordsMeddled.find((r) => r.vaccinationIdentifier === v.id)) {
        recordsMeddled.push({
          adverseReaction: null,
          allergyStatus: null,
          clinicName: null,
          date: null,
          injectionSite: null,
          location: null,
          otherInformation: null,
          time: null,
          vaccinationIdentifier: v.id,
          vaccinator: null,
          vaccineBatchNumber: null,
          vaccineExpiryDate: null,
          vaccineType: null,
          consentAnswers: [],
          prescreeningAnswers: [],
        });
      }
    });
  }
  // Sort by (reverse) order of VaccinationHistoryIdentifiers
  recordsMeddled.sort(
    (a, b) =>
      VaccinationHistoryIdentifiers.map((i) => i.id).indexOf(
        b.vaccinationIdentifier
      ) -
      VaccinationHistoryIdentifiers.map((i) => i.id).indexOf(
        a.vaccinationIdentifier
      )
  );
  return recordsMeddled;
};
export function VaccinationHistory({
  id,
  allowViewEmptyRecords = false,
  injectMissingRecordsUsingVaccinationIdentifiiers = false,
  allowEdit = false,
}: {
  id?: number;
  allowViewEmptyRecords?: boolean;
  injectMissingRecordsUsingVaccinationIdentifiiers?: boolean;
  allowEdit?: boolean;
}): JSX.Element {
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(true);
  const [vaccinationRecords, setVaccinationRecords] = useState([]);
  const [
    vaccinationHistoryItemIsExpanded,
    setVaccinationHistoryItemIsExpanded,
  ] = useState(false);
  const { me } = useContext(MeContext);
  const idProvided = !!id;
  useEffect(
    function getVaccinationHistory() {
      if (!idProvided) {
        if (!me) {
          return;
        } else {
          id = me?.currentTrust.trustWorkerId;
        }
      }
      DataClient.getData(`/TrustWorker/${id}/VaccinationHistory`)
        .then(
          ({
            success,
            results,
          }: {
            success: boolean;
            results: VaccinationRecord[];
          }) => {
            if (!success || !results) throw new Error();
            setVaccinationRecords(
              meddleWithRecords(
                results,
                allowViewEmptyRecords,
                injectMissingRecordsUsingVaccinationIdentifiiers
              )
            );
          }
        )
        .catch((e) => {
          console.error(e);
          // setAlert(ApiAlert({ res: e.response }));
          setAlert(
            ApiAlert({
              alwaysShow: true,
              message: "Could not retrieve vaccination records.", // KISS
            })
          );
        })
        .finally(() => {
          setLoading(false);
        });
      // ...
      // Strip out records with all null values(?)
    },
    [me]
  );
  if (loading) return <LinearProgress />;
  else if (alert) return alert;
  else
    return (
      <table className="table -vaccination-history">
        {vaccinationRecords?.length ? (
          <>
            {!vaccinationHistoryItemIsExpanded && (
              <tr>
                <th>Date</th>
                <th>Vaccine</th>
                <th>Clinic Site</th>
                <th>Vaccine Type</th>
                <th>Vaccine Batch Number</th>
                <th></th>
              </tr>
            )}
            {vaccinationRecords.map(
              (record: VaccinationRecordAfterMeddling, key) => {
                // (originally tickets #4450 & #4652, but now a yearly thing)
                // Disable edit for CovidBooster2 / FluBooster2 during first-half 2023;
                // then make Booster 3 the non-editable ones once we hit the 23/24 season
                // then make Booster 4 the non-editable ones for the 24/25 season
                const ThisYearsBoosters = VaccinationHistoryIdentifiers.filter(
                  (v) => ["FluBooster4", "CovidBooster4"].includes(v.key)
                )
                  ?.map((v) => v.id)
                  ?.some((id) => id === record.vaccinationIdentifier);
                return (
                  <VaccinationHistoryItem
                    {...{
                      key,
                      id,
                      record,
                      allowEdit: !ThisYearsBoosters && allowEdit,
                      setVaccinationHistoryItemIsExpanded,
                    }}
                  />
                );
              }
            )}
          </>
        ) : (
          <p>No records</p>
        )}
      </table>
    );
}
