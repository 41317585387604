import React from "react";
import {
  getCurrentTrust,
  getRolesGeneric,
  getTrusts,
} from "../services/TrustSwitcher";
import { TrustRole } from "../types/TrustRoles";

const UnauthorisedComponent = ({
  multipleTrusts,
}: {
  multipleTrusts: boolean;
}) => (
  <div className="wrapper-main">
    <div className="wrapper-text">
      {!multipleTrusts && (
        <>
          <h1>You are not authorised to view this page</h1>
          <p>
            <strong>This is for one of the following reasons:</strong>
          </p>
          <ol>
            <li>
              Your account is no longer active as your record has been removed
              from VaccinationTrack.
              <br />
              This is most likely because you have left the Trust. If you think
              this is an error, please contact your vaccination campaign
              administrator.
              <br />
              <br />
            </li>
            <li>
              Your account doesn't have the appropriate access rights to view
              this page.
            </li>
          </ol>
        </>
      )}
      {multipleTrusts && (
        <>
          <h1>You are not authorised for this page with this trust.</h1>
          <h2>Please select a different trust.</h2>
        </>
      )}
    </div>
  </div>
);

export const requireAuthBoolean = (allowedRoles: TrustRole[]): boolean => {
  const currentTrust = getCurrentTrust();
  const roles = getRolesGeneric();
  const userRoles = roles ?? currentTrust?.roles ?? [];
  // console.log({
  //   currentTrust,
  //   roles,
  //   userRoles,
  // });
  return allowedRoles.some((role) => userRoles.includes(role));
};

export const requireAuth =
  (allowedRoles: TrustRole[]) =>
  (ComposedComponent: React.ComponentType) =>
  (props: any): JSX.Element => {
    const trusts = getTrusts();
    if (requireAuthBoolean(allowedRoles)) {
      return <ComposedComponent {...props} />;
    } else {
      return <UnauthorisedComponent multipleTrusts={trusts?.length > 1} />;
    }
  };

export default requireAuth;
