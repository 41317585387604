import React, { Component, Fragment } from "react";
import { Route } from "react-router";
import { ApplicationPaths } from "./ApiAuthorizationConstants";
import { Login } from "./Login";
import { Logout } from "./Logout";
export default class ApiAuthorizationRoutes extends Component {
  render() {
    return (
      <Fragment>
        <Route
          path={ApplicationPaths.Login}
          render={() => <Login action="login" />}
        />
        <Route
          path={ApplicationPaths.LoginFailed}
          render={() => <Login action="loginFailed" />}
        />
        <Route
          path={ApplicationPaths.LoginCallback}
          render={() => <Login action="loginCallback" />}
        />
        <Route
          path={ApplicationPaths.Profile}
          render={() => <Login action="profile" />}
        />
        <Route
          path={ApplicationPaths.Register}
          render={() => <Login action="register" />}
        />
        <Route
          path={ApplicationPaths.LogOut}
          render={() => <Logout action="logout" />}
        />
        <Route
          path={ApplicationPaths.LogOutCallback}
          render={() => <Logout action="logoutCallback" />}
        />
        <Route
          path={ApplicationPaths.LoggedOut}
          render={() => <Logout action="loggedOut" />}
        />
      </Fragment>
    );
  }
}
