import {
  FormControl,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { useForm, Controller, useWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import api from "./data";
import { humanPathogen, Pathogens } from "../../../../lib/pathogens";
import { enumVaccinationStatus } from "../../../../lib/enums";
import ConfirmDialog from "../../ConfirmDialog";
import { humanNameFromObject } from "../../../../lib/common_utils";

function ExemptDialog({
  open,
  onClose,
  onSuccess,
  onError,
  value,
  pathogen,
  onRemove,
}) {
  const [confirmDialog, setConfirmDialog] = useState(null);
  const [loading, setLoading] = useState(false);
  const { handleSubmit, reset, control, formState } = useForm();
  const canUndoExemption =
    value?.pathogenPermissions[pathogen]?.canRecordDecline === false;
  useEffect(() => {
    reset(
      value
        ? {
            id: value?.id,
            confirm:
              value[`vaccinationStatusId${Pathogens[pathogen]}`] ===
              enumVaccinationStatus.Exempt
                ? true
                : null,
            declineDescription:
              value[`declineDescription${Pathogens[pathogen]}`],
          }
        : {}
    );
  }, [value, pathogen, reset]);

  const data = useWatch({
    control,
  });

  const onSubmit = (data) => {
    setConfirmDialog(true);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>
          Exemption for {Pathogens[pathogen]} Vaccinations
        </DialogTitle>

        <DialogContent>
          <form
            id="decline"
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="confirm"
              control={control}
              rules={{ required: "Please confirm" }}
              render={({ field }) => {
                return (
                  <FormControl error={formState.errors?.confirm}>
                    <FormControlLabel
                      label={`Mark ${humanNameFromObject(
                        value
                      )} as 'Exempt' from ${humanPathogen(
                        pathogen
                      )} vaccinations`}
                      control={
                        <Checkbox
                          onChange={(e) => field.onChange(e.target.checked)}
                          checked={field.value}
                        />
                      }
                    />
                    {formState.errors?.confirm && (
                      <FormHelperText>
                        {formState.errors?.confirm?.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                );
              }}
            />

            <Controller
              name="declineDescription"
              control={control}
              rules={{ required: "Reason is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Reason for Exemption *"
                  multiline
                  rows={4}
                  error={formState.errors?.declineDescription}
                  helperText={
                    formState.errors?.declineDescription?.message ??
                    "*Please explain why the staff member is exempt and give details of what evidence has been supplied to support the exemption."
                  }
                />
              )}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            form="decline"
            type="submit"
            variant="contained"
            color="secondary"
            autoFocus
          >
            Save
          </Button>
          <Button
            onClick={onClose}
            variant="outlined"
            color="secondary"
            style={{
              color: "#fff",
            }}
          >
            Cancel
          </Button>
          <div
            style={{
              flex: "1 1 auto",
            }}
          />
          {canUndoExemption && (
            <Button
              onClick={onRemove}
              variant="outlined"
              style={{
                float: "right",
                backgroundColor: "red",
                color: "#fff",
              }}
            >
              Undo Exemption
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        open={!!confirmDialog}
        disableConfirm={loading}
        title={`Are you sure you want to mark ${value?.firstName} ${
          value?.lastName
        } as 'Exempt' from ${humanPathogen(Pathogens[pathogen])} vaccinations?`}
        onConfirm={() => {
          setLoading(true);
          api.staff
            .decline(data.id, 10, data.declineDescription, pathogen)
            .then(onSuccess)
            .catch(onError)
            .finally(() => {
              setConfirmDialog(null);
              setLoading(false);
            });
        }}
        onClose={() => setConfirmDialog(null)}
      >
        <p>
          Please ensure you have received appropriate written authorisation.
          They will not be invited for any future {humanPathogen(pathogen)}{" "}
          vaccinations as long as they are marked as 'Exempt'.
        </p>
      </ConfirmDialog>
    </>
  );
}

export { ExemptDialog };
