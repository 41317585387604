import { LinearProgress } from "@material-ui/core";
import React from "react";
import { useParams } from "react-router-dom";
import ToastMessage from "../../../../components/Universal/toast_message";
import ConfirmDialog from "../../ConfirmDialog";
import useTrustGroupUser from "./useTrustGroupUser";
import NavContainer from "../NavContainer";
import TrustGroupUserForm from "./TrustGroupUserForm";
import DataClient from "../../../../lib/services/api/DataClient";

export default function TrustGroupUserCreateUpdate() {
  const { id } = useParams<{ id: string }>();
  const [feedback, setFeedback] =
    React.useState<{ type: "success" | "error"; message: string }>(null);
  const [trustGroups, setTrustGroups] = React.useState([]);
  const [data, loading, error] = useTrustGroupUser(
    id === "new" ? null : Number(id)
  );
  React.useEffect(() => {
    if (error) {
      setFeedback({
        type: "error",
        message: "There was a problem loading the System User.",
      });
    }
  }, [error]);

  const fetchTrustGroups = async () => {
    // setLoading(true);
    // setAlert(null);
    const res = await DataClient.postData(`/TrustGroup/Search`, {
      pageSize: -1,
    })
      .catch((e) => {
        console.error(e);
        // setAlert(ApiAlert({ res: e.response }));
      })
      .finally(() => {
        // setLoading(false);
      });
    setTrustGroups(res?.results?.pagedResults || null);
  };
  React.useEffect(() => {
    fetchTrustGroups();
  }, []);

  const isUpdate = id !== "new";
  const [warningDialog, setWarningDialog] = React.useState<boolean | string[]>(
    false
  );
  const [proceedIfWarnings, setProceedIfWarnings] = React.useState(false);
  return (
    <>
      {loading && <LinearProgress color="primary" />}
      <NavContainer>
        <TrustGroupUserForm
          setFeedback={setFeedback}
          setWarningDialog={setWarningDialog}
          isUpdate={isUpdate}
          id={Number(id)}
          formData={data ?? null}
          trustGroups={trustGroups}
          proceedIfWarnings={proceedIfWarnings}
        />
        {feedback && (
          <ToastMessage
            variant={feedback.type}
            message={feedback.message}
            clear={() => {
              setFeedback(null);
            }}
          />
        )}
        <ConfirmDialog
          open={Array.isArray(warningDialog)}
          title="Please confirm"
          confirmLabel="Confirm"
          onConfirm={() => {
            setWarningDialog(false);
            setProceedIfWarnings(true);
          }}
          onClose={() => {
            setWarningDialog(false);
          }}
        >
          <span>
            {Array.isArray(warningDialog)
              ? warningDialog.join(" ")
              : "A similar Trust Worker already exists. Please confirm and then resubmit to update them as a Trust Admin."}
          </span>
        </ConfirmDialog>
      </NavContainer>
    </>
  );
}
