// TrustGroupUser is the lowest priority role because it isn't really a role (it just adds access to dashboard) except.. when it is.
// https://techdept-team.slack.com/archives/C01UKV2AK5M/p1634639981228900

export type TrustRoles = {
  SuperAdmin: "SuperAdmin";
  TrustAdmin: "TrustAdmin";
  ClinicAdmin: "ClinicAdmin";
  TrustWorker: "TrustWorker";
  Vaccinator: "Vaccinator";
  TrustGroupUser: "TrustGroupUser";
  ReportingViewer: "ReportingViewer";
  ReportingStaffViewer: "ReportingStaffViewer";
};

export const Roles: TrustRoles = {
  SuperAdmin: "SuperAdmin",
  TrustAdmin: "TrustAdmin",
  ClinicAdmin: "ClinicAdmin",
  TrustWorker: "TrustWorker",
  Vaccinator: "Vaccinator",
  TrustGroupUser: "TrustGroupUser",
  ReportingViewer: "ReportingViewer",
  ReportingStaffViewer: "ReportingStaffViewer",
};

export type TrustRole =
  | "SuperAdmin"
  | "TrustAdmin"
  | "ClinicAdmin"
  | "TrustWorker"
  | "Vaccinator"
  | "TrustGroupUser"
  | "ReportingViewer"
  | "ReportingStaffViewer";
