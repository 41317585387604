import { useCallback, useEffect, useState } from "react";
import DataClient from "../../../../lib/services/api/DataClient";
import {
  getReservations,
  getPathogenPermissionMatrix,
  TrustWorkerPermissions,
} from "../trustWorkerExtensions";
import api from "./data";
import { Pathogens } from "../../../../lib/pathogens";
import { getCurrentTrust } from "../../../../lib/services/TrustSwitcher";
import { Moment } from "moment";

export interface AlertBoxCounts {
  countUnapproved: number;
  countEmailInDoubt: number;
}

export default function useListData(
  startModal: string,
  includeUnapproved = true
) {
  const [activeModal, setActiveModal] = useState(startModal || null);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [items, setItems] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  // This default is also partially reset in Table.jsx ~L#772
  const [filter, setFilter] = useState({
    pageNumber: 1,
    pageSize: 25,
    searchText: "",
    includeUnapproved: includeUnapproved,
  } as any);
  const [feedback, setFeedback] = useState(null);
  const [activeItem, setActiveItem] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(null);
  const [fetchStaffCancelToken, setFetchStaffCancelToken] = useState(null);
  const [activePathogen, setActivePathogen] = useState(null);
  const [isFetchingStaff, setIsFetchingStaff] = useState(false);
  const [alertBoxCounts, setAlertBoxCounts] = useState<AlertBoxCounts>({
    countUnapproved: 0,
    countEmailInDoubt: 0,
  });

  const fetchStaff = useCallback(() => {
    fetchAlertBoxCounts();
    // console.table(filter); // no IE support
    setIsFetchingStaff(true);
    setItems(null);
    // api.staff.list(filter)
    const request = DataClient.call({
      url: "/trustworker",
      method: "GET",
      params: filter,
    });
    if (fetchStaffCancelToken) {
      fetchStaffCancelToken.cancel();
      setFetchStaffCancelToken(null);
    }
    // setTimeout(() => {
    //   // Make sure it's in the stack after the request.then(!res) cop-out
    //   setFetchStaffCancelToken(request.cancelTokenSource);
    // }, 100);
    request.client
      .then((res: any) => {
        const { success, results } = res || {};
        if (!res) {
          return setFetchStaffCancelToken(null);
        }
        if (success) {
          setItems(
            results?.pagedResults?.map((item: any) => ({
              ...item,
              // [dd#4573] - add pseudo flag for requiresAdmin
              flagRequiresAdmin: item.requiresAdmin,
              role: item.isClinicAdmin
                ? "Clinic Admin"
                : item.isVaccinator
                ? "Vaccinator"
                : "Staff",
            }))
          );
          setTotalCount(results.totalCount);
        }
      })
      .catch((e) => {
        console.error(e);
        !e.handled &&
          setFeedback({
            type: "error",
            message: "Error fetching staff list",
          });
      })
      .finally(() => {
        setIsFetchingStaff(false);
      });
  }, [filter]);

  useEffect(() => {
    fetchStaff();
  }, [fetchStaff]);

  // This is called manually after specific actions
  const fetchAlertBoxCounts = useCallback(() => {
    Promise.all([
      DataClient.getData(`/TrustWorker/CountUnapproved`),
      DataClient.getData(`/TrustWorker/CountEmailInDoubt`),
    ])
      .then(([alertBoxCounts, countEmailInDoubt]) => {
        setAlertBoxCounts({
          countUnapproved: parseInt(alertBoxCounts.results || 0),
          countEmailInDoubt: parseInt(countEmailInDoubt.results || 0),
        });
      })
      .catch(() =>
        setFeedback({
          type: "error",
          message: "Error fetching alert box counts",
        })
      );
  }, []);

  const handleArchive = (item?: any) => {
    item = !item ? activeItem : item;
    api.staff
      .archive([item.id])
      .then(() => {
        setFeedback({
          type: "success",
          message: "Successfully archived staff member",
        });
        fetchStaff();
        setActiveModal(null);
        setConfirmDialog(null);
      })
      .catch(() =>
        setFeedback({
          type: "error",
          message: "Error archiving staff member",
        })
      );
  };

  const handleArchiveMany = () => {
    api.staff
      .archive(selectedIds)
      .then(() => {
        setFeedback({
          type: "success",
          message: "Successfully archived staff member(s)",
        });
        setFilter({
          ...filter,
          searchTerm: "",
        });
        fetchStaff();
        setActiveModal(null);
      })
      .catch(() =>
        setFeedback({
          type: "error",
          message: "Error archiving staff members(s)",
        })
      );
  };

  const removeEmailInDoubt = () => {
    api.staff
      .clearEmailInDoubt(activeItem.id)
      .then(() => {
        setFeedback({
          type: "success",
          message: `Email in Doubt flag removed`,
        });
        setActiveItem({ ...activeItem, flagEmailInDoubt: false });
        setActiveModal(null);
        fetchStaff();
      })
      .catch(() =>
        setFeedback({
          type: "error",
          message: "Failed to remove Email in Doubt flag",
        })
      );
  };

  const removePossibleDuplicate = () => {
    api.staff
      .clearPossibleDuplicate(activeItem.id)
      .then(() => {
        setFeedback({
          type: "success",
          message: `Possible Duplicate flag removed`,
        });
        setActiveItem({ ...activeItem, flagPossibleDuplicate: false });
        setActiveModal(null);
        fetchStaff();
      })
      .catch((e) => {
        setFeedback({
          type: "error",
          message: "Failed to remove Possible Duplicate flag",
        });
      });
  };

  const handleFrontLineChange = (id: number, value: boolean) => {
    api.staff
      .setFrontLine(id, value)
      .then((x) => {
        setFeedback({
          type: "success",
          message: "Successfully set front line staff",
        });
        fetchStaff();
      })
      .catch((e) =>
        setFeedback({
          type: "error",
          message: "Error setting front line status",
        })
      );
  };

  const handleImmformChange = (id: number, value: string) => {
    api.staff
      .setImmform(id, value)
      .then((x) => {
        setFeedback({
          type: "success",
          message: "Successfully set immform",
        });
        fetchStaff();
      })
      .catch(() =>
        setFeedback({
          type: "error",
          message: "Error setting immform",
        })
      );
  };

  // SELF REG USER ACTIONS

  type SelfRegActions =
    | "reject"
    | "rejectMany"
    | "delete"
    | "deleteMany"
    | "approve"
    | "approveMany";
  // rename to handle Form action or something as now also for archived users.
  const handleSelfRegAction = (action: SelfRegActions, item: any) => {
    if (action == "delete") {
      handleHardDeleteSelfReg(item);
    }
    if (action == "deleteMany") {
      handleHardDeleteSelfRegMany();
    }
    if (action == "reject") {
      handleRejectSelfReg(item);
    }
    if (action == "rejectMany") {
      handleRejectSelfRegMany();
    }
    if (action == "approve") {
      handleApproveSelfReg(item);
    }
    if (action == "approveMany") {
      handleApproveSelfRegMany();
    } else {
      throw new Error(`Could not find self reg action: &{action}`);
    }
  };

  const handleHardDeleteSelfReg = (item?: any) => {
    item = !item ? activeItem : item;
    api.staff
      .hardDeleteUser(item.id)
      .then(() => {
        setFeedback({
          type: "success",
          message: `Successfully deleted self registered user: ${item.emailAddress}`,
        });
        fetchStaff();
        setConfirmDialog(null);
      })
      .catch(() =>
        setFeedback({
          type: "error",
          message: `Error deleting self registered user: ${item.emailAddress}`,
        })
      );
  };
  const handleHardDeleteSelfRegMany = () => {
    api.staff
      .hardDeleteMany(selectedIds)
      .then(() => {
        setFeedback({
          type: "success",
          message: `Successfully deleted self registered users`,
        });
        fetchStaff();
        setConfirmDialog(null);
      })
      .catch(() =>
        setFeedback({
          type: "error",
          message: `Error deleting self registered users`,
        })
      );
  };

  const handleRejectSelfReg = (item?: any) => {
    item = !item ? activeItem : item;
    api.staff
      .rejectSelfReg(item.id)
      .then(() => {
        setFeedback({
          type: "success",
          message: `Successfully rejected self registered user: ${item.emailAddress}`,
        });
        fetchStaff();
        setConfirmDialog(null);
      })
      .catch(() =>
        setFeedback({
          type: "error",
          message: `Error rejecting self registered user: ${item.emailAddress}`,
        })
      );
  };
  const handleRejectSelfRegMany = () => {
    api.staff
      .rejectSelfRegMany(selectedIds)
      .then(() => {
        setFeedback({
          type: "success",
          message: `Successfully rejected self registered users`,
        });
        fetchStaff();
        setConfirmDialog(null);
      })
      .catch(() =>
        setFeedback({
          type: "error",
          message: `Error rejecting self registered users`,
        })
      );
  };
  const handleApproveSelfReg = (item?: any) => {
    item = !item ? activeItem : item;
    api.staff
      .approveSelfReg(item.id)
      .then(() => {
        setFeedback({
          type: "success",
          message: `Successfully approved self registered user: ${item.emailAddress}`,
        });
        fetchStaff();
        setConfirmDialog(null);
      })
      .catch(() =>
        setFeedback({
          type: "error",
          message: `Error approving self registered user: ${item.emailAddress}`,
        })
      );
  };

  const handleApproveSelfRegMany = () => {
    api.staff
      .approveSelfRegMany(selectedIds)
      .then(() => {
        setFeedback({
          type: "success",
          message: `Successfully approved self registered users.`,
        });
        fetchStaff();
        setConfirmDialog(null);
      })
      .catch(() =>
        setFeedback({
          type: "error",
          message: `Error approving self registered users`,
        })
      );
  };

  // ARCHIVED USER ACTIONS

  type ArchivedUserActions =
    | "delete"
    | "deleteMany"
    | "restore"
    | "restoreMany";
  // rename to handle Form action or something as now also for archived users.
  const handleArchivedUserAction = (action: ArchivedUserActions, item: any) => {
    if (action == "delete") {
      handleHardDeleteArchivedUser(item);
    }
    if (action == "restore") {
      handleRestoreArchivedUser(item);
    }
    if (action == "restoreMany") {
      handleRestoreArchivedUserMany(selectedIds);
    }
    if (action == "deleteMany") {
      handleHardDeleteManyArchivedUsers(selectedIds);
    }
  };

  const handleRestoreArchivedUser = (item?: any) => {
    console.log(`item to restore: `, item);
    item = !item ? activeItem : item;
    api.staff
      .restoreArchivedUser(item.id)
      .then(() => {
        setFeedback({
          type: "success",
          message: `Successfully restored archived user: ${item.emailAddress}`,
        });
        fetchStaff();
        setActiveModal(null);
      })
      .catch(() =>
        setFeedback({
          type: "error",
          message: `Error restoring archived user: ${item.emailAddress}`,
        })
      );
  };
  const handleRestoreArchivedUserMany = (ids: number[]) => {
    api.staff
      .restoreMany(ids)
      .then(() => {
        setFeedback({
          type: "success",
          message: `Successfully restored archived users`,
        });
        fetchStaff();
        setActiveModal(null);
      })
      .catch(() =>
        setFeedback({
          type: "error",
          message: `Error restoring archived users`,
        })
      );
  };

  const handleHardDeleteArchivedUser = (item?: any) => {
    item = !item ? activeItem : item;
    api.staff
      .hardDeleteUser(item.id)
      .then(() => {
        setFeedback({
          type: "success",
          message: `Successfully deleted archived user: ${item.emailAddress}`,
        });
        fetchStaff();
        setActiveModal(null);
      })
      .catch(() =>
        setFeedback({
          type: "error",
          message: `Error deleting archived user: ${item.emailAddress}`,
        })
      );
  };

  const handleHardDeleteManyArchivedUsers = (ids: number[]) => {
    api.staff
      .hardDeleteMany(ids)
      .then(() => {
        setFeedback({
          type: "success",
          message: `Successfully deleted archived users`,
        });
        fetchStaff();
        setActiveModal(null);
      })
      .catch(() =>
        setFeedback({
          type: "error",
          message: `Error deleting archived users`,
        })
      );
  };

  const extendPathogen = (
    item: any,
    pathogen: { index: number; name: string }
  ) => {
    const permissions = item.pathogenPermissions[
      pathogen.index
    ] as TrustWorkerPermissions;
    const status = item[`vaccinationStatus${pathogen.name}`];
    const hasBooking = !!item[`reservationId${pathogen.name}`];
    const isVaccinated = item[`vaccinationStatusId${pathogen.name}`] === 2;

    // #4101 Removing asterisk from Had Elsewhere* etc
    // #4101 Update: 'Booked in Another Trust' (like asterisk again but Booked only)
    return {
      [`vaccinationStatus${pathogen.name}`]:
        permissions.lockedByOtherTrust && status === "Booked"
          ? `${status} in Another Trust`
          : status,

      [`hospitalSiteName${pathogen.name}`]:
        isVaccinated && !hasBooking
          ? getCurrentTrust().trustName
          : item[`clinicLocationName${pathogen.name}`],

      [`clinicLocationName${pathogen.name}`]:
        isVaccinated && !hasBooking
          ? "Recorded by Trust Admin"
          : item[`hospitalSiteName${pathogen.name}`],
    };
  };

  const extendedItems = items
    ?.map((i: any) => ({
      ...i,
      pathogenPermissions: getPathogenPermissionMatrix(i),
      reservations: getReservations(i),
    }))
    .map((i: any) => ({
      ...i,
      ...Object.values(Pathogens)
        .map((p) => Number(p))
        .filter((p) => !isNaN(p))
        .map((p) => ({ index: p, name: Pathogens[p] }))
        .reduce(
          (item, pathogen) => ({
            ...item,
            ...extendPathogen(i, pathogen),
          }),
          {} as any
        ),
    }));

  interface restoreUserData {
    firstName: string;
    lastName: string;
    dob: Moment;
    emailAddress: string;
  }

  return {
    isFetchingStaff,
    fetchStaff,
    activeModal,
    setActiveModal,
    selectedIds,
    setSelectedIds,
    extendedItems,
    items,
    setItems,
    totalCount,
    setTotalCount,
    filter,
    setFilter,
    feedback,
    setFeedback,
    activeItem,
    setActiveItem,
    confirmDialog,
    setConfirmDialog,
    activePathogen,
    setActivePathogen,
    handleArchive,
    handleArchiveMany,
    handleFrontLineChange,
    handleImmformChange,
    handleSelfRegAction,
    handleArchivedUserAction,
    alertBoxCounts,
    removeEmailInDoubt,
    removePossibleDuplicate,
  };
}
