import StaffList from "./List";
import { TableToolbarButtons } from "./TableToolbar";
export default (props: any) => {
  return (
    <div className="wrapper-main">
      <StaffList
        {...props}
        {...{
          tableProps: {
            header: "Staff",
            showAlertBoxes: true,
            readOnly: false,
            buttonsToShow: [
              TableToolbarButtons.downloadCsv,
              TableToolbarButtons.addStaffManually,
              TableToolbarButtons.uploadEsrFile,
            ],
          },
        }}
      />
    </div>
  );
};
