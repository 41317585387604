import { TextFieldProps, TextField, Typography } from "@material-ui/core";
import { useState } from "react";

export const TextFieldWithCharacterCount = ({
  onChange,
  MAX_CHARS,
  textFieldProps,
}: {
  onChange: (event: any) => void;
  MAX_CHARS: number;
  textFieldProps: TextFieldProps;
}) => {
  const [value, setValue] = useState(
    (textFieldProps?.defaultValue as string) || ""
  );
  return (
    <>
      <TextField
        {...textFieldProps}
        {...{
          value,
          onChange: (event: any) => {
            setValue(event.target.value);
            onChange(event);
          },
          inputProps: { maxLength: MAX_CHARS },
        }}
      />
      <Typography
        variant="caption"
        color={value.length >= MAX_CHARS ? "error" : "initial"}
        style={{ fontWeight: value.length >= MAX_CHARS ? "bold" : "initial" }}
      >
        {`${value.length}/${MAX_CHARS}`}
      </Typography>
    </>
  );
};
