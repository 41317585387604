import { MyAppointment } from "./MyAppointments";

// The intended order is:
// Combined > Covid > Flu
// All unbookable go last (.canBook === false)
// Already booked appointments go first (hasBooking === true)
// Appointments with 'no actions' go last ( canCancel, canDecline,canReschedule === false)
// dd#4776: Combined clinics should be first or last. First if it is available, otherwise last.
export const sortAppointments = (
  appointments: MyAppointment[]
): MyAppointment[] => {
  const sortedApps = appointments
    .sort(sortOrderCombinedCovidFlu)
    .sort(sortCantBookToEnd)
    .sort(alreadyBookedFirst)
    .sort(sortNoActionsToEnd)
    .sort(combinedClinicsFirstOrLast);
  return sortedApps;
};

export const alreadyBookedFirst = (a: MyAppointment, b: MyAppointment) => {
  if (a.hasBooking === b.hasBooking) {
    return 0;
  } else if (a.hasBooking) {
    return -1;
  } else {
    return 1;
  }
};

const sortNoActionsToEnd = (a: MyAppointment, b: MyAppointment) => {
  if (
    a.canCancel === false &&
    a.canDecline === false &&
    a.canReschedule === false
  ) {
    if (
      b.canCancel === false &&
      b.canDecline === false &&
      b.canReschedule === false
    ) {
      return 0;
    } else {
      return 1;
    }
  } else {
    if (
      b.canCancel === false &&
      b.canDecline === false &&
      b.canReschedule === false
    ) {
      return -1;
    } else {
      return 0;
    }
  }
};

const sortCantBookToEnd = (a: MyAppointment, b: MyAppointment) => {
  if (a.canBook === b.canBook) {
    return 0;
  } else if (a.canBook) {
    return -1;
  } else {
    return 1;
  }
};

const combinedClinicsFirstOrLast = (
  a: MyAppointment,
  b: MyAppointment
): number => {
  const isCombined = (app: MyAppointment) =>
    app.pathogens.includes(2) && app.pathogens.includes(1);
  const isAvailable = (app: MyAppointment) => app.canBook;
  if ((isCombined(a) && !isAvailable(a)) || (isCombined(b) && isAvailable(b))) {
    return 1;
  } else if (
    (isCombined(a) && isAvailable(a)) ||
    (isCombined(b) && !isAvailable(b))
  ) {
    return -1;
  } else {
    return 0;
  }
};

const sortOrderCombinedCovidFlu = (
  a: MyAppointment,
  b: MyAppointment
): number => {
  if (a.pathogens.includes(2) && !b.pathogens.includes(2)) {
    return -1;
  } else if (b.pathogens.includes(2) && !a.pathogens.includes(2)) {
    return 1;
  } else if (a.pathogens.length > b.pathogens.length) {
    return -1;
  } else if (a.pathogens.length < b.pathogens.length) {
    return 1;
  } else {
    return 0;
  }
};
