import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import api from "../views/Users/TrustAdmin/Staff/data";
import { Pathogens } from "../lib/pathogens";
import StyledButton from "../views/Users/Patients/MyAppointments/Appointment/StyledButton";
import { enumVaccinationStatus } from "../lib/enums";
import { TrustWorkerDetailed } from "../views/Users/TrustAdmin/Staff/TrustWorkerDetailedDTO";

import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import KeyboardDatePickerDateOnly from "./KeyboardDatePickerDateOnly";
import { humanDateTime } from "../lib/common_utils";

interface DeclineDialogProps {
  open: boolean;
  title: string;
  onClose: (...args: any[]) => void;
  onSuccess: (data: any) => void;
  onError: (error: Error) => void;
  onRemove?: () => void; // Undo decline
  value: { id: number; trustWorkerDetails?: TrustWorkerDetailed };
  pathogen: Pathogens;
  preselectReasonId?: number;
  showStaffVaccinationSites?: boolean;
}

interface HewFormData {
  trustWorkerId: number;
  vaccinationStatusId?: number;
  declineReasonId?: number;
  declineDescription?: string;
  hadElsewhereSite?: string;
  hadElsewhereLocation?: string;
  hadElsewhereDate?: string;
  hadElsewhereVaccinationBatchNumber?: string;
  hadElsewhereVaccinationTypeId?: number;
}

type HewSitesLookup = {
  [key: string]: { label: string; locationLabel: string };
};

const baseHewSites = [
  {
    label: "GP Practice",
    locationLabel: "GP Practice Name",
  },
  {
    label: "Pharmacy",
    locationLabel: "Pharmacy Name",
  },
  {
    label: "Other NHS Trust",
    locationLabel: "Name of other NHS Trust",
  },
  {
    label: "Vaccination Centre",
    locationLabel: "Vaccination Centre Name",
  },
];
const staffOnlyHewSites = [
  {
    label: "Recorded on NIVS",
    locationLabel: "Recorded on NIVS",
  },
  {
    label: "Unknown",
    locationLabel: "Unknown",
  },
];

const mapTrustWorkerDetailedToFormDataHew = (
  trustWorker: TrustWorkerDetailed,
  pathogen: Pathogens
): HewFormData => {
  if (pathogen === Pathogens.Covid) {
    return {
      trustWorkerId: trustWorker.id,
      vaccinationStatusId: trustWorker.vaccinationStatusIdCovid,
      declineReasonId: trustWorker.declineReasonIdCovid,
      declineDescription: trustWorker.declineDescriptionCovid,
      hadElsewhereSite: trustWorker.hadElsewhereSiteCovid,
      hadElsewhereLocation: trustWorker.hadElsewhereLocationCovid,
      hadElsewhereDate: trustWorker.hadElsewhereDateCovid,
      hadElsewhereVaccinationBatchNumber:
        trustWorker.hadElsewhereVaccinationBatchNumberCovid,
      hadElsewhereVaccinationTypeId:
        trustWorker.hadElsewhereVaccinationTypeIdCovid,
    };
  }
  if (pathogen === Pathogens.Flu) {
    return {
      trustWorkerId: trustWorker.id,
      vaccinationStatusId: trustWorker.vaccinationStatusIdFlu,
      declineReasonId: trustWorker.declineReasonIdFlu,
      declineDescription: trustWorker.declineDescriptionFlu,
      hadElsewhereSite: trustWorker.hadElsewhereSiteFlu,
      hadElsewhereLocation: trustWorker.hadElsewhereLocationFlu,
      hadElsewhereDate: trustWorker.hadElsewhereDateFlu,
      hadElsewhereVaccinationBatchNumber:
        trustWorker.hadElsewhereVaccinationBatchNumberFlu,
      hadElsewhereVaccinationTypeId:
        trustWorker.hadElsewhereVaccinationTypeIdFlu,
    };
  }
};
/**
 * A dialog to allow the user to add or edit a HadElsewhere or Decline a
 * vaccination. Also possible to undo a declined vaccination.
 *
 * This component is used in the trust worker List.tsx, the MyAppointments.tsx
 * and in another place TODO: find out where.
 *
 * From the trust worker List view - the edit data comes in as a
 * TrustWorkerDetailed object. This is converted to an HewFormData object
 * which is used to display the current data in the form.
 *
 * There is no edit option in the MyAppointments view, so the data is not set.
 *
 * If this is used in other places - it would be sensible to convert the
 * trustWorker and new data by importing and using the mapping function
 * in the parent components.
 *
 * @export
 */
export function HewOrDecline({
  open,
  title,
  onClose,
  onSuccess,
  onError,
  onRemove,
  value,
  pathogen,
  preselectReasonId,
  showStaffVaccinationSites = false,
}: DeclineDialogProps): JSX.Element {
  const defaultVaccinationStatus =
    preselectReasonId === 1 ? 5 : preselectReasonId === 2 ? 9 : null;
  const id = value?.id || -1;
  const [lookups, setLookups] = useState(null);
  const [isEditing, setIsEditing] = useState(true);
  const [HewSites, setHewSites] = useState<HewSitesLookup>({});

  const {
    handleSubmit,
    control,
    formState,
    setValue,
    getValues,
    watch,
    reset,
  } = useForm();

  useEffect(
    function _valueChanged() {
      // Set form edit data
      if (value.trustWorkerDetails) {
        const hewFormData = mapTrustWorkerDetailedToFormDataHew(
          value.trustWorkerDetails,
          pathogen
        );
        Object.keys(hewFormData).forEach((key) => {
          setValue(key, hewFormData[key]);
        });
      } else {
        setValue("trustWorkerId", value.id);
      }
      // Set is Editing
      if (
        (pathogen === Pathogens.Covid &&
          value.trustWorkerDetails?.vaccinationStatusIdCovid) ||
        (pathogen === Pathogens.Flu &&
          value.trustWorkerDetails?.vaccinationStatusIdFlu)
      ) {
        setIsEditing(true);
      } else {
        setIsEditing(false);
      }
      // Set defaultVaccinationStatus
      if (defaultVaccinationStatus) {
        setValue("vaccinationStatusId", defaultVaccinationStatus);
      }
    },
    [value]
  );

  useMemo(
    function _setHewSites(): void {
      const hadElsewhereSites = showStaffVaccinationSites
        ? [...baseHewSites, ...staffOnlyHewSites]
        : baseHewSites;

      const hewObject = hadElsewhereSites
        .map((s) => ({ ...s, value: s.label }))
        .reduce(
          // @ts-ignore // TODO
          (p, c) => ({ ...p, [c.value]: c }),
          {}
        );
      setHewSites(hewObject);
    },
    [showStaffVaccinationSites]
  );

  const fetchLookups = useCallback(
    function _fetchLookups(): void {
      Promise.all([
        api.staff.getDeclineReasons(),
        api.staff.getVaccinationTypes(pathogen),
      ])
        .then(([declineReasons, vaccinationTypes]) => {
          setLookups({
            declineReasons,
            vaccinationTypes,
          });
        })
        .catch((e) => {
          console.error(e);
        });
    },
    [pathogen]
  );

  useEffect(() => {
    fetchLookups();
  }, [fetchLookups]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "vaccinationStatusId") {
        reset({
          vaccinationStatusId: getValues("vaccinationStatusId"),
        });
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  /**
   * Returns a small object containing the formatted time and user who modified the HEW.
   * @param pathogen
   * @param trustWorkerDetailed
   */
  function modifiedByPathogen(
    pathogen: Pathogens,
    trustWorkerDetailed: TrustWorkerDetailed
  ): {
    modifiedBy: string | null;
    modifiedDate: string | null;
  } {
    if (!pathogen || !trustWorkerDetailed)
      return {
        modifiedBy: null,
        modifiedDate: null,
      };
    if (pathogen === Pathogens.Flu) {
      return {
        modifiedBy: trustWorkerDetailed.hewOrDeclineModifiedByFlu || null,
        modifiedDate:
          trustWorkerDetailed.hewOrDeclineModifiedDateFlu &&
          humanDateTime(
            trustWorkerDetailed.hewOrDeclineModifiedDateFlu,
            false
          ) !== "Invalid Date"
            ? humanDateTime(
                trustWorkerDetailed.hewOrDeclineModifiedDateFlu,
                false,
                "DD/MM/YYYY [at] HH:mm"
              )
            : null,
      };
    } else if (pathogen === Pathogens.Covid) {
      return {
        modifiedBy: trustWorkerDetailed.hewOrDeclineModifiedByCovid || null,
        modifiedDate:
          trustWorkerDetailed.hewOrDeclineModifiedDateCovid &&
          humanDateTime(
            trustWorkerDetailed.hewOrDeclineModifiedDateCovid,
            false
          ) != "Invalid Date"
            ? humanDateTime(
                trustWorkerDetailed.hewOrDeclineModifiedDateCovid,
                false,
                "DD/MM/YYYY [at] HH:mm"
              )
            : null,
      };
    } else {
      console.error(
        "Could not find the modified information from the following pathogen:",
        pathogen
      );
    }
  }

  const onSubmit = () => {
    const formValues = getValues();
    event.preventDefault();
    if (lookups.declineReasons) {
      api.staff
        .decline(
          id,
          formValues.vaccinationStatusId,
          formValues.declineDescription,
          pathogen,
          formValues.declineReasonId,
          formValues.hadElsewhereSite,
          formValues.hadElsewhereLocation,
          formValues.hadElsewhereDate,
          formValues.hadElsewhereVaccinationTypeId,
          formValues.hadElsewhereVaccinationBatchNumber
        )
        .then((x: any) => {
          // resetForm(); Could be rhf reset() if req'd
          reset();
          return x;
        })
        .then(onSuccess)
        .catch(onError);
    }
  };
  const titleBackground = {
    [Pathogens.Flu]: "#169bd8",
    [Pathogens.Covid]: "#c80089",
  };

  const handleClose = () => {
    onClose();
  };

  // Only show location text input if the site is a base site
  const showLocationForHewSite = (hewSiteKey: string) => {
    return baseHewSites.find((site) => site.label === hewSiteKey);
  };

  const dateWithin6Months = (date: any) => {
    date = moment(date);
    const isWithin6Mo = date
      .clone()
      .add(6, "months")
      .isAfter(moment().startOf("day"));
    return isWithin6Mo ? true : "Date must be within the last 6 months";
  };

  return (
    (getValues() && (
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          style={{ backgroundColor: titleBackground[pathogen] }}
          disableTypography
        >
          <h2 style={{ color: "white" }}>{title}</h2>
        </DialogTitle>
        <div
          style={{
            minHeight: "16rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "16px 24px",
          }}
        >
          {lookups?.declineReasons ? (
            <>
              <form
                id="decline"
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Reason"
                      select
                      required
                      fullWidth
                      error={!!formState.errors?.vaccinationStatusId}
                      helperText={
                        formState.errors?.vaccinationStatusId?.message
                      }
                    >
                      <MenuItem selected value={""}></MenuItem>
                      <MenuItem value={enumVaccinationStatus["Had Elsewhere"]}>
                        Had Elsewhere
                      </MenuItem>
                      <MenuItem value={enumVaccinationStatus.Declined}>
                        Declined
                      </MenuItem>
                    </TextField>
                  )}
                  name="vaccinationStatusId"
                  control={control}
                  rules={{ required: "Reason is required" }}
                />

                {watch("vaccinationStatusId") ===
                  enumVaccinationStatus.Declined && (
                  <Controller
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Reason"
                        select
                        required
                        fullWidth
                        error={!!formState.errors?.declineReasonId}
                        helperText={formState.errors?.declineReasonId?.message}
                      >
                        <MenuItem value={""}></MenuItem>
                        {lookups.declineReasons.map(
                          (i: { id: number; name: string }) => (
                            <MenuItem key={i.id} value={i.id}>
                              {i.name}
                            </MenuItem>
                          )
                        )}
                      </TextField>
                    )}
                    name="declineReasonId"
                    control={control}
                    rules={{ required: "Decline Reason is required" }}
                  />
                )}

                {getValues("vaccinationStatusId") ===
                  enumVaccinationStatus["Had Elsewhere"] && (
                  <>
                    <Controller
                      render={({ field: { ref, ...rest } }) => {
                        return (
                          <KeyboardDatePickerDateOnly
                            {...rest}
                            label="Date of Vaccination"
                            fullWidth
                            placeholder="dd/mm/yyyy"
                            autoOk={true}
                            format="DD/MM/YYYY"
                            disableFuture={true}
                            minDate={new Date().setMonth(
                              new Date().getMonth() - 6
                            )}
                            minDateMessage="Date must be within the last 6 months"
                            error={!!formState.errors?.hadElsewhereDate}
                            helperText={
                              formState.errors?.hadElsewhereDate?.message
                            }
                          />
                        );
                      }}
                      name="hadElsewhereDate"
                      control={control}
                      defaultValue={null}
                      rules={{
                        required: "Date of Vaccination is required",
                        validate: (value) => dateWithin6Months(value),
                      }}
                    />

                    <Controller
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Vaccination Site"
                          select
                          required
                          error={!!formState.errors?.hadElsewhereSite}
                          helperText={
                            formState.errors?.hadElsewhereSite?.message
                          }
                        >
                          <MenuItem value={undefined}></MenuItem>
                          {Object.entries(HewSites).map(([key, value]) => (
                            <MenuItem key={`hadElsewhere-${key}`} value={key}>
                              {value.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                      name="hadElsewhereSite"
                      control={control}
                      rules={{ required: "Vaccination Site is required" }}
                    />

                    {showLocationForHewSite(watch("hadElsewhereSite")) && (
                      <Controller
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            label={
                              HewSites[getValues("hadElsewhereSite")]
                                ?.locationLabel
                            }
                            error={!!formState.errors?.hadElsewhereLocation}
                            helperText={
                              formState.errors?.hadElsewhereLocation?.message
                            }
                          />
                        )}
                        name="hadElsewhereLocation"
                        control={control}
                      />
                    )}

                    <Controller
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Vaccination Type (optional)"
                          select
                        >
                          <MenuItem value={undefined}></MenuItem>
                          {lookups?.vaccinationTypes.map((i: any) => (
                            <MenuItem
                              key={`vaccinationType-${i.id}`}
                              value={i.id}
                            >
                              {i.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                      name="hadElsewhereVaccinationTypeId"
                      control={control}
                    />

                    <Controller
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Batch Number (optional)"
                        />
                      )}
                      name="hadElsewhereVaccinationBatchNumber"
                      control={control}
                    />
                  </>
                )}

                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Other info (optional)"
                      multiline
                      minRows={4}
                    />
                  )}
                  name="declineDescription"
                  control={control}
                />
                <div style={{ paddingTop: "8px" }}>
                  {modifiedByPathogen(pathogen, value?.trustWorkerDetails)
                    .modifiedDate && (
                    <p style={{ marginBottom: "4px" }}>
                      Recorded on:{" "}
                      {modifiedByPathogen(pathogen, value?.trustWorkerDetails)
                        .modifiedDate || "Unknown"}
                    </p>
                  )}
                  {modifiedByPathogen(pathogen, value?.trustWorkerDetails)
                    .modifiedBy && (
                    <p>
                      Recorded by:{" "}
                      {modifiedByPathogen(pathogen, value.trustWorkerDetails)
                        .modifiedBy || "Unknown"}
                    </p>
                  )}
                </div>

                <div style={{ marginTop: "16px" }}>
                  <StyledButton
                    form="decline"
                    type="submit"
                    color={`${Pathogens[pathogen]}`.toLowerCase()}
                    autoFocus
                    style={{ marginRight: "8px" }}
                  >
                    Submit
                  </StyledButton>
                  <Button onClick={handleClose} variant="outlined">
                    Cancel
                  </Button>
                  {isEditing && (
                    <Button
                      onClick={onRemove}
                      variant="outlined"
                      style={{
                        float: "right",
                        backgroundColor: "red",
                        color: "#fff",
                      }}
                    >
                      Undo Decline / Had Elsewhere
                    </Button>
                  )}
                </div>
              </form>
            </>
          ) : (
            <div style={{ display: "flex", placeContent: "center" }}>
              <CircularProgress />
            </div>
          )}
        </div>
      </Dialog>
    )) ||
    null
  );
}
