import React from "react";
import styles from "./Checkbox.module.scss";

type CheckboxProps = {
  label: string;
  register: any;
  id: string;
  required?: boolean;
  alt?: boolean;
  registerOptions?: any;
};

const Checkbox = function ({
  label,
  register,
  id,
  required = false,
  alt = false,
  registerOptions,
}: CheckboxProps): JSX.Element {
  return (
    <label className={`${styles.checkbox} ${alt ? styles.alt : ""}`}>
      <span className={styles.input}>
        <input
          type="checkbox"
          name="checked"
          {...register(id, registerOptions)}
          required={required}
        />
        <span className={styles.control}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="100%"
            height="100%"
            aria-hidden="true"
            focusable="false"
          >
            <path
              fill="none"
              stroke="currentColor"
              strokeWidth="4"
              d="M1.73 12.91l6.37 6.37L22.79 4.59"
            />
          </svg>
        </span>
      </span>
      <span>
        {label}
        {required && " *"}
      </span>
    </label>
  );
};

export default Checkbox;
