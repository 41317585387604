import React, { useState, useEffect, useRef } from "react";
import { Grid, LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Chart from "react-apexcharts";
import DropdownInput from "../../../components/FormComponent/dropdown";
import { ApexOptions } from "apexcharts";
import DashboardApi from "../DashboardApi";
import { ChartColors } from "./ChartConstants";

const useStyles = makeStyles((theme) => ({
  loadingProgress: {
    padding: 0,
    width: "100%",
    marginTop: 7,
    height: 0
  }
}));

export const UnVaccinatedStaffChart = (props: any) => {
  const api = DashboardApi;
  const classes = useStyles();
  const [state, setState] = useState({
    series: [],
    options: {
      chart: {
        width: "100%",
        type: "bar",
        stacked: true,
        toolbar: {
          show: false
        }
      },
      legend: {
        position: "right"
      },
      states: {
        hover: {
          filter: "lighten",
          value: 0.35
        }
      },
      plotOptions: {
        bar: {
          columnWidth: "90%",
          stacked: true,
          horizontal: false,
          dataLabels: {
            position: "bottom"
          }
        }
      },
      xaxis: {
        labels: {
          trim: true,
          hideOverlappingLabels: false
        },
        tooltip: {
          enabled: true
        }
      },
      yaxis: {
        labels: {
          formatter: function (val: number, opt: any) {
            return val + "%";
          }
        },
        max: 100
      },
      colors: ChartColors,
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              show: true,
              position: "left"
            }
          }
        }
      ],
      dataLabels: {
        enabled: false,
        textAnchor: "start",
        style: {
          colors: ["#fff"]
        },
        formatter: function (val: any, opt: any) {
          return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
        },
        offsetX: 0,
        dropShadow: {
          enabled: true
        }
      },
      tooltip: {
        x: {
          show: false
        },
        y: {
          title: {
            formatter: function () {
              return "";
            }
          }
        }
      }
    } as ApexOptions
  });

  const GetDropdownOptions = () => {
    const options = [
      { label: "Staff Groups", value: "staffGroups" },
      { label: "Level 1", value: "level1" }
    ];
    if (props.trustId === 0) {
      options.unshift({ label: "Trusts", value: "trust" });
    }
    return options;
  };
  const chartRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [dropdownOptions, setDropDownOptions] = useState(GetDropdownOptions());
  const [selectedDimension, setSelectedDimension] = useState(
    dropdownOptions[0]
  );

  const refreshOptions = () => {
    const options = GetDropdownOptions();
    setDropDownOptions(options);
    setSelectedDimension(options[0]);
  };

  const fetchTableData = () => {
    setState({
      series: [],
      options: {
        xaxis: {
          categories: []
        }
      }
    });
    setLoading(true);
    api
      .unvaccinatedStaff(
        selectedDimension.value,
        props.pathogenIds,
        props.onlyFLHCW,
        props.trustId,
        props.excludeL1Ids
      )
      .then((res: any) => {
        if (res.categories && res.categories.length > 0) {
          setState({
            series: res.series,
            options: {
              xaxis: {
                categories: res.categories
              }
            }
          });
        }
      })
      // .catch((e) => {
      //     if (!e.handled) {
      //     }
      // })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchTableData();
  }, [props, selectedDimension]);

  useEffect(() => {
    refreshOptions();
  }, [props]);

  return (
    <Grid>
      <DropdownInput
        inputFieldProps={{
          id: "dropdown_unvac_chart",
          name: "dropdown_unvac_chart",
          value: selectedDimension,
          placeholder: "Staff Groups"
        }}
        styles={{ marginTop: "0" }}
        options={dropdownOptions}
        setDropDownValue={(option: any) => {
          setSelectedDimension(option);
        }}
      />
      <Chart
        ref={chartRef}
        options={state.options}
        series={state.series}
        type="bar"
        height={400}
      />
      {loading && (
        <Grid className={classes.loadingProgress}>
          <LinearProgress color="secondary" aria-busy={loading} />
        </Grid>
      )}
    </Grid>
  );
};
