import { FunctionComponent, ReactNode, useCallback, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import ApiAlert from "../../../../../components/ApiAlert";
import { Pathogens } from "../../../../../lib/pathogens";
import DataClient from "../../../../../lib/services/api/DataClient";
import SettingsContainer from "../SettingsContainer";
import {
  MasterVaccineBatchFieldValues,
  MasterVaccineBatchesForm,
} from "./Form";

export const MasterVaccineBatchesAdd: FunctionComponent<
  RouteComponentProps<{ pathogenId: string }>
> = ({ match }) => {
  const pathogenId = parseInt(match.params.pathogenId);
  const pathogenName = Pathogens[pathogenId];

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alert, setAlert] = useState<ReactNode>(null);

  const history = useHistory();

  const onSubmit = useCallback(
    async (fieldValues: MasterVaccineBatchFieldValues) => {
      setIsSubmitting(true);

      try {
        const result = await DataClient.postData(
          "/ClinicMasterVaccinationBatch",
          fieldValues
        );

        if (!result.success) {
          throw new Error();
        }

        history.push("/settings/master-vaccine-batches");
      } catch (error: any) {
        const alert = ApiAlert({
          alwaysShow: true,
          message: "Could not save vaccine batch",
          res: error?.response,
        });

        setAlert(alert);
      } finally {
        setIsSubmitting(false);
      }
    },
    [history, setAlert, setIsSubmitting]
  );

  return (
    <SettingsContainer>
      <h2>Add {pathogenName} Master Vaccine Batch</h2>

      {alert}

      <MasterVaccineBatchesForm
        pathogenId={pathogenId}
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
      />
    </SettingsContainer>
  );
};
