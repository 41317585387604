import { Redirect } from "react-router-dom";
import useCampaigns from "../../lib/hooks/useCampaigns";
import {
  getRoleCurrentTrust,
  isClinicAdmin,
  isTrustGroupUser,
} from "../../lib/services/TrustSwitcher";
import { Roles } from "../../lib/types/TrustRoles";

const Home = () => {
  const role = getRoleCurrentTrust();
  const isTrustWorker = role === Roles.TrustWorker;
  const isVaccinator = role === Roles.Vaccinator;
  const isTrustAdmin = role === Roles.TrustAdmin;
  const isSuperAdmin = role === Roles.SuperAdmin;
  const { loading, hasActiveCampaigns } = useCampaigns(isTrustWorker);
  if (loading) return null;
  if (isTrustWorker) return <Redirect to="/my-appointments" />;
  if (isVaccinator) return <Redirect to="/clinics" />;
  if (isClinicAdmin()) return <Redirect to="/clinics" />;
  if (isTrustAdmin) {
    if (hasActiveCampaigns) {
      return <Redirect to="/staff" />;
    } else {
      return <Redirect to="/settings" />;
    }
  }
  if (isSuperAdmin) {
    return <Redirect to="/trusts" />;
  }
  if (isTrustGroupUser()) return <Redirect to="/reporting" />;
  return <Redirect to="/staff" />;
};

export default Home;
