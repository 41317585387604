import { MUIDataTableColumnDef, MUIDataTableMeta } from "mui-datatables";
import {
  humanDate,
  humanDateTime,
  humanTime,
  pascalCaseToSentence,
} from "../../lib/common_utils";
import { ChangesetTable } from "./ChangesetTable";
import { changeTypeToNameMapper } from "./ClinicEventDetailsMapper";
import {
  ChangesetBase,
  ChangesetDetailsLookup,
  ClinicChangesetDetailedDTO,
  ClinicChangesetDTO,
  ClinicChangesetSearchFilterDTO,
} from "./EventLogData";
import { ChangesetType } from "./EventLogData";

const hideClinicRepeatingGroupDatesForChangeType = [
  ChangesetType.AddClinicVaccinationBatch,
  ChangesetType.CancelSingleClinic,
  ChangesetType.CreateSingleClinic,
  ChangesetType.DeleteClinicVaccinationBatch,
  ChangesetType.EnableClinic,
  ChangesetType.SuspendClinic,
  ChangesetType.UpdateClinicVaccinationBatch,
  ChangesetType.UpdatePrimaryClinicVaccinationBatch,
  ChangesetType.UpdateSingleClinic,
];

const clinicTableColumns: MUIDataTableColumnDef[] = [
  {
    name: "type",
    label: "Change Type",
    options: {
      customBodyRender: (value: number) => {
        return pascalCaseToSentence(changeTypeToNameMapper(value));
      },
    },
  },
  {
    name: "createdDate",
    label: "Change Date & Time",
    options: {
      customBodyRender: (value: number) => {
        return humanDateTime(value);
      },
    },
  },
  {
    name: "clinicId",
    label: "Clinic ID",
    options: {
      customBodyRender: (value) => (value === 0 ? "N/A" : value),
    },
  },
  // { name: "hospitalSiteId", label: "Hospital Site Id" },
  { name: "hospitalSiteName", label: "Site" },
  // { name: "clinicLocationId", label: "Location Id" },
  {
    name: "clinicLocationName",
    label: "Clinic Location",
  },
  {
    name: "clinicDate",
    label: "Date",
    options: {
      customBodyRender: (value: string) =>
        humanDate(value) === "01/01/0001" ? "N/A" : humanDate(value),
    },
  },
  {
    name: "dateFrom",
    label: "Date From",
    options: {
      customBodyRender: (value: string, tableMeta: MUIDataTableMeta) =>
        hideClinicRepeatingGroupDatesForChangeType.includes(
          tableMeta.rowData[0]
        )
          ? "N/A"
          : humanDate(value),
    },
  },
  {
    name: "dateTo",
    label: "Date To",
    options: {
      customBodyRender: (value: string, tableMeta: MUIDataTableMeta) =>
        hideClinicRepeatingGroupDatesForChangeType.includes(
          tableMeta.rowData[0]
        )
          ? "N/A"
          : humanDate(value),
    },
  },

  // { name: "id", label: "Id" },
  // 'parentChangeset',
  {
    name: "timeFrom",
    label: "Start Time",
    options: {
      customBodyRender: (value: number) => humanTime(value),
    },
  },
  {
    name: "timeTo",
    label: "End Time",
    options: {
      customBodyRender: (value: number) => humanTime(value),
    },
  },
  {
    name: "createdByEmail",
    label: "Changed By",
  },
];

export function ClinicTable({
  clinicResults,
  totalCount,
  setClinicFilter,
  clinicFilter,
  getClinicDetails,
  clinicDetailsLookup,
}: {
  clinicResults: ClinicChangesetDTO[];
  totalCount: number;
  setClinicFilter: (filter: ClinicChangesetSearchFilterDTO) => void;
  clinicFilter: ClinicChangesetSearchFilterDTO;
  getClinicDetails: (changesets: ChangesetBase[]) => void;
  clinicDetailsLookup: ChangesetDetailsLookup<ClinicChangesetDetailedDTO>;
}): JSX.Element {
  return (
    <ChangesetTable
      {...{
        changesetResults: clinicResults,
        totalCount: totalCount,
        setChangesetFilter: setClinicFilter,
        changesetFilter: clinicFilter,
        getChangesetDetails: getClinicDetails,
        changesetDetails: clinicDetailsLookup,
        changesetTableColumns: clinicTableColumns,
      }}
    />
  );
}
