import { CircularProgress } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { makeStyles, ThemeProvider } from "@material-ui/styles";
import { useEffect, useState } from "react";
import DataClient from "../../lib/services/api/DataClient";
import MUIDataTable from "mui-datatables";
import { getCurrentTrustIdFromMe } from "../../lib/services/TrustSwitcher";
import {
  overridesGenericCells,
  overridesTableGenericColors
} from "../../lib/themes/overrides";
import { palette } from "../../lib/themes/vars";
import { merge } from "lodash";
const useStyles = makeStyles(() => ({
  root: {
    "& h2": {
      margin: "1em 0"
    }
  }
}));
const theme = createTheme({
  palette,
  overrides: merge(
    {},
    overridesGenericCells,
    overridesTableGenericColors
  ) as any
});

interface PropTypes {
  excludeL1Ids: string;
}

export function VaccinatorsLeagueTableTab(props: PropTypes) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [table, setTable] = useState(null);

  useEffect(
    function getData() {
      (async () => {
        const trustId = await getCurrentTrustIdFromMe();
        const response = await DataClient.getData(
          `Dashboard/vaccinator-league-table`,
          {
            trustId,
            excludeL1: props.excludeL1Ids
          }
        );
        const data = response?.success ? response?.results?.rows : null;
        if (data) {
          const tableOptions = {
            download: false,
            filter: false,
            print: false,
            rowsPerPage: 25,
            rowsPerPageOptions: [25, 50, 100],
            search: false,
            searchOpen: false,
            selectableRows: false,
            selectableRowsHeader: false,
            sort: true,
            sortOrder: {
              name: "totalVaccinations",
              direction: "desc"
            },
            viewColumns: false,
            textLabels: {
              body: {
                noMatch: "No vaccinations recorded yet"
              }
            }
          };
          const tableColumns = [
            {
              name: "name",
              label: "Name"
            },
            {
              name: "fluVaccinations",
              label: "Flu",
              options: {
                customBodyRender: (value: any) => (
                  <div className="table-status" data-theme-id={1}>
                    {value}
                  </div>
                )
              }
            },
            {
              name: "covidVaccinations",
              label: "Covid",
              options: {
                customBodyRender: (value: any) => (
                  <div className="td-status" data-theme-id={2}>
                    {value}
                  </div>
                )
              }
            },
            {
              name: "totalVaccinations",
              label: "Total",
              options: {
                customBodyRender: (value: any) => <strong>{value}</strong>
              }
            }
          ];
          setTable({
            options: tableOptions,
            columns: tableColumns,
            data
          });
        }
        setLoading(false);
      })();
    },
    [props]
  );
  return (
    <main className={classes.root}>
      <ThemeProvider theme={theme}>
        <h2>Vaccinators' League Table</h2>
        {loading && <CircularProgress />}
        {table && <MUIDataTable title="" {...table} />}
      </ThemeProvider>
    </main>
  );
}
