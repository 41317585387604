import {
  CircularProgress,
  createTheme,
  TableCell,
  TableRow,
  ThemeProvider,
} from "@material-ui/core";

import { merge } from "lodash";
import {
  overridesGenericCells,
  overridesTableSearchBox,
  overridesTableHackActions,
  overridesTableGenericColors,
  overridesMain,
} from "../../lib/themes/overrides";
import { palette } from "../../lib/themes/vars";
import MUIDataTable, {
  MUIDataTableColumnDef,
  MUIDataTableOptions,
} from "mui-datatables";
import { ClinicEventDetails } from "./ClinicEventDetails";
import { ChangesetBase } from "./EventLogData";
// import { ChangesetEventDetails } from "./ChangesetEventDetails";

const theme = createTheme({
  palette,
  overrides: merge(
    {},
    overridesMain,
    overridesGenericCells,
    overridesTableGenericColors,
    overridesTableSearchBox,
    overridesTableHackActions
  ),
});

export function ChangesetTable<
  ChangesetTypeDTO extends { id: number },
  ChangesetDetailedDTO,
  ChangesetTypeSearchFilterDTO extends { pageNumber: number; pageSize: number }
>({
  changesetResults,
  totalCount,
  setChangesetFilter,
  changesetFilter,
  getChangesetDetails,
  changesetDetails,
  changesetTableColumns,
}: {
  changesetResults: ChangesetTypeDTO[];
  totalCount: number;
  setChangesetFilter: (filter: ChangesetTypeSearchFilterDTO) => void;
  changesetFilter: ChangesetTypeSearchFilterDTO;
  getChangesetDetails: (changesets: ChangesetBase[]) => void;
  changesetDetails: ChangesetDetailedDTO;
  changesetTableColumns: MUIDataTableColumnDef[];
}): JSX.Element {
  const findChangesetFromRow = (rowIndex: number) => changesetResults[rowIndex];
  const findChangesetDetailsFromRow = (rowIndex: number) => {
    const changesetId = findChangesetFromRow(rowIndex);
    return changesetDetails[changesetId?.id];
  };

  // Expandable rows example: https://github.com/gregnb/mui-datatables/blob/master/examples/expandable-rows/index.js
  const changesetTableOptions: MUIDataTableOptions = {
    elevation: 0,
    print: false,
    search: false,
    sort: false,
    filter: false,
    download: false,
    selectableRows: "none",
    expandableRows: true,
    serverSide: true,
    rowsPerPage: changesetFilter.pageSize,
    rowsPerPageOptions: [25, 50, 100],
    count: totalCount,
    page: changesetFilter.pageNumber - 1,
    onChangePage: (pageNumber: number) =>
      setChangesetFilter({ ...changesetFilter, pageNumber: pageNumber + 1 }),
    onChangeRowsPerPage: (rowCount: number) => {
      const pageNumber = rowCount > totalCount ? 1 : changesetFilter.pageNumber;
      return setChangesetFilter({ ...changesetFilter, pageSize: rowCount, pageNumber: pageNumber })
    }
      ,
    onRowExpansionChange: (curExpanded: any) => {
      // Workaround for the plugin returning a 2d array when you close all expanded rows at the same time.
      if (Array.isArray(curExpanded[0])) return;
      const expanded: ChangesetBase[] = curExpanded.map((r: any) =>
        findChangesetFromRow(r.index)
      );
      getChangesetDetails(expanded);
    },
    renderExpandableRow: (rowData: any, rowMeta: any) => {
      const colSpan: number = rowData.length + 1;
      // Falls back to "LOADING" if the data is not yet available from API.
      const expandedRowData = findChangesetDetailsFromRow(rowMeta.rowIndex);
      return (
        <>
          {expandedRowData ? (
            <TableRow>
              <TableCell colSpan={colSpan}>
                <ClinicEventDetails
                  expandedRowData={expandedRowData}
                ></ClinicEventDetails>
              </TableCell>
            </TableRow>
          ) : (
            <TableRowLoading colSpan={colSpan}></TableRowLoading>
          )}
        </>
      );
    },
  };

  return (
    <>
      <div className="changeset-results-container">
        <>
          <ThemeProvider theme={theme}>
            <MUIDataTable
              data={changesetResults}
              columns={changesetTableColumns}
              options={changesetTableOptions}
              title=""
            />
          </ThemeProvider>
        </>
      </div>
    </>
  );
}

function TableRowLoading({ colSpan }: { colSpan: number }) {
  return (
    <TableRow>
      <TableCell colSpan={colSpan}>
        <div
          style={{
            display: "flex",
            placeContent: "center",
            alignItems: "center",
            height: "100px",
          }}
        >
          <CircularProgress />
        </div>
      </TableCell>
    </TableRow>
  );
}
