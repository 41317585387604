import {
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Tooltip,
} from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";
import React from "react";
import ReactMarkdown from "react-markdown";
import { ReactMarkdownOptions } from "react-markdown/lib/react-markdown";
import { useHistory } from "react-router-dom";
import ToastMessage from "../../../../components/Universal/toast_message";
import NavContainer from "../NavContainer";
import useTemplateList from "./useTemplateList";

const NotificationTemplateDisplayNameMarkdown: React.FC<
  Omit<ReactMarkdownOptions, "components">
> = (props) => {
  return (
    <ReactMarkdown
      {...props}
      components={{ p: (props) => <p {...props} style={{ margin: 0 }} /> }}
    />
  );
};

export default function NotificationTemplates() {
  const history = useHistory();
  const [feedback, setFeedback] = React.useState<{
    type: "success" | "error";
    message: string;
  }>(null);
  const [templates, loading, error] = useTemplateList();
  React.useEffect(() => {
    if (error) {
      setFeedback({
        type: "error",
        message: "There was a problem loading the templates.",
      });
    }
  }, [error]);
  return (
    <>
      {loading && <LinearProgress color="primary" />}
      <NavContainer>
        <h1>Default Notification Templates</h1>
        {templates?.length > 0 && (
          <Paper>
            <List>
              {templates.map((item) => (
                <ListItem key={item.id}>
                  <ListItemText
                    primary={
                      item.displayName ? (
                        <NotificationTemplateDisplayNameMarkdown>
                          {item.displayName}
                        </NotificationTemplateDisplayNameMarkdown>
                      ) : (
                        item.name
                      )
                    }
                  />
                  <ListItemSecondaryAction>
                    <Tooltip title="Edit">
                      <IconButton
                        color="primary"
                        onClick={() => {
                          history.push(`/default-notifications/${item.id}`);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Paper>
        )}
        {feedback && (
          <ToastMessage
            variant={feedback.type}
            message={feedback.message}
            clear={() => {
              setFeedback(null);
            }}
          />
        )}
      </NavContainer>
    </>
  );
}
