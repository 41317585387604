/**The enums are the basic and important part of this file,
 * the rest are just some utils for Trusts and Clinics that
 * might wish to display as "Combined". The formtting utils
 * are basic operations to fix numbers becoming strings via
 * <option> values */

import { cloneDeep, difference } from "lodash";
import { enumVaccinationStatus } from "./enums";
import { enumToArray } from "./enumManipulations";
import { getTrustPathogens } from "./services/TrustSwitcher";
import { MyAppointment } from "../views/Users/Patients/MyAppointments/MyAppointments";
import { themes } from "./themes/vars";

export interface Pathogen {
  id: number;
  name: string;
  description?: string;
  order?: number;
}
export interface VaccinationType extends Pathogen {
  pathogens?: any; // currently unwitnessed, possibly Pathogen
}
export type PathogenOption = {
  label: string;
  value: Pathogens[];
};
export enum Pathogens {
  "Flu" = 1,
  "Covid" = 2,
}
// ^ Wanting to iterate over the pathogens?
// You're probably looking for getTrustPathogens() from TrustSwitcher - which only gets these that pertain to your current trust, or some useful functions below:
// [{ id: [1], name: "Flu" }
//  { id: [2], name: "Covid" },
//  { id: [1, 2], name: "Flu & Covid Together" }]
export const getAllPathogenCombinations = (): Pathogen[] => {
  return getPathogenCombinations(enumToArray(Pathogens));
};
// As above but only for current trust
export const getTrustPathogenCombinations = () =>
  getPathogenCombinations(getTrustPathogens() || []);

// [1, 2]
export const getTrustPathogenIds = (): number[] | null =>
  getTrustPathogens()?.map((p) => p.id) || [];

export const getTrustPathogenNames = (): string[] =>
  getTrustPathogens()?.map((p) => p.name) || [];

// This is godawful, but I just .. I give up:
// https://techdepartment.mydonedone.com/issuetracker/projects/85036/issues/5#history-27816077
// and again, now referring to them as a 'booster'
// https://2-app.donedone.com/6110/project/17407/task/682435
export const humanPathogen = (p: number | string, booster = true) => {
  if (!p) return "";
  const string = typeof p === "number" ? Pathogens[p] : p;
  return string === "Flu"
    ? "flu"
    : string === "Covid"
    ? booster
      ? "Covid booster"
      : "Covid"
    : string;
};
// "flu", "Covid booster", or "flu & Covid"
export const humanPathogens = (p: number[]) => {
  return `${p.map((p) => humanPathogen(p)).join(" & ")} `;
};
// "flu", "Covid", or "flu & Covid"
// [#4648] https://2-app.donedone.com/6110/project/17408/task/1545401
export const humanPathogensSimple = (p: number[]) => {
  return `${p.map((p) => humanPathogen(p, false)).join(" & ")} `;
};
// Select options, [{id, name}] -> [{value:[], label}]
export const pathogensToInputs = (pathogens: Pathogen[]) =>
  pathogens.map(({ id, name }) => ({
    value: !Array.isArray(id) ? [id] : id,
    label: name,
  }));

// Utility for getAllPathogenCombinations / getTrustPathogenCombinations above
// Change ids to arrays and add combinations of them
export const getPathogenCombinations = (pathogens: Pathogen[]) => {
  if (!pathogens) return [];
  const combinations = cloneDeep(pathogens as any).map((p: any) => {
    p.id = !Array.isArray(p.id) ? [p.id] : p.id;
    return p;
  });
  // Needs future-proofing for more pathogens, but you get the idea:
  if (pathogens.length >= 2) {
    combinations.push({
      id: pathogens.map((p) => p.id),
      name: `${pathogens.map((p) => p.name).join(" & ")}`, // Together`, // removed #2105
    });
  }
  return combinations;
};

export type PathogenSelectOptions = "1" | "2" | "1|2"
export const pathogenColor = (pathogenId: PathogenSelectOptions) =>
  themes[pathogenId].main;

// [1] -> [2]
export const otherPathogenIds = (
  pathogenIds: number[],
  allPathogens = getTrustPathogenIds()
) => difference(allPathogens, pathogenIds);

export const trustInvolvesFlu = () =>
  getTrustPathogenIds()?.includes(Pathogens["Flu"]);
export const trustInvolvesCovid = () =>
  getTrustPathogenIds()?.includes(Pathogens["Covid"]);

export const userHasAnyOfTheseVaccinationStatuses = ({
  user,
  statuses,
  pathogenNames = getTrustPathogenNames(),
}: {
  user: any;
  statuses: number[];
  pathogenNames?: string[];
}) =>
  pathogenNames.some((name) =>
    statuses.includes(user?.[`vaccinationStatusId${name}`])
  );

export const userHasAnyVaccination = (user: any) =>
  userHasAnyOfTheseVaccinationStatuses({
    user,
    statuses: [enumVaccinationStatus["Vaccinated"]],
  });

export const userHasDeclinedOrHadElsewhere = (user: any) =>
  userHasAnyOfTheseVaccinationStatuses({
    user,
    statuses: [
      enumVaccinationStatus["Declined"],
      enumVaccinationStatus["Declined (allergy)"],
      enumVaccinationStatus["Declined (other contraindication)"],
      enumVaccinationStatus["Declined (doesn't want a vaccine)"],
      enumVaccinationStatus["Had Elsewhere"],
    ],
  });

export const userHasAnyReservation = (user: any) => user?.reservations?.length;

// Used for <select>s
export const SelectPathogenList: PathogenOption[] | null = pathogensToInputs(
  getAllPathogenCombinations()
);

// As above but only for current trust
export const SelectTrustPathogenList: PathogenOption[] = pathogensToInputs(
  getTrustPathogenCombinations()
);

// Used for <select>s
export function getLabel(pathogens: Pathogens[]): string | undefined {
  //Since the arrays involved are tiny, using JSON.stringify for Array comparison seems acceptable
  const option = SelectPathogenList.find(
    (pathogenOption) =>
      JSON.stringify(pathogenOption.value) === JSON.stringify(pathogens)
  );
  return option?.label;
}

export function getDescriptiveLabel(
  pathogens: Pathogens[]
): string | undefined {
  //Since the arrays involved are tiny, using JSON.stringify for Array comparison seems acceptable
  const option = SelectPathogenList.find(
    (pathogenOption) =>
      JSON.stringify(pathogenOption.value) === JSON.stringify(pathogens)
  );
  return option?.label;
}

const SEPARATOR = ",";

// Used for getting <select> values and making sure they are numbers for the API
export function formatForServer(value: string): Pathogens[] {
  return value.split(SEPARATOR).map((v) => parseInt(v));
}

// opposite to formatForServer, getting data from the API into <select> <option>s
export function formatForOptionValue(pathogens: Pathogens[]): string {
  return pathogens.join(SEPARATOR);
}

export function pathogensFromArray(
  source: number[] | { id: number }[]
): Pathogens[] {
  return source.map((p: Pathogens | { id: Pathogens }) => {
    if (typeof p === "number") {
      return p;
    }
    return p.id;
  });
}
export const sortCombinedPathogensToStart = (
  a: MyAppointment,
  b: MyAppointment
) => b.pathogens.length - a.pathogens.length;
// Create pathogens array from "pathogen.1: true" type checkboxes
export const createPathogensFromPathogenCheckboxes = (newItem: any) => {
  if (Object.keys(newItem).some((k) => k.includes("pathogen."))) {
    newItem.pathogens =
      getTrustPathogenIds().filter((id) => newItem[`pathogen.${id}`]) || [];
  }
  return newItem;
};
// Create default pathogen checkbox values
export const createPathogenCheckboxValuesFromPathogens = (
  defaultValue: any
) => {
  defaultValue?.pathogens?.forEach((id: any) => {
    defaultValue[`pathogen.${id}`] = true;
  });
  return defaultValue;
};
// [dd#2898] "N.B this is only needed for Covid and not flu"
export const shouldShowClinicianNames = (pathogenId: number) =>
  pathogenId === Pathogens.Covid;
