import React from "react";
import ReactDOM from "react-dom";
import DataClient from "../../../../../lib/services/api/DataClient";
// If changing, update in both useNotificationTemplate.ts and NotificationTypeEnum.cs
export enum NotificationTemplateEnum {
  CampaignInvite = 1,
  WelcomeToFluTrack = 2,
  ReminderToBook = 3,
  AppointmentConfirmation = 4,
  AppointmentReschedule = 5,
  AppointmentCancel = 6,
  AppointmentAttendanceReminder = 7,
  PostVaccinationEmail = 8,
  AttendedButNotVaccinated = 9,
  Dna = 10,
  ClinicCancelledByTrustAdmin = 11,
  ClinicMovedByTrustAdmin = 12,
  ResetPassword = 13,
  TrustAdminWelcome = 14,
  VaccinatorAssignment = 15,
  AdministratorWelcome = 16,
  BrandTemplate = 17,
  TrustGroupUserWelcome = 18,
  ClinicAdminAssignment = 19,
  CampaignContinuation = 20,
  RegistrationReceived = 21,
  RegistrationRejected = 22,
  TrustWorkerArchived = 23,
  ReminderToBookForVerified = 24,
  ReportingAdminAssignment = 25,
  ReportingStaffAdminAssignment = 26,
}
export interface NotificationTemplate {
  description: string;
  emailBody: string;
  emailSubject: string;
  id: number;
  isDefaultTemplate?: boolean;
  mandatoryTags?: string;
  name: string;
  displayName?: string;
  notificationTemplateId?: NotificationTemplateEnum;
  optionalTags?: string;
  sendSms?: boolean;
  smsAllowed?: boolean;
  smsBody: string;
  suppress?: boolean;
  trustId?: number;
  systemTags?: string;
}
export default function useNotificationTemplate({
  id,
  fetchUrl,
  editingSuperAdminTemplate,
}: {
  id: number;
  fetchUrl: string;
  editingSuperAdminTemplate?: boolean;
}) {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [template, setTemplate] = React.useState<NotificationTemplate>(null);
  const loadTemplate = React.useCallback(
    async () => DataClient.getData(fetchUrl),
    [id]
  );
  const loadTemplateSearch = React.useCallback(
    async () =>
      DataClient.postData(`/NotificationTemplate/Search`, {
        pageSize: -1,
      }),
    []
  );
  React.useEffect(() => {
    setLoading(true);
    setTemplate(null);
    // Grab the notification search endpoint for data such as smsAllowed
    loadTemplateSearch()
      .then(
        ({ success, results }) =>
          (success && results.find((r: any) => r.id === id)) || null
      )
      .then((searchInfo) => {
        loadTemplate().then((data) => {
          if (data.success) {
            ReactDOM.unstable_batchedUpdates(() => {
              setLoading(false);
              setTemplate({
                ...data.results,
                smsAllowed: searchInfo.smsAllowed || false,
                sendSms: data.results.smsBody?.length > 0,
              });
            });
          } else {
            throw new Error("Endpoint failed.");
          }
        });
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, [id, loadTemplate, loadTemplateSearch]);
  return { loading, template };
}
