import { Tooltip } from "@material-ui/core";
import { startCase, startsWith } from "lodash";
import "./FlagIcons.scss";

type Size = "small" | "large";

const flagLabelsCustom = {
  flagFrontline: "Frontline Staff",
  flagAtRisk: "Recorded as 'At Risk'",
  flagClinicAdmin: "Clinic Admin",
  flagCovidVaccinated: "Vaccinated for Covid",
  //flagCovidVaccinatedBooster3: "Received Covid Booster 23/24",
  //flagCovidVaccinatedBooster2: "Received Covid Booster 22/23",
  //flagCovidVaccinatedBooster1: "Received Covid Booster 21/22",
  //flagCovidVaccinatedDouble: "Received 2nd Covid Dose",
  //flagCovidVaccinatedSingle: "Received 1st Covid Dose",
  flagExemptCovid: "Exempt from Covid Vaccination",
  flagExemptFlu: "Exempt from Flu Vaccination",
  flagFluVaccinated: "Vaccinated for Flu",
  flagAddedManually: "Manually Added (not via CSV upload)",
  flagUnder18: "Staff member is under 18",
  flagOver65: "Staff member is over 65",
  flagTrustAdmin: "Trust Admin",
  flagVaccinator: "Is a Vaccinator",
  flagVerified: "Has Verified Their Account",
  flagRequiresAdmin:
    "Requires Attention - No staff should be left with the status of 'Booked' at the end of a clinic.",
  flagClinicRequiresAdmin:
    "Requires Attention - Go to clinic and click the ‘Close Clinic’ or ‘Show Staff Requiring Admin’ button",
  flagClinicCompleted:
    "Clinic admin up-to-date (clinic is either closed or in progress)",
  flagReportingViewer: "Has Reporting Access",
  flagReportingStaffViewer: "Has Reporting Access with Defined Staff List",
  flagAccepted: "Accepted",
  flagDeclined: "Declined",
};
const flagOrderCustom = [
  "flagFrontline",
  "flagAtRisk",
  "flagExemptCovid",
  "flagExemptFlu",
  "flagCovidVaccinated",
  "flagFluVaccinated",
  "flagUnder18",
  "flagOver65",
  "flagTrustAdmin",
  "flagClinicAdmin",
  "flagVaccinator",
  "flagVerified",
  "flagAddedManually",
  "flagRequiresAdmin",
];
export const FlagIcons = (item: Record<string, any>) => {
  if (!item) return [];
  const flagKeys = Object.keys(item).filter((k) => startsWith(k, "flag"));
  const flags = flagKeys.filter((k) => item[k]);
  flags.sort((a, b) => {
    if (flagOrderCustom.indexOf(a) === -1) return 1;
    if (flagOrderCustom.indexOf(b) === -1) return -1;
    return flagOrderCustom.indexOf(a) - flagOrderCustom.indexOf(b);
  });
  return flags.map((f, i) => <Flag type={f} key={`flag-${i}`} />);
};
export const Flag = ({
  type,
  size = "small",
}: {
  type: string;
  size?: Size;
}) => {
  const label = flagLabelsCustom[type] || startCase(type.replace("flag", ""));
  // if (!label) return null; // Hide flags without labels
  return (
    <span
      className={`flag ${size === "large" ? "flag--large" : null} -${type}`}
    >
      <Tooltip title={label}>
        <span>
          {/* Looking for [V] [2] icons? see FlagIcons.scss */}
          <span>
            {/* Extra nesting for Tooltippy McReasons */}
            {label}
          </span>
        </span>
      </Tooltip>
    </span>
  );
};
