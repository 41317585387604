import { LinearProgress } from "@material-ui/core";
import {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import ApiAlert from "../../../../../components/ApiAlert";
import { Pathogens } from "../../../../../lib/pathogens";
import DataClient from "../../../../../lib/services/api/DataClient";
import SettingsContainer from "../SettingsContainer";
import {
  MasterVaccineBatchFieldValues,
  MasterVaccineBatchesForm,
} from "./Form";
import { MasterVaccineBatch } from "./List";

export const MasterVaccineBatchesEdit: FunctionComponent<
  RouteComponentProps<{ pathogenId: string; id: string }>
> = ({ match }) => {
  const pathogenId = parseInt(match.params.pathogenId);
  const pathogenName = Pathogens[pathogenId];

  const id = parseInt(match.params.id);

  const [isLoading, setIsLoading] = useState(true);
  const [masterVaccinationBatch, setMasterVaccinationBatch] =
    useState<MasterVaccineBatch | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alert, setAlert] = useState<ReactNode>(null);

  const history = useHistory();

  const fetchMasterVaccineBatch = useCallback(async () => {
    setIsLoading(true);

    const { results: masterVaccineBatch } = await DataClient.getData(
      `/ClinicMasterVaccinationBatch/${id}`
    );

    setMasterVaccinationBatch(masterVaccineBatch);
    setIsLoading(false);
  }, [id]);

  const onSubmit = useCallback(
    async (fieldValues: MasterVaccineBatchFieldValues) => {
      setIsSubmitting(true);

      try {
        const result = await DataClient.update(
          `/ClinicMasterVaccinationBatch/${id}`,
          fieldValues
        );

        if (!result.success) {
          throw new Error();
        }

        history.push("/settings/master-vaccine-batches");
      } catch (error: any) {
        const alert = ApiAlert({
          alwaysShow: true,
          message: "Could not save vaccine batch",
          res: error?.response,
        });

        setAlert(alert);
      } finally {
        setIsSubmitting(false);
      }
    },
    [history, id, setAlert, setIsSubmitting]
  );

  useEffect(() => {
    fetchMasterVaccineBatch();
  }, [fetchMasterVaccineBatch]);

  return (
    <SettingsContainer>
      {isLoading ? <LinearProgress /> : null}

      <h1>Edit {pathogenName} Master Vaccine Batch</h1>

      {alert}

      {masterVaccinationBatch ? (
        <MasterVaccineBatchesForm
          pathogenId={pathogenId}
          masterVaccineBatch={masterVaccinationBatch}
          isSubmitting={isSubmitting}
          onSubmit={onSubmit}
        />
      ) : null}
    </SettingsContainer>
  );
};
