import "core-js/features/url";
import "core-js/features/url-search-params";
import "core-js/features/array/find";
import "core-js/features/array/find-index";
import "core-js/features/array/includes";
import "core-js/features/object/entries";
import "core-js/features/object/values";
import "core-js/features/string/includes";
import "core-js/features/string/repeat";
import "core-js/features/string/replace-all";

// https://github.com/facebook/create-react-app/issues/9906#issuecomment-719488780
/** @jsxRuntime classic */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

//import registerServiceWorker from './registerServiceWorker';

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
if (window.location.hostname === "localhost") {
  rootElement.classList.add("localhost");
}

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>,
  rootElement
);

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
//registerServiceWorker();
