import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import {
  Add as AddIcon,
  ArrowLeft,
  ArrowRight,
  CalendarViewDay,
  KeyboardBackspace,
  Pause,
  Search,
  ViewList,
  ViewWeek,
} from "@material-ui/icons/";
import startCase from "lodash/startCase";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import WeekCalendar from "react-week-calendar";
import "react-week-calendar/dist/style.less";
import ApiAlert from "../../../../components/ApiAlert";
import DropdownInput from "../../../../components/FormComponent/dropdown";
import ToastMessage from "../../../../components/Universal/toast_message";
import { clinicTypeElement, enumClinicTypeId } from "../../../../lib/enums";
import { Pathogens } from "../../../../lib/pathogens";
import DataClient from "../../../../lib/services/api/DataClient";
import {
  getTrustPathogens,
  getRoleCurrentTrust,
  getTrustActiveCampaign,
  isClinicAdminOrHigher,
} from "../../../../lib/services/TrustSwitcher";
import "./Planner-styles.scss";
import PlannerList from "./PlannerList";
import PlannerDownloader from "./PlannerDownloader";
import { alertNoActiveCampaign } from "../../../../components/AlertMessaging";
import clinicLocationApi from "../Settings/Clinics/api";
import { isTodaysDate } from "../../../../lib/common_utils";
export default function VaccinationPlanner() {
  const [loading, setLoading] = useState(true);
  const [action, setAction] = useState(null);
  const [hospitals, setHospitals] = useState([]);
  const [clinics, setClinics] = useState([]);
  const [selectedPathogens, setSelectedPathogens] = useState({
    // value: pathogens,
    // label: getLabel(pathogens),
    label: "All Clinic Types",
  });
  // const [
  //   selectedRequireBookingAllPathogens,
  //   setSelectedRequireBookingAllPathogens,
  // ] = useState(false);
  const [selectedHospital, setSelectedHospital] = useState(null);
  const [selectedClinic, setSelectedClinic] = useState(null);
  // Start date defaults to today's week
  const [startDate, setStartDate] = useState(
    moment().hours(0).minutes(0).startOf("isoWeek")
  );
  const [startDatePrev, setStartDatePrev] = useState(
    moment().hours(0).minutes(0).startOf("isoWeek")
  );
  const [listPage, setListPage] = useState(0);
  const [viewMode, setViewMode] = useState("week");
  const [events, setEvents] = useState([]);
  const [alert, setAlert] = useState(null);
  const [fail, setFail] = useState(false);
  const getClinics = React.useCallback(() => {
    let data = {
      HospitalSiteId: selectedHospital?.id || null,
      DateFrom: startDate.format("YYYY-MM-DD"),
      DateTo: startDate.clone().add(6, "days").format("YYYY-MM-DD"),
      ClinicLocationId: selectedClinic?.id || null,
    };
    // If, and only if, checking the box (and multiple pathogens) add parameter, otherwise .. don't.
    // if (
    //   selectedPathogens?.value?.length > 1 &&
    //   selectedRequireBookingAllPathogens
    // ) {
    //   data.RequireBookingAllPathogens = true;
    // }
    Object.keys(data).forEach(
      (k) => !data[k] && data[k] !== undefined && delete data[k]
    );
    const params = new URLSearchParams(data);
    selectedPathogens?.pathogens?.forEach((p) => {
      params.append("pathogens", p);
    });
    selectedPathogens?.excludePathogens?.forEach((p) => {
      params.append("excludePathogens", p);
    });
    // console.log("::getClinics", params.toString());
    setLoading(true);
    DataClient.getData(`/Reservation/ClinicPlanner`, params)
      .then(({ success, results }) => {
        // console.log(`::gotClinics`, results);
        if (success) {
          let formattedRes = results.map((clinic) => {
            clinic.start = moment(clinic.dateTimeFrom, "YYYY-MM-DD HH:mm");
            clinic.end = moment(clinic.dateTimeTo, "YYYY-MM-DD HH:mm");
            // Fix end time for 24 hour clinics to just before midnight on same day, rather than 00:00 following morn
            if (clinic.is24HourClinic) {
              clinic.dateTimeTo = clinic.dateTimeTo.replace(
                "00:00:00",
                "23:59:59"
              );
              clinic.end = moment(clinic.dateTimeTo, "YYYY-MM-DD HH:mm")
                .clone()
                .subtract(1, "day");
            }
            return clinic;
          });
          setEvents(formattedRes || []);
        } else {
          setAction({
            type: "error",
            message: "Could not retrieve clinics",
          });
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setAlert(ApiAlert({ res: e.response }));
      });
  }, [
    selectedClinic?.id,
    // selectedRequireBookingAllPathogens,
    selectedHospital?.id,
    selectedPathogens.pathogens,
    selectedPathogens.excludePathogens,
    startDate,
  ]);
  const scrollTo8am = () => {
    // Event stack can get a little out of wack
    setTimeout(() => {
      const calenderData = document.getElementsByClassName(
        "weekCalendar__content"
      );
      if (["day", "week"].includes(viewMode) && calenderData?.[0]) {
        calenderData[0].scrollTop = 450;
      }
    }, 0);
  };
  // Scroll to 8am whenever viewMode is changed
  useEffect(() => {
    scrollTo8am();
  }, [viewMode]);
  useEffect(() => {
    (async () => {
      // Happy if current trust has an active campaign, even if you're not personally ON it
      if (!(await getTrustActiveCampaign())) {
        setFail(true);
        setAlert(alertNoActiveCampaign());
      } else {
        getClinics();
      }
    })();
  }, [
    getClinics,
    startDate,
    selectedHospital,
    selectedClinic,
    // selectedRequireBookingAllPathogens,
    selectedPathogens.pathogens,
    selectedPathogens.excludePathogens,
  ]);
  useEffect(() => {
    DataClient.getData("/HospitalSite/hospital_sites")
      .then(({ success, results }) => {
        if (success && results) {
          const hospitalList = results.map(
            ({ id, hospitalSiteName, trustId }) => {
              return {
                id,
                label: hospitalSiteName,
                value: hospitalSiteName,
              };
            }
          );
          setHospitals(
            [{ id: null, label: "All Sites", value: null }].concat(
              hospitalList
            ) || []
          );
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((e) => {
        let message = "Error fetching sites";
        setAction({
          type: "error",
          message,
        });
      });
  }, []);
  // const handleChange = (event) => {
  //   setSelectedVaccinator(event);
  // };
  const role = getRoleCurrentTrust();
  // So you used to be able to select intervals on the planner and then it'd automatically open the create clinic modal. Pretty slick!
  // const handleSelect = (newIntervals) => {
  //   setCreateData(newIntervals[0]);
  //   setShowCreate(true);
  // };
  const HeaderCellComponent = (prop) => {
    const { date, dayFormat } = prop;
    return (
      <span className="header-column">
        {viewMode === "day" && (
          <Button
            // component={Link}
            // to={`/clinics`}
            onClick={() => {
              setStartDate(startDatePrev);
              setViewMode("week");
            }}
            color="secondary"
            variant="contained"
            startIcon={<KeyboardBackspace />}
            className="button-calendar-back"
          >
            Back
          </Button>
        )}
        <Button
          onClick={() => {
            // When swapping into day mode, store the start date (i.e. a monday) so we can come back out to it later
            setStartDatePrev(startDate);
            setStartDate(date);
            setViewMode("day");
          }}
          fullWidth
          color={isTodaysDate(date) ? "secondary" : "primary"}
          variant="contained"
          disableRipple={viewMode === "day"}
          endIcon={viewMode !== "day" ? <Search /> : null}
        >
          {date.format("Do MMM [\n] dddd")}
        </Button>
      </span>
    );
  };
  const EventComponent = (props) => {
    // const { start, end, value, id, clinic_location_name } = props;
    const {
      clinicLocationName,
      hospitalSiteName,
      clinicRepeatingGroupId,
      clinicTypeId,
      duration,
      end,
      id,
      isBookingSuspended,
      pathogens,
      requireBookingAllPathogens,
      start,
    } = props;
    let colors = ["#d8adcb", "#abd0b8", "#e3d5a8", "#b2bcdb"];
    // All clinics actually have clinicRepeatingGroupId, even if not recurring (to tell which type of clinic, you'd look at recurrenceId with enumRecurrenceId)
    let chosenColor =
      colors[
        clinicRepeatingGroupId
          ? clinicRepeatingGroupId % colors.length
          : id % colors.length
      ];
    // const durationHours = moment
    //   .utc(moment(end) - moment(start))
    //   .format("H[h] m[m]")
    //   .replace(" 0m", "");
    return (
      <Link
        className={`event ${isBookingSuspended ? "-suspended" : ""}`}
        style={{ background: chosenColor }}
        to={`/clinics/${id}/view`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {clinicTypeId === enumClinicTypeId.walkAround ? (
          <span className="event-sash -walkaround">Walk Around</span>
        ) : (
          ""
        )}
        <h3>{hospitalSiteName}</h3>
        <h4>{clinicLocationName}</h4>
        <h4>
          <strong>
            {clinicTypeElement({ pathogens, requireBookingAllPathogens })}
          </strong>
        </h4>
        {/* <div className="planner-status">{`${start.format("H:mm")} - ${end.format("H:mm")}`}</div> */}
        {duration && (
          // "24 hour clinic", "09:00 - 11:00"
          <div className="planner-status">{`${duration}`}</div>
        )}
        {/* {!is24HourClinic && durationHours && (
          <div className="planner-status">({`${durationHours}`})</div>
        )} */}
        {isBookingSuspended && (
          <div className="planner-status -suspended">
            <Pause /> <span>Paused</span>
          </div>
        )}
      </Link>
    );
  };
  const handleSelectedHospital = (selectedHospitalId) => {
    if (selectedHospitalId) {
      setLoading(true);
      clinicLocationApi
        .list(selectedHospitalId)
        .then((results) => {
          const clinicsList = results?.map(({ id, locationName }, i) => ({
            id,
            label: locationName,
            value: locationName,
          }));
          setClinics(
            [{ id: null, label: "All Locations", value: null }].concat(
              clinicsList
            ) || []
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const changeStartDate = (days) => {
    setLoading(true);
    setStartDate(startDate.clone().add(days, "days"));
    setLoading(false);
  };
  const dropdownlabel = {
    display: "inline-block",
    marginBottom: 5,
  };
  const isMultiplePathogenTrust = getTrustPathogens()?.length > 1;
  return (
    <main>
      <div className="wrapper-main">
        <h1>Vaccination Clinic Planner</h1>
      </div>
      <div className="wrapper">{alert}</div>
      {!fail && (
        <>
          <div className="wrapper">
            <div>
              {/* <div style={{marginBottom : 50,marginLeft:100}}> */}
              <Grid container spacing={1}>
                <Grid container item xs={8} md={5}>
                  {isMultiplePathogenTrust && (
                    <Grid item xs={12} sm={4}>
                      <Typography
                        component="span"
                        variant="subtitle2"
                        color="primary"
                        style={dropdownlabel}
                      >
                        Select Clinic Type
                      </Typography>
                      <Paper elevation={0}>
                        <DropdownInput
                          inputFieldProps={{
                            id: "pathogens",
                            name: "pathogens_id",
                            value: selectedPathogens,
                          }}
                          styles={{ marginTop: "0" }}
                          options={[
                            // ...pathogensToInputs(
                            //   getTrustPathogenCombinations()
                            // ),
                            // [dd#2105] "flu should be flu only / covid should be covid only clinics"
                            // "Covid & Flu ... so that is shows both rigid and fliexible conbined lcinics"
                            { label: "All Clinic Types" },
                            {
                              label: "Flu",
                              value: "Flu", // purely for dropdown.js::isSelected
                              pathogens: [Pathogens.Flu],
                              excludePathogens: [Pathogens.Covid],
                            },
                            {
                              label: "Covid",
                              value: "Covid", // purely for dropdown.js::isSelected
                              pathogens: [Pathogens.Covid],
                              excludePathogens: [Pathogens.Flu],
                            },
                            {
                              label: "Flu & Covid",
                              value: "Flu & Covid", // purely for dropdown.js::isSelected
                              pathogens: [Pathogens.Covid, Pathogens.Flu],
                            },
                          ]}
                          setDropDownValue={(data) => {
                            setSelectedPathogens(data);
                            setSelectedHospital(null);
                            setSelectedClinic(null);
                          }}
                        />
                      </Paper>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={isMultiplePathogenTrust ? 4 : 8}>
                    <Typography
                      component="span"
                      variant="subtitle2"
                      color="primary"
                      style={dropdownlabel}
                    >
                      Select Sites
                    </Typography>
                    <Paper elevation={0}>
                      <DropdownInput
                        inputFieldProps={{
                          id: "hospital-site",
                          name: "hospital_site_id",
                          value: selectedHospital,
                          placeholder: "All Sites",
                        }}
                        styles={{ marginTop: "0" }}
                        options={hospitals}
                        setDropDownValue={(data) => {
                          setSelectedHospital(data);
                          setSelectedClinic(null);
                          handleSelectedHospital(data["id"]);
                        }}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography
                      component="span"
                      variant="subtitle2"
                      color="primary"
                      style={dropdownlabel}
                    >
                      Select Clinic Location
                    </Typography>
                    <Paper elevation={0}>
                      <DropdownInput
                        inputFieldProps={{
                          id: "vaccination-clinic",
                          name: "clinic_location",
                          value: selectedClinic,
                          placeholder: "All Locations",
                        }}
                        styles={{ marginTop: "0" }}
                        options={clinics}
                        setDropDownValue={setSelectedClinic}
                      />
                    </Paper>
                  </Grid>
                </Grid>
                <Grid item className="planner-modes">
                  {["week", "day", "list"].map((mode) => (
                    <Button
                      onClick={() => {
                        setStartDatePrev(startDate);
                        // If swapping out of day mode, reset to previous start date (i.e. a monday)
                        if (viewMode === "day") {
                          setStartDate(startDatePrev);
                        }
                        setViewMode(mode);
                      }}
                      color="primary"
                      startIcon={
                        {
                          week: <ViewWeek />,
                          day: <CalendarViewDay />,
                          list: <ViewList />,
                        }[mode]
                      }
                      className="button-calendar-list"
                      variant={viewMode === mode ? "outlined" : null}
                    >
                      {startCase(mode)} View
                    </Button>
                  ))}
                  {isClinicAdminOrHigher() && (
                    <PlannerDownloader
                      {...{
                        startDate: startDate?.toDate(),
                        endDate: startDate
                          ?.clone()
                          .add(viewMode === "day" ? 0 : 7, "days")
                          .toDate(),
                      }}
                    />
                  )}
                </Grid>
                {/* Myke: Removing this (temporarily at least) as you can't filter by vaccinator.. not with the new endpoint anyway */}
                {/* <Grid
              item
              md={2}
              xs={12}
              style={{
                position: "relative",
                display: role === "TrustWorker" ? "none" : "block",
              }}
            ><Typography
              component="span"
              variant="subtitle2"
              color="primary"
              style={dropdownlabel}
            >
              Filter By Vaccinator
            </Typography>
            <Paper elevation={0}>
              <DropdownInput
                inputFieldProps={{
                  id: "vaccinator-name",
                  name: "vaccinator_name",
                  value: selectedVaccinator,
                  placeholder: "Search Vaccinator",
                  required: true,
                }}
                options={vaccinators}
                multiple
                setDropDownValue={setSelectedVaccinator}
                onChange={(value) => {
                  handleChange(value);
                }}
                styles={{ marginTop: "0" }}
              />
            </Paper> </Grid>*/}
                {isClinicAdminOrHigher() && (
                  <Grid item className="createClinicButton">
                    <Button
                      color="secondary"
                      style={{ color: "#ffff" }}
                      variant="contained"
                      to="/clinics/create"
                      component={Link}
                    >
                      <AddIcon fontSize="small" />
                      Create Clinic
                    </Button>
                  </Grid>
                )}
              </Grid>
            </div>
            {/* Certainly not the correct way to mark up a checkbox but rushing like mad */}
            {/* Update: Removing for #2105 */}
            {/* {selectedPathogens?.value?.length >= 2 && (
              <Grid item md={4} xs={9}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedRequireBookingAllPathogens}
                      onChange={(e) => {
                        setSelectedRequireBookingAllPathogens(e.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography
                      component="span"
                      variant="subtitle2"
                      color="primary"
                    >
                      Only clinics that require all vaccine types
                    </Typography>
                  }
                />
              </Grid>
            )} */}
          </div>
          <div className={`planner-wrapper wrapper-bleed -${viewMode}`}>
            {viewMode === "day" ? (
              <WeekCalendar
                firstDay={moment(startDate)}
                startTime={moment({ h: 0, m: 0 })}
                endTime={moment({ h: 23, m: 59 })}
                numberOfDays={1}
                eventComponent={EventComponent}
                showModalCase={[]}
                cellHeight={30}
                // Events round position to nearest 30 mins:
                scaleUnit={30}
                dayFormat={"ddd, Do MMMM"}
                scaleFormat={"H:mm"}
                headerCellComponent={HeaderCellComponent}
                selectedIntervals={events}
                // onIntervalSelect={handleSelect}
                eventSpacing={0}
              />
            ) : viewMode === "list" ? (
              <div>
                <h2>Week commencing {startDate.format("ddd Do MMMM")}</h2>
                <PlannerList {...{ data: events, page: listPage }} />
              </div>
            ) : (
              // Week (default)
              <>
                <WeekCalendar
                  firstDay={moment(startDate)}
                  startTime={moment({ h: 0, m: 0 })}
                  endTime={moment({ h: 23, m: 59 })}
                  numberOfDays={7}
                  eventComponent={EventComponent}
                  showModalCase={[]}
                  cellHeight={30}
                  // Events round position to nearest 30 mins:
                  scaleUnit={30}
                  dayFormat={"ddd, Do MMMM"}
                  scaleFormat={"H:mm"}
                  headerCellComponent={HeaderCellComponent}
                  selectedIntervals={events}
                  // onIntervalSelect={handleSelect}
                  eventSpacing={0}
                />
              </>
            )}
            <Button
              title="Previous"
              className="planner-arrow -left"
              onClick={() => {
                // Admittedly these don't currently update the route when panning around in week/planner view but not a requirement
                // props.history.push(
                //   `/clinics/view-mode/day/${startDate
                //     .clone()
                //     .subtract(1, "days")
                //     .format("YYYY-MM-DD")}`
                // );
                changeStartDate(viewMode === "day" ? -1 : -7);
                setListPage(0);
                scrollTo8am();
              }}
            >
              <ArrowLeft />
            </Button>
            <Button
              title="Next"
              className="planner-arrow -right"
              onClick={() => {
                changeStartDate(viewMode === "day" ? 1 : 7);
                setListPage(0);
                scrollTo8am();
              }}
            >
              <ArrowRight />
            </Button>
          </div>
        </>
      )}
      {action && (
        <ToastMessage
          variant={action.type}
          message={action.message}
          clear={() => {
            setAction(null);
          }}
        />
      )}
      {loading && (
        <div className="interstitial">
          <CircularProgress />
        </div>
      )}
    </main>
  );
}
