import ConfirmDialog from "../../ConfirmDialog";

interface ArchiveUserDialogProps {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
  activeItem: any;
  userHasAnyReservation: (arg0: any) => number;
}

export function ArchiveUserDialog({
  open,
  onConfirm,
  onClose,
  activeItem,
  userHasAnyReservation,
}: ArchiveUserDialogProps) {
  const config = {
    open: open,
    title: `Archive ${activeItem?.firstName} ${activeItem?.lastName}`,
    onClose: onClose,
    onConfirm: onConfirm,
    children: (
      <>
        {/* dd-363 Messaging around deleting vaccinated/booked staff
          NB: It can have none/any/all of these! */}

        {activeItem && userHasAnyReservation(activeItem) ? (
          <>
            <p>
              This staff member has an appointment booked. Their appointment
              will be cancelled and they will be notified of the cancellation.
            </p>
          </>
        ) : null}
        <p>
          Archiving will hide them within the system. They will be excluded from
          all functionality in the system. However, any vaccination history
          associated with the record will be retained.
        </p>
        <p>
          To view archived staff, select ‘View Archived Staff’ in the ‘Active /
          Inactive’ section in ‘Filters’. From there you can permanently delete
          a record by clicking the ‘Delete’ icon.
        </p>
        {activeItem ? (
          <p>
            <strong>
              Are you sure you want to archive {activeItem.firstName}{" "}
              {activeItem.lastName}?
            </strong>
          </p>
        ) : null}
      </>
    ),
  };

  return <ConfirmDialog {...config}></ConfirmDialog>;
}
