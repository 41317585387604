import { Button, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useForm } from "react-hook-form";
import Wysiwyg from "../../../../../components/Wysiwyg";
import DataClient from "../../../../../lib/services/api/DataClient";
import { filterTagsNaughties, filterTagsUrls, Tags } from "../../../Tags";
import Checkbox from "../../../Patients/BookAppointment/Checkbox";
import styles from "./EditTemplateForm.module.scss";
import useNotificationTemplate, {
  NotificationTemplate,
  NotificationTemplateEnum,
} from "./useNotificationTemplate";
import uniq from "lodash/uniq";
import { validateSmsBody } from "./smsBodyValidator";
type EditTemplateFormProps = {
  editingSuperAdminTemplate: boolean;
  fetchUrl: string;
  onCancel: (...args: any[]) => void;
  onUpdate: (...args: any[]) => void;
  onError: (...args: any[]) => void;
  setFeedback: (feedback: { type: string; message: string }) => void;
  id: number;
  loading?: boolean;
};
export interface TagSets {
  tagsUrls?: string[];
  tagsRegular?: string[];
}
const formId = "editEmailSmsTemplate";
export default function EditTemplateForm({
  editingSuperAdminTemplate,
  fetchUrl,
  onCancel,
  onUpdate,
  onError,
  setFeedback,
  id,
  loading,
}: EditTemplateFormProps): JSX.Element {
  const { template, loading: templateLoading } = useNotificationTemplate({
    id,
    fetchUrl,
    editingSuperAdminTemplate,
  });
  const {
    control,
    watch,
    reset,
    handleSubmit,
    register,
    getValues,
    formState: { errors },
    setError,
  } = useForm();
  const [saveState, setSavedState] = React.useState<NotificationTemplate>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    if (template) reset(template);
    setSavedState(template);
  }, [template, reset]);
  console.log(":: ~ template:", template);
  const tagsSystem = template?.systemTags
    ? template.systemTags.split(" ").filter(filterTagsNaughties).sort()
    : [];
  const tagsMandatory = template?.mandatoryTags
    ? template.mandatoryTags.split(" ").sort()
    : [];
  const tagsOptional = template?.optionalTags
    ? template.optionalTags.split(" ").sort()
    : [];
  const tagsAll = uniq([
    ...(editingSuperAdminTemplate ? tagsSystem : []),
    ...tagsMandatory,
    ...tagsOptional,
  ]);
  // #4451 Add {{EmailAddress}} to placeholders if on one of two templates (and available)
  if (
    [
      NotificationTemplateEnum.CampaignInvite,
      NotificationTemplateEnum.WelcomeToFluTrack,
      NotificationTemplateEnum.CampaignContinuation,
      NotificationTemplateEnum.ReminderToBook,
      NotificationTemplateEnum.ReminderToBookForVerified,
      NotificationTemplateEnum.ClinicCancelledByTrustAdmin,
      NotificationTemplateEnum.ClinicMovedByTrustAdmin,
      NotificationTemplateEnum.AdministratorWelcome,
      NotificationTemplateEnum.ReportingAdminAssignment,
      NotificationTemplateEnum.ReportingStaffAdminAssignment,
    ].includes(template?.notificationTemplateId) &&
    tagsSystem.includes("{{EmailAddress}}")
  ) {
    tagsAll.push("{{EmailAddress}}");
  }
  // VT-57 In a similar way, add {{TrustName}} to Post-vax Confirmation
  if (
    [
      NotificationTemplateEnum.PostVaccinationEmail,
    ].includes(template?.notificationTemplateId) &&
    tagsSystem.includes("{{TrustName}}")
  ) {
    tagsAll.push("{{TrustName}}");
  }
  tagsAll.sort();
  const tagsUrls = tagsAll.filter(filterTagsUrls);
  const tagsRegular = tagsAll.filter((t) => !filterTagsUrls(t));
  const { smsAllowed } = template || {};
  const onSubmit = async (data: any) => {
    const {
      description,
      emailBody,
      emailSubject,
      name,
      displayName,
      sendSms,
      smsBody,
      suppress,
    } = data;
    const { id: templateId, notificationTemplateId, trustId } = template;
    const emailTagValidation = validateSmsBody(emailBody, tagsAll);
    if (emailTagValidation !== true) {
      const errorMessage: string = emailTagValidation
        ? emailTagValidation
        : "Invalid tags";
      setError("emailBody", { message: errorMessage });
      return;
    }
    if (!tagsMandatory.every((p) => emailBody.includes(p))) {
      const tagsMissing = tagsMandatory.filter((p) => !emailBody.includes(p));
      throw Error(
        `The placeholder${tagsMissing.length > 1 ? "s" : ""} ${tagsMissing.join(
          ", "
        )} must be included in the body of the email`
      );
    }
    // isDefaultTemplate is a potentially obsolete concept (the initial creation of a trust admin template) as they're copied when we create trusts
    const creatingNewTrustAdminTemplate =
      !editingSuperAdminTemplate && template.isDefaultTemplate;
    const url = `/${
      editingSuperAdminTemplate
        ? "NotificationTemplate"
        : "TrustNotificationTemplate"
    }${creatingNewTrustAdminTemplate ? "" : `/${templateId}`}`;
    const options = {
      emailSubject,
      emailBody,
      // Explicitly send null if sms not allowed
      smsBody:
        smsAllowed && (editingSuperAdminTemplate || sendSms) ? smsBody : null,
      suppress,
    };
    if (creatingNewTrustAdminTemplate) {
      Object.assign(options, {
        notificationTemplateId,
        trustId,
      });
    }
    if (editingSuperAdminTemplate) {
      Object.assign(options, {
        description,
        name,
        displayName,
      });
    }
    setIsSubmitting(true);
    DataClient[creatingNewTrustAdminTemplate ? "postData" : "update"](
      url,
      options
    )
      .then((res) => {
        if (res.success) {
          onUpdate();
          setSavedState(res.results);
          setIsSubmitting(false);
        } else {
          setIsSubmitting(false);
          console.error(res);
          throw Error("Form failed to submit");
        }
      })
      .catch(() => {
        setIsSubmitting(false);
        onError();
      });
  };
  const watchSendSms = watch("sendSms");
  const sendTestEmail = () => {
    if (!testEmail?.length) {
      return setFeedback({
        type: "error",
        message: "You must enter an email.",
      });
    }

    const emailSubject = getValues("emailSubject");
    const emailBody = getValues("emailBody");
    if (
      emailSubject !== saveState.emailSubject ||
      emailBody !== saveState.emailBody
    ) {
      setFeedback({
        type: "error",
        message: "You must save the template first.",
      });
      return;
    }
    DataClient.getData(
      editingSuperAdminTemplate
        ? "/NotificationTemplate/TestEmail"
        : "/TrustNotificationTemplate/TestEmail",
      {
        notificationTemplateId: id,
        emailAddress: testEmail,
      }
    )
      .then(() => {
        setFeedback({ type: "success", message: "Test Email Sent" });
      })
      .catch(() => {
        setFeedback({ type: "error", message: "Failed to send test email" });
      });
  };
  const [testEmail, setTestEmail] = useState("");
  return loading || templateLoading ? (
    <CircularProgress />
  ) : (
    <div className={styles.template}>
      <h1>Edit Template: {template.name}</h1>
      <form
        id={formId}
        className={styles.form}
        onSubmit={(e) => {
          handleSubmit(onSubmit)(e).catch((err) => {
            console.log(err);
            setFeedback({ type: "error", message: err.message });
          });
        }}
        autoComplete="off"
      >
        <div className={styles.email}>
          {editingSuperAdminTemplate && (
            <>
              <label className={styles.textInput}>
                Name
                <input
                  {...register("name", {
                    required: "You must enter a name",
                  })}
                />
                {errors.name && <span role="alert">{errors.name.message}</span>}
              </label>
              <label className={styles.textInput}>
                Display name
                <input {...register("displayName")} />
                <span>
                  Markdown formatted name displayed in trust settings.
                </span>
                {errors.displayName && (
                  <span role="alert">{errors.displayName.message}</span>
                )}
              </label>

              <label className={styles.textInput}>
                Description
                <textarea
                  {...register("description", {
                    required: "You must enter a description",
                  })}
                  rows={3}
                />
                {errors.description && (
                  <span role="alert">{errors.description.message}</span>
                )}
              </label>
            </>
          )}
          {!editingSuperAdminTemplate && (
            <p className={styles.templateDescription}>{template.description}</p>
          )}
          <label className={styles.textInput}>
            Email Subject
            <input
              {...register("emailSubject", {
                required: "You must enter an email subject",
              })}
              autoComplete="new-password"
            />
            {errors.emailSubject && (
              <span role="alert">{errors.emailSubject.message}</span>
            )}
          </label>
          <label className={styles.textInput}>Email Body</label>
          <Wysiwyg
            {...{
              control,
              name: "emailBody",
              tagSets: { tagsUrls, tagsRegular },
            }}
          />
          {errors.emailBody && (
            <span role="alert">{errors.emailBody.message}</span>
          )}
        </div>
        {(tagsSystem || tagsMandatory || tagsOptional) && (
          <div className="placeholders -aside">
            <h3>Placeholders</h3>
            <Tags
              {...{
                title: "Text Placeholders",
                subheading:
                  "These must be placed in the email. The contents of these will be populated by the system.",
                tags: tagsRegular,
                tagsToAsterisk: tagsMandatory,
                copyable: true,
              }}
            />
            <Tags
              {...{
                title: "Available Buttons",
                subheading:
                  "Add these by clicking the 'Insert Button / Link' button.",
                tags: tagsUrls,
                tagsToAsterisk: tagsMandatory,
                copyable: false,
              }}
            />
            <p>
              <strong>*Mandatory</strong>
            </p>
          </div>
        )}
        <div className={styles.testEmail}>
          <h2>Send a Test Email</h2>
          <label className={styles.textInput}>
            Email Address
            <input
              value={testEmail}
              onChange={(e) => {
                setTestEmail(e.currentTarget.value);
              }}
            />
          </label>
          <Button color="secondary" variant="contained" onClick={sendTestEmail}>
            Send a Test Email (Click ‘Save’ below to save any changes first)
          </Button>
        </div>
        {smsAllowed && (
          <div className={styles.smsNotification}>
            <h2>SMS Notification</h2>
            {!editingSuperAdminTemplate && (
              <Checkbox
                label="Send SMS Notification"
                register={register}
                id="sendSms"
              />
            )}
            {editingSuperAdminTemplate || watchSendSms ? (
              <label className={styles.textInput}>
                SMS Body Text
                <textarea
                  {...register("smsBody", {
                    required: "You must enter SMS body text",
                    validate: (v: string) => validateSmsBody(v, tagsRegular),
                  })}
                  autoCorrect="off"
                  rows={3}
                />
                {errors.smsBody && (
                  <span role="alert">{errors.smsBody.message}</span>
                )}
              </label>
            ) : null}
          </div>
        )}
        <div className={styles.actions}>
          <Button
            form={formId}
            type="submit"
            color="secondary"
            variant="contained"
            disabled={isSubmitting}
          >
            Save
          </Button>
          <Button onClick={onCancel}>Cancel</Button>
        </div>
      </form>
    </div>
  );
}
