import MomentUtils from "@date-io/moment";
import { createTheme } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import { createContext, useEffect, useState } from "react";
import "./App.scss";
import { HelpCopy } from "./components/Help/Help";
import { requireAuthBoolean } from "./lib/hoc/requireAuth";
import { overridesMain } from "./lib/themes/overrides";
import { palette } from "./lib/themes/vars";
import { Roles } from "./lib/types/TrustRoles";
import { helpCopyJsx } from "./HelpCopy";
import BrowserAppRoutes from "./views/Routes";
const theme = createTheme({
  palette,
  overrides: overridesMain as any,
});
export const HelpContext = createContext([]);
export default () => {
  const [helpCopy, setHelpCopy] = useState<HelpCopy[]>([]);
  useEffect(() => {
    (async () => {
      const atLeastTrustworker = requireAuthBoolean([
        Roles.TrustWorker,
        Roles.TrustAdmin,
        Roles.ClinicAdmin,
        Roles.Vaccinator,
      ]);
      if (!atLeastTrustworker) return;
      setHelpCopy(helpCopyJsx as HelpCopy[]);
    })();
  }, [setHelpCopy]);
  return (
    <HelpContext.Provider value={helpCopy}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <BrowserAppRoutes />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </HelpContext.Provider>
  );
};
