import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { Alert } from "@material-ui/lab";
import find from "lodash/find";
import { Component } from "react";

type RequiresProperty = {
  id: string;
  value?: any;
  notValue?: any;
  func?: (field: InputProps) => boolean;
};

type RequiresFunction = (fields: InputProps[]) => boolean;

export interface InputProps {
  id: string;
  label?: string | JSX.Element;
  legend?: string | JSX.Element;
  type:
    | "text"
    | "date"
    | "email"
    | "number"
    | "time"
    | "timespan"
    | "select"
    | "selectArray"
    | "radio" // radio and checkbox can be a group set,
    | "checkbox" // if options array is provided
    | "hidden";
  repeater?: boolean; // only currently for timespan, no need for others so not worth coding
  value?: any;
  options?: {
    label: string;
    // value: string | number | HTMLOptionElement;
    value: any;
    disabled?: boolean;
  }[];
  min?: number | string;
  max?: number | string;
  step?: number;
  className?: string;
  required?: boolean;
  requires?: RequiresProperty[] | RequiresFunction;
  validation?: (...args: any[]) => void;
  valid?: boolean;
  validationErrors?: [];
  fields?: any[];
  isBoolean?: boolean;
  disabled?: boolean;
  updateFields?: (...args: any[]) => Promise<any>;
  onChangeCallback?: (...args: any[]) => void;
}
export default class Input extends Component<
  InputProps,
  Record<string, unknown>
> {
  state = { count: 0, value: null } as any;
  componentDidMount() {
    this.initRepeater();
  }
  componentDidUpdate(props: any) {
    if (this.props.value !== props.value) {
      this.initRepeater();
    }
  }
  initRepeater() {
    // If repeater on edit page with values, increase the count to render
    const { value, repeater } = this.props;
    if (repeater) {
      this.setState({ count: value?.length ? value.length : 0 });
    }
  }
  updateField(id: string, value: any) {
    let { fields } = this.props;
    const { updateFields } = this.props;
    const field = fields.find((f) => f.id === id);
    field.value = field.isBoolean ? !!value : value;
    // console.log("::updateField", id, field);
    // Validate them all and add key, to prevent submit elsewhere
    fields = fields.map((f) => ({
      ...f,
      valid: !(f?.validation && f.validation(f.value, fields)),
      validationErrors: (f?.validation && f.validation(f.value, fields)) || [],
    }));
    updateFields(fields).then(() => {
      this.props.onChangeCallback && this.props.onChangeCallback(field);
    });
  }
  handleInputChange = (e: any) => {
    const { fields, validation } = this.props;
    const { type, name, checked } = e.target;
    let { value } = e.target;
    const id: string = name;
    value =
      type === "checkbox"
        ? checked
        : type === "radio" && /^\d+$/.test(value) // only digits (no whitespace)
        ? parseInt(value)
        : value;
    this.setState({
      value,
    });
    // console.log("::handleInputChange", { id, type, checked, value });
    this.updateField(id, value);
  };
  handlePairChange = ({
    e,
    id,
    row,
    column,
  }: {
    e: any;
    id: string;
    row: number;
    column: number;
  }) => {
    const { value } = e.target;
    const { fields } = this.props;
    const field = find(fields, { id });
    field.value = field.value || [];
    field.value[row] = field.value[row] ? field.value[row] : [];
    field.value[row][column] = value;
    // If both times are set, re-enable the add button
    // if (field.value[row][0] && field.value[row][1]) {
    //   this.setState({ disabledAdd: true });
    // }
    this.updateField(id, field.value);
  };
  add = (e: any) => {
    e.preventDefault();
    this.setState({
      count: this.state.count + 1,
    });
  };
  remove = ({ e, id, row }: { e: any; id: string; row: number }) => {
    e.preventDefault();
    const { fields } = this.props;
    const field = find(fields, { id });
    // console.log({ fields, id, field }, this.state.count);
    field?.value?.splice(row, 1);
    this.updateField(id, field.value);
    this.setState({
      count: this.state.count >= 1 ? this.state.count - 1 : this.state.count,
    });
  };
  input = () => {
    const { id, type, value, min, max, step, required, disabled } = this.props;
    // console.log(":: ~ id:", id, value, typeof value);
    return (
      <input
        {...{
          id,
          name: id,
          disabled,
          type,
          required,
          // value,
          defaultValue: value,
          min,
          max,
          step,
          onChange: this.handleInputChange,
        }}
      />
    );
  };
  select = () => {
    const { id, options, value, required, disabled } = this.props;
    return (
      <select
        {...{
          id,
          name: id,
          disabled,
          key: `select-${id}`,
          required,
          onChange: this.handleInputChange,
          value,
        }}
      >
        {options?.map(
          ({ label: optionLabel, value: optionValue, disabled }) => {
            // console.log(optionValue);
            const optionId = `${id}.${optionValue}`;
            return (
              <option
                {...{
                  value: optionValue,
                  key: `option-${optionId}`,
                  disabled,
                }}
              >
                {optionLabel}
              </option>
            );
          }
        )}
      </select>
    );
  };
  // Same as above but you're gonna wanna parse it on subit with a split
  selectArray = this.select;
  radioCheckbox = () => {
    const { id, type, value, required, disabled } = this.props;
    // console.log({ id, value });
    return (
      <span key={`radioCheckbox-${id}.${value}`}>
        <input
          {...{
            id,
            name: id,
            disabled,
            type,
            required,
            value,
            checked: value,
            onChange: this.handleInputChange,
          }}
        />
        <span className="checkbox__control">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            aria-hidden="true"
            focusable="false"
          >
            <path
              fill="none"
              stroke="currentColor"
              strokeWidth="4"
              d="M1.73 12.91l6.37 6.37L22.79 4.59"
            />
          </svg>
        </span>
      </span>
    );
  };
  radioSet = () => {
    const { id, type, isBoolean, value, options, required, disabled } =
      this.props;
    return options?.map(
      ({
        label: optionLabel,
        value: optionValue,
        disabled: optionDisabled,
      }) => {
        const optionId = `${id}.${optionValue}`;
        return (
          <span key={optionId}>
            <input
              {...{
                type,
                name: id,
                disabled: disabled || optionDisabled,
                id: optionId,
                value: optionValue,
                checked:
                  isBoolean && [0, 1, true, false].includes(value)
                    ? !!optionValue === !!value
                    : optionValue === value || optionValue === `${value}`,
                required,
                onChange: this.handleInputChange,
              }}
            />
            <label htmlFor={optionId}>{optionLabel}</label>
          </span>
        );
      }
    );
  };
  pair = (row?: number, showRemoveButton = false) => {
    const { id, type, min, disabled } = this.props;
    const value: any[][] = this.props.value || [];
    return (
      <div className="pair" key={`pair-${id}.${row}`}>
        <input
          {...{
            id: `${id}.${row}.0`,
            name: `${id}.${row}.0`,
            disabled,
            key: `${id}.${row}.0`,
            type: type.replace("span", ""),
            required: true,
            value: value[row] && value[row][0] ? value[row][0] : "",
            min,
            onChange: (e) => this.handlePairChange({ e, id, row, column: 0 }),
          }}
        />
        <input
          {...{
            id: `${id}.${row}.1`,
            name: `${id}.${row}.1`,
            disabled,
            key: `${id}.${row}.1`,
            type: type.replace("span", ""),
            required: true,
            value: value[row] && value[row][1] ? value[row][1] : "",
            min,
            onChange: (e) => this.handlePairChange({ e, id, row, column: 1 }),
          }}
        />
        {showRemoveButton && (
          <Button
            onClick={(e) => this.remove({ e, id, row })}
            // disabled={!(value?.[row]?.[0] && value[row][1])}
            variant="contained"
            size="small"
            startIcon={<DeleteIcon />}
          >
            Remove
          </Button>
        )}
      </div>
    );
  };
  render() {
    const {
      id,
      label,
      legend,
      fields,
      type,
      repeater,
      options,
      className,
      required,
      validationErrors,
      valid,
    } = this.props;
    const { count, value } = this.state;
    if (type === "hidden") return null;
    const inputs = (
      <>
        {["text", "date", "email", "number", "time"].includes(type)
          ? // Date is not IE compatible at all but then again most of the site isn't. We'd have to rig up material UI for it
            this.input()
          : ["select", "selectArray"].includes(type)
          ? this.select()
          : ["radio"].includes(type) && options.length
          ? this.radioSet()
          : ["radio", "checkbox"].includes(type) && !options?.length
          ? this.radioCheckbox()
          : ["timespan"].includes(type)
          ? [...Array(count)].map((c, i) => this.pair(i, i === count - 1))
          : ""}
      </>
    );
    return (
      // #3661 we absolutely have to have these wrapped in a keyed element or values unpredictably vanish
      // when changing booking model a few times - but .section-wrap is styled with a shadowy display: contents
      <div key={`input-wrap-${id}`} className={`section-wrap -${type}`}>
        {legend && <legend key={`legend-${id}`}>{legend}</legend>}
        <section
          className={`input input-${type} ${
            options?.length ? "input-set" : ""
          } ${className || ""}`.trim()}
          key={`section-${id}`}
        >
          {label && (
            <label htmlFor={id}>
              {label}
              {required && "*"}
            </label>
          )}
          {inputs}
          {repeater && (
            <Button
              onClick={this.add}
              color="secondary"
              variant="contained"
              size="small"
              startIcon={<AddIcon />}
            >
              Add
            </Button>
          )}
          {valid === false && validationErrors && (
            <Alert severity="error">{validationErrors?.join(", ")}</Alert>
          )}
        </section>
      </div>
    );
  }
}
