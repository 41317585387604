import { useState } from "react";
import { useParams } from "react-router-dom";
import DataClient from "../../../../../../lib/services/api/DataClient";
import { getTrustId } from "../../../../../../lib/services/TrustSwitcher";
import {
  CampaignFrontLineImmFormSettingOptionDTO,
  CampaignFrontLineImmFormSettingsUpdateDTO,
} from "./models";
import { Role as NonSgRole } from "./useNonStaffRoles";
import { Group, Role as SgRole } from "./useStaffGroups";

type GroupType =
  | "frontLineStaffGroups"
  | "immFormStaffGroups"
  | "frontLineNonEsrStaffTypes"
  | "immFormNonEsrStaffTypes";

type AnyRole = SgRole | NonSgRole;

export function useImmFormSettings(
  setConfirmDialogOpen: (isOpen: boolean) => void,
  setLoading: (isLoading: boolean) => void,
  setFeedback: (value: { type: string; message: string }) => void
): [
  updateImmFormSettingsByRole: (
    groupType: GroupType,
    role: AnyRole,
    isChecked: boolean
  ) => void,
  updateImmFormSettingsByGroup: (
    groupType: GroupType,
    group: Group,
    isChecked: boolean
  ) => void,
  saveImmFormSettingsData: () => void,
  hasChanges: boolean
] {
  const { campaignId } = useParams<{ campaignId: string }>();
  const [immFormSettings, setImmFormSettings] =
    useState<CampaignFrontLineImmFormSettingsUpdateDTO>({
      campaignId: +campaignId,
      frontLineStaffGroups: [],
      immFormStaffGroups: [],
      frontLineNonEsrStaffTypes: [],
      immFormNonEsrStaffTypes: [],
    });

  const [hasChanges, setHasChanges] = useState(false);
  // const [currentTrustId, setCurrentTrustId] = useState(null);

  const updateImmFormSettings = (
    groupType: GroupType,
    settingOptionDTOArray: CampaignFrontLineImmFormSettingOptionDTO[]
  ) => {
    const currentArray = [...immFormSettings[groupType]];

    settingOptionDTOArray.forEach((settingOptionDTO) => {
      const alreadyExistsIdx = currentArray.findIndex(
        (item) => item.optionId === settingOptionDTO.optionId
      );
      if (alreadyExistsIdx !== -1) {
        currentArray[alreadyExistsIdx] = settingOptionDTO;
      } else {
        currentArray.push(settingOptionDTO);
      }
    });

    setHasChanges(true);

    setImmFormSettings({
      ...immFormSettings,
      [groupType]: currentArray,
    });
  };

  const updateImmFormSettingsByRole = (
    groupType: GroupType,
    role: AnyRole,
    isChecked: boolean
  ) => {
    role.current = isChecked;
    const settingOptionDTOArray = mapRoleArrayToSettingOptionDTOArray([role]);
    updateImmFormSettings(groupType, settingOptionDTOArray);
  };

  const updateImmFormSettingsByGroup = (
    groupType: GroupType,
    group: Group,
    isChecked: boolean
  ) => {
    function setAllChecked(
      DtoArray: CampaignFrontLineImmFormSettingOptionDTO[],
      isChecked: boolean
    ): CampaignFrontLineImmFormSettingOptionDTO[] {
      DtoArray.map((role) => {
        role.isOn = isChecked;
      });
      return DtoArray;
    }
    if (group["1"]) {
      console.log("group array");
      //why group coming in as array of groups?
      Object.keys(group).forEach((key) => {
        const settingOptionDTOArray = mapGroupToSettingOptionDTOArray(
          group[key]
        );
        updateImmFormSettings(
          groupType,
          setAllChecked(settingOptionDTOArray, isChecked)
        );
      });
    } else {
      const settingOptionDTOArray = mapGroupToSettingOptionDTOArray(group);
      updateImmFormSettings(
        groupType,
        setAllChecked(settingOptionDTOArray, isChecked)
      );
    }
  };

  const saveImmFormSettingsData = () => {
    const currentTrustId = getTrustId();
    if (!currentTrustId) {
      setFeedback({
        type: "error",
        message:
          "There was an error with your trust id, please refresh and try again",
      });
      return;
    }
    setConfirmDialogOpen(false);
    setLoading(true);
    DataClient.postData(
      `/CampaignFrontLineImmFormSettings/${currentTrustId}`,
      immFormSettings
    )
      .then(() => {
        setHasChanges(false);
        setLoading(false);
        setFeedback({
          type: "success",
          message: "Assignment settings saved",
        });
      })
      .catch(() => {
        setLoading(false);
        setFeedback({
          type: "error",
          message:
            "There was an error saving your changes, please refresh and try again",
        });
      });
  };

  const mapGroupToSettingOptionDTOArray = (
    group: Group
  ): CampaignFrontLineImmFormSettingOptionDTO[] => {
    return mapRoleArrayToSettingOptionDTOArray(group.children);
  };

  const mapRoleArrayToSettingOptionDTOArray = (
    roles: AnyRole[]
  ): CampaignFrontLineImmFormSettingOptionDTO[] => {
    return roles.map((role) => {
      const anyrole = role as any;
      const optionId = anyrole.jobRoleId
        ? anyrole.jobRoleId
        : anyrole.staffTypeId;
      return {
        optionId: optionId,
        isOn: role.current,
      };
    });
  };
  return [
    updateImmFormSettingsByRole,
    updateImmFormSettingsByGroup,
    saveImmFormSettingsData,
    hasChanges,
  ];
}

// const getImmformSettings = (): CampaignFrontLineImmFormSettingsUpdateDTO => {
//   return {
//     trustId: 35, // TODO - hardcoded
//     frontLineStaffGroups: useStaffGroups.
//   }
// }
