import { CircularProgress, Grid, Tab, Tabs } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import DropdownInput from "../../components/FormComponent/dropdown";
import Help from "../../components/Help/Help";
import { enumToArray } from "../../lib/enumManipulations";
import { PathogenSelectOptions, trustInvolvesCovid } from "../../lib/pathogens";
import {
  getRolesCurrentTrust,
  getTrustsFromMe,
  isTrustGroupUser
} from "../../lib/services/TrustSwitcher";
import ComplianceTab from "../Dashboard/ComplianceTab";
import DashboardApi from "../Dashboard/DashboardApi";
import DashboardTab from "../Dashboard/DashboardTab";
import ImmFormTab from "../Dashboard/ImmFormTab";
import { VaccinatorsLeagueTableTab } from "../Dashboard/VaccinatorsLeagueTableTab";
import { StaffListTab } from "../Dashboard/ReportingTab";
import StaffGroupsLevelsTab from "../Dashboard/StaffGroupsLevelsTab";
import { TrustsLeagueTableTab } from "../Dashboard/TrustsLeagueTableTab";

const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8"
  },
  indicator: {
    backgroundColor: "#1890ff"
  }
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: 400,
    marginRight: theme.spacing(4),
    "&:hover": {
      color: "#40a9ff",
      opacity: 1
    },
    "&$selected": {
      color: "#1890ff",
      fontWeight: 500
    },
    "&:focus": {
      color: "#40a9ff"
    }
  },
  selected: {}
  // @ts-ignore
}))((props: any) => <Tab disableRipple {...props} label={props.label} />);

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginLeft: theme.spacing(5),
    marginRight: theme.spacing(5),
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    }
  },
  pathogenDropdown: {
    paddingRight: 3,
    paddingTop: 3,
    borderBottom: "1px solid #e8e8e8",
    maxWidth: "15em"
  },
  doubleDropdown: {
    display: "flex",
    maxWidth: "unset",
    "&:first-child": {
      marginRight: "3px"
    }
  },
  borderBottom: {
    borderBottom: "1px solid #e8e8e8"
  },
  hidden: {
    display: "none"
  },
  root: {
    flexGrow: 1
  },
  padding: {
    padding: theme.spacing(3)
  },
  demo1: {
    backgroundColor: theme.palette.background.paper
  },
  demo2: {
    backgroundColor: "#2e1534"
  }
}));

export default function CustomizedTabs(): JSX.Element {
  const api = DashboardApi;
  const [isMultiTrustUser, setIsMultiTrustUser] = useState(false);
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  enum TabValues {
    "Dashboard",
    "Performance Tables",
    "Trusts' League Table",
    "Vaccinators' League Table",
    "ImmForm",
    "Staff List"
    // "Compliance", // DD3091
  }
  const tabValueChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    tabValue: TabValues
  ) => {
    setTabValue(tabValue);
  };
  const [loading, setLoading] = useState(true);
  const getUserConfig = () => {
    setLoading(true);
    api
      .getUserConfig()
      .then((res) => {
        const options = [];
        const pathogens: number[] = res.pathogens;

        if (pathogens.indexOf(1) > -1) {
          options.push({ label: "Flu", value: "1" });
        }
        if (pathogens.indexOf(2) > -1) {
          options.push({ label: "Covid", value: "2" });
        }
        if (pathogens.indexOf(2) > -1 && pathogens.indexOf(1) > -1) {
          options.push({ label: "Flu & Covid", value: "1|2" });
        }

        if (options.length > 0) {
          setSelectedPathogenOption(options[0]);
          setSelectedPathogenIds(options[0].value);
          setPathogenOptions(options);
        }

        const trustOptions = res.trusts.map((trustInfo) => {
          return { label: trustInfo.trustName, value: trustInfo.id };
        });
        if (trustOptions.length > 1) {
          trustOptions.unshift({ label: "All Trusts", value: 0 });
        }
        setTrustOptions(trustOptions);
        setSelectedTrustOption(trustOptions[0]);
      })
      // .catch((e) => {
      //   if (!e.handled) {
      //   }
      // })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    (async () => {
      getUserConfig();
      const trusts = await getTrustsFromMe();
      const _isMultiTrustUser = trusts?.length > 1;
      setIsMultiTrustUser(_isMultiTrustUser);
    })();
  }, []);

  const [trustOptions, setTrustOptions] = useState([]);
  const [selectedTrustOption, setSelectedTrustOption] = useState<any>([]);

  const [pathogenOptions, setPathogenOptions] = useState([]);
  const [selectedPathogenIds, setSelectedPathogenIds] = useState("1");
  const [selectedPathogenOption, setSelectedPathogenOption] = useState({
    label: "loading..",
    value: ""
  });

  // used  to populate the dropdown
  const [level1IdsOptions, setLevel1IdsOptions] = useState<
    { label: string; value: string }[]
  >([]);
  // used to show selected items in dropdown
  const [level1IdsOptionsSelected, setLevel1IdsOptionsSelected] = useState<
    { label: string; value: string }[]
  >([]);

  // used for sever communication
  const [excludeL1Ids, _setExcludeL1Ids] = useState("");
  const setExcludeL1Ids = (value: string[]) => {
    // invert selection as spec changes to include, not exclude.
    const toExclude = level1IdsOptions
      .filter((opt) => !value.includes(opt.value))
      .map((opt) => opt.value);
    const pipeForQParams = toExclude.join("|");
    _setExcludeL1Ids(pipeForQParams);
  };

  // Can this be moved to the top where pathogens are GETted?
  useEffect(() => {
    (async function getLevel1DropdownOptions() {
      const newLevel1Ids = await api.level1Ids(
        selectedPathogenIds,
        false, // tODO make dynamic fhcw
        selectedTrustOption?.value
      );
      const newLevel1IdsOptions: { label: string; value: string }[] =
        newLevel1Ids.map((name: string) => {
          return { label: name, value: name };
        });
      setLevel1IdsOptions(newLevel1IdsOptions);
      setLevel1IdsOptionsSelected(
        newLevel1IdsOptions.filter(
          (opt) => opt.value !== "selectAll" && opt.value !== "selectNone"
        )
      );
    })();
  }, [selectedPathogenIds]);

  const userHasReportingStaffViewerRole = () => {
    return (
      getRolesCurrentTrust().length &&
      getRolesCurrentTrust().includes("ReportingStaffViewer")
    );
  };

  if (loading)
    return (
      <div className="interstitial">
        <CircularProgress />
      </div>
    );
  // #dd-357 Visibly hide dashboard contents if neither a trust group user, nor trust admin
  // (Nav item is hidden but people could sneakily navigate back and see this page after changing trust)
  // dd#4617 - only show if reporting viewer or trustgroupuser
  if (!isTrustGroupUser() && !userHasReportingStaffViewerRole) {
    return (
      <div className="wrapper-main">
        <div className="wrapper-text">
          <h1>
            You are not authorised for this page
            {isMultiTrustUser && " with this trust"}.
          </h1>
          {isMultiTrustUser && <h2>Please select a different trust.</h2>}
        </div>
      </div>
    );
  }
  return (
    <div className={classes.wrapper}>
      <div className={classes.root}>
        <div className={classes.demo1}>
          <Grid container>
            <Grid item xs={7}>
              <AntTabs
                value={tabValue}
                // @ts-ignore
                onChange={tabValueChange}
              >
                {enumToArray(TabValues)
                  .filter(
                    (t) =>
                      // Hide compliance if trust doesn't involve it
                      !(t.name === "Compliance" && !trustInvolvesCovid()) &&
                      !(
                        t.name === "Staff List" &&
                        !userHasReportingStaffViewerRole()
                      )
                  )
                  .filter(
                    (t) =>
                      // Only show ImmForm if single pathogen
                      t.name !== "ImmForm" ||
                      selectedPathogenOption.value !== "1|2"
                  )
                  .map(({ name }, i) => (
                    <AntTab label={name} key={`tab-${i}`} />
                  ))}
              </AntTabs>
            </Grid>
            <Grid item xs={5} className={classes.borderBottom}>
              <Grid container justifyContent="flex-end">
                <Grid
                  item
                  xs
                  className={clsx(
                    classes.pathogenDropdown,
                    trustOptions.length < 2 ? classes.hidden : ""
                  )}
                >
                  <DropdownInput
                    disableClearable
                    inputFieldProps={{
                      id: "dropdown_trust",
                      name: "dropdown_trust",
                      value: selectedTrustOption
                    }}
                    styles={{
                      marginTop: "0",
                      padding: "2"
                    }}
                    options={trustOptions}
                    setDropDownValue={(option: any) => {
                      setSelectedTrustOption(option);
                    }}
                  />
                </Grid>
                {![TabValues["Compliance"], TabValues["Staff List"]].includes(
                  tabValue
                ) && (
                  <Grid
                    item
                    xs
                    className={clsx(
                      classes.doubleDropdown,
                      classes.pathogenDropdown
                    )}
                  >
                    <div
                      className="help-tooltip-wrapper"
                      style={{ width: "100%" }}
                    >
                      <DropdownInput
                        multipleCheckbox={true}
                        inputFieldProps={{
                          id: "level1Ids",
                          name: "level1Ids",
                          value: level1IdsOptionsSelected
                        }}
                        styles={{
                          marginTop: "0",
                          padding: "2",
                          color: "black",
                          marginRight: "0"
                        }}
                        options={level1IdsOptions}
                        value={level1IdsOptions[0]}
                        setDropDownValue={(ddOptions: any[]) => {
                          setLevel1IdsOptionsSelected(ddOptions);
                          setExcludeL1Ids(ddOptions.map((o) => o.value));
                          return;
                        }}
                        className="select-dashboard-pathogen"
                      />
                      <Help helpCopyKey="6.1" />
                    </div>
                    <DropdownInput
                      disableClearable
                      inputFieldProps={{
                        id: "dropdown_pathogen",
                        name: "dropdown_pathogen",
                        value: selectedPathogenOption
                      }}
                      styles={{
                        marginTop: "0",
                        marginLeft: "2em",
                        padding: "2",
                        flex: "0 1 25%"
                      }}
                      options={pathogenOptions}
                      setDropDownValue={(option: any) => {
                        setSelectedPathogenIds(option.value);
                        setSelectedPathogenOption(option);
                      }}
                      className={clsx(
                        "select-dashboard-pathogen",
                        pathogenOptions.length < 2 ? classes.hidden : ""
                      )}
                      htmlAttributes={{
                        "data-theme-id": selectedPathogenOption?.value.replace(
                          "|",
                          ","
                        )
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
        {tabValue === TabValues["Dashboard"] && (
          <DashboardTab
            pathogenIds={selectedPathogenIds}
            trustId={selectedTrustOption?.value}
            excludeL1Ids={excludeL1Ids}
          />
        )}
        {tabValue === TabValues["Performance Tables"] && (
          <StaffGroupsLevelsTab
            pathogenIds={selectedPathogenIds}
            trustId={selectedTrustOption?.value}
            excludeL1Ids={excludeL1Ids}
          />
        )}
        {tabValue === TabValues["Vaccinators' League Table"] && (
          <VaccinatorsLeagueTableTab excludeL1Ids={excludeL1Ids} />
        )}
        {tabValue === TabValues["Trusts' League Table"] && (
          <TrustsLeagueTableTab
            excludeL1Ids={excludeL1Ids}
            pathogenIds={selectedPathogenIds as PathogenSelectOptions}
          />
        )}
        {tabValue === TabValues["ImmForm"] && (
          <ImmFormTab
            pathogenIds={selectedPathogenIds}
            trustId={selectedTrustOption?.value}
            excludeL1Ids={excludeL1Ids}
          />
        )}
        {tabValue === TabValues["Compliance"] && trustInvolvesCovid() && (
          <ComplianceTab trustId={selectedTrustOption?.value} />
        )}
        {/* Coming soon to a vacctrack near you! */}
        {/* {tabValue === TabValues["FluCompliance"] && (
          <ComplianceTab pathogen={Pathogens.Flu} />
        )} */}
        {tabValue === TabValues["Staff List"] &&
          userHasReportingStaffViewerRole() && (
            <StaffListTab trustId={selectedTrustOption?.value} />
          )}
      </div>
    </div>
  );
}
