import { IconButton, Switch, Tooltip } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Email as EmailIcon,
} from "@material-ui/icons";
import { ThemeProvider } from "@material-ui/styles";
import MUIDataTable, { MUIDataTableMeta, SelectableRows } from "mui-datatables";
import React from "react";
import { useHistory } from "react-router";
import { getLabel, Pathogens } from "../../../../lib/pathogens";
import { Trust } from "../Trust/useTrustList";

const palette = {
  primary: {
    main: "#283583",
    contrastText: "#fff",
  },
  secondary: {
    main: "#009fe3",
    contrastText: "#fff",
  },
  error: {
    main: "#be584e",
    contrastText: "#fff",
  },
  action: {
    selected: "#d4dcf1",
  },
};

const theme = createTheme({
  palette,
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: "none",
      },
    },
    MuiTable: {
      root: {
        borderSpacing: "0 10px",
        borderCollapse: "separate",
      },
    },
    MuiTableCell: {
      root: {
        border: "none",
        borderBottom: "none",
        padding: "0.1em .3em",
      },
      head: {
        fontWeight: "bold",
        fontSize: "0.9rem",
      },
    },
    MuiPopover: {
      paper: {
        backgroundColor: "#fff",
      },
    },
  },
});

const options = {
  elevation: 0,
  rowsPerPage: 25,
  rowsPerPageOptions: [10, 25, 50, 100],
  sort: false,
  search: true,
  searchOpen: true,
  filter: false,
  print: false,
  download: false,
  viewColumns: false,
  selectableRowsHeader: false,
  selectableRows: "none" as SelectableRows,
};

type TableProps = {
  data: Trust[];
  updateTrustShowInLeagueTable: (trust: any[], value: boolean) => void;
  deleteTrust: (id: number) => void;
  resendInviteEmails: (id: number) => void;
};

function pathogenIncludes(pathogens: Pathogens[]) {
  return {
    covid: pathogens.includes(1),
    flu: pathogens.includes(2),
    combined: pathogens.length === 2,
  };
}

export default function TrustsTable({
  data,
  updateTrustShowInLeagueTable,
  deleteTrust,
  resendInviteEmails,
}: TableProps): JSX.Element {
  const history = useHistory();
  const columns = [
    {
      name: "id",
      options: {
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "trustName",
      label: "Trust Name",
    },
    {
      name: "senderEmailAddress",
      label: "Sender Email Address",
      options: {
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "logoUrl",
      label: "Logo",
      options: {
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "pathogens",
      label: "Pathogens",
      options: {
        customBodyRender: (pathogens: Pathogens[]) =>
          getLabel(pathogens) ?? "Not Set",
      },
    },
    {
      name: "bookingData",
      label: "Bookings",
      options: {
        customBodyRender: (bookingData: {
          pathogens: Pathogens[];
          fluOnly: number;
          covidOnly: number;
          combined: number;
        }) => {
          return (
            (bookingData && (
              <>
                {pathogenIncludes(bookingData.pathogens).covid && (
                  <span
                    style={{
                      backgroundColor: "#169bd8",
                      color: "white",
                      margin: "2px",
                      padding: "1px 3px",
                      borderRadius: "2px",
                    }}
                  >
                    {bookingData.fluOnly}
                  </span>
                )}
                {pathogenIncludes(bookingData.pathogens).flu && (
                  <span
                    style={{
                      backgroundColor: "#c80089",
                      color: "white",
                      margin: "2px",
                      padding: "1px 3px",
                      borderRadius: "2px",
                    }}
                  >
                    {bookingData.covidOnly}
                  </span>
                )}
                {pathogenIncludes(bookingData.pathogens).combined && (
                  <span
                    style={{
                      backgroundColor: "#35a444",
                      color: "white",
                      margin: "2px",
                      padding: "1px 3px",
                      borderRadius: "2px",
                    }}
                  >
                    {bookingData.combined}
                  </span>
                )}
              </>
            )) || <></>
          );
        },
      },
    },
    {
      name: "showInLeagueTable",
      label: "Show in League Table",
      options: {
        customBodyRender: (v: boolean, d: MUIDataTableMeta) => {
          return (
            <Switch
              checked={v}
              onChange={() => updateTrustShowInLeagueTable(d.rowData, !v)}
              value="active"
              color="primary"
              inputProps={{ "aria-label": "Show Trust in League Table" }}
            />
          );
        },
      },
    },
    {
      name: "",
      options: {
        display: true,
        viewColumns: false,
        setCellProps: () => ({
          style: { display: "flex", justifyContent: "flex-end" },
        }),
        customBodyRender: (v: any, d: any) => (
          <>
            <Tooltip title="Resend Invite Emails">
              <IconButton onClick={() => resendInviteEmails(d.rowData[0])}>
                <EmailIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Edit">
              <IconButton
                color="primary"
                onClick={() => {
                  history.push(`/trusts/${d.rowData[0]}`);
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                color="primary"
                onClick={() => {
                  deleteTrust(d.rowData[0]);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        ),
      },
    },
  ];
  return (
    <ThemeProvider theme={theme}>
      <MUIDataTable title="" data={data} columns={columns} options={options} />
    </ThemeProvider>
  );
}
