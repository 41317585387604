import cloneDeep from "lodash/cloneDeep";
// Remove 'helpful' reverse mapping if "One-off" = 1 (number) style
export const filterHeterogenous = (e: any): any => {
  // Have to clone it twice! Never seen this issue before but
  // occurs when we import and use in pathogens.ts
  const filtering = cloneDeep(cloneDeep(e));
  for (const key in filtering) {
    if (!isNaN(key as any)) {
      delete filtering[key];
    }
  }
  return filtering;
};
export type enumInArray = {
  id: number;
  name: string;
};
export const enumToArray = (e: any): enumInArray[] => {
  return Object.keys(filterHeterogenous(e)).map(
    (key) =>
      ({
        id: e[key],
        name: key.toString(),
      } as enumInArray)
  );
};
export const enumKeyByValue = (e: any, value: any) =>
  Object.keys(e)[Object.values(e).indexOf(value)];
