import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  LinearProgress,
  Theme,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import DashboardApi, { ClinicCapacitySummary } from "./DashboardApi";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  flex1: {
    // flex: 1
  },
  flex0: {
    // flex: 0
  },
  desktop: {
    width: 120,
  },
  subtitle1: {
    fontWeight: 800,
    fontSize: theme.spacing(2),
  },
  activeStaffCount: {
    fontWeight: 800,
  },
  totalStaffCount: {
    fontSize: theme.spacing(1.5),
    color: theme.palette.grey[400],
  },
  data: {
    borderRight: "1px solid #020202",
    display: "flex",
    flexDirection: "column",
    "&:last-child": {
      borderRight: "none",
    },
  },
  dataWrapper: {
    alignItems: "center",
  },
  p: {
    textAlign: "center",
  },
  countColorGreen: {
    color: theme.palette.success.main,
    fontWeight: 400,
  },
  countColorSecondary: {
    color: theme.palette.secondary.main,
    fontWeight: 800,
  },
  totalVaccinationCount: {
    marginTop: theme.spacing(2),
  },
  totalCountText: {
    fontSize: "15px",
  },
  loadingProgress: {
    padding: 0,
    width: "100%",
    marginTop: 7,
    height: 0,
  },
}));

export function ClinicCapacity(props: any) {
  const api = DashboardApi;
  const [clinicData, setClinicData] = useState({} as ClinicCapacitySummary);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setClinicData({
      availableSlots: 0,
      bookedSlots: 0,
      futureAvailableSlots: 0,
      futureBookedSlots: 0,
      futureTotalSlots: 0,
      totalSlots: 0,
    } as ClinicCapacitySummary);
    setLoading(true);
    api.clinicCapacity(props.pathogenIds, props.trustId).then((res) => {
      setClinicData(res);
      setLoading(false);
    });
  }, [api, props]);
  return (
    <Grid
      xs={12}
      item
      container
      justifyContent="space-between"
      spacing={0}
      className={classes.flex1}
    >
      <Grid xs={12} item container className={classes.dataWrapper}>
        <Grid className={classes.data} item xs={4}>
          <Typography className={classes.p}>
            Total slots (Past &amp; Future)
          </Typography>
          <Typography className={clsx(classes.countColorGreen, classes.p)}>
            {clinicData.totalSlots}
          </Typography>
        </Grid>
        <Grid className={classes.data} item xs={4}>
          <Typography className={classes.p}>
            Slots booked (Past &amp; Future)
          </Typography>
          <Typography className={clsx(classes.countColorGreen, classes.p)}>
            {clinicData.bookedSlots}
          </Typography>
        </Grid>
        <Grid className={classes.data} item xs={4}>
          <Typography className={classes.p}>
            Slots available (Past &amp; Future)
          </Typography>
          <Typography className={clsx(classes.countColorGreen, classes.p)}>
            {clinicData.availableSlots}
          </Typography>
        </Grid>
      </Grid>
      <Grid xs={12} item container className={classes.dataWrapper}>
        <Grid className={classes.data} item xs={4}>
          <Typography className={classes.p}>Total slots (Future)</Typography>
          <Typography
            className={clsx(classes.p, "theme-color")}
            data-theme-id={props.pathogenIds.replace("|", ",")}
          >
            {clinicData.futureTotalSlots}
          </Typography>
        </Grid>
        <Grid className={classes.data} item xs={4}>
          <Typography className={classes.p}>Slots booked (Future)</Typography>
          <Typography
            className={clsx(classes.p, "theme-color")}
            data-theme-id={props.pathogenIds.replace("|", ",")}
          >
            {clinicData.futureBookedSlots}
          </Typography>
        </Grid>
        <Grid className={classes.data} item xs={4}>
          <Typography className={classes.p}>
            Slots available (Future)
          </Typography>
          <Typography
            className={clsx(classes.p, "theme-color")}
            data-theme-id={props.pathogenIds.replace("|", ",")}
          >
            {clinicData.futureAvailableSlots}
          </Typography>
        </Grid>
      </Grid>

      {loading && (
        <Grid className={classes.loadingProgress}>
          <LinearProgress color="secondary" aria-busy={loading} />
        </Grid>
      )}
    </Grid>
  );
}
