import React from "react";
import { useHistory } from "react-router-dom";
/**
 * @param path URL to redirect to
 * @param delay Number of seconds to wait until redirecting
 */
export default function useDelayedRedirect(path: string, delay: number) {
  const history = useHistory();
  const id = React.useRef<NodeJS.Timeout | null>(null);
  return React.useCallback(() => {
    if (id.current) {
      clearTimeout(id.current);
    }
    id.current = setTimeout(() => {
      history.push(path);
    }, delay * 1000);
  }, [path, delay, history]);
}
