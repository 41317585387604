import DataClient from "../../../../../lib/services/api/DataClient";
import { catchFormErrors } from "../../../../../lib/shared/api_client";
import { Clinic } from "./clinic";

const from = (item: Clinic) => ({
  ...item,
  locationName: item.name,
  hospitalSiteId: item.hospitalId,
});

const to = (item: any) =>
  ({
    ...item,
    name: item.locationName,
    hospitalId: item.hospitalSiteId,
  } as Clinic);

const api = {
  list: (hospitalSiteId: number) =>
    DataClient.postData("/ClinicLocation/Search", {
      pageSize: -1,
      hospitalSiteId,
      includeArchived: true,
    }).then((data: any) => data?.results?.pagedResults?.map(to)),
  // BB: No corresponding endpoint
  // get: (id: number) =>
  //   DataClient.getData(`/ClinicLocation/${id}`)
  //     .then((res) => res.results)
  //     .then(to),
  add: (item: Clinic) => {
    return DataClient.postData("/ClinicLocation", from(item)).catch(
      catchFormErrors
    );
  },
  update: (item: Clinic) =>
    DataClient.update(`/ClinicLocation/${item.id}`, from(item)),
  delete: (id: number) =>
    DataClient.remove({
      url: `/ClinicLocation/${id}/HardDelete`,
    }),
  archive: (id: number) =>
    DataClient.remove({
      url: `/ClinicLocation/${id}`,
    }),
  unarchive: (id: number) =>
    DataClient.update(`/ClinicLocation/${id}/Restore`, {}),
};

export default api;
