import React from "react";
import styles from "./appointments.module.scss";
import { Pathogens, pathogensFromArray } from "../../../../../lib/pathogens";
import ConfirmDialog from "../../../ConfirmDialog";
import DataClient from "../../../../../lib/services/api/DataClient";
import { Delete as DeleteIcon, Event as EventIcon } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ToastMessage from "../../../../../components/Universal/toast_message";
import {
  AppointmentDetails,
  CampaignPathogenStatus,
} from "../../BookAppointment/useAppointments";
import StyledButton from "./StyledButton";
import { MyAppointment } from "../MyAppointments";
import CombinedTabs from "./CombinedTabs";
import { enumVaccinationStatus } from "../../../../../lib/enums";
import { AppointmentFields } from "./AppointmentFields";
const colourMap = {
  [JSON.stringify([Pathogens.Flu])]: "flu",
  [JSON.stringify([Pathogens.Covid])]: "covid",
  [JSON.stringify([Pathogens.Flu, Pathogens.Covid])]: "combined",
};
export default function Booking({
  appointment,
  campaignPathogenStatuses,
  reload,
}: {
  appointment: MyAppointment;
  campaignPathogenStatuses: CampaignPathogenStatus[]; // same for all instances
  reload: (...args: any[]) => void;
}) {
  const history = useHistory();
  const { pathogens, details, canCancel, canReschedule, isCombined } =
    appointment;
  const [showCancelDialog, setShowCancelDialog] = React.useState(false);
  const [showRescheduleDialog, setShowRescheduleDialog] = React.useState(false);
  const [currentCombinedTabPathogen, setCurrentCombinedTabPathogen] =
    React.useState<number | null>(null);
  const [action, setAction] = React.useState<Action>(null);
  const vaccinationStatusString = getStatus(pathogens, details);
  const buttonColour = colourMap[JSON.stringify(pathogens)];
  const cancelAppointmentText = ({
    // appointment,
    campaignPathogenStatuses,
    currentCombinedTabPathogen,
  }: {
    // appointment: MyAppointment;
    campaignPathogenStatuses: CampaignPathogenStatus[];
    currentCombinedTabPathogen: number;
  }) => {
    const statusForThisPathogen = campaignPathogenStatuses?.find(
      (s) => s.pathogenId === currentCombinedTabPathogen
    )?.vaccinationStatusId;
    const statusForTotherPathogen = campaignPathogenStatuses?.find(
      (s) => s.pathogenId !== currentCombinedTabPathogen
    )?.vaccinationStatusId;
    if (
      // ┌(ಠ_ಠ)┘ P2 2.3
      // "If status is Vaccinated for the other pathogen," TK: "..for one pathogen"
      // "and status is Booked, ABNV or DNA for that pathogen," TK: "..for the other pathogen"
      // TK: "Cancel & Rebook [the unvaccinated one]"
      (statusForThisPathogen === enumVaccinationStatus.Vaccinated &&
        [
          enumVaccinationStatus.Booked,
          enumVaccinationStatus["Attended But Not Vaccinated"],
          enumVaccinationStatus.DNA,
        ].includes(statusForTotherPathogen)) ||
      (statusForTotherPathogen === enumVaccinationStatus.Vaccinated &&
        [
          enumVaccinationStatus.Booked,
          enumVaccinationStatus["Attended But Not Vaccinated"],
          enumVaccinationStatus.DNA,
        ].includes(statusForThisPathogen))
    ) {
      return `Cancel & Rebook ${
        campaignPathogenStatuses.find(
          // Current focussed tab pathogen
          // (s) => s.pathogenId === currentCombinedTabPathogen
          // Unvaccinated pathogen
          (s) => s.vaccinationStatusId !== enumVaccinationStatus.Vaccinated
        )?.pathogen?.name
      } Appointment`;
    } else {
      // "Had Elsewhere or Declined should retain Cancel Appointment text"
      return "Cancel Appointment";
    }
  };
  return (
    <>
      {isCombined ? (
        <CombinedTabs
          {...{
            appointment,
            campaignPathogenStatuses,
            setCurrentCombinedTabPathogen,
          }}
        />
      ) : (
        <>
          {vaccinationStatusString && (
            <div className={styles.status} data-theme-id={pathogens}>
              <h3>{vaccinationStatusString}</h3>
            </div>
          )}
          <AppointmentFields
            appointment={{
              ...appointment,
              ...{
                // details: appointment.status,
              },
            }}
          />
        </>
      )}
      <div className={styles.actions}>
        <div className={styles.book}>
          {canReschedule && (
            <StyledButton
              onClick={() => {
                setShowRescheduleDialog(true);
              }}
              type="submit"
              color={buttonColour}
              autoFocus
              startIcon={<EventIcon />}
            >
              Reschedule
            </StyledButton>
          )}
          {canCancel && (
            <Button
              onClick={() => {
                setShowCancelDialog(true);
              }}
              variant="contained"
              startIcon={<DeleteIcon />}
            >
              {cancelAppointmentText({
                // appointment,
                campaignPathogenStatuses,
                currentCombinedTabPathogen,
              })}
            </Button>
          )}
        </div>
      </div>
      {showCancelDialog && (
        <ConfirmCancel
          onConfirm={() => {
            setShowCancelDialog(false);
            removeReservation(
              Number(details.id),
              () => {
                setAction({ type: "success", message: "Booking cancelled!" });
                reload();
              },
              () => {
                setAction({
                  type: "error",
                  message: "Failed to cancel booking.",
                });
              }
            );
          }}
          onClose={() => {
            setShowCancelDialog(false);
          }}
        />
      )}
      {showRescheduleDialog && (
        <ConfirmReschedule
          onConfirm={() => {
            setShowRescheduleDialog(false);
            history.push(
              `/book-appointment/${encodeURIComponent(
                JSON.stringify(pathogensFromArray(pathogens))
              )}`,
              {
                reschedule: Number(appointment.details.id),
              }
            );
          }}
          onClose={() => {
            setShowRescheduleDialog(false);
          }}
        />
      )}
      {action && (
        <ToastMessage
          variant={action.type}
          message={action.message}
          clear={() => {
            setAction(null);
          }}
        />
      )}
    </>
  );
}
const statusSwitch: Record<string, (a: AppointmentDetails) => string> = {
  [JSON.stringify([Pathogens.Flu])]: (appointment: AppointmentDetails) =>
    `${appointment.vaccinationStatusFlu}`,
  [JSON.stringify([Pathogens.Covid])]: (appointment: AppointmentDetails) =>
    `${appointment.vaccinationStatusCovid}`,
  [JSON.stringify([Pathogens.Flu, Pathogens.Covid])]: (
    appointment: AppointmentDetails
  ) =>
    `Flu: ${appointment.vaccinationStatusFlu}, Covid: ${appointment.vaccinationStatusCovid}`,
};
const getStatus = (pathogens: Pathogens[], appointment: AppointmentDetails) => {
  const getPathogenStatus = statusSwitch[JSON.stringify(pathogens)];
  return getPathogenStatus ? getPathogenStatus(appointment) : "N/A";
};
const removeReservation = (
  id: number,
  success: (...args: any[]) => void,
  failure: (...args: any[]) => void
) => {
  DataClient.remove({
    url: `/Reservation/${id}`,
  })
    .then((res) => {
      if (res.success) {
        success();
      } else {
        failure();
      }
    })
    .catch(() => {
      failure();
    });
};
type Action = {
  type: string;
  message: string;
};
type DialogProps = {
  onConfirm: (...args: any[]) => void;
  onClose: (...args: any[]) => void;
};
const ConfirmCancel = ({ onConfirm, onClose }: DialogProps) => (
  <ConfirmDialog
    open
    title="Cancel Appointment"
    confirmLabel="Yes, Cancel"
    onConfirm={onConfirm}
    onClose={onClose}
  >
    <p>
      Are you sure you want to cancel this appointment? Once cancelled you must
      book another vaccination appointment.
    </p>
  </ConfirmDialog>
);
const ConfirmReschedule = ({ onConfirm, onClose }: DialogProps) => (
  <ConfirmDialog
    open
    title="Reschedule Appointment"
    confirmLabel="Yes, Reschedule"
    onConfirm={onConfirm}
    onClose={onClose}
  >
    <>
      <p>Are you sure you want to reschedule this appointment?</p>
      <p>
        Please note: your current booking will not be cancelled until you book
        the new one.
      </p>
    </>
  </ConfirmDialog>
);
