import React from "react";
import { Pathogens } from "../../../../lib/pathogens";
import DataClient from "../../../../lib/services/api/DataClient";

export type ConsentQuestion = {
  id: number;
  isPreConsent: boolean;
  isRequired: boolean;
  question: string;
  sortOrder: number;
  trustId: number;
  pathogens: Pathogens[];
};

type QuestionErrors = {
  consentError?: string;
  confirmationError?: string;
};

export default function useConsentQuestions(
  trustWorkerId: number,
  pathogens: Pathogens[]
) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [loadingTime, setLoadingTime] = React.useState(0);
  const [confirmationQuestions, setConfirmationQuestions] = React.useState<
    ConsentQuestion[]
  >([]);
  const [consentQuestions, setConsentQuestions] = React.useState<
    ConsentQuestion[]
  >([]);
  const [retry, setRetry] = React.useState(0);
  const [errors, setErrors] = React.useState<QuestionErrors>({});
  React.useEffect(() => {
    if (pathogens) {
      setIsLoading(true);
      DataClient.postData("/TrustConsentQuestion/Search", {
        pageSize: -1,
        pathogens,
      })
        .then(({ success, results }) => {
          setIsLoading(false);
          if (success && results) {
            setConsentQuestions(results.pagedResults);
          } else {
            setErrors((errors) => ({
              ...errors,
              consentError: "Could not load consent questions.",
            }));
          }
        })
        .catch(() => {
          setIsLoading(false);
          setErrors((errors) => ({
            ...errors,
            consentError: "Could not load consent questions.",
          }));
        });
      DataClient.postData("/TrustExclusionQuestion/Search", {
        pageSize: -1,
        pathogens,
      })
        .then(({ success, results }) => {
          setIsLoading(false);
          if (success && results) {
            setConfirmationQuestions(results.pagedResults);
          } else {
            setErrors((errors) => ({
              ...errors,
              confirmationError:
                "Could not load pre-booking confirmation questions.",
            }));
          }
        })
        .catch(() => {
          setIsLoading(false);
          setErrors((errors) => ({
            ...errors,
            confirmationError:
              "Could not load pre-booking confirmation questions.",
          }));
        });
    }
  }, [retry, trustWorkerId, pathogens]);

  React.useEffect(() => {
    let interval: any = null;
    if (isLoading) {
      interval = setInterval(() => {
        setLoadingTime((seconds) => seconds + 1);
      }, 1000);
    } else if (!isLoading && loadingTime !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isLoading, loadingTime]);

  const reload = React.useCallback(() => {
    setIsLoading(true);
    setLoadingTime(0);
    setErrors({});
    setRetry((v) => v + 1);
  }, [setRetry, setErrors]);
  return {
    confirmationQuestions,
    consentQuestions,
    errors,
    reload,
    isLoading,
    loadingTime,
  };
}
