import { cloneDeep, isEmpty, isEqual, xorWith } from "lodash";
import startCase from "lodash/startCase";
import { DateTime } from "luxon";
import moment from "moment";
import { monthStrings } from "./enums";
export const flattenObjectToArray = (object, key) => {
  let values = [];
  for (let i = 0; i < object.length; i++) {
    // if (object[i].hasOwnProperty(key)) {
    if (Object.prototype.hasOwnProperty.call(object[i], key)) {
      values.push(object[i][key]);
    }
  }
  return values;
};
export const getParams = function (url) {
  let params = {};
  let parser = document.createElement("a");
  parser.href = url;
  let query = parser.search.substring(1);
  let lets = query.split("&");
  for (let i = 0; i < lets.length; i++) {
    let pair = lets[i].split("=");
    params[pair[0]] = decodeURIComponent(pair[1]);
  }
  return params;
};
// export const validateEmail = (email) => {
//   var re =
//     /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//   return re.test(String(email).toLowerCase());
// };
// removeEmptyValues
// Carefully remove various types of empty values from object, specifically:
//   null, NaN, undefined, "",
export const removeEmptyValues = (obj) =>
  Object.fromEntries(
    Object.entries(obj).filter(
      ([_, v]) =>
        !Number.isNaN(v) && v !== "" && v !== null && typeof v !== "undefined",
    ),
  );
export const removeZeroFields = ({ data, fieldsThatDontWantZero }) => {
  // Ideally we'd do it all programatically, comparing original fields, but can't figure out exactly how..  so for now:
  fieldsThatDontWantZero.forEach((field) => {
    if (data[field] === 0) {
      delete data[field];
    }
  });
  return data;
};
// Object has no values or those of false, null, "" (for validation checks)
export const isEmptyOfValues = (obj) => Object.values(obj).every((v) => !v);
export const getTrustId = () =>
  JSON.parse(localStorage.getItem("user"))?.trustId;
// Simple render functions for name, email and such to display them consistently and avoid random null and whitespace errors
export const humanName = (firstName = "", lastName = "") =>
  `${firstName ? firstName + " " : ""}${lastName ? lastName : ""}`;
export const humanNameFromObject = (item) =>
  humanName(item?.firstName, item?.lastName);
// John Smith (john@smith.com)
export const humanNameAndEmailFromObject = (item = {}) =>
  humanNameFromObject(item) +
  (item?.emailAddress ? ` (${item?.emailAddress})` : "");
// 09:30:00 -> 9:30

// Date Time Utils;
export const humanDateTime = (
  isoDate,
  removeOffset = true,
  outputFormat = "DD/MM/YYYY HH:mm",
) => {
  const withOrWithoutOffset = removeOffset ? isoDate.split("+")[0] : isoDate;
  return moment(withOrWithoutOffset).format(outputFormat);
};

export const humanTime = (hhmmss) => moment(hhmmss, "HH:mm:ss").format("HH:mm");

// 30/01/2020 handling various formats: moment, luxon, and all the various strings the API outputs >_<
export const humanDate = (dateInput, outputFormat = "DD/MM/YYYY") => {
  // dateInput = "1999-12-25T00:00:00-00:01";
  // dateInput = "1000-01-01T00:00:00-00:01";
  // dateInput = "1999-12-25T00:00:00";
  // dateInput = "25/12/1999";
  if (!dateInput) return null;
  if (dateInput === "01/01/0001") return "N/A"; // API borking (/trustworker)
  if (dateInput === "--01") return "-"; // API borking (#4924 /VaccinationHistory:vaccineExpiryDate set as null returns this)
  // Luxon
  dateInput = DateTime.isDateTime(dateInput) ? dateInput.toISO() : dateInput;
  // Strip time and any offsets from unusual offset format ...T00:00:00 / ...T00:00:00-00:01 (v history)
  dateInput =
    typeof dateInput === "string" && dateInput.includes("T")
      ? dateInput.split("T", 1)[0]
      : dateInput;
  // Trustworker Vaccine date is  another  date format from the backend.
  // This was commented out as the .trim() method was not available on some dates and causing a crash in the clinics page: VT-41
  // The Trustworker Vaccine Date was updated to a better format so this should no longer be necessary anyway.
  // dateInput = dateInput.trim();
  const date = moment(dateInput, "YYYY-MM-DDTHH:mm:ssZ", true).isValid() // v history dates
    ? moment(dateInput, "YYYY-MM-DDTHH:mm:ssZ")
    : moment(dateInput, "YYYY-MM-DDTHH:mm:ss", true).isValid() // v history dates other times
      ? moment(dateInput, "YYYY-MM-DDTHH:mm:ss")
      : moment(dateInput, "DD/MM/YYYY", true).isValid() // 09/05/1999 (/trustworker appointment details)
        ? moment(dateInput, "DD/MM/YYYY")
        : // 21/05/1985 00:00:00 (/Reservation/ShowAllBookings)
          moment(dateInput, "DD/MM/YYYY HH:mm:s", true).isValid()
          ? moment(dateInput, "DD/MM/YYYY HH:mm:s")
          : // Trustworker Vaccine date is yet another date format from the backend.
            moment(dateInput, "DD/MM/YYYY HH:mm:ss +HH:mm", true).isValid()
            ? moment(dateInput, "DD/MM/YYYY HH:mm:ss +HH:mm")
            : // 1985-05-21T00:00:00 (generally)
              moment(dateInput).isValid()
              ? moment(dateInput)
              : new Date(date);
  const ddmmyyyy = moment(date).format(outputFormat);
  return ddmmyyyy;
};
// February 2023
export const humanDateMMMMYYYY = (month, year) =>
  `${monthStrings[month - 1] || `-`} ${year || `-`}`;
// Feb 2023
export const humanDateMMMYYYY = (month, year) => {
  if (!month || !year) return "";
  return `${monthStrings[month - 1]?.substring(0, 3) || `-`} ${year || `-`}`;
};
export const lexicalMonth = (nonZeroIndexedMonth) =>
  monthStrings[nonZeroIndexedMonth - 1];
export const isoDate = (date) => date.toISOString().split("T")[0];
export const isValidDate = (d) => d && moment(d).isValid();
export const datePlusTime = (date, time) =>
  moment(moment(date).format("YYYY-MM-DD") + " " + time);
export const currentDate = () => moment().format("YYYY-MM-DD");
export const currentTime = () => moment().format("HH:mm");
export const isTodaysDate = (date) =>
  moment(date).startOf("day").isSame(moment().startOf("day"));
export const isEarlierInTheDay = (time1, time2) =>
  moment(time1, "HH:mm").isBefore(moment(time2, "HH:mm"));

export const isBeforeToday = (date) => moment(date).isBefore(moment(), "day");

export const toZeroOffsetMidnightIsoString = (date) => {
  if (!date) return null;
  // check if the date is a moment or a string in the correct format (YYYY-MM-DD)
  if (!moment(date, "YYYY-MM-DD", true).isValid() && !moment.isMoment(date)) {
    throw new Error(
      `Requires date format YYYY-MM-DD or moment object: Invalid date: ${date}`,
    );
  }
  const dateOnly = moment.isMoment(date)
    ? date.clone().format("YYYY-MM-DD")
    : moment(date).clone().format("YYYY-MM-DD");

  return moment
    .utc(dateOnly)
    .set("hour", 0)
    .set("minute", 0)
    .set("second", 0)
    .set("millisecond", 0);
};

// Text Manipulation Utils
/**
 * Splits a Pascal-Case word into individual words separated by spaces.
 * @param {Object} word
 * @returns {String}
 */
export function pascalCaseToSentence(word) {
  var wordRe = /($[a-z])|[A-Z][^A-Z]+/g;
  return word?.match(wordRe)?.join(" ") || word;
}

// Usage:
// new cellFunctions("foo bar").dna().capitalize().value,
export class cellFunctions {
  constructor(v) {
    this.value = v || "";
  }
  // "foo bar" -> "Foo Bar"
  capitalize() {
    this.value = startCase(this.value);
    return this;
  }
  // "" -> "N/A"
  na(str) {
    this.value = this.value.trim() ? this.value : str ? str : "N/A";
    return this;
  }
  // "dna" -> "DNA"
  dna() {
    this.value =
      this.value.toLowerCase() === "dna"
        ? this.value.toUpperCase()
        : this.value;
    return this;
  }
  yesNo() {
    this.value = this.value && this.value !== "False" ? "Yes" : "No";
    return this;
  }
  removeLeadingNumbers() {
    this.value = this.value.replace(/^\d+ /, "");
    return this;
  }
  humanDate() {
    this.value = humanDate(this.value);
    return this;
  }
  // Might need class and html on these, like..
  // addClass(classes) {
  // this.classes ..
  // }
  // html() {
  //   return <span className="{this.classes}">{this._value}</span>;
  // }
}
// " ... <pre>foo</pre> ... " -> "foo"
export const parseServerError = (e) => {
  console.error("Server Error", e);
  if (e.title) return e.title;
  if (e.message) return e.message;
  if (typeof e === "object") return JSON.stringify(e); // hope for the best
  const pre = e.match(/<pre>(.+?)<\/pre>/);
  return pre && pre[1] ? pre[1] : e;
};
// https://stackoverflow.com/a/29234240/3098773 - ish
export const joinIsh = ({
  arr,
  separator = ", ",
  separatorLast = separator,
}) => {
  if (!arr || !arr.length || !Array.isArray(arr)) return "";
  if (arr.length === 1) return arr[0];
  return arr.slice(0, -1).join(separator) + separatorLast + arr.slice(-1);
};
export const isLocalhostOrQa = () =>
  ["localhost", "app-flutrack-application-qa.azurewebsites.net"].includes(
    window.location.hostname,
  );
export const isArrayEqual = (x, y) =>
  // https://stackoverflow.com/a/37066038/3098773
  isEmpty(xorWith(cloneDeep(x), cloneDeep(y), isEqual));
export const whatsDifferent = (x, y) =>
  xorWith(cloneDeep(x), cloneDeep(y), isEqual);
const clinicEditCutoff = moment("2022-08-16");
// Clinic is editable if either:
// - Today's date is before our cutoff, or
// - Clinic's date is before the cutoff
// i.e. When it comes round to August 31st at midnight, clinics before that point will no longer be editable.
// NB: This does not include recurring clinics that span the cutoff but that shouldn't be an issue:
// https://techdept-team.slack.com/archives/C01UKV2AK5M/p1647352930960819?thread_ts=1647350871.802029&cid=C01UKV2AK5M
// Update: Changed to 2022-08-16
// mhttps://techdept-team.slack.com/archives/C0275F7ST4Z/p1661897956691349
export const clinicIsEditable = (date) =>
  moment() <= clinicEditCutoff || moment(date) > clinicEditCutoff;
export const lowerCaseFirstLetter = (str) => {
  return str.charAt(0).toLowerCase() + str.slice(1);
};
export const filterObjectKeys = (obj, allowed) =>
  allowed.reduce((o, k) => ({ ...o, [k]: obj?.[k] }), {});
