import React from "react";
import ToastMessage from "../../../../../components/Universal/toast_message";
import ConfirmDialog from "../../../ConfirmDialog";
import TrustAdminForm from "../../../SuperAdmin/TrustAdmin/TrustAdminForm";
import SettingsContainer from "../SettingsContainer";

export default function TrustAdminCreate() {
  const [feedback, setFeedback] =
    React.useState<{ type: "success" | "error"; message: string }>(null);
  const [warningDialog, setWarningDialog] = React.useState<boolean | string[]>(
    false
  );
  const [proceedIfWarnings, setProceedIfWarnings] = React.useState(false);
  return (
    <SettingsContainer>
      <TrustAdminForm
        setFeedback={setFeedback}
        setWarningDialog={setWarningDialog}
        isUpdate={false}
        id={null}
        formData={null}
        isChoosingTrust={false}
        returnPath="/settings/trust-admins"
        proceedIfWarnings={proceedIfWarnings}
      />
      {feedback && (
        <ToastMessage
          variant={feedback.type}
          message={feedback.message}
          clear={() => {
            setFeedback(null);
          }}
        />
      )}
      <ConfirmDialog
        open={Array.isArray(warningDialog)}
        title="Please confirm"
        confirmLabel="Confirm"
        onConfirm={() => {
          setWarningDialog(false);
          setProceedIfWarnings(true);
        }}
        onClose={() => {
          setWarningDialog(false);
        }}
      >
        <span>
          {Array.isArray(warningDialog)
            ? warningDialog.join(" ")
            : "A similar Trust Worker already exists. Please confirm and then resubmit to update them as a Trust Admin."}
        </span>
      </ConfirmDialog>
    </SettingsContainer>
  );
}
