import {
  ClickAwayListener,
  IconButton,
  Tooltip,
  withStyles,
  Zoom,
} from "@material-ui/core";
import { Help } from "@material-ui/icons";
import { useState } from "react";
import { palette } from "../../lib/themes/vars";
import { HelpCopy } from "./Help";
import "./HelpTooltip.scss";
// Client style mockup in: https://2-app.donedone.com/6110/project/17407/task/682443
const StyledTooltip = withStyles({
  tooltip: {
    backgroundColor: palette.help.main,
    color: palette.help.contrastText,
    // Unhacked, they now want left align again
    textAlign: "left",
    margin: "4px",
    minWidth: "20em",
    // Having overflow problems in table headers? Add .table-hack-th-tooltips
    // zIndex: 9999, // maybe don't go crazy, think about modals
    fontSize: ".8rem",
  },
})(Tooltip);
export default ({ helpCopy }: { helpCopy: HelpCopy }): JSX.Element => {
  const [open, setOpen] = useState(false);
  if (!helpCopy) return null;
  return (
    // This would be a lot less complicated with a typical hover trigger, but yeah
    // https://2-app.donedone.com/6110/project/17407/task/682443
    // https://mui.com/material-ui/react-tooltip/#triggers
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <StyledTooltip
        // placement={helpCopy.placement || "top"}
        PopperProps={{
          disablePortal: true,
          placement: helpCopy.placement || "top",
          modifiers: {
            // placement="top" issue https://stackoverflow.com/a/69121527/3098773
            // (apparently fixed in more recent material-ui but stuck on v4 rn)
            preventOverflow: {
              enabled: false,
            },
          },
        }}
        onClose={() => setOpen(false)}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        TransitionComponent={Zoom}
        title={
          <>
            {helpCopy.title && (
              <strong style={{ display: "block" }}>{helpCopy.title}</strong>
            )}
            {helpCopy.content}
          </>
        }
      >
        <IconButton onClick={() => setOpen(true)}>
          <Help />
        </IconButton>
      </StyledTooltip>
    </ClickAwayListener>
  );
};
