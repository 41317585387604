import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid/Grid";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import styles from "../Form.module.scss";
import useCreateTrust from "./useCreateTrust";
import useTrust from "./useTrust";
import useUpdateTrust from "./useUpdateTrust";
import useUploadLogo from "./useUploadLogo";
import {
  SelectPathogenList,
  formatForServer,
  formatForOptionValue,
} from "../../../../lib/pathogens";
import { getTrustGroups } from "../TrustGroup/TrustGroups";
import ApiAlert from "../../../../components/ApiAlert";
import { LinearProgress } from "@material-ui/core";

const emailDomainPart = "VaccinationTrack.com";

function removeEmailDomainPart(email: string) {
  return email.substring(0, email.indexOf(`@${emailDomainPart}`));
}

function addEmailDomainPart(email: string) {
  return `${email}@${emailDomainPart}`;
}

type CreateUpdateProps = {
  id: number | null;
  setFeedback: (feedback: {
    type: "success" | "error";
    message: string;
  }) => void;
};

export default function TrustCreateUpdate({
  id,
  setFeedback,
}: CreateUpdateProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();
  const isUpdate = id !== null;
  const [alert, setAlert] = useState(null);
  const [trustGroups, setTrustGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const {
    reset,
    handleSubmit,
    register,
    formState: { errors: formErrors },
  } = useForm();
  useEffect(() => {
    getTrustGroups()
      .then((res) => {
        setTrustGroups(res?.results?.pagedResults || null);
      })
      .catch((e) => {
        console.error(e);
        setAlert(ApiAlert({ res: e.response }));
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [trust, _, trustErrors] = useTrust(id);
  useEffect(() => {
    if (isUpdate && trust) {
      const formFormat = {
        ...trust,
        senderEmailAddress: trust.senderEmailAddress
          ? removeEmailDomainPart(trust.senderEmailAddress)
          : null,
        pathogens: formatForOptionValue(trust.pathogens),
      };
      reset(formFormat);
    }
  }, [isUpdate, trust, reset]);
  useEffect(() => {
    if (trustErrors) {
      setFeedback({
        type: "error",
        message: "Could not load the Trust. Please retry.",
      });
    }
  }, [trustErrors, setFeedback]);
  const [createTrust, createdSuccess, createdError] = useCreateTrust();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updateTrust, updateError, __, updateSuccess] = useUpdateTrust();

  const [uploadLogo, uploadingLogo, logoUrl, uploadError] = useUploadLogo();

  useEffect(() => {
    if (createdSuccess) {
      setFeedback({ type: "success", message: "Trust created successfully." });
      history.push("/trusts", {
        trust: createdSuccess,
      });
    }
  }, [createdSuccess, setFeedback, history]);

  useEffect(() => {
    if (createdError) {
      setFeedback({ type: "error", message: "Failed to create Trust." });
    }
  }, [createdError, setFeedback]);

  useEffect(() => {
    if (uploadError) {
      setFeedback({ type: "error", message: "Failed to upload logo." });
    }
  }, [uploadError, setFeedback]);

  useEffect(() => {
    if (updateSuccess) {
      setFeedback({ type: "success", message: "Trust updated successfully." });
      history.push("/trusts", {
        update: updateSuccess,
      });
    }
  }, [updateSuccess, setFeedback, history]);

  useEffect(() => {
    if (updateError.length) {
      setFeedback({ type: "error", message: "Failed to update Trust." });
    }
  }, [updateError, setFeedback]);

  const onSubmit = (data: any) => {
    const senderEmailAddress = data.senderEmailAddress
      ? addEmailDomainPart(data.senderEmailAddress)
      : null;

    if (isUpdate) {
      updateTrust(id, {
        ...trust,
        trustName: data.trustName,
        trustGroupId: data.trustGroupId ? data.trustGroupId : null,
        pathogens: formatForServer(data.pathogens),
        senderEmailAddress,
        logoUrl: logoUrl.length > 0 ? logoUrl : trust.logoUrl,
      });
    } else {
      createTrust({
        trustName: data.trustName,
        trustGroupId: data.trustGroupId ? data.trustGroupId : null,
        senderEmailAddress,
        logoUrl,
        showInLeagueTable: true,
        pathogens: formatForServer(data.pathogens),
      });
    }
  };

  const isUpdatedLogo = logoUrl?.length > 0;
  const isPreviousLogo = trust?.logoUrl?.length > 0;
  const currentLogoUrl = isUpdatedLogo
    ? logoUrl
    : isPreviousLogo
      ? trust.logoUrl
      : null;
  return (
    <Dialog
      fullScreen={fullScreen}
      open={true}
      onClose={() => {
        history.push("/trusts");
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {isUpdate ? <h2>Edit Trust</h2> : <h2>Create Trust</h2>}
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <LinearProgress />
        ) : (
          <form
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
            style={{ marginBottom: "10px" }}
            className="form"
          >
            {alert}
            <div className={styles.email}>
              <label className={styles.textInput}>
                Name
                <input
                  {...register("trustName", {
                    required: "You must enter a name",
                  })}
                />
                {formErrors.trustName && (
                  <span role="alert">{formErrors.trustName.message}</span>
                )}
              </label>
              <label className={styles.textInput}>
                System
                <select {...register("trustGroupId")}>
                  <option value="">Please Select</option>
                  {trustGroups?.map(
                    ({ id, name }: { id: string; name: string }) => (
                      <option value={parseInt(id)}>{name}</option>
                    )
                  )}
                </select>
                {formErrors.trustGroupId && (
                  <span role="alert">{formErrors.trustGroupId.message}</span>
                )}
              </label>
              <label className={styles.textInput}>
                Clinic Type
                <select
                  {...register("pathogens", {
                    required: "You must select clinic type",
                  })}
                >
                  {SelectPathogenList.map((pathogen) => (
                    <option value={formatForOptionValue(pathogen.value)}>
                      {pathogen.label}
                    </option>
                  ))}
                </select>
                {formErrors.pathogens && (
                  <span role="alert">{formErrors.pathogens.message}</span>
                )}
              </label>
              <label className={styles.textInput}>
                Sender Email
                <div className={styles.inputGroup}>
                  <input
                    {...register("senderEmailAddress", {
                      validate: {
                        email: (value: string) => {
                          if (value.includes("@")) {
                            return "Please enter an email address without a domain.";
                          }
                        },
                      },
                    })}
                  />
                  <span>@{emailDomainPart}</span>
                </div>
                {formErrors.senderEmailAddress && (
                  <span role="alert">
                    {formErrors.senderEmailAddress.message}
                  </span>
                )}
              </label>
              <label className={styles.textInput}>
                Logo
                {currentLogoUrl && (
                  <img
                    src={currentLogoUrl}
                    alt={trust ? `Logo for ${trust.trustName}` : "Logo"}
                    style={{
                      width: "400px",
                      aspectRatio: "auto",
                      marginTop: "1rem",
                    }}
                  />
                )}
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    uploadLogo({ file });
                  }}
                />
                {formErrors.logoFile && (
                  <span role="alert">{formErrors.logoFile.message}</span>
                )}
              </label>
            </div>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={3}
            >
              <Grid item>
                <Button
                  type="button"
                  color="default"
                  onClick={() => {
                    history.push("/trusts");
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  disabled={uploadingLogo}
                  autoFocus
                >
                  {isUpdate ? "Update" : "Create"}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>
    </Dialog>
  );
}
