import { enumVaccinationStatus } from "../../../lib/enums";
import { Pathogens } from "../../../lib/pathogens";
import { getTrustPathogens } from "../../../lib/services/TrustSwitcher";

type TrustWorker = any;
export type TrustWorkerPermissions = {
  isComplete: boolean;
  canRecordDecline: boolean;
  canUndoDecline: boolean;
  canRecordExempt: boolean;
  canUndoExempt: boolean;
  canBook: boolean;
  cantBookReasons: string[];
  canCancel: boolean;
  canRecordVaccination: boolean;
  canEditVaccination: boolean;
  canDropIn: boolean;
  hasFutureBooking: boolean;
  lockedByOtherTrust: boolean;
};

const getPathogenPermissions = (
  item: TrustWorker,
  pathogen: number
): TrustWorkerPermissions => {
  const status =
    item.campaignPathogenStatuses.find((s: any) => s.pathogenId === pathogen) ??
    {};

  const trustPathogens = getTrustPathogens()?.map((p) => p.id) ?? [];
  const suffix = Pathogens[pathogen];

  const vaccinationStatusId =
    status.vaccinationStatusId as enumVaccinationStatus;

  const isTrustEligible = trustPathogens.includes(pathogen);
  const isEligible = isTrustEligible && item.pathogens.includes(pathogen);
  const hasDeclined = vaccinationStatusId === enumVaccinationStatus.Declined;
  const hadElsewhere =
    vaccinationStatusId === enumVaccinationStatus["Had Elsewhere"];
  const hasDeclinedOrHadElsewhere = hasDeclined || hadElsewhere;
  const isVaccinated = vaccinationStatusId === enumVaccinationStatus.Vaccinated;
  const isExempt = vaccinationStatusId === enumVaccinationStatus.Exempt;

  const hasAppointment = !!item[`reservationId${suffix}`];
  const isComplete = hasDeclinedOrHadElsewhere || isVaccinated || isExempt;
  const hasFutureBooking =
    new Date(item[`clinicDate${suffix}`]) >
    new Date(new Date().setHours(0, 0, 0, 0));

  const managedByOtherTrust = !!status.sourceStatusId;
  const bookedInOtherTrust =
    vaccinationStatusId === enumVaccinationStatus.Booked && managedByOtherTrust;
  const vaccinatedByOtherTrust =
    vaccinationStatusId === enumVaccinationStatus["Had In Another Trust"] &&
    managedByOtherTrust;
  const lockedByOtherTrust = bookedInOtherTrust || vaccinatedByOtherTrust;
  const canRecordDecline =
    isEligible &&
    !hasDeclinedOrHadElsewhere &&
    !isExempt &&
    !isVaccinated &&
    !lockedByOtherTrust;
  const canUndoDecline =
    (hasDeclinedOrHadElsewhere || isExempt) && !lockedByOtherTrust;
  const canRecordExempt =
    canRecordDecline || vaccinationStatusId === enumVaccinationStatus.Declined;
  const canUndoExempt =
    (hasDeclinedOrHadElsewhere || isExempt) && !lockedByOtherTrust;
  // const canBook = isEligible && !isComplete && !hasAppointment && !lockedByOtherTrust,
  // TK on slack: "... This needs to say instead: 'This staff member has previously declined this vaccine.' ... "
  const canBookChecks = [
    // "the vaccination" gets replaced with the pathogen, e.g. "the Flu vaccination"
    {
      value: isEligible,
      reason: "This staff member is not eligible for the vaccination",
    },
    {
      value: !hasDeclined,
      reason: "This staff member has declined the vaccination",
    },
    {
      value: !hadElsewhere,
      reason: "This staff member has already had the vaccination elsewhere",
    },
    {
      value: !isVaccinated,
      reason: "This staff member has already had the vaccination",
    },
    {
      value: !isExempt,
      reason: "This staff member is exempt from the vaccination",
    },
    {
      value: !hasAppointment,
      reason:
        "This staff member already has an appointment for the vaccination",
    },
    {
      value: !bookedInOtherTrust,
      reason:
        "This staff member has been booked in another trust for the vaccination",
    },
    {
      value: !vaccinatedByOtherTrust,
      reason:
        "This staff member has been vaccinated by another trust for the vaccination",
    },
  ];
  const cantBookReasons = canBookChecks
    .filter((c) => !c.value)
    .map((c) => c.reason);
  const canBook = !cantBookReasons.length;
  const pathogenPermissions = {
    isComplete,
    canRecordDecline,
    canUndoDecline,
    canRecordExempt,
    canUndoExempt,
    canBook,
    cantBookReasons,
    canCancel: !isComplete && hasAppointment && !lockedByOtherTrust,
    canRecordVaccination:
      isTrustEligible && !isVaccinated && !isExempt && !lockedByOtherTrust,
    hasFutureBooking,
    canEditVaccination: isVaccinated,
    canDropIn:
      isTrustEligible &&
      !hasAppointment &&
      !lockedByOtherTrust &&
      // [dd#2101] https://2-app.donedone.com/6110/project/17407/task/682498
      // "Vaccinators to be able to drop in staff with a status of Declined or Exempt"
      (!isComplete || hasDeclined || isExempt),
    lockedByOtherTrust,
    // temp:
    hasDeclinedOrHadElsewhere,
    isVaccinated,
    isExempt,
    isTrustEligible,
    hasAppointment,
  };
  // console.log(
  //   item,
  //   status,
  //   item.firstName,
  //   item.lastName,
  //   Pathogens[pathogen],
  //   pathogenPermissions,
  //   { isComplete, hasDeclinedOrHadElsewhere }
  // );
  return pathogenPermissions;
};

const allPathogens = Object.values(Pathogens)
  .map((p) => Number(p))
  .filter((p) => !isNaN(p));

const getPathogenPermissionMatrix = (item: TrustWorker) =>
  allPathogens.reduce(
    (p, c) => ({
      ...p,
      [c]: getPathogenPermissions(item, c),
    }),
    {} as { [pathogen: number]: TrustWorkerPermissions }
  );

const getReservation = (item: TrustWorker, pathogen: number) => {
  const suffix = Pathogens[pathogen];
  const reservation = Object.entries(item)
    .filter(([key]) => key.endsWith(suffix))
    .reduce(
      (p, [key, value]) => ({
        ...p,
        [key.slice(0, -suffix.length)]: value,
      }),
      {}
    );
  return {
    ...item,
    ...reservation,
    pathogens: [pathogen],
  };
};

const getReservations = (item: TrustWorker) => {
  return Object.entries(
    allPathogens
      .map((p) => ({
        ...getReservation(item, p),
        canCancel:
          item[`declineReasonId${Pathogens[p]}`] === null &&
          item[`vaccinationStatusId${Pathogens[p]}`] !== 2,
      }))
      .filter((a) => a.reservationId)
      .reduce(
        (p, c) => ({
          ...p,
          [c.reservationId]: {
            ...c,
            canCancel: (p[c.reservationId]?.canCancel || true) && c.canCancel,
            pathogens: (p[c.reservationId]?.pathogens || []).concat(
              c.pathogens
            ),
          },
        }),
        {}
      )
  ).map(([_id, value]) => value);
};

export { getReservations, getPathogenPermissionMatrix };
