import {
  Box,
  FormControlLabel,
  List,
  ListItem,
  Paper,
  Switch,
  Typography,
} from "@material-ui/core";
import React from "react";
import { Role } from "./useNonStaffRoles";

const StaffRoleSettings = ({
  title,
  roles,
  loading,
  handleRoleChange,
}: {
  title: string;
  roles: Role[];
  loading: boolean;
  handleRoleChange: (role: Role, value: boolean) => void;
}) => {
  return (
    <div>
      <Box pb={2}>
        <Typography variant="h5" color="primary">
          {title}
        </Typography>
      </Box>

      {roles?.length > 0 && (
        <Paper>
          <List component="nav">
            {roles.map((role) => (
              <ListItem key={`Role${role.staffTypeId}`}>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={loading}
                      checked={role.current}
                      onChange={(e) => handleRoleChange(role, e.target.checked)}
                    />
                  }
                  label={role.staffTypeName}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}

      {roles && roles.length === 0 && <h1>No data</h1>}
    </div>
  );
};

export default StaffRoleSettings;
