export default () => (
  <div className="wrapper-text">
    <h1>VaccinationTrack Privacy Notice</h1>
    <small>Last Updated: 5th September 2024</small>
    <h3>WHO WE ARE AND WHAT WE DO</h3>
    <h3>Who We Are</h3>
    <p>
      We are Quantum Health Solutions Limited (“Quantum Health”, “us”, “we”,
      “our”). We are a limited company registered in England and Wales under
      registration number 12142481 and we have our registered office at 1
      Vincent Square, London, United Kingdom, SW1P 2PN. We are registered with
      the UK supervisory authority, Information Commissioner’s Office (“ICO”),
      in relation to our processing of Personal Data under registration number
      ZA756240.
    </p>
    <h3>What We Do</h3>
    <p>
      VaccinationTrack is a cloud-based software owned and provided by Quantum
      Health Solutions which manages the annual staff vaccination programmes of
      organisations. We and our affiliates, subsidiaries and related entities
      are committed to protecting the privacy and security of the Personal Data
      we process about you.
    </p>
    <h3>Processor</h3>
    <p>
      Other than where it is specifically listed in section 7, we are the
      processor of the Personal Data we process about you. This means that we
      process Personal Data on behalf of, and solely on the instructions of the
      controller of your Personal Data (your NHS employer).
    </p>
    <p>
      By using the VaccinationTrack service, your NHS employer will always act
      as the controller of your Personal Data. They will determine the lawful
      basis for processing your Personal Data. We will always act solely as the
      processor.
    </p>
    <h3>PURPOSE OF THIS PRIVACY NOTICE</h3>
    <p>
      The purpose of this privacy notice is to explain what Personal Data we
      receive about you from your employer as part of the NHS led annual
      vaccination programme, and how we process it. This privacy notice also
      explains your rights, so please read it carefully. If you have any
      questions, you can contact us using the information provided below under
      the ‘How to contact us’ section. Alternatively, you can contact your NHS
      employer who is the controller for your Personal Data.
    </p>
    <h3>WHO THIS PRIVACY NOTICE APPLIES TO</h3>
    <p>
      The information in this privacy notice applies to you if your employer
      invites you to book an appointment to receive a vaccination, for this we
      act as a processor acting upon your employer’s instructions with regard to
      how we handle your Personal Data.
    </p>
    <p>
      This privacy notice will also apply to you if you interact with us in a
      professional/business capacity, in this particular instance we are the
      controller of your Personal Data.
    </p>
    <h3>WHAT PERSONAL DATA IS</h3>
    <p>
      ‘Personal Data’ means any information from which someone can be identified
      either directly or indirectly. For example, you can be identified by your
      name or an online identifier.
    </p>
    <p>
      ‘Special Category Personal Data’ is more sensitive Personal Data and
      includes information revealing racial or ethnic origin, political
      opinions, religious or philosophical beliefs, trade union membership,
      genetic data, biometric data for the purposes of uniquely identifying
      someone, data concerning physical or mental health or data concerning
      someone’s sex life or sexual orientation.
    </p>
    <h3>PERSONAL DATA WE PROCESS</h3>
    <p>
      The type of Personal Data we process about you will depend on our
      relationship with you. Please see the list below to understand the types
      of Personal Data we process.
    </p>
    <h3>HOW WE RECEIVE YOUR PERSONAL DATA</h3>
    <p>
      When acting as a processor we collect most of the Personal Data directly
      from your employer, or from you when you sign up to VaccinationTrack.
      <br />
      <br />
      The Personal Data we receive about you may include:
    </p>
    <ul>
      <li>First and Last name;</li>
      <li>Email address;</li>
      <li>Staff group;</li>
      <li>Job role;</li>
      <li>Employee number;</li>
      <li>NHS number;</li>
      <li>Ethnicity;</li>
      <li>Postcode;</li>
      <li>Appointment time / date;</li>
      <li>Login details / password;</li>
      <li>Vaccination details.</li>
    </ul>
    <p>
      We do not collect Personal Data about you from any third parties other
      than your employer.
    </p>
    <p>
      When acting as a controller we collect your Personal Data directly from
      you.
      <br />
      The Personal Data we collect may include:
    </p>
    <ul>
      <li>First and Last name;</li>
      <li>Email address;</li>
      <li>Phone number</li>
      <li>Organisation/company details</li>
    </ul>
    <h3>PURPOSES AND LAWFUL BASES</h3>
    <p>
      We will only use your Personal Data when the law allows. Most commonly, we
      will use your Personal Data in the following circumstances:
    </p>
    <p>
      As a processor we use your Personal Data for the following purposes and
      rely upon the following lawful bases, as determined by your NHS employer.
      For more information on this please see contact your employer.
    </p>
    <table className="table-generic">
      <tr>
        <th>Purpose of processing</th>
        <th>Lawful basis for processing</th>
      </tr>
      <tr>
        <td>
          Fulfilment of our contractual obligations with the NHS organisations
          that use VaccinationTrack
        </td>
        <td>
          This is necessary for the performance of the contract we have entered
          with your employer
        </td>
      </tr>
      <tr>
        <td>
          Providing you with continued support during the vaccination programme
          when using the VaccinationTrack service
        </td>
        <td>
          This is necessary for the performance of the contract we have entered
          with your employer
        </td>
      </tr>
      <tr>
        <td>
          Sharing your details with selected third parties that help provide
          VaccinationTrack’s service operations
        </td>
        <td>
          This is necessary for the performance of the contract we have entered
          with your employer
        </td>
      </tr>
    </table>
    <p>
      As the controller, we process your Personal Data in the following
      circumstances:
    </p>
    <table className="table-generic">
      <tr>
        <th>Purpose of processing</th>
        <th>Lawful basis for processing</th>
      </tr>
      <tr>
        <td>
          Responding to correspondence from you with regards to engaging our
          services
        </td>
        <td>
          It is in our legitimate interest to respond to you when you make an
          enquiry
        </td>
      </tr>
      <tr>
        <td>
          Providing you with support and advice before the start of the
          appointment booking programme and before you use the VaccinationTrack
          service
        </td>
        <td>
          It is in our legitimate interest to provide you with guidance to
          ensure that you are content to use the service
        </td>
      </tr>
      <tr>
        <td>
          Improving training and the quality of the service we provide by
          recording details of enquiries
        </td>
        <td>
          It is in our legitimate interest to continuously improve the service
          provided by our staff to NHS staff
        </td>
      </tr>
      <tr>
        <td>Internal management and administration purposes</td>
        <td>
          It is in our legitimate interest to use your Personal Data to identify
          areas for improving the effectiveness of the VaccinationTrack service
        </td>
      </tr>
    </table>
    <p>
      Where Personal Data is processed because it is necessary for the
      performance of a contract to which you are a party, we will be unable to
      provide our services without the required information.
    </p>
    <h3>SHARING YOUR PERSONAL DATA</h3>
    <p>
      It is possible that we may be required to share your Personal Data to
      comply with applicable laws or with valid legal processes, in response to
      a court order or with government or law enforcement agencies, such as the
      local council, Inland Revenue or the police. We will consult with your NHS
      employer (the controller) before disclosing any Personal Data. The lawful
      basis in these instances will be to comply with a legal obligation.
    </p>
    <h3>INTERNATIONAL TRANSFERS</h3>
    <p>
      VaccinationTrack’s services only operate in the UK. We ensure that all
      third parties we engage with to provide our services are also based or
      hosted within the UK. Please note that when acting as a processor, such as
      when you book a vaccination with us, we do not transfer your data outside
      of the UK.
    </p>
    <h3>YOUR RIGHTS AND HOW TO COMPLAIN</h3>
    <p>
      You have certain rights in relation to the processing of your Personal
      Data, including to:
    </p>
    <ul style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <li>
        <b>Right to be informed.</b> You have the right to know what Personal
        Data we collect about you, how we use it, for what purpose and in
        accordance with which lawful basis, who we share it with and how long we
        keep it. We use our privacy notice to explain this.
      </li>
      <li>
        <b>Right of access (commonly known as a “Subject Access Request”).</b>{" "}
        You have the right to receive a copy of the Personal Data we hold about
        you.
      </li>
      <li>
        <b>Right to rectification.</b> You have the right to have any incomplete
        or inaccurate information we hold about you corrected.
      </li>
      <li>
        <b>Right to erasure (commonly known as the right to be forgotten).</b>{" "}
        You have the right to ask us to delete your Personal Data.
      </li>
      <li>
        <b>Right to object to processing.</b> You have the right to object to us
        processing your Personal Data. If you object to us using your Personal
        Data for marketing purposes, we will stop sending you marketing
        material.
      </li>
      <li>
        <b>Right to restrict processing.</b> You have the right to restrict our
        use of your Personal Data.
      </li>
      <li>
        <b>Right to portability.</b> You have the right to ask us to transfer
        your Personal Data to another party.
      </li>
      <li>
        <b>Automated decision-making.</b> You have the right not to be subject
        to a decision based solely on automated processing which will
        significantly affect you. We do not use automated decision-making.
      </li>
      <li>
        <b>Right to withdraw consent.</b> If you have provided your consent for
        us to process your Personal Data for a specific purpose, you have the
        right to withdraw your consent at any time. If you do withdraw your
        consent, we will no longer process your information for the purpose(s)
        you originally agreed to, unless we are permitted by law to do so.
      </li>
      <li>
        <b>Right to lodge a complaint. </b> You have the right to lodge a
        complaint with the relevant supervisory authority, if you are concerned
        about the way in which we are handling your Personal Data. The
        supervisory authority in the UK is the Information Commissioner’s Office
        who can be contacted online at:{" "}
        <a
          href="https://ico.org.uk/global/contact-us/"
          target="_blank"
          rel="noreferrer"
        >
          Contact us | ICO
        </a>
        , Or by telephone on 0303 123 1113.
      </li>
    </ul>
    <h3>HOW TO EXERCISE YOUR RIGHTS</h3>
    <p>
      You will not usually need to pay a fee to exercise any of the above
      rights. However, we may charge a reasonable fee if your request is clearly
      unfounded or excessive. Alternatively, we may refuse to comply with the
      request in such circumstances.
    </p>
    <p>
      If you wish to exercise your rights, you may contact us using the details
      set out below within the section called ‘How to contact us and our Data
      Protection Officer’. We may need to request specific information from you
      to confirm your identity before we can process your request. Once in
      receipt of this, we will process your request without undue delay and
      within one month. In some cases, such as with complex requests, it may
      take us longer than this and, if so, we will keep you updated.
    </p>
    <p>
      Please be aware that where we process your Personal Data on behalf of your
      employer that your employer is responsible for complying with any rights
      requests you may action and we recommend that they are contacted in the
      first instance.
    </p>
    <h3>HOW TO CONTACT US AND OUR DATA PROTECTION OFFICER</h3>
    <p>
      If you wish to contact us in relation to this privacy notice or if you
      wish to exercise any of your rights outlined above, please contact us as
      follows:
    </p>
    <p>
      <address>
        1 Vincent Square,
        <br />
        London,
        <br />
        United Kingdom,
        <br />
        SW1P 2PN
      </address>{" "}
      <a href="mailto:info@qh-solutions.com" target="_blank" rel="noreferrer">
        info@qh-solutions.com
      </a>
    </p>
    <h3>CHANGES TO THIS PRIVACY NOTICE</h3>
    <p>
      We may update this notice (and any supplemental privacy notice), from time
      to time as shown below. We will notify of the changes where required by
      applicable law to do so.
    </p>
    <p>Last modified 5th September 2024</p>
  </div>
);
