import { Button } from "@material-ui/core";
import { withStyles, WithStyles } from "@material-ui/core/styles";

interface Styles {
  color: string;
  children: React.ReactNode;
  [key: string]: any;
}

interface ColorsMapping {
  flu: string;
  covid: string;
  combined: string;
  [key: string]: any;
}

interface ButtonStyles extends WithStyles<typeof buttonStyles> {
  color: string;
}

const styledBy =
  (property: string, mapping: ColorsMapping) => (props: Styles) =>
    mapping[props[property]];

const buttonStyles = {
  root: {
    padding: "6px 16px",
    background: styledBy("color", {
      flu: "#009fe3",
      covid: "#c80089",
      combined: "#36a545",
    }),
    color: "#fff",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
    "&:hover": {
      background: styledBy("color", {
        flu: "#027aad",
        covid: "#831b62",
        combined: "#2f7238",
      }),
    },
  },
  outlined: {
    margin: "20px",
  },
};

const StyledButton = withStyles(buttonStyles)(
  ({ classes, color, ...other }: ButtonStyles) => (
    <Button className={classes.root} {...other} />
  )
);

export default StyledButton;
