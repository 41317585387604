export const ApplicationName = "Qhs.FluTrack.Application";

export const QueryParameterNames = {
  ReturnUrl: "returnUrl",
  Message: "message",
};

export const messagesLogoutActions = {
  logout: (
    <>
      <h1>Log out</h1>
      <p>Redirecting, please wait..</p>
    </>
  ),
  logoutCallback: (
    <>
      <h1>Log out</h1>
      <p>Logging out, please wait...</p>
    </>
  ),
  loggedOut: (
    <>
      <h1>Successfully logged out</h1>
    </>
  ),
};

// export const LoginActions = {
//   login: "login",
//   loginCallback: "login-callback",
//   loginFailed: "login-failed",
//   profile: "profile",
//   register: "register",
// };
export const messagesLoginActions = {
  login: (
    <>
      <h1>Log in</h1>
      <p>Redirecting, please wait..</p>
    </>
  ),
  loginCallback: (
    <>
      <h1>Log in</h1>
      <p>Logging in, please wait...</p>
    </>
  ),
  profile: "...", // redirects
  register: "...", // redirects
};

const prefix = "/authentication";

export const ApplicationPaths = {
  DefaultLoginRedirectPath: "/",
  ApiAuthorizationClientConfigurationUrl: `_configuration/${ApplicationName}`,
  ApiAuthorizationPrefix: prefix,
  Login: `${prefix}/login`,
  LoginFailed: `${prefix}/login-failed`,
  LoginCallback: `${prefix}/login-callback`,
  Register: `${prefix}/register`,
  Profile: `${prefix}/profile`,
  LogOut: `${prefix}/logout`,
  LoggedOut: `${prefix}/logged-out`,
  LogOutCallback: `${prefix}/logout-callback`,
  IdentityRegisterPath: "Identity/Account/Register",
  IdentityManagePath: "Identity/Account/Manage",
};
