import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "@material-ui/core";
import { InsertLink } from "@material-ui/icons";
import { ContentState, EditorState, Modifier } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { useState } from "react";
import ConfirmDialog from "../views/Users/ConfirmDialog";
import { tagLabel } from "../views/Users/Tags";
import { TagSets } from "../views/Users/TrustAdmin/Settings/Templates/EditTemplateForm";
import "../_placeholders.scss";
export default ({
  contentState,
  editorState,
  onChange,
  tagSets,
}: {
  contentState: any;
  editorState: any;
  onChange: any;
  tagSets?: TagSets;
}) => {
  //
  //
  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  // Email templates will need migrating to *__ __* which is quite
  // a challenge and sensitive so let's wait til post crimbo!
  // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  //
  //
  const [modal, setModal] = useState<string | null>(null);
  // const [modal, setModal] = useState<string | null>("insert"); // ****debug
  const [text, setText] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [useButtonStyle, setUseButtonStyle] = useState(true);
  const open = (): void => {
    setModal("insert");
  };
  const insert = (
    text?: string,
    url?: string,
    useButtonStyle?: boolean
  ): void => {
    // These are all the tags that make it out alive after htmlToDraft and draftjsToMd
    // (block) h1 h2 h3 h4 h5 h6 pre blockquote li
    // (inline) b i strong em a
    // No attributes appear to survive, so the sneaky workaround for button style is:
    // <i><b><a> -> *__[Link](#)__* then style with em strong a {}
    const html = `${useButtonStyle && url ? "<em><strong>" : ""}${
      url ? `<a href="${url}">` : ""
    }${text || "Link Text"}${useButtonStyle && url ? "</strong></em>" : ""}${
      url ? `</a>` : ""
    } `;
    console.log(html);
    const newBlockMap = htmlToDraft(html); // convertFromHTML breaks link urls that are placeholders!
    // The next few lines of code took two days to write. Just sayin' WYSIWYG stuff is challenging!
    const contentStateNew = Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      ContentState.createFromBlockArray(
        newBlockMap.contentBlocks,
        newBlockMap.entityMap
      ).getBlockMap()
    );
    onChange(EditorState.push(editorState, contentStateNew, "insert-fragment"));
  };
  return (
    <>
      <div className="rdw-link-wrapper">
        <Button
          onClick={open}
          variant="outlined"
          color="primary"
          startIcon={<InsertLink />}
        >
          Insert Button / Link
        </Button>
      </div>
      <ConfirmDialog
        title="Insert Button / Link"
        open={modal === "insert"}
        confirmLabel="Insert"
        onConfirm={() => {
          insert(text, url, useButtonStyle);
          setTimeout(() => {
            setModal(null);
          }, 750); // needs > 0
        }}
        disableConfirm={!url || !text}
        onClose={() => setModal(null)}
        dialogParams={{
          fullWidth: true,
          maxWidth: "sm",
        }}
      >
        <form autoComplete="off">
          {/* <h4 style={{ margin: "0 0 1em" }}>
            Use this tool to place a new link at your current cursor position.
          </h4> */}
          <label style={{ display: "block", margin: "0 0 .5em" }}>
            Enter one of the following system buttons or enter your own URL to
            an external link e.g. a link on your intranet:
          </label>
          <section>
            {!!tagSets?.tagsUrls.length && (
              <div className="placeholders -insert">
                <ul>
                  {tagSets?.tagsUrls.map((tag: string) => (
                    <li
                      onClick={() => {
                        setUrl(tag);
                      }}
                    >
                      <InsertLink fontSize="small" color="secondary" />
                      {tagLabel(tag)}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <TextField
              label="URL"
              placeholder="http://example.com"
              value={url}
              onChange={(e) => {
                setUrl(e.target.value || null);
              }}
              fullWidth={true}
            />
          </section>
          <br />
          <br />
          <br />
          <label style={{ display: "block", margin: "0 0 .5em" }}>
            Enter the text that will appear in the button, e.g. 'Book
            Appointment', 'Click here to Book':
          </label>
          {/* {!!tagSets?.tagsRegular.length && (
            <div className="placeholders -insert">
              <ul>
                {tagSets?.tagsRegular.map((t: string) => (
                  <li
                    onClick={() => {
                      setText(t);
                    }}
                  >
                    <InsertLink fontSize="small" color="secondary" />
                    {startCase(t.replace("{{", "").replace("}}", ""))}
                  </li>
                ))}
              </ul>
            </div>
          )} */}
          <Grid container>
            <Grid item sm={6}>
              <TextField
                label="Link Text"
                placeholder="Visible link text"
                value={text}
                onChange={(e) => {
                  setText(e.target.value || "");
                }}
                required={true}
                fullWidth
              />
            </Grid>
          </Grid>
          <br />
          <br />
          <section>
            <FormControlLabel
              control={
                <Checkbox
                  checked={useButtonStyle}
                  onChange={(e) => {
                    setUseButtonStyle(!!e.target.checked);
                  }}
                  // Leave enabled even though it's moot without an URL (submit button is disabled anywya)
                  // disabled={!url}
                />
              }
              label={`(Uncheck this if you want the link to appear as standard underlined text)`}
            />
          </section>
          <br />
        </form>
      </ConfirmDialog>
    </>
  );
};
