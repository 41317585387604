import React from "react";
import ReactDOM from "react-dom";
import DataClient from "../../../../lib/services/api/DataClient";

const DELETE = "/Trust/";

export default function useCreateTrust() {
  const [success, setSuccess] = React.useState<false | number>(false);
  const [error, setError] = React.useState(false);

  const callback = (id: number) => {
    setError(false);
    setSuccess(false);
    DataClient.remove({ url: `${DELETE}${id}` })
      .then((res: unknown) => {
        ReactDOM.unstable_batchedUpdates(() => {
          if (!res["success"]) {
            throw new Error("API Update failed");
          }
          setSuccess(id);
        });
      })
      .catch((error) => {
        ReactDOM.unstable_batchedUpdates(() => {
          console.error(error);
          setError(true);
        });
      });
  };

  return [callback, success, error] as const;
}
