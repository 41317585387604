import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import api from "./data";
import StaffForm from "./Form";
import { staffCreateEditSubmit } from "./StaffValidation";
import ConfirmDialog from "../../ConfirmDialog";

import { isEqual } from "lodash";

function EditDialog({
  open,
  onClose,
  onSuccess,
  onError,
  value,
  onRemoveEmailInDoubt,
  onRemovePossibleDuplicate,
}: any): JSX.Element {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [formLoaded, setFormLoaded] = useState(false);
  const [duplicateDetails, setDuplicateDetails] = useState([]);

  /* 
  This value can be false, or an object: 
  {
    data: submitData used to show info in modal, 
    saveDataCb: callback to save data after modal confirmed
  }
  */
  const [showEsrUserManuallyEditedDialog, setShowEsrUserManuallyEditedDialog] =
    useState<false | { data: any; saveDataCb: () => void }>(false);

  const clearErrorsAndClose = () => {
    errors && setErrors({});
    onClose();
  };

  const saveAndClose = (data: any) => {
    if (isEqual(data, value)) {
      clearErrorsAndClose();
    } else {
      staffCreateEditSubmit(api.staff.edit)(
        {
          data,
          onSuccess,
          onError,
          setErrors,
          setLoading,
        },
        setShowEsrUserManuallyEditedDialog
      );
    }
  };

  const fetchPossibleDuplicateDetails = useCallback(
    (id: number) => {
      api.staff
        .getDuplicateDetails(id)
        .then((duplicateDetailsResult: { results: any[] }) => {
          setDuplicateDetails(duplicateDetailsResult.results);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    [setDuplicateDetails]
  );

  useEffect(() => {
    setDuplicateDetails([]);
    if (value && value.flagPossibleDuplicate) {
      fetchPossibleDuplicateDetails(value.id);
    }
  }, [fetchPossibleDuplicateDetails, value && value.id]);

  const formatPropertyName = (propName: string): string => {
    return propName
      .split(/(?=[A-Z])/g)
      .join(" ")
      .toLowerCase();
  };

  const warningTextStyles = {
    marginBottom: "20px",
    paddingBottom: "20px",
    borderBottom: "1px solid #e0e0e0",
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={clearErrorsAndClose}
        fullWidth
        maxWidth="sm"
        className="-fill-height -gradient-fade"
      >
        <DialogTitle disableTypography>
          <h2>Edit Staff Member</h2>
        </DialogTitle>
        <DialogContent>
          {value?.approved === false && (
            <div style={warningTextStyles}>
              <p>
                This staff member has self-registered but has not yet been
                approved. If you wish, you can edit their details before
                approving or rejecting them.
              </p>
              {value.lineManager && (
                <p>For reference their line manager is {value?.lineManager}</p>
              )}
            </div>
          )}
          {value?.flagEmailInDoubt && (
            <div style={warningTextStyles}>
              <p>
                This user's email is 'in doubt'. Someone matching this name and
                date of birth has tried to self-register but with a different
                email address. Therefore, you may wish to update their existing
                record with the email address they submitted during self
                registration.
              </p>
              <p>
                If you are updating the user's email address, please use the
                ‘Resend Invite’ function in the blue cog actions menu on the
                staff page to resend an invite to their new email address. Just
                amending the email address and/or re-uploading if they're on
                your staff list will not resend an invite.
              </p>
              <p>
                To remove the 'Email in Doubt' flag, click the button below or
                click 'Remove Email in Doubt Flag' in the actions menu on the
                staff page.
              </p>
              <p>
                <b>
                  Email address
                  {value.possibleDuplicates.filter(
                    (x: any) => x.duplicatedOn === "EmailAddress"
                  ).length > 1
                    ? "es"
                    : ""}{" "}
                  submitted during self registration:
                </b>
              </p>
              {value.possibleDuplicates
                .filter((x: any) => x.duplicatedOn === "EmailAddress")
                .map((duplicate: any) => (
                  <p>
                    <b>{duplicate.value}</b>
                  </p>
                ))}
              <Button
                onClick={() => {
                  onRemoveEmailInDoubt(value);
                }}
                type="button"
                variant="outlined"
                color="secondary"
              >
                Clear Email in Doubt
              </Button>
            </div>
          )}
          {value?.flagPossibleDuplicate && (
            <div style={warningTextStyles}>
              <p>
                This user is possibly a duplicate. Someone partially matching
                this name and date of birth has tried to self-register with a
                different email address. Please check with this user and update
                their details accordingly, or click below to clear this status
                if they prefer to keep using the original email.
              </p>
              <p>
                If you are updating the user's email address, please use the
                ‘Resend Invite’ function in the cog menu to send an invite to
                their new email address
              </p>
              {!duplicateDetails.length && <p>Loading list of duplicates...</p>}
              {!!duplicateDetails.length && (
                <p>
                  Possible duplicate
                  {duplicateDetails?.length > 1 ? "s" : ""}
                </p>
              )}
              {!!duplicateDetails.length &&
                duplicateDetails?.map((duplicate: any) => (
                  <p>
                    {duplicate.duplicantEmail} - matches:{" "}
                    {formatPropertyName(duplicate.duplicatedOn)}: (
                    {duplicate.value}).
                  </p>
                ))}

              <Button
                onClick={() => {
                  onRemovePossibleDuplicate(value);
                }}
                type="button"
                variant="outlined"
                color="secondary"
              >
                Clear Possible Duplicate
              </Button>
            </div>
          )}
          <StaffForm
            formId="editForm"
            defaultValue={value}
            onSubmit={saveAndClose}
            errors={errors}
            setFormLoaded={setFormLoaded}
          />
        </DialogContent>

        <DialogActions>
          <Button
            form="editForm"
            type="submit"
            variant="contained"
            color="secondary"
            autoFocus
            disabled={loading}
          >
            Update
          </Button>
          <Button
            onClick={clearErrorsAndClose}
            variant="outlined"
            color="secondary"
            style={{
              color: "#fff",
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        open={showEsrUserManuallyEditedDialog !== false}
        {...{
          title: `Important Reminder!`,
          children: (
            <>
              <p>
                You have just made changes to a staff record that was previously
                uploaded via the staff list.
              </p>
              <p>
                <strong>
                  Please remember to make the same changes on the staff list CSV
                  file,
                </strong>
                <span> </span>
                otherwise the changes will be overwritten after the next upload
                and the record will revert back to its previous state.
              </p>
            </>
          ),
          onCancel: () => setShowEsrUserManuallyEditedDialog(false),
          onClose: () => setShowEsrUserManuallyEditedDialog(false),
          confirmLabel: "OK",
          onConfirm: () => {
            if (!showEsrUserManuallyEditedDialog) return;
            const { saveDataCb } = showEsrUserManuallyEditedDialog;
            saveDataCb();
          },
        }}
      ></ConfirmDialog>
    </>
  );
}

export { EditDialog };
