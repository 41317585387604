import {
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { ArrowBack, CloudDownload } from "@material-ui/icons";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import DataClient from "../../../../../lib/services/api/DataClient";
import { mapResultsSummary } from "./List";

/*
 NB: This table is not just errors any more. It also displays things like archived users being restored.
*/
function ESRErrorsTable({ items }) {
  return (
    <TableContainer component={Paper} className="table">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Row Number</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Messages</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.lineNumber}</TableCell>
              <TableCell>
                {row.firstName} {row.lastName}
              </TableCell>
              <TableCell>
                {row.messages.map((msg) => (<p>{msg}</p>))} {
                  row.assistance && (<p><strong>RECOMMENDED SOLUTION:</strong> {row.assistance}</p>)
                }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function ESRSuccessTable({ results }) {
  return (
    <>
      <ul>
        {results?.map((summary, ind) => (
          <li key={ind}>
            {summary.prefixValue} {summary.label}
          </li>
        ))}
      </ul>
    </>
  );
}

function Detail() {
  const { id } = useParams();

  const history = useHistory();
  const [data, setData] = useState(null);

  const fetchData = useCallback(() => {
    Promise.all([
      DataClient.getData(`/EsrUpload/${id}`),
      DataClient.postData(`/EsrUploadDetail/Search`, {
        pageSize: -1,
        esrUploadId: id,
        severityMin: 2,
      }),
    ])
      .then((r) => {
        setData({
          item: r[0].results,
          errors: r[1].results,
          isSuccess: r[0].results.uploadStatusName === "Successful",
        });
      })
      .catch((e) => {
        console.error(e);
      });
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDownload = (id, date) => {
    DataClient.getFile({
      url: `/EsrUploadDetail/${id}/GetFile`,
      filename: `Staff List Errors ${moment(date).format(
        "MMMM Do YYYY, H:mm:ss"
      )}.csv`,
    }).catch((e) => console.error(e));
  };

  return (
    <main className="wrapper-main">
      <h1>
        Staff List File
        {data && (
          <>
            {" "}
            - {moment(data.item.dateTimeUploaded).format("DD/MM/yyyy HH:mm:ss")}
          </>
        )}
      </h1>

      <p>
        <Button
          onClick={() => history.push("/esr")}
          color={"primary"}
          startIcon={<ArrowBack />}
        >
          Back to Staff List Upload
        </Button>
      </p>

      {/* Results */}
      {data?.item && (
        <>
          <ESRSuccessTable results={mapResultsSummary(data?.item)} />
        </>
      )}

      {/* Errors */}
      {data && !data.isSuccess && (
        <>
          <h3 className="danger">
            Some errors occurred whilst processing the upload:
          </h3>
          <p>
            <Button
              onClick={() => handleDownload(id, data.item.dateTimeUploaded)}
              color="secondary"
              variant="contained"
              startIcon={<CloudDownload />}
            >
              Download Errors
            </Button>
          </p>
        </>
      )}

      {data ? (
        !!data.errors.length && <ESRErrorsTable items={data.errors} />
      ) : (
        <CircularProgress />
      )}
    </main>
  );
}

export default Detail;
