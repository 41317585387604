import Copy from "../../components/Copy";
// export const customTagLabels = {
//   "{{BookAppointmentUrl}}": "Book Appointment",
//   "{{Fullname}}": "{{Fullname}}",
//   "{{OptoutLink}}": "Optout Link (Decline)",
//   "{{VerifyAccount}}": "{{VerifyAccount}}",
// };
// export const tagLabel = (tag: string) =>
//   customTagLabels[tag] || startCase(tag.replace("{{", "").replace("}}", ""));
// https://techdepartment.mydonedone.com/issuetracker/projects/84148/issues/374#history-27748286
export const tagLabel = (tag: string) => tag;
export const Tags = ({
  title,
  subheading,
  tags,
  tagsToAsterisk = [],
  copyable,
}: {
  title?: string;
  subheading?: string;
  tags: string[];
  tagsToAsterisk?: string[];
  copyable: boolean;
}) => {
  // Move mandatory tags, if provided, to start of array. Not quite alphabetical within each section but meh
  tags.sort().sort((a, b) => (tagsToAsterisk.includes(a) ? -1 : 0));
  return tags?.length ? (
    <>
      {title && <h4>{title}</h4>}
      {subheading && <p>{subheading}</p>}
      <ul>
        {tags.map((tag: string) => (
          <li
            key={tag}
            className={`${tagsToAsterisk.includes(tag) ? "required" : ""}`}
          >
            {copyable ? (
              <Copy {...{ copyContent: tag }}>
                <>{tagLabel(tag)}</>
              </Copy>
            ) : (
              tagLabel(tag)
            )}
          </li>
        ))}
      </ul>
    </>
  ) : null;
};
export const filterTagsNaughties = (tag: string) =>
  !["{{EmailLogo}}"].includes(tag);
export const filterTagsUrls = (tag: string) =>
  [
    "{{BookAppointmentUrl}}",
    "{{ManageBookingUrl}}",
    "{{WebsiteBaseUrl}}",
    "{{OptoutLink}}",
    "{{VerifyAccount}}",
    "{{HadElsewhere}}",
    "{{PasswordReset}}",
    "{{EmailLogo}}",
  ].includes(tag);
