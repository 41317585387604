// To replace TableTheme.js if we have confirmation that it's redundant..
// Myke: Trying to collate table styles in one place! I didn't write most of it though, otherwise it'd be commented
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { font1, palette } from "./vars";
const breakpoints = createBreakpoints({});
// Included everywhere, via App.js ::
export const overridesMain = {
  typography: {
    // Material UI defaults to Roboto
    fontFamily: font1,
  },
  MuiGrid: {
    "spacing-xs-5": {
      margin: "0",
      width: "100%",
      [breakpoints.down("xs")]: {
        margin: "-15px",
      },
    },
    "spacing-xs-2": {
      margin: "0",
      width: "100%",
      [breakpoints.down("xs")]: {
        margin: "-15px",
      },
    },
  },
  MuiTab: {
    root: {
      textTransform: "none",
    },
  },
  MuiButton: {
    root: {
      // fontFamily: "Times New Roman", // debugging
      fontFamily: font1,
      fontWeight: 600,
      letterSpacing: "normal",
      textTransform: "none" as const,
    },
  },
  MuiAlert: {
    root: {
      marginBottom: "2em",
    },
  },
  // obsolete, throws a warning
  // MuiDialogTitle: {
  //   variant: "h2",
  // },
  // <DialogActions />
  MuiDialogActions: {
    root: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      background: "#283583",
      padding: "1em",
    },
  },
};
export const overridesGenericCells = {
  MuiTableCell: {
    root: {
      border: "none",
      borderBottom: "none",
      padding: "0 .2em",
      height: "3em", // functionally a min-height
      textAlign: "left",
      "&:first-child": {
        borderRadius: "0.5em 0 0 0.5em",
        paddingLeft: "1em",
      },
      "&:last-child": {
        borderRadius: "0 0.5em 0.5em 0",
      },
      "&:only-child": {
        borderRadius: "0.5em",
      },
      "& [class*='MuiButton-root']": {
        textTransform: "none",
        wordBreak: "normal",
        lineHeight: "1.1",
      },
    },
  },
};
export const overridesTableGenericColors = {
  MUIDataTableHeadCell: {
    // obsolete, throws warning
    // head: {
    //   color: palette.primary.contrastText,
    //   backgroundColor: palette.primary.main,
    // },
    fixedHeader: {
      color: palette.primary.contrastText,
      backgroundColor: palette.primary.main,
    },
    sortAction: {
      lineHeight: "1.1",
      color: palette.primary.contrastText,
      // textDecoration: "underline",
      "& svg": {
        fill: palette.primary.contrastText,
      },
    },
    sortActive: {
      color: palette.primary.contrastText,
    },
  },
  MUIDataTableSelectCell: {
    headerCell: {
      color: palette.primary.contrastText,
      backgroundColor: palette.primary.main,
    },
  },
  MuiTable: {
    root: {
      borderSpacing: "0 10px",
      borderCollapse: "separate",
    },
  },
  MUIDataTable: {
    paper: {
      boxShadow: "none",
      backgroundColor: "transparent",
    },
  },
  MUIDataTableBodyRow: {
    root: {
      backgroundColor: "#fff",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
    },
  },
  MUITableBodyRow: {
    root: {
      backgroundColor: "#fff",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
    },
  },
  MuiTableRow: {
    root: {
      backgroundColor: "#fff",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
    },
  },
  MUIDataTableToolbar: {
    left: {
      position: "relative", // perhaps wrong
    },
    root: {
      borderRadius: "0.5em",
    },
  },
};
export const overridesTableRemoveSelect = {
  MUIDataTableSelectCell: {
    root: {
      display: "none",
    },
  },
  MUIDataTableToolbarSelect: {
    title: {
      display: "none",
    },
  },
};
export const overridesTableSearchBox = {
  MuiInputBase: {
    formControl: {
      border: "1px solid #ddd",
      borderRadius: "5px",
      maxWidth: "15em",
      background: "#fff",
    },
    input: {
      marginLeft: ".5em",
    },
  },
  MuiInput: {
    underline: {
      "&:hover:not(.Mui-disabled):before": {
        borderBottom: "0px",
      },
      "&:before": {
        borderBottom: "0px",
      },
      "&:after": {
        borderBottom: "0px",
      },
    },
  },
  MUIDataTableSearch: {
    // Hiding left icon
    searchIcon: {
      // position:'absolute',
      // right:'19%',
      // height:'32px',
      // borderLeft:'1px solid #ddd',
      // marginTop:'0px',
      // padding:'0px 5px',
      // color:'#000',
      display: "none",
    },
    clearIcon: {
      display: "none",
      padding: ".2em",
    },
  },
};
// Filter dialog styles (generic)
export const overridesTableFilters = {
  MUIDataTableToolbar: {
    filterPaper: {
      overflow: "visible", // Allow help tooltip overflow, within filters popup, e.g. Frontline(?)
      "@media (min-width:600px)": {
        width: "600px",
        top: "4.7em!important",
      },
      // "& *:not(button *)": {
      "& h1,label": {
        fontFamily: font1,
      },
      "& [aria-label='Close'] + div > div:first-child": {
        flexFlow: "column",
        "& > div": {
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          paddingRight: "1em",
        },
      },
      "& [class*='MuiTypography-root']": {
        margin: "0",
      },
      "& h1": {
        padding: "0",
        margin: "0",
      },
      "& [class*='gridListTile']": {
        marginTop: 0,
      },
      "& [data-testid='filterReset-button']": {
        background: "none!important",
        "& [class*='MuiTouchRipple']": {
          display: "none",
        },
      },
      "& label": {
        display: "block",
        margin: "-.3em 0 0",
        fontSize: "1.3em",
      },
      // Hide any <Help /> components in the dialog (which would appear as the element is the same as table headers)
      "& [help-copy-key]": {
        display: "none",
      },
    },
  },
};
// Filter dialog styles (staff page only)
export const overridesTableFiltersStaff = {
  // https://github.com/gregnb/mui-datatables/issues/1810#issuecomment-999201175
  MUIDataTableFilter: {
    gridListTile: {
      // Role - move to last position
      "&:nth-child(4)": {
        order: 6,
      },
    },
  },
};
// Use with lodash merge() as it is non-shallow
export const overridesTableHackActions = {
  // https://github.com/gregnb/mui-datatables/issues/1839
  MUIDataTableToolbar: {
    left: { minWidth: "25em" },
    actions: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      "& span:nth-child(2)": {
        order: -1,
        // justifySelf: "flex-start",
        // margin: "0 auto 0 2em",
      },
    },
  },
  // There is another part to these styles, .table-hack-actions in _table.scss (as the button cannot be easily styled here)
};
export const overridesToolbarRemoveLeftPadding = {
  MUIDataTableToolbar: {
    root: {
      paddingLeft: 0,
    },
  },
};
