import { useState, useEffect } from "react";
import { Grid, Typography, LinearProgress, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgressbar } from "react-circular-progressbar";
import clsx from "clsx";
import DashboardApi, { DashboardOverview } from "../Dashboard/DashboardApi";
import { humanPathogensSimple } from "../../lib/pathogens";
import Help from "../../components/Help/Help";
const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: theme.spacing(2),
    height: 300,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  flex: {
    display: "flex",
    alignItems: "flex-end",
    maxHeight: "50px",
    // flex: 1
  },
  flex0: {
    // flex: 0
  },
  progress: {
    marginTop: 5,
    paddingBottom: 20,
  },
  subtitle1: {
    marginTop: 20,
    fontWeight: 800,
    fontSize: theme.spacing(4),
  },
  activeStaffCount: {
    fontSize: theme.spacing(3.5),
    fontWeight: 800,
  },
  totalStaffCount: {
    fontSize: theme.spacing(2.5),
    paddingBottom: 4,
    color: theme.palette.grey[400],
  },
  data: {
    height: theme.spacing(6),
    marginBottom: theme.spacing(2),
    borderRight: "1px solid #020202",
    display: "flex",
    flexDirection: "column",
    // marginBottom: "4em",
    minHeight: "4em",
    "&:last-child": {
      borderRight: "none",
    },
  },
  dataTooltip: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "100%",
    textAlign: "center",
    "& .MuiIconButton-root": {
      marginTop: "-0.55em",
    },
  },
  activeWrapper: {
    alignItems: "center",
  },
  dataWrapper: {
    alignItems: "center",
  },
  p: {
    textAlign: "center",
  },
  dataValueMain: {
    textAlign: "center",
    fontWeight: 800,
  },
  countColorGreen: {
    color: theme.palette.success.main,
    fontWeight: 400,
  },
  countColorSecondary: {
    color: theme.palette.secondary.main,
    fontWeight: 800,
  },
  totalVaccinationCount: {
    marginTop: theme.spacing(2),
  },
  totalCountText: {
    fontSize: "15px",
  },
  loadingProgress: {
    padding: 0,
    width: "100%",
  },
}));
// Parsing strange format "1", "2", "1|2"
export const currentVaccineType = (pathogenIds: string) =>
  humanPathogensSimple(pathogenIds.split("|").map((x) => parseInt(x)));
export function Activestaff(props: any) {
  const vaccinatedPercent = () => {
    console.log(`overview.totalVaccinated: `, overview.totalVaccinated);
    console.log(
      `overview.totalStaff: `,
      overview.totalStaff - overview.inactiveStaff,
    );
    if (!overview.totalVaccinated || overview.totalVaccinated < 1) {
      return 0;
    }
    return Math.round(
      (overview.totalVaccinated /
        (overview.totalStaff - overview.inactiveStaff)) *
        100,
    );
  };
  const classes = useStyles();
  const [loading, setLoading]: [boolean, any] = useState();
  const [overview, setOverview]: [DashboardOverview, any] = useState({
    decline: 0,
    hadElsewhere: 0,
    inactiveStaff: 0,
    noBooking: 0,
    totalBooking: 0,
    totalAppointmentsBooked: 0,
    totalVaccinationsGiven: 0,
    totalDnasRecorded: 0,
    totalStaff: 0,
    totalVaccinated: 0,
  } as DashboardOverview);
  const api = DashboardApi;
  useEffect(() => {
    setLoading(true);
    setOverview({
      decline: 0,
      hadElsewhere: 0,
      inactiveStaff: 0,
      noBooking: 0,
      totalBooking: 0,
      totalAppointmentsBooked: 0,
      totalVaccinationsGiven: 0,
      totalDnasRecorded: 0,
      totalStaff: 0,
      totalVaccinated: 0,
    } as DashboardOverview);
    api
      .overView(
        props.pathogenIds,
        props.onlyFLHCW,
        props.trustId,
        props.excludeL1Ids,
      )
      .then((res) => {
        setLoading(false);
        setOverview(res);
      });
  }, [api, props]);
  const currentAppointmentType = () =>
    props.pathogenIds.length === 1
      ? props.pathogenIds[0] == 1
        ? "Flu Only"
        : "Covid Only"
      : "Flu & Covid Combined";
  return (
    <>
      <Grid xs={12} item container justifyContent="space-between" spacing={4}>
        <Grid
          xs={6}
          item
          container
          className={classes.activeWrapper}
          direction="column"
          justifyContent="flex-start"
        >
          <Grid className={classes.data} item xs={12}>
            <Typography className={classes.subtitle1}>Active Staff</Typography>
            <Grid className={classes.flex} item xs={12}>
              <span className="help-tooltip-wrapper -dash-large">
                <Typography
                  className={clsx(
                    classes.countColorSecondary,
                    classes.activeStaffCount,
                    "theme-color",
                  )}
                  data-theme-id={props.pathogenIds.replace("|", ",")}
                >
                  {overview.totalStaff - overview.inactiveStaff}
                </Typography>
                <div className="tooltip-overrides-small-counter-active-staff-count">
                  <Help
                    helpCopyOverride={{
                      location: "",
                      type: "tooltip",
                      title: "",
                      content: (
                        <>
                          Number of active staff entitled to a{" "}
                          {currentVaccineType(props.pathogenIds)} vaccine
                        </>
                      ),
                    }}
                  />
                </div>
              </span>
              <Typography className={classes.totalStaffCount}>
                &nbsp;/&nbsp;
              </Typography>
              <span className="help-tooltip-wrapper -dash-large">
                <Typography variant="body2" className={classes.totalStaffCount}>
                  {overview.totalStaff}
                </Typography>
                <div className="tooltip-overrides-small-counter-active-staff-total">
                  <Help
                    helpCopyOverride={{
                      location: "",
                      type: "tooltip",
                      title: "",
                      content: (
                        <>
                          Total number of staff including inactive staff
                          entitled to a {currentVaccineType(props.pathogenIds)}{" "}
                          vaccine. (Anyone with an inactive assignment status or
                          who has left the Trust is classed as inactive).
                        </>
                      ),
                    }}
                  />
                </div>
              </span>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={6} item container>
          <CircularProgressbar
            className={classes.progress}
            value={vaccinatedPercent()}
            text={`${vaccinatedPercent()}%`}
            strokeWidth={7}
          />
          <div className="tooltip-overrides-small-counter-active-staff-percentage">
            <Help
              helpCopyOverride={{
                location: "",
                type: "tooltip",
                title: "",
                content: (
                  <>
                    Percentage of active staff (who are entitled to a{" "}
                    {currentVaccineType(props.pathogenIds)} vaccine) who have
                    been vaccinated for {currentVaccineType(props.pathogenIds)}
                  </>
                ),
              }}
            />
          </div>
        </Grid>
      </Grid>
      <Grid xs={12} item container className={classes.dataWrapper}>
        <Grid className={classes.data} item xs={4}>
          <div className={classes.dataTooltip}>
            <div className="help-tooltip-wrapper">
              <Typography className={classes.p}>Vaccinated</Typography>
              <div className="tooltip-overrides-small-counter-grid-items">
                <Help
                  helpCopyOverride={{
                    location: "",
                    type: "tooltip",
                    placement: "top",
                    title: "",
                    content: (
                      <>
                        Total number of active staff who have been vaccinated
                        for {currentVaccineType(props.pathogenIds)}
                      </>
                    ),
                  }}
                />
              </div>
            </div>
          </div>
          <Typography
            className={clsx(classes.dataValueMain, "theme-color")}
            data-theme-id={props.pathogenIds.replace("|", ",")}
          >
            {overview.totalVaccinated}
          </Typography>
        </Grid>
        {props.pathogenIds.length == 1 && (
          <>
            <Grid className={classes.data} item xs={4}>
              <div className={classes.dataTooltip}>
                <div className="help-tooltip-wrapper">
                  <Typography className={classes.p}>By Trust</Typography>
                  <div className="tooltip-overrides-small-counter-grid-items">
                    <Help
                      helpCopyOverride={{
                        location: "",
                        type: "tooltip",
                        placement: "top",
                        title: "",
                        content: (
                          <>
                            Number of active staff who have been vaccinated for{" "}
                            {currentVaccineType(props.pathogenIds)} by the Trust
                          </>
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
              <Typography
                className={clsx(classes.dataValueMain, "theme-color")}
                data-theme-id={props.pathogenIds.replace("|", ",")}
              >
                {overview.totalVaccinated - overview.hadElsewhere}
              </Typography>
            </Grid>
            <Grid className={classes.data} item xs={4}>
              <div className={classes.dataTooltip}>
                <div className="help-tooltip-wrapper">
                  <Typography className={classes.p}>Had Elsewhere</Typography>
                  <div className="tooltip-overrides-small-counter-grid-items">
                    <Help
                      helpCopyOverride={{
                        location: "",
                        type: "tooltip",
                        placement: "top",
                        title: "",
                        content: (
                          <>
                            Number of active staff who have been vaccinated for{" "}
                            {currentVaccineType(props.pathogenIds)} outside the
                            Trust
                          </>
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
              <Typography
                className={clsx(classes.dataValueMain, "theme-color")}
                data-theme-id={props.pathogenIds.replace("|", ",")}
              >
                {overview.hadElsewhere}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
      {props.pathogenIds.length == 1 && (
        <>
          <Grid xs={12} item container className={classes.dataWrapper}>
            <Grid className={classes.data} item xs={4}>
              <div className={classes.dataTooltip}>
                <div className="help-tooltip-wrapper">
                  <Typography className={classes.p}>Booked</Typography>
                  <div className="tooltip-overrides-small-counter-grid-items">
                    <Help
                      helpCopyOverride={{
                        location: "",
                        type: "tooltip",
                        placement: "top",
                        title: "",
                        content: (
                          <>
                            Number of active staff who are booked to be
                            vaccinated for{" "}
                            {currentVaccineType(props.pathogenIds)} (either in
                            this Trust or another Trust using VaccinationTrack)
                          </>
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
              <Typography className={clsx(classes.countColorGreen, classes.p)}>
                {overview.totalBooking}
              </Typography>
            </Grid>
            <Grid className={classes.data} item xs={4}>
              <div className={classes.dataTooltip}>
                <div className="help-tooltip-wrapper">
                  <Typography className={classes.p}>Declined</Typography>
                  <div className="tooltip-overrides-small-counter-grid-items">
                    <Help
                      helpCopyOverride={{
                        location: "",
                        type: "tooltip",
                        placement: "top",
                        title: "",
                        content: (
                          <>
                            Number of active staff who have declined a{" "}
                            {currentVaccineType(props.pathogenIds)} vaccine
                          </>
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
              <Typography className={clsx(classes.countColorGreen, classes.p)}>
                {overview.decline}
              </Typography>
            </Grid>
            <Grid className={classes.data} item xs={4}>
              <div className={classes.dataTooltip}>
                <div className="help-tooltip-wrapper">
                  <Typography className={classes.p}>No Booking</Typography>
                  <div className="tooltip-overrides-small-counter-grid-items">
                    <Help
                      helpCopyOverride={{
                        location: "",
                        type: "tooltip",
                        placement: "top",
                        title: "",
                        content: (
                          <>
                            Number of active staff who haven’t booked a{" "}
                            {currentVaccineType(props.pathogenIds)} vaccine
                            (either in this Trust or another Trust using
                            VaccinationTrack)
                          </>
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
              <Typography className={classes.p}>
                <small>(inc. DNA &amp; ABNV)</small>
              </Typography>
              <Typography className={clsx(classes.countColorGreen, classes.p)}>
                {overview.noBooking}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
      <Grid item container className={clsx(classes.totalVaccinationCount)}>
        <Typography className={classes.totalCountText}>
          Total {currentAppointmentType()} appointments booked (includes
          cancellations &amp; reschedules / excludes staff dropped into clinics)
          <strong> {overview.totalAppointmentsBooked} </strong>
        </Typography>
      </Grid>
      <Grid item container className={clsx(classes.totalVaccinationCount)}>
        <Typography className={classes.totalCountText}>
          Total {currentVaccineType(props.pathogenIds)} vaccinations recorded
          during the campaign
          <strong> {overview.totalVaccinationsGiven} </strong>
        </Typography>
      </Grid>
      {props.pathogenIds.length === 1 && (
        <Grid item container className={clsx(classes.totalVaccinationCount)}>
          <Typography className={classes.totalCountText}>
            Total {currentVaccineType(props.pathogenIds)} DNAs recorded during
            the campaign
            <strong> {overview.totalDnasRecorded} </strong>
          </Typography>
        </Grid>
      )}
      {loading && (
        <Grid className={classes.loadingProgress}>
          <LinearProgress color="secondary" aria-busy={loading} />
        </Grid>
      )}
    </>
  );
}
