import {
  Button,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Tooltip,
} from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";
import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ToastMessage from "../../../../components/Universal/toast_message";
import useSuperAdminList from "./useSuperAdminList";
import NavContainer from "../NavContainer";
import SuperAdminCreateUpdate from "./SuperAdminCreateUpdate";

export default function TrustAdmins() {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { state } = useLocation<{ admin: any; update: any }>();
  const [feedback, setFeedback] = React.useState<{
    type: "success" | "error";
    message: string;
  }>(null);
  const [admins, loading, error] = useSuperAdminList();
  const [adminList, setAdminList] = React.useState([]);
  React.useEffect(() => {
    setAdminList(admins);
  }, [admins]);
  React.useEffect(() => {
    if (error) {
      setFeedback({
        type: "error",
        message: "There was a problem loading the Super Admins.",
      });
    }
  }, [error]);
  React.useEffect(() => {
    if (state?.admin && admins) {
      setAdminList((v) => {
        if (v.some((t) => t.id === state.admin.id)) return v;
        const updated = [...v, state.admin];
        updated.sort((a, b) => a.email.localeCompare(b.email));
        return updated;
      });
    }
    if (state?.update && admins) {
      setAdminList((v) => {
        const updated = [...v];
        const index = updated.findIndex((a) => a.id === state.update.id);
        updated[index] = state.update;
        return updated;
      });
    }
  }, [state, admins]);
  return (
    <>
      {loading && <LinearProgress color="primary" />}
      <NavContainer>
        <Grid container direction="row" justifyContent="space-between">
          <h1>Manage Super Admins</h1>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              history.push("/users/super-admins/new");
            }}
          >
            Add Super Admin
          </Button>
        </Grid>
        {adminList?.length > 0 && (
          <Paper>
            <List>
              {adminList.map((admin) => (
                <ListItem key={admin.id}>
                  <ListItemText primary={admin.email} />
                  <ListItemSecondaryAction>
                    <Tooltip title="Edit">
                      <IconButton
                        color="primary"
                        onClick={() => {
                          history.push(`/users/super-admins/${admin.id}`);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Paper>
        )}
        {id && (
          <SuperAdminCreateUpdate
            id={id !== "new" ? Number(id) : null}
            setFeedback={setFeedback}
          />
        )}
        {feedback && (
          <ToastMessage
            variant={feedback.type}
            message={feedback.message}
            clear={() => {
              setFeedback(null);
            }}
          />
        )}
      </NavContainer>
    </>
  );
}
