import {
  Box,
  FormControl,
  FormControlLabel,
  Paper,
  Switch,
} from "@material-ui/core";
// import { Check, Clear } from "@material-ui/icons";
import { pick } from "lodash";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ApiAlert from "../../../../../components/ApiAlert";
import { updateNotificationSettingsSwitches } from "../../../../../lib/enums";
import DataClient from "../../../../../lib/services/api/DataClient";
import ConfirmDialog from "../../../ConfirmDialog";
import { SteppedConfirmDialog } from "../../../SteppedConfirmDialog";
export default function ({ trust }: { trust: any }): JSX.Element {
  const [alert, setAlert] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [switchPreflight, setSwitchPreflight] = useState(null);
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: pick(
      trust,
      updateNotificationSettingsSwitches.map((s) => s.name)
    ),
  });
  const onSubmit = async (data: any) => {
    setDisabled(true);
    const { success, result } = await DataClient.update(
      `/Trust/${trust?.id}/UpdateNotificationSettings`,
      data
    );
    if (success) {
      setAlert(
        ApiAlert({
          alwaysShow: true,
          severity: "success",
          title: "Saved",
          message: "Email settings have been successfully updated",
        })
      );
      setDisabled(false);
    }
  };
  return (
    <form autoComplete="off">
      {alert}
      {updateNotificationSettingsSwitches.map(
        (
          {
            name,
            label,
            description,
            activateDialogTitle,
            deactivateDialogTitle,
            deactivateButtonLabel,
            dialogFixedHeight,
            generateConfirmationSteps,
          },
          i
        ) => (
          <Controller
            {...{ name, control, key: `switch-${i}` }}
            render={({ field: { name, value, onChange, ...rest } }) => {
              const confirmationSteps = generateConfirmationSteps(
                Boolean(value)
              );

              return (
                <>
                  <Paper
                    style={{
                      maxWidth: "30em",
                      margin: "1.5em 0 0",
                      paddingLeft: 14,
                      paddingRight: 20,
                    }}
                  >
                    <FormControl fullWidth {...{ disabled }}>
                      <FormControlLabel
                        {...{
                          label: (
                            <>
                              {/* {value ? <Check /> : <Clear />} */}
                              {label}
                            </>
                          ),
                          labelPlacement: "start",
                          style: {
                            justifyContent: "space-between",
                            marginLeft: "0",
                          },
                        }}
                        control={
                          <>
                            <Switch
                              {...rest}
                              checked={value}
                              onChange={(e: any, value: boolean) => {
                                // ^ Possibly should use hook watches, if adding other non-change-submitting fields, but for brevity this works nicely
                                setSwitchPreflight(name);
                                // onChange(value);
                              }}
                            />
                            {Array.isArray(confirmationSteps) ? (
                              <SteppedConfirmDialog
                                title={
                                  value
                                    ? deactivateDialogTitle
                                      ? deactivateDialogTitle
                                      : label
                                    : activateDialogTitle
                                      ? activateDialogTitle
                                      : label
                                }
                                steps={confirmationSteps}
                                open={switchPreflight === name}
                                confirmLabel={
                                  value
                                    ? deactivateButtonLabel
                                      ? deactivateButtonLabel
                                      : "Deactivate"
                                    : "Activate"
                                }
                                fixedHeight={dialogFixedHeight}
                                onConfirm={() => {
                                  onChange(!value); // nb: is value before change
                                  setAlert(null);
                                  handleSubmit(onSubmit)(value).catch((err) => {
                                    setAlert(
                                      ApiAlert({
                                        message:
                                          err.response?.data ||
                                          "Unspecified error",
                                        severity: "error",
                                        alwaysShow: true,
                                      })
                                    );
                                    setDisabled(false);
                                  });
                                  setSwitchPreflight(null);
                                }}
                                onClose={() => {
                                  setSwitchPreflight(null);
                                }}
                              />
                            ) : (
                              <ConfirmDialog
                                title={
                                  value
                                    ? deactivateDialogTitle
                                      ? deactivateDialogTitle
                                      : label
                                    : activateDialogTitle
                                      ? activateDialogTitle
                                      : label
                                }
                                open={switchPreflight === name}
                                onConfirm={() => {
                                  onChange(!value); // nb: is value before change
                                  setAlert(null);
                                  handleSubmit(onSubmit)(value).catch((err) => {
                                    setAlert(
                                      ApiAlert({
                                        message:
                                          err.response?.data ||
                                          "Unspecified error",
                                        severity: "error",
                                        alwaysShow: true,
                                      })
                                    );
                                    setDisabled(false);
                                  });
                                  setSwitchPreflight(null);
                                }}
                                onClose={() => {
                                  setSwitchPreflight(null);
                                }}
                                confirmLabel={
                                  value
                                    ? deactivateButtonLabel
                                      ? deactivateButtonLabel
                                      : "Deactivate"
                                    : "Activate"
                                }
                              >
                                {confirmationSteps}
                              </ConfirmDialog>
                            )}
                          </>
                        }
                      />
                    </FormControl>
                  </Paper>
                  <Box p={1} style={{ maxWidth: "60em", fontSize: "1em" }}>
                    {description}
                  </Box>
                </>
              );
            }}
          />
        )
      )}
    </form>
  );
}
