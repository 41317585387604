import React from "react";
import ReactDOM from "react-dom";
import DataClient from "../../../../lib/services/api/DataClient";
import { Trust } from "./Trust";

const PUT_TEMPLATE = "/Trust/";

export default function useUpdateTrust() {
  const [errors, setErrors] = React.useState([]);
  const [success, setSuccess] = React.useState<false | Trust>(false);

  const callback = (id: number | string, update: Trust) => {
    setSuccess(false);
    DataClient.update(`${PUT_TEMPLATE}${id}`, update)
      .then((res: unknown) => {
        ReactDOM.unstable_batchedUpdates(() => {
          if (!res["success"]) {
            throw new Error("API Update failed");
          }
          setSuccess({ ...update, id: id as number });
        });
      })
      .catch((error) => {
        ReactDOM.unstable_batchedUpdates(() => {
          console.error(error);
          setErrors((errs) => [...errs, { id, showInLeagueTable: !update.showInLeagueTable }]);
        });
      });
  };

  const clearErrors = () => {
    setErrors([]);
  };

  return [callback, errors, clearErrors, success] as const;
}
