import {
  Box,
  Grid,
  Typography,
  LinearProgress,
  Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import DataClient from "../../lib/services/api/DataClient";
interface ComplianceStats {
  compliantPercentage: number;
  exempt: number;
  pending: number;
  totalCompliant: number;
  totalNonExemptCompliant: number; // this is.. not correct
  totalNotCompliant: number;
  totalStaff: number;
  noFirstDose: number;
  onlyFirstDose: number;
  onlySecondDose: number;
}
const useStyles = makeStyles((theme) => ({
  subtitle1: {
    color: "#000",
    marginTop: 20,
    fontWeight: 800,
    fontSize: theme.spacing(4),
  },
  stats: {
    display: "flex",
    flexWrap: "wrap",
    margin: "0",
    listStyleType: "none",
    "& li": {
      width: "33.3%",
      padding: "0 1em",
      margin: "0 0 1em",
      textAlign: "center",
      borderRight: "1px solid grey",
      "&:nth-child(3n)": { borderRight: "none" },
    },
    "& strong": {
      color: theme.palette.secondary.main,
    },
  },
  mainStat: {
    color: "grey",
    "& h2": { color: "black" },
    "& strong": { color: "black", fontSize: "1.5em" },
  },
  dial: {
    width: "100%",
    maxWidth: "15em",
    fontWeight: "bold",
  },
}));
export default ({
  pathogenIds,
  label,
  trustId,
}: {
  pathogenIds: number[];
  label: string;
  trustId: number;
}) => {
  const [stats, setStats] = useState<ComplianceStats>();
  const classes = useStyles();
  useEffect(() => {
    (async () => {
      // const currentTrustId = await getCurrentTrustIdFromMe();
      setStats(
        (
          await DataClient.getData(`/DashBoard/compliance`, {
            trustId, // Does not need a selector, as there's no type of account that can view multiple (system accounts can't see staff)
            onlyFLHCW: false, // Maybe needs a toggle? wait for client request
            pathogenIds,
          })
        ).results as ComplianceStats
      );
    })();
  }, [trustId, pathogenIds]);
  return (
    <Paper elevation={0} style={{ marginBottom: "2em" }}>
      {stats ? (
        <Grid container alignItems="center">
          <Grid xs={6} sm={3} item container>
            <Box pl={7} className={classes.mainStat}>
              <Typography className={classes.subtitle1}>
                {label} Compliant Staff
              </Typography>
              <strong>{stats.totalCompliant} /</strong> {stats.totalStaff}
            </Box>
          </Grid>
          <Grid xs={6} sm={3} item>
            <Box p={3}>
              <CircularProgressbar
                // className={classes.progress}
                value={Math.round(stats.compliantPercentage)}
                text={`${Math.round(stats.compliantPercentage)}%`}
                strokeWidth={7}
                className={classes.dial}
              />
            </Box>
          </Grid>
          <Grid xs={12} sm={6} item>
            <Box p={3}>
              <ul className={classes.stats}>
                <li>
                  <p>Total Staff</p>
                  <strong>{stats.totalStaff}</strong>
                </li>
                <li>
                  <p>{label} Compliant</p>
                  <strong>{stats.totalCompliant}</strong>
                </li>
                <li>
                  <p>Not {label} Compliant</p>
                  <strong>{stats.totalNotCompliant}</strong>
                </li>
                <li>
                  <p>Exempt for {label} </p>
                  <strong>{stats.exempt}</strong>
                </li>
                <li>
                  <p>Total Pending</p>
                  <strong>{stats.pending}</strong>
                </li>
                <li>
                  <p>% Compliant Excluding Exempt</p>
                  <strong>
                    {Math.round(
                      (stats.totalCompliant /
                        (stats.totalStaff - stats.exempt)) *
                        100
                    )}
                    %
                  </strong>
                </li>
                <li>
                  <p>No 1st Covid Dose</p>
                  <strong>{stats.noFirstDose}</strong>
                </li>
                <li>
                  <p>1st Covid Dose Only</p>
                  <strong>{stats.onlyFirstDose}</strong>
                </li>
                <li>
                  <p>2nd Covid Dose Only</p>
                  <strong>{stats.onlySecondDose}</strong>
                </li>
              </ul>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <LinearProgress />
      )}
    </Paper>
  );
};
