import styles from "./CombinedTabs.module.scss";
import { enumVaccinationStatus } from "../../../../../lib/enums";
import { CampaignPathogenStatus } from "../../BookAppointment/useAppointments";
import { MyAppointment } from "../MyAppointments";
import { Tab, Tabs } from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { AppointmentFields } from "./AppointmentFields";
// import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
export default function ({
  appointment,
  campaignPathogenStatuses,
  setCurrentCombinedTabPathogen,
}: {
  appointment: MyAppointment;
  campaignPathogenStatuses: CampaignPathogenStatus[];
  setCurrentCombinedTabPathogen: React.Dispatch<number>;
  // The data here is a tricky mix of the separate campaignPathogenStatuses and the umbrella appointment itself
}) {
  const [tabValue, setTabValue] = useState(
    `${campaignPathogenStatuses?.[0]?.pathogen?.id}`
  );
  // (because the tabs plugin requires {} empty interface:)
  // eslint-disable-next-line
  const tabValueChange = (event: React.ChangeEvent<{}>, tabValue: string) => {
    setTabValue(tabValue);
    // Pass up the current open tab in a dumb kinda way, but it's for a dumb kinda feature
    setCurrentCombinedTabPathogen(parseInt(tabValue));
  };
  useEffect(() => {
    // console.log({ appointment, campaignPathogenStatuses });
    setCurrentCombinedTabPathogen(parseInt(tabValue)); // init
  }, [setCurrentCombinedTabPathogen, campaignPathogenStatuses]);
  return (
    <div className={styles.wrapper}>
      <TabContext value={tabValue}>
        <Tabs value={tabValue} onChange={tabValueChange}>
          {campaignPathogenStatuses?.map((s, i) => (
            <Tab
              label={
                <>
                  <h3 className="theme-color" data-theme-id={s.pathogen?.id}>
                    {s.pathogen?.name}
                  </h3>
                  <h4>
                    {enumVaccinationStatus[s.vaccinationStatusId]}
                    {s.vaccinationStatusId === enumVaccinationStatus.Vaccinated
                      ? " ✓"
                      : ""}
                  </h4>
                </>
              }
              value={`${s.pathogen?.id}`}
              className={styles.tabNav}
            />
          ))}
        </Tabs>
        {campaignPathogenStatuses?.map((s, i) => (
          <TabPanel value={`${s.pathogen?.id}`} className={styles.tab}>
            <AppointmentFields
              appointment={{
                ...appointment,
                ...{ status: s },
              }}
            />
          </TabPanel>
        ))}
      </TabContext>
    </div>
  );
}
