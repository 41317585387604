import React from "react";
import DataClient from "../../../../lib/services/api/DataClient";
import { TrustGroupUserData } from "./useTrustGroupUserList";

export default function useCreateTrustGroupUser() {
  const [success, setSuccess] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean | string[]>(false);
  const [warning, setWarning] = React.useState<boolean | string[]>(false);

  const callback = (user: TrustGroupUserData) => {
    setError(false);
    setSuccess(false);
    setWarning(false);
    DataClient.postData(`/TrustGroupUser`, {
      firstName: user.firstName,
      lastName: user.lastName,
      emailAddress: user.emailAddress,
      trustGroupId: user.trustGroup.id,
      proceedIfWarnings: user.proceedIfWarnings,
    })
      .then((res: unknown) => {
        if (res["success"]) {
          setSuccess(true);
        } else {
          console.log(res);
          throw new Error("API Creation failed");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 422) {
          setWarning(error.response?.data?.results);
        } else if (error?.response?.status === 409) {
          setError(
            error.response?.data?.results || error.response?.data?.errors
          );
        } else {
          console.error(error);
          setError(true);
        }
      });
  };

  return [callback, success, error, warning] as const;
}
