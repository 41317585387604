import { humanDate, humanDateTime, humanTime } from "../../lib/common_utils";
import { ChangesetType } from "./EventLogData";

/*
For the record: I know that all this mapping is a terrible idea and not in the original spec. When it breaks ¯\_(ツ)_/¯
*/

const renameKey = (oldKey: string, newKey: string, obj: any) => {
  if (
    oldKey !== newKey &&
    Object.hasOwn(obj, oldKey) &&
    !Object.hasOwn(obj, newKey)
  ) {
    Object.defineProperty(
      obj,
      newKey,
      Object.getOwnPropertyDescriptor(obj, oldKey)
    );
    delete obj[oldKey];
  }
};

const deleteKey = (key: string, obj: any) => {
  if (Object.hasOwn(obj, key)) {
    delete obj[key];
  }
};

const createKey = (key: string, value: any, obj: any) => {
  obj[key] = value;
};

const changeValue = (key: string, newValue: any, obj: any) => {
  if (Object.hasOwn(obj, key)) {
    obj[key] = newValue;
  }
};

const combineValues = (
  key1: string,
  key2: string,
  newKey: string,
  obj: any
) => {
  if (Object.hasOwn(obj, key1) && Object.hasOwn(obj, key2)) {
    obj[newKey] = `${obj[key1]} ${obj[key2]}`;
    delete obj[key1];
    delete obj[key2];
  }
};

// Turns DateTime strings into human readable strings
// Has to handle both strings and objects
const readableDateTimeStrings = (data: any) => {
  const allowedKeys = ["ArrivalDateTime", "DateChanged"];
  Object.keys(data).forEach((key) => {
    if (allowedKeys.includes(key)) {
      if (typeof data[key] == "string") {
        data[key] = humanDateTime(data[key]);
      } else {
        if (!data[key]) return;
        Object.keys(data[key]).forEach((subKey) => {
          if (typeof data[key][subKey] == "string") {
            data[key][subKey] = humanDateTime(data[key][subKey]);
          }
        });
      }
    }
  });
  return data;
};

const readableDateStrings = (data: any) => {
  const dateKeys = ["ClinicDate", "CreatedDate", "Date", "DateFrom", "DateTo"];
  Object.keys(data).forEach((key) => {
    if (dateKeys.includes(key)) {
      if (typeof data[key] == "string") {
        data[key] = humanDate(data[key]);
      } else {
        if (!data[key]) return;
        Object.keys(data[key]).forEach((subKey) => {
          if (typeof data[key][subKey] == "string") {
            data[key][subKey] = humanDate(data[key][subKey]);
          }
        });
      }
    }
  });
  return data;
};

const appointmentsStaffCanBookHandler = (details: any) => {
  if (details["ClinicType"] && details["ClinicType"].length < 2) {
    deleteKey("AppointmentsStaffCanBook", details);
  }
  const ASCB = details["AppointmentsStaffCanBook"];
  if (ASCB === true) {
    details["AppointmentsStaffCanBook"] = "Flu & Covid Together Only";
  }
  if (ASCB === false) {
    details["AppointmentsStaffCanBook"] =
      "Flu & Covid Together / Flu Only / Covid Only";
  }
};

function upcaseID(string: string): string {
  const idWords = ["Id", "id"];
  return string
    .split(" ")
    .map((word) => (idWords.includes(word) ? "ID" : word))
    .join(" ");
}

// Converts boolean values to Yes/No for both top-level booleans and 'Previous'/'Current' nested objects.
function BoolToYesNo(
  key: string,
  details: Record<string, any>
): Record<string, any> {
  function convertBool(value: any) {
    if (value === true) {
      value = "Yes";
    }
    if (value === false) {
      value = "No";
    }
    return value;
  }
  if (
    Object.hasOwn(details[key], "Current") &&
    Object.hasOwn(details[key], "Previous")
  ) {
    details[key]["Current"] = convertBool(details[key]["Current"]);
    details[key]["Previous"] = convertBool(details[key]["Previous"]);
  } else {
    convertBool(details[key]);
  }

  return details;
}

export const changeTypeToNameMapper = (type: ChangesetType): string => {
  switch (type) {
    case ChangesetType.CreateReservation:
      return "BookAppointment";
    case ChangesetType.CancelReservation:
      return "CancelAppointment";
    case ChangesetType.RescheduleReservation:
      return "RescheduleAppointment";
    default:
      return ChangesetType[type];
  }
};

export const clinicEventDetailsMapper = (changeset: {
  type: ChangesetType;
  data: any;
}): { type: ChangesetType; data: any } => {
  Object.keys(changeset.data).map((key) => {
    let details = changeset.data[key];
    details = applyGlobalMappings(details);
    details = applyMappingsByType(changeset.type, details);
    return details;
  });
  // console.log(`changeset.data: `, changeset.data);
  // detailsList.forEach((details) => {
  //   details = readableDateStrings(details);
  //   console.log(`details: `, details);
  // });
  return changeset;
};

/**
 * Renames and deletes keys for all changeset.data objects based to make the output object more readable for the user
 */
const applyGlobalMappings = (details: any): any => {
  details = readableDateStrings(details);
  details = readableDateTimeStrings(details);

  if (details["Clinics"] && Array.isArray(details["Clinics"])) {
    details["Clinics"]?.map((clinic: any) => {
      return readableDateStrings(clinic);
    });
  }
  renameKey("Pathogens", "ClinicType", details);
  renameKey("ClinicTypeName", "BookingModel", details);
  renameKey("SlotDuration", "AppointmentDuration (mins)", details);
  // renameKey("TrustId", "TrustID", details);
  // renameKey("Trust", "TrustID", details);
  renameKey("NumberOfSpaces", "NumberOfVaccinators", details);
  renameKey("RecurrenceName", "RecurringClinic", details);
  renameKey("RepeatOnDays", "RepeatOnTheseDays", details);
  renameKey("RequireBookingAllPathogens", "AppointmentsStaffCanBook", details);
  renameKey("ArrivalDateTime", "AppointmentDate &Time", details);
  renameKey("HospitalSiteName", "Site", details);
  renameKey("ClinicLocationName", "Clinic Location", details);
  renameKey("TrustWorkerId", "StaffMemberId", details);
  renameKey("TrustWorkerEmailAddress", "StaffMemberEmailAddress", details);
  renameKey("NumberOfDates", "NumberOfClinicsInSeries", details);
  renameKey("NumberOfTimeslots", "TotalSlots", details);
  renameKey(
    "MaximumNumberOfDropInAllowed",
    "MaximumNumberOfBookableDropIns",
    details
  );
  renameKey("CreateReservation", "BookAppointment", details);
  renameKey("RescheduleReservation", "RescheduleAppointment", details);
  renameKey("CancelReservation", "CancelAppointment", details);
  renameKey("ManuallyAddStaff", "ManuallyAddStaffButton", details);

  combineValues(
    "TrustWorkerFirstName",
    "TrustWorkerLastName",
    "StaffMember",
    details
  );

  // deleteKey("ClinicId", details);
  deleteKey("RecurringClinic", details);
  deleteKey("ClinicTypeId", details);
  deleteKey("RecurrenceId", details);
  deleteKey("recurrenceId", details);
  deleteKey("HospitalSiteId", details);
  deleteKey("ClinicLocationId", details);
  deleteKey("CampaignId", details);
  deleteKey("CampaignName", details);
  appointmentsStaffCanBookHandler(details);
  if (Array.isArray(details["ClinicType"])) {
    details["ClinicType"].forEach((clinicType) => {
      deleteKey("Id", clinicType);
    });
  }
  return details;
};

const applyMappingsByType = (type: ChangesetType, details: any): any => {
  if (type == ChangesetType.CreateSingleClinic) {
    deleteKey("Id", details);
    deleteKey("DateFrom", details);
    deleteKey("DateTo", details);
    deleteKey("RecurrenceId", details);

    // Move ClincId up from nested position
    if (Array.isArray(details["Clinics"]) && details["Clinics"][0]["Id"]) {
      createKey("ClinicId", details["Clinics"][0]["Id"], details);
    }
    deleteKey("Clinics", details);
  }
  if (
    type == ChangesetType.CreateSingleClinic ||
    type == ChangesetType.CreateRecurringClinc ||
    type == ChangesetType.UpdateSingleClinic ||
    type == ChangesetType.UpdateRecurringClinic
  ) {
    // Move ClinicType to top level
    if (Array.isArray(details["ClinicType"])) {
      const clinicTypes = details["ClinicType"].map(
        (clinicType: any) => clinicType["Name"]
      );
      const newValue = clinicTypes.join(", ");
      changeValue("ClinicType", newValue, details);
    }
    // Map Booking model text
    if (details["BookingModel"] == "Walk around clinic.") {
      changeValue(
        "BookingModel",
        "Walk around (not visible for staff booking)",
        details
      );
    }
    if (details["BookingModel"] == "Bookings with drop ins") {
      changeValue(
        "BookingModel",
        "Bookable time slots plus bookable drop-ins",
        details
      );
    }
    if (details["BookingModel"] == "Bookings only") {
      changeValue("BookingModel", "Bookable time slots", details);
    }
    if (details["ManuallyAddStaffButton"]) {
      BoolToYesNo("ManuallyAddStaffButton", details);
    }
  }
  if (type == ChangesetType.UpdateSingleClinic) {
    if (
      typeof details["DateFrom"] == "string" ||
      typeof details["DateTo"] == "string" ||
      typeof details["TimeFrom"] == "string" ||
      typeof details["TimeTo"] == "string"
    ) {
      return;
    }
    if (details["DateFrom"]?.Previous && details["DateFrom"]?.Current) {
      changeValue("DateFrom", humanDate(details["DateFrom"].Previous), details);
      changeValue("DateTo", humanDate(details["DateTo"].Current), details);
    }
    if (details["TimeFrom"]?.Previous && details["TimeFrom"]?.Current) {
      changeValue("TimeFrom", humanTime(details["TimeFrom"].Previous), details);
      changeValue("TimeTo", humanTime(details["TimeTo"].Current), details);
    }
  }
  if (type == ChangesetType.CreateRecurringClinc) {
    deleteKey("Id", details);
    renameKey("TimeFrom", "StartTime", details);
    renameKey("TimeTo", "EndTime", details);
    // Rename true/false to Yes/No
    if (!details["Clinics"].length) return;
    details["Clinics"].map((clinic: any) => {
      deleteKey("BookingSuspended", clinic);
      deleteKey("IsBookingSuspended", clinic);
      return clinic;
    });
  }
  if (type == ChangesetType.UpdateRecurringClinic) {
    renameKey("DateTo", "Date", details);
    renameKey("TimeTo", "Time", details);
  }
  if (
    type == ChangesetType.CreateRecurringClinc ||
    type == ChangesetType.CreateSingleClinic
  ) {
    // Rename true/false to Yes/No
    if (details["Is24HourClinic"] == false) {
      details["Is24HourClinic"] = "No";
    } else {
      details["Is24HourClinic"] = "Yes";
    }
    renameKey("Is24HourClinic", "Is 24HourClinic", details);
    deleteKey("Is24HourClinic", details);
  }
  if (
    type == ChangesetType.CreateReservation ||
    type == ChangesetType.CancelReservation ||
    type == ChangesetType.RescheduleReservation
  ) {
    renameKey("Id", "Appointment id", details);
  }
  const updateBookingSuspended = (clinic: any) => {
    renameKey("IsBookingSuspended", "BookingSuspended", clinic);
    if (!clinic["BookingSuspended"]) return;
    if (clinic["BookingSuspended"] === false) {
      clinic["BookingSuspended"] = "No";
    } else {
      clinic["BookingSuspended"] = "Yes";
    }
  };
  if (
    type == ChangesetType.SplitClinic ||
    type === ChangesetType.CreateRecurringClinc ||
    type === ChangesetType.CancelRecurringClinic
  ) {
    // Rename true/false to Yes/No
    if (!details["Clinics"].length) return;
    details["Clinics"].forEach((clinic: any) => {
      updateBookingSuspended(clinic);
    });
  }

  if (type === ChangesetType.UpdateRecurringClinic) {
    console.log(`details: `, details);
    if (!details["Clinics"]) return;
    details["Clinics"]["Current"].forEach((clinic: any) => {
      updateBookingSuspended(clinic);
      renameKey("BookingSuspended", "Booking Suspended", clinic);
      deleteKey("BookingSuspended", clinic);
      readableDateStrings(clinic);
    });
    details["Clinics"]["Previous"] &&
      details["Clinics"]["Previous"].forEach((clinic: any) => {
        updateBookingSuspended(clinic);
        renameKey("BookingSuspended", "Booking Suspended", clinic);
        deleteKey("BookingSuspended", clinic);
        readableDateStrings(clinic);
      });
  }

  if (
    type == ChangesetType.SuspendClinic ||
    type == ChangesetType.EnableClinic
  ) {
    // Rename true/false to Yes/No
    const IbsObj = details["IsBookingSuspended"];
    if (!IbsObj) return;
    Object.keys(IbsObj).forEach((key: any) => {
      if (IbsObj[key] == false) {
        IbsObj[key] = "No";
      } else {
        IbsObj[key] = "Yes";
      }
    });
    renameKey("IsBookingSuspended", "BookingSuspended", details);
  }
  return details;
};
