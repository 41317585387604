import React, { useState, useEffect, useRef } from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import DashboardApi from "../DashboardApi";
import { ChartColors } from "./ChartConstants";

export const DeclineReasonsChart = (props: any) => {
  const api = DashboardApi;

  const [state, setState] = useState({
    series: [],
    options: {
      chart: {
        type: "pie",
        toolbar: {
          show: false,
        },
      },
      legend: {
        position: "bottom",
      },
      plotOptions: {},
      colors: ChartColors,
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        style: {
          colors: ["#fff"],
        },

        offsetX: 0,
        dropShadow: {
          enabled: true,
        },
      },
      tooltip: {
        x: {
          show: false,
        },
      },
    } as ApexOptions,
  });
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState([]);
  const [options, setChartOptions] = useState(state.options);
  const [loading, setLoading] = useState(false);
  const fetchResults = () => {
    setLoading(true);
    api
      .declineReasons(
        props.pathogenIds,
        props.onlyFLHCW,
        props.trustId,
        props.excludeL1Ids
      )
      .then((result: any) => {
        setChartOptions({
          ...options,
          ...{ labels: result.labels },
        });
        setChartData(result.data);
      })
      // .catch((e) => {
      //   if (!e.handled) {
      //   }
      // })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    setChartData([]);
    fetchResults();
  }, [props]);

  return (
    <Chart
      ref={chartRef}
      options={{
        ...options,
      }}
      series={chartData}
      type="pie"
      height={490}
    />
  );
};
