import {
  Box,
  FormControlLabel,
  Paper,
  Switch,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { Pathogens } from "../../../../lib/pathogens";
import { Dispatch, useEffect, useState } from "react";
import { omit, uniq } from "lodash";
import { enumVaccinationStatus } from "../../../../lib/enums";
import { font1 } from "../../../../lib/themes/vars";
enum EnumComplianceFilters {
  A = "isFrontline",
  B = "isImmform",
  C = "isActive",
  D = "isNewStarter",
  E = "excludeVaccinationStatusesFlu",
  F = "excludeVaccinationStatusesCovid",
  G = "hasFirstCovidDate",
  H = "hasSecondCovidDate",
  I = "vaccinationStatusIdCovid",
  J = "vaccinationStatusIdFlu",
  K = "hasNotBothCovidDates",
  L = "TrustWorkerPathogens",
  // ^ letters are inconsequential
}
type ComplianceFilters = {
  [P in `${EnumComplianceFilters}`]?: any;
};
// Can't quite decide whether to use an enum->type, or type (no iteration) or object->type (no optional values)
// const complianceFilters = {
//   hasFirstCovidDate: false,
//   hasSecondCovidDate: false,
// };
// type ComplianceFilters = typeof complianceFilters;
const filterOptionsHidden = {
  IsActive: true,
};
enum filterOptionIds {
  "notCovidCompliant_tab",
  "no1stCovidDose_tab",
  "firstCovidDoseOnly_tab",
  "secondCovidDoseOnly_tab",
  "firstAndSecondCovidDose_tab",
  "exemptForCovid_tab",
  "exemptForFlu_tab",
  "newStarters_tab",
  "OnlyFLHC_toggle",
  "HideExemptCovid_toggle",
  "HideExemptFlu_toggle",
  "NewStarters_toggle",
}
type FilterOption = {
  id: filterOptionIds;
  label: string;
  // Visibly hide tabs or toggles
  filterOptionsToHide?: filterOptionIds[];
  // Remove filter from the query
  filtersToRemove?: string[];
  filters?: ComplianceFilters;
  radioSet?: 1;
  active?: boolean;
};
const filterOptionsGeneric: FilterOption[] = [
  {
    id: filterOptionIds.notCovidCompliant_tab,
    label: "Not Covid Compliant",
    radioSet: 1,
    filterOptionsToHide: [filterOptionIds.HideExemptFlu_toggle],
    filtersToRemove: ["excludeVaccinationStatusesFlu"],
    filters: {
      TrustWorkerPathogens: [Pathogens.Covid],
      hasNotBothCovidDates: true,
    },
    active: false,
  },
  {
    id: filterOptionIds.no1stCovidDose_tab,
    label: "No 1st Covid Dose",
    radioSet: 1,
    filterOptionsToHide: [filterOptionIds.HideExemptFlu_toggle],
    filtersToRemove: ["excludeVaccinationStatusesFlu"],
    filters: {
      TrustWorkerPathogens: [Pathogens.Covid],
      hasFirstCovidDate: false,
    },
    active: true,
  },
  {
    id: filterOptionIds.firstCovidDoseOnly_tab,
    label: "1st Covid Dose Only",
    radioSet: 1,
    filterOptionsToHide: [filterOptionIds.HideExemptFlu_toggle],
    filtersToRemove: ["excludeVaccinationStatusesFlu"],
    filters: {
      TrustWorkerPathogens: [Pathogens.Covid],
      hasFirstCovidDate: true,
      hasSecondCovidDate: false,
    },
    active: false,
  },
  {
    id: filterOptionIds.secondCovidDoseOnly_tab,
    label: "2nd Covid Dose Only",
    radioSet: 1,
    filterOptionsToHide: [filterOptionIds.HideExemptFlu_toggle],
    filtersToRemove: ["excludeVaccinationStatusesFlu"],
    filters: {
      TrustWorkerPathogens: [Pathogens.Covid],
      hasFirstCovidDate: false,
      hasSecondCovidDate: true,
    },
    active: false,
  },
  {
    id: filterOptionIds.firstAndSecondCovidDose_tab,
    label: "1st & 2nd Covid Dose",
    radioSet: 1,
    filterOptionsToHide: [filterOptionIds.HideExemptFlu_toggle],
    filtersToRemove: ["excludeVaccinationStatusesFlu"],
    filters: {
      TrustWorkerPathogens: [Pathogens.Covid],
      hasFirstCovidDate: true,
      hasSecondCovidDate: true,
    },
    active: false,
  },
  {
    id: filterOptionIds.firstAndSecondCovidDose_tab,
    label: "No Covid Booster 2", // or whatever the current booster is, i.e. basically a status of vaccinated. NB: booster 2 is not 'spring booster 2022' (that's 1.5)
    radioSet: 1,
    filterOptionsToHide: [filterOptionIds.HideExemptFlu_toggle],
    filtersToRemove: ["excludeVaccinationStatusesFlu"],
    filters: {
      TrustWorkerPathogens: [Pathogens.Covid],
      excludeVaccinationStatusesCovid: [
        enumVaccinationStatus["Vaccinated"],
        enumVaccinationStatus["Had Elsewhere"],
        enumVaccinationStatus["Had In Another Trust"],
      ],
    },
    active: false,
  },
  {
    id: filterOptionIds.exemptForCovid_tab,
    label: "Exempt for Covid",
    radioSet: 1,
    filterOptionsToHide: [
      filterOptionIds.HideExemptCovid_toggle,
      filterOptionIds.HideExemptFlu_toggle,
    ],
    filtersToRemove: [
      "excludeVaccinationStatusesCovid",
      "excludeVaccinationStatusesFlu",
    ],
    filters: {
      TrustWorkerPathogens: [Pathogens.Covid],
      vaccinationStatusIdCovid: [enumVaccinationStatus.Exempt],
    },
    active: false,
  },
  {
    id: filterOptionIds.exemptForFlu_tab,
    label: "No Flu Dose",
    radioSet: 1,
    filterOptionsToHide: [filterOptionIds.HideExemptCovid_toggle],
    filtersToRemove: ["excludeVaccinationStatusesCovid"],
    filters: {
      TrustWorkerPathogens: [Pathogens.Flu],
      excludeVaccinationStatusesFlu: [
        enumVaccinationStatus["Vaccinated"],
        enumVaccinationStatus["Had Elsewhere"],
        enumVaccinationStatus["Had In Another Trust"],
      ],
    },
    active: false,
  },
  {
    id: filterOptionIds.newStarters_tab,
    label: "New Starters (Pending)",
    radioSet: 1,
    filterOptionsToHide: [filterOptionIds.NewStarters_toggle],
    filters: { isNewStarter: true },
    active: false,
  },
  {
    id: filterOptionIds.OnlyFLHC_toggle,
    label: "Only FLHCW",
    filters: { isFrontline: true },
    active: false,
  },
  {
    id: filterOptionIds.HideExemptCovid_toggle,
    label: "Hide Exempt for Covid",
    filters: {
      excludeVaccinationStatusesCovid: [enumVaccinationStatus.Exempt],
    },
    active: true,
  },
  {
    id: filterOptionIds.HideExemptFlu_toggle,
    label: "Hide Exempt for flu",
    filters: {
      excludeVaccinationStatusesFlu: [enumVaccinationStatus.Exempt],
    },
    active: true,
  },
  {
    id: filterOptionIds.NewStarters_toggle,
    label: "New Starters (Pending)",
    filters: { isNewStarter: true },
    active: false,
  },
];
export default ({
  pathogenIds,
  filter,
  setFilter,
  isLoading,
}: {
  pathogenIds: number[];
  filter: Record<string, any>;
  setFilter: Dispatch<any>;
  isLoading: boolean;
}) => {
  if (!(pathogenIds.includes(Pathogens.Covid) && pathogenIds.length === 1)) {
    return console.log(
      "Not currently configured for pathogens other than Covid Alone"
      // (the unwanted sequel to Home Alone)
    );
  }
  const [filterOptions, setFilterOptions] = useState(
    // pathogenIds.includes(Pathogens.Covid) ? filterOptionsCovid : []
    // dd#2121 No longer delineating between flu and covid compliance tabs
    filterOptionsGeneric
  );
  const triggerSearch = () => {
    // Try and merge these filters intelligently with each other and any current ones, such as page size (or even role, as we may end up applying this to the main staff page) by wiping these compliance filters from the parent set before applying our merged current active ones
    const complianceFilterKeys = Object.values(EnumComplianceFilters);
    // const filtersActive = merge(
    //   {},
    //   ...filterOptions.filter((o) => o.active).map((o) => o.filters)
    // );
    // Even _more_ intelligently merge filters, to allow array values such as excludeVaccinationStatusesCovid to concatenate (e.g. 1st & 2nd Covid dose AND Hide Exempt)
    const filtersActive = {};
    filterOptions
      .filter((o) => o.active)
      .map((o) => o.filters)
      .forEach((f) => {
        for (const [key, value] of Object.entries(f)) {
          if (filtersActive[key] && Array.isArray(filtersActive[key])) {
            filtersActive[key] = [...filtersActive[key], ...value];
          } else {
            filtersActive[key] = value;
          }
        }
      });
    // console.log("filtersActive", filtersActive);
    const filtersToRemoveActive = Object.assign(
      [],
      ...filterOptions.filter((o) => o.active).map((o) => o.filtersToRemove)
    );
    let filterNew: any = {
      ...filterOptionsHidden,
      ...omit(filter, complianceFilterKeys),
      ...filtersActive,
    };
    // console.log("filterNew", filterNew);
    // Remove any filtersToRemove from current set of filters, for hide-such-and-such negative filter toggles kinda thing
    filterNew = omit(filterNew, filtersToRemoveActive);
    setFilter({ ...filterNew, pageNumber: 1 });
  };
  // Trigger initial search, with whatever filters are set to active
  useEffect(() => {
    triggerSearch();
  }, []);
  const findActiveFilterOption = (radioSet = 1) =>
    filterOptions
      .filter((o) => o.radioSet === radioSet)
      .findIndex((o) => o.active);
  const setTabValue = (i: number, radioSet = 1) => {
    setFilterOptions(
      filterOptions.map((o, j) => {
        if (o.radioSet === radioSet) {
          o.active = i === j;
        }
        return o;
      })
    );
  };
  // These change functions are less complicated than they look; we basically end up with the filterOptions collection as above but with values true/false. However, for those in radioSet:1, the other values are set false as.. there can be only one!
  const tabChange =
    (f: FilterOption) => (e: React.ChangeEvent<HTMLInputElement>) => {
      // Update the visual tab selection
      setTabValue(filterOptions.indexOf(f));
      // Update our local filter state and wipe other true values for radioSet 1
      setFilterOptions(
        filterOptions.map((o) => {
          o.active = o === f ? true : o.radioSet === 1 ? false : o.active;
          return o;
        })
      );
      setFilterOptions(filterOptions);
      triggerSearch();
    };
  const toggleChange =
    (f: FilterOption) =>
    (e: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
      // Update our local state with value true/false
      setFilterOptions(
        filterOptions.map((o) => {
          o.active = o === f ? value : o.active;
          return o;
        })
      );
      triggerSearch();
    };
  const hideFilterOptionsToHide = (f: FilterOption) => {
    const allFilterOptionsToHide = uniq(
      [].concat(
        ...filterOptions
          .filter((f) => f.active && f.filterOptionsToHide)
          .map((f) => f.filterOptionsToHide),
        []
      )
    );
    // console.log(
    //   "::allFilterOptionsToHide",
    //   JSON.stringify(allFilterOptionsToHide.map((o) => filterOptionIds[o]))
    // );
    return !allFilterOptionsToHide.includes(f.id);
  };
  return (
    <>
      <Paper elevation={0} style={{ background: "white", marginBottom: "0" }}>
        <Tabs
          value={findActiveFilterOption()}
          aria-label="Filters"
          textColor="secondary"
        >
          {filterOptions
            ?.filter((f) => f.radioSet === 1)
            ?.map((f) => (
              <Tab
                label={f.label}
                onClick={tabChange(f)}
                style={{
                  minWidth: "0",
                  padding: "0 1em",
                  textTransform: "none",
                  fontFamily: font1,
                }}
                disabled={isLoading}
              />
            ))}
        </Tabs>
      </Paper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: ".75em",
        }}
      >
        {filterOptions
          ?.filter((f) => !f.radioSet)
          ?.filter(hideFilterOptionsToHide)
          ?.map((f) => (
            <FormControlLabel
              control={<Switch onChange={toggleChange(f)} checked={f.active} />}
              disabled={isLoading}
              label={
                <Typography
                  style={{
                    fontSize: "0.875rem",
                    fontFamily: font1,
                  }}
                >
                  {f.label}
                </Typography>
              }
              style={{ margin: "0 .5em" }}
            />
          ))}
      </Box>
    </>
  );
};
