import { Grid, LinearProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ApexOptions } from "apexcharts";
import { useCallback, useEffect, useMemo, useState } from "react";
import Chart from "react-apexcharts";
import { PathogenSelectOptions, pathogenColor } from "../../../lib/pathogens";
import { getCurrentTrustIdFromMe } from "../../../lib/services/TrustSwitcher";
import DataClient from "../../../lib/services/api/DataClient";

const madeStyles = makeStyles((theme) => ({
  loadingProgress: {
    padding: 0,
    width: "100%",
    marginTop: 7,
    height: 0,
  },
}));

interface PropTypes {
  excludeL1Ids: string;
  pathogenSelectOptions: PathogenSelectOptions;
}
interface TLChartDataPoint {
  id: number;
  name: string;
  staff: number;
  vaccinations: number;
}

function zerodPercentage(vaccinations: number, staff: number) {
  if (staff <= 0 || vaccinations <= 0) return 0;
  console.log(`vaccinations: `, vaccinations);
  console.log(`staff: `, staff);
  const percentage = (vaccinations / staff) * 100;
  return Math.round(percentage);
}

export function TrustsLeagueChart({
  excludeL1Ids,
  pathogenSelectOptions,
}: PropTypes) {
  const highlightedFillColor = pathogenColor(pathogenSelectOptions);

  const createSeriesFromData = (
    chartData: TLChartDataPoint[],
    trustId?: number,
  ): ApexAxisChartSeries => {
    const series = {
      name: "TLChartData",
      data: chartData
        .map((chartDataPoint) => ({
          x: chartDataPoint.name,
          y: zerodPercentage(chartDataPoint.vaccinations, chartDataPoint.staff),
          fillColor:
            chartDataPoint.id === trustId ? highlightedFillColor : "#283583",
        }))
        .sort((a, b) => b.y - a.y),
    };
    return [series];
  };

  const classes = madeStyles();

  const chartOptions = useMemo(() => tableOptionsBase(), []);

  const [chartSeries, setChartSeries] = useState<ApexAxisChartSeries>(() =>
    createSeriesFromData([{ id: 0, name: "", vaccinations: 0, staff: 10 }]),
  );
  const [loading, setLoading] = useState<boolean>(true);

  const getChartData = useCallback(async () => {
    setLoading(true);

    try {
      const trustId = await getCurrentTrustIdFromMe();
      const response = await DataClient.getData(
        "Dashboard/trust-league-table",
        {
          trustId,
          excludeL1: excludeL1Ids,
          pathogenIds: pathogenSelectOptions,
        },
      );
      const data = response?.success ? response?.results?.rows : [];
      const series = createSeriesFromData(data, trustId);
      setChartSeries(series);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [excludeL1Ids, pathogenSelectOptions]);

  useEffect(() => {
    getChartData();
  }, [getChartData]);

  return (
    <Grid>
      {loading ? (
        <Grid className={classes.loadingProgress}>
          <LinearProgress color="secondary" aria-busy={loading} />
        </Grid>
      ) : (
        <Chart options={chartOptions} series={chartSeries} type="bar" />
      )}
    </Grid>
  );
}

const tableOptionsBase = (): ApexOptions => {
  return {
    chart: {
      width: "90%",
      type: "bar",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    legend: {
      position: "right",
    },
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 0.35,
        },
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "90%",
        horizontal: true,
        dataLabels: {
          position: "bottom",
        },
      },
    },
    xaxis: {
      position: "top",
      type: "category",
      labels: {
        // trim: true,
        // hideOverlappingLabels: false,
        formatter: function (val: string, opt: any) {
          return val + "%";
        },
      },
      max: 100,
    },
    yaxis: {
      labels: {
        align: "left",
        maxWidth: 1000,
        offsetX: -10,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            show: true,
            position: "left",
          },
        },
      },
    ],
    dataLabels: {
      enabled: false,
      textAnchor: "start",
      style: {
        colors: ["#fff"],
      },
      formatter: function (val: any, opt: any) {
        return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
      },
      offsetX: 0,
      dropShadow: {
        enabled: true,
      },
    },
    tooltip: {
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function () {
            return "";
          },
        },
        formatter: function (val: number) {
          return val + "%";
        },
      },
    },
  };
};
