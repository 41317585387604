import { Button, DialogActions } from "@material-ui/core";
import { KeyboardBackspace } from "@material-ui/icons";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import KeyboardDatePickerDateOnly from "../../../../../components/KeyboardDatePickerDateOnly";
import { Pathogens, VaccinationType } from "../../../../../lib/pathogens";
import DataClient from "../../../../../lib/services/api/DataClient";
import { MasterVaccineBatch } from "./List";

export type MasterVaccineBatchFieldValues = {
  pathogenId: number;
  vaccinationTypeId: number;
  vaccinationBatchNumber: string;
  expiryDate: Date;
};

const ErrorMessage: FunctionComponent<{ message?: any }> = ({ message }) => {
  if (!message) {
    return null;
  }

  return (
    <p className="error" style={{ height: 0 }}>
      {message}
    </p>
  );
};

interface MasterVaccineBatchesFormProps {
  pathogenId: number;
  masterVaccineBatch?: MasterVaccineBatch;
  isSubmitting: boolean;
  onSubmit: (fieldValues: MasterVaccineBatchFieldValues) => void;
}

export const MasterVaccineBatchesForm: FunctionComponent<
  MasterVaccineBatchesFormProps
> = ({ pathogenId, masterVaccineBatch, isSubmitting, onSubmit }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [vaccinationTypes, setVaccinationTypes] = useState<VaccinationType[]>(
    [],
  );

  const { control, formState, handleSubmit, register, setValue } =
    useForm<MasterVaccineBatchFieldValues>({
      defaultValues: {
        pathogenId,
        vaccinationTypeId: masterVaccineBatch?.vaccinationTypeId,
        vaccinationBatchNumber: masterVaccineBatch?.vaccinationBatchNumber,
        expiryDate: masterVaccineBatch?.expiryDate,
      },
    });
  const { errors } = formState;

  const fetchVaccinationTypes = useCallback(async () => {
    setIsLoading(true);

    try {
      const { results: vaccinationTypes, success } = await DataClient.postData(
        `/VaccinationType/Search`,
        {
          pageSize: -1,
          pathogens: [pathogenId],
        },
      );

      if (!success) {
        throw new Error();
      }

      setVaccinationTypes(vaccinationTypes);

      if (masterVaccineBatch) {
        setValue("vaccinationTypeId", masterVaccineBatch.vaccinationTypeId);
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    pathogenId,
    masterVaccineBatch,
    setIsLoading,
    setVaccinationTypes,
    setValue,
  ]);

  useEffect(() => {
    fetchVaccinationTypes();
  }, [fetchVaccinationTypes]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form">
      <section>
        <label>Vaccine Type</label>
        <select
          {...register("vaccinationTypeId", {
            valueAsNumber: true,
            required: "Please enter a vaccine type",
          })}
          disabled={isLoading}
        >
          <option value="">Please Select</option>
          {vaccinationTypes.map((vaccinationType) => (
            <option key={vaccinationType.id} value={vaccinationType.id}>
              {vaccinationType.name}
            </option>
          ))}
        </select>
        <ErrorMessage message={errors.vaccinationTypeId?.message} />
      </section>
      <section>
        <label>Batch Number</label>
        <input
          style={{ textTransform: "uppercase" }}
          {...register("vaccinationBatchNumber", {
            required: "Please enter a batch number",
            // pattern: {
            //   value: /^[0-9\s]*$/,
            //   message: "Please only enter numbers and spaces.",
            // },
          })}
        />
        <ErrorMessage message={errors.vaccinationBatchNumber?.message} />
      </section>

      <section>
        <label>Expiry Date (DD/MM/YYYY)</label>
        <Controller
          name="expiryDate"
          control={control}
          rules={{ required: "Please enter an expiry date" }}
          defaultValue={null}
          render={({ field }) => {
            return (
              <KeyboardDatePickerDateOnly
                {...field}
                inputVariant="outlined"
                InputLabelProps={{ shrink: true }}
                autoOk
                format="DD/MM/yyyy"
              />
            );
          }}
        />
        <ErrorMessage message={errors.expiryDate?.message} />
      </section>

      {pathogenId === Pathogens.Covid ? (
        <p>
          Please note: When vaccinators add a Covid master vaccine batch into
          their clinic, they will still need to enter the defrost date and
          person drawing up. This is because this information will vary from
          clinic to clinic.
        </p>
      ) : null}

      <DialogActions>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          autoFocus
          disabled={isSubmitting}
        >
          Save
        </Button>
        <Button
          component={Link}
          to={`/settings/master-vaccine-batches`}
          variant="outlined"
          color="secondary"
          style={{
            color: "#fff",
          }}
          startIcon={<KeyboardBackspace />}
          disabled={isSubmitting}
        >
          Back
        </Button>
      </DialogActions>
    </form>
  );
};
