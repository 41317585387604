import axios from "axios";
import {
  ApplicationPaths,
  QueryParameterNames,
} from "../../components/api-authorization/ApiAuthorizationConstants";
import { BASE_API_URL } from "./constants";
import authService from "../../components/api-authorization/AuthorizeService";
import { getSelectedTrust } from "../../lib/services/TrustSwitcher";
import qs from "qs";

export const request = (options) => {
  const cancelTokenSource = axios.CancelToken.source();
  const client = axios.create({
    baseURL: BASE_API_URL,
    cancelToken: cancelTokenSource.token,
  });
  client.defaults.paramsSerializer = (params) => {
    if (params instanceof URLSearchParams) {
      return params;
    }

    return qs.stringify(params, { indices: false });
  };

  // Request interceptor for API calls
  client.interceptors.request.use(
    async (config) => {
      const token = await authService.getAccessToken();
      const selectedTrust = getSelectedTrust();

      config.headers = {
        ...(token && { Authorization: `Bearer ${token}` }),
        ...(selectedTrust && { trustid: selectedTrust.toString() }),
        ...{
          ...(options?.headers?.trustId && {
            trustid: options.headers.trustId.toString(),
          }),
        },
        Pragma: "no-cache",
      };
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
  return {
    client: client(options)
      .then((response) => response.data)
      .catch((error) => {
        if (axios.isCancel(error)) return; // chill
        if (error?.response?.status === 401) {
          window.location.href = `${ApplicationPaths.Login}?${
            QueryParameterNames.ReturnUrl
          }=${encodeURI(window.location.href)}`;
          return Promise.reject({ ...error, handled: true });
        }
        throw error;
      }),
    cancelTokenSource,
  };
};

export const catchFormErrors = (error) => {
  const response = error.response;
  // Handle server validation errors for form;
  if (response?.status === 400 && Array.isArray(response.data)) {
    // transform response into error messages object
    const errors = response.data.reduce((o, i) => {
      const n = `${i.propertyName[0].toLowerCase()}${i.propertyName.substr(1)}`;
      o[n] = i.errorMessage;
      return o;
    }, {});
    console.warn(errors);
    return { ...response, errors };
  }
  // Handle 409 server errors explicitly dd-3110
  // Caught in StaffValidation.ts: staffCreateEditSubmit()
  if (response?.status === 409 && response.data.summary) {
    throw { ...response, errors: response.data.summary };
  }

  throw error;
};

export default request;
