import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ToastMessage from "../../../../components/Universal/toast_message";
// import EditNotificationForm from "./EditNotificationForm";
// import useTemplate from "./useTemplate";
import NavContainer from "../NavContainer";
import EditTemplateForm from "../../TrustAdmin/Settings/Templates/EditTemplateForm";
import { Feedback } from "../../TrustAdmin/Settings/SettingsContainer";
export default function DefaultNotificationEdit() {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [feedback, setFeedback] = useState<Feedback>(null);
  // React.useEffect(() => {
  //   if (error) {
  //     setFeedback({
  //       type: "error",
  //       message: "There was a problem loading the default notification.",
  //     });
  //   }
  // }, [error]);
  return (
    <>
      <NavContainer>
        <>
          <EditTemplateForm
            id={Number(id)}
            editingSuperAdminTemplate={true}
            fetchUrl={`/NotificationTemplate/${id}`}
            // loading={loading}
            onCancel={() => {
              history.push(`/settings/templates`);
            }}
            onUpdate={() => {
              setFeedback({
                type: "success",
                message: "Notification template updated",
              });
            }}
            onError={() =>
              setFeedback({ type: "error", message: "Failed saving record" })
            }
            setFeedback={setFeedback}
          />
        </>
        {feedback && (
          <ToastMessage
            variant={feedback.type}
            message={feedback.message}
            clear={() => {
              setFeedback(null);
            }}
          />
        )}
      </NavContainer>
    </>
  );
}
