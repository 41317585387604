import {
  Box,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Tooltip,
} from "@material-ui/core";
import {
  Add as AddIcon,
  ArrowDownward,
  ArrowUpward,
  Cancel as CancelIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Save as SaveIcon,
} from "@material-ui/icons";
import { useCallback, useEffect, useMemo, useState } from "react";
import ReactDom from "react-dom";
import { TextFieldWithCharacterCount } from "../../../../../components/TextFieldWithCharacterCount";
import ToastMessage from "../../../../../components/Universal/toast_message";
import { Pathogens } from "../../../../../lib/pathogens";
import DataClient from "../../../../../lib/services/api/DataClient";
import ConfirmDialog from "../../../ConfirmDialog";
import { Feedback } from "../SettingsContainer";

interface Question {
  id: number;
  question?: string;
  name?: string; // TrustOrganisationOption is super similar but 'name' key, instead of 'question'. So we'll quickly mutate the key on pull/push: search useKeyOfName
  pathogens: Pathogens[];
}

const QuestionList = ({
  baseUrl,
  title,
  titleFull,
  pathogens,
  successMessage,
  deletedMessage,
}: {
  baseUrl: string;
  title: string;
  titleFull: string;
  pathogens: Pathogens[];
  successMessage?: string;
  deletedMessage?: string;
}) => {
  const api = useMemo(
    () => ({
      list: () =>
        DataClient.postData(`${baseUrl}/Search`, {
          pageSize: -1,
          pathogens,
        }).then((res) => res.results.pagedResults as Question[]),
      add: (item: Question) =>
        DataClient.postData(baseUrl, { ...item, pathogens }),
      update: (item: Question) =>
        DataClient.update(`${baseUrl}/${item.id}`, { ...item, pathogens }),
      delete: (id: number) => DataClient.remove({ url: `${baseUrl}/${id}` }),
      move: (id: number, belowId: number) =>
        DataClient.update(`${baseUrl}/MoveBelow`, {
          id: id,
          idToMoveBelow: belowId,
        }),
    }),
    [baseUrl, pathogens]
  );
  const [initialising, setInitialising] = useState(true);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<Question[]>(null);
  const [feedback, setFeedback] = useState<Feedback>(null);
  const [activeItem, setActiveItem] = useState<Question>(null);
  const [action, setAction] = useState<"delete" | "edit" | "add" | "save">(
    null
  );
  const [saveError, setSaveError] = useState(false);
  const useKeyOfName = baseUrl.includes("TrustOrganisationOption");

  const moveNameKeyToQuestion = (item: any) => {
    return { ...item, question: item?.name };
  };
  const moveQuestionKeyToName = (item: any) => {
    return { ...item, name: item?.question };
  };

  const fetchData = useCallback(() => {
    setLoading(true);
    api
      .list()
      .then((items: any) =>
        setItems(useKeyOfName ? items.map(moveNameKeyToQuestion) : items)
      )
      .catch((e) => {
        if (!e.handled) {
          setFeedback({
            type: "error",
            message: "Error fetching list",
          });
        }
      })
      .finally(() => {
        ReactDom.unstable_batchedUpdates(() => {
          setLoading(false);
          setInitialising(false);
        });
      });
  }, [setInitialising, setLoading, setFeedback, api]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleAdd = () => {
    const item = { id: null, question: "" } as Question;
    setAction("add");
    setActiveItem(item);
    setItems([...items, item]);
  };

  const handleSave = () => {
    if (!activeItem.question) {
      setSaveError(true);
      return;
    }
    const action = activeItem.id ? api.update : api.add;

    const item = useKeyOfName ? moveQuestionKeyToName(activeItem) : activeItem;

    action(item)
      .then(() =>
        setFeedback({
          type: "success",
          message: successMessage || "Record updated",
        })
      )
      .catch(() =>
        setFeedback({ type: "error", message: "Failed to update record" })
      )
      .finally(() => {
        setActiveItem(null);
        setAction(null);
        setSaveError(null);
        fetchData();
      });
  };

  const handleDelete = () => {
    api
      .delete(activeItem.id)
      .then(() =>
        setFeedback({
          type: "success",
          message: deletedMessage || "Record deleted",
        })
      )
      .catch(() =>
        setFeedback({
          type: "error",
          message: "Error removing record",
        })
      )
      .finally(() => {
        setActiveItem(null);
        setAction(null);
        fetchData();
      });
  };

  const handleEdit = (item: Question) => {
    setActiveItem(item);
    setAction("edit");
  };

  const handleCancel = () => {
    setActiveItem(null);
    setAction(null);
    fetchData();
  };

  const move = (id: number, belowId: number) => {
    api
      .move(id, belowId)
      .then(() => {
        fetchData();
        setFeedback({
          type: "success",
          message: "Record moved",
        });
      })
      .catch(() =>
        setFeedback({
          type: "error",
          message: "Failed to move record",
        })
      );
  };
  const handleMoveUp = (item: Question) => {
    const index = items.indexOf(items.find((i) => i.id === item.id));
    move(items[index - 1].id, item.id);
  };

  const handleMoveDown = (item: Question) => {
    const index = items.indexOf(items.find((i) => i.id === item.id));
    move(item.id, items[index + 1].id);
  };

  const ListActionButton = ({
    title,
    disabled,
    onClick,
    Icon,
  }: {
    title: string;
    onClick: (...args: any[]) => void;
    Icon: (props: any) => JSX.Element;
    disabled?: boolean;
  }) => {
    return (
      <Tooltip title={title}>
        {/* span to keep Tooltip happy when child is disabled */}
        <span>
          <IconButton color="primary" disabled={disabled} onClick={onClick}>
            <Icon fontSize="small" />
          </IconButton>
        </span>
      </Tooltip>
    );
  };

  const isEditing = action === "edit" || action === "add";
  return (
    <Box mb={4}>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item>
          <h1>{titleFull}</h1>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleAdd}
            disabled={!!activeItem}
          >
            Add
          </Button>
        </Grid>
      </Grid>
      {initialising && <LinearProgress />}
      <Paper>
        {items && items.length > 0 && (
          <List>
            {items
              .map((i) => ({
                ...i,
                isEditing:
                  i.id === activeItem?.id &&
                  (action === "edit" || action === "add"),
              }))
              .map((i) => ({
                ...i,
                disabled: (isEditing && !i.isEditing) || loading,
              }))
              .map((item, index) => (
                <ListItem key={item.id} className="relative-actions">
                  {item.isEditing && (
                    <>
                      <TextFieldWithCharacterCount
                        MAX_CHARS={300}
                        onChange={(e) => {
                          activeItem.question = e.target.value;
                        }}
                        textFieldProps={{
                          label: `Enter ${title.toLowerCase()}${
                            useKeyOfName ? "" : " statement"
                          }... `,
                          defaultValue: activeItem.question,
                          error: saveError,
                          fullWidth: true,
                        }}
                      />
                      <ListItemSecondaryAction className="list-actions">
                        <ListActionButton
                          title="Save"
                          onClick={() => {
                            setAction("save");
                          }}
                          Icon={SaveIcon}
                        />
                        <ListActionButton
                          title="Cancel"
                          onClick={handleCancel}
                          Icon={CancelIcon}
                        />
                      </ListItemSecondaryAction>
                    </>
                  )}
                  {(!isEditing || !item.isEditing) && (
                    <>
                      <ListItemText primary={item.question} />
                      <ListItemSecondaryAction className="list-actions">
                        {/* <Tooltip title="Reorder">
                            <span>
                              <IconButton color="primary" disabled={!!activeItem}>
                                <DragHandleIcon fontSize="small" />
                              </IconButton>
                            </span>
                          </Tooltip> */}
                        {items.length > 1 && (
                          <>
                            <ListActionButton
                              title="Move Up"
                              disabled={item.disabled || index === 0}
                              onClick={() => handleMoveUp(item)}
                              Icon={ArrowUpward}
                            />
                            <ListActionButton
                              title="Move Down"
                              disabled={
                                item.disabled || index === items.length - 1
                              }
                              onClick={() => handleMoveDown(item)}
                              Icon={ArrowDownward}
                            />
                          </>
                        )}
                        <ListActionButton
                          title="Edit"
                          disabled={item.disabled}
                          onClick={() => handleEdit(item)}
                          Icon={EditIcon}
                        />
                        <ListActionButton
                          title="Delete"
                          disabled={item.disabled}
                          onClick={() => {
                            setActiveItem(item);
                            setAction("delete");
                          }}
                          Icon={DeleteIcon}
                        />
                      </ListItemSecondaryAction>
                    </>
                  )}
                </ListItem>
              ))}
          </List>
        )}
        {items && items.length === 0 && (
          // See comment aligning https://2-app.donedone.com/6110/project/17407/task/682438
          <p style={{ padding: "1.5em 1em" }}>
            No {title.toLowerCase()} records have been added
          </p>
        )}
      </Paper>

      <ConfirmDialog
        title={`Are you sure you want to delete this ${
          title == "Consent" ? "consent statement" : "pre-screening statement"
        }?`}
        children={
          useKeyOfName ? (
            <p></p>
          ) : (
            <p>
              <b>PLEASE NOTE.</b> If this{" "}
              {title === "Consent" ? "consent" : "pre-screening"} statement has
              previously been used when recording a vaccination during the
              current campaign, it will be deleted from those vaccination
              records.
            </p>
          )
        }
        open={action === "delete"}
        onClose={handleCancel}
        onConfirm={handleDelete}
      />

      {activeItem ? (
        <ConfirmDialog
          title={`Are you sure you want to ${
            activeItem.id ? "edit" : "add"
          } this ${
            title == "Consent" ? "consent statement" : "pre-screening statement"
          }?`}
          children={
            useKeyOfName ? (
              <p></p>
            ) : activeItem.id ? (
              <p>
                <b>PLEASE NOTE.</b> If this{" "}
                {title === "Consent" ? "consent" : "pre-screening"} statement
                has previously been used when recording a vaccination during the
                current campaign, it will be updated across those vaccination
                records.
              </p>
            ) : (
              <p>
                <b>PLEASE NOTE.</b> If you have already recorded vaccinations
                during this campaign, this new{" "}
                {title === "Consent" ? "consent" : "pre-screening"} statement
                will be added to the consent record on those vaccinations and
                will appear as unchecked.
              </p>
            )
          }
          open={action === "save"}
          onClose={handleCancel}
          onConfirm={handleSave}
        />
      ) : null}

      {feedback && (
        <ToastMessage
          variant={feedback.type}
          message={feedback.message}
          clear={() => setFeedback(null)}
        />
      )}
    </Box>
  );
};

export default QuestionList;
