import { Button, IconButton, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Add, CloudDownload } from "@material-ui/icons";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Help from "../../../../components/Help/Help";
import { overridesMain } from "../../../../lib/themes/overrides";
import ConfirmDialog from "../../ConfirmDialog";
import api from "./data";
export const toolbarStyles = makeStyles({
  toolbar: {
    display: "inline-flex",
    "& > button": {
      marginRight: "1rem",
    },
    "& > button:last-child": {
      marginRight: 0,
    },
  },
  button: {
    ...overridesMain.MuiButton.root,
    "& svg": {
      marginRight: "0.6rem",
    },
  },
});
export enum TableToolbarButtons {
  downloadCsv = "Download CSV",
  addStaffManually = "Add Staff Manually",
  uploadEsrFile = "Upload Staff List",
  downloadReport = "Download Report as CSV",
  downloadBookingDetails = "Download Clinic Details as CSV",
}
export default function TableToolbar({
  setActiveModal,
  buttonsToShow = [],
  filter,
}: {
  setActiveModal: (modal: string) => void;
  buttonsToShow: TableToolbarButtons[];
  filter: any;
}) {
  const history = useHistory();
  const classes = toolbarStyles();
  const [modal, setModal] = useState(null);
  return (
    <>
      <div className={classes.toolbar}>
        {buttonsToShow.includes(TableToolbarButtons.downloadCsv) && (
          <Tooltip title={TableToolbarButtons.downloadCsv}>
            <IconButton
              onClick={() => {
                setModal("downloading");
                api.staff.download(filter).catch(console.error);
              }}
            >
              <CloudDownload fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
        {buttonsToShow.includes(TableToolbarButtons.addStaffManually) && (
          <div className="help-tooltip-wrapper" style={{ marginRight: "1em" }}>
            <Button
              onClick={() => setActiveModal("add")}
              color="primary"
              variant="contained"
              className={classes.button}
            >
              <Add fontSize="small" />
              {TableToolbarButtons.addStaffManually}
            </Button>
            <Help helpCopyKey="1.1" />
          </div>
        )}
        {buttonsToShow.includes(TableToolbarButtons.uploadEsrFile) && (
          <Button
            onClick={() => history.push("/esr")}
            color="secondary"
            variant="contained"
            className={classes.button}
          >
            <Add fontSize="small" />
            {TableToolbarButtons.uploadEsrFile}
          </Button>
        )}
        {buttonsToShow.includes(TableToolbarButtons.downloadReport) && (
          <Button
            onClick={() => {
              setModal("downloading");
              api.staff.downloadReport(filter).catch(console.error);
            }}
            color="secondary"
            variant="contained"
            className={classes.button}
          >
            <CloudDownload fontSize="small" />
            {TableToolbarButtons.downloadReport}
          </Button>
        )}
        {buttonsToShow.includes(TableToolbarButtons.downloadBookingDetails) && (
          <Tooltip title={TableToolbarButtons.downloadBookingDetails}>
            <IconButton
              style={{
                // Hacky as heck but I can't for the life of me vertically align this correctly/quickly on booking detail
                top: "0.2em",
              }}
              onClick={() => {
                setModal("downloading");
                api.staff.downloadBookingDetails(filter).catch(console.error);
              }}
            >
              <CloudDownload fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </div>
      {modal === "downloading" && (
        <ConfirmDialog
          open={true}
          title="The CSV file is being prepared"
          confirmLabel="OK"
          showClose={false}
          onClose={() => {
            setModal(null);
          }}
          onConfirm={() => {
            setModal(null);
          }}
          // Not sure why paper background isn't kicking in, but we're in the close-out-list stage so isn't worth pulling threads
          dialogParams={{ class: "modal-background-fix" }}
        >
          <p>
            It will automatically download when ready. Please be aware, it can
            take up to 40 seconds if you have a large staff list.
          </p>
        </ConfirmDialog>
      )}
    </>
  );
}
