import React from "react";
import ReactDOM from "react-dom";
import DataClient from "../../../../lib/services/api/DataClient";
import { Trust } from "./Trust";

const POST_URL = "/Trust/";

export default function useCreateTrust() {
  const [success, setSuccess] = React.useState<false | Trust>(false);
  const [error, setError] = React.useState(false);

  const callback = (trust: Trust) => {
    setError(false);
    setSuccess(false);
    DataClient.postData(`${POST_URL}`, trust)
      .then((res: unknown) => {
        ReactDOM.unstable_batchedUpdates(() => {
          if (!res["success"]) {
            throw new Error("API Update failed");
          }
          setSuccess({
            id: res["results"].id,
            ...trust,
          });
        });
      })
      .catch((error) => {
        ReactDOM.unstable_batchedUpdates(() => {
          console.error(error);
          setError(true);
        });
      });
  };

  return [callback, success, error] as const;
}
