import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import set from "lodash/set";
import moment from "moment";
import { humanDate, humanTime } from "../lib/common_utils";
import {
  enumClinicSchedule,
  enumClinicTypeId,
  enumRecurrenceId,
} from "../lib/enums";
import DataClient from "../lib/services/api/DataClient";
import clinicLocationApi from "../views/Users/TrustAdmin/Settings/Clinics/api";
import { InputProps } from "./Input";
// {
//   id: "showIfFooSelectsOption",
//   type: "text",
//   requires: [{ id: "foo", value: "someOptionId" }],
// },
// {
//   id: "showIfFooSelectsAnyOther",
//   type: "text",
//   requires: [{ id: "foo", notValue: "someOptionId" }],
// },
// Or alternatively, pass a func to test the value yourself

export const showHideFields = (fields: any[]): any[] => {
  return fields.filter(({ requires }: InputProps) => {
    if (!requires) {
      return true;
    }

    if (typeof requires === "function") {
      return requires(fields);
    }

    return requires.every(({ id, value, notValue, func }) =>
      fields.find((f: any) => {
        if (f.id !== id) {
          return false;
        }
        if (func) return func(f.value);
        return value
          ? f.value === value
          : notValue
          ? f.value !== notValue
          : false;
      })
    );
  });
};
export const populateFields = ({
  fields,
  clinic,
}: {
  fields: any[];
  clinic: Clinic;
}) => {
  // For every clinic field, attempt to jam the value straight in
  // console.log("::populateFields", { clinic, fields });
  fields = fields.map((field) => {
    if (
      clinic[field.id] ||
      (field.isBoolean && typeof clinic[field.id] != "undefined")
    ) {
      let value = clinic[field.id].toString();
      // Dates, parse to format 2021-01-30
      if (field.type === "date" && value) {
        value = moment(new Date(value)).format().split("T")[0];
      }
      // Radios, attempt to parse number if appropriate
      if (field.type === "radio" && field.isBoolean) {
        value = clinic[field.id] ? 1 : 0;
      } else if (field.type === "radio" && !isNaN(value as any)) {
        value = parseInt(value);
      }
      // Checkboxes, attempt to parse "true", "false
      if (field.type === "checkbox") {
        value = value === "true" ? true : value === "false" ? false : value;
      }
      // console.log("::Setting", field.id, ":", value, typeof value);
      field.value = value;
      // For select boxes and whatnot, if there's only a "Please select" value, attempt to populate that with anticipated keys like:
      // clinicLocation looks for clinicLocationName, clinicLocationId
    } else if (field.options?.length === 1) {
      if (clinic[`${field.id}Name`]) {
        field.options[0].label = clinic[`${field.id}Name`].toString();
      }
      if (clinic[`${field.id}Id`]) {
        field.options[0].value = clinic[`${field.id}Id`].toString();
        // Set the value of the select too so it can be submitted in edit mode
        field.value = clinic[`${field.id}Id`].toString();
      }
    }
    return field;
  });
  return fields;
};
export const populateClinicSchedule = ({
  fields,
  clinic,
}: {
  fields: any[];
  clinic: Clinic;
}) => {
  // Set clinicSchedule to 'One-off' if has repeatOnDays value
  set(
    find(fields, { id: "clinicSchedule" }),
    "value",
    enumRecurrenceId[clinic.recurrenceId] === "None"
      ? enumClinicSchedule["One-off"]
      : enumClinicSchedule.Recurring
  );
  return fields;
};
export const populateRepeatOnDays = ({
  fields,
  clinic,
}: {
  fields: any[];
  clinic: Clinic;
}) => {
  if (clinic.repeatOnDays) {
    const repeatOnDaysField = find(fields, { id: "repeatOnDays" });
    set(repeatOnDaysField, "value", clinic.repeatOnDays);
    repeatOnDaysField.onChangeCallback(clinic.repeatOnDays);
  }
  return fields;
};
export const populateBreakTimes = ({
  fields,
  clinic,
}: {
  fields: any[];
  clinic: Clinic;
}) => {
  if (clinic?.breakTimes) {
    set(
      find(fields, { id: "breakTimes" }),
      "value",
      clinic.breakTimes.map(({ start, end }) => [start, end])
    );
  }
  return fields;
};
export const populateHospitalSite = ({
  fields,
  hospitalSites,
  clinic,
}: {
  fields: any[];
  hospitalSites: {
    hospitalSiteName: string;
    id: string;
    isArchived: boolean;
  }[];
  clinic: Clinic;
}) => {
  const existingSelection = { ...find(fields, { id: "hospitalSite" }) };
  set(find(fields, { id: "hospitalSite" }), "options", [
    { label: "Select from the list", value: "" },
    ...hospitalSites
      // Don't show Archived hospitals unless they are the current clinic's hospital.
      .filter((result: any) => {
        return !result.isArchived || result.id == clinic.hospitalSiteId;
      })
      .map(({ hospitalSiteName, id, isArchived }) => ({
        label: hospitalSiteName,
        value: `${id}`,
        disabled: isArchived,
      })),
  ]);
  if (existingSelection) {
    // console.log(existingSelection.value);
    set(
      find(fields, { id: "hospitalSite" }),
      "value",
      `${existingSelection.value}`
    );
    // console.log(find(fields, { id: "hospitalSite" }).value);
  }
  return fields;
};
export const populatePathogens = ({
  fields,
  pathogens,
  clinic,
}: {
  fields: any[];
  pathogens: any[];
  clinic?: Clinic;
}) => {
  // Populate the pathogen select items from passed array (i.e. from trust via /me)
  if (pathogens) {
    const options = [
      // { label: "Select from the list", value: "" },
      ...pathogens.map(({ id, name }: { id: string; name: string }) => ({
        label: name,
        value: `${id}`, // make certain it's a string, i.e. "1", "2", "1,2"
      })),
    ];
    // Add { label: "Both", value: "1,2" } if suitable (or 'Any')
    if (options.length === 2) {
      options.push({
        label: options.length <= 3 ? "Flu & Covid" : "Any",
        value: options
          .filter((o) => o.value != "")
          .map((o) => o.value)
          .join(","),
      });
    }
    set(find(fields, { id: "pathogens" }), "options", options);
  }
  // Edit clinic, existing selection from clinic
  if (clinic?.pathogens) {
    const flatogens = clinic.pathogens.map((p) => p.id).join(",");
    // If your trust changes what pathogens they can use, it wain't work (be reet though)
    set(find(fields, { id: "pathogens" }), "value", `${flatogens}`);
  }
  return fields;
};
// Used when opening edit clinic, to fetch other locations in the same site,
// and also when changing the site dropdown
export const populateClinicLocation = async (
  { fields }: { fields: any[] },
  currentClinic: Clinic,
  mode: "create" | "edit"
) => {
  return new Promise<any[]>((resolve, reject) => {
    const hospitalId = find(fields, { id: "hospitalSite" })?.value;
    if (!hospitalId) {
      // console.log("::populateClinicLocation", "Missing hospitalId");
      set(find(fields, { id: "clinicLocation" }), "options", [
        { label: "Select Site first", value: "" },
      ]);
      resolve(fields);
    }
    clinicLocationApi
      .list(hospitalId)
      .then((results) => {
        if (results) {
          set(find(fields, { id: "clinicLocation" }), "options", [
            { label: "Select from the list", value: "" },
            ...results
              // Don't show Archived clinics unless they are the current clinic.
              .filter((result: any) => {
                return (
                  !result.isArchived ||
                  (mode == "edit" &&
                    result.id == currentClinic.clinicLocationId)
                );
              })
              .map(
                ({
                  id,
                  locationName,
                  isArchived,
                }: {
                  id: string;
                  locationName: string;
                  isArchived: boolean;
                }) => ({
                  label: locationName,
                  value: id,
                  disabled: isArchived,
                })
              ),
          ]);
          resolve(fields);
        } else {
          reject("::populateClinicLocation data error");
        }
      })
      .catch(reject);
  });
};
export const populateFieldsEditClinic = async ({
  pathogens,
  fields,
  campaigns,
  hospitalSites,
  campaignActive,
  clinic,
  mode,
}: {
  pathogens: any[];
  fields: any[];
  campaigns: any[];
  hospitalSites: any[];
  campaignActive: any[];
  clinic: Clinic;
  mode: "create" | "edit";
}) => {
  // console.log("::populateFieldsEditClinic", {
  //   fields,
  //   campaigns,
  //   hospitalSites,
  //   campaignActive,
  //   clinic,
  // });
  fields = populateFields({ fields, clinic });
  fields = populateClinicSchedule({ fields, clinic });
  fields = populatePathogens({ fields, pathogens, clinic });
  fields = populateHospitalSite({ fields, hospitalSites, clinic });
  fields = await populateClinicLocation({ fields }, clinic, mode);
  fields = populateRepeatOnDays({ fields, clinic });
  fields = populateBreakTimes({ fields, clinic });
  // console.log("✔ populateFieldsEditClinic", { fields });
  // Disable walkaround option for booking model if any bookings exist
  if (clinic) {
    const res = await DataClient.getData(
      `/Reservation/ShowAllBookings?clinicRepeatingGroupId=${clinic.clinicRepeatingGroupId}&pageSize=1`
    );
    if (res.results.length > 0) {
      fields
        .find((f: { id: string }) => f.id === "clinicTypeId")
        .options.find(
          (o: { value: number }) => o.value == enumClinicTypeId.walkAround
        ).disabled = true;
    }
  }
  return fields;
};
export const populateFieldsCreateClinic = async ({
  pathogens,
  fields,
  campaigns,
  hospitalSites,
  campaignActive,
  clinic,
}: {
  pathogens: any[];
  fields: any[];
  campaigns: any[];
  hospitalSites: any[];
  campaignActive: any[];
  clinic?: Clinic;
}) => {
  fields = populatePathogens({ fields, pathogens });
  fields = populateHospitalSite({ fields, hospitalSites, clinic });
  return fields;
};
export const arrayToObjIdValue = (
  arr: {
    id: any;
    value?: any;
  }[]
) =>
  arr.reduce((obj, item) => {
    obj[item.id] = item.value ?? null;
    return obj;
  }, {});
const integerFields = [
  "clinicLocationId",
  "clinicTypeId",
  "maximumNumberOfDropInAllowed",
  "slotDuration",
  "campaignId",
  "numberOfSpaces",
];
// 1999-12-25T00:00:00[+/-00:00] -> 1999-12-25T00:00:00-00:00
export const parseIsoDatetimeForVaccinationHistory = (value: string) => {
  // A weird feedback loop with the API causing grief
  if (!value || (typeof value === "string" && value?.includes("Invalid date")))
    return null;
  // Material datepicker madness
  const dateTime = moment.isMoment(value)
    ? value
    : moment(humanDate(value), "DD/MM/YYYY");
  return `${dateTime.format("YYYY-MM-DD")}T00:00:00-00:00`;
};
// 1999-12-25T00:00:00[+/-00:00] -> 1999-12-25
export const parseIsoDatetime = (value: string) => {
  if (
    moment(value, "YYYY-MM-DDTHH:mm:ss", true).isValid() ||
    moment(value, "YYYY-MM-DDTHH:mm:ssZ", true).isValid()
  ) {
    return moment(humanDate(value), "DD/MM/YYYY").format("YYYY-MM-DD");
  } else return value;
};
// Parse any detected API dates into normal ISO for date inputs
// 1999-12-25T00:00:00 -> 1999-12-25
export const parseFieldsIsoDates = (fields: Record<string, any>) => {
  Object.entries(fields).forEach(([key, value]) => {
    fields[key] = parseIsoDatetime(value as string);
  });
  return fields;
};
export const parseFieldTime = humanTime;
// 1999-12-25T09:00:00 -> 09:00
// export const parseFieldTime = (value: string) => humanTime /
export const createPostDataForClinic = ({
  mode,
  campaignId,
  fields,
}: {
  mode: "create" | "edit";
  campaignId: string | number;
  fields: InputProps[];
}) => {
  // Format values as required by API
  fields = cloneDeep(fields).map((field) => {
    if (field.value) {
      // Date: 2021-01-30
      if (field.type === "date") {
        field.value = parseIsoDatetime(field.value);
      }
      // Time: 09:00
      if (field.type === "time") {
        field.value = parseFieldTime(field.value);
      }
      // "1,2" => [1, 2]
      // "foo,bar" => ["foo", "bar"]
      if (field.type === "selectArray") {
        field.value = field.value
          .split(",")
          .map((v: any) => (!isNaN(v) ? parseInt(v) : v));
      }
      // Clinic Type should be checkboxes really, but it's actually radios that split to an array
      // "2" => [2], "1,2" => [1,2]
      if (field.id === "pathogens") {
        field.value = `${field.value}`.split(",").map((p) => parseInt(p));
      }
    }
    return field;
  });
  // Convert to object format { date: "1983-06-30", .. }
  const fieldsObj: any = arrayToObjIdValue(fields);
  console.log("::createPostDataForClinic...", fields, fieldsObj);
  if (fieldsObj.breakTimes?.length) {
    // Brutally strip out any empty break time sets, or incomplete ones
    // (shouldn't happen with clientside validation but JIC)
    fieldsObj.breakTimes = fieldsObj.breakTimes
      .filter(
        (b: any[]) =>
          b !== null && b.length === 2 && b[0] !== null && b[1] !== null
      )
      // Convert it into API format [{"start": "09:00", "end":  "09:00"}, ..]
      .map((b: any[]) => ({ start: b[0], end: b[1] }));
  } else {
    delete fieldsObj.breakTimes; // prevent submitting [] as it may be causing issues
  }
  // Force everything into strings, i.e. "true"
  // and remove null keys entirely(!)
  for (const key in fieldsObj) {
    if (fieldsObj[key] === null) {
      // console.log("::deleting", key, JSON.stringify(fieldsObj[key]));
      delete fieldsObj[key];
    } else {
      fieldsObj[key] = integerFields.includes(key)
        ? parseInt(fieldsObj[key])
        : fieldsObj[key];
    }
  }
  console.log("::...createPostDataForClinic", fieldsObj);
  const {
    breakTimes,
    cancelReservationsIfInConflict,
    clinicLocation,
    clinicSchedule,
    clinicTypeId,
    date,
    dateFrom,
    dateTo,
    is24HourClinic,
    manuallyAddStaff,
    maximumNumberOfDropInAllowed,
    moveReservationsIfRequired,
    numberOfSpaces,
    pathogens,
    repeatOnDays,
    requireBookingAllPathogens,
    slotDuration,
    timeFrom,
    timeTo,
  } = fieldsObj;
  // fieldsObj.is24HourClinic = fieldsObj.is24HourClinic ? "true": "false";
  const clinicLocationId = clinicLocation ? parseInt(clinicLocation) : null;
  let params: any = {};
  // Same for create/edit (mostly)
  if (clinicSchedule === enumClinicSchedule["One-off"]) {
    params = {
      breakTimes,
      campaignId,
      clinicLocationId,
      clinicTypeId,
      date,
      is24HourClinic,
      manuallyAddStaff,
      maximumNumberOfDropInAllowed,
      numberOfSpaces,
      pathogens,
      requireBookingAllPathogens,
      slotDuration,
      timeFrom,
      timeTo,
    };
  } else if (clinicSchedule === enumClinicSchedule["Recurring"]) {
    params = {
      breakTimes,
      campaignId,
      clinicLocationId,
      clinicTypeId,
      dateFrom,
      dateTo,
      is24HourClinic,
      manuallyAddStaff,
      maximumNumberOfDropInAllowed,
      numberOfSpaces,
      repeatOnDays,
      pathogens,
      requireBookingAllPathogens,
      slotDuration,
      timeFrom,
      timeTo,
    };
  }
  if (clinicTypeId === enumClinicTypeId.walkAround) {
    // Remove fields that are 'Not walkaround'
    delete params.slotDuration;
    delete params.numberOfSpaces;
    delete params.breakTimes;
    delete params.maximumNumberOfDropInAllowed;
    delete params.requireBookingAllPathogens;
    if (is24HourClinic) {
      delete params.timeFrom;
      delete params.timeTo;
    }
  } else {
    delete params.is24HourClinic;
    if (clinicTypeId === enumClinicTypeId.bookingsOnly) {
      delete params.maximumNumberOfDropInAllowed;
    }
  }
  // Delete requireBookingAllPathogens if not an appropriate clinic type, as it'd break the API (rush build)
  if (pathogens?.length < 2) {
    delete params.requireBookingAllPathogens;
  }
  // Handle 'Are you sure?' forceful edit fields (pushed from elsewhere)
  if (mode === "edit") {
    params.moveReservationsIfRequired = moveReservationsIfRequired;
    params.cancelReservationsIfInConflict = cancelReservationsIfInConflict;
  }
  return params;
};
