import {
  Checkbox,
  CircularProgress,
  MenuItem,
  TextField,
  FormGroup,
  FormControlLabel,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import {
  createPathogenCheckboxValuesFromPathogens,
  getTrustPathogenIds,
  Pathogens,
} from "../../../../lib/pathogens";
import api from "./data";
import styles from "./Form.module.scss";

const useStyles = makeStyles({
  root: {
    margin: "1rem 0",
  },
});

function Form({ formId, defaultValue, onSubmit, errors, setFormLoaded }) {
  const classes = useStyles();

  const pathogens = getTrustPathogenIds();

  let itemStateInit = {
    assignmentStatusId: 2,
    dateOfBirth: null,
    // #190 If a single-pathogen trust, default to its only option
    pathogens: pathogens.length === 1 ? [pathogens[0]] : [],
  };
  defaultValue = createPathogenCheckboxValuesFromPathogens(defaultValue);
  // Nullify first/second covid date for field, if necessary, like dob
  // if (trustInvolvesCovid()) {
  //   itemStateInit.firstCovidVaccinationDate = null;
  //   itemStateInit.lastCovidDoseDate = null;
  // }
  itemStateInit = {
    ...itemStateInit,
    ...defaultValue,
  };
  const [item, setItem] = useState(itemStateInit);
  const [lookups, setLookups] = useState(null);

  const fetchLookups = useCallback(() => {
    api.staff
      .getLookups()
      .then((lookups) => {
        setLookups(lookups);
        if (setFormLoaded) {
          setFormLoaded(true);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, [setFormLoaded]);

  useEffect(() => {
    fetchLookups();
  }, [fetchLookups]);

  const handleChange = (prop) => (event, v) => {
    const id = v?.id; // if it's a lookup
    const target = event?.target;
    let value =
      id || target
        ? target.type === "checkbox"
          ? target.checked
          : target.value
        : event;
    if (
      value &&
      [
        "dateOfBirth",
        // "firstCovidVaccinationDate",
        // "lastCovidDoseDate",
      ].includes(prop)
    ) {
      value.hours(12);
    }
    setItem({
      ...item,
      [prop]: value === "" ? null : value,
    });
  };

  const inputProps = (name) => ({
    variant: "outlined",
    onChange: handleChange(name),
    error: !!errors[name],
    helperText: errors[name],
    fullWidth: true,
    className: classes.root,
    InputLabelProps: {
      shrink: true,
    },
  });

  return lookups ? (
    <form
      id={formId}
      autoComplete="off"
      onSubmit={(event) => {
        event.preventDefault();
        // item.flagExemptCovid = true;
        onSubmit(item);
      }}
      className={styles.form}
    >
      <TextField
        label="First Name"
        id="firstName"
        required
        defaultValue={item.firstName}
        {...inputProps("firstName")}
        focused={true}
      />

      <TextField
        label="Last Name"
        id="lastName"
        required
        defaultValue={item.lastName}
        {...inputProps("lastName")}
      />

      <KeyboardDatePicker
        label="Date of Birth"
        id="dateOfBirth"
        autoOk={true}
        format="DD/MM/YYYY"
        disableFuture={true}
        maxDate={moment().subtract(16, "years")}
        maxDateMessage={"Age needs to be above 18"}
        required
        value={item.dateOfBirth}
        {...inputProps("dateOfBirth")}
        inputVariant="outlined"
      />

      <TextField
        label="Email Address"
        id="emailAddress"
        type="email"
        defaultValue={item.emailAddress}
        {...inputProps("emailAddress")}
      />

      {pathogens?.length && (
        <>
          {/* <TextField
            label="Vaccine(s) Staff Member Can Receive: select one or more from the available option(s)"
            select
            required
            SelectProps={{ multiple: true }}
            id="pathogens"
            defaultValue={item.pathogens}
            {...inputProps("pathogens")}
          >
            {pathogens.map((id) => (
              <MenuItem value={id}>{Pathogens[id] || "N/A"}</MenuItem>
            ))}
          </TextField> */}
          <label>
            Vaccine(s) Staff Member Can Receive: select one or more from the
            available option(s) *
          </label>
          <FormGroup error={true} id="pathogens">
            {pathogens?.map((id) => (
              <FormControlLabel
                {...inputProps(`pathogen.${id}`)}
                className=""
                control={
                  <Checkbox
                    id={`pathogen.${id}`}
                    data-theme-id={id}
                    // checked={item.pathogens?.includes(id)}
                    checked={item[`pathogen.${id}`]}
                  />
                }
                label={Pathogens[id] || "N/A"}
              />
            ))}
            {errors?.pathogens && (
              <FormHelperText error>{errors?.pathogens}</FormHelperText>
            )}
          </FormGroup>
        </>
      )}

      {/* #166, then removed again with #32 */}
      {/* {trustInvolvesCovid() && (
        <>
          <KeyboardDatePicker
            label="First Covid Vaccination Date"
            autoOk={true}
            format="DD/MM/YYYY"
            value={item.firstCovidVaccinationDate}
            {...inputProps("firstCovidVaccinationDate")}
            inputVariant="outlined"
          />
          <KeyboardDatePicker
            label="Last Covid Vaccination Date"
            autoOk={true}
            format="DD/MM/YYYY"
            value={item.lastCovidDoseDate}
            {...inputProps("lastCovidDoseDate")}
            inputVariant="outlined"
          />
        </>
      )} */}

      <TextField
        label="Assignment Status"
        id="assignmentStatusId"
        select
        required
        value={item.assignmentStatusId}
        {...inputProps("assignmentStatusId")}
      >
        <MenuItem value=""></MenuItem>
        {lookups.assignmentStatus.map((i) => (
          <MenuItem key={i.id} value={i.id}>
            {i.name}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        label="Staff Type"
        id="staffTypeId"
        select
        value={item.isEsr == null ? "" : item.isEsr}
        {...inputProps("isEsr")}
      >
        <MenuItem value=""></MenuItem>
        <MenuItem value={false}>Non ESR Staff</MenuItem>
        <MenuItem value={true}>ESR Staff</MenuItem>
      </TextField>

      {item.isEsr === false && (
        <>
          <TextField
            label="Non ESR Staff Group"
            id="nonEsrStaffTypeId"
            select
            value={item.nonEsrStaffTypeId || ""}
            {...inputProps("nonEsrStaffTypeId")}
          >
            <MenuItem value=""></MenuItem>
            {lookups.nonEsrStaffType.map((i) => (
              <MenuItem key={i.id} value={i.id}>
                {i.name}
              </MenuItem>
            ))}
          </TextField>
        </>
      )}

      {item.isEsr && (
        <>
          <TextField
            label="Staff Group"
            id="staffGroupId"
            select
            value={item.staffGroupId || ""}
            {...inputProps("staffGroupId")}
            onChange={(e) => {
              setItem({
                ...item,
                staffGroupId: e.target.value,
                jobRoleId: null,
              });
            }}
          >
            <MenuItem value=""></MenuItem>
            {lookups.staffGroup.map((i) => (
              <MenuItem key={i.id} value={i.id}>
                {i.name}
              </MenuItem>
            ))}
          </TextField>

          {item.staffGroupId && (
            <TextField
              label="Job Role"
              id="jobRoleId"
              required
              select
              value={item.jobRoleId || ""}
              {...inputProps("jobRoleId")}
            >
              <MenuItem value=""></MenuItem>
              {lookups.jobRole
                .filter((x) => x.staffGroupId === item.staffGroupId)
                .map((i) => (
                  <MenuItem key={i.id} value={i.id}>
                    {i.name}
                  </MenuItem>
                ))}
            </TextField>
          )}
        </>
      )}

      {[1, 2, 3, 4].map((n) => (
        <TextField
          label={`Level ${n}`}
          id={`level${n}`}
          key={n}
          defaultValue={item[`level${n}`]}
          {...inputProps(`level${n}`)}
        />
      ))}

      <TextField
        label="Employee Number"
        id="employeeNumber"
        defaultValue={item.employeeNumber}
        {...inputProps("employeeNumber")}
      />

      {/* "When adding staff manually make either postcode OR NHS number mandatory" #2090
      "When editing a staff member, disallow deleting both postcode and NHS number - at least one must remain" #2090 edited
      See StaffValidation.js */}
      <TextField
        label="NHS Number"
        id="nhsNumber"
        defaultValue={item.nhsNumber}
        {...inputProps("nhsNumber")}
      />
      <TextField
        label="Postcode"
        id="postCode"
        defaultValue={item.postCode}
        {...inputProps("postCode")}
      />

      {/* "Please remove Mobile Number from Add Staff Manually form. A Mobile Number is only provided by the staff member themselves during the booking process (optional). However, it should be in 'Edit staff member.'"
      UPDATE: "Add it back in here. One of the main uses for the manually add staff function is in a clinic when a staff member turns up and they add them to the system there and there. So it's actually fine to have the mobile field there" */}
      {/* {formId !== "createForm" && ( */}
      <TextField
        label="Mobile Number"
        id="mobileNumber"
        defaultValue={item.mobileNumber}
        {...inputProps("mobileNumber")}
      />

      <TextField
        label="Gender"
        id="gennderId"
        select
        value={item.genderId || ""}
        {...inputProps("genderId")}
      >
        <MenuItem value=""></MenuItem>
        {lookups.gender.map((i) => (
          <MenuItem key={i.id} value={i.id}>
            {i.name}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        label="Ethnicity"
        id="ethnicityId"
        select
        value={item.ethnicityId || ""}
        {...inputProps("ethnicityId")}
      >
        <MenuItem value=""></MenuItem>
        {lookups.ethnicity.map((i) => (
          <MenuItem key={i.id} value={i.id}>
            {i.name}
          </MenuItem>
        ))}
      </TextField>
      {/* [DD#207] Removing "Role" entirely as there's no point reworking it to include
       isClinicAdmin, as you can set all this in the SetAdditionalRoles modal anyway */}
      {/* <TextField
        label="Role"
        select
        required
        value={item.isVaccinator == null ? false : item.isVaccinator}
        {...inputProps("isVaccinator")}
      >
        <MenuItem value=""></MenuItem>
        <MenuItem value={false}>Staff</MenuItem>
        <MenuItem value={true}>Vaccinator</MenuItem>
      </TextField> */}
      {/* "Remove the Frontline Toggle from the Add Staff Manually form"
      This field apparently gets set by role on create, and doens't need to be visible during edit either so yolo */}
      {/* <FormControlLabel
        control={
          <Switch
            checked={!!item.isFrontLine}
            onChange={handleChange("isFrontLine")}
            color="primary"
          />
        }
        label="Is Frontline?"
      /> */}
    </form>
  ) : (
    <div className={styles.centerContent}>
      <CircularProgress />
    </div>
  );
}

export default Form;
