import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import moment from "moment";
import { PropsWithChildren, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { VaccinationHistoryIdentifiers } from "../lib/VaccinationHistoryIdentifierEnum";
import { humanDate, humanTime } from "../lib/common_utils";
import { enumPrescribingMethods } from "../lib/enums";
import { Pathogens } from "../lib/pathogens";
import DataClient from "../lib/services/api/DataClient";
import ApiAlert from "./ApiAlert";
import {
  parseIsoDatetime,
  parseIsoDatetimeForVaccinationHistory,
} from "./Fields";
import { Flag } from "./FlagIcons";
import KeyboardDatePickerDateOnly from "./KeyboardDatePickerDateOnly";
import { VaccinationRecordAfterMeddling } from "./VaccinationHistory";

function FieldDisplay({
  label,
  children,
}: PropsWithChildren<{ label: string }>) {
  console.log(`children: `, children);
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 2fr",
        columnGap: "1em",
      }}
    >
      <span style={{ fontWeight: 600 }}>{label}</span>
      <span>{children}</span>
    </div>
  );
}

export function VaccinationHistoryItem({
  id,
  record,
  allowEdit = false,
  setVaccinationHistoryItemIsExpanded,
}: {
  id: string | number;
  record: VaccinationRecordAfterMeddling;
  allowEdit?: boolean;
  setVaccinationHistoryItemIsExpanded: (isExpanded: boolean) => void;
}): JSX.Element {
  const [editMode, setEditMode] = useState(false);
  const [alert, setAlert] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  // Create a local state for record date so it can be updated with a fetch
  const [_record, _setRecord] = useState(record);
  const fixDates = (
    record: VaccinationRecordAfterMeddling,
  ): VaccinationRecordAfterMeddling => {
    // API is returning an unusual offset format that breaks the picker 1999-12-25T00:00:00-00:01
    // So force it into 1999-12-25 format to feed into datepicker
    record.date = parseIsoDatetime(record.date);
    return record;
  };
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues: fixDates(record) });
  // useEffect(() => {
  //   reset(record);
  // }, [reset]);
  const onSubmit = async (props: any) => {
    console.log(`onsubmit props: `, props);
    setAlert(null);
    setDisabled(true);
    const data = {
      adverseReaction: props.adverseReaction || null,
      allergyStatus: props.allergyStatus || null,
      clinicName: props.clinicName || null,
      // Send up dates with full times and time offsets because it seemingly needs that (and adds freaky server offsets otherwise which can even break the dates)
      date: props.date
        ? parseIsoDatetimeForVaccinationHistory(props.date)
        : null,
      injectionSite: props.injectionSite || null,
      prescribingMethod: props.prescribingMethod || null,
      location: props.location || null,
      otherInformation: props.otherInformation || null,
      time: props.time ? `${humanTime(props.time)}:00` : null,
      vaccinationIdentifier: props.vaccinationIdentifier || null,
      vaccinator: props.vaccinator || null,
      vaccineBatchNumber: props.vaccineBatchNumber || null,
      // Store as free text field due to inconsistent historical values.
      vaccineExpiryDate: props.vaccineExpiryDate
        ? `${humanDate(props.vaccineExpiryDate)}`
        : null,
      vaccineDefrostDate: props.vaccineDefrostDate
        ? `${humanDate(props.vaccineDefrostDate)}`
        : null,
      vaccineType: props.vaccineType || null,
    };

    DataClient.postData(`/TrustWorker/${id}/UpdateVaccinationHistory`, data)
      .catch((err) => {
        console.error(err);
      })
      .then(({ success }) => {
        if (success) {
          setAlert(
            ApiAlert({
              alwaysShow: true,
              severity: "success",
              title: "Saved",
              message: "Record has been successfully updated",
            }),
          );
          setTimeout(() => {
            setAlert(null);
          }, 5000);
          // Update local record data with changes
          _setRecord({ ...record, ...data });
          setEditMode(false);
        }
      });

    setDisabled(false);
  };
  // console.log({ formState });
  if (!_record) return null;
  const vaccine = VaccinationHistoryIdentifiers.find(
    (v) => v.id === _record.vaccinationIdentifier,
  );
  const vaccineRender = (
    <span
      className="theme-pill"
      data-theme-id={`${vaccine?.pathogen}`}
      style={{ display: "inline-block" }}
    >
      {vaccine?.name}
    </span>
  );
  console.log(`_racord: `, _record);
  return (
    <tbody className={`${showMoreInfo ? "-expanded" : ""}`}>
      {!showMoreInfo && (
        <tr>
          <td data-label="Date">
            <span>{humanDate(_record.date) || "-"}</span>
          </td>
          <td data-label="Vaccine">
            {editMode && <InputLabel shrink>Vaccine</InputLabel>}
            {vaccineRender}
          </td>
          <td data-label="Clinic Site">
            <span>{_record.clinicName || "-"}</span>
          </td>
          <td data-label="Vaccine Type">
            <span>{_record.vaccineType || "-"}</span>
          </td>
          <td data-label="Vaccine Batch Number">
            <span>{_record.vaccineBatchNumber || "-"}</span>
          </td>
          <td className="_actions">
            <Button
              onClick={(e) => {
                e.preventDefault();
                setShowMoreInfo(true);
                setVaccinationHistoryItemIsExpanded(true);
              }}
              variant="text"
              color="primary"
              size="small"
              disabled={disabled}
              style={{ textDecoration: "underline" }}
            >
              {allowEdit ? "Edit /" : ""} Show More Info
            </Button>
          </td>
        </tr>
      )}
      {/*   More Info   */}
      {showMoreInfo && (
        <>
          <tr>
            <th style={{ verticalAlign: "middle" }}>{vaccineRender}</th>
            <th colSpan={4}></th>
            <th className="_actions">
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setEditMode(false);
                  setShowMoreInfo(false);
                  setVaccinationHistoryItemIsExpanded(false);
                }}
                variant="text"
                size="small"
                disabled={disabled}
                style={{ color: "white", textDecoration: "underline" }}
              >
                Show Less Info
              </Button>
            </th>
          </tr>
          <tr>
            <td colSpan={6}>
              {/*// More info / edit expanded section*/}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ flex: "0 0 45%" }}>
                  <h2
                    className="theme-color"
                    data-theme-id={`${vaccine?.pathogen}`}
                  >
                    Pre-Screening Statements
                  </h2>
                  <ul className="list-unstyled" style={{ paddingLeft: "32px" }}>
                    {record.prescreeningAnswers.length === 0 && <p>-</p>}
                    {record.prescreeningAnswers.map((prescreenAnswer) => (
                      <li style={{ position: "relative" }}>
                        <span style={{ position: "absolute", left: "-32px" }}>
                          {prescreenAnswer.answer ? (
                            <Flag type="flagAccepted" />
                          ) : (
                            <Flag type="flagDeclined" />
                          )}{" "}
                        </span>
                        {prescreenAnswer.questionText}
                      </li>
                    ))}
                  </ul>
                  <h2
                    className="theme-color"
                    data-theme-id={`${vaccine?.pathogen}`}
                  >
                    Consent Statements
                  </h2>
                  <ul className="list-unstyled" style={{ paddingLeft: "32px" }}>
                    {record.consentAnswers.length === 0 && <p>-</p>}

                    {record.consentAnswers.map((consentAnswer) => (
                      <li style={{ position: "relative" }}>
                        <span style={{ position: "absolute", left: "-32px" }}>
                          {consentAnswer.answer ? (
                            <Flag type="flagAccepted" />
                          ) : (
                            <Flag type="flagDeclined" />
                          )}{" "}
                        </span>
                        {consentAnswer.questionText}
                      </li>
                    ))}
                  </ul>
                </div>
                <div style={{ flex: "0 0 45%" }}>
                  <h2
                    className="theme-color"
                    data-theme-id={`${vaccine?.pathogen}`}
                  >
                    Vaccination Details
                  </h2>
                  {editMode ? (
                    <ul className="list-editable">
                      <li data-label="Date">
                        <Controller
                          name="date"
                          control={control}
                          // rules={{ required: "Field is required" }}
                          render={({ field: { ref, ...rest } }) => {
                            return (
                              <KeyboardDatePicker
                                label="Date"
                                inputVariant="outlined"
                                InputLabelProps={{ shrink: true }}
                                {...rest}
                                {...{
                                  autoOk: true,
                                  format: "DD/MM/YYYY",
                                  maxDate: moment(),
                                  error: !!errors?.date,
                                  helperText: errors?.date?.message,
                                }}
                              />
                            );
                          }}
                        />
                      </li>
                      <li data-label="Time">
                        <Controller
                          name="time"
                          control={control}
                          render={({ field: { ref, ...rest } }) => {
                            return (
                              <KeyboardTimePicker
                                label="Time"
                                inputVariant="outlined"
                                InputLabelProps={{ shrink: true }}
                                // onChange (date: DateIOType, value?: string | null) => void
                                format="HH:mm"
                                {...rest}
                                // Parse date from server (14:23:00.0000000), or edited,
                                // to a moment date object because.. timepickers are actually dates lol
                                value={moment(rest.value, "HH:mm:ss")}
                                {...{
                                  error: !!errors?.time,
                                  helperText: errors?.time?.message,
                                }}
                              />
                            );
                          }}
                        />
                      </li>
                      <li data-label="Clinic Site">
                        <Controller
                          name="clinicName"
                          control={control}
                          render={({ field: { ref, ...rest } }) => (
                            <TextField
                              label="Clinic Site"
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              {...rest}
                              {...{
                                error: !!errors?.clinicName,
                                helperText: errors?.clinicName?.message,
                              }}
                            />
                          )}
                        />
                      </li>
                      <li data-label="Location">
                        <Controller
                          name="location"
                          control={control}
                          render={({ field: { ref, ...rest } }) => (
                            <TextField
                              label="Clinic Location"
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              {...rest}
                              {...{
                                error: !!errors?.location,
                                helperText: errors?.location?.message,
                              }}
                            />
                          )}
                        />
                      </li>
                      <li data-label="Vaccine Type">
                        <Controller
                          name="vaccineType"
                          control={control}
                          render={({ field: { ref, ...rest } }) => (
                            <TextField
                              label="Vaccine Type"
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              {...rest}
                              {...{
                                error: !!errors?.vaccineType,
                                helperText: errors?.vaccineType?.message,
                              }}
                            />
                          )}
                        />
                      </li>
                      <li data-label="Vaccine Batch Number">
                        <Controller
                          name="vaccineBatchNumber"
                          control={control}
                          render={({ field: { ref, ...rest } }) => (
                            <TextField
                              label="Vaccine Batch Number"
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              {...rest}
                              {...{
                                error: !!errors?.vaccineBatchNumber,
                                helperText: errors?.vaccineBatchNumber?.message,
                              }}
                            />
                          )}
                        />
                      </li>

                      <li data-label="Vaccine Expiry Date">
                        <Controller
                          name="vaccineExpiryDate"
                          control={control}
                          // rules={{ required: "Field is required" }}
                          render={({ field: { ref, ...rest } }) => {
                            return (
                              // Store as free text field due to inconsistent historical values.
                              <TextField
                                label="Vaccine Expiry Date"
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                                {...rest}
                                {...{
                                  error: !!errors?.vaccineExpiryDate,
                                  helperText:
                                    errors?.vaccineExpiryDate?.message,
                                }}
                              />
                            );
                          }}
                        />
                      </li>

                      {vaccine?.pathogen === Pathogens["Covid"] && (
                        <li data-label="Covid Defrost Expiry Date">
                          <>
                            <Controller
                              name="vaccineDefrostDate"
                              control={control}
                              defaultValue={null}
                              render={({ field: { ref, ...rest } }) => {
                                return (
                                  <KeyboardDatePickerDateOnly
                                    label="Covid Defrost Expiry Date"
                                    inputVariant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    {...rest}
                                    {...{
                                      autoOk: true,
                                      format: "DD/MM/YYYY",
                                      error: !!errors?.vaccineDefrostDate,
                                      helperText:
                                        errors?.vaccineDefrostDate?.message,
                                    }}
                                  />
                                );
                              }}
                            />
                          </>
                        </li>
                      )}

                      <li data-label="Vaccinator">
                        <Controller
                          name="vaccinator"
                          control={control}
                          render={({ field: { ref, ...rest } }) => (
                            <TextField
                              label="Vaccinator"
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              {...rest}
                              {...{
                                error: !!errors?.vaccinator,
                                helperText: errors?.vaccinator?.message,
                              }}
                            />
                          )}
                        />
                      </li>
                      <li data-label="Injection Site">
                        <Controller
                          name="injectionSite"
                          control={control}
                          render={({ field: { ref, ...rest } }) => (
                            <TextField
                              label="Injection Site"
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              {...rest}
                              {...{
                                error: !!errors?.injectionSite,
                                helperText: errors?.injectionSite?.message,
                              }}
                            />
                          )}
                        />
                      </li>
                      <li data-label="Prescribing Method">
                        <Controller
                          name="prescribingMethod"
                          control={control}
                          render={({ field: { ref, ...rest } }) => (
                            <FormControl
                              variant="outlined"
                              fullWidth
                              className="select-fix"
                            >
                              <InputLabel
                                shrink={true}
                                id="prescribing-method-select-label"
                              >
                                Prescribing Method
                              </InputLabel>
                              <Select
                                labelId="prescribing-method-select-label"
                                // label="Prescribing Method"
                                variant="outlined"
                                fullWidth={false}
                                {...rest}
                                {...{
                                  error: !!errors?.prescribingMethod,
                                  helperText:
                                    errors?.prescribingMethod?.message,
                                }}
                                placeholder="Select Prescribing Method"
                              >
                                {Object.entries(enumPrescribingMethods)
                                  .filter(([key, value]) => {
                                    return isNaN(Number(key));
                                  })
                                  .map(([key, value]) => (
                                    <MenuItem key={key} value={key}>
                                      {key}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          )}
                        />
                      </li>
                      <li data-label="Known Allergies">
                        <Controller
                          name="allergyStatus"
                          control={control}
                          render={({ field: { ref, ...rest } }) => (
                            <TextField
                              label="Known Allergies"
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              {...rest}
                              {...{
                                error: !!errors?.allergyStatus,
                                helperText: errors?.allergyStatus?.message,
                              }}
                            />
                          )}
                        />
                      </li>
                      <li data-label="Other Information">
                        <Controller
                          name="otherInformation"
                          control={control}
                          render={({ field: { ref, ...rest } }) => (
                            <TextField
                              label="Other Information"
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              {...rest}
                              {...{
                                error: !!errors?.otherInformation,
                                helperText: errors?.otherInformation?.message,
                              }}
                            />
                          )}
                        />
                      </li>
                    </ul>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "0.5em",
                      }}
                    >
                      <FieldDisplay label="Date">
                        {humanDate(_record.date) || "-"}
                      </FieldDisplay>

                      <FieldDisplay label="Time">
                        {(_record.time && humanTime(_record.time)) || "-"}
                      </FieldDisplay>

                      <FieldDisplay label="Clinic Site">
                        {_record.clinicName || "-"}
                      </FieldDisplay>

                      <FieldDisplay label="Clinic Location">
                        {_record.location || "-"}
                      </FieldDisplay>

                      <FieldDisplay label="Vaccine Type">
                        {_record.vaccineType || "-"}
                      </FieldDisplay>

                      <FieldDisplay label="Vaccine Batch Number">
                        {_record.vaccineBatchNumber || "-"}
                      </FieldDisplay>

                      <FieldDisplay label="Vaccine Expiry Date">
                        {/* Display as free text field due to inconsistent historical values. */}
                        {/* Active records *are* stored as dates, but returned from the API as formatted date strings in the format `dd MMMM yyyy`. */}
                        {_record.vaccineExpiryDate || "-"}
                      </FieldDisplay>

                      {vaccine?.pathogen === Pathogens["Covid"] && (
                        <FieldDisplay label="Covid Defrost Expiry Date">
                          {_record.vaccineDefrostDate || "-"}
                        </FieldDisplay>
                      )}

                      <FieldDisplay label="Vaccinator">
                        {_record.vaccinator || "-"}
                      </FieldDisplay>

                      <FieldDisplay label="Injection Site">
                        {_record.injectionSite || "-"}
                      </FieldDisplay>

                      <FieldDisplay label="Prescribing Method">
                        {_record.prescribingMethod || "-"}
                      </FieldDisplay>

                      <FieldDisplay label="Known Allergies">
                        {_record.allergyStatus || "-"}
                      </FieldDisplay>

                      <FieldDisplay label="Other Information">
                        {_record.otherInformation || "-"}
                      </FieldDisplay>
                    </div>
                  )}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={6} className="_actions">
              {allowEdit && (
                <>
                  {editMode ? (
                    <>
                      <form
                        autoComplete="off"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="secondary"
                          size="small"
                          disabled={disabled}
                        >
                          Update
                        </Button>
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            setEditMode(false);
                          }}
                          variant="outlined"
                          size="small"
                          disabled={disabled}
                        >
                          Cancel
                        </Button>
                      </form>
                    </>
                  ) : (
                    // <Tooltip title="Edit Vaccination Record">
                    //   <IconButton onClick={() => setEditMode(true)}>
                    //     <EditIcon fontSize="small" color="primary" />
                    //   </IconButton>
                    //   </Tooltip>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        setEditMode(true);
                      }}
                      variant="contained"
                      color="secondary"
                      size="small"
                      disabled={disabled}
                    >
                      Edit
                    </Button>
                  )}
                </>
              )}
            </td>
          </tr>
        </>
      )}
      {alert}
    </tbody>
  );
}
