// Myke: Trying to collate table styles in one place!
export const palette = {
  primary: {
    main: "#283583",
    contrastText: "#fff",
  },
  secondary: {
    main: "#009fe3",
    contrastText: "#fff",
  },
  error: {
    main: "#be584e",
    contrastText: "#fff",
  },
  action: {
    selected: "#d4dcf1",
  },
  inactive: {
    main: "lightgray",
    contrastText: "#000",
  },
  present: {
    main: "#a9dcf2",
    contrastText: "#000",
  },
  help: {
    main: "#40ae49",
    contrastText: "#fff",
  },
  walkaround: {
    main: "#57a24f",
    contrastText: "white",
  },
};
export const font1 = `"omnes-pro", "Roboto", "Helvetica", "sans-serif"`;
// export const font2 = `"Roboto", "Helvetica", "sans-serif"`; // if client wants default material styles back
export const themes = {
  "1": { main: "#169bd8", contrastText: "#000" },
  "2": { main: "#c80089", contrastText: "#fff" },
  "1|2": { main: "#35a444", contrastText: "#000" },
};
