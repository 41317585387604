import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import {
  Delete as DeleteIcon,
  Event as EventIcon,
  KeyboardBackspace as BackIcon,
} from "@material-ui/icons";
import { DateTime } from "luxon";
import BookingForm from "../../Patients/BookAppointment/BookingForm";
import AppointmentDetails from "../../Patients/MyAppointments/AppointmentDetails";

function ViewAppointmentDialog({
  open,
  onClose,
  onCancel,
  onReschedule,
  item,
  label,
}) {
  return (
    open && (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
        <DialogContent>
          {item ? (
            <AppointmentDetails result={item} label={label} />
          ) : (
            <p>No known appointment</p>
          )}
        </DialogContent>
        <DialogActions>
          {onReschedule && (
            <Button
              onClick={onReschedule}
              type="submit"
              variant="contained"
              color="secondary"
              autoFocus
              startIcon={<EventIcon />}
            >
              Reschedule Appointment
            </Button>
          )}

          {onCancel && (
            <Button
              onClick={onCancel}
              variant="contained"
              startIcon={<DeleteIcon />}
            >
              Cancel Appointment
            </Button>
          )}

          <Button
            onClick={onClose}
            variant="outlined"
            color="secondary"
            style={{
              color: "#fff",
              marginLeft: "auto",
            }}
            startIcon={<BackIcon />}
          >
            Back
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
}

function BookAppointmentDialog({
  open,
  onClose,
  onSuccess,
  item,
  pathogens,
  reschedule,
}) {
  return item ? (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xl"
      className="-fill-height -gradient-fade"
    >
      <DialogContent>
        <h1>{reschedule ? "Reschedule" : "Book"} Vaccination Appointment</h1>
        <BookingForm
          trustWorkerId={item.id}
          nhsNumber={item.nhsNumber}
          onSuccess={onSuccess}
          pathogens={pathogens}
          reschedule={reschedule}
          dateOverride={
            item.lastCovidDoseDate
              ? DateTime.fromISO(item.lastCovidDoseDate)
              : null
          }
          isForStaff={true}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          color="secondary"
          style={{
            color: "#fff",
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
}

export { ViewAppointmentDialog, BookAppointmentDialog };
