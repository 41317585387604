import { Button, CircularProgress, DialogActions } from "@material-ui/core";
import { KeyboardBackspace as BackIcon } from "@material-ui/icons/";
import { startCase } from "lodash";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Link, RouteComponentProps, useHistory } from "react-router-dom";
import ApiAlert from "../../../../components/ApiAlert";
import KeyboardDatePickerDateOnly from "../../../../components/KeyboardDatePickerDateOnly";
import { removeZeroFields } from "../../../../lib/common_utils";
import { Pathogens, shouldShowClinicianNames } from "../../../../lib/pathogens";
import DataClient from "../../../../lib/services/api/DataClient";
import {
  MasterVaccineBatch,
  vaccineBatchInformalName,
} from "../Settings/MasterVaccineBatches/List";
import {
  fetchVaccinators,
  humanVaccinatorNames,
  selectOptions,
} from "../Vaccinators";

type MasterVaccineBatchSelectionStatus = "pending" | "selected" | "unselected";

const ErrorMessage = (err: any) =>
  err?.message ? (
    <p className="error" style={{ height: 0 }}>
      {err.message}
    </p>
  ) : null;

interface MasterVaccineBatchFieldValues {
  id: number | null;
}

interface MasterVaccineBatchSelectionProps {
  clinicId: number;
  pathogenId: number;
  pathogenName: string;
  isLoading: boolean;
  masterVaccineBatches: MasterVaccineBatch[];
  onSubmit: SubmitHandler<MasterVaccineBatchFieldValues>;
}

const MasterVaccineBatchSelection: FunctionComponent<
  MasterVaccineBatchSelectionProps
> = ({
  clinicId,
  pathogenId,
  pathogenName,
  isLoading,
  masterVaccineBatches,
  onSubmit,
}) => {
  const { formState, handleSubmit, register, watch, reset } =
    useForm<MasterVaccineBatchFieldValues>({
      defaultValues: {
        id: -1,
      },
    });

  const { errors } = formState;

  const id = watch("id");
  const masterVaccineBatch = masterVaccineBatches.find(
    (masterVaccineBatch) => masterVaccineBatch.id === id,
  );
  const hasMasterVaccineBatches = !isLoading && masterVaccineBatches.length > 0;

  useEffect(() => {
    if (!isLoading) {
      reset({
        id: hasMasterVaccineBatches ? -1 : 0,
      });
    }
  }, [isLoading, hasMasterVaccineBatches, reset]);

  return (
    <>
      <p>
        All batches available in your Trust should already be pre-added and
        available to select from the drop-down menu below. This saves you
        entering the information manually for each clinic.
      </p>
      <p>
        If no batches have been pre-added by your trust yet, you can manually
        enter the details by selecting 'Manually enter batch details' from the
        drop-down menu.
      </p>
      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <section className="section-double-left-align">
          <section>
            <select
              {...register("id", {
                valueAsNumber: true,
              })}
              disabled={!hasMasterVaccineBatches}
            >
              {hasMasterVaccineBatches ? (
                <option value={-1}>
                  Select{" "}
                  {pathogenId === Pathogens.Flu
                    ? pathogenName.toLowerCase()
                    : pathogenName}{" "}
                  vaccine batch
                </option>
              ) : null}
              {masterVaccineBatches.map((masterVaccineBatch) => (
                <option
                  key={masterVaccineBatch.id}
                  value={masterVaccineBatch.id}
                >
                  {vaccineBatchInformalName({
                    vaccinationTypeName:
                      masterVaccineBatch.vaccinationType?.name,
                    vaccinationBatchNumber:
                      masterVaccineBatch.vaccinationBatchNumber,
                    expiryDate: masterVaccineBatch.expiryDate,
                  })}
                </option>
              ))}
              {hasMasterVaccineBatches ? (
                <>
                  <option disabled value={0}>
                    --------------
                  </option>
                  <option value={0}>Manually enter batch details</option>
                </>
              ) : (
                <option value={0}>No pre-added batches available</option>
              )}
            </select>
            {ErrorMessage(errors.id)}
          </section>
        </section>

        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            autoFocus
            disabled={id === -1}
          >
            {masterVaccineBatch
              ? "Choose selected batch"
              : "Manually enter batch details"}
          </Button>
          <Button
            component={Link}
            to={`/clinics/${clinicId}/vaccine-batches/${pathogenId}`}
            variant="outlined"
            color="secondary"
            style={{
              color: "#fff",
            }}
            startIcon={<BackIcon />}
          >
            Back
          </Button>
        </DialogActions>
      </form>
    </>
  );
};

export default (
  props: RouteComponentProps<{
    id?: string;
    clinicId: string;
    pathogenId: string;
  }> & { mode: "add" | "edit" },
): JSX.Element => {
  const history = useHistory();
  const mode = props.mode || "add";
  const id = props.match.params.id ? parseInt(props.match.params.id) : null;
  const clinicId = parseInt(props.match.params.clinicId);
  const pathogenId = parseInt(props.match.params.pathogenId);
  const pathogenName = Pathogens[pathogenId];
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState(null);
  const [masterVaccineBatches, setMasterVaccineBatches] = useState<
    MasterVaccineBatch[]
  >([]);
  const [
    masterVaccineBatchSelectionStatus,
    setMasterVaccineBatchSelectionStatus,
  ] = useState<MasterVaccineBatchSelectionStatus>("pending");
  const [vaccinationTypes, setVaccinationTypes] = useState([]);
  const [vaccinatorNames, setVaccinatorNames] = useState([]);
  const {
    getValues,
    handleSubmit,
    register,
    unregister,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    // shouldUseNativeValidation: true,
  });
  const [showCustomVaccinatorInput, setShowCustomVaccinatorInput] =
    useState(false);
  const showClinicianNames = shouldShowClinicianNames(pathogenId);

  useEffect(() => {
    // Promises, promises..
    Promise.all([
      DataClient.getData(`/Clinic/${clinicId}`),
      DataClient.postData(`/ClinicMasterVaccinationBatch/Search`, {
        pageSize: -1,
        pathogens: [pathogenId],
      }),
      DataClient.postData(`/VaccinationType/Search`, {
        pageSize: -1,
        pathogens: [pathogenId],
      }),
      fetchVaccinators(),
    ])
      .then((res) => {
        // Handle API failures
        res.forEach(({ success }, i) => {
          if (!success) {
            throw new Error(`Failed to fetch correct data (${i})`);
          }
        });
        const resClinic = res[0].results;
        const { vaccinationBatchInfos } = resClinic;
        const vaccinationBatchInfo = vaccinationBatchInfos?.find(
          (b: any) => b?.id === id,
        );
        const masterVaccineBatches = res[1].results || [];
        setMasterVaccineBatches(masterVaccineBatches);
        const resVaccinationTypes = res[2].results || [];
        setVaccinationTypes(resVaccinationTypes);
        if (showClinicianNames) {
          const resVaccinators = res[3].results?.pagedResults || [];
          const vaccinatorNames = humanVaccinatorNames(resVaccinators);
          console.log(`vaccinatorNames: `, vaccinatorNames);
          setVaccinatorNames(vaccinatorNames);
        }
        if (vaccinationBatchInfo) {
          const {
            expiryDate,
            defrostDate,
            vaccinationBatchNumber,
            vaccinationTypeId,
            clinicianName,
          } = vaccinationBatchInfo || {};
          reset({
            // API appears to return these randomly as 0 or null
            expiryDate: expiryDate || null,
            defrostDate: defrostDate || null,
            vaccinationBatchNumber: vaccinationBatchNumber || "",
            vaccinationTypeId: vaccinationTypeId || null,
            clinicianName: clinicianName || null,
          });
        }
        setLoading(false);
      })
      .catch((e) => {
        // API not returning what we expect
        setAlert(ApiAlert({ res: e.message || e.response, alwaysShow: true }));
        setLoading(false);
      });
  }, [reset, pathogenId]);

  const onSubmitMasterVaccineBatchForm = useCallback(
    (fieldValues: MasterVaccineBatchFieldValues) => {
      const masterVaccineBatch = masterVaccineBatches.find(
        (masterVaccineBatch) => masterVaccineBatch.id === fieldValues.id,
      );

      reset({
        vaccinationTypeId: masterVaccineBatch?.vaccinationType?.id,
        vaccinationBatchNumber: masterVaccineBatch?.vaccinationBatchNumber,
        expiryDate: masterVaccineBatch?.expiryDate,
      });

      if (masterVaccineBatch) {
        setMasterVaccineBatchSelectionStatus("selected");
      } else {
        setMasterVaccineBatchSelectionStatus("unselected");
      }
    },
    [masterVaccineBatches, reset, setMasterVaccineBatchSelectionStatus],
  );

  const onSubmitVaccineBatchForm = (data: any) => {
    // console.log("::submit", data);
    // Select defaults to 0 if they're valueAsNumber so sack those off
    data = removeZeroFields({
      data,
      fieldsThatDontWantZero: ["vaccinationTypeId"],
    });
    data.pathogenId = pathogenId;
    setLoading(true);
    const endpoint =
      mode === "add"
        ? DataClient.postData(`/Clinic/${clinicId}/AddClinicBatchDetails`, data)
        : DataClient.update(`/Clinic/UpdateClinicBatchDetails/${id}`, data);
    endpoint
      .then((res) => {
        setLoading(false);
        if (res.success) {
          history.push(`/clinics/${clinicId}/vaccine-batches/${pathogenId}`);
        }
        // Validation catch
        return setAlert(ApiAlert({ res }));
      })
      .catch((e) => {
        // Interal server error catch
        setAlert(ApiAlert({ res: e.response }));
        setLoading(false);
      });
  };

  return (
    <main className="wrapper-main">
      <h1>
        {startCase(mode)} {pathogenName} Vaccine Batch
      </h1>

      {alert ? (
        alert
      ) : mode === "add" && masterVaccineBatchSelectionStatus === "pending" ? (
        <MasterVaccineBatchSelection
          clinicId={clinicId}
          pathogenId={pathogenId}
          pathogenName={pathogenName}
          isLoading={loading}
          masterVaccineBatches={masterVaccineBatches}
          onSubmit={onSubmitMasterVaccineBatchForm}
        />
      ) : (
        <form
          onSubmit={handleSubmit(onSubmitVaccineBatchForm)}
          className="form"
        >
          {/* Candidate for moving this to a component. */}
          {/* TODO style from Liam's form.module.scss when it's merged in */}
          {masterVaccineBatchSelectionStatus === "selected" ? (
            <p>
              {pathogenId === Pathogens.Covid
                ? "Please enter a defrost expiry date and select a clinician drawing up the vaccine from the drop down menu."
                : "Check the batch details are correct, then click 'Add batch to clinic'. Click 'Back' to select a different batch."}
            </p>
          ) : null}

          <section>
            <label>Vaccine Type</label>
            <select
              {...register("vaccinationTypeId", {
                valueAsNumber: true,
                required: "Please enter a vaccine type",
              })}
              disabled={masterVaccineBatchSelectionStatus === "selected"}
            >
              <option value="">Please Select</option>
              {vaccinationTypes.map(
                ({ id, name }: { id: string; name: string }, i) => (
                  <option value={parseInt(id)} key={`to-${i}`}>
                    {name}
                  </option>
                ),
              )}
            </select>
            {ErrorMessage(errors.vaccinationTypeId)}
          </section>
          <section>
            <label>Batch Number</label>
            <input
              style={{ textTransform: "uppercase" }}
              {...register("vaccinationBatchNumber", {
                required: "Please enter a batch number",
                // pattern: {
                //   value: /^[0-9\s]*$/,
                //   message: "Please only enter numbers and spaces.",
                // },
              })}
              disabled={masterVaccineBatchSelectionStatus === "selected"}
            />
            {ErrorMessage(errors.vaccinationBatchNumber)}
          </section>
          <section className="section-double-left-align">
            <section>
              <label>Expiry Date (DD/MM/YYYY)</label>
              <Controller
                name="expiryDate"
                control={control}
                rules={{ required: "Please enter an expiry date" }}
                defaultValue={null}
                render={({ field: { ref, ...rest } }) => {
                  return (
                    <KeyboardDatePickerDateOnly
                      inputVariant="outlined"
                      InputLabelProps={{ shrink: true }}
                      {...rest}
                      {...{
                        autoOk: true,
                        format: "DD/MM/YYYY",
                        disabled:
                          masterVaccineBatchSelectionStatus === "selected",
                      }}
                    />
                  );
                }}
              />
              {ErrorMessage(errors.expiryDate)}
            </section>

            <section>
              {pathogenId === Pathogens["Covid"] && (
                <>
                  <label>Covid Defrost Expiry Date (DD/MM/YYYY)</label>
                  <Controller
                    name="defrostDate"
                    control={control}
                    rules={{
                      required: "Please enter a covid defrost expiry date",
                    }}
                    defaultValue={null}
                    render={({ field: { ref, ...rest } }) => {
                      return (
                        <KeyboardDatePickerDateOnly
                          inputVariant="outlined"
                          InputLabelProps={{ shrink: true }}
                          {...rest}
                          {...{
                            autoOk: true,
                            format: "DD/MM/YYYY",
                          }}
                        />
                      );
                    }}
                  />
                  {ErrorMessage(errors.defrostDate)}
                </>
              )}
            </section>
          </section>

          {showClinicianNames && (
            <section>
              <label>Clinician Drawing Up Vaccine</label>
              <select
                name="clinicianName"
                {...register("clinicianName", {
                  required:
                    "Please enter the clinician drawing up the vaccine. If they are not on the drop down list, scroll to the bottom and select 'Other...' then enter their name manually.",
                })}
                onChange={(e) => {
                  if (e.target.value === "Other") {
                    unregister("clinicianName");
                    setShowCustomVaccinatorInput(true);
                    e.target.value = "";
                  } else {
                    // Force update as new item not selected after switching from custom Other back to d/down.
                    setValue("clinicianName", e.target.value);
                    setShowCustomVaccinatorInput(false);
                  }
                }}
              >
                <option value="">Please Select</option>
                {selectOptions(vaccinatorNames, getValues("clinicianName"))}
                <option disabled value="">
                  --------------
                </option>
                <option value="Other">Other...</option>
              </select>
              {!showCustomVaccinatorInput && ErrorMessage(errors.clinicianName)}
            </section>
          )}
          {showClinicianNames && showCustomVaccinatorInput && (
            <section>
              <label>Other Clinician Name</label>
              <input
                {...register("clinicianName", {
                  required:
                    "Please enter the clinician drawing up the vaccine.",
                  value: "",
                })}
              />
              {showCustomVaccinatorInput && ErrorMessage(errors.clinicianName)}
            </section>
          )}
          <DialogActions>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              autoFocus
            >
              Add batch to clinic
            </Button>
            {mode === "add" ? (
              <Button
                variant="outlined"
                color="secondary"
                style={{
                  color: "#fff",
                }}
                startIcon={<BackIcon />}
                onClick={() => setMasterVaccineBatchSelectionStatus("pending")}
              >
                Back
              </Button>
            ) : (
              <Button
                component={Link}
                to={`/clinics/${clinicId}/vaccine-batches/${pathogenId}`}
                variant="outlined"
                color="secondary"
                style={{
                  color: "#fff",
                }}
                startIcon={<BackIcon />}
              >
                Back
              </Button>
            )}
          </DialogActions>
        </form>
      )}
      {loading && (
        <div className="interstitial">
          <CircularProgress />
        </div>
      )}
    </main>
  );
};
