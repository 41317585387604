import React from "react";
import ReactDOM from "react-dom";
import DataClient from "../../../../lib/services/api/DataClient";
import { TrustGroupUser } from "./useTrustGroupUserList";

export default function useUpdateTrustGroupUser() {
  const [success, setSuccess] = React.useState<false | TrustGroupUser>(false);
  const [error, setError] = React.useState(false);
  const [warning, setWarning] = React.useState<boolean | string[]>(false);

  const callback = (id: number, update: TrustGroupUser) => {
    setSuccess(false);
    DataClient.update(`/TrustGroupUser/${id}`, {
      firstName: update.firstName,
      lastName: update.lastName,
      emailAddress: update.emailAddress
    })
      .then((res: unknown) => {
        ReactDOM.unstable_batchedUpdates(() => {
          if (res["success"]) {
            setSuccess({ ...update, id });
          } else {
            console.log(res);
            throw new Error("API Update failed");
          }
        });
      })
      .catch((error) => {
        ReactDOM.unstable_batchedUpdates(() => {
          if (error?.response?.status === 422) {
            setWarning(error.response?.data?.results);
          } else if (error?.response?.status === 409) {
            setError(
              error.response?.data?.results || error.response?.data?.errors
            );
          } else {
            console.error(error);
            setError(true);
          }
        });
      });
  };

  return [callback, success, error, warning] as const;
}
