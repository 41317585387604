import { Button, LinearProgress } from "@material-ui/core";
import { Add, Delete, Edit } from "@material-ui/icons";
import {
  Dispatch,
  FunctionComponent,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Link } from "react-router-dom";
import ApiAlert from "../../../../../components/ApiAlert";
import { humanDate } from "../../../../../lib/common_utils";
import { Pathogen, VaccinationType } from "../../../../../lib/pathogens";
import { getTrustPathogens } from "../../../../../lib/services/TrustSwitcher";
import DataClient from "../../../../../lib/services/api/DataClient";
import { palette } from "../../../../../lib/themes/vars";
import ConfirmDialog from "../../../ConfirmDialog";
import SettingsContainer from "../SettingsContainer";

export interface MasterVaccineBatch {
  id: number;
  trustId: number;
  pathogenId: number;
  pathogen: Pathogen;
  vaccinationTypeId?: number;
  vaccinationType?: VaccinationType;
  vaccinationBatchNumber?: string;
  expiryDate: Date;
  createdBy?: string;
  editedBy?: string;
  createdDate?: string;
  editedDate?: string;
}

type DeleteAction = { type: "delete"; masterVaccineBatch: MasterVaccineBatch };

type Action = DeleteAction;

export const vaccineBatchInformalName = ({
  vaccinationTypeName,
  vaccinationBatchNumber,
  expiryDate,
}: {
  vaccinationTypeName?: string;
  vaccinationBatchNumber?: string;
  expiryDate?: Date;
}): string =>
  [vaccinationTypeName, vaccinationBatchNumber, humanDate(expiryDate)]
    .filter((part) => Boolean(part))
    .join(" - ") || "Unspecified";

interface MasterVaccineBatchesTableProps {
  pathogen: Pathogen;
  masterVaccineBatches: MasterVaccineBatch[];
  setAction: Dispatch<SetStateAction<Action | null>>;
}

const MasterVaccineBatchesTable: FunctionComponent<
  MasterVaccineBatchesTableProps
> = ({ pathogen, masterVaccineBatches, setAction }) => {
  return (
    <>
      <h2>{pathogen.name} Master Vaccine Batches</h2>
      <p>
        <Button
          component={Link}
          to={`/settings/master-vaccine-batches/${pathogen.id}/add`}
          color="primary"
          variant="contained"
          className="theme-button"
          data-theme-id={pathogen.id}
          startIcon={<Add />}
        >
          Add {pathogen.name} Master Vaccine Batch
        </Button>
      </p>

      <div style={{ width: "100%", overflow: "auto", marginBottom: "2em" }}>
        <table
          className="table table-batches table-fonts"
          style={{ marginBottom: 0, width: "auto" }}
        >
          <thead>
            <tr>
              <th>Vaccine Type</th>
              <th>Batch Number</th>
              <th>Expiry Date</th>
              <th>Added By</th>
              <th>Added Date</th>
              <th>Last Edited By</th>
              <th>Last Edited Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {masterVaccineBatches.map((masterVaccineBatch) => (
              <tr key={masterVaccineBatch.id}>
                <td data-label="Vaccine Type">
                  {masterVaccineBatch.vaccinationType?.name || " - "}
                </td>
                <td data-label="Batch Number">
                  {masterVaccineBatch.vaccinationBatchNumber || " - "}
                </td>
                <td data-label="Expiry Date">
                  {humanDate(masterVaccineBatch.expiryDate) || " - "}
                </td>
                <td data-label="Created by">
                  <div
                    style={{
                      minWidth: "8rem",
                      overflow: "auto",
                      overflowWrap: "anywhere",
                    }}
                  >
                    {masterVaccineBatch.createdBy}
                  </div>
                </td>
                <td data-label="Date Created">
                  {humanDate(masterVaccineBatch.createdDate)}
                </td>
                <td data-label="Edited by">
                  <div
                    style={{
                      minWidth: "6rem",
                      overflow: "auto",
                      overflowWrap: "anywhere",
                    }}
                  >
                    {masterVaccineBatch.editedBy}
                  </div>
                </td>
                <td data-label="Date Last Edited">
                  {humanDate(masterVaccineBatch.editedDate)}
                </td>
                <td data-label="">
                  <div className="_actions">
                    <Button
                      component={Link}
                      to={`/settings/master-vaccine-batches/${pathogen.id}/${masterVaccineBatch.id}/edit`}
                      variant="contained"
                      color="secondary"
                      startIcon={<Edit />}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outlined"
                      startIcon={<Delete />}
                      style={{
                        color: palette.error.main,
                        borderColor: palette.error.main,
                      }}
                      onClick={() =>
                        setAction({
                          type: "delete",
                          masterVaccineBatch: masterVaccineBatch,
                        })
                      }
                    >
                      Delete
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export const MasterVaccineBatchesList: FunctionComponent = () => {
  const pathogens = getTrustPathogens();

  const [isLoading, setIsLoading] = useState(true);
  const [masterVaccineBatches, setMasterVaccineBatches] = useState<
    MasterVaccineBatch[] | null
  >(null);
  const [action, setAction] = useState<Action | null>(null);
  const [alert, setAlert] = useState<ReactNode>(null);

  const fetchMasterVaccineBatches = useCallback(async () => {
    setIsLoading(true);

    try {
      const { results: masterVaccineBatches, success } =
        await DataClient.postData("ClinicMasterVaccinationBatch/Search", {});

      if (!success) {
        throw new Error();
      }

      setMasterVaccineBatches(masterVaccineBatches);
    } catch (error) {
      const alert = ApiAlert({
        alwaysShow: true,
        message: "Could not load vaccine batches",
      });

      setAlert(alert);
    } finally {
      setIsLoading(false);
    }
  }, [setMasterVaccineBatches, setIsLoading, setAlert]);

  const handleDelete = useCallback(async () => {
    if (action.type === "delete") {
      try {
        const result = await DataClient.remove({
          url: `ClinicMasterVaccinationBatch/${action.masterVaccineBatch.id}`,
        });

        if (!result.success) {
          throw new Error();
        }
      } catch (error) {
        const alert = ApiAlert({
          alwaysShow: true,
          message: "Could not delete vaccine batch",
        });

        setAlert(alert);
      } finally {
        await fetchMasterVaccineBatches();
        setAction(null);
      }
    }
  }, [action, fetchMasterVaccineBatches, setAction, setAlert]);

  const handleDeleteClose = useCallback(() => {
    setAction(null);
  }, [setAction]);

  useEffect(() => {
    fetchMasterVaccineBatches();
  }, [fetchMasterVaccineBatches]);

  return (
    <SettingsContainer>
      {isLoading ? <LinearProgress /> : null}

      <h1>Master Vaccine Batches</h1>

      {alert}

      <p>
        A master vaccine batch can only be added by a trust admin. It is a batch
        that is available for all vaccinators to add to their clinics. It means
        that they don't need to manually enter a batch at the start of the
        clinic. They just select the correct master batch from a pre-populated
        drop down list. This saves them time and more importantly it prevents
        errors resulting from the manual inputting of batch information by
        vaccinators.
      </p>

      <p>
        Vaccinators are still able to add their own batch in a clinic is
        necessary but adding all available batches in the trust here as master
        batches and communicating clearly with the vaccinators, they should
        never need to enter their own batch info. This is a new feature for the
        24/25 campaign, so make Vaccinators who used the were used to having to
        adding the batch info themselves at the start of a clinic so if you do
        use Master batches make sure they are aware of the change.
      </p>

      <br />

      {masterVaccineBatches
        ? pathogens.map((pathogen) => (
            <MasterVaccineBatchesTable
              key={pathogen.id}
              pathogen={pathogen}
              masterVaccineBatches={masterVaccineBatches.filter(
                (masterVaccineBatche) =>
                  masterVaccineBatche.pathogenId === pathogen.id,
              )}
              setAction={setAction}
            />
          ))
        : null}

      {action && action.type === "delete" ? (
        <ConfirmDialog
          title="Delete Master Vaccine Batch"
          open={action.type === "delete"}
          onConfirm={handleDelete}
          onClose={handleDeleteClose}
        >
          <p>
            Are you sure you want to delete{" "}
            <strong>
              {vaccineBatchInformalName({
                vaccinationTypeName:
                  action.masterVaccineBatch.vaccinationType?.name,
                vaccinationBatchNumber:
                  action.masterVaccineBatch.vaccinationBatchNumber,
                expiryDate: action.masterVaccineBatch.expiryDate,
              }) || "this batch"}
            </strong>
            ?
          </p>
        </ConfirmDialog>
      ) : null}
    </SettingsContainer>
  );
};
