import { differenceWith, isEqual } from "lodash";
import moment from "moment";
import { pascalCaseToSentence } from "../../lib/common_utils";
import { Pathogen, VaccinationType } from "../../lib/pathogens";
import { clinicEventDetailsMapper } from "./ClinicEventDetailsMapper";
import { ChangesetType } from "./EventLogData";

const differenceExclusions = [
  // Hide object specific clinic changes
  /Clinics\[.*\]/,
];

/**
 *Looks up the correct component based on the change event type and returns the component displaying the relevant data.
 *
 * @export
 * @param {{expandedRowData: ClinicChangesetDetailedDTO}}
 * @return {JSX.Element}
 */
export function ClinicEventDetails<
  ChangesetDetailedDTO extends { type: ChangesetType; data: any }
>({ expandedRowData }: { expandedRowData: ChangesetDetailedDTO }): JSX.Element {
  const changeType = ChangesetType[expandedRowData.type];

  const rowData = expandedRowData.data;

  /** Maps the ChangesetType to the relevant view type */
  const detailsComponentLookup = {
    CreateSingleClinic: () =>
      ClinicRepeatingGroup("Created clinic:", expandedRowData),
    UpdateSingleClinic: () => Differences("Clinic changes:", expandedRowData),
    CancelSingleClinic: () =>
      ClinicRepeatingGroup("Cancelled clinic:", expandedRowData),
    CreateRecurringClinc: () =>
      ClinicRepeatingGroup("Created recurring clinic:", expandedRowData), // ooh is this a typo? I copy+pasted it
    UpdateRecurringClinic: () =>
      Differences("Recurring clinic changes:", expandedRowData),
    CancelRecurringClinic: () =>
      ClinicRepeatingGroup("Cancelled recurring clinic:", expandedRowData),
    SuspendClinic: () => Differences("Suspended clinic:", expandedRowData),
    EnableClinic: () => Differences("Enabled clinic:", expandedRowData),
    CloseClinic: () => Differences("Closed clinic:", expandedRowData),
    AddClinicVaccinationBatch: () =>
      ClinicVaccinationBatchInfo("Added vaccination batch:", expandedRowData),
    DeleteClinicVaccinationBatch: () =>
      ClinicVaccinationBatchInfo("Deleted vaccination batch:", expandedRowData),
    UpdateClinicVaccinationBatch: () =>
      Differences("Vaccination batch changes:", expandedRowData),
    UpdatePrimaryClinicVaccinationBatch: () =>
      ClinicVaccinationBatchInfo(
        "Primary vaccination batch set to:",
        expandedRowData
      ),
    SplitClinic: () => ClinicRepeatingGroup("Split clinic:", expandedRowData),
    // Reservations all have same Type "Reservation"
    CreateReservation: () => Reservation("Appointment Booked", expandedRowData),
    RescheduleReservation: () =>
      RescheduleReservation("Rescheduled Appointment", expandedRowData),
    CancelReservation: () =>
      Reservation("Cancelled Appointment", expandedRowData),
    Reservation: () => Reservation("Appointment", expandedRowData),
  };
  console.log(`changeType: `, changeType);
  console.log(`expandedRowData: `, expandedRowData);
  const mappedExpandedRowData = clinicEventDetailsMapper(expandedRowData);
  return detailsComponentLookup[changeType](mappedExpandedRowData);
}

function upcaseID(string: string): string {
  const idWords = ["Id", "id"];
  return string
    .split(" ")
    .map((word) => (idWords.includes(word) ? "ID" : word))
    .join(" ");
}

function Differences(title: string, expandedRowData: any) {
  const differences = Object.entries(expandedRowData.data.Differences).reduce(
    (differences, [key, value]) => {
      if (!differenceExclusions.some((regex) => regex.test(key))) {
        differences[key] = value;
      }

      return differences;
    },
    {}
  );

  return (
    <div style={{ padding: "1em" }}>
      <h3 style={{ marginTop: 0 }}>{title}</h3>
      <div>{JsonToDataGrid(differences) || "No data"}</div>
    </div>
  );
}

function ClinicRepeatingGroup(
  title: string,
  expandedRowData: {
    data: { ClinicRepeatingGroup: ClinicRepeatingGroupDTO };
  }
) {
  const repeatingGroup = expandedRowData.data.ClinicRepeatingGroup;
  return (
    <div style={{ padding: "1em" }}>
      <h3 style={{ marginTop: 0 }}>{title}</h3>
      <div>{JsonToDataGrid(repeatingGroup)}</div>
    </div>
  );
}

function ClinicVaccinationBatchInfo(
  title: string,
  expandedRowData: {
    data: { ClinicVaccinationBatchInfo: ClinicVaccinationBatchInfoDTO };
  }
) {
  const clinicVaccinationBatchInfo =
    expandedRowData.data.ClinicVaccinationBatchInfo;
  return (
    <div style={{ padding: "1em" }}>
      <h3 style={{ marginTop: 0 }}>{title}</h3>
      <div>{JsonToDataGrid(clinicVaccinationBatchInfo)}</div>
    </div>
  );
}

function Reservation(
  title: string,
  expandedRowData: {
    data: { Reservation: any };
  }
) {
  const clinicVaccinationBatchInfo = expandedRowData.data.Reservation;
  return (
    <div key={expandedRowData.data["Id"]} style={{ padding: "1em" }}>
      <h3 style={{ marginTop: 0 }}>{title}</h3>
      <div>{JsonToDataGrid(clinicVaccinationBatchInfo)}</div>
    </div>
  );
}
function RescheduleReservation(
  title: string,
  expandedRowData: {
    data: { Differences: any };
  }
) {
  const clinicVaccinationBatchInfo = expandedRowData.data.Differences;
  return (
    <div key={expandedRowData.data["Id"]} style={{ padding: "1em" }}>
      <h3 style={{ marginTop: 0 }}>{title}</h3>
      <p>
        A rescheduled appointment is a combination of an appointment
        cancellation immediately followed by a new appointment booking. For full
        details see the corresponding cancellation and new booking above.
      </p>
      <div>{JsonToDataGrid(clinicVaccinationBatchInfo)}</div>
    </div>
  );
}
/**
 * Recursively Creates components based on JSON objects.
 * NB: self-referencing objects or others with infinite depth will cause rendering mayhem.
 *
 * @param {Record<string, any>} data
 * @return {*}
 */
function JsonToDataGrid(data: Record<string, any>) {
  const compiledView = (
    <>
      {Object.keys(data).map((key) => {
        if (isObject(data[key])) {
          return (
            TryPreviousCurrentPair(key, data[key]) ||
            TryListClinics(key, data[key]) ||
            TryRecursiveObject(key, data[key]) ||
            (() => {
              console.error(`Could not display object: ${data[key]}`);
              return false;
            })()
          );
        } else if (Array.isArray(data[key])) {
          return data[key].map(
            (iteratedObject: any) =>
              TryRecursiveObject(key, iteratedObject) ||
              (() => {
                console.error(
                  `Could not display array item: ${iteratedObject}`
                );
                return false;
              })()
          );
        } else {
          return (
            TryKeyValuePair(key, data[key]) ||
            (() => {
              if (data[key]) {
                console.error(
                  `Could not display anything: ${key}: ${data[key]}`
                );
              }
              return false;
            })()
          );
        }
      })}
    </>
  );
  if (!compiledView) console.error("Cannot display an item correctly");
  else return compiledView;
}

function TryRecursiveObject(key: string, object: Record<string, any>) {
  return (
    <dl
      style={{
        marginLeft: "1em",
        display: "inline-block",
        width: "100%",
      }}
      key={key + object["Id"]}
    >
      <dt>{upcaseID(pascalCaseToSentence(key))}: </dt>
      <dd>{JsonToDataGrid(object)}</dd>
    </dl>
  );
}

function TryKeyValuePair(key: string, value: string) {
  const isKeyValuePair = typeof key !== "object" && typeof value !== "object";
  if (!isKeyValuePair) return;
  return (
    <dl key={key + value} className="is-string" style={{ marginLeft: "1em" }}>
      <dt
        style={{
          display: "inline",
          flexWrap: "nowrap",
        }}
      >
        {upcaseID(pascalCaseToSentence(key))}:
      </dt>

      <dd
        style={{
          display: "inline",
          flexWrap: "nowrap",
          marginLeft: "0.2em",
        }}
      >
        {value}
      </dd>
    </dl>
  );
}

/**
 * Tests to validate if the object is a previous/current pair.
 * If not validated `false` is returned so the next
 * fn can try to display the data.
 *
 * Valid example: `{Previous: 'Deo', Current: 'Osbourne'}`
 *
 * @param {string} key
 * @param {(Record<"Previous" | "Current", string>)} prevCurr
 * @return {*}  {(JSX.Element | false)}
 */
function TryPreviousCurrentPair(
  key: string,
  prevCurr: Record<"Previous" | "Current", string>
): JSX.Element | false {
  const isPreviousCurrentPair =
    Object.hasOwn(prevCurr, "Previous") &&
    Object.hasOwn(prevCurr, "Current") &&
    typeof prevCurr.Current !== "object" &&
    typeof prevCurr.Previous !== "object" &&
    Object.keys(prevCurr).length == 2;

  if (isPreviousCurrentPair) {
    console.log(`prevCurr["Previous"]: `, prevCurr["Previous"]);
    if (
      key === "ArrivalDateAndTime" &&
      !moment(prevCurr["Previous"], "DD/MM/YYYY").isValid()
    ) {
      prevCurr["Previous"] = moment(prevCurr["Previous"]).format("DD/MM/YYYY");
      prevCurr["Current"] = moment(prevCurr["Current"]).format("DD/MM/YYYY");
    }
    return (
      <dl>
        <dt>{upcaseID(pascalCaseToSentence(key))}</dt>
        <dd>
          {": "}
          {prevCurr["Previous"].toString()} {"to "}{" "}
          {prevCurr["Current"].toString()}
        </dd>
      </dl>
    );
  } else {
    return false;
  }
}
/**
 * Test to validate if object is a Clinic change.
 * If not validated `false` is returned so the next
 * fn can try to display the data.
 *
 * Valid example:
 * ```
 * {Previous: [{name: 'LocalGP'}, {name: 'CityCP}], Current: [{name: 'LocalHospital'}, {name: 'CityHospital'}]}```
 *
 * @param {*} prevCurr
 * @return {*}  {(JSX.Element | false)}
 */
function TryListClinics(key: string, prevCurr: any): JSX.Element | false {
  const isListOfClinics =
    Object.hasOwn(prevCurr, "Previous") &&
    Object.hasOwn(prevCurr, "Current") &&
    Array.isArray(prevCurr["Previous"]) &&
    Array.isArray(prevCurr["Current"]) &&
    Object.keys(prevCurr).length == 2;

  if (!isListOfClinics) return false;

  const removedClinics = differenceWith(
    prevCurr.Previous,
    prevCurr.Current,
    isEqual
  );
  const addedClinics = differenceWith(
    prevCurr.Current,
    prevCurr.Previous,
    isEqual
  );
  return (
    <>
      {removedClinics.length > 0 && (
        <>
          <dl>
            <dt>Removed {key}: </dt>
          </dl>
          <ClinicGroup clinics={removedClinics}></ClinicGroup>
        </>
      )}
      {addedClinics.length > 0 && (
        <>
          <dl>
            <dt>Added {key}: </dt>
          </dl>
          <ClinicGroup clinics={addedClinics}></ClinicGroup>
        </>
      )}
    </>
  );
}

function ClinicGroup({ clinics }: { clinics: any[] }) {
  return (
    <>
      {clinics.map((clinic) => (
        <div
          key={clinic.id}
          style={{
            marginLeft: "1em",
            marginBottom: "1em",
          }}
        >
          {Object.keys(clinic).map((key: string) => {
            return (
              <dl key={key}>
                <dt>
                  {key} {": "}
                </dt>
                <dd>{clinic[key].toString()}</dd>
              </dl>
            );
          })}
        </div>
      ))}
    </>
  );
}

//Should be moved to utils;
const isObject = (obj: any) =>
  Object.prototype.toString.call(obj) === "[object Object]";

interface ClinicVaccinationBatchInfoDTO {
  Id: number;
  ClinicId: number;
  PathogenId: PathogenEnum;
  Pathogen: Pathogen;
  IsDefault: boolean;
  ClinicianName: string;
  VaccinationTypeId?: VaccinationTypeEnum;
  VaccinationType: VaccinationType;
  VaccinationBatchNumber: string;
  ExpiryMonth?: number;
  ExpiryYear?: number;
}

interface ClinicRepeatingGroupDTO {
  id: number;
  is24HourClinic: boolean;
  timeFrom?: string; //TimeSpan?
  timeTo?: string; //TimeSpan?
  breakTimeFrom: string;
  breakTimeTo: string;
  slotDuration?: number;
  repeatOnDays: string;
  numberOfSpaces?: number;
  dateFrom?: string; //DateTime?
  dateTo?: string; //DateTime?
  dayOfMonth: number;
  recurrenceId: RecurrenceEnum;
  clinicTypeId: ClinicTypeEnum;
  maximumNumberOfDropInAllowed?: number;
  requireBookingAllPathogens: boolean;
  createdDate: string; //DateTimeOffset
  editedDate: string; //DateTimeOffset?
  isSlots: boolean;
  isDropIn: boolean;
  pathogens: Pathogen[];
}

enum RecurrenceEnum {
  None = 0,
  Daily = 1,
  Weekly = 2,
  Monthly = 3,
}

enum ClinicTypeEnum {
  /**Slots Only (No Drop ins)*/
  BookingsOnly = 1,
  /**Slots with Drop ins*/
  BookingsWithDropIns = 2,
  /**Drop in only clinic*/
  WalkAroundClinics = 3,
}

enum VaccinationTypeEnum {
  SanofiPasteurQIVe = 1,
  SeqirusaQIV = 2,
  SeqirusQIVc = 3,
  MASTAQIVe = 4,
  AstraZenecaLAIV = 5,
  ViatrisQIVe = 6,
  SanofiPasteurQIVr = 7,
  MastaQIVeInfluvac = 8,
  PfizerBioNTechmRna = 201,
  OxfordAstraZeneca = 202,
  ModernamRna = 203,
  ModernamSpikevaxOmicron = 204,
}
enum PathogenEnum {
  Flu = 1,
  Covid = 2,
}
