import DataClient from "../../../../../lib/services/api/DataClient";
import { catchFormErrors } from "../../../../../lib/shared/api_client";
import { Template } from "./template";

const api = {
  list: () =>
    DataClient.postData("/NotificationTemplate/Search", {
      pageSize: -1,
      orderBy: "Order ASC",
    }).then((res: any) => res.results as Template[]),
  update: (item: Template) =>
    DataClient.update(`/NotificationTemplate/${item.id}`, item).catch(
      catchFormErrors
    ),
  testEmail: (template: Template, email: string) => {
    alert("TODO: implement api endpoint");
    return Promise.resolve();
  },
};

export default api;
