import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { humanNameFromObject } from "../../../../lib/common_utils";
import { isTrustAdmin } from "../../../../lib/services/TrustSwitcher";
import ConfirmDialog from "../../ConfirmDialog";
import { TrustWorkerDetailed } from "./TrustWorkerDetailedDTO";
import api, { SetReportingRolesDTO } from "./data";

const availableReportingLevels = [
  {
    label: "Reporting with anonymised data (recommended)",
    value: "IsReportingViewer",
  },
  {
    label: "Reporting with anonymised data & defined staff list",
    value: "IsReportingStaffViewer",
  },
];

export function SetReportingAccess({
  open,
  onClose,
  onSuccess,
  onError,
  value: trustWorker,
}: {
  open: any;
  onClose: () => Record<string, any>;
  onSuccess: any;
  onError: any;
  value: TrustWorkerDetailed; //activeItem;
}): JSX.Element {
  const [confirmDialog, setConfirmDialog] = useState(null);
  const [removeAccessDialog, setRemoveAccessDialog] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  // const [levelOfAccess, setLevelOfAccess] = useState(additionalRoleOptions[0]);

  const [reportingLevel, setReportingLevel] = useState(null);
  const [availableAccessLevels, setAvailableAccessLevels] = useState<
    Array<Array<string>>
  >([[], [], [], []]);
  const [selectedAccessLevels, _setSelectedAccessLevels] = useState<
    Array<Array<string>>
  >([[], [], [], []]);

  function setSelectedAccessLevels(levelIndex: number, selected: string[]) {
    _setSelectedAccessLevels((prev) => {
      const newSelectedAccessLevels = [...prev];
      newSelectedAccessLevels[levelIndex] = selected;
      return newSelectedAccessLevels;
    });
    // const newSelectedAccessLevels = [...selectedAccessLevels];
    // newSelectedAccessLevels[levelIndex] = selected;
    // _setSelectedAccessLevels(newSelectedAccessLevels);
  }

  function isFormValid() {
    // console.log(reportingLevel);
    return (
      reportingLevel &&
      (reportingLevel === "IsReportingViewer" ||
        selectedAccessLevels.some((level) => level.length > 0))
    );
  }

  // Apply current trustworkers roles to the form
  useEffect(() => {
    if (!isTrustAdmin() || !trustWorker) return; // !trustworker during load? maybe?
    setReportingLevel(
      trustWorker.isReportingStaffViewer
        ? "IsReportingStaffViewer"
        : trustWorker.isReportingViewer
        ? "IsReportingViewer"
        : null
    );
    if (
      trustWorker.reportingStaffAllowedLevels &&
      Object.keys(trustWorker.reportingStaffAllowedLevels)?.length > 0
    ) {
      Object.keys(trustWorker.reportingStaffAllowedLevels).forEach((key, i) => {
        setSelectedAccessLevels(
          i,
          trustWorker.reportingStaffAllowedLevels[key]
        );
      });
    } else {
      setSelectedAccessLevels(0, []);
      setSelectedAccessLevels(1, []);
      setSelectedAccessLevels(2, []);
      setSelectedAccessLevels(3, []);
    }
  }, [trustWorker]);

  useEffect(() => {
    if (!isTrustAdmin()) return;
    api.staff.getReportAccessLevelValues().then((accessLevels) => {
      setAvailableAccessLevels(accessLevels);
    });
  }, []);

  function submitStaffReportingLevels(removeAll = false) {
    const postData: SetReportingRolesDTO = {
      IsClinicAdmin: trustWorker.isClinicAdmin, // Not relevant but keep value
      IsVaccinator: trustWorker.isVaccinator, // Not relevant but keep value
      IsReportingViewer:
        reportingLevel == "IsReportingViewer" ||
        reportingLevel == "IsReportingStaffViewer",
      IsReportingStaffViewer: reportingLevel == "IsReportingStaffViewer",
      // Currently does not overwrite existing values when the roles switch from `IsReportingStaffViewer` to `IsReportingViewer`
      AllowedLevels: {
        L1: selectedAccessLevels[0],
        L2: selectedAccessLevels[1],
        L3: selectedAccessLevels[2],
        L4: selectedAccessLevels[3],
      },
    };
    if (removeAll) {
      postData.IsReportingViewer = false;
      postData.IsReportingStaffViewer = false;
      postData.AllowedLevels = {
        L1: [],
        L2: [],
        L3: [],
        L4: [],
      };
    }

    setSubmitting(true);
    api.staff
      .setReportingRoles(trustWorker, postData)
      .then((response: TrustWorkerDetailed) => {
        if (response) {
          onSuccess();
          setConfirmDialog(null);
        } else {
          throw new Error();
        }
      })
      .catch((e) => {
        onError({
          message:
            e?.response?.data?.results?.join(",") || "Could not update roles",
        });
        setConfirmDialog(null);
      })
      .finally(() => {
        setConfirmDialog(null);
        setSubmitting(false);
      });
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        {!trustWorker ? (
          <p>Loading...</p>
        ) : (
          <>
            <DialogTitle>
              Give {trustWorker.firstName} {trustWorker.lastName} Access to
              Reporting{" "}
            </DialogTitle>
            <DialogContent>
              <form
                id="update-user-roles"
                autoComplete="off"
                onSubmit={(e) => {
                  e.preventDefault();
                  setConfirmDialog(true);
                }}
              >
                <section style={{ marginBottom: "3em" }}>
                  <p>
                    There are two types of reporting access that can be given to
                    any Staff Member, Clinic Admin or Vaccinator. Trust Admins
                    already have reporting access by default.
                  </p>
                  <ul>
                    <li>
                      <strong>Reporting with anonymised data.</strong> This
                      gives access to the standard reporting pages that Trust
                      Admins see. Apart from the Vaccinators’ League Table page,
                      all data is anonymised. This is the most common access
                      level.
                      <br />
                      <br />
                    </li>
                    <li>
                      <strong>
                        Reporting with anonymised data & defined staff list.
                      </strong>{" "}
                      In addition to the above, access is also given to a ‘Staff
                      List’ page in the ‘Reporting’ section which shows a
                      defined list of staff and their vaccination statuses. This
                      is useful for managers who need up to date vaccination
                      info for staff in their specific department or region. To
                      define the list of staff they can see, select from the
                      levels below. See the ‘Enabling Reporting Access’ section
                      in the user guide for more information.
                    </li>
                  </ul>
                  <Select
                    style={{ width: "100%" }}
                    variant="outlined"
                    onChange={(e) => {
                      setReportingLevel(e.target.value);
                    }}
                    value={reportingLevel}
                    displayEmpty
                    // placeholder text like so (because MUI is hot garbage)
                    // https://github.com/mui/material-ui/issues/11069
                    // https://mui.com/material-ui/react-select/#placeholder
                    renderValue={(selected: any) =>
                      availableReportingLevels.find((o) => o.value == selected)
                        ?.label || "Select Level of Reporting Access"
                    }
                  >
                    {availableReportingLevels.map((o, i) => (
                      <MenuItem value={o.value} key={`option-${i}`}>
                        {o.label}
                      </MenuItem>
                    ))}
                  </Select>
                </section>
                {reportingLevel == availableReportingLevels[1].value && (
                  <section>
                    <h3>
                      Please select the level(s) you would like to allow access
                      to
                    </h3>
                    <p>
                      Always choose the lowest level required. E.g., if you want
                      to give access to a ward in level 4, just select that ward
                      from the level 4 drop-down. You can select as many levels
                      as you like and multiple selections can be made in each
                      level. You must select at least one option from at least
                      one level.
                    </p>

                    {availableAccessLevels.map((accessLevel, levelIdx) => (
                      <section style={{ width: "80%" }}>
                        <InputLabel>{"Level" + (levelIdx + 1)}</InputLabel>
                        <Select
                          style={{ width: "100%", marginBottom: "10px" }}
                          label={"Level" + (levelIdx + 1)}
                          variant="outlined"
                          multiple
                          renderValue={(selected: any) => selected.join(", ")}
                          placeholder="All levels"
                          value={selectedAccessLevels[levelIdx]}
                          onChange={(e) => {
                            setSelectedAccessLevels(
                              levelIdx,
                              e.target.value as string[]
                            );
                          }}
                        >
                          {accessLevel.map((o: any, i: number) => (
                            <MenuItem value={o} key={`option-${i}`}>
                              <Checkbox
                                checked={
                                  selectedAccessLevels[levelIdx].indexOf(o) > -1
                                }
                              />
                              {o}
                            </MenuItem>
                          ))}
                        </Select>
                      </section>
                    ))}
                  </section>
                )}
              </form>
            </DialogContent>
            <DialogActions>
              <Button
                form="update-user-roles"
                type="submit"
                variant="contained"
                color="secondary"
                autoFocus
                disabled={!isFormValid()}
              >
                Confirm
              </Button>
              <Button
                onClick={() => {
                  onClose();
                }}
                variant="outlined"
                color="secondary"
                style={{
                  color: "#fff",
                }}
              >
                Cancel
              </Button>
              {trustWorker.isReportingViewer && (
                <Button
                  onClick={() => {
                    setRemoveAccessDialog(true);
                  }}
                  variant="outlined"
                  color="secondary"
                  style={{
                    color: "#fff",
                    backgroundColor: "#f44336",
                    marginLeft: "auto",
                  }}
                >
                  Remove Reporting Access
                </Button>
              )}
            </DialogActions>
          </>
        )}
      </Dialog>

      {/* // Dialog to confirm new or edited reporting access */}
      <ConfirmDialog
        open={!!confirmDialog}
        disableConfirm={submitting}
        title={(() => {
          return `Are you sure you want to give ${humanNameFromObject(
            trustWorker
          )} access to reporting?`;
        })()}
        onConfirm={() => {
          submitStaffReportingLevels();
        }}
        onClose={() => {
          setConfirmDialog(null);
        }}
      >
        <>
          {reportingLevel == "IsReportingStaffViewer" ? (
            <p>
              This will give them access to the standard reporting section with
              anonymised data as well as a defined staff list showing staff
              vaccination statuses.
            </p>
          ) : (
            <p>
              This will give them access to the standard reporting section with
              anonymised data.
            </p>
          )}
        </>
      </ConfirmDialog>

      {/* // Dialog to confirm removed reporting access */}
      <ConfirmDialog
        open={!!removeAccessDialog}
        disableConfirm={submitting}
        title={(() => {
          return `Are you sure you want to remove reporting access for  ${humanNameFromObject(
            trustWorker
          )}?`;
        })()}
        onConfirm={() => {
          submitStaffReportingLevels(true);
          setRemoveAccessDialog(null);
        }}
        onClose={() => {
          setRemoveAccessDialog(null);
        }}
      ></ConfirmDialog>
    </>
  );
}
