import React from "react";
import { Pathogens } from "../../../../lib/pathogens";
import DataClient from "../../../../lib/services/api/DataClient";
import useDataClient from "../useDataClient";

export type TrustAdmin = {
  id: number;
  emailAddress: string;
  firstName: string;
  lastName: string;
  dateOfBirth?: string;
  jobTitle: string;
  trustName: string;
  mobileNumber?: string;
  pathogens?: Pathogens[];
};

export type TrustAdminData = {
  id?: number;
  mobileNumber?: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  dateOfBirth: string;
  trust: {
    id: number;
    trustName?: string;
  };
  proceedIfWarnings: boolean;
  pathogens?: Pathogens[];
};

type TrustAdminResponse = {
  success: boolean;
  results: {
    totalCount: number;

    pagedResults: TrustAdminData[];
  };
};

const isTrustAdminResponse = (res: unknown): res is TrustAdminResponse => {
  return (
    res["success"] &&
    res["results"] &&
    //res["results"]["totalCount"] &&
    res["results"]["pagedResults"] &&
    Array.isArray(res["results"]["pagedResults"])
  );
};

export type FilterProps = {
  pageNumber: number;
  pageSize: number;
};

export default function useTrustAdminList(
  defaultPage = 1,
  defaultSize = 10
) {
  const [searchText, setSearchText] = React.useState("");
  const [pageNumber, setPageNumber] = React.useState(defaultPage);
  const [pageSize, setPageSize] = React.useState(defaultSize);
  const postDataArgs = React.useMemo(() => {
    return [
      "/TrustAdmin/Search",
      {
        searchText: searchText || "",
        pageNumber,
        pageSize,
      },
    ];
  }, [searchText, pageNumber, pageSize]);
  const hookOptions = React.useMemo(
    () => ({
      formatter: (data: TrustAdminResponse) => {
        if (!data.success) {
          throw new Error("API call was a failure");
        }
        return {
          totalCount: data.results.totalCount,
          results: data.results.pagedResults.map(
            (d: TrustAdminData): TrustAdmin => ({
              id: d.id,
              firstName: d.firstName,
              lastName: d.lastName,
              jobTitle: d.jobTitle,
              emailAddress: d.emailAddress,
              trustName: d.trust?.trustName,
              mobileNumber: d.mobileNumber,
              pathogens: d.pathogens,
            })
          ),
        };
      },
      guard: isTrustAdminResponse,
    }),
    []
  );
  const adminList = useDataClient<
    { totalCount: number; results: TrustAdmin[] },
    TrustAdminResponse
  >(DataClient.postData, postDataArgs, hookOptions);
  return [...adminList, setSearchText, setPageNumber, setPageSize] as const;
}
