import { useEffect, useState } from "react";
import ConfirmDialog from "../../ConfirmDialog";
import { CloudDownload } from "@material-ui/icons/";
import { Alert } from "@material-ui/lab";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Button } from "@material-ui/core";
import moment from "moment";
import DataClient from "../../../../lib/services/api/DataClient";
export default function PlannerDownloader({ startDate, endDate }) {
  const [downloadDates, setDownloadDates] = useState({});
  useEffect(() => {
    setDownloadDates({ startDate, endDate });
  }, [startDate, endDate]);
  const [downloadError, setDownloadError] = useState(null);
  const [modal, setModal] = useState(null);
  const [loading, setLoading] = useState(false);
  return (
    <>
      <Button
        onClick={() => {
          setModal("download");
        }}
        color="primary"
        startIcon={<CloudDownload />}
        variant="contained"
      >
        Download
      </Button>
      <ConfirmDialog
        title="Download Clinic Data CSV File"
        open={modal === "download"}
        loading={loading}
        confirmLabel="Download"
        closeLabel="Close"
        onConfirm={() => {
          setDownloadError(null);
          if (
            !downloadDates?.startDate ||
            !downloadDates?.endDate ||
            downloadDates.startDate > downloadDates.endDate
          ) {
            return setDownloadError(
              "Please provide sensible start and end dates"
            );
          }
          setLoading(true);
          const DateFrom = moment(downloadDates.startDate).format("YYYY-MM-DD");
          const DateTo = moment(downloadDates.endDate).format("YYYY-MM-DD");
          const DateFromUk = moment(downloadDates.startDate).format(
            "DD-MM-YYYY"
          );
          const DateToUk = moment(downloadDates.endDate).format("DD-MM-YYYY");
          DataClient.getFile({
            url: `/Reservation/ClinicPlannerExport`,
            filename: `Vaccination Clinic Data - ${DateFromUk} - ${DateToUk}.csv`,
            method: "POST",
            params: {
              DateFrom,
              DateTo,
            },
          })
            .catch((e) => {
              return setDownloadError(
                e?.message || "Something went wrong with data download"
              );
            })
            .finally(() => {
              setLoading(false);
            });
        }}
        onClose={() => {
          setModal(null);
        }}
      >
        <p>Please choose a date range to download:</p>
        <form autoComplete="off">
          <section className="section-double">
            <KeyboardDatePicker
              label="Start Date"
              required
              placeholder="dd/mm/yyyy"
              autoOk={true}
              format="DD/MM/YYYY"
              value={downloadDates?.startDate || null}
              onChange={(e) => {
                setDownloadDates((dates) => ({
                  ...dates,
                  startDate: e.toDate(),
                }));
              }}
            />
            <KeyboardDatePicker
              label="End Date"
              required
              placeholder="dd/mm/yyyy"
              autoOk={true}
              format="DD/MM/YYYY"
              value={downloadDates?.endDate || null}
              onChange={(e) => {
                setDownloadDates((dates) => ({
                  ...dates,
                  endDate: e.toDate(),
                }));
              }}
            />
          </section>
          {downloadError && <Alert severity="error">{downloadError}</Alert>}
        </form>
      </ConfirmDialog>
    </>
  );
}
