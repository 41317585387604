import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import notfound from "./404_error.png";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";

const useStyles = makeStyles((theme) => ({
  placeholderBackground: {
    width: "100%",
    height: "85vh",
  },
  img: {
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    marginTop: "5%",
  },
  title: {
    fontWeight: "bold",
    paddingBottom: "2%",
  },
  infoText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    flexDirection: "row",
  },
  subheader: {
    fontSize: "20px",
    color: theme.palette.primary.light,
  },
}));

function PageNotFound() {
  const classes = useStyles();
  return (
    <Grid container className={classes.placeholderBackground}>
      <Grid item xs={false} md={5} className={classes.infoText}>
        <div style={{ width: "100%", paddingLeft: "10%" }}>
          <Typography
            variant="h2"
            color="primary"
            className={classes.title}
            gutterBottom
          >
            Oops!
          </Typography>
          <Typography className={classes.subheader} gutterBottom>
            The page you are looking for does not exist.
            <br />
            You might have mistyped the address. Please check the URL and try
            again or <a href="/">return to home</a>.
          </Typography>
        </div>
      </Grid>
      <Grid item md={7}>
        <div style={{ paddingTop: "10%" }}>
          <img className={classes.img} src={notfound} width={"75%"} alt="" />
        </div>
      </Grid>
    </Grid>
  );
}

export default PageNotFound;
