import { useContext } from "react";
import { HelpContext } from "../../App";
import HelpInfoBox from "./HelpInfoBox";
import HelpTooltip from "./HelpTooltip";
export interface HelpCopy {
  location: string;
  type: string;
  placement?:
    | "bottom"
    | "left"
    | "right"
    | "top"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start";
  title?: string | JSX.Element;
  content?: string | JSX.Element;
}
// How do I add/change help messages? See README for instructions
export default ({
  helpCopyKey,
  helpCopyOverride = null,
}: {
  helpCopyKey?: string;
  helpCopyOverride?: HelpCopy;
}): JSX.Element => {
  const helpContext = useContext(HelpContext);
  const helpCopy = helpCopyOverride
    ? helpCopyOverride
    : helpContext.find((c) => c.location == helpCopyKey);
  const type = helpCopy?.type;
  if (!helpCopy) return null;
  return (
    <div help-copy-key={helpCopyKey || ""}>
      {type === "tooltip" ? (
        <HelpTooltip helpCopy={helpCopy} />
      ) : type === "infobox" ? (
        <HelpInfoBox helpCopy={helpCopy} />
      ) : null}
    </div>
  );
};
