import DataClient from "../../../../../lib/services/api/DataClient";
import { catchFormErrors } from "../../../../../lib/shared/api_client";
import { Hospital } from "./hospital";

const from = (item: Hospital) => ({
  ...item,
  hospitalSiteName: item.name,
});

const to = (item: any) =>
  ({
    ...item,
    name: item.hospitalSiteName,
  } as Hospital);

const api = {
  list: () =>
    DataClient.getData(
      "/HospitalSite/hospital_sites?includeArchived=true"
    ).then((data: any) => data.results.map(to)),
  get: (id: number) =>
    DataClient.getData(`/HospitalSite/${id}`)
      .then((res) => res.results)
      .then(to),
  add: (item: Hospital) => {
    return DataClient.postData("/HospitalSite", from(item)).catch(
      catchFormErrors
    );
  },
  update: (item: Hospital) =>
    DataClient.update(`/HospitalSite/${item.id}`, from(item)),
  deleteHard: (id: number) =>
    DataClient.remove({
      url: `/HospitalSite/${id}/HardDelete`,
    }),
  archive: (id: number) =>
    DataClient.remove({
      url: `/HospitalSite/${id}`,
    }),
  unarchive: (id: number) =>
    DataClient.update(`/HospitalSite/${id}/Restore`, {}),
};

export default api;
