// import { isClinicAdminOrHigher } from "../../lib/services/TrustSwitcher";
import StaffList from "../Users/TrustAdmin/Staff/List";
export function StaffListTab({ trustId }: { trustId: number }): JSX.Element {
  return (
    <>
      <h1 className="dashboard-heading">Staff List</h1>
      {/* <ComplianceStats
        {...{ pathogenIds: [Pathogens.Covid], label: "Covid", trustId }}
      /> */}
      {
        <StaffList
          {...{
            // trustId,
            tableProps: {
              header: null,
              showAlertBoxes: false,
              // buttonsToShow: [TableToolbarButtons.downloadReport],
              // complianceFiltersEnabled: true,
              // isComplianceTable: true,
              // complianceFiltersPathogenIds: [Pathogens.Covid],
              // downloadReportEnabled: true,
              readOnly: true,
              optionsOverrides: {
                search: true,
                searchAlwaysOpen: true,
                filter: true,
                selectableRows: false,
              },
            },
          }}
        />
      }
    </>
  );
}
