import DataClient from "../../lib/services/api/DataClient";

const routeDashboard = "/dashboard";

const DashboardApi = {
  getUserConfig: () =>
    DataClient.getData(`${routeDashboard}/user-config`).then(
      (res) => res.results as DashboardUserConfig
    ),
  overView: (
    pathogenIds: string,
    onlyFLHCW: boolean,
    trustId: number,
    excludeL1: number[] = []
  ) =>
    DataClient.getData(`${routeDashboard}/overview`, {
      pathogenIds: pathogenIds,
      onlyFLHCW: onlyFLHCW,
      trustId: trustId,
      excludeL1: excludeL1,
    }).then((res) => res.results as DashboardOverview),
  immFormReport: (
    pathogenIds: string,
    onlyFLHCW: boolean,
    trustId: number,
    excludeL1: string
  ) =>
    DataClient.getData(`${routeDashboard}/immform-report`, {
      pathogenIds: pathogenIds,
      onlyFLHCW: onlyFLHCW,
      trustId: trustId,
      excludeL1: excludeL1,
    }).then((res) => res.results as ImmFormReportData),
  clinicCapacity: (pathogenIds: number[], trustId: number) =>
    DataClient.getData(`${routeDashboard}/clinic-capacity`, {
      pathogenIds: pathogenIds,
      trustId: trustId,
    }).then((res) => res.results as ClinicCapacitySummary),
  vaccinatedStaff: (
    dimension: string,
    pathogenIds: string,
    onlyFLHCW: boolean,
    trustId: number,
    excludeL1: string
  ) =>
    DataClient.getData(`${routeDashboard}/vaccinated-staff/${dimension}`, {
      pathogenIds: pathogenIds,
      onlyFLHCW: onlyFLHCW,
      trustId: trustId,
      excludeL1: excludeL1,
    }).then((res) => res.results),
  unvaccinatedStaff: (
    dimension: string,
    pathogenIds: string,
    onlyFLHCW: boolean,
    trustId: number,
    excludeL1: string
  ) =>
    DataClient.getData(`${routeDashboard}/unvaccinated-staff/${dimension}`, {
      pathogenIds: pathogenIds,
      onlyFLHCW: onlyFLHCW,
      trustId: trustId,
      excludeL1: excludeL1,
    }).then((res) => res.results as []),
  declineReasons: (
    pathogenIds: string,
    onlyFLHCW: boolean,
    trustId: number,
    excludeL1: string
  ) =>
    DataClient.getData(`${routeDashboard}/decline-reasons`, {
      pathogenIds: pathogenIds,
      onlyFLHCW: onlyFLHCW,
      trustId: trustId,
      excludeL1: excludeL1,
    }).then((res) => res.results as []),
  performanceTrustTotals: (
    pathogenIds: string,
    onlyFLHCW: boolean,
    trustId: number,
    excludeL1: string
  ) =>
    DataClient.getData(`${routeDashboard}/performance/totals`, {
      pathogenIds: pathogenIds,
      onlyFLHCW: onlyFLHCW,
      trustId: trustId,
      excludeL1: excludeL1,
    }).then((res) => res.results as PerformanceRow),
  performanceTrustBreakdown: (
    dimension: string,
    pathogenIds: string,
    onlyFLHCW: boolean,
    trustId: number,
    excludeL1: string
  ) =>
    DataClient.getData(`${routeDashboard}/performance/breakdown/${dimension}`, {
      pathogenIds: pathogenIds,
      onlyFLHCW: onlyFLHCW,
      trustId: trustId,
      excludeL1: excludeL1,
    }).then((res) => res.results as PerformanceRow[]),
  performanceTrustDownload: (
    dimension: string,
    pathogenIds: string,
    onlyFLHCW: boolean,
    trustId: number,
    excludeL1: string,
    filename: string
  ) =>
    DataClient.getFile({
      url: `${routeDashboard}/performance/download/${dimension}`,
      filename: `${filename}.csv`,
      method: "GET",
      params: {
        pathogenIds: pathogenIds,
        onlyFLHCW: onlyFLHCW,
        trustId: trustId,
      excludeL1: excludeL1,
      },
    }).then(() => {
      // console.log("downloaded:", dimension);
    }),
  level1Ids: (pathogenIds: string, onlyFLHCW: boolean, trustId: number) =>
    DataClient.getData(`${routeDashboard}/level-1-values`, {
      pathogenIds: pathogenIds,
      onlyFLHCW: onlyFLHCW,
      trustId: trustId,
    }).then((res) => res),
};

export default DashboardApi;

export interface ImmFormReportData {
  immFormEsrCategoryTotals: ImmFormRow;
  immFormEsrCategories: ImmFormRow[];
  immFormNonEsrGroupTotals: ImmFormRow;
  immFormNonEsrGroups: ImmFormRow[];
  nonImmFormEsrCategoryTotals: ImmFormRow;
  nonImmFormEsrCategories: ImmFormRow[];
  nonImmFormNonEsrGroupTotals: ImmFormRow;
  nonImmFormNonEsrGroups: ImmFormRow[];
  totalImmFormWorkers: number;
  totalNonImmFormWorkers: number;
}

export interface ImmFormRow {
  name: string;
  total: number;
  vaccinated: number;
}

export interface PerformanceRow {
  name: string;
  totalStaff: number;
  frontlineStaff: number;
  vaccinated: number;
  vaccinatedByTrust: number;
  booked: number;
  hadElsewhere: number;
  noBooking: number;
  declined: number;
  dna: number;
  abnv: number;
  frontLinePercentage: number;
  totalPercentage: number;
}

export interface PagedResults<T> {
  totalCount: number;
  pagedResults: T[];
}

export interface StaffGroup {
  id: number;
  name: string;
  isFlhwByDefault: boolean;
  isImmFormByDefault: boolean;
}

export interface ClinicCapacitySummary {
  totalSlots: number;
  bookedSlots: number;
  availableSlots: number;
  futureAvailableSlots: number;
  futureTotalSlots: number;
  futureBookedSlots: number;
}
export interface DashboardUserConfig {
  isGroupAdmin: boolean;
  trusts: TrustInfo[];
  pathogens: number[];
}

export interface TrustInfo {
  id: number;
  trustName: string;
}
export interface DashboardOverview {
  totalStaff: number;
  inactiveStaff: number;
  totalVaccinated: number;
  totalBooking: number;
  hadElsewhere: number;
  noBooking: number;
  decline: number;
  totalAppointmentsBooked: number;
  totalVaccinationsGiven: number;
  totalDnasRecorded: number;
}
