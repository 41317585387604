import { Button, CircularProgress, LinearProgress } from "@material-ui/core";
import { Add, ArrowBack, Check, Delete, Edit } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import ApiAlert from "../../../../components/ApiAlert";
import { humanDate } from "../../../../lib/common_utils";
import {
  Pathogen,
  Pathogens,
  VaccinationType,
  shouldShowClinicianNames,
} from "../../../../lib/pathogens";
import DataClient from "../../../../lib/services/api/DataClient";
import { palette } from "../../../../lib/themes/vars";
import ConfirmDialog from "../../ConfirmDialog";
export interface VaccineBatch {
  clinicId: number;
  clinicianName?: string;
  // expiryMonth?: string;
  // expiryYear?: string;
  expiryDate: string;
  defrostDate: string;
  id: number;
  isDefault: boolean;
  pathogen: Pathogen;
  pathogenId: number;
  vaccinationBatchNumber?: string;
  vaccinationType?: VaccinationType;
  vaccinationTypeId?: number;
  createdBy?: string;
  editedBy?: string;
  createdDate?: string;
  editedDate?: string;
}
export const vaccineBatchInformalName = (b: VaccineBatch) =>
  [
    b?.vaccinationType?.name,
    b?.vaccinationBatchNumber,
    humanDate(b?.expiryDate),
  ]
    .filter((_) => _ != null && _ != "")
    .join(" - ") || "Unspecified";

export default (
  props: RouteComponentProps<{
    clinicId: string;
    pathogenId: string;
  }>,
): JSX.Element => {
  const { clinicId } = props.match.params;
  const pathogenId = parseInt(props.match.params.pathogenId);
  const pathogenName = Pathogens[pathogenId];
  const [batches, setBatches] = useState<VaccineBatch[]>([]);
  const [action, setAction] = useState<"delete" | "edit" | "setDefault">(null);
  const [activeBatch, setActiveBatch] = useState<VaccineBatch>(null);
  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(true);
  const [settingAsDefault, setSettingAsDefault] = useState(null);
  const showClinicianNames = shouldShowClinicianNames(pathogenId);
  const fetchAllTheThings = () => {
    // Promises, promises..
    setLoading(true);
    Promise.all([
      // DataClient.getData(`/Clinic/${clinicId}`),
      DataClient.postData(`/Clinic/${clinicId}/GetAllClinicBatchDetails`),
    ])
      .then((res) => {
        // Handle API failures
        res.forEach(({ success }, i) => {
          if (!success) {
            throw new Error(`Failed to fetch correct data (${i})`);
          }
        });
        const vaccinationBatchInfos = res[0].results;
        const batches = vaccinationBatchInfos?.filter(
          (b: any) => b?.pathogenId === pathogenId,
        ) as VaccineBatch[];
        // If no batch was set as default, assume the first
        if (
          batches?.length &&
          !batches?.find((b: VaccineBatch) => b.isDefault)
        ) {
          batches[0].isDefault = true;
        }
        setBatches(batches);
      })
      .finally(() => {
        setLoading(false);
        setSettingAsDefault(null);
      });
  };
  useEffect(() => {
    fetchAllTheThings();
  }, [setBatches]);
  const setAsDefault = (id: number | string) => {
    setSettingAsDefault(id);
    setAction(null);
    DataClient.update(`/Clinic/SetClinicBatchDefault/${id}`)
      .then(({ success, results }) => {
        if (!success) throw new Error();
      })
      .catch((e) => {
        console.error(e);
        setAlert(
          ApiAlert({
            alwaysShow: true,
            message: `Could not set ${vaccineBatchInformalName(
              activeBatch,
            )} as default`, // KISS
          }),
        );
      })
      .finally(() => {
        fetchAllTheThings();
      });
  };

  return (
    <main className="wrapper-main">
      <h1>{pathogenName} Vaccine Batches</h1>
      <p>
        <Button
          component={Link}
          to={`/clinics/${clinicId}/view`}
          color={"primary"}
          startIcon={<ArrowBack />}
        >
          Back to Clinic
        </Button>
      </p>
      <p>
        Add vaccine batch information to save you manually adding it everytime
        you record a vaccination.
      </p>
      <p>
        Default batches will automatically appear when recording vaccinations
        unless you manually select another batch.
      </p>
      <br />
      <p>
        <Button
          component={Link}
          to={`/clinics/${clinicId}/vaccine-batches/${pathogenId}/add`}
          color="primary"
          variant="contained"
          className="theme-button"
          data-theme-id={pathogenId}
          startIcon={<Add />}
        >
          Add Batch
        </Button>
      </p>
      {alert}
      <table
        className="table table-batches table-fonts"
        style={{ marginBottom: "1em" }}
      >
        <thead>
          <tr>
            {/* NB: There are also data-label values */}
            <th>Status</th>
            <th>Vaccine Type</th>
            <th>Batch Number</th>
            <th>Expiry Date</th>
            {pathogenId === Pathogens["Covid"] && (
              <th>Covid Defrost Expiry Date</th>
            )}
            {showClinicianNames && <th>Clinician Drawing Up</th>}
            <th>Added By</th>
            <th>Added Date</th>
            <th>Last Edited By</th>
            <th>Last Edited Date</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {batches?.map((b, i) => (
            <tr key={`batch-row-${i}`}>
              <td data-label="Status">
                {!b.id ? (
                  ""
                ) : b.isDefault ? (
                  <Button
                    disabled
                    variant="contained"
                    style={{
                      minWidth: "10em",
                      color: palette.walkaround.contrastText,
                      backgroundColor: palette.walkaround.main,
                    }}
                    startIcon={<Check />}
                  >
                    Default Batch
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setActiveBatch(b);
                      setAction("setDefault");
                    }}
                    variant="outlined"
                    style={{
                      minWidth: "10em",
                      color: palette.walkaround.main,
                      borderColor: palette.walkaround.main,
                      opacity: settingAsDefault ? 0.7 : 1,
                    }}
                    disabled={settingAsDefault}
                    endIcon={
                      settingAsDefault === b.id ? <CircularProgress /> : null
                    }
                  >
                    {settingAsDefault ? "Setting" : "Set"} as Default
                  </Button>
                )}
              </td>
              <td data-label="Vaccine Type">
                {b.vaccinationType?.name || " - "}
              </td>
              <td data-label="Batch Number">
                {b.vaccinationBatchNumber || " - "}
              </td>

              <td data-label="Expiry Date">
                {humanDate(b.expiryDate) || " - "}
              </td>
              {pathogenId === Pathogens["Covid"] && (
                <td data-label="Covid Defrost Expiry Datee">
                  {humanDate(b.defrostDate) || " - "}
                </td>
              )}
              {showClinicianNames && (
                <td data-label="Clinician Drawing Up">
                  {b.clinicianName || " - "}
                </td>
              )}
              <td data-label="Created by">{b.createdBy}</td>
              <td data-label="Date Created">{humanDate(b.createdDate)}</td>
              <td data-label="Edited by">{b.editedBy}</td>
              <td data-label="Date Last Edited">{humanDate(b.editedDate)}</td>
              <td data-label="">
                {!b.id ? (
                  ""
                ) : (
                  <div className="_actions">
                    <Button
                      component={Link}
                      to={`/clinics/${clinicId}/vaccine-batches/${pathogenId}/${b.id}/edit`}
                      color="secondary"
                      variant="contained"
                      startIcon={<Edit />}
                    >
                      Edit
                    </Button>
                    <Button
                      onClick={() => {
                        setActiveBatch(b);
                        setAction("delete");
                      }}
                      variant="outlined"
                      startIcon={<Delete />}
                      style={{
                        color: palette.error.main,
                        borderColor: palette.error.main,
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {loading && <LinearProgress />}
      <ConfirmDialog
        title="Delete Vaccine Batch"
        open={action === "delete"}
        onConfirm={() => {
          DataClient.remove({
            url: `/Clinic/DeleteClinicBatchDetails/${activeBatch.id}`,
          })
            .then(({ success, results }) => {
              if (!success) throw new Error();
              setAlert(
                ApiAlert({
                  alwaysShow: true,
                  severity: "success",
                  title: "Success",
                  message: `Batch ${vaccineBatchInformalName(
                    activeBatch,
                  )} deleted`,
                }),
              );
              fetchAllTheThings();
            })
            .catch((e) => {
              console.error(e);
              setAlert(
                ApiAlert({
                  alwaysShow: true,
                  message: "Could not delete vaccine batch", // KISS
                }),
              );
            })
            .finally(() => {
              setAction(null);
            });
        }}
        onClose={() => {
          setAction(null);
        }}
      >
        <p>
          Are you sure you want to delete{" "}
          <strong>
            {vaccineBatchInformalName(activeBatch) || "this batch"}
          </strong>
          ?
        </p>
      </ConfirmDialog>
      <ConfirmDialog
        title="Set Default Batch"
        open={action === "setDefault"}
        onConfirm={() => {
          setAsDefault(activeBatch.id);
        }}
        onClose={() => {
          setAction(null);
        }}
      >
        <p>Are you sure you want to set this as the default batch?</p>
      </ConfirmDialog>
    </main>
  );
};
