import { humanName } from "../../../lib/common_utils";
import DataClient from "../../../lib/services/api/DataClient";
export interface Vaccinator {
  firstName: string;
  lastName: string;
}
export const fetchVaccinators = () =>
  DataClient.getData(`/TrustWorker/ListVaccinators`, {
    pageSize: -1,
  });
export const humanVaccinatorNames = (vaccinators: Vaccinator[]) =>
  vaccinators?.map((v) => humanName(v.firstName || "", v.lastName || "")) || [];
export const selectOptions = (values: string[], currentValue?: string) => (
  <>
    {/* If values do not include the current value, prepend it into the options */}
    {currentValue && !values?.includes(currentValue) ? (
      <option value={currentValue} key={`so--1`} disabled>
        {currentValue}
      </option>
    ) : null}
    {values?.map((name: string, i) => (
      <option value={name} key={`so-${i}`}>
        {name}
      </option>
    ))}
  </>
);
