import React from "react";
import DataClient from "../../../../lib/services/api/DataClient";
import useDataClient from "../useDataClient";

export type Template = {
  id: number;
  name: string;
  displayName?: string;
  description: string;
  emailSubject: string;
  emailBody: string;
  smsBody: string;
};

type TemplateResponse = {
  success: boolean;
  results: Template[];
};

const isTemplateResponse = (res: unknown): res is TemplateResponse => {
  return (
    res["success"] !== undefined &&
    res["results"] &&
    Array.isArray(res["results"])
  );
};

export default function useTemplateList() {
  const listArgs = React.useMemo(() => ["/NotificationTemplate/Search"], []);
  const hookOptions = React.useMemo(
    () => ({
      guard: isTemplateResponse,
      formatter: (data: TemplateResponse) => {
        if (!data.success) {
          throw new Error("API call was a failure");
        }
        return data.results;
      },
    }),
    []
  );
  const templatesClient = useDataClient<Template[], TemplateResponse>(
    DataClient.postData,
    listArgs,
    hookOptions
  );

  return templatesClient;
}
