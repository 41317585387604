import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogProps,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Tooltip,
} from "@material-ui/core";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  KeyboardBackspace as BackIcon,
  ToggleOff,
  ToggleOn,
} from "@material-ui/icons";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import ToastMessage from "../../../../../components/Universal/toast_message";
import ConfirmDialog from "../../../ConfirmDialog";
import FormDialog from "../FormDialog";
import { Hospital } from "../Hospitals/hospital";
import { Feedback } from "../SettingsContainer";
import api from "./api";
import { Clinic } from "./clinic";
import Form from "./Form";

const useStyles = makeStyles({
  root: {
    minWidth: "30rem",
  },
});

const ClinicList: FunctionComponent<{ hospital: Hospital }> = ({
  hospital,
}) => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<Clinic[]>(null);
  const [action, setAction] = useState<
    "delete" | "create" | "edit" | "archive" | "unarchive"
  >(null);
  const [activeItem, setActiveItem] = useState<Clinic>(null);
  const [feedback, setFeedback] = useState<Feedback>(null);
  const classes = useStyles();

  const fetchData = useCallback(() => {
    setLoading(true);
    api
      .list(hospital?.id)
      .then((data) => setItems(data))
      .catch((e) => {
        if (!e.handled) {
          setFeedback({
            type: "error",
            message: "Error fetching clinic list",
          });
        }
      })
      .finally(() => setLoading(false));
  }, [setFeedback, hospital]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleCancel = () => {
    setAction(null);
  };

  const handleDelete = () => {
    api
      .delete(activeItem.id)
      .then(() => {
        setFeedback({ type: "success", message: "Clinic deleted" });
      })
      .catch(() =>
        setFeedback({ type: "error", message: "Failed deleting clinic" }),
      )
      .finally(() => {
        fetchData();
        setAction(null);
      });
  };

  const handleCreated = () => {
    setAction(null);
    setActiveItem(null);
    fetchData();
    setFeedback({
      type: "success",
      message: "Clinic created successfully!",
    });
  };

  const handleUpdated = () => {
    setAction(null);
    setActiveItem(null);
    fetchData();
    setFeedback({
      type: "success",
      message: "Clinic updated successfully!",
    });
  };

  const handleArchive = () => {
    api
      .archive(activeItem.id)
      .then(() => {
        setFeedback({ type: "success", message: "Clinic location archived" });
      })
      .catch(() =>
        setFeedback({
          type: "error",
          message: "Failed archiving clinic location",
        }),
      )
      .finally(() => {
        fetchData();
        setAction(null);
      });
  };

  const handleUnarchive = () => {
    api
      .unarchive(activeItem.id)
      .then(() => {
        setFeedback({ type: "success", message: "Clinic location unarchived" });
      })
      .catch(() =>
        setFeedback({
          type: "error",
          message: "Failed unarchiving clinic location",
        }),
      )
      .finally(() => {
        fetchData();
        setAction(null);
      });
  };

  const hasOneArchivedItem = (): boolean => {
    return items.some((item) => item.isArchived);
  };

  return (
    <>
      {loading && <LinearProgress color="primary" />}

      <Grid container spacing={2} justifyContent="space-between">
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              setActiveItem({
                hospitalId: hospital.id,
                name: null,
                id: null,
                directionsAndParking: null,
                isArchived: false,
              });
              setAction("create");
            }}
          >
            Add Clinic Location
          </Button>
        </Grid>
      </Grid>

      <Paper>
        {items && items.length > 0 && (
          <List>
            {items.map((item) => (
              <ListItem
                key={item.id}
                className={classes.root}
                style={{ paddingRight: "160px" }}
              >
                <ListItemText
                  primary={item.name}
                  style={{ overflowWrap: "break-word" }}
                />
                <ListItemSecondaryAction>
                  <Tooltip title="Edit">
                    <IconButton
                      color="primary"
                      onClick={() => {
                        setActiveItem(item);
                        setAction("edit");
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  {item.isArchived ? (
                    <Tooltip title="Unarchive">
                      <IconButton
                        // color="secondary"
                        style={{ color: "red" }}
                        onClick={() => {
                          setActiveItem(item);
                          setAction("unarchive");
                        }}
                      >
                        <ToggleOn />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Archive">
                      <IconButton
                        color="secondary"
                        onClick={() => {
                          setActiveItem(item);
                          setAction("archive");
                        }}
                      >
                        <ToggleOff />
                      </IconButton>
                    </Tooltip>
                  )}
                  {item.isArchived && (
                    <Tooltip title="Delete">
                      <IconButton
                        color="secondary"
                        onClick={() => {
                          setActiveItem(item);
                          setAction("delete");
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {!item.isArchived && hasOneArchivedItem() && (
                    <button
                      style={{ width: "50px", visibility: "hidden" }}
                    ></button>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
        {items && items.length === 0 && (
          <Box p={1}>
            <p>No clinic locations</p>
          </Box>
        )}
      </Paper>

      <FormDialog
        open={action === "create"}
        onClose={handleCancel}
        onError={() =>
          setFeedback({ type: "error", message: "Failed saving clinic" })
        }
        title="Create Clinic"
        id="formEdit"
        value={activeItem}
        render={(props) => <Form {...props} />}
        onSuccess={handleCreated}
        saveButtonText="Create"
        save={api.add}
      />

      <FormDialog
        open={action === "edit"}
        onClose={handleCancel}
        onError={() =>
          setFeedback({ type: "error", message: "Failed saving clinic" })
        }
        title="Edit Clinic"
        id="formCreate"
        value={activeItem}
        render={(props) => <Form {...props} />}
        onSuccess={handleUpdated}
        saveButtonText="Update"
        save={api.update}
      />

      <ConfirmDialog
        title="Are you sure you want to delete this clinic?"
        open={action === "delete"}
        onClose={handleCancel}
        onConfirm={handleDelete}
      />

      <ConfirmDialog
        title="Are you sure you want to archive this clinic location?"
        children={
          <p>
            The clinic location will no longer appear in the ‘Clinic Location’
            drop-down menu when creating or editing a clinic. However, any
            reference to the clinic location will not be removed from any
            bookings or previously recorded vaccinations.
          </p>
        }
        open={action === "archive"}
        onClose={handleCancel}
        onConfirm={handleArchive}
      />
      <ConfirmDialog
        title="Are you sure you want to unarchive this clinic location?"
        children={
          <p>
            The clinic location will now appear in the ‘Clinic Location’
            drop-down menu when creating or editing a clinic.
          </p>
        }
        open={action === "unarchive"}
        onClose={handleCancel}
        onConfirm={handleUnarchive}
      />

      {feedback && (
        <ToastMessage
          variant={feedback.type}
          message={feedback.message}
          clear={() => setFeedback(null)}
        />
      )}
    </>
  );
};

const ClinicListDialog: FunctionComponent<
  DialogProps & {
    hospital: Hospital;
    onClose: (...args: any[]) => void;
    onConfirm: (...args: any[]) => void;
  }
> = ({ onClose, hospital, ...dialogProps }) => (
  <Dialog {...dialogProps} onClose={onClose}>
    <DialogTitle>{hospital?.name} - Clinics</DialogTitle>

    <ClinicList hospital={hospital} />

    <DialogActions>
      <Button
        onClick={onClose}
        variant="outlined"
        color="secondary"
        style={{
          color: "#fff",
        }}
        startIcon={<BackIcon />}
      >
        Back to Sites
      </Button>
    </DialogActions>
  </Dialog>
);

export { ClinicListDialog, ClinicList };
export default ClinicList;
