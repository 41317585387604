import request from "../../shared/api_client";
// This is what we want ideally, and passing cancel tokens to the views as well
// interface Data {
//   success?: boolean;
//   results?: [];
// }
const call = ({
  url,
  method = "POST",
  params = {},
  data = {},
  headers,
}: {
  url: string;
  method: "POST" | "GET" | "DELETE" | "PUT";
  params?: Record<string, any>;
  data?: Record<string, any>;
  headers?: Record<string, any>;
}) => {
  return request({
    url,
    method,
    params,
    data,
    headers,
  }); // whole request including cancelTokenSource (not just client)
};
const getData = (url: string, params = {}) =>
  request({
    url,
    method: "GET",
    params: params ? params : {},
  }).client;
const postData = (url: string, data = {}, params = {}) =>
  request({
    url,
    method: "POST",
    data,
    params,
  }).client;
const getFile = ({
  url,
  filename,
  method = "GET",
  params = {},
}: {
  url: string;
  filename: string;
  method?: string;
  params?: Record<string, any>;
}) =>
  request({
    url,
    method,
    responseType: "blob",
    params,
  }).client.then((response) => {
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  });
const update = (url: string, data = {}) =>
  request({
    url,
    method: "PUT",
    data,
  }).client;
const remove = ({
  url,
  params = {},
  data = {},
}: {
  url: string;
  params?: Record<string, any>;
  data?: Record<string, any>;
}) =>
  request({
    url,
    method: "DELETE",
    params,
    data,
  }).client;
const DataClient = {
  call,
  getData,
  postData,
  getFile,
  update,
  remove,
};
export default DataClient;
