import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useState } from "react";
import { Feedback } from "./SettingsContainer";

export interface FormProps<T> {
  id: string;
  defaultValue: T;
  onSubmit: (item: T) => void;
  onFeedback?: (feedback: Feedback) => void;
}

interface FormDialogProps<T> extends DialogProps {
  id: string;
  title: string;
  saveButtonText: string;
  save: (item: T) => Promise<T & { errors?: string[] }>;
  onClose: (...args: any[]) => void;
  onSuccess: (item: T) => void;
  onError: (...args: any[]) => void;
  value: T;
  render: (props: FormProps<T>) => React.ReactNode;
}

const useStyles = makeStyles({
  root: {},
});

const FormDialog = <T,>({
  id,

  title,
  saveButtonText,
  save,
  onSuccess,
  onError,
  render,
  value,
  ...dialogProps
}: FormDialogProps<T>) => {
  const [errors, setErrors] = useState(null);
  const classes = useStyles();

  const handleSubmit = (newItem: T) => {
    setErrors(null);
    save(newItem)
      .then((response) => {
        if (response.errors) {
          setErrors(response.errors);
          return;
        }
        onSuccess(newItem);
      })
      .catch(onError);
  };

  return (
    <Dialog fullWidth {...dialogProps}>
      <DialogTitle disableTypography className={classes.root}>
        <h2>{title}</h2>
      </DialogTitle>
      <DialogContent>
        {render({
          defaultValue: value,
          onSubmit: handleSubmit,
          id,
        })}
        {errors && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            <pre>{JSON.stringify(errors, null, 2)}</pre>
          </Alert>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          form={id}
          type="submit"
          variant="contained"
          color="secondary"
          autoFocus
        >
          {saveButtonText}
        </Button>
        <Button
          onClick={dialogProps.onClose}
          variant="outlined"
          color="secondary"
          style={{
            color: "#fff",
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { FormDialog };
export default FormDialog;
